import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../constants/messages";
import { onParticipantDetail } from "../../../redux/actions/ParticipantAction";
import AddGuarantor from "./AddGuarantor";
import GuarantorListing from "./GuarantorListing";
const AddGuarantors = (props) => {
  const [guarantors, setGuarantors] = useState([]);
  const [toAddNewGuarantor, setToAddNewGuarantor] = useState(false);
  const [editGuarantor, setToEditGuarantor] = useState(false);
  const logNewCaseActiveKey = useSelector(
    (state) => state.auth.logNewCaseActiveKey
  );
  const newlyCreatedCase = useSelector((state) => state.auth.newlyCreatedCase);
  const [guarantorMaxLengthError,setGuarantorMaxLengthError] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (toAddNewGuarantor || editGuarantor) {
      document.getElementById("guarantors_heading").scrollIntoView({behavior:'smooth'});
    }
  }, [toAddNewGuarantor, editGuarantor]);
  useEffect(() => {
    if (
      newlyCreatedCase &&
      newlyCreatedCase.guarantors &&
      Array.isArray(newlyCreatedCase.guarantors)
    ) {
      setGuarantors(newlyCreatedCase.guarantors);
      if (newlyCreatedCase.guarantors.length === 0) {
        setToAddNewGuarantor(false);
        setToEditGuarantor(false);
      }
    }
  }, [newlyCreatedCase]);
  useEffect(() => {
    setToAddNewGuarantor(false);
  }, [logNewCaseActiveKey]);
  const handleAddNewGuarantor = () => {
    if(newlyCreatedCase && newlyCreatedCase.guarantors && Array.isArray(newlyCreatedCase.guarantors) && newlyCreatedCase.guarantors.length!==5){
      setGuarantorMaxLengthError('')
      setToAddNewGuarantor(true);
    }else{
      setGuarantorMaxLengthError(MESSAGES.MAX_GUARANTOR_ERROR);
      window.scrollTo(0, window.screen.height);
    }
    setToEditGuarantor(false);
  };

  const cancelAddNewGuarantor = () => {
    setToAddNewGuarantor(false);
    setToEditGuarantor(false);
  };
  const editGuarantorHandler = (co_applicant) => {
    
    setToAddNewGuarantor(false);
    setToEditGuarantor(co_applicant);
  };
  const makeEditable = (guarantor) => {
    setGuarantorMaxLengthError('')
    setToAddNewGuarantor(false);
    // setToEditGuarantor(guarantor);
    dispatch(
      onParticipantDetail(
        {
          applicant_type: "Guarantor",
          applicant_coapplicant_guarantor_id: guarantor.id,
        },
        (guarantor) => {
          

          setToEditGuarantor(guarantor);
        }
      )
    );
  };

  return (
    <React.Fragment>
      {guarantors.length > 0 && (
        <React.Fragment>
          <div className="HeaderPanelAction">
            <h3></h3>
            <div className="ButtonWrap">
              {(toAddNewGuarantor || editGuarantor) && (
                <Button className="AddField" onClick={cancelAddNewGuarantor}>
                  Cancel
                </Button>
              )}
              <Button className="AddField" onClick={handleAddNewGuarantor}>
                Add New Guarantor
              </Button>
            </div>
          </div>
          <GuarantorListing
            guarantors={newlyCreatedCase.guarantors}
            // editGuarantor={editGuarantorHandler}
            editGuarantor={makeEditable}
            caseDetail={newlyCreatedCase}
            afterDeleteCoapplicant = {(guarantorId)=>{
              setGuarantorMaxLengthError('')
              if(editGuarantor && editGuarantor.id && editGuarantor.id ==guarantorId ){
                setToEditGuarantor(false)
              }
            }}
          />
          <p className='errorMessage'>{guarantorMaxLengthError}</p>
        </React.Fragment>
      )}
      {guarantors.length === 0 ? (
        <AddGuarantor {...props} makeEditable={makeEditable} />
      ) : (
        toAddNewGuarantor && <AddGuarantor makeEditable={makeEditable} />
      )}
      {editGuarantor && (
        <AddGuarantor
          editGuarantor={editGuarantor}
          makeEditable={makeEditable}
        />
      )}
    </React.Fragment>
  );
};
export default AddGuarantors;
