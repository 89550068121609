import React, { Component, useEffect } from "react";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import CaseDetailHeader from "../components/casedetail/header";
import ViewCaseTabs from "../components/viewcasetabs";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  onCaseDetail,
  onResetNewCaseCreated,
  onUpdateNewCreatedCase,
} from "../redux/actions/CaseAction";
// import { onCaseDetail } from "../redux/actions/AuthAction";

const Viewcases = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    return function () {
      
      dispatch(onResetNewCaseCreated());
    };
  }, [dispatch]);
  useEffect(() => {
    const onSuccess = (caseDetail) => {
      
      if (
        caseDetail &&
        caseDetail.status &&
        caseDetail.status !== "Case Logged"
      ) {
        props.history.push(`/viewOnGoingcase/${caseDetail.id}`);
      } else {
        dispatch(onUpdateNewCreatedCase(caseDetail, () => {}));
      }
    };
    const onError = () => {
      props.history.push("/new-cases");
    };
    if (params.id) {
      const data = {
        caseId: params.id,
      };
      dispatch(onCaseDetail(data, onSuccess, onError));
    }
  }, [dispatch, params.id, props.history]);
  return (
    <>
      <div className="CaseDetailWrap">
        <TopBarNav />
        <Navigation />
        <CaseDetailHeader />
        <ViewCaseTabs />
      </div>
    </>
  );
};
export default Viewcases;
