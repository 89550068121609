import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Form, Row, Select, Input, Modal, Upload, message } from "antd";
import { useParams, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import {
  UploadOutlined
} from "@ant-design/icons";
import { submitFieldInvestigationResponse } from '../../../../redux/actions/FieldInvestigation';


const { Option } = Select;
const { TextArea } = Input;

export default function CoApplicantDetails() {
  const dispatch = useDispatch();
  const formData = new FormData();
  const caseData = useSelector((state) => state.fieldInvestigation.fiCaseDetails);
  const types = [
    "application/pdf",
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/x-zip-compressed",
    "application/vnd.ms-excel",
    "application/kset"
    
  ];
  const [fileList, setFileList] = useState([]);
  const [openOCR, setOpenOCR] = useState(false);
  const [openImage, setopenImage] = useState(false);
  const [imgurl, setimageurl] = useState("");
  const [fiSuccess, setFiSuccess] = useState(false);
  const [message, setMessage] = useState("");
  const [form] = Form.useForm();
  const params = useParams();

  const handleImage = (e, value) => {
    setopenImage(true);
    setimageurl(e);
  }

  const handleCancel = () => {
    setOpenOCR(false);
    setopenImage(false);
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
    },
    beforeUpload: (file) => {
      console.log(file.type);
      if (
        file.type === 'image/jpeg' ||
        file.type === 'image/png'||
        file.type === 'application/pdf' ||
        file.type === "application/x-zip-compressed"||
        file.type === "application/kset" ||
        file.type === "application/vnd.ms-excel"
      ) {
        setFileList([...fileList, file]); 
        return false;
      } else {
        toast.error(
          `Incorrect file format. Upload only pdf, png, jpg,excel or zip file.`
        );
        return false;
      }
    },
    onChange(info) {
      const listFiles = info.fileList;
      const files=listFiles.filter((file)=>{if(types.includes(file.type))
        return file})
      setFileList(files); 
    },
    fileList,  
  };
  console.log(caseData);
  const handleOnFinish = async (val) => {
    console.log(fileList);
    formData.append("fi_id", params.fiId);
    formData.append("case_id", caseData.id);
    fileList.forEach((file) => {
      formData.append("fi_report[]", file.originFileObj, file.name);
    });
    formData.append("remark", val.remark);
    formData.append("feedback_type", val.feedback_type);
    formData.append("fi_attempt_id", params.attemptId);
    formData.append("fi_agency_id", params.agencyId);
    formData.append("request_created_by", val.request_created_by);
    console.log(val);
    dispatch(submitFieldInvestigationResponse(formData, onSuccess))
  };
  const onSuccess = (response) => {
    console.log(response);
    setMessage("Your FI submitted Successfully !!")
    setFiSuccess(true);
  }
  const handleFileListChange = ({ fileList }) => {
    setFileList(fileList);
  };

  const handleRemove = (file) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  }
  const handlebeforeUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!types.includes(file.type) || !isLt2M) {
      if (!types.includes(file.type)) {
        message.error(`${file.name} is not a pdf,png,jpg file`);
      } else {
        toast.error("File must be smaller than 10 MB!");
      }
    }
    setFileList([file]);
    // setFileList(fileList);
    return false;
  }

  return (
    <>
      {caseData?.co_applicants.length === 0 ? <>
        <div className="card card-body">
          <h3 style={{ textAlign: "center" }}>No Co-Applicants</h3>
        </div>
      </> :
        <div className="card card-body">
          <div >
            <h5>Co Applicant</h5>
            <Row gutter={24}>
              <Col span={8} md={8} xs={16} sm={16}>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">First Name</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.first_name ? caseData?.co_applicants?.first_name : "-"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Mobile No</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.mobile_no ? caseData?.co_applicants?.mobile_no : "-"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">City</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.city ? caseData?.co_applicants?.city : "-"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Relation</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.co_applicants?.coapplicant_applicant_relation
                        ? caseData?.co_applicants?.coapplicant_applicant_relation
                        : "-"}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={8} md={8} xs={16} sm={16}>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Last Name</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.last_name ? caseData?.co_applicants?.last_name : "-"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Gender</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.gender ? caseData?.co_applicants?.gender : "-"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Postal Code</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.pin ? caseData?.co_applicants?.pin : "-"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Pan No.</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.pan_no ? caseData?.co_applicants?.pan_no : "-"}</p>
                  </Col>
                </Row>
              </Col>
              <Col span={8} md={8} xs={16} sm={16}>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">
                      Date of Birth
                    </p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.dob ? caseData?.co_applicants?.dob : "-"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">State</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>{caseData?.co_applicants?.state ? caseData?.co_applicants?.state : "-"}</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Address</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.co_applicants?.address_line1 ? caseData?.co_applicants?.address_line1 : "-"}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={8} md={8} xs={16} sm={16}>
                <h6 className="font-weight-bold">Documents</h6>
                {caseData?.co_applicants?.kyc_result.map((value, index) => {
                  return (
                    <div key={index}>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">
                            {value.document_name}
                          </p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{value.identity_no ? value.identity_no : "-"}</p>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Col>
              {/* <Col span={8} md={8} xs={16} sm={16}>
                <p className="font-weight-bold">
                  Uploaded Scanned Copy / Photo Of OCR Documents
                </p>
                {caseData?.co_applicants?.ocr_documents?.map((data, index) => {
                  return (
                    <div key={index}>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">
                            {data.document_name}
                          </p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p
                            id={data.document_path}
                            onClick={() => handleImage(data.document_path)}
                            style={{
                              color: "blue",
                            }}
                          >
                            <a> {data.document_name} </a>
                          </p>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Col> */}
              {/* <Col span={8} md={8} xs={16} sm={16}>
                <p className="font-weight-bold">
                  Uploaded Scanned Copy / Photo Of Non OCR Documents
                </p>
                {caseData?.co_applicants?.non_ocr_required_documents?.map((data, index) => {
                  return (
                    <div key={index}>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">
                            {data.document_name}
                          </p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p
                            id={data.document_path}
                            onClick={() => handleImage(data.document_path)}
                            style={{
                              color: "blue",
                            }}
                          >
                            <a>{data.document_name}</a>

                          </p>
                        </Col>
                      </Row>
                    </div>
                  );
                })}
              </Col> */}
              </Row>

              <div className="CaseDetailHeader">
              <div className="InnerWrap">
                <Row>
                  <Col span={4} xs={24} sm={24} md={12}>
                    <p className="CaseId">
                      Please Fill below details
                    </p>
                  </Col>
                </Row>
              </div>
            </div>
            <Row>
              <Form
                form={form}
                onFinish={handleOnFinish}
                layout="inline"
                autoComplete="off"
                name="basic"
                initialValues={{
                  remember: true,
                }}
                style={{ width: "100%" }}
              >


                <Col span={8} md={8} xs={16} sm={16}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold ">Remark</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <Form.Item
                        name="remark"
                        rules={[
                          {
                            required: true,
                            message: "Please Fill Review",
                          },
                        ]}
                      >
                        <TextArea rows={4} placeholder="Write Review" />
                      </Form.Item>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} md={8} xs={16} sm={16}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold ">Feedback Status</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <Form.Item

                        name="feedback_type"
                        rules={[
                          {
                            required: true,
                            message: "Please Select Status",
                          },
                        ]}
                      >
                        <Select placeholder="Select status">
                          <Option key="Positive" value="Positive"></Option>
                          <Option key="Negative" value="Negative"></Option>
                          <Option key="Refer" value="Refer"></Option>
                          <Option key="Check Comments" value="Check Comments"></Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold ">Upload FI Report</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                    <Form.Item
                        name="fi_report"
                        required={true}
                        rules={[
                          {
                            required: true,
                            message: "Please Upload FI report",
                          },
                        ]}
                      >
                        <Upload
                          {...props}
                          multiple={true}
                          accept=".pdf,.png,.jpeg,.zip,.xls" // Include '.zip' to accept zip files
                        >
                          <Button icon={<UploadOutlined />}>
                            Upload FI Report
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Form.Item
                  name="participant_id"
                  initialValue={caseData?.co_applicants?.id}
                  hidden={true}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  name="case_id"
                  initialValue={caseData?.id}
                  hidden={true}
                >
                  <Input type="text" />
                </Form.Item>
                <Form.Item
                  name="participant_type"
                  initialValue={caseData?.co_applicants?.applicant_type}
                  hidden={true}
                >
                  <Form.Item
                    name="request_created_by"
                    initialValue={caseData?.sales_allocated_to}
                    hidden={true}
                  ></Form.Item>
                  <Form.Item
                    name="fi_id"
                    initialValue={"1"}
                    hidden={true}
                  ></Form.Item>
                  <Form.Item
                    name="fi_agency_id"
                    initialValue={caseData?.co_applicants?.fi_details?.fi_agency_id}
                    hidden={true}
                  ></Form.Item>
                  <Input type="text" />
                </Form.Item>
                <Col span={8} md={8} xs={16} sm={16}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <Form.Item></Form.Item>
                    </Col>

                    <Col span={12} xs={24} sm={12}>
                      <Form.Item></Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <Form.Item></Form.Item>
                    </Col>

                    <Col span={12} xs={24} sm={12}>
                      <Button
                        htmlType="submit"
                        id={caseData?.co_applicants.id}
                        type="primary"
                        style={{
                          fontSize: "18px",
                          backgroundColor: "#202A44",
                          color: "#fff",
                          height: "38px",
                          width: "130px"
                        }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Row>
            <hr />
          </div>
          <Modal
            open={openImage}
            onCancel={handleCancel}
            centered
            footer={null}
            width={700}
            bodyStyle={{ height: 700 }}
          >
            {imgurl.endsWith('.pdf') ? (
              <iframe title="Preview" src={imgurl} style={{ width: '100%', height: '60vh', marginTop: "5%" }} />
            ) : (
              <img alt="Preview" style={{ width: '100%', height: '60vh' }} src={imgurl} />
            )}
          </Modal>
        </div>

      }
      {fiSuccess ? <Redirect to={{
        pathname: `/link/expired`,
        state: { message: message, color: "green" },
      }}>
      </Redirect > : <> </>}

    </>
  )
}
