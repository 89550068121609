import React, { Component, useEffect } from "react";
import TopBarNav from '../components/topbar'
import Navigation from '../components/navigation'
import ViewDataAsOn from '../components/viewdata'
import { connect, useDispatch } from "react-redux";
import { onDashboardData } from "../redux/actions/DashboardAction";
import { onCaseMasters } from "../redux/actions/MasterData";
// import { onCaseMasters, onDashboardData } from "../redux/actions/AuthAction";
const Home = (props)=> {
    // const { dashboardData } = props;
    const dispatch = useDispatch();
    useEffect(()=>{
      dispatch(onCaseMasters(()=>{}))
        dispatch(onDashboardData())
    },[dispatch])

    const onReset = () => {
      dispatch(onDashboardData())
    }
    const onFilter = (data) => {
      dispatch(onDashboardData(data))
    }
    return (
        <React.Fragment>
        <div className='dashBoardWrap'>
            <TopBarNav/>
            <Navigation/>
            <ViewDataAsOn onFilter={onFilter} reset={onReset}/>
        </div>
        
        </React.Fragment>
      )
}
const mapStateToProps = (store) => {
    const { auth } = store;
    return {
      isLoggedIn: auth.isLoggedIn,
      userData: auth.userData,
      dashboardData:auth.dashboardData?auth.dashboardData:{},
    };
  };
  export default connect(mapStateToProps, null)(Home);