import React from "react";
import { useSelector } from "react-redux";
import {
  isEicherUser,
  isVolvoUser,
  loggedInUserData,
} from "../../../helpers/utils";
import EicherCaseDetail from "../../eicher-case-detail";
import VolvoCaseDetail from "../../volvo-case-detail";
import CommonLoanDetail from "../common-loan-detail";
const LeadDetail = (props) => {
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  const userData = loggedInUserData();
  return (
    <React.Fragment>
      <div className="FormWrap">
        <div className="InfoCard">
          {isEicherUser(userData) && (
            <EicherCaseDetail caseDetail={caseDetail} />
          )}
          {isVolvoUser(userData) && <VolvoCaseDetail caseDetail={caseDetail} />}
        </div>
      </div>
    </React.Fragment>
  );
};
export default LeadDetail;
