import React, { useCallback, useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Upload,
  Button,
  Input,
  Card,
  Modal,
} from "antd";
import uplaodIcon from "../../../assets/images/uploadfile.png";
import NonOcrDocuments from "../../common/NonOcrDocuments";
import { connect, useDispatch, useSelector } from "react-redux";
import { REQUIRED_MESSAGES } from "../../../constants/messages";
import RunOcr from "../modals/runSingleOcr";
import { OCR_DOCUMENT_LABEL, RELATIONSHIPS } from "../../../constants/constants";
import CoApplicantList from "../../common/co-applicant/CoApplicantList";
import GuarantorList from "../../common/guarantor/Guarantor";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileNameFromUrl,
  fileTypeFromName,
} from "../../../helpers/utils";
import { useHistory } from "react-router-dom";
import { isCaseStatusLogged } from "../../common/helpers/helper";
import PreviewImageOrPDF from "../../common/modals/PreviewImageOrPDF";
import AddIndividual from "./AddIndividual";
import MemberOrDirectorListing from "./MemberOrDirectorListing";
import {
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../../redux/actions/CaseAction";
import {
  onAddParticipant,
  onResetParticipant,
} from "../../../redux/actions/ParticipantAction";
import { onDeleteNonOcrDocument } from "../../../redux/actions/NonOcrAction";
import { onRunOcr } from "../../../redux/actions/OcrAction";
import { onAddMemberOrDirector } from "../../../redux/actions/MemberAndDirectorAction";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;
const AddParticipant = (props) => {
  const { caseMasters,  newlyCreatedCase, toReset } = props;
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  
  
  const history = useHistory();
  const [runOcr, setRunOcr] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [relationship, setRelationship] = useState("");
  const [form] = Form.useForm();
  const [toRunOcrAlso, setToRunOcrAlso] = useState(false);
  const [nonOcrDocuments, setNonOcrDocuments] = useState([]);
  const [ocrResponse, setOcrResponse] = useState([]);
  const [defaultFileList, setDefaultFileList] = useState({
    pan_card: [],
    aadhar_card: [],
    driving_license: [],
    passport: [],
    voter_id: [],
    gst_registration: [],
  });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [showForm, setShowForm] = useState(true);
  // const [isEdit, setIsEdit] = useState(false);
  const [userId, setUserId] = useState("");
  const [applicantCategory, setApplicantCategory] = useState("");
  const [applicantSubCategory, setApplicantSubCategory] = useState("");
  const [isMemberOrDirectorButton, setIsMemberOrDirector] = useState(false);
  const [isMemberOrDirectorModalOpen, setMemberOrDirectorModalOpen] =
    useState(false);
  const [members, setMembers] = useState([]);
  const [savedMembers, setSavedMembers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [directors, setDirectors] = useState([]);
  const [fixedNonOcrDocs, setFixedNonOcrDocs] = useState({});
  const [memberErrorMessage, setMemberErrorMessage] = useState("");
  const dispatch = useDispatch();
  const deleteMemberOrDirector = (member)=>{
    
    if(member.isNew){
      setMembers((members)=>{
        let newMembers = members.filter((m)=>{return member.key !==m.key});
        return newMembers
      })
    }else{
      //delete api
    }
  }
  useEffect(() => {
    dispatch(onCaseDetail({ caseId: props.caseId }, (caseDetail) => {
      if(props.type==='Main Applicant'){
        setSavedMembers(caseDetail.main_applicant.member_director)
      }
    }));
  }, [props.caseId, dispatch,props.type]);
  const updateDefaultOcrDocs = (ocr_documents) => {
    let defaultFiles = {};
    ocr_documents.forEach((ocr_document, index) => {
      let uid = (index + 1).toString();
      let name = fileNameFromUrl(ocr_document.document_path);
      let fileType = fileTypeFromName(name);
      let url = ocr_document.document_path;
      let status = "done";
      let doc_array = [
        {
          uid,
          name,
          fileType,
          url,
          status,
        },
      ];
      if (ocr_document.document_name === OCR_DOCUMENT_LABEL.PAN_CARD) {
        defaultFiles = {
          ...defaultFiles,
          pan_card: doc_array,
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
        defaultFiles = {
          ...defaultFiles,
          aadhar_card: doc_array,
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE) {
        defaultFiles = {
          ...defaultFiles,
          driving_license: doc_array,
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.PASSPORT) {
        defaultFiles = {
          ...defaultFiles,
          passport: doc_array,
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.VOTER_ID) {
        defaultFiles = {
          ...defaultFiles,
          voter_id: doc_array,
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.GST_REGISTRATION) {
        defaultFiles = {
          ...defaultFiles,
          gst_registration: doc_array,
        };
      }
    });
    setDefaultFileList((defaultFileList) => {
      return { ...defaultFileList, ...defaultFiles };
    });
    // setFileList((defaultFileList) => {
    //   return { ...defaultFileList, ...defaultFiles };
    // });
  };
  const updateDefaultRequiredNonOcrDocs = (non_ocr_documents) => {
    
    let defaultFiles = {};
    non_ocr_documents.forEach((ocr_document, index) => {
      let uid = (index + 1).toString();
      let name = fileNameFromUrl(ocr_document.document_path);
      let fileType = fileTypeFromName(name);
      let url = ocr_document.document_path;
      let status = "done";
      let doc_array = [
        {
          uid,
          name,
          fileType,
          url,
          status,
        },
      ];
      defaultFiles = {
        ...defaultFiles,
        [ocr_document.document_name]: doc_array,
      };
    });
    
    setFixedNonOcrDocs((defaultFileList) => {
      return { ...defaultFileList, ...defaultFiles };
    });
  };
  const updatedDefaultNonOcrDocs = (non_ocr_documents) => {
    let nonOcrDocs = [];
    non_ocr_documents.forEach((non_ocr_document) => {
      nonOcrDocs = [
        ...nonOcrDocs,
        {
          name: non_ocr_document.document_name,
          doc: non_ocr_document.document_path,
          id: non_ocr_document.id,
        },
      ];
    });
    setNonOcrDocuments(nonOcrDocs);
  };
  const changeSubCategories = useCallback(
    (val, cb) => {
      if (
        caseMasters.subcategories &&
        Array.isArray(caseMasters.subcategories) &&
        caseMasters.subcategories.length > 0
      ) {
        // const filteredSubCategories = caseMasters.subcategories.filter(
        //   (subCategory) => {
        //     return subCategory.fk_category_id == val;
        //   }
        // );
        const filteredSubCategories = caseMasters.subcategories
          .filter((subCategory) => {
            return subCategory.fk_category_id.toString() === val.toString();
          })
          .map((subCategory) => {
            subCategory.fk_category_id = subCategory.fk_category_id.toString();
            return subCategory;
          });
        cb(filteredSubCategories);
      }
    },
    [caseMasters.subcategories]
  );
  useEffect(() => {
    //if tab is co-applicant or guarantor then applicant category default is individual
    if (
      caseMasters.categories &&
      Array.isArray(caseMasters.categories) &&
      caseMasters.categories.length > 0
    ) {
      if (props.type === "Co Applicant" || props.type === "Guarantor") {
        let defaultSelectedApplicantCategory =
          caseMasters.categories[0].id.toString();
        changeSubCategories(
          defaultSelectedApplicantCategory,
          (filteredSubCategories) => {
            setSubCategories(filteredSubCategories);
          }
        );
        form.setFieldsValue({
          applicant_category: defaultSelectedApplicantCategory,
        });
        setApplicantCategory(defaultSelectedApplicantCategory);
      }
    }
  }, [caseMasters.categories, form, props.type, changeSubCategories]);
  useEffect(() => {
    if (props.isNewCase && newlyCreatedCase) {
      if (props.type === "Main Applicant") {
        if (
          newlyCreatedCase &&
          newlyCreatedCase.main_applicant &&
          newlyCreatedCase.main_applicant.ocr_documents &&
          newlyCreatedCase.main_applicant.ocr_documents.length > 0
        ) {
          updateDefaultOcrDocs(newlyCreatedCase.main_applicant.ocr_documents);
        }
        if (
          newlyCreatedCase &&
          newlyCreatedCase.main_applicant &&
          newlyCreatedCase.main_applicant.non_ocr_documents &&
          newlyCreatedCase.main_applicant.non_ocr_documents.length > 0
        ) {
          updatedDefaultNonOcrDocs(
            newlyCreatedCase.main_applicant.non_ocr_documents
          );
        }
        if (
          newlyCreatedCase &&
          newlyCreatedCase.main_applicant &&
          newlyCreatedCase.main_applicant.non_ocr_required_documents &&
          newlyCreatedCase.main_applicant.non_ocr_required_documents.length > 0
        ) {
          updateDefaultRequiredNonOcrDocs(
            newlyCreatedCase.main_applicant.non_ocr_required_documents
          );
        }
      } else if (props.type === "Co Applicant") {
        if (
          newlyCreatedCase &&
          newlyCreatedCase.co_applicants &&
          newlyCreatedCase.co_applicants.length > 0
        ) {
          let co_applicant = newlyCreatedCase.co_applicants.find(
            (co_applicant) => {
              return co_applicant.id == userId;
            }
          );
          if (co_applicant) {
            updatedDefaultNonOcrDocs(co_applicant.non_ocr_documents);
          }
        }
      } else if (props.type === "Guarantor") {
        if (
          newlyCreatedCase &&
          newlyCreatedCase.guarantors &&
          newlyCreatedCase.guarantors.length > 0
        ) {
          let guarantor = newlyCreatedCase.guarantors.find((guarantor) => {
            return guarantor.id == userId;
          });
          if (guarantor) {
            updatedDefaultNonOcrDocs(guarantor.non_ocr_documents);
          }
        }
      }
    }
  }, [props.type, props.isNewCase, newlyCreatedCase, userId]);
  const KycCheck = (runOcr) => {
    setRunOcr(runOcr);
  };
  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "applicant_category") {
      form.setFieldsValue({ application_sub_category: undefined });
      setApplicantSubCategory("");
    } else if (formFieldName === "business_area") {
      form.setFieldsValue({ product_name: undefined });
    } else if (formFieldName === "product_name") {
      form.setFieldsValue({ product_model: undefined });
    }
  };
  useEffect(() => {
    if (toReset) {
      setShowForm(true);
      form.resetFields();
      setDefaultFileList([]);
      // setFileList([]);
      setNonOcrDocuments([]);
      // setFileList([]);
      setRelationship("");
      if (
        caseMasters &&
        caseMasters.categories &&
        Array.isArray(caseMasters.categories) &&
        caseMasters.categories.length > 0 &&
        caseMasters.categories[0].id
      ) {
        form.setFieldsValue({
          applicant_category: caseMasters.categories[0].id.toString(),
        });
        setApplicantCategory(caseMasters.categories[0].id.toString());
        changeSubCategories(
          caseMasters.categories[0].id.toString(),
          (filteredSubCategories) => {
            setSubCategories(filteredSubCategories);
          }
        );
      } else {
        form.setFieldsValue({
          applicant_category: undefined,
        });
        setApplicantCategory("");
        setSubCategories([]);
      }

      form.setFieldsValue({ application_sub_category: undefined });
      setApplicantSubCategory(undefined);
      dispatch(onResetParticipant(false));
    }
    if (
      caseDetail &&
      // props.mode === "upload_main_applicant_document" &&
      props.type === "Main Applicant"
    ) {
      form.setFieldsValue({
        applicant_category: caseDetail.fk_category_id.toString(),
      });
      setApplicantCategory(caseDetail.fk_category_id.toString());
      changeSubCategories(
        caseDetail.fk_category_id,
        (filteredSubCategories) => {
          setSubCategories(filteredSubCategories);
          const applicantSubCategory =
            filteredSubCategories.length > 0
              ? caseDetail.fk_subcategory_id.toString()
              : undefined;
          form.setFieldsValue({
            application_sub_category: applicantSubCategory,
          });
          setApplicantSubCategory(applicantSubCategory);
        }
      );
      
    }
  }, [
    caseDetail,
    props.mode,
    changeSubCategories,
    form,
    props.type,
    dispatch,
    toReset,
    caseMasters,
  ]);
  useEffect(() => {
    if (applicantCategory === "1") {
    }
    if (applicantCategory === "2") {
      setIsMemberOrDirector("director");
    } else if (applicantCategory === "3") {
      setIsMemberOrDirector("member");
    } else {
      setIsMemberOrDirector(false);
    }
  }, [applicantCategory]);
  useEffect(() => {
    if (applicantSubCategory) {
    }
  }, [applicantSubCategory]);
  const beforeUpload = (file, list, docName) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file)) {
        // setFileList({
        //   ...fileList,
        //   [docName]: file,
        // });
        setDefaultFileList({
          ...defaultFileList,
          [docName]: list,
        });
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const onRemoveOcrDoc = (fileName) => {
    setDefaultFileList({
      ...defaultFileList,
      [fileName]: [],
    });
  };

  const removeNonOcrDocuments = (deleteIndex) => {
    let docs = nonOcrDocuments.filter((doc, index) => {
      return index !== deleteIndex;
    });
    setNonOcrDocuments(docs);
  };
  const deleteNonOcrDocumentFromBackEnd = (deleteIndex) => {
    dispatch(onDeleteNonOcrDocument({ id: deleteIndex }, () => {}));
    if (props.type === "Main Applicant") {
      let updatedNonOcrDocs =
        newlyCreatedCase.main_applicant.non_ocr_documents.filter(
          (non_ocr_doc) => {
            return non_ocr_doc.id !== deleteIndex;
          }
        );
      let newlyCreatedCaseObj = {
        ...newlyCreatedCase,
        main_applicant: {
          ...newlyCreatedCase.main_applicant,
          non_ocr_documents: updatedNonOcrDocs,
        },
      };
      updateDefaultOcrDocs(updatedNonOcrDocs);
      dispatch(onUpdateNewCreatedCase(newlyCreatedCaseObj, () => {}));
    } else if (props.type === "Co Applicant") {
      let co_applicants = newlyCreatedCase.co_applicants.filter(
        (co_applicant) => co_applicant.id != userId
      );
      let co_applicant = newlyCreatedCase.co_applicants.find(
        (co_applicant) => co_applicant.id == userId
      );
      let updatedNonOcrDocs = co_applicant.non_ocr_documents.filter(
        (non_ocr_doc) => {
          return non_ocr_doc.id !== deleteIndex;
        }
      );
      co_applicant.non_ocr_documents = updatedNonOcrDocs;
      let newlyCreatedCaseObj = {
        ...newlyCreatedCase,
        co_applicants: [
          // ...newlyCreatedCase.co_applicants,
          ...co_applicants,
          co_applicant,
        ],
      };
      updateDefaultOcrDocs(updatedNonOcrDocs);
      dispatch(onUpdateNewCreatedCase(newlyCreatedCaseObj, () => {}));
    } else if (props.type === "Guarantor") {
      let guarantors = newlyCreatedCase.guarantors.filter(
        (guarantor) => guarantor.id != userId
      );
      let guarantor = newlyCreatedCase.guarantors.find(
        (guarantor) => guarantor.id == userId
      );
      let updatedNonOcrDocs = guarantor.non_ocr_documents.filter(
        (non_ocr_doc) => {
          return non_ocr_doc.id !== deleteIndex;
        }
      );
      guarantor.non_ocr_documents = updatedNonOcrDocs;
      let newlyCreatedCaseObj = {
        ...newlyCreatedCase,
        guarantors: [
          // ...newlyCreatedCase.guarantors,
          ...guarantors,
          guarantor,
        ],
      };
      updateDefaultOcrDocs(updatedNonOcrDocs);
      dispatch(onUpdateNewCreatedCase(newlyCreatedCaseObj, () => {}));
    }
  };
  const checkOcrFieldValidity = (fieldName, errorMessage) => {
    if (
      !defaultFileList[fieldName] ||
      !Array.isArray(defaultFileList[fieldName]) ||
      defaultFileList[fieldName].length === 0
    ) {
      form.setFields([
        {
          name: fieldName,
          errors: [errorMessage],
        },
      ]);
      return true;
    }
  };
  const checkNonOcrFieldValidity = (fieldName, label, errorMessage) => {
    
    
    if (
      !fixedNonOcrDocs[label] ||
      !Array.isArray(fixedNonOcrDocs[label]) ||
      fixedNonOcrDocs[label].length === 0
    ) {
      form.setFields([
        {
          name: fieldName,
          errors: [errorMessage],
        },
      ]);
      return true;
    }
  };
  const checkValidation = () => {
    let isFormValid = true;
    let isAadharCardInvalid,
      isGstInvalid,
      isBankStatementInvalid,
      isHufDeedInvalid,
      isMSMECertificateInvalid,
      isPartnershipDeedInvalid,
      isIncorporateCertificateInvalid,
      isShareholdingPatternInvalid,
      isTrustDeedInvalid,
      isSocietyDeedInvalid,
      customerConsentInvalid,
      isPanCardInvalid;
    isPanCardInvalid = checkOcrFieldValidity(
      "pan_card",
      REQUIRED_MESSAGES.PAN_CARD_REQUIRED
    );
    customerConsentInvalid = checkNonOcrFieldValidity(
      "customer_consent",
      "Customer Consent",
      REQUIRED_MESSAGES.CUSTOMER_CONSENT_REQUIRED
    );
    if (applicantCategory === "1") {
      isAadharCardInvalid = checkOcrFieldValidity(
        "aadhar_card",
        REQUIRED_MESSAGES.ADHAR_CARD_REQUIRED
      );
    } else if (applicantCategory !== "1") {
      isGstInvalid = checkOcrFieldValidity(
        "gst_registration",
        REQUIRED_MESSAGES.GST_REGISTRATION_REQUIRED
      );
      if (applicantCategory === "2") {
        if (applicantSubCategory) {
          if (applicantSubCategory === "1" || applicantSubCategory === "2") {
            isPartnershipDeedInvalid = checkNonOcrFieldValidity(
              "partnership_deed",
              "Partnership Deed",
              REQUIRED_MESSAGES.PARTNERSHIP_DEED_REQUIRED
            );
            isBankStatementInvalid = checkNonOcrFieldValidity(
              "bank_statement",
              "Bank Statement",
              REQUIRED_MESSAGES.BANK_STATEMENT_REQUIRED
            );
          } else if (
            applicantSubCategory === "3" ||
            applicantSubCategory === "4"
          ) {
            isBankStatementInvalid = checkNonOcrFieldValidity(
              "bank_statement",
              "Bank Statement",
              REQUIRED_MESSAGES.BANK_STATEMENT_REQUIRED
            );
            isIncorporateCertificateInvalid = checkNonOcrFieldValidity(
              "incorporate_certificate",
              "Incorporation Certificate",
              REQUIRED_MESSAGES.INCORPORATE_CERTIFICATE_REQUIRED
            );
            isShareholdingPatternInvalid = checkNonOcrFieldValidity(
              "shareholding_pattern",
              "Shareholding Pattern",
              REQUIRED_MESSAGES.SHAREHOLDING_PATTERN_REQUIRED
            );
          } else if (applicantSubCategory === "5") {
            isTrustDeedInvalid = checkNonOcrFieldValidity(
              "trust_deed",
              "Trust Deed",
              REQUIRED_MESSAGES.TRUST_DEED_REQUIRED
            );
          } else if (applicantSubCategory === "6") {
            isSocietyDeedInvalid = checkNonOcrFieldValidity(
              "society_deed",
              "Society Deed",
              REQUIRED_MESSAGES.SOCIETY_DEED_REQUIRED
            );
          }
        }
      } else if (applicantCategory === "3") {
        let nonOcrDocs = [
          {
            label: "Bank Statement",
            name: "bank_statement",
            errorMessage: REQUIRED_MESSAGES.BANK_STATEMENT_REQUIRED,
          },
          {
            label: "HUF Deed",
            name: "huf_deed",
            errorMessage: REQUIRED_MESSAGES.HUF_DEED_REQUIRED,
          },
        ];
        isBankStatementInvalid = checkNonOcrFieldValidity(
          "bank_statement",
          "Bank Statement",
          REQUIRED_MESSAGES.GST_REGISTRATION_REQUIRED
        );
        isHufDeedInvalid = checkNonOcrFieldValidity(
          "huf_deed",
          "HUF Deed",
          REQUIRED_MESSAGES.HUF_DEED_REQUIRED
        );
      } else if (applicantCategory === "4") {
        isBankStatementInvalid = checkNonOcrFieldValidity(
          "bank_statement",
          "Bank Statement",
          REQUIRED_MESSAGES.GST_REGISTRATION_REQUIRED
        );
        isMSMECertificateInvalid = checkNonOcrFieldValidity(
          "msme_certificate",
          "MSME CERTIFICATE",
          REQUIRED_MESSAGES.MSME_CERTIFICATE_REQUIRED
        );
      }
    }
    if (
      isAadharCardInvalid ||
      isGstInvalid ||
      isBankStatementInvalid ||
      isHufDeedInvalid ||
      isMSMECertificateInvalid ||
      isPartnershipDeedInvalid ||
      isIncorporateCertificateInvalid ||
      isShareholdingPatternInvalid ||
      isTrustDeedInvalid ||
      isSocietyDeedInvalid ||
      isPanCardInvalid ||
      customerConsentInvalid
    ) {
      isFormValid = false;
    }
    
    return isFormValid;
  };
  const checkDealerOrMember = (values) => {
    
    
    if (applicantCategory === "2" || applicantCategory === "3") {
      if(members.length<2 && savedMembers.length<2){
        
        setMemberErrorMessage(isMemberOrDirectorButton==='member'?'Please add atleast 2 members':'Please add atleast 2 directors');
        return;
      }
    }
    return true;
  };
  const saveMembersOrDirectors = (participant) => {
    let formDatas = [];
    members.forEach((member,index) => {
      const formData = new FormData();
      formData.append("fk_case_id", props.caseId); //77
      formData.append("applicant_type", props.type);
      formData.append(
        "member_type",
        isMemberOrDirectorButton === "member" ? "Member" : "Director"
      );
      formData.append("applicant_coapplicant_guarantor_id", participant.id);
      formData.append("member_director_id", "");
      formData.append("member_director_name", member.customer_name);
      if (Object.keys(member.ocrDocuments).length > 0) {
        Object.keys(member.ocrDocuments).forEach((doc) => {
          if (
            Array.isArray(member.ocrDocuments[doc]) &&
            member.ocrDocuments[doc].length > 0
          ) {
            if (member.ocrDocuments[doc][0] instanceof File) {
              formData.append(doc, member.ocrDocuments[doc][0]);
            }
          }
        });
      }
      member.fixedNonOcrDocuments.forEach((nonOcrDoc) => {
        
        if (nonOcrDoc.name && nonOcrDoc.doc) {
          formData.append("non_ocr_document_name[]", nonOcrDoc.name);
          formData.append("non_ocr_document[]", nonOcrDoc.doc);
          formData.append("non_ocr_document_required[]", "1");
        }
      });
      member.nonOcrDocuments.forEach((nonOcrDoc) => {
        if (nonOcrDoc.name && nonOcrDoc.doc.file) {
          formData.append("non_ocr_document_name[]", nonOcrDoc.name);
          formData.append("non_ocr_document[]", nonOcrDoc.doc.file);
          formData.append("non_ocr_document_required[]", "0");
        }
      });
      dispatch(
        onAddMemberOrDirector(formData, (responseData) => {
          
          dispatch(onCaseDetail({caseId:props.caseId},(caseDetail)=>{
            // dispatch(onUpdateNewCreatedCase(caseDetail))
          }))
          // if (responseData) {
          //   setMembers(members => (
          //     // Filter out the item with the matching index
          //     members.filter((value, i) => i !== index)
          //   ));
          // }
        })
      );
    });
  };
  const onFinish = (values) => {
    const isFormValid = checkValidation();
    if (!isFormValid) {
      return;
    }
    
    if (!checkDealerOrMember(values)) {
      
      return;
    }
    const formData = new FormData();
    formData.append("fk_case_id", props.caseId); //77
    formData.append("applicant_type", props.type);
    if (props.type === "Main Applicant") {
      formData.append("user_id", caseDetail.main_applicant.id);
      if (props.isNewCase && props.modalType !== "upload_document") {
        formData.append("fk_category_id", newlyCreatedCase.fk_category_id);
        formData.append(
          "fk_subcategory_id",
          newlyCreatedCase.fk_subcategory_id &&
            newlyCreatedCase.fk_subcategory_id != 0
            ? newlyCreatedCase.fk_subcategory_id
            : ""
        );
      } else {
        formData.append("fk_category_id", values.applicant_category);
        formData.append(
          "fk_subcategory_id",
          values.application_sub_category ? values.application_sub_category : ""
        );
      }
    } else {
      formData.append("fk_category_id", values.applicant_category);
      formData.append(
        "fk_subcategory_id",
        values.application_sub_category ? values.application_sub_category : ""
      );
      if (props.type === "Co Applicant") {
        formData.append("user_id", userId);
      } else {
        formData.append("user_id", userId);
      }
    }
    if (props.type === "Co Applicant") {
      formData.append("coapplicant_applicant_relation", values.relationship);
      formData.append(
        "other_relation",
        values.other_relation ? values.other_relation : ""
      );
    }
    if (Object.keys(defaultFileList).length > 0) {
      Object.keys(defaultFileList).forEach((doc) => {
        if (
          Array.isArray(defaultFileList[doc]) &&
          defaultFileList[doc].length > 0
        ) {
          if (defaultFileList[doc][0] instanceof File) {
            formData.append(doc, defaultFileList[doc][0]);
          }
        }
      });
    }
    // if (Object.keys(fileList).length > 0) {
    //   Object.keys(fileList).forEach((doc) => {
    //     if (Array.isArray(fileList[doc])) {
    //     } else {
    //       formData.append(doc, fileList[doc]);
    //     }
    //   });
    // }
    
    if (Object.keys(fixedNonOcrDocs).length > 0) {
      Object.keys(fixedNonOcrDocs).forEach((doc) => {
        if (
          Array.isArray(fixedNonOcrDocs[doc]) &&
          fixedNonOcrDocs[doc].length > 0
        ) {
          if (fixedNonOcrDocs[doc][0] instanceof File) {
            formData.append("non_ocr_document_name[]", doc);
            formData.append("non_ocr_document[]", fixedNonOcrDocs[doc][0]);
            formData.append("non_ocr_document_required[]", "1");
          }
        }
      });
    }
    nonOcrDocuments.forEach((nonOcrDoc) => {
      if (nonOcrDoc.name && nonOcrDoc.doc.file) {
        formData.append("non_ocr_document_name[]", nonOcrDoc.name);
        formData.append("non_ocr_document[]", nonOcrDoc.doc.file);
        formData.append("non_ocr_document_required[]", "0");
      }
    });
      
  
    dispatch(
      onAddParticipant(formData, async (response) => {
        
        if (applicantCategory === "2" || applicantCategory === "3") {
           saveMembersOrDirectors(response);
          // dispatch(onCaseDetail({caseId:props.caseId},(caseDetail)=>{
          //   dispatch(onUpdateNewCreatedCase(caseDetail))
          // }))
        }
        if (toRunOcrAlso) {
          let data = {
            fk_case_id: props.caseId,
            applicant_type: props.type,
            applicant_coapplicant_guarantor_id: response.id,
          };
          setRunOcr(true);
          dispatch(
            onRunOcr(data, (response) => {
              setOcrResponse(response);
            })
          );
          setToRunOcrAlso(false);
        }
        setMembers([])
        if (props.isNewCase) {
          let updatedParticipant = {};
          if (props.type === "Main Applicant") {
            updatedParticipant.main_applicant = response;
          } else {
            if (props.type === "Co Applicant") {
              let isAdded = newlyCreatedCase.co_applicants.find(
                (co_applicant) => co_applicant.id === response.id
              );
              if (isAdded) {
                updatedParticipant.co_applicants =
                  newlyCreatedCase.co_applicants.map((co_applicant) => {
                    if (co_applicant.id === response.id) {
                      return response;
                    } else return co_applicant;
                  });
              } else {
                updatedParticipant.co_applicants = [
                  ...newlyCreatedCase.co_applicants,
                  response,
                ];
              }
            } else {
              let isAdded = newlyCreatedCase.guarantors.find(
                (guarantor) => guarantor.id === response.id
              );
              if (isAdded) {
                updatedParticipant.guarantors = newlyCreatedCase.guarantors.map(
                  (guarantor) => {
                    if (guarantor.id === response.id) {
                      return response;
                    } else return guarantor;
                  }
                );
              } else {
                updatedParticipant.guarantors = [
                  ...newlyCreatedCase.guarantors,
                  response,
                ];
              }
            }
            form.resetFields();
            setDefaultFileList([]);
            // setShowForm(false);
          }

          dispatch(
            onUpdateNewCreatedCase(
              {
                ...newlyCreatedCase,
                ...updatedParticipant,
              },
              () => {}
            )
          );
        }
        if (
          props.modalType === "add_new_coapplicant" ||
          props.modalType === "add_new_guarantor"
        ) {
          setDefaultFileList([]);
          form.resetFields();
          form.setFieldsValue({
            applicant_category: "1",
          });
          setApplicantCategory("1");
          props.closeModal(response);
        }
        if (props.changeUploaded) {
          props.changeUploaded();
        }
      })
    );
  };
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (file.type === "application/pdf") {
          setPreviewPdf(reader.result);
        } else {
          setPreviewImage(reader.result);
        }
      };
    } else {
      if (file.fileType === "pdf") {
        setPreviewPdf(file.url);
      } else {
        setPreviewImage(file.url || file.preview);
      }
    }
    setPreviewVisible(true);
  };
  useEffect(() => {
    if (props.type === "Co Applicant" && newlyCreatedCase) {
      if (
        newlyCreatedCase.co_applicants &&
        newlyCreatedCase.co_applicants.length > 0
      ) {
        setShowForm(false);
      }
    } else if (props.type === "Guarantor" && newlyCreatedCase) {
      if (
        newlyCreatedCase.guarantors &&
        newlyCreatedCase.guarantors.length > 0
      ) {
        setShowForm(false);
      }
    }
  }, [props.type, newlyCreatedCase]);

  const handleEdit = (applicant) => {
    setShowForm(true);
    // setIsEdit(true);
    setUserId(applicant.id);

    changeSubCategories(applicant.fk_category_id, (filteredSubCategories) => {
      setSubCategories(filteredSubCategories);
    });
    const applicantCategory = applicant.fk_category_id.toString();
    const applicantSubCategory =
      applicant.fk_subcategory_id && applicant.fk_subcategory_id !== "undefined"
        ? applicant.fk_subcategory_id.toString()
        : undefined;
    form.setFieldsValue({
      applicant_category: applicantCategory,
      application_sub_category: applicantSubCategory,
    });
    setApplicantCategory(applicantCategory);
    setApplicantSubCategory(applicantSubCategory);
    if (props.type === "Co Applicant") {
      setRelationship(applicant.coapplicant_applicant_relation);
      form.setFieldsValue({
        relationship: applicant.coapplicant_applicant_relation,
        other_relation: applicant.other_relation,
        // application_sub_category:applicant.fk_subcategory_id
      });
    }
    setDefaultFileList([]);
    // setFileList([]);
    updateDefaultOcrDocs(applicant.ocr_documents);
  };
  const resetForm = () => {
    setShowForm(true);
    setUserId("");
    setDefaultFileList([]);
    // setFileList([]);
    form.resetFields();
    form.setFieldsValue({
      applicant_category: "1",
    });
    setApplicantCategory("1");
    updatedDefaultNonOcrDocs([]);
  };
  const GetPanCardField = () => {
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item label="PAN Card" name="pan_card" required={true}>
          <Upload
            maxCount={1}
            // accept=".png,.jpeg"
            fileList={defaultFileList.pan_card}
            beforeUpload={(file, fileList, e) => {
              form.setFields([
                {
                  name: "pan_card",
                  errors: [],
                },
              ]);
              const result = beforeUpload(file, fileList, "pan_card");
              if (result) {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            name="pan_card"
            onRemove={() => {
              onRemoveOcrDoc("pan_card");
            }}
            onPreview={handlePreview}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
        {/*<Form.Item label="PAN Card" name="pan">
              <Upload maxCount={1}>
                <Button>
                  Upload <img src={uplaodIcon} alt="uplaodIcon" />
                </Button>
              </Upload>
            </Form.Item>*/}
      </Col>
    );
  };
  const GetDrivingLicenseField = () => {
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item label={OCR_DOCUMENT_LABEL.DRIVING_LICENSE} name="driving_license">
          <Upload
            maxCount={1}
            fileList={defaultFileList.driving_license}
            beforeUpload={(file, fileList) => {
              const result = beforeUpload(file, fileList, "driving_license");
              if (result) {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            onRemove={() => {
              onRemoveOcrDoc("driving_license");
            }}
            name="driving_license"
            onPreview={handlePreview}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const GetAadharCardField = () => {
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item
          label="Aadhar Card"
          name="aadhar_card"
          required={true}
          // rules={[
          //   {
          //     required: true,
          //     message: REQUIRED_MESSAGES.ADHAR_CARD_REQUIRED,
          //   },
          // ]}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file, fileList) => {
              form.setFields([
                {
                  name: "aadhar_card",
                  errors: [],
                },
              ]);
              const result = beforeUpload(file, fileList, "aadhar_card");
              if (result) {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            onPreview={handlePreview}
            onRemove={() => {
              onRemoveOcrDoc("aadhar_card");
            }}
            name="aadhar_card"
            fileList={defaultFileList.aadhar_card}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const GetVoterIdField = () => {
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item label="Voter ID" name="voter_id">
          <Upload
            maxCount={1}
            beforeUpload={(file, fileList) => {
              const result = beforeUpload(file, fileList, "voter_id");
              if (result) {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            fileList={defaultFileList.voter_id}
            onRemove={() => {
              onRemoveOcrDoc("voter_id");
            }}
            onPreview={handlePreview}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const GetPassportField = () => {
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item label="Passport" name="passport">
          <Upload
            maxCount={1}
            fileList={defaultFileList.passport}
            beforeUpload={(file, fileList) => {
              const result = beforeUpload(file, fileList, "passport");
              if (result) {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            onRemove={() => {
              onRemoveOcrDoc("passport");
            }}
            onPreview={handlePreview}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const GetGstRegistrationField = () => {
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item label="GST Registration" name="gst_registration" required>
          <Upload
            maxCount={1}
            fileList={defaultFileList.gst_registration}
            beforeUpload={(file, fileList, e) => {
              form.setFields([
                {
                  name: "gst_registration",
                  errors: [],
                },
              ]);
              const result = beforeUpload(file, fileList, "gst_registration");
              if (result) {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            onRemove={() => {
              onRemoveOcrDoc("gst_registration");
            }}
            onPreview={handlePreview}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  
  return (
    <>
      {props.isNewCase &&
        props.type === "Co Applicant" &&
        newlyCreatedCase.co_applicants.length > 0 && (
          <React.Fragment>
            <div className="HeaderPanelAction">
              <h3></h3>
              <div className="ButtonWrap">
                {showForm && (
                  <Button
                    className="plane"
                    onClick={() => {
                      setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button onClick={resetForm}>Add New Co-Applicant</Button>
              </div>
            </div>
            <CoApplicantList
              coApplicants={newlyCreatedCase.co_applicants}
              handleEdit={handleEdit}
            />
          </React.Fragment>
        )}

      {props.isNewCase &&
        props.type === "Guarantor" &&
        newlyCreatedCase.guarantors.length > 0 && (
          <React.Fragment>
            <div className="HeaderPanelAction">
              <h3></h3>
              <div className="ButtonWrap">
                {showForm && (
                  <Button
                    className="plane"
                    onClick={() => {
                      setShowForm(false);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button onClick={resetForm}>Add New Guarantor</Button>
              </div>
            </div>
            <GuarantorList
              guarantors={newlyCreatedCase.guarantors}
              handleEdit={handleEdit}
            />
          </React.Fragment>
        )}
      {showForm && (
        <Form
          {...layout}
          name="mainapplicant"
          form={form}
          onValuesChange={handleFormValuesChange}
          onFinish={onFinish}
        >
          <Row gutter={32}>
            {(props.type === "Co Applicant" ||
              props.type === "Guarantor" ||
              props.mode === "upload_main_applicant_document") && (
              // ||props.type === "Main Applicant"
              <React.Fragment>
                <Col span={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    label={props.type + " type"}
                    name="applicant_category"
                    rules={[
                      {
                        required: true,
                        message:
                          REQUIRED_MESSAGES.APPLICATION_CATEGORY_REQUIRED,
                      },
                    ]}
                    className="TopTabBox"
                  >
                    <Select
                      // defaultValue={caseDetail && props.type==='Main Applicant' && (caseDetail.fk_category_id).toString()}
                      // value={applicationCategory}
                      placeholder="Please Select Co-Applicant Type"
                      onSelect={(val) => {
                        changeSubCategories(val, (filteredSubCategories) => {
                          setSubCategories(filteredSubCategories);
                        });
                        form.setFields([
                          {
                            name: "application_sub_category",
                            errors: [],
                          },
                        ]);
                      }}
                      onChange={(val) => {
                        setApplicantCategory(val.toString());
                      }}
                    >
                      {caseMasters.categories &&
                        caseMasters.categories.map((category, index) => {
                          return (
                            <Option
                              value={category.id.toString()}
                              key={category.id}
                            >
                              {category.category_name}
                            </Option>
                          );
                        })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    label="Sub Category"
                    name="application_sub_category"
                    rules={[
                      {
                        required: subCategories.length !== 0,
                        message:
                          REQUIRED_MESSAGES.APPLICATION_SUB_CATEGORY_REQUIRED,
                      },
                    ]}
                    className="TopTabBox"
                  >
                    <Select
                      placeholder={"Please select sub category"}
                      disabled={subCategories.length === 0}
                      onChange={(val) => {
                        setApplicantSubCategory(val.toString());
                      }}
                      // defaultValue={caseDetail && props.type==='Main Applicant'}
                    >
                      {subCategories.map((subCategory) => (
                        <Option
                          value={subCategory.id.toString()}
                          key={subCategory.id.toString()}
                        >
                          {subCategory.subcategory_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </React.Fragment>
            )}
            {props.type === "Co Applicant" && (
              <React.Fragment>
                <Col span={12} md={12} sm={12} xs={24}>
                  <Form.Item
                    label="Relationship"
                    name="relationship"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.RELATIONSHIP_REQUIRED,
                      },
                    ]}
                    className="TopTabBox relapp"
                  >
                    <Select
                      placeholder={"Relationship with applicant"}
                      onSelect={(val) => {
                        setRelationship(val);
                      }}
                    >
                      {RELATIONSHIPS.map((relation) => (
                        <Option value={relation} key={relation}>
                          {relation}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {relationship === "Other" && (
                  <Col span={12} md={12} sm={12} xs={24}>
                    <Form.Item
                      label="Other"
                      name="other_relation"
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGES.OTHER_RELATION_REQUIRED,
                        },
                      ]}
                    >
                      <Input placeholder="relationship" />
                    </Form.Item>
                  </Col>
                )}
              </React.Fragment>
            )}
            <Col span={24} md={24} xs={24} sm={24}>
              <h4>OCR Compatible Documents</h4>
            </Col>
            {GetPanCardField()}
            {applicantCategory !== "1" && GetGstRegistrationField()}
            {applicantCategory === "1" && (
              <React.Fragment>
                <GetDrivingLicenseField />
                <GetAadharCardField />
                <GetVoterIdField />
                <GetPassportField />
              </React.Fragment>
            )}
          </Row>
          <NonOcrDocuments
            form={form}
            setNonOcrDocuments={setNonOcrDocuments}
            removeNonOcrDocuments={removeNonOcrDocuments}
            nonOcrDocuments={nonOcrDocuments}
            handlePreview={handlePreview}
            applicantCategory={applicantCategory}
            applicantSubCategory={applicantSubCategory}
            fixedNonOcrDocs={fixedNonOcrDocs}
            setFixedNonOcrDocs={setFixedNonOcrDocs}
            deleteNonOcrDocumentFromBackEnd={deleteNonOcrDocumentFromBackEnd}
          />
          {isMemberOrDirectorButton && (
            <div className="HeaderPanelAction">
              <h3></h3>
              <div>
                <Button
                  onClick={() => {
                    if([...savedMembers,...members].length>=5){
                      setMemberErrorMessage(`Maximum 5 ${isMemberOrDirectorButton === "member"?'members':'directors'} can be added`);
                    }else{
                      setMemberErrorMessage("");
                    setMemberOrDirectorModalOpen(true);
                    }
                    
                  }}
                  className="AddField "
                >
                  {isMemberOrDirectorButton === "member"
                    ? "Add Member Details"
                    : "Add Director Details"}
                </Button>
              </div>
            </div>
          )}
          {/*{members.length > 0 && (
            <MemberOrDirectorListing
              members={members}
              isMemberOrDirectorButton={isMemberOrDirectorButton}
            />
          )}*/}
          {props.type === "Main Applicant" && (
            <MemberOrDirectorListing
              members={[...savedMembers,...members]}
              isMemberOrDirectorButton={isMemberOrDirectorButton}
              deleteMemberOrDirector={deleteMemberOrDirector}
            />
          )}
          {props.type === 'Co Applicant' && <MemberOrDirectorListing
          members={[...savedMembers,...members]}
          isMemberOrDirectorButton={isMemberOrDirectorButton}
          deleteMemberOrDirector={deleteMemberOrDirector}
        />}
          {memberErrorMessage && <p>{memberErrorMessage}</p>}
          <div className="MainBtnWrap">
            {/*<Button className="plane">Cancel</Button>*/}
            {/*{props.type === "Co Applicant" &&
              props.isNewCase &&
              newlyCreatedCase.co_applicants.length > 0 &&
              showForm && (
                <Button
                  className="plane"
                  onClick={() => {
                    setShowForm(false);
                  }}
                >
                  Cancel1
                </Button>
                )}*/}
            {/*{props.type === "Guarantor" &&
              props.isNewCase &&
              newlyCreatedCase.guarantors.length > 0 &&
              showForm && (
                <Button
                  className="plane"
                  onClick={() => {
                    setShowForm(false);
                  }}
                >
                  Cancel
                </Button>
                )}*/}
            {(props.modalType === "upload_document" ||
              props.modalType === "add_new_coapplicant" ||
              props.modalType === "add_new_guarantor") && (
              <Button
                className="plane"
                onClick={() => {
                  setShowForm(true);
                  props.closeUploadDocumentsModalHandler();
                }}
              >
                Cancel
              </Button>
            )}
            {props.modalType === "upload_document" && (
              <Button
                onClick={() => {
                  props.handleTabChange(
                    (parseInt(props.uploadDocumentActiveKey) + 1).toString()
                  );
                }}
              >
                Next
              </Button>
            )}
            {props.isNewCase &&
              (props.modalType
                ? props.modalType !== "upload_document"
                : true) && (
                <Button
                  className="plane"
                  onClick={() => {
                    if (isCaseStatusLogged(caseDetail)) {
                      history.push("/new-cases");
                    } else {
                      history.push("/OnGoingCases");
                    }
                  }}
                >
                  Cancel
                </Button>
              )}
            <Button
              className="SaveBtn"
              htmlType="submit"
              type="primary"
              onClick={() => {
                checkValidation();
                // checkAadharCardValidity();
                // checkPanCardValidity();
              }}
            >
              Save
            </Button>
            <Button
              className="filled"
              onClick={() => {
                if (checkValidation()) {
                  setToRunOcrAlso(true);
                }
                // setRunOcr(true);
              }}
              htmlType="submit"
            >
              Save and Run OCR
            </Button>
          </div>
        </Form>
      )}

      {/* Run KYC */}
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={runOcr}
        onOk={() => KycCheck(false)}
        onCancel={() => KycCheck(false)}
        footer={null}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunOcr ocrResponse={ocrResponse} />
      </Modal>
      <PreviewImageOrPDF
        previewVisible={previewVisible}
        previewImage={previewImage}
        previewPdf={previewPdf}
        onCancel={() => {
          setPreviewImage(false);
          setPreviewPdf(false);
          setPreviewVisible(false);
        }}
      />
      <Modal
        title={
          isMemberOrDirectorButton === "member"
            ? "Add HUF Member Details"
            : "Add Director Details"
        }
        centered
        visible={isMemberOrDirectorModalOpen}
        // onOk={() => setModal2Visible(false)}
        onCancel={() => setMemberOrDirectorModalOpen(false)}
        footer={null}
        width={1000}
        className="ModalFormWrap AddCoapplicant"
        style={{ top: 50 }}
        destroyOnClose={true}
      >
        <AddIndividual
          members={members}
          setMembers={(member) => {
            setMembers((members) => {
              return [...members, {...member,isNew:true}];
            });
            setMemberOrDirectorModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
    isCaseSaved: auth.isNewlyCreatedCaseSaved,
    savedCase: auth.newlyCreatedCase,
    mainApplicant: auth.mainApplicant,
    coApplicant: auth.coApplicant,
    gurantor: auth.gurantor,
    caseDetail: auth.caseDetail,
    newlyCreatedCase: auth.newlyCreatedCase,
    toReset: auth.toReset,
    uploadDocumentActiveKey: auth.uploadDocumentActiveKey,
  };
};
export default connect(mapStateToProps, null)(AddParticipant);
