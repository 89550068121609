import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Input,
  Modal,
  Upload,
  message,
} from "antd";
import { toast } from "react-toastify";
import { RunRequestBoxModel } from "..//..//RequestModels/RunRequestBoxModel";

export default function MainApplicantDetails(viewlabel) {
  const dispatch = useDispatch();
  const caseData = useSelector((state) => state.fieldInvestigation.fiCase);
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState([]);
  const [caseRow, setCaseRow] = useState("");
  const [alertBoxVisible, setalertBoxVisible] = useState(false);

  const sendRequest = (e) => {
    setCaseRow(e);
    setalertBoxVisible(true);
  };

  const deleteFieldModelCancel = () => {
    setalertBoxVisible(false);
  };
  return (
    <>
      {caseData?.main_applicant === undefined ? (
        <>
          <div className="card card-body">
            <h3 style={{ textAlign: "center" }}>No Main Applicant</h3>
          </div>
        </>
      ) : (
        <div id="mainapplicant">
          <div className=" card card-body">
            <Row gutter={24}>
              <Col span={6} md={6} xs={16} sm={16}>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">First Name</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.first_name
                        ? caseData?.main_applicant?.first_name
                        : "-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Mobile No</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.mobile_no
                        ? caseData?.main_applicant?.mobile_no
                        : "-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">City</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.city
                        ? caseData?.main_applicant?.city
                        : "-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Email</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.email
                        ? caseData?.main_applicant?.email
                        : "-"}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} md={6} xs={16} sm={16}>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Last Name</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.last_name
                        ? caseData?.main_applicant?.last_name
                        : "-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Gender</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.gender
                        ? caseData?.main_applicant?.gender
                        : "-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Postal Code</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.pin
                        ? caseData?.main_applicant?.pin
                        : "-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Pan No.</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.pan_no
                        ? caseData?.main_applicant?.pan_no
                        : "-"}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} md={6} xs={16} sm={16}>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Date of Birth</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.dob
                        ? caseData?.main_applicant?.dob
                        : "-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">State</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.state
                        ? caseData?.main_applicant?.state
                        : "-"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <p className="font-weight-bold">Address</p>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>
                      {caseData?.main_applicant?.address_line1
                        ? caseData.main_applicant.address_line1
                        : "-"}
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} md={6} xs={16} sm={16}>
                <h6 className="font-weight-bold ">Documents</h6>
                {caseData?.main_applicant?.kyc_result.map((val) => {
                  return (
                    <>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">
                            {val.document_name}
                          </p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.identity_no ? val.identity_no : "-"}</p>
                        </Col>
                      </Row>
                    </>
                  );
                })}
              </Col>
              <Col span={6} md={6} xs={16} sm={16}>
                {caseData?.main_applicant?.fi_status === 0 && (
                  <Button
                    className="AddField"
                    //setCaseId={row.case_id}
                    title="Send Request"
                    //disabled
                    style={{ marginRight: "-950px", float: "right" }}
                    onClick={(e) =>
                      sendRequest([
                        caseData.id,
                        caseData.main_applicant.id,
                        caseData.main_applicant.applicant_type,
                        "1",
                        caseData.main_applicant.first_name,
                        viewlabel,
                        "",
                      ])
                    }
                  >
                    Send Request
                  </Button>
                )}
                {/* {((caseData?.main_applicant?.fi_details?.length > 0) && (caseData?.main_applicant?.fi_details[0]?.feedback_type === 'Refer') || (caseData?.main_applicant?.fi_details[0]?.feedback_type === 'Negative')) && <Button
                  className="AddField"
                  //setCaseId={row.case_id}
                  title="Re-send Request"
                  //disabled
                  style={{ marginRight: "-950px", float: "right" }}
                  onClick={e => sendRequest([caseData.id, caseData.main_applicant.id,
                  caseData.main_applicant.applicant_type, "2", caseData.main_applicant.first_name, viewlabel, ""])}
                >
                  Re-send Request
                </Button>} */}
                {(caseData?.main_applicant?.fi_status === 1 || caseData?.main_applicant?.fi_status === 2) && (
                    <Button
                      className="AddField"
                      title="Re-send Request"
                      style={{ marginRight: "-950px", float: "right" }}
                      onClick={(e) =>
                        sendRequest([
                          caseData.id,
                          caseData.main_applicant.id,

                          caseData.main_applicant.applicant_type,
                          "2",
                          caseData.main_applicant.first_name,
                          viewlabel,
                          "",
                        ])
                      }
                    >
                      Re-send Request
                    </Button>
                  )}

                {/* {(caseData?.main_applicant?.fi_details[0]?.feedback_type != 'Refer' && caseData?.main_applicant?.fi_details[0]?.feedback_type != 'Negative') &&<p
                  title="Completed"
                  style={{ marginRight: "-950px", float: "right", color: "green", fontSize: "20px" }}
                >
                  Completed 
                </p>} */}
                {caseData?.main_applicant?.fi_status === 3 && (
                  <p
                    title="Not Started"
                    style={{
                      marginRight: "-950px",
                      float: "right",
                      color: "green",
                      fontSize: "20px",
                    }}
                  >
                    Completed
                  </p>
                )}
              </Col>
            </Row>
          </div>
        </div>
      )}
      <RunRequestBoxModel
        deleteVisible={alertBoxVisible}
        deleteCancel={deleteFieldModelCancel}
        caseRow={caseRow}
        caseStatus={caseRow[3]}
        name={caseRow[4]}
        viewlabel={caseRow[5]}
        index={caseRow[6]}
        closeModal={() => {
          setalertBoxVisible(false);
        }}
      />
    </>
  );
}
