import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { OCR_DOCUMENT_LABEL } from "../../../../../constants/constants";
const GSTRegistration = () => {
  const ocrResult = useSelector(state => state.auth.currentOcrResult);
  
  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  
  const [gstOcrResult, setGSTOcrResult] = useState({});
  const [gstKycResult, setGSTKycResult] = useState({});
  
  useEffect(() => {
    let gstDetails = ocrResult.find((ocr_result)=>{
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.GST_REGISTRATION
    });
    if(gstDetails){
      setGSTOcrResult(gstDetails);
    }
  }, [ocrResult])
  useEffect(() => {
    let gstKyc = kycResult.find((ocr_result)=>{
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.GST_REGISTRATION
    });
    if(gstKyc){
      setGSTKycResult(gstKyc)
    }
  }, [kycResult])
  return (
    <>
      <div className="CardBlock">
        <h3>GST Number Details</h3>
        <Card>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>GST Number</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(gstOcrResult && gstOcrResult.gst_registration_no)? gstOcrResult.gst_registration_no : "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Organization Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(gstOcrResult && gstOcrResult.organization_name) ? gstOcrResult.organization_name: "-"}</p>
            </Col>
          </Row>
          
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Address</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(gstOcrResult && gstOcrResult.address)? gstOcrResult.address:'-'}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Date of Incorporation</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {(gstOcrResult &&
                  gstOcrResult.date_of_incorporation)?gstOcrResult.date_of_incorporation:'-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Address Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {gstKycResult &&
                gstKycResult.address_matching_result?gstKycResult.address_matching_result:'-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Date of Incorporation Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {gstKycResult &&
                gstKycResult.dob_matching_result?gstKycResult.dob_matching_result:'-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Organization Name Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {gstKycResult &&
                gstKycResult.name_matching_result?gstKycResult.name_matching_result:'-'}
              </p>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default GSTRegistration;
