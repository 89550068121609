import React, { Component } from "react";
import { Row, Col, Form, Input, Button, Checkbox, Select, Radio } from "antd";
const { Option } = Select;
class BureauReportForm extends Component {
  render() {
    return (
      <>
        <div className="BureauFormWrap">
          <Form
            layout="vertical"
            name="basic"
            initialValues={{ remember: true }}
          >
            <Row gutter={32}>
              <Col span={12} md={12} xs={24} sm={12}>
                <Form.Item label="General Report For" name="generalreport">
                  <Checkbox>Applicant</Checkbox>
                  <Checkbox>Co - Applicant</Checkbox>
                  <Checkbox>Guarantor</Checkbox>
                </Form.Item>
                <Form.Item className='applicantName'>
                    <Select defaultValue="Avichal ">
                    <Option value="Sunil">Sunil</Option>
                    </Select>
                </Form.Item>
               
                <Form.Item
                  label="Select Report Generation Parameter"
                  name="genparam"
                >
                  <p>Select Bureau</p>
                  <Checkbox>Applicant</Checkbox>
                  <Checkbox>Co - Applicant</Checkbox>
                  <Checkbox>Guarantor</Checkbox>
                </Form.Item>
                <Form.Item name='parambasis'>
                    <p>Select the parameter basis which report needs to be fetched</p>
                  <Row>
                    <Col span={8} md={8} sm={24} xs={24}>
                      <label>Enquiry Amount</label>
                    </Col>
                    <Col span={16} md={16} sm={24} xs={24}>
                      <Input />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={8} md={8} sm={24} xs={24}>
                      <label>Purpose</label>
                    </Col>
                    <Col span={16} md={16} sm={24} xs={24}>
                      <Select defaultValue="">
                        <Option value="Purpose">Purpose</Option>
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={8} md={8} sm={24} xs={24}>
                      <label>Name as mentioned on</label>
                    </Col>
                    <Col span={16} md={16} sm={24} xs={24}>
                      <Select defaultValue="PAN Card">
                        <Option value="PAN Card">PAN Card</Option>
                        <Option value="DL">DL</Option>
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                <Radio.Group>
                  <Radio value={1}>Male</Radio>
                  <Radio value={2}>Female</Radio>
                </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={8} md={8} sm={24} xs={24}>
                      <label>Identification</label>
                    </Col>
                    <Col span={16} md={16} sm={24} xs={24}>
                      <Select defaultValue="PAN Card">
                        <Option value="PAN Card">PAN Card</Option>
                        <Option value="DL">DL</Option>
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={8} md={8} sm={24} xs={24}>
                      <label>Phone no</label>
                    </Col>
                    <Col span={16} md={16} sm={24} xs={24}>
                      <Input />
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={8} md={8} sm={24} xs={24}>
                      <label>Address</label>
                    </Col>
                    <Col span={16} md={16} sm={24} xs={24}>
                      <Select defaultValue="As Mention On Aadhar Card">
                        <Option value="As Mention On Aadhar Card">
                          As Mention On Aadhar Card
                        </Option>
                        <Option value="As Mention On DL">
                          As Mention On DL
                        </Option>
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={8} md={8} sm={24} xs={24}>
                      <label>State</label>
                    </Col>
                    <Col span={16} md={16} sm={24} xs={24}>
                      <Select defaultValue="MP">
                        <Option value="MP">MP</Option>
                        <Option value="UP">UP</Option>
                        <Option value="HP">HP</Option>
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col span={8} md={8} sm={24} xs={24}>
                      <label>Category</label>
                    </Col>
                    <Col span={16} md={16} sm={24} xs={24}>
                      <Select defaultValue="Category">
                        <Option value="Category">Category</Option>
                        <Option value="Category1">Category1</Option>
                        <Option value="Category2">Category2</Option>
                      </Select>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={12} md={12} xs={24} sm={12}>
                <div className="LeftInfoArea">
                  <h4>Selected Parameters</h4>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Co-Applicant</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>Avichal Rawat</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Bureau Name</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <Checkbox>CIBIL</Checkbox>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Name as mentioned on</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>Avichal Rawat</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>DOB as mentioned on</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>1/1/1999</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>PAN No</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>12467989</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Enquiry Amount</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>1500000</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Purpose</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>Auto-Loan</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Gender</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>Male</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Phone no</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>1234567</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Address</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>Sample text</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>State </label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>Madhyapradesh</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row  gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Category </label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>Avichal Rawat</p>
                      </Col>
                    </Row>
                  </Form.Item>
                 
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}
export default BureauReportForm;
