import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Upload,
  Input,
  Radio,
  DatePicker,
  Select,
} from "antd";

const layout = {
  labelCol: { span: 11, sm: 24, md: 11 },
  wrapperCol: { span: 13, sm: 24, md: 13 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const onFinish = (values) => {
  
};

const onFinishFailed = (errorInfo) => {
  
};
const { Option } = Select;

class DetailDocform extends Component {
  render() {
    return (
      <>
        <div className="FormWrap">
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Row gutter={32}>
              <Col span={8} md={8} xs={24} sm={24}>
                <Form.Item label="First name" name="firstname">
                  <Input placeholder="Tanvish kalra" />
                </Form.Item>
                <Form.Item label="Middle name" name="middlename">
                  <Input placeholder="Tanvish kalra" />
                </Form.Item>
                <Form.Item label="Last name" name="lastname">
                  <Input placeholder="Tanvish kalra" />
                </Form.Item>
                <Form.Item label="Gender" name="Gender">
                  <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1}>Male</Radio>
                    <Radio value={2}>Female</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="PAN No." name="pannumber">
                  <Input placeholder="98765421" />
                </Form.Item>
               
                
              </Col>
              <Col span={8} md={8} xs={24} sm={24}>
                <Form.Item label="Address Line 1" name="addressline1">
                  <Input placeholder="Address" />
                </Form.Item>
                <Form.Item label="Address Line 2" name="addressline2">
                  <Input placeholder="Address" />
                </Form.Item>
                <Form.Item label="Address Line 3" name="addressline3">
                  <Input placeholder="Address" />
                </Form.Item>
                <Form.Item label="Landmark" name="Landmark">
                  <Input placeholder="Landmark" />
                </Form.Item>
                <Form.Item label="Postal Code" name="postalcode">
                  <Input placeholder="Postal Code" />
                </Form.Item>
               
               
              </Col>
              <Col span={8} md={8} xs={24} sm={24}>
              <Form.Item label="State" name="state">
                  <Select defaultValue="State">
                    <Option value="MP">MP</Option>
                    <Option value="UP">UP</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="City" name="city">
                  <Select defaultValue="City">
                    <Option value="indore">indore</Option>
                    <Option value="bhopal">bhopal</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Date of Birth" name="dob">
                  <DatePicker />
                </Form.Item>
                <Form.Item label="Mobile No" name="mobno">
                  <Input placeholder="12467989" />
                </Form.Item>
                <Form.Item label="Email" name="email">
                  <Input placeholder="xyz@gmail.com" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={32} className="UploadWrap">
              <Col span={24}>
                <h3>Upload Scanned Copy / Photo Of The Following Documents</h3>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="PAN Card" name="pan">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="Driving License" name="DL">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="Doc1" name="doc1">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="Aadhar Card" name="Aadhar">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="Passport" name="Passport">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="Doc2" name="doc2">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="Voter ID" name="VoterID">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="Customer Consent" name="CustomerConsent">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
              <Col span={8} xs={24} md={8} sm={12}>
                <Form.Item label="Doc3" name="doc3">
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
            <Row  gutter={32} className="UploadWrap">
              <Col span={12} xs={24} md={12} sm={12}>
              <Form.Item label="Document Name" name="DocName" className='DocColumn'>
                  <Upload maxCount={1}>
                    <Button>Upload </Button>
                  </Upload>
                  <Button>Add</Button>
                </Form.Item>
              </Col>
              <Col span={12} xs={24} md={12} sm={12}>
              <table>
                <thead>
                  <tr>
                    <th>Document Name </th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Doc 1</td>
                    <td>
                      <Button>View</Button>
                      <Button className='delete'>Delete</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>Doc 2</td>
                    <td>
                      <Button>View</Button>
                      <Button className='delete'>Delete</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
              </Col>
            </Row>
          </Form>
          
        </div>
      </>
    );
  }
}
export default DetailDocform;
