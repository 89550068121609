import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import { getSalesUserAPI,} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    HIDE_LOADING,
    SHOW_LOADING,
    CREDIT_USER,
    CREDIT_USER_SUCCESS,
    CREDIT_USER_FAILED
} from "../constants/ActionTypes";
import { errorMessage, successMessage } from "../../helpers/utils";

function* onFectchCreditUser() {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getSalesUserAPI);
        yield put({type: HIDE_LOADING});
        yield put({type: CREDIT_USER_SUCCESS, creditUser: response.data.data});
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: CREDIT_USER_FAILED});
    }
}


function* actionCreditUser() {
    yield takeLatest(CREDIT_USER, onFectchCreditUser)
}

export default function* authSaga() {
    yield all([
        fork(actionCreditUser),
    ]);
}
