import React, { Component } from "react";
import { Button, Card, Row, Col } from "antd";
class Crif extends Component {
  render() {
    return (
      <>
        <div className="HeaderPanelAction">
            <div className='HeadingButton'>
                <Button>View Analysis</Button><h3>Displaying Summary Extract Of Credit Bureau Report</h3>
            </div>
            <div className="ButtonWrap">
            <Button>Download Summary Report (excel)</Button>
            <Button>Download Credit Bureau Report</Button>
          </div>
        </div>
        <div className='inquiryInputBlock'>
            <Card title='Inquiry Input Information' extra='Date Of Issue: 21-01-2021'>
                <Row gutter={32}>
                    <Col span={8} md={8} xs={24} sm={24}>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Name</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>Nirmala Rajendra Kumar</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Father</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>Rajendra Kumar</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Phone Number</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>+91 9856321475</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Entity Id</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>1A25D1245</p>
                           </Col>
                       </Row>
                    </Col>
                    <Col span={8} md={8} xs={24} sm={24}>
                    <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>DOB/Age</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>01-01-1967</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Spouse</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>XYZ</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>ID(s)</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>JMGPG1089H (PAN)</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Current Address</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>8 1 Munishwara Street Housr Main Road  Bangalore 560030 Bangalore 560030 KA</p>
                           </Col>
                       </Row>
                    </Col>
                    <Col span={8} md={8} xs={24} sm={24}>
                    <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Gender</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>Male</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Mother</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>XYZ</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Email ID(s)</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>lorem24@gmail.com</p>
                           </Col>
                       </Row>
                       <Row>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Other Address</h4>
                           </Col>
                           <Col span={12} md={12} xs={24} sm={24}>
                               <p>8 1 Munishwara Street Housr Main Road </p>
                           </Col>
                       </Row>
                    </Col>
                </Row>
            </Card>
        </div>
        
        <div className=" BoxCardTitle">
            <Card title="Trigger 1 " extra='Date Of Issue: 21-01-2021'>
                <div className='TableWrap'>
                <table>
                    <thead>
                        <tr>
                            <th>Score Name</th>
                            <th>Score</th>
                            <th>Description</th>
                          
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> Perform-Consumer </td>
                            <td><b>756</b>  Score Range: 300-900</td>
                            <td width='800px'><b>D</b> - Low Risk</td>
                           
                            
                        </tr>
                    </tbody>
                </table>
                <p>Tip: A-B Very Low Risk; C-D: Low Risk; E-G: Medium Risk; H-j: High Risk; K-M: Very High Risk</p>
                </div>
               
            </Card>
            
        </div>
        <div className='inquiryInputBlock gridcard'>
            <Card title='Personal Information - Variations'>
                <Row gutter={32}>
                    <Col span={12} md={12} xs={24} sm={24}>
                       <Card>
                           <Row>
                               <Col span={12} md={12} xs={24} sm={24}>
                                   <h4>Name Variations</h4>
                                    <p>Nirmala G</p>   
                                </Col>
                               <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Report On</h4>
                                    <p>30-09-2015</p>   
                               </Col>
                           </Row>
                          
                       </Card>
                       <Card>
                           <Row>
                               <Col span={12} md={12} xs={24} sm={24}>
                                   <h4>Address Varistions</h4>
                                    <p>18 1, Koramangala Bazar Street, Bangalo 560030 AR</p>  
                                    <p>8 1, Muneshwara Temple Street, Bangalo 560030 AR</p> 
                                    <p>8 1, Muneshwara Street House, Main Road 560030 KA</p>
                                    <p>18 1, Koramangala Bazar Street, Bangalo 560030 AR</p>
                                    <p>18 1, Koramangala Bazar Street, Bangalo 560030 AR</p>
                                </Col>
                               <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Report On</h4>
                                    <p>30-09-2015</p>   
                                    <p>31-12-2015</p>   
                                    <p>30-09-2015</p>   
                                    <p>31-12-2015</p>   
                                    <p>31-12-2015</p> 
                                    <p>31-12-2015</p>   
                               </Col>
                           </Row>
                          
                       </Card>
                    </Col>
                    <Col span={12} md={12} xs={24} sm={24}>
                       <Card>
                           <Row>
                               <Col span={12} md={12} xs={24} sm={24}>
                                   <h4>DOB Variations</h4>
                                    <p>01-01-2066</p>  
                                    <p>01-01-1967</p> 
                                </Col>
                               <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Report On</h4>
                                    <p>30-09-2015</p>   
                                    <p>31-12-2015</p>   
                               </Col>
                           </Row>
                          
                       </Card>
                       <Card>
                           <Row>
                               <Col span={12} md={12} xs={24} sm={24}>
                                   <h4>Phone Variations</h4>
                                    <p>6568627692</p>  
                                    <p>5556726748</p> 
                                </Col>
                               <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Report On</h4>
                                    <p>30-09-2015</p>   
                                    <p>31-12-2015</p>   
                               </Col>
                           </Row>
                          
                       </Card>
                       <Card>
                           <Row>
                               <Col span={12} md={12} xs={24} sm={24}>
                                   <h4>ID Variations</h4>
                                    <p>JMGPG1089H (PAN)</p>  
                                    <p>17301055559993 (Voter ID)</p> 
                                </Col>
                               <Col span={12} md={12} xs={24} sm={24}>
                               <h4>Report On</h4>
                                    <p>30-09-2015</p>   
                                    <p>31-12-2015</p>   
                               </Col>
                           </Row>
                          
                       </Card>
                    </Col>
                   
                </Row>
            </Card>
        </div>
        <div className=" BoxCardTitle GridCardLink">
            <Card title="Account Summary">
                <div className='TableWrap'>
                <table>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Number Of Account(s)</th>
                            <th>Active Account(s)</th>
                            <th>Overdue Accounts(s)</th>
                            <th>Current Balance</th>
                            <th>Amt Disbd/ High Credit</th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> Primary Match </td>
                            <td><b>1</b></td>
                            <td><b>0</b></td>
                            <td><b>0</b></td>
                            <td><b>0</b></td>
                            <td><b>0</b></td>
                            
                        </tr>
                        <tr>
                            <td><a href=''>Total</a> </td>
                            <td><b>1</b></td>
                            <td><b>0</b></td>
                            <td><b>0</b></td>
                            <td><b>0</b></td>
                            <td><b>0</b></td>
                            
                        </tr>
                        <tr>
                            <td colSpan='2'><a href=''>Inquiries In Last 24 Months: </a> 40</td>
                            <td colSpan='2'><a href=''>New Account(s) In Last 6 Months: </a> 0</td>
                            <td colSpan='2'><a href=''>New Delinquent Account(s) In Last 6 Months:</a> 0</td>
                           
                            
                        </tr>
                    </tbody>
                </table>
               
                </div>
                <p>Tip: Current Balance & Disbursed Amount Is Considered ONLY For ACTIVE Accounts.</p>
            </Card>
        </div>
        <div className=" BoxCardTitle TableInCard">
            <Card title="Account Information ">
                <div className='TableWrap '>
                    <div className='taglabel'>
                        <p><span className='number'>1</span> <span>CLOSED</span></p>
                    </div>
                <table>
                 <thead>
                     <tr>
                         <th colSpan='2'>Account Type: <a href=''>Microfinance Housing Loan</a></th>
                         <th colSpan='2'>Credit Grantor: SFB</th>
                         <th>Account #: xxx</th>
                         <th>Info. as Of :31-01-2019</th>
                         
                        
                     </tr>
                 </thead>
                 <tbody>
                     <tr>
                         <td>Ownership</td>
                         <td>INDIVIDUAL</td>
                         <td>Disbursed Date</td>
                         <td>30-03-2012</td>
                         <td>Disbd Amt/High Credit</td>
                         <td>30,000</td>
                        
                     </tr>
                     <tr>
                         <td>Ownership</td>
                         <td>INDIVIDUAL</td>
                         <td>Disbursed Date</td>
                         <td>30-03-2012</td>
                         <td>Disbd Amt/High Credit</td>
                         <td>30,000</td>
                        
                     </tr>
                     <tr>
                         <td>Ownership</td>
                         <td>INDIVIDUAL</td>
                         <td>Disbursed Date</td>
                         <td>30-03-2012</td>
                         <td>Disbd Amt/High Credit</td>
                         <td>30,000</td>
                        
                     </tr>
                   
                 </tbody>
             </table>
                </div>
                <div className='TableSmall'>
             <table>
                 <thead>
                     <tr>
                         <th>Jan/19</th>
                         <th>Feb/19</th>
                         <th>Mar/19</th>
                         <th>Apr/19</th>
                         <th>May/19</th>
                         <th>Jun/19</th>
                         <th>Jul/19</th>
                         <th>Aug/19</th>
                         <th>Sep/19</th>
                         <th>Oct/19</th>
                         <th>Nov/19</th>
                        
                     </tr>
                 </thead>
                 <tbody>
                     <tr>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                     </tr>
                 </tbody>
             </table>
         </div>
            </Card>
           
        </div>
        <div className=" BoxCardTitle HirarcgyGridTable">
            <Card title="Summary" extra='Date Of Issue: 21-01-2021'>
                <div className='TableWrap '>
                <table>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>
                                <table className='InnerTable'>
                                    <thead>
                                        <tr>
                                            <th colSpan='2'>Association</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Own</td>
                                            <td>Other</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </th>
                            <th>
                            <table className='InnerTable'>
                                    <thead>
                                        <tr>
                                            <th colSpan='3'>Account Summary</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Active </td>
                                            <td>Closed</td>
                                            <td>Default</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </th>
                            <th>
                            <table className='InnerTable'>
                                    <thead>
                                        <tr>
                                            <th colSpan='2'>Disbursed Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Own </td>
                                            <td>Other</td>
                                          
                                        </tr>
                                    </tbody>
                                </table>

                            </th>
                            <th>
                            <table className='InnerTable'>
                                    <thead>
                                        <tr>
                                            <th colSpan='2'>Instalment Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Own </td>
                                            <td>Other</td>
                                          
                                        </tr>
                                    </tbody>
                                </table>
                            </th>
                            <th>
                            <table className='InnerTable'>
                                    <thead>
                                        <tr>
                                            <th colSpan='2'>Current Balance</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Own </td>
                                            <td>Other</td>
                                          
                                        </tr>
                                    </tbody>
                                </table>
                            </th>
                           
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> Primary Match </td>
                            <td>
                                <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                            <td>0</td>
                                            <td>1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                            <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                            <td>2</td>
                                            <td>16</td>
                                            <td>0</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                            <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                        <td>70,000</td>
                                            <td>70,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                            <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                        <td>70,000</td>
                                            <td>4,584</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                            <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                        <td>70,000</td>
                                            <td>34,003</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            
                        </tr>
                        <tr>
                            <td>Secondary Match</td>
                            <td>
                                <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                            <td>0</td>
                                            <td>1</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                            <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                            <td>2</td>
                                            <td>16</td>
                                            <td>0</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                            <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                        <td>70,000</td>
                                            <td>70,000</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                            <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                        <td>70,000</td>
                                            <td>4,584</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            <td>
                            <table className='InnerTable'>
                                    <tbody>
                                        <tr>
                                        <td>70,000</td>
                                            <td>34,003</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                            
                        </tr>
                       
                    </tbody>
                </table>
               
                </div>
                <p>Tip: Current Balance & Disbursed Amount Is Considered ONLY For ACTIVE Accounts.</p>
            </Card>
        </div>
      </>
    );
  }
}
export default Crif;
