import React, { Component } from "react";
import { Card, Row, Col } from 'antd';
import IconImage from '../../../../../../assets/images/imageIcon.png';
class DrivingLicense extends Component {
    render() {
      return (
        <>
            <div className='CardBlock'>
                <h3>Driving License</h3>
                <Card>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>DL Number</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>AHMPC45o6C</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Name</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Tanvish Kalra</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>License Validity</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Expired</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Address Matching</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Yes</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Blood Group</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Yes</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>DOB Matching</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Yes</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Image</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p><img src={IconImage} alt='icon'/></p>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
      )
    }
}
export default DrivingLicense;