import {
  Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip
} from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
// import { onCaseMasters, onGetInProgessStatusCases, onGetLoggedStatusCases } from "../../redux/actions/AuthAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import EditIcon from "../../assets/images/edit.png";
import ReloadIcon from "../../assets/images/reload.png";
import ViewIcon from "../../assets/images/view.png";
import { MESSAGES } from "../../constants/messages";
import {
  disabledFutureDates, loggedInUserData
} from "../../helpers/utils";
import {
  onGetInProgessStatusCases, onGetLoggedStatusCases
} from "../../redux/actions/CaseAction";
import { onCaseMasters } from "../../redux/actions/MasterData";
const { Option } = Select;

const FilterNewCaseList = (props) => {
  
  const { userCases, role, caseMasters, listing } = props;
  
  const userData = loggedInUserData();
  
  const dispatch = useDispatch();
  const [caseList, setCaseList] = useState([]);
  const [products, setProducts] = useState([]);
  const [models, setModels] = useState([]);
  const [form] = Form.useForm();
  const [searchedInput, setSearchedInput] = useState("");
  // const userPermissions = useSelector(state => state.auth.userPermissions)
  
  useEffect(() => {
    if (caseMasters) {
      if (caseMasters.products && Array.isArray(caseMasters.products)) {
        setProducts(caseMasters.products);
      }
      if (
        caseMasters.product_models &&
        Array.isArray(caseMasters.product_models)
      ) {
        setModels(caseMasters.product_models);
      }
    }
  }, [caseMasters]);
  const getCases = useCallback(() => {
    if(listing === "new-cases"){
      dispatch(onGetLoggedStatusCases())
    }else if (listing === "ongoingcases") {
      // dispatch(onGetAllStatusCases())
      dispatch(onGetInProgessStatusCases())
    }
  }, [dispatch, listing]);
  useEffect(() => {
    getCases();
    dispatch(onCaseMasters(() => {}));
  }, [dispatch, listing, getCases]);
  useEffect(() => {
    setCaseList(userCases);
  }, [userCases]);
  let volvoColumns = [
    {
      title: "Case ID",
      dataIndex: "id",
      render: (text, row, record) => {
        return <div>{row.id ? row.id : ""}</div>;
      },
    },

    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (text, row, record) => {
        return <div style={{wordBreak:'break-word'}}>{row.customer_name ? row.customer_name : "-"}</div>;
      },
    },
    {
      title: "Applicant Category",
      dataIndex: "category_name",
      // key: "category_name",
      render: (text, row, record) => {
        return <div>{row.category_name ? row.category_name : "-"}</div>;
      },
      // sorter: (a, b) => a.ApplicantCategory - b.ApplicantCategory,
    },
    {
      title: "Business Area",
      dataIndex: "business_area",
      key: "business_area",
      // sorter: (a, b) => a.BusinessArea - b.BusinessArea,
    },
    {
      title: "Case Source",
      dataIndex: "case_source",
      render: (text, row, record) => {
        return <div>{row.case_source?row.case_source:'-'}</div>;
      },
    },
    // {
    //   title: "Product Type",
    //   dataIndex: "ProductType",
    //   key: "ProductType",
    // },
    {
      title: "Financing Type",
      dataIndex: "finance_type",
      // key: "finance_type",
      render: (text, row, record) => {
        return <div>{row.finance_type ? row.finance_type : "-"}</div>;
      },
    },
    {
      title: "Product Segment",
      dataIndex: "product_name",
      // key: "product_name",
      render: (text, row, record) => {
        return <div>{row.product_name ? row.product_name : "-"}</div>;
      },
      // sorter: (a, b) => a.ProductName - b.ProductName,
    },
    {
      title: "Product Description",
      dataIndex: "model_no",
      // key: "model_no",
      render: (text, row, record) => {
        return <div>{row.model_no ? row.model_no : "-"}</div>;
      },
    },
    {
      title: "Qty",
      dataIndex: "qty_required",
      render: (text, row, record) => {
        return <div>{row.qty_required.toLocaleString()}</div>;
      },
    },
    {
      title: <p>Amount Required(&#8377;)</p>,
      dataIndex: "finance_amount",
      render: (text, row, record) => {
        return <div>{row.finance_amount.toLocaleString()}</div>;
      },
    },
    {
      title: "Case Status",
      dataIndex: "status",
      render: (text, row, record) => {
        return <div><Link to={`/viewOnGoingcase/${row.id}`}>{row.status}</Link></div>;
      },
    },
    // {
    //   title: "Age of case in days",
    //   dataIndex:"created_on",
    //   key:"created_on"
    // },
    {
      title: "Created Date",
      dataIndex: "id",
      render: (text, row, record) => {
        return <div>{moment(row.created_at).format("ll")}</div>;
      },
    },
    ...(listing === "ongoingcases"
      ? [
          {
            title: "Last Updated Date",
            dataIndex: "id",
            render: (text, row, record) => {
              return <div>{moment(row.updated_at).format("ll")}</div>;
            },
          },
        ]
      : []),
      {
        title: "Age Of Case",
        dataIndex: "created_on",
        render: (text, row, record) => {
          return <div>{row.created_on}</div>;
        },
      },
    // {
    //   title: "Allocated To",
    //   dataIndex: "id",
    //   render: (text, row, record) => {
    //     return <div>{row.allocated_to}</div>;
    //   },
    // },
    
    ...((userData && userData.permissions && userData.permissions.Case && userData.permissions.Case.view) ? [{
      title: "Action",
      key: "action",
      render: (text, row, record) => {
        return (
          <Space size="middle">
            
          <Tooltip placement="topLeft" title="View" arrowPointAtCenter>
          <Link
          to={
            listing === "new-cases"
              ? `/viewcase/${row.id}`
              : row.status==='Case Logged'?`/viewcase/${row.id}`:`/viewOnGoingcase/${row.id}`
          }
        >
          <img src={ViewIcon} alt="View" />
        </Link>
    </Tooltip>
            {/*{userData && userData.user_type === 'Sales' && <Link to={`/edit-case/${row.id}`}>
              <img src={EditIcon} alt="Edit" />
        </Link>}*/}
            {/*<Link to="/">
              <img src={ReloadIcon} alt="Reload" />
        </Link>*/}
          </Space>
        );
      },
    }]:[]),
  ];
  const eicherColumns = [
    {
      title: "Case ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Applicant Category",
      dataIndex: "category_name",
      key: "category_name",
      // sorter: (a, b) => a.ApplicantCategory - b.ApplicantCategory,
    },
    {
      title: "Business Area",
      dataIndex: "business_area",
      key: "business_area",
      // sorter: (a, b) => a.BusinessArea - b.BusinessArea,
    },
    {
      title: "Product Type",
      dataIndex: "ProductType",
      key: "ProductType",
      // sorter: (a, b) => a.ProductType - b.ProductType,
    },
    {
      title: "Product Name",
      dataIndex: "product_name",
      key: "product_name",
      // sorter: (a, b) => a.ProductName - b.ProductName,
    },
    {
      title: "Product Description",
      dataIndex: "model_no",
      key: "model_no",
    },
    {
      title: "Qty.",
      dataIndex: "qty_required",
      key: "qty_required",
    },
    {
      title: "Amount Require.",
      dataIndex: "finance_amount",
      key: "finance_amount",
    },
    {
      title: "Dealer Name",
      dataIndex: "DealerName",
      key: "DealerName",
      sorter: (a, b) => a.DealerName - b.DealerName,
    },
    {
      title: "Dealer Location",
      dataIndex: "DealerLocation",
      key: "DealerLocation",
      sorter: (a, b) => a.DealerLocation - b.DealerLocation,
    },
    {
      title: "Action",
      key: "action",
      render: (text, row, record) => {
        return (
          <Space size="middle">
            <Link to={`/viewcase/${row.id}`}>
              <img src={ViewIcon} alt="View" />
            </Link>
            <Link to="/">
              <img src={EditIcon} alt="Edit" />
            </Link>
            <Link to="/">
              <img src={ReloadIcon} alt="Reload" />
            </Link>
          </Space>
        );
      },
    },
  ];
  const changeProducts = (business_area) => {
    form.setFieldsValue({
      product_name: "",
      model_no: "",
    });
    if (
      caseMasters &&
      caseMasters.products &&
      Array.isArray(caseMasters.products)
    ) {
      if (business_area) {
        const filteredProducts = caseMasters.products.filter((product) => {
          return (
            product.fk_business_area_id.toString() === business_area.toString()
          );
        });
        
        const productIds = filteredProducts.map((product) => {
          return product.id.toString();
        });
        const filteredModels = caseMasters.product_models.filter(
          (product_model) => {
            return productIds.includes(product_model.fK_product_id.toString());
          }
        );
        setProducts(filteredProducts);
        setModels(filteredModels);
      } else {
        setProducts(caseMasters.products);
        setModels(caseMasters.product_models);
      }
    }
  };
  const changeModels = (productId) => {
    form.setFieldsValue({
      model_no: "",
    });
    if (
      caseMasters &&
      caseMasters.products &&
      Array.isArray(caseMasters.products)
    ) {
      if (productId) {
        const filteredModels = caseMasters.product_models.filter(
          (product_model) => {
            return (
              product_model.fK_product_id.toString() === productId.toString()
            );
          }
        );
        setModels(filteredModels);
      } else {
        setModels(caseMasters.product_models);
      }
    }
  };
  const filterCaseListing = (e) => {
    const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
    const filteredCases = userCases.filter((userCase) => {
      let casesById = userCase.id.toString().includes(searchedInput);
      let casesByCustomerName = userCase.customer_name
        ? userCase.customer_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByFinanceType = userCase.finance_type
        ? userCase.finance_type.toLowerCase().includes(searchedInput)
        : "";
      let casesByProductName = userCase.product_name
        ? userCase.product_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByAllocation = userCase.allocated_to
        ? userCase.allocated_to.toLowerCase().includes(searchedInput)
        : "";
      let casesByApplicantCategory = userCase.category_name
        ? userCase.category_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByBusinessArea = userCase.business_area
        ? userCase.business_area.toLowerCase().includes(searchedInput)
        : "";
      let casesByModelNumber = userCase.model_no
        ? userCase.model_no.toLowerCase().includes(searchedInput)
        : "";
      let casesByQuantity = userCase.qty_required
        ? userCase.qty_required
            .toString()
            .includes(searchedInput.replaceAll(",", ""))
        : "";
      let casesByAmountRequired = userCase.finance_amount
        ? userCase.finance_amount
            .toString()
            .includes(searchedInput.replaceAll(",", ""))
        : "";
      let casesByStatus = userCase.status
        ? userCase.status
            .toLowerCase()
            .includes(searchedInput)
        : "";
      return (
        casesById ||
        casesByCustomerName ||
        casesByFinanceType ||
        casesByProductName ||
        casesByAllocation ||
        casesByApplicantCategory ||
        casesByBusinessArea ||
        casesByModelNumber ||
        casesByQuantity ||
        casesByAmountRequired||
        casesByStatus
      );
    });
    setCaseList(filteredCases);
  };
  const handleClearFilterAction = () => {
    const formValues = form.getFieldsValue();
    
    if (
      formValues.application_category ||
      formValues.business_area ||
      formValues.from_date ||
      formValues.model_no ||
      formValues.product_name ||
      formValues.to_date ||
      formValues.status
    ) {
      getCases();
      form.resetFields();
      changeProducts('')
      changeModels('')
      setSearchedInput("");
    }
  };
  const handleAddFiltersAction = (values) => {
    
    
    if (values.from_date && values.to_date) {
      if (!(moment(values.from_date).isSame(values.to_date))) {
        if (values.from_date.isAfter(values.to_date)) {
          toast.error(MESSAGES.FROM_DATE_MUST_BE_LESS_THAN_TO_DATE);
          return;
        }
      }
    }
    setSearchedInput("");
    let filterData = `customer_type=${
      values.application_category ? values.application_category : ""
    }&from_date=${
      values.from_date ? moment(values.from_date).format("YYYY-MM-DD") : ""
    }&to_date=${
      values.to_date ? moment(values.to_date).format("YYYY-MM-DD") : ""
    }&business_area=${
      values.business_area ? values.business_area : ""
    }&product_name=${values.product_name ? values.product_name : ""}&model_no=${
      values.model_no ? values.model_no : ""
    }&case_status=${values.status?values.status:''}`;
    if (listing === "new-cases") {
      dispatch(onGetLoggedStatusCases(filterData, () => {}));
    } else if (listing === "ongoingcases") {
      dispatch(onGetInProgessStatusCases(filterData));
      // if(userData && isSalesUser(userData)){
      //   dispatch(onGetAllStatusCases(filterData));
      // }else{
      //   dispatch(onGetLoggedStatusCases(filterData));
      // }
    }
  };
  return (
    <React.Fragment>
      <div className="FilterList">
        <div className="HeadWrap">
          <h3>Filter the Case List</h3>
        </div>
      </div>
      <div className="FilterForm formexpandedfilter">
        <Form
          name="global_state"
          layout="inline"
          form={form}
          onFinish={handleAddFiltersAction}
        >
          <Row>
            <Col>
              <Form.Item name="application_category" label="Applicant Category">
                <Select defaultValue="">
                  <Option value="">All</Option>
                  {caseMasters.categories &&
                    caseMasters.categories.map((category, index) => (
                      <Option value={category.id} key={category.id}>
                        {category.category_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="business_area" label="Business Area">
                <Select defaultValue="" onChange={changeProducts}>
                  <Option value="">All</Option>
                  {caseMasters.business_areas &&
                    caseMasters.business_areas.map((business_area) => (
                      <Option value={business_area.id} key={business_area.id}>
                        {business_area.business_area}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {/*<Col>
              <Form.Item name="finance_type" label="Finance Type">
                <Select defaultValue="All">
                  <Option value="loan">loan</Option>
                  <Option value="lease">lease</Option>
                </Select>
              </Form.Item>
            </Col>*/}
            <Col>
              <Form.Item name="product_name" label="Product Segment">
                <Select defaultValue="" onChange={changeModels}>
                  <Option value="">All</Option>
                  {products &&
                    products.map((product) => (
                      <Option value={product.id} key={product.id}>
                        {product.product_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="model_no" label="Product Description">
                <Select defaultValue="">
                  <Option value="">All</Option>
                  {models.map((productModal) => (
                    <Option value={productModal.id} key={productModal.id}>
                      {productModal.model_no}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/*<Col>
              <Form.Item name="DealerName" label="Dealer Name">
                <Select defaultValue="Select">
                  <Option value="Dealer Name 1">Dealer Name 1</Option>
                  <Option value="Dealer Name 2">Dealer Name 2</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="DealerLocation" label="Dealer Location">
                <Select defaultValue="All">
                  <Option value="Dealer Location 1">Dealer Location 1</Option>
                  <Option value="Dealer Location 2">Dealer Location 2</Option>
                </Select>
              </Form.Item>
            </Col>*/}
            <Col>
              <Form.Item name="from_date" label="From">
                <DatePicker
                  disabledDate={disabledFutureDates}
                  inputReadOnly
                  format="ll"
                />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name="to_date" label="To">
                <DatePicker
                  disabledDate={disabledFutureDates}
                  inputReadOnly
                  format="ll"
                />
              </Form.Item>
            </Col>
            {listing === "ongoingcases" && <Col>
              <Form.Item name="status" label="Status">
                <Select defaultValue="">
                  <Option value="">All</Option>
                  <Option value='In Progress'>In Progress</Option>
                  <Option value='Case Logged'>Case Logged</Option>
                </Select>
              </Form.Item>
            </Col>}
            <Col>
              <div className="BtnGroup">
                <Button htmlType="submit">Apply Filters</Button>
                <Button onClick={handleClearFilterAction}>Clear All</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="CaseListData">
        <Form>
          <Input.Group compact>
            <Input.Search
              allowClear
              // placeholder="Search on Case ID, Dealer Name, Product Name"
              placeholder="Search on Case ID, Customer Name, Product Segment,Finance Type"
              value={searchedInput}
              onChange={(e) => {
                setSearchedInput(e.target.value);
                filterCaseListing(e);
              }}
            />
          </Input.Group>
        </Form>
        <Table
          // columns={userData.roles && userData.roles.group_name === "Volvo" ? volvoColumns : eicherColumns}
          columns={volvoColumns}
          dataSource={caseList}
          pagination={{
            showSizeChanger: false,
          }}
        />
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    userCases: auth.userCases ? auth.userCases : [],
    role: auth.role,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
  };
};
export default connect(mapStateToProps, null)(FilterNewCaseList);

// import React, { Component } from "react";
// import { Form, Select, DatePicker, Button, Row, Col, Input, Table, Tag, Space  } from "antd";
// import {EyeFilled, SyncOutlined} from '@ant-design/icons';
// import ViewIcon from '../../assets/images/view.png';
// import ReloadIcon from '../../assets/images/reload.png';
// import EditIcon from '../../assets/images/edit.png';
// import {Link} from "react-router-dom";
// const { Option } = Select;
// const columns = [
//   {
//     title: 'Case I',
//     dataIndex: 'Case',
//     key: 'Case',

//   },
//   {
//     title: 'Applicant Category',
//     dataIndex: 'ApplicantCategory',
//     key: 'ApplicantCategory',
//     sorter: (a, b) => a.ApplicantCategory - b.ApplicantCategory,
//   },
//   {
//     title: 'Business Area',
//     dataIndex: 'BusinessArea',
//     key: 'BusinessArea',
//     sorter: (a, b) => a.BusinessArea - b.BusinessArea,
//   },
//   {
//     title: 'Product Type',
//     dataIndex: 'ProductType',
//     key: 'ProductType',
//     sorter: (a, b) => a.ProductType - b.ProductType,
//   },
//   {
//     title: 'Product Name',
//     dataIndex: 'ProductName',
//     key: 'ProductName',
//     sorter: (a, b) => a.ProductName - b.ProductName,
//   },
//   {
//     title: 'Model No.',
//     dataIndex: 'ModelNo',
//     key: 'ModelNo',

//   },
//   {
//     title: 'Qty.',
//     dataIndex: 'Qty',
//     key: 'Qty',

//   },
//   {
//     title: 'Amount Require.',
//     dataIndex: 'AmountRequire',
//     key: 'AmountRequire',

//   },
//   {
//     title: 'Dealer Name',
//     dataIndex: 'DealerName',
//     key: 'DealerName',
//     sorter: (a, b) => a.DealerName - b.DealerName,
//   },
//   {
//     title: 'Dealer Location',
//     dataIndex: 'DealerLocation',
//     key: 'DealerLocation',
//     sorter: (a, b) => a.DealerLocation - b.DealerLocation,
//   },
//   {
//     title: 'Action',
//     key: 'action',
//     render: (text, record) => (
//       <Space size="middle">
//         <Link to='/view-cases'><img src={ViewIcon} alt='View' /></Link>
//         <Link to='/view-cases'><img src={EditIcon} alt='Edit' /></Link>
//         <Link to='/view-cases'><img src={ReloadIcon} alt='Reload' /></Link>
//       </Space>
//     ),
//   },
// ];

// const data = [
//   {
//     key: '1',
//     Case: '234',
//     ApplicantCategory: 'Individual',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['nice', 'developer'],
//   },
//   {
//     key: '2',
//     Case: '235',
//     ApplicantCategory: 'Corporate',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['loser'],
//   },
//   {
//     key: '3',
//     Case: '236',
//     ApplicantCategory: 'Corporate',
//     BusinessArea: 'Volvo Bus',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['cool', 'teacher'],
//   },

//   {
//     key: '4',
//     Case: '237',
//     ApplicantCategory: 'Individual',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['cool', 'teacher'],
//   },

//   {
//     key: '5',
//     Case: '238',
//     ApplicantCategory: 'Individual',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['cool', 'teacher'],
//   },

//   {
//     key: '6',
//     Case: '239',
//     ApplicantCategory: 'Individual',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['cool', 'teacher'],
//   },
// ];
// class FilterNewCaseList extends Component {
//   render() {
//     return (
//       <>
//         <div className="FilterList">
//           <div className="HeadWrap">
//             <h3>Filter the Case List</h3>
//           </div>
//         </div>
//         <div className="FilterForm">
//           <Form name="global_state" layout="inline">
//             <Row>
//               <Col>
//                 <Form.Item
//                   name="ApplicationCategory"
//                   label="Application Category"
//                 >
//                   <Select defaultValue="All">
//                     <Option value="Category1">Category 1</Option>
//                     <Option value="Category2">Category 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="BusinessArea" label="Business Area">
//                   <Select defaultValue="All">
//                     <Option value="BusinessArea1">Business Area 1</Option>
//                     <Option value="BusinessArea2">Business Area 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="ProductType" label="Product Type">
//                   <Select defaultValue="All">
//                     <Option value="ProductType">Product Type 1</Option>
//                     <Option value="ProductType">Product Type 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="ProductName" label="Product Name">
//                   <Select defaultValue="All">
//                     <Option value="ProductName">Product Name 1</Option>
//                     <Option value="ProductName">Product Name 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="ModelNo" label="Model No">
//                   <Select defaultValue="All">
//                     <Option value="9876543210">9876543210</Option>
//                     <Option value="9876542310">9876542310</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="DealerName" label="Dealer Name">
//                   <Select defaultValue="Select">
//                     <Option value="Dealer Name 1">Dealer Name 1</Option>
//                     <Option value="Dealer Name 2">Dealer Name 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="DealerLocation" label="Dealer Location">
//                   <Select defaultValue="All">
//                     <Option value="Dealer Location 1">Dealer Location 1</Option>
//                     <Option value="Dealer Location 2">Dealer Location 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="From" label="From">
//                   <DatePicker />
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="To" label="To">
//                   <DatePicker />
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <div className="BtnGroup">
//                   <Button>Apply Filters</Button>
//                   <Button>Clear All</Button>
//                 </div>
//               </Col>
//             </Row>
//           </Form>
//         </div>
//         <div className='CaseListData'>
//           <Form>
//             <Input.Group compact >
//               <Input.Search allowClear placeholder='Search on Case ID, Dealer Name, Product Name'/>
//             </Input.Group>
//           </Form>
//           <Table columns={columns} dataSource={data} pagination={false}/>
//         </div>
//       </>
//     );
//   }
// }
// export default FilterNewCaseList;
