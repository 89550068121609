import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import { getVahanDetailsAPI,getVahanRequestDetailsAPI,uploadStoreVaahanDetailsAPI,runVahanHypothecationAPI,getVahanDetailsByIdAPI} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    SHOW_LOADING,
    HIDE_LOADING,
    GET_VAAHAN_DETAILS,
    VAAHAN_SUCCESS,
    STORE_VAAHAN_DETAILS,
    GET_VAHAN_REQUEST_DETAILS,
    RUN_VAHAN_HYPOTHECATION,
    GET_VAAHAN_DETAILS_BY_ID,
    VAAHAN_DETAILS
} from "../constants/ActionTypes";
import { errorMessage, successMessage } from "../../helpers/utils";

function* loadVaahanDetails(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(getVahanDetailsAPI);
      if (response.status === 200 && response.data) {
        yield put({ type: VAAHAN_SUCCESS, vaahanData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  function* loadVaahanDetailById(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(getVahanDetailsByIdAPI,action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: VAAHAN_DETAILS, vaahanDataByID: response.data.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  function* storeVaahanDetails(action) {
    try {
      yield put({ type: SHOW_LOADING });
     
      const response = yield call(uploadStoreVaahanDetailsAPI, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: VAAHAN_SUCCESS, vaahanData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  function* vahanRequestDetails(action) {
    try {
      yield put({ type: SHOW_LOADING });
    
      const response = yield call(getVahanRequestDetailsAPI, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: VAAHAN_SUCCESS, vaahanData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  function* vahanHypothecation(action) {
    try {
      yield put({ type: SHOW_LOADING });
      
      const response = yield call(runVahanHypothecationAPI, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: VAAHAN_SUCCESS, vaahanData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }

  function* onLoadVaahanDetails() {
    yield takeLatest(GET_VAAHAN_DETAILS,loadVaahanDetails)
  }
  function* onLoadVaahanDetailsByid() {
    yield takeLatest(GET_VAAHAN_DETAILS_BY_ID,loadVaahanDetailById)
  }
  function* onUploadStoreVahanDetails() {
    yield takeLatest(STORE_VAAHAN_DETAILS,storeVaahanDetails)
  }
  function* onGetVahanRequestDetails() {
    yield takeLatest(GET_VAHAN_REQUEST_DETAILS,vahanRequestDetails)
  }
  function* onRunVahanHypothecation() {
    yield takeLatest(RUN_VAHAN_HYPOTHECATION,vahanHypothecation)
  }
  
  export default function* vaahanSaga() {
    yield all([ 
       fork(onLoadVaahanDetails),
       fork(onUploadStoreVahanDetails),
       fork(onGetVahanRequestDetails),
       fork(onRunVahanHypothecation),
       fork(onLoadVaahanDetailsByid)
    ]);
  
}


