import React, { Component } from "react";
import { Collapse, Row, Col, Card } from "antd";
import InfoMatching from './informationmatching'
import InformationValidity from './informationvalidity'
import PanCard from './pancard'
import VoterId from './voterid'
import DrivingLicense from './drivinglicense'
import AddharCard from './aadharcard'
const { Panel } = Collapse;

class MainApplicantFormKyc extends Component {
  render() {
    return (
      <>
        <Card>
          <div className="headerWrap">
            <Row>
              <Col span={6} md={6} xs={24} sm={12}>
                <Row>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <h3>Customer’s Full Name</h3>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <p>Tanvish Kalra</p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} md={6} xs={24} sm={12}>
                <Row>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <h3>Age and Gender</h3>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <p>25 Year, Male</p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} md={6} xs={24} sm={12}>
                <Row>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <h3>Mobile No.</h3>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <p>12467989</p>
                  </Col>
                </Row>
              </Col>
              <Col span={6} md={6} xs={24} sm={12}>
                <Row>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <h3>PAN No.</h3>
                  </Col>
                  <Col span={12} md={12} xs={24} sm={24}>
                    <p>12467989</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Collapse defaultActiveKey={["1"]}>
            <Panel header="KYC Summary" key="1"  >
                <Row gutter='64'>
                    <Col span={12} md={12} xs={24} sm={12}>
                        <InfoMatching/>
                    </Col>
                    <Col span={12} md={12} xs={24} sm={12}>
                        <InformationValidity/>
                    </Col>
                </Row>
            </Panel>
            <Panel header="KYC Details" key="2">
                <Row gutter='32'>
                    <Col span={8} md={8} sm={24} xs={24}>
                        <PanCard/>
                    </Col>
                    <Col span={8} md={8} sm={24} xs={24}>
                        <VoterId/>
                    </Col>
                    <Col span={8} md={8} sm={24} xs={24}>
                        <DrivingLicense/>
                    </Col>
                    <Col span={8} md={8} sm={24} xs={24}>
                    <AddharCard/>
                    </Col>
                    
                </Row>
            </Panel>
          </Collapse>
        </Card>
      </>
    );
  }
}
export default MainApplicantFormKyc;
