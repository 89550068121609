import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  getAgent,
  addFieldInvestigation,
  addFieldInvestigationOTP,
  addFieldInvestigationOTPVerify,
  getFieldInvestigationAll,
  getFieldInvestigationCase,
  submitFieldInvestigationResponse,
  linkExpire,
  getCaseForFI
}
  from "../apis/Api";
import { toast } from "react-toastify";
import {
  SHOW_LOADING,
  HIDE_LOADING,
  GET_FI_AGENT,
  LOAD_FI_AGENT,
  ADD_FIELDINVESTIGATION,
  LOAD_FIELDINVESTIGATION,
  ADD_FIELDINVESTIGATIONOTP,
  LOAD_FIELDINVESTIGATIONOTP,
  ADD_FIELDINVESTIGATIONOTPVERIFY,
  LOAD_FIELDINVESTIGATIONOTPVERIFY,
  LINK_EXPIRECHECK,
  SET_LINK_EXPIRECHECK,
  GET_FIELDINVESTIGATIONALL,
  LOAD_FIELDINVESTIGATIONALL,
  GET_FIELDINVESTIGATIONCASE,
  LOAD_FIELDINVESTIGATIONCASE,
  ADD_FIELDINVESTIGATIONRESPONSE,
  LOAD_FIELDINVESTIGATIONRESPONSE,
  CHECK_LINK_EXPIRE,
  SET_CHECK_LINK_EXPIRE,
  LOAD_CASE_DETAIL_FOR_FI,
  GET_CASE_DETAIL_FOR_FI
} from "../constants/ActionTypes";

import { errorMessage, successMessage } from "../../helpers/utils";

function* fetchFiAgent(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getAgent);
    if (response.status === 200 && response.data) {
      yield put({ type: LOAD_FI_AGENT, fiAgentData: response.data.data });
    }
    yield put({ type: HIDE_LOADING });
    action.onSuccess({ data: response.data.data });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* addFieldInvestigationEmail(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(addFieldInvestigation, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: LOAD_FIELDINVESTIGATION, fiAddData: response.data.data });
      toast.success(response.data.data);
    }
    yield put({ type: HIDE_LOADING });
    action.onFISuccess({ data: response.data.data });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* addFieldInvestigationEmailOTP(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(addFieldInvestigationOTP, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: LOAD_FIELDINVESTIGATIONOTP, fiOTP: response.data.data });
      toast.success(response.data.message);
    }
    yield put({ type: HIDE_LOADING });
    action.onFISuccess({ data: response.data.data });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* addFieldInvestigationEmailOTPVerify(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(addFieldInvestigationOTPVerify, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: LOAD_FIELDINVESTIGATIONOTPVERIFY, fiOTPVerify: response });
      toast.success(response);
    }
    yield put({ type: HIDE_LOADING });
    action.onFIOTPSuccess({ data: response });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}


function* getFieldInvestigationCasesAll(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getFieldInvestigationAll, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: LOAD_FIELDINVESTIGATIONALL, fiGetAll: response.data.data });
      toast.success(response);
    }
    yield put({ type: HIDE_LOADING });
    action.onSuccess({ data: response });
  } catch (error) {
    //errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* getFieldInvestigationCasesdetail(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getFieldInvestigationCase, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: LOAD_FIELDINVESTIGATIONCASE, fiCase: response.data.data });
      toast.success(response.data.data);
    }
    yield put({ type: HIDE_LOADING });
    action.onGetSuccess({ data: response });
  } catch (error) {
    //errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* submitFIResponse(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(submitFieldInvestigationResponse, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: LOAD_FIELDINVESTIGATIONRESPONSE, fiResponse: response.data.data });
      toast.success(response.data.data);
    }
    yield put({ type: HIDE_LOADING });
    action.onSuccess({ data: response.data.data });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* checkfiLinkExpire(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(linkExpire, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: SET_CHECK_LINK_EXPIRE, fiLinkExpire: response.data });
    }
    yield put({ type: HIDE_LOADING });
    action.onSuccess({ data: response });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* getCasesdetailForFI(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getCaseForFI, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: LOAD_CASE_DETAIL_FOR_FI, fiCaseDetails: response.data.data });
      toast.success(response.data.data);
    }
    yield put({ type: HIDE_LOADING });
    action.onGetSuccess({ data: response });
  } catch (error) {
    //errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* actionGetFiAgent() {
  yield takeLatest(GET_FI_AGENT, fetchFiAgent)
}
function* actionAddFieldInvestigation() {
  yield takeLatest(ADD_FIELDINVESTIGATION, addFieldInvestigationEmail)
}
function* actionAddFieldInvestigationOTP() {
  yield takeLatest(ADD_FIELDINVESTIGATIONOTP, addFieldInvestigationEmailOTP)
}
function* actionAddFieldInvestigationOTPVerify() {
  yield takeLatest(ADD_FIELDINVESTIGATIONOTPVERIFY, addFieldInvestigationEmailOTPVerify)
}
function* actionGetFieldInvestigationAll() {
  yield takeLatest(GET_FIELDINVESTIGATIONALL, getFieldInvestigationCasesAll)
}
function* actionGetFieldInvestigationCase() {
  yield takeLatest(GET_FIELDINVESTIGATIONCASE, getFieldInvestigationCasesdetail)
}
function* actionSubmitFieldInvestigationResponse() {
  yield takeLatest(ADD_FIELDINVESTIGATIONRESPONSE, submitFIResponse)
}
function* actionfiLinkExpire() {
  yield takeLatest(CHECK_LINK_EXPIRE, checkfiLinkExpire)
}
function* actionCaseDetailForFi() {
  yield takeLatest(GET_CASE_DETAIL_FOR_FI, getCasesdetailForFI)
}

export default function* fieldInvestigationSaga() {
  yield all([
    fork(actionGetFiAgent),
    fork(actionAddFieldInvestigation),
    fork(actionAddFieldInvestigationOTP),
    fork(actionAddFieldInvestigationOTPVerify),
    fork(actionGetFieldInvestigationAll),
    fork(actionGetFieldInvestigationCase),
    fork(actionSubmitFieldInvestigationResponse),
    fork(actionfiLinkExpire),
    fork(actionCaseDetailForFi),
  ]);
}