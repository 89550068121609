import {
  DOWNLOAD_HUNTER_REPORT,
  DOWNLOAD_HUNTER_SUMMARY_REPORT,
  KYC_DONE_CASES_LIST,
  RUN_HUNTER,
  HUNTER_SUMMARY_LIST,
  SET_HUNTER_SUMMARY,
  GET_HUNTER_DETAIL_BY_CASE
} from "../constants/ActionTypes";



export const onDownloadHunterReport = (onSuccess) => ({
  type: DOWNLOAD_HUNTER_REPORT,
  payload: [],
  onSuccess,
});



export const onKycDoneCasesLoad = (onSuccess) => {
  return {
    type: KYC_DONE_CASES_LIST,
    payload: [],
    onSuccess,
  };
};
export const onRunHunter = (case_id, onSuccess) => ({
  type: RUN_HUNTER,
  payload: case_id,
  onSuccess,
});

export const onHunterSummaryList = (onSuccess) => ({
  type: HUNTER_SUMMARY_LIST,
  payload: [],
  onSuccess,
});

export const onHunterDetilsByCase = (case_id, onSuccess) => ({
  type: GET_HUNTER_DETAIL_BY_CASE,
  payload: case_id,
  onSuccess,
});





