import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { onResetNewCaseCreated, onSetResetNewCaseCreated } from "../../redux/actions/CaseAction";
import AddParticipantTabs from "../case-participants/AddParticipantTab";
import CaseForm from "./forms/CaseForm";
import EditCaseForm from "./forms/EditCaseForm";

const AddCase = (props) => {
  
  const newlyCreatedCase = useSelector((state) => state.auth.newlyCreatedCase);
  
  useEffect(()=>{
    window.scrollTo(0, 10);
  },[]);
  // const dispatch = useDispatch();
  // useEffect(()=>{
  //   return function(){
  //     
  //     dispatch(onResetNewCaseCreated())
  //   }
  // },[dispatch])
  return (
    <React.Fragment>
      {newlyCreatedCase ? (
        <EditCaseForm caseDetails={newlyCreatedCase}  />
      ) : (
        <CaseForm />
      )}
      {newlyCreatedCase && <div className="LogNewCasesWrap" id='addnextdetails'>
        <AddParticipantTabs />
      </div>}
      {/*{caseDetail ? (
        <EditCaseForm caseDetails={caseDetail} editDisable = {true} />
      ) : (
        <CaseForm />
      )}
      {caseDetail && <div className="LogNewCasesWrap">
        <AddParticipantTabs caseDetails={caseDetail}/>
      </div>}*/}
    </React.Fragment>
  );
};
export default AddCase;
