import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  Radio,
  DatePicker, message
} from "antd";
import { Link } from "react-router-dom";
import { disabledFutureDates } from "../../../../../helpers/utils";
import moment from 'moment';
import { MESSAGES, REQUIRED_MESSAGES } from "../../../../../constants/messages";
import { REGEX } from "../../../../../constants/regex";
import NumberFormat from "react-number-format";
import {
  checkLoanAmountAllowed,
  checkQuantityAllowed,
  firstCharacterOfEachWordUpperCase,
} from "../../../../../helpers/utils";
import { OCR_DOCUMENT_LABEL } from "../../../../../constants/constants";
const { Option } = Select;
class BureauReportForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bureauname: 'CRIF',
      nameon: '',
      dob: '',
      enquiryAmount: 0,
      gender: '',
      mobile_no: '',
      address: '',
      state: '',
      city: '',
      pin_code: '',
      document_no: '',
      nameonName: '',
      constitutions: '',
      identityLabel: 'PAN No.',
      applicant_name: '',
      score_type: '',
      applicant_first_name: '',
      applicant_last_name: '',
      applicant_middle_name: ''
    };
  }

  componentDidMount() {
    const { selectedApplicant, caseDetail, getCity } = this.props;
   
    let stateid = this.props.states.find(element => element.state_code ===selectedApplicant.state_code );
      // stateid = name.id
      if(stateid){
        getCity(stateid.id,'CRIF');
      }
    let name = "";
    if (selectedApplicant.first_name && selectedApplicant.middle_name && selectedApplicant.last_name) {
      name = selectedApplicant.first_name + ' ' + selectedApplicant.middle_name + ' ' + selectedApplicant.last_name;
    } else if (selectedApplicant.first_name && selectedApplicant.last_name) {
      name = selectedApplicant.first_name + ' ' + selectedApplicant.last_name;
    } else if (selectedApplicant.first_name) {
      name = selectedApplicant.first_name;
    }
    if (selectedApplicant.category_name === "Corporate" || selectedApplicant.category_name === "Proprietor" || selectedApplicant.category_name === "HUF") {
      name = selectedApplicant.organization_name;
    }

    this.setState({
      enquiryAmount: '₹' + caseDetail.finance_amount,
      gender: selectedApplicant.gender,
      mobile_no: selectedApplicant.mobile_no,
      address: selectedApplicant.address_line1,
      state: selectedApplicant.state,
      city: selectedApplicant.city,
      pin_code: selectedApplicant.pin,
      document_no: selectedApplicant.pan_no,
      dob: selectedApplicant.dob,
      applicant_name: name,
      applicant_first_name: selectedApplicant.first_name,
      applicant_last_name: selectedApplicant.last_name ? selectedApplicant.last_name : '',
      applicant_middle_name: selectedApplicant.middle_name ? selectedApplicant.middle_name : '',
    })
  }
  onChange = (e) => {
    const { selectedApplicant,getCity,cibilReportMasters } = this.props;
    let name = "";
    if (selectedApplicant.first_name && selectedApplicant.middle_name && selectedApplicant.last_name) {
      name = selectedApplicant.first_name + ' ' + selectedApplicant.middle_name + ' ' + selectedApplicant.last_name;
    } else if (selectedApplicant.first_name && selectedApplicant.last_name) {
      name = selectedApplicant.first_name + ' ' + selectedApplicant.last_name;
    } else if (selectedApplicant.first_name) {
      name = selectedApplicant.first_name;
    }
    if (selectedApplicant.category_name === "Corporate" || selectedApplicant.category_name === "Proprietor" || selectedApplicant.category_name === "HUF") {
      name = selectedApplicant.organization_name;
    }

    if (e.target.value === 'CIBIL') {
     let stateid = cibilReportMasters.statePinCodeStandardization.find(element => element.stateCode ==selectedApplicant.states_cibil_code);
     if(stateid){
      getCity(stateid.stateCode,'CIBIL');
     }
    } 
    this.setState({
      bureauname: e.target.value,
      state: selectedApplicant.state,
      city: selectedApplicant.city,
    })
    this.props.form.setFieldsValue({
      applicant_name: e.target.value == "CIBIL" ? selectedApplicant.first_name : name,
      document_type: e.target.value == "CIBIL" ? '01' : 'ID07',
      city:selectedApplicant.city,
      state: e.target.value == "CIBIL" ? selectedApplicant.states_cibil_code : selectedApplicant.state_code
    });
  }
  onNameChange = (e) => {
    const name = this.props.ocrresult.find(element => element.document_name === e);
    this.setState({
      nameon: e,
      nameonName: name.name
    })
  }
  onSelectChange = (e, values) => {

    this.setState({
      [values.name]: values.children
    })
  }
  onSelectCityChange = (e) => {
    this.setState({
      city: e
    })
  }
  onSelectAddMention = (e) => {
    const { selectedApplicant } = this.props;
    const { bureauname } = this.state;
    if (e === 'Default Case Address') {
      this.setState({
        address: selectedApplicant.address_line1,
        state: bureauname == "CIBIL" ? selectedApplicant.states_cibil_code : selectedApplicant.state_code,
        city: selectedApplicant.city,
      });
      this.props.form.setFieldsValue({
        address: selectedApplicant.address_line1,
        state: bureauname == "CIBIL" ? selectedApplicant.states_cibil_code : selectedApplicant.state_code,
        city: selectedApplicant.city,
      });
    } else {
      const name = this.props.ocrresult.find(element => element.document_name === e);

      this.setState({
        address: name.address,
        // pin_code:name.pin? name.pin:'',
        state: bureauname == "CIBIL" ? name.states_cibil_code : name.state_code,
        city: name.city,

      });
      this.props.form.setFieldsValue({
        address: name.address,
        // pin_code:name.pin? name.pin:null,
        state: bureauname == "CIBIL" ? name.states_cibil_code : name.state_code,
        city: name.city,
      });
    }

  }
  onSelectAdressChange = (e) => {
    const name = this.props.ocrresult.find(element => element.document_name === e);
    let doc_type = ''
    let pan_no = '';
    let adhaar_no = ''
    if (this.state.bureauname === 'CIBIL') {
      switch (e) {
        case "Passport":
          doc_type = "02";
          pan_no = name.passport_no;
          break;
        case "Voter ID":
          doc_type = "03";
          pan_no = name.voter_id;
          break;
        case "Ucode":
          doc_type = "06";
          pan_no = name.dl_no;
          break;
        case "Ration Card":
          doc_type = "05";
          pan_no = name.dl_no;
          break;
        case "Driving License":
          doc_type = "04";
          pan_no = name.dl_no;
          break;
        case "PAN Card":
          doc_type = "01";
          pan_no = name.pan_no;
          break;
        case OCR_DOCUMENT_LABEL.GST_REGISTRATION:
          doc_type = "0";
          pan_no = name.gst_registration_no;
          break;
        default:
          doc_type = "0";
          pan_no = name.aadhaar_no;
      }
    } else {
      switch (e) {
        case "Passport":
          doc_type = "ID01";
          pan_no = name.passport_no;
          break;
        case "Voter ID":
          doc_type = "ID02";
          pan_no = name.voter_id;
          break;
        case "UID":
          doc_type = "ID03";
          pan_no = name.dl_no;
          break;
        case "Ration Card":
          doc_type = "ID05";
          pan_no = name.dl_no;
          break;
        case "Driving License":
          doc_type = "ID06";
          pan_no = name.dl_no;
          break;
        case "PAN Card":
          doc_type = "ID07";
          pan_no = name.pan_no;
          break;
        case OCR_DOCUMENT_LABEL.GST_REGISTRATION:
          doc_type = "ID04";
          pan_no = name.gst_registration_no;
          break;
        default:
          doc_type = "ID04";
          pan_no = name.aadhaar_no;
      }
    }

    this.setState({
      document_no: pan_no,
      identityLabel: e,

    });
    this.props.form.setFieldsValue({
      document_no: pan_no,
      document_type: doc_type,
    });
  }

  onDateChange = (date, dateString) => {
    this.setState({
      dob: dateString
    })
  }

  onChangeGender = (e) => {
    this.setState({
      gender: e.target.value
    })
  }
  onInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }
  nameValidation = (e) => {
    const { bureauname } = this.state;
    if (bureauname === "CIBIL") {
      this.setState({
        [e.target.name]: e.target.value
      })
    } else {
      this.setState({
        applicant_name: e.target.value
      })
    }
  };
  onSelectStateChange = (e) => {
    let name = {};
    let stateid = 0;
    const { cibilReportMasters } = this.props;
    if (this.state.bureauname === 'CIBIL') {
      name = cibilReportMasters.statePinCodeStandardization.find(element => element.stateCode == e);
      stateid = name.stateCode
    } else {
      name = this.props.states.find(element => element.state_code === e);
      stateid = name.id

    }
    this.setState({
      state: name && name.standardState ? name.standardState : name.state_name,
    })
    this.props.form.setFieldsValue({
      city: undefined,
      // pin_code:null
    });
    this.props.getCity(stateid, this.state.bureauname);
  }
  onFinishFailed = () => {
    // message.error('Please fill all the mandatory fields!');
  }
  removeLoanMaxError = () => {
    const loan_amount_errors = this.props.form.getFieldError("loan_amount");
    if (loan_amount_errors.length > 0) {
      if (loan_amount_errors[0] !== REQUIRED_MESSAGES.LOAN_AMOUNT_REQUIRED) {
        this.props.form.setFields([
          {
            name: "amount",
            errors: [],
          },
        ]);
      }
    }
  };
  render() {
    const { selectedApplicant, fetchReport, bureauReportMasters, states, cities,
      caseDetail, form, ocrresult, cibilReportMasters, cibilcities,buttonDisable } = this.props;
    let name = "";
    let category = selectedApplicant.category_name ? selectedApplicant.category_name : 'Individual';
    if (selectedApplicant.first_name && selectedApplicant.middle_name && selectedApplicant.last_name) {
      name = selectedApplicant.first_name + ' ' + selectedApplicant.middle_name + ' ' + selectedApplicant.last_name;
    } else if (selectedApplicant.first_name && selectedApplicant.last_name) {
      name = selectedApplicant.first_name + ' ' + selectedApplicant.last_name;
    } else if (selectedApplicant.first_name) {
      name = selectedApplicant.first_name;
    }
    if (selectedApplicant.category_name === "Corporate" || selectedApplicant.category_name === "Proprietor" || selectedApplicant.category_name === "HUF") {
      name = selectedApplicant.organization_name;
    }
    const { bureauname, nameon, dob, enquiryAmount,
      gender, mobile_no, address, state, activity, registeradd,
      city, document_no, pin_code, applicant_name, constitutions, identityLabel, typesofloan, score_type
    } = this.state;

    return (
      <>
        <div className="BureauFormWrap">
          <Form
            layout="vertical"
            name="basic"
            form={form}
            scrollToFirstError={{behavior:'smooth',block:'end'}}
            initialValues={{
              user_type: selectedApplicant.applicant_type,
              applicant_name: name,
              applicant_first_name: selectedApplicant.first_name,
              applicant_last_name: selectedApplicant.last_name ? selectedApplicant.last_name : '',
              applicant_middle_name: selectedApplicant.middle_name ? selectedApplicant.middle_name : '',
              report_for: selectedApplicant.category_name
                ? selectedApplicant.category_name
                : "Individual",
              state: selectedApplicant.state_code,
              city: selectedApplicant.city,
              pin_code: selectedApplicant.pin,
              document_no: selectedApplicant.pan_no,
              document_type: bureauname == "CIBIL" ? '01' : 'ID07',
              document: 'PAN Card',
              bureau_name: "CRIF",
              gender: selectedApplicant.gender,
              amount: caseDetail.finance_amount.replaceAll(",", ""),
              date_of_registration:
                category === "Individual"
                  ? undefined
                  : moment(selectedApplicant.date_of_incorporation)._isValid
                    ? moment(selectedApplicant.date_of_incorporation)
                    : null,
              date_of_birth:
                category === "Individual"
                  ? moment(selectedApplicant.dob)._isValid
                    ? moment(selectedApplicant.dob)
                    : undefined
                  : undefined,
              address: selectedApplicant.address_line1,
              phone_no: selectedApplicant.mobile_no,
              address_mentioned: "Default Case Address"
            }}
            onFinish={fetchReport}
            onFinishFailed={this.onFinishFailed}
          >
            <Row gutter={32}>
              <Col span={12} md={12} xs={24} sm={12}>
                <Form.Item label="Generate Report For" name="user_type">
                  <Radio.Group
                    disabled={true}
                    value={selectedApplicant.applicant_type}
                    defaultValue={selectedApplicant.applicant_type}
                  >
                    <Radio value="Main Applicant" defaultChecked="true">
                      Main Applicant
                    </Radio>
                    <Radio value="Co Applicant">Co Applicant</Radio>
                    <Radio value="Guarantor">Guarantor</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="Select Report Generation Parameter"
                  name="bureau_name"
                  rules={[
                    { required: true, message: "Please Select Bureau Name!" },
                  ]}
                >
                  <Radio.Group onChange={this.onChange} value={bureauname}>
                    {(selectedApplicant.category_name === "Individual" || selectedApplicant.member_type) && (
                      <Radio value="CIBIL">CIBIL</Radio>

                    )}
                    <Radio checked={true} value="CRIF">
                      CRIF
                    </Radio>
                    {/* <Radio value='Both'>Both</Radio> */}
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  className="applicantName"
                  // name="applicant_name"
                  name={bureauname === "CIBIL" ? 'applicant_first_name' : "applicant_name"}
                  label={bureauname === "CIBIL" ? 'Applicant First Name' : "Applicant Name"}
                  maxLength={50}
                  rules={[
                    { required: true, message: "Please Enter Applicant Name!" },
                  ]}
                >
                  {/* <Select  defaultValue={selectedApplicant.id} value={selectedApplicant.id}>
                    <Option value={selectedApplicant.id}>{selectedApplicant.first_name} {selectedApplicant.last_name}</Option>
                  </Select> */}
                  <Input
                    value={name}
                    maxLength={50}
                    name="applicant_first_name"
                    placeholder="Applicant Name"
                    onChange={this.nameValidation}
                  />
                </Form.Item>

                {bureauname === "CIBIL" && (<Row gutter={12}>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      className="applicantName"
                      name="applicant_middle_name"
                      label="Middle Name"
                      maxLength={50}

                    >
                      {/* <Select  defaultValue={selectedApplicant.id} value={selectedApplicant.id}>
                    <Option value={selectedApplicant.id}>{selectedApplicant.first_name} {selectedApplicant.last_name}</Option>
                  </Select> */}
                      <Input
                        value={name}
                        maxLength={50}
                        name="applicant_middle_name"
                        placeholder="Applicant Middle Name"
                        onChange={this.nameValidation}
                      />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" span={12}>
                    <Form.Item
                      className="applicantName"
                      name="applicant_last_name"
                      label="Last Name"
                      maxLength={50}

                    >
                      {/* <Select  defaultValue={selectedApplicant.id} value={selectedApplicant.id}>
                    <Option value={selectedApplicant.id}>{selectedApplicant.first_name} {selectedApplicant.last_name}</Option>
                  </Select> */}
                      <Input
                        value={name}
                        maxLength={50}
                        name="applicant_last_name"
                        placeholder="Applicant Last Name"
                        onChange={this.nameValidation}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                )}
                <Form.Item
                  label="Category"
                  className="applicantName"
                  name="report_for"
                >
                  <Select defaultValue="Category" disabled={true}>
                    <Option value="Individual">Individual</Option>
                    <Option value="Corporate">Corporate</Option>
                    <Option value="HUF">HUF</Option>
                    <Option value="Proprietor">Proprietor</Option>
                  </Select>
                </Form.Item>

                <p>
                  Select the parameter basis which report needs to be fetched
                </p>
                {(selectedApplicant.category_name === "Individual" || selectedApplicant.member_type) ? (
                  <Form.Item
                    label="Gender"
                    name="gender"
                    rules={[
                      { required: true, message: "Please Select Gender!" },
                    ]}
                  >
                    <Radio.Group onChange={this.onChangeGender} value={gender}>
                      <Radio value="MALE">Male</Radio>
                      <Radio value="FEMALE">Female</Radio>
                      <Radio value="Other">Other</Radio>
                    </Radio.Group>
                  </Form.Item>
                ) : (
                  ""
                )}

                {/* <Form.Item label="Name as mentioned on" className="applicantName" 
                    name="name_as_mentioned_on"
                    rules={[
                      { required: true, message: "Please Select Name as mentioned on!" },
                    ]}
                     >
                      <Select defaultValue="Select"  onChange={(e) => this.onNameChange(e)}  >
                        {ocrresult && ocrresult.map((doc) => (
                            <Option value={doc.document_name} key={doc.id}>
                              {doc.document_name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item> */}

                {(selectedApplicant.category_name === "Corporate" ||
                  selectedApplicant.category_name === "Proprietor" ||
                  selectedApplicant.category_name === "HUF") &&
                  bureauname === "CIBIL" ? (
                  <Form.Item
                    label="Member Reference Number"
                    name="referencenumber"
                    className="applicantName"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Please Enter Member Reference Number!",
                      //   validateTrigger: true,
                      // },
                      // {
                      //   pattern: /^[1-9][0-9]{5}$/,
                      //   message: "please enter valid Reference Number!",
                      //   validateTrigger: true,
                      // },
                    ]}
                  >
                    <NumberFormat
                      format="##########"
                      placeholder="Member Reference Number"
                      class="ant-input"
                      name="referencenumber"
                      onChange={this.onInputChange}
                    />
                    {/*<Input placeholder="Postal Code" maxLength={6}/>*/}
                  </Form.Item>
                ) : (
                  ""
                )}

                {bureauname === "CRIF" && (<Form.Item
                  label="Class of Activity"
                  className="applicantName"
                  name="class_of_activity"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Class of Activity!",
                    },
                  ]}
                >
                  <Select
                    defaultValue="Select Activity"
                    onChange={this.onSelectChange}
                  >
                    {bureauReportMasters &&
                      Array.isArray(bureauReportMasters.class_of_activity) &&
                      bureauReportMasters.class_of_activity.map((act) => (
                        <Option value={act.name} key={act.id} name="activity">
                          {act.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                )}

                <Form.Item
                  className="applicantName"
                  label={
                    selectedApplicant.category_name === "Corporate" ||
                      selectedApplicant.category_name === "Proprietor" ||
                      selectedApplicant.category_name === "HUF"
                      ? "Loan/Lease Amount"
                      : "Enquiry Amount"
                  }
                  name="amount"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.LOAN_AMOUNT_REQUIRED,
                    },
                  ]}
                // extra="Note: Maximum loan amount is 10B"
                >
                  <NumberFormat
                    placeholder="Loan Amount"
                    class="ant-input"
                    name="enquiryAmount"
                    prefix="&#8377;"
                    isAllowed={(values) => {
                      if (values.value.startsWith("0")) {
                        return false;
                      }
                      if (checkLoanAmountAllowed(values)) {
                        return true;
                      }
                      form.setFields([
                        {
                          name: "loan_amount",
                          errors: [MESSAGES.MAX_AMOUNT_1000],
                        },
                      ]);
                      // toast.error(MESSAGES.MAX_AMOUNT_1000)
                    }}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    thousandSeparator={true}
                    decimalScale={0}
                    className="ant-input"
                    onTouchEnd={this.removeLoanMaxError}
                    onMouseLeave={this.removeLoanMaxError}
                    onChange={this.onInputChange}
                  />
                </Form.Item>
                {selectedApplicant.category_name === "Corporate" ||
                  selectedApplicant.category_name === "Proprietor" ||
                  selectedApplicant.category_name === "HUF" ? (
                  <>
                    <Form.Item
                      label="Legal Constitution"
                      className="applicantName"
                      name="legal_constitution"
                      rules={[
                        {
                          required: true,
                          message: "Please Select Legal Constitution!",
                        },
                      ]}
                    >
                      <Select
                        defaultValue="Select Constitution"
                        onChange={this.onSelectChange}
                      >
                        {bureauReportMasters &&
                          Array.isArray(
                            bureauReportMasters.legal_constitutions
                          ) &&
                          bureauReportMasters.legal_constitutions.map((act) => (
                            <Option
                              value={act.code}
                              key={act.id}
                              name="constitutions"
                            >
                              {act.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </>
                ) : (
                  ""
                )}

                {bureauname === "CRIF" && (<Form.Item
                  label="Types of Loan"
                  className="applicantName"
                  name="loan_type"
                  rules={[
                    { required: true, message: "Please Select Loan Type!" },
                  ]}
                >
                  <Select
                    defaultValue="Select"
                    name="loan_type"
                    onChange={this.onSelectChange}
                  >
                    {bureauReportMasters &&
                      Array.isArray(bureauReportMasters.types_of_loan) &&
                      bureauReportMasters.types_of_loan.map((act) => (
                        <Option
                          value={act.code}
                          key={act.id}
                          name="typesofloan"
                        >
                          {act.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                )}
                <Form.Item
                  label={
                    selectedApplicant.category_name === "Corporate" ||
                      selectedApplicant.category_name === "Proprietor" ||
                      selectedApplicant.category_name === "HUF"
                      ? "Date of registration"
                      : "Date of Birth"
                  }
                  className="applicantName"
                  name={
                    selectedApplicant.category_name === "Corporate" ||
                      selectedApplicant.category_name === "Proprietor" ||
                      selectedApplicant.category_name === "HUF"
                      ? "date_of_registration"
                      : "date_of_birth"
                  }
                  rules={[
                    {
                      required: true,
                      message:
                        selectedApplicant.category_name === "Corporate" ||
                          selectedApplicant.category_name === "Proprietor" ||
                          selectedApplicant.category_name === "HUF"
                          ? " Please select Date of registration"
                          : " Please select Date of Birth",
                    },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledFutureDates}
                    inputReadOnly
                    onChange={this.onDateChange}
                  />
                </Form.Item>
                {bureauname === "CIBIL" && (
                  <Form.Item
                    label="Score Type"
                    className="applicantName"
                    name="score_type"
                    rules={[
                      { required: true, message: "Please Select Purpose!" },
                    ]}
                  //  ref= 'inputRef'
                  >
                    <Select
                      defaultValue="Select"
                      onChange={this.onSelectChange}
                    >
                      {cibilReportMasters &&
                        Array.isArray(cibilReportMasters.scoreType) &&
                        cibilReportMasters.scoreType.map((act) => (
                          <Option
                            value={act.code}
                            key={act.code}
                            name="score_type"
                          >
                            {act.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}

                <Form.Item
                  label="Identification"
                  className="applicantName"
                  name="document"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Identification Type!",
                    },
                  ]}
                >
                  <Select
                    defaultValue="PAN Card"
                    onChange={this.onSelectAdressChange}
                  >
                    {ocrresult &&
                      Array.isArray(ocrresult) &&
                      ocrresult.map((doc) => (
                        <Option value={doc.document_name} key={doc.id}>
                          {doc.document_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
                {identityLabel == OCR_DOCUMENT_LABEL.PAN_CARD ? (
                  <Form.Item
                    label="PAN No."
                    className="applicantName"
                    name="document_no"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.PAN_NUMBER_REQUIRED,
                      },
                      {
                        pattern: REGEX.PAN_NUMBER_REGEX,
                        message: MESSAGES.INVALID_PAN_NUMBER,
                        validateTrigger: true,
                      },
                    ]}
                  >
                    <Input
                      name="document_no"
                      placeholder="ABCDE1234F"
                      maxLength={10}
                      onChange={this.onInputChange}
                    />
                  </Form.Item>
                ) : 
                  identityLabel == OCR_DOCUMENT_LABEL.AADHAR_CARD ? <Form.Item
                    label="Document No."
                    className="applicantName"
                    name="document_no"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Document No",
                      },
                      {
                          len:12,
                          message:MESSAGES.AADHAR_NUMBER_INVALID,
                          validateTrigger: true,

                        },
                        {
                          pattern:REGEX.NUMBER_ONLY_REGEX,
                          message:'Only numbers are allowed'
                        }
                    ]}
                  >
                    <Input
                      maxLength={15}
                      name="document_no"
                      onChange={this.onInputChange}
                    />
                  </Form.Item>:<Form.Item
                    label="Document No."
                    className="applicantName"
                    name="document_no"
                    rules={[
                      {
                        required: true,
                        message: "Please enter Document No",
                      },
                      
                    ]}
                  >
                    <Input
                      maxLength={15}
                      name="document_no"
                      onChange={this.onInputChange}
                    />
                  </Form.Item>}
                  
                
                <h3>
                  <u style={{ color: "#4D4F5C" }}>Contact Details</u>

                </h3>
                <Form.Item
                  label="Mobile No"
                  name="phone_no"
                  className="applicantName"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.MOBILE_NUMBER_REQUIRED,
                    },
                  ]}
                >
                  <NumberFormat
                    placeholder="9986437788"
                    type="tel"
                    format="+91##########"
                    class="ant-input"
                    name="mobile_no"
                    onChange={this.onInputChange}
                  />
                  {/*<Input placeholder="12467989" />*/}
                </Form.Item>
                {bureauname === "CRIF" && (
                  <Form.Item
                    label="Registered Office Address Type"
                    className="applicantName"
                    name="address_type"
                    rules={[
                      { required: true, message: "Please Select Address Type!" },
                    ]}
                    ref="inputRef"
                  >
                    <Select defaultValue="Select" onChange={this.onSelectChange}>
                      {bureauReportMasters &&
                        Array.isArray(bureauReportMasters.address_types) &&
                        bureauReportMasters.address_types.map((act) => (
                          <Option
                            value={act.code}
                            key={act.id}
                            name="registeradd"
                          >
                            {act.name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                )}
                <Form.Item
                  label="Address as mentioned on"
                  className="applicantName"
                  name="address_mentioned"
                // rules={[
                //   { required: true, message: "Please Select Address as mentioned On!" },
                // ]}
                >
                  <Select
                    // defaultValue={OCR_DOCUMENT_LABEL.GST_REGISTRATION}
                    placeholder='Address as mentioned on'
                    onChange={this.onSelectAddMention}
                  >
                    <Option value="Default Case Address" >
                      Default Case Address
                    </Option>
                    {ocrresult &&
                      Array.isArray(ocrresult) &&
                      ocrresult.map((doc) =>
                        doc.document_name === OCR_DOCUMENT_LABEL.PAN_CARD ? (
                          ""
                        ) : (
                          <Option value={doc.document_name} key={doc.id}>
                            {doc.document_name}
                          </Option>
                        )
                      )}
                  </Select>
                </Form.Item>
                {/* {bureauname ==='CRIF' && */}


                {/* } */}

                <div className="labelbottom">
                  <Form.Item
                    label="Address"
                    name="address"
                    className="applicantName"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.ADDRESS_REQUIRED,
                      },
                    ]}
                  >
                    <Input.TextArea
                      placeholder="Address"
                      name="address"
                      onChange={this.onInputChange}
                    />
                  </Form.Item>

                  {/*<p className="bottom">
                    {selectedApplicant.address_taken_from}
                  </p>*/}
                </div>
                {/* <Form.Item
                  label="State"
                  className="applicantName"
                  name="state"
                  rules={[{ required: true, message: "Please Select State!" }]}
                >
                    <Select
                      defaultValue={selectedApplicant.state_code}
                      onChange={(e) => this.onSelectStateChange(e)}
                    >
                      {states &&
                        states.map((state) => (
                          <Option value={state.state_code} key={state.id}>
                            {state.state_name}
                          </Option>
                        ))}
                    </Select>
                </Form.Item> */}
                <Form.Item
                  label="State"
                  className="applicantName"
                  name="state"
                  rules={[{ required: true, message: "Please Select State!" }]}
                >
                  {bureauname === "CRIF" ? (
                    <Select
                      showSearch
                      placeholder="Search State"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }

                      defaultValue={selectedApplicant.state_code}
                      onChange={(e) => this.onSelectStateChange(e)}

                    >
                      {states &&
                        states.map((state) => (
                          <Option value={state.state_code} key={state.id}>
                            {state.state_name}
                          </Option>
                        ))}
                    </Select>
                  ) : (
                    <Select
                      showSearch
                      placeholder="Select State"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      filterSort={(optionA, optionB) =>
                        optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                      }

                      defaultValue={selectedApplicant.states_cibil_code}
                      onChange={(e) => this.onSelectStateChange(e)}

                    >
                      {cibilReportMasters &&
                        Array.isArray(
                          cibilReportMasters.statePinCodeStandardization
                        ) &&
                        cibilReportMasters.statePinCodeStandardization.map(
                          (state) => (
                            <Option
                              value={state.stateCode}
                              key={state.stateCode}
                            >
                              {state.standardState}
                            </Option>
                          )
                        )}
                    </Select>
                  )}
                </Form.Item>
                <Form.Item
                  label="City"
                  className="applicantName"
                  name="city"
                  rules={[{ required: true, message: "Please Select City!" }]}
                >
                  <Select
                    onChange={(e) => this.onSelectCityChange(e)}
                    showSearch
                    placeholder="Select City"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                  >
                    {bureauname === "CRIF" ? (
                      cities &&
                      cities.map((c) => (
                        <Option value={c.city_name} key={c.id}>
                          {c.city_name}
                        </Option>
                      )))
                      :
                      (cibilcities &&
                        cibilcities.map((c) => (
                          <Option value={c.city_name} key={c.id}>
                            {c.city_name}
                          </Option>
                        )))}
                  </Select>
                </Form.Item>


                <Form.Item
                  label="PIN Code"
                  name="pin_code"
                  className="applicantName"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.PIN_CODE_REQUIRED,
                      validateTrigger: true,
                    },
                    {
                      pattern: /^[1-9][0-9]{5}$/,
                      message: MESSAGES.INVALID_PIN_CODE,
                      validateTrigger: true,
                    },
                  ]}
                >
                  <NumberFormat
                    format="######"
                    placeholder="Pin Code"
                    class="ant-input"
                    name="pin_code"
                    onChange={this.onInputChange}
                  />
                  {/*<Input placeholder="Postal Code" maxLength={6}/>*/}
                </Form.Item>

                <Form.Item name="document_no">
                  <Input type="hidden" />
                </Form.Item>
                <Form.Item name="adhar_card_no">
                  <Input type="hidden" />
                </Form.Item>

                <Form.Item name="document_type">
                  <Input type="hidden" />
                </Form.Item>
              </Col>
              <Col span={12} md={12} xs={24} sm={12}>
                <div className="LeftInfoArea">
                  <h4>Selected Parameters</h4>
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>
                          {selectedApplicant.member_type
                            ? selectedApplicant.applicant_type +
                            " " +
                            selectedApplicant.member_type
                            : selectedApplicant.applicant_type}
                        </label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{bureauname === "CIBIL" ? this.state.applicant_first_name + ' ' + this.state.applicant_middle_name + ' ' + this.state.applicant_last_name : applicant_name}</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Bureau Name</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <Checkbox checked={true}>{bureauname}</Checkbox>
                      </Col>
                    </Row>
                  </Form.Item>

                  {bureauname === "CRIF" && (<Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Class of Activity</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{activity ? activity : '-'} </p>
                      </Col>
                    </Row>
                  </Form.Item>
                  )}
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>
                          {" "}
                          {selectedApplicant.category_name === "Corporate" ||
                            selectedApplicant.category_name === "Proprietor" ||
                            selectedApplicant.category_name === "HUF"
                            ? "Date of registration"
                            : "Date of Birth"}{" "}
                        </label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{dob ? dob : '-'}</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  {bureauname === "CIBIL" && (
                    <Form.Item>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Score Type</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p>{score_type ? score_type : '-'}</p>
                        </Col>
                      </Row>
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>{identityLabel}</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{document_no ? document_no : '-'}</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>
                          {selectedApplicant.category_name === "Corporate" ||
                            selectedApplicant.category_name === "Proprietor" ||
                            selectedApplicant.category_name === "HUF"
                            ? "Loan/Lease Amount"
                            : "Enquiry Amount"}
                        </label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{enquiryAmount ? enquiryAmount : '-'}</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  {bureauname === "CRIF" &&  (
                    <>
                      <Form.Item>
                        <Row gutter={16}>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <label>Types of Loan</label>
                          </Col>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <p>{typesofloan ? typesofloan : '-'}</p>
                          </Col>
                        </Row>
                      </Form.Item>
                      <Form.Item>
                        <Row gutter={16}>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <label>Legal Constitutions</label>
                          </Col>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <p>{constitutions ? constitutions : '-'}</p>
                          </Col>
                        </Row>
                      </Form.Item>
                    </>
                  )}
                  {selectedApplicant.category_name === "Individual" || selectedApplicant.member_type ? (
                    <Form.Item>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Gender</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p>{gender ? gender : '-'}</p>
                        </Col>
                      </Row>
                    </Form.Item>
                  ) : (
                    ""
                  )}
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Phone no</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        {/* <p>{selectedApplicant.mobile_no? selectedApplicant.mobile_no:'N/A'}</p> */}
                        <p>{mobile_no ? mobile_no : '-'}</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  {bureauname === "CRIF" && (
                    <Form.Item>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Registered Office Address Type</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p>{registeradd ? registeradd : '-'}</p>
                        </Col>
                      </Row>
                    </Form.Item>
                  )}
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>Address</label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{address ? address : '-'}</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>State </label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{state ? state : '-'}</p>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>City </label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{city ? city : '-'}</p>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Form.Item>
                    <Row gutter={16}>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <label>PIN Code </label>
                      </Col>
                      <Col span={12} md={12} sm={12} xs={12}>
                        <p>{pin_code ? pin_code : '-'}</p>
                      </Col>
                    </Row>
                  </Form.Item>

                  <Row>
                    <Col
                      span={24}
                      md={24}
                      sm={24}
                      xs={24}
                      className="text-center mt-2"
                    >
                      <Button
                        className="FetchBtn"
                        type="primary"
                        htmlType="submit"
                        disabled={buttonDisable}
                      >
                        Fetch Report
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}
export default BureauReportForm;
