import React, { useState } from "react";
import { Button, Col, Form, Input, Row, Select, Space, Table } from "antd";
import ViewIcon from "../../assets/images/view.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import hunterData from "./hunterData";
import { RunRequestBox } from "./modals/RunRequestBox";
import moment from "moment";
import { onCaseDetail } from "../../redux/actions/CaseAction";
import {
  onKycDoneCasesLoad,
  onHunterSummaryList,
  onDownloadHunterReport,
} from "../../redux/actions/HunterAction";
import { Link } from "react-router-dom";
import { loggedInUserData } from "../../helpers/utils";
function Index() {
  const { Option } = Select;
  const dispatch = useDispatch();
  const [hunterList, setHunterList] = useState([]);
  const [searchedInput, setSearchedInput] = useState("");
  const [dataList, setdataList] = useState();
  const [data, setData] = useState([]);
  const [cases, setCaseList] = useState([]);
  const [caseId, setCaseId] = useState("");
  const [alertBoxVisible, setalertBoxVisible] = useState(false);
  const loginUserData = loggedInUserData();
  const formData = new FormData();

  const downloadTemplate = () => {
    // dispatch(onDownloadTemplate(onDownloaded))
  };

  // console.log(tableData,"tableData")
  const onDownloaded = (responseData) => {
    setalertBoxVisible(true);
  };

  const onSuccess = (responseData) => {
    setdataList(responseData?.data?.data);
    setHunterList(responseData?.data?.data);
  };
  const onCasesSuccess = (responseData) => {
   
  };
  const onloadScreen = (responseData) => {
    setData(responseData?.data?.data);
  };

  const fetchData = () => {
    dispatch(onHunterSummaryList(onSuccess));
  };
  const reRunCase=(e)=>{
    setCaseId(e.caseId);
    setalertBoxVisible(true);
    // setdataList(e);
    dispatch(onCaseDetail(e, onCasesSuccess));
  }

  const runHunterRequest = (e) => {
    console.log(e,"yadudu")
    setCaseId(e.caseId);
    // setdataList(e);
    setalertBoxVisible(true);
    dispatch(onCaseDetail(e, onCasesSuccess));
  };

  useEffect(() => {
    dispatch(onKycDoneCasesLoad(onloadScreen));
    fetchData();
  }, []);

  const onDownloadSuccess = (response) => {
    const url = response.data.file;
    const outputFilename = `${response.data.name}.xls`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  const downloadHunterReport = () => { 
    dispatch(onDownloadHunterReport(onDownloadSuccess));
  };

  const deleteHunterCancel = () => {
    setalertBoxVisible(false);
    fetchData();
    form.resetFields(["caseId"]);

  };

  const columns = [
    // {
    //   title: "Request Id",
    //   dataIndex: "hunter_request_id",
    //   key: "hunter_request_id",
    // },
    {
      title: "Case Id",
      dataIndex: "case_id",
      key: "case_id",
      sorter: (a, b) => a.case_id - b.case_id,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name"
    },
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name"
    },
    {
      title: "Status",
      dataIndex: "hunter_status",
      key: "hunter_status",
    },
    {
      title: "Last Updated Date",
      dataIndex: "hunter_updated_date",
      key: "hunter_updated_date",
      render: (text, row, record) => {
        return <div>{moment(row.hunter_updated_date).format("ll LT")}</div>;
      },
    },
    {
      title: "Final Decision",
      dataIndex: "final_decision",
      key: "final_decision",
    },
    {
      title: "Work Status",
      dataIndex: "work_status",
      key: "work_status",
      render: (text, row, record) => {
        if (row.work_status) {
          return <div>{row.work_status }</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      title: "Fraud Status",
      dataIndex: "fraud_status",
      key: "fraud_status",
      render: (text, row, record) => {
        if (row.fraud_status) {
          return <div>{row.fraud_status }</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      title: "Descision Date",
      dataIndex: "hunter_web_response_date",
      key: "hunter_web_response_date",
      render: (text, row, record) => {
        if (row.hunter_web_response_date) {
          return <div>{moment(row.hunter_web_response_date).format("ll LT")}</div>;
        } else {
          return <div>N/A</div>;
        }
      },
    },
    {
      title: "Sales Allocated to",
      dataIndex: "sales_allocated_name",
      key: "sales_allocated_name",
      className: loginUserData.user_type == "Credit" ? "show" : "hide",
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, row, record) => {
        console.log(row, "xyzzz");
        return (
          <div>
            <Space size="middle">
              <Link to={`/hunter-response-view/${row.hunter_request_id}`}>
                <img
                  src={ViewIcon}
                  alt={row.hunter_request_id}
                  title="Click to view the run results"
                />
              </Link>

              {/* <Button
                className="AddField"
                onClick={() => {
                  downloadHunterReport(row.hunter_request_id);
                }}
                title="Click to download consolidated results for this case ID"
              >
                Download
              </Button> */}
               {loginUserData && loginUserData.user_type === "Sales" ? (
  <>    
              {row?.hunter_status == "Error" && (
                <Button className="AddField" title="Re Run"
                onClick={() => {
                  const payload={
                    caseId:row.case_id,
                  }
                  reRunCase(payload);
                }}
                >
                  Re Run
                </Button>
              )}
  </>
) : (
  <>  </>
)}
            </Space>
          </div>
        );
      },
    },
  ];

  const [form] = Form.useForm();

  const filterhunterCaseListing = (e) => {
    if (e.target.value === "") {
      setHunterList(dataList);
    } else {
      const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
      const filteredCases = dataList.filter((userCase) => {
        let casesById = userCase.case_id.toString().includes(searchedInput);
        return casesById;
      });
      setHunterList(filteredCases);
    }
  };

  return (
    <>
      <div
        className="FilterForm formexpandedfilter fleet-analy"
        runHunterRequest
      >
        <Form
          name="global_state"
          layout="inline"
          form={form}
          onFinish={runHunterRequest}
        >
          <Row align="top">
            <Col span={6}>
            {loginUserData && loginUserData.user_type === "Sales" ? (
              <Form.Item
                name="caseId"
                className="required"
                label="Select Case*"
                rules={[{ required: true, message: "Please select case id" }]}
              >
                <Select
                  showSearch
                  placeholder="Select case id"
                  optionFilterProp="children"
                  dropdownStyle={{ minWidth: "50%", height: "auto" }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {data?.map((category, index) => (
                    <Option value={category.case_id} key={index}>
                      {category.case_id + " " + category.customer_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            ) : (
              <></>
            )}
            </Col>
            {loginUserData && loginUserData.user_type === "Sales" ? (
            <Col
              span={6}
              flex="150px"
              style={{ marginTop: "35px", marginLeft: "40px" }}
            >
              <Button
                size="large"
                type="primary"
                htmlType="submit"
                className="AddField  "
                title="Click to open participants details"
              >
                Run
              </Button>
            </Col>
            ) : (
              <></>
            )}
            <Col
              span={6}
              flex="150px"
              style={{ marginTop: "35px", marginLeft: "auto" }}
            >
            <Button
                className="AddField"
                onClick={() => {
                  downloadHunterReport();
                }}

                title="Click to download consolidated results for this case ID"
              >
                Download
              </Button>
              </Col>
          </Row>
        </Form>
      </div>
      <div className="CaseListData CaseListDataMobile">
        <Row>
          <Col span={12}>
            <Form>
              <Input.Group compact>
                <Input.Search
                  allowClear
                  placeholder="Search On Case ID"
                  value={searchedInput}
                  onChange={(e) => {
                    setSearchedInput(e.target.value);
                    filterhunterCaseListing(e);
                  }}
                />
              </Input.Group>
            </Form>
          </Col>
        </Row>

        <Table columns={columns} dataSource={hunterList} pagination={true} />
      </div>
      <RunRequestBox
        deleteVisible={alertBoxVisible}
        deleteCancel={deleteHunterCancel}
        closeModal={() => {
          setalertBoxVisible(false);
        }}
        caseId={caseId}
      />
    </>
  );
}

export default Index;
