import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Button,
  Col,
  Input,
  Radio,
  Modal,
  DatePicker,
} from "antd";
import { MESSAGES, REQUIRED_MESSAGES } from "../../constants/messages";
import { useDispatch } from "react-redux";
import { getVahanRequestDetails } from "../../redux/actions/VaahanAction";
import moment from "moment";

const VahanRequestDetailModal = (props) => {
  const [fromDate,setFromDate]=useState('');
  const [toDate,setToDate]=useState('');
  const dispatch=useDispatch();
  const { Option } = Select;
  const {data,type}=props;
 
  const onSuccess = (response) => {
    const resData = response?.data?.data;
 
    
    if(type==="HPA"){
      props.form.setFieldsValue({
        chachis_no: resData?.chachis_no,
        eng_no: resData?.eng_no,
        type:type,
        vahan_request_id:data?.vahan_request_id
      });
    }else if(type==="HPC"){
      if(resData?.from_date){
        props.form.setFieldsValue({
        from_date: moment(resData?.from_date),
          
        })
      }
      if(resData?.upto_date){
        props.form.setFieldsValue({
          upto_date: moment(resData?.upto_date),
        })
      }
      props.form.setFieldsValue({
        chachis_no: resData?.chachis_no,
        reg_no: resData?.reg_no,
        type:type,
        vahan_request_id:data?.vahan_request_id
      });
    }
    else if(type==="HPT"){
      props.form.setFieldsValue({
        chachis_no: resData?.chachis_no,
        reg_no: resData?.reg_no,
        type:type,
        vahan_request_id:data?.vahan_request_id
      });
    }
    
   
  };
  const getDetails=()=>{
    const payload = {
        "vahan_request_id" :data?.vahan_request_id,
        "type" : type
    }
    dispatch(getVahanRequestDetails(payload,onSuccess))
  }

  useEffect(() => {
    getDetails()
  },[])

  return (
    <>
      <Modal
        title="Vahan Request Detail"
        visible={props.isModalVisible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        footer={[
          <Button key="back" onClick={props.handleCancel}>
            Cancel
          </Button>,
          <Button form="vahanRequestForm" key="submit" htmlType="submit">
            Submit
          </Button>,
        ]}
        width={600}
        className="JustReqWrap"
        style={{ top: 100 }}
        destroyOnClose={true}
      >
        <Form
          form={props.form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          onFinish={props.handleOk}
          id="vahanRequestForm"
        >
         {<Form.Item
            label="Type"
            className="applicantName"
            name="type"
            style={{display:"none"}}
            
          >
            <Input/>
          </Form.Item>}
          {<Form.Item
            label="Vahan Request Id"
            className="applicantName"
            name="vahan_request_id"
            style={{display:"none"}}
            
          >
            <Input/>
          </Form.Item>}
          {(type==="HPA" || type==="HPC" || type==="HPT") && <Form.Item
            label="Chassis No"
            className="applicantName"
            name="chachis_no"
            rules={[
                  {
                    required: true,
                    message: "Please enter chassis no",
                  },
                ]}
          >
            <Input />
          </Form.Item>}
         

          {(type==="HPA") &&<Form.Item
            label="Engine No"
            name="eng_no"
            className="UserNameselector"
            rules={[
                  {
                    required: true,
                    message: "Please enter engine no",
                  },
                ]}
          >
            <Input />
          </Form.Item>}
          {( type==="HPC" || type==="HPT") &&<Form.Item
            label="Registration No"
            name="reg_no"
            className="UserNameselector"
            rules={[
                  {
                    required: true,
                    message: "Please enter registration no",
                  },
                ]}
          >
            <Input />
          </Form.Item>}
          <span style={{display:"-webkit-inline-box"}}>
          {(type==="HPA" || type==="HPC") &&<Form.Item
            label="From Date"
            name="from_date"
            rules={[
                  {
                    required: true,
                    message: "Please select from date",
                  },
                ]}
           
          >
            <DatePicker  format={'ll'} />
          </Form.Item>}
          {(type==="HPA" || type==="HPC") &&<Form.Item
            label="Upto Date"
            name="upto_date"
            rules={[
                  {
                    required: true,
                    message: "Please select upto Date",
                  },
                ]}
          >
            <DatePicker  format={'ll'}/>
          </Form.Item>}
          {type==="HPT" &&<Form.Item
            label="Termination Date"
            name="termination_date"
            rules={[
                  {
                    required: true,
                    message: "Please select termination date",
                  },
                ]}
          >
            <DatePicker format={'ll'}/>
          </Form.Item>}
          </span>
        </Form>
      </Modal>
    </>
  );
};

export default VahanRequestDetailModal;
