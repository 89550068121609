import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { OCR_DOCUMENT_LABEL } from "../../../../../constants/constants";
const PanCard = () => {
  const ocrResult = useSelector((state) => state.auth.currentOcrResult);
  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  const [panOcrResult, setPanOcrResult] = useState({});
  const [panKycResult, setPanKycResult] = useState({});
  useEffect(() => {
    let pan = ocrResult.find((ocr_result) => {
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.PAN_CARD;
    });
    if (pan) {
      setPanOcrResult(pan);
    }
  }, [ocrResult]);
  useEffect(() => {
    let panKyc = kycResult.find((ocr_result) => {
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.PAN_CARD;
    });
    if (panKyc) {
      setPanKycResult(panKyc);
    }
  }, [kycResult]);
  return (
    <>
      <div className="CardBlock">
        <h3>PAN Card</h3>
        <Card>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>PAN Number</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(panOcrResult && panOcrResult.pan_no)?panOcrResult.pan_no : "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Entity Name Linked to the PAN</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(panOcrResult && panOcrResult.name) ?panOcrResult.name: "-"}</p>
            </Col>
          </Row>
          {panOcrResult && panOcrResult.father && <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Father Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(panOcrResult && panOcrResult.father) ?panOcrResult.father: "-"}</p>
            </Col>
          </Row>}
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(panOcrResult && panOcrResult.dob)?panOcrResult.dob:'-'}</p>
            </Col>
          </Row>

          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Duplicate PAN Exists</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(panKycResult && panKycResult.duplicate_pan)?panKycResult.duplicate_pan:'-'}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Name Matching With Given Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {(panKycResult &&
                panKycResult.name_matching_result)?panKycResult.name_matching_result:'-'}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {(panKycResult &&
                panKycResult.dob_matching_result)?panKycResult.dob_matching_result:'-'}
              </p>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default PanCard;
