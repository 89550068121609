export const MESSAGES = {
  LoginSuccess: "You are logged in successfully",
  LoginFailed: "Please enter valid creadentials",
  DefaultError: "Something went wrong, please try again.",
  INTERNET_DISCONNECTED_ERROR: "Network Error",
  RegisterSuccess: "You are registered successfully.",
  RegisterFailed: "Some issue in Registration",
  DefaultSuccess: "Success",
  MAX_AMOUNT_1000: "Loan amount can not be more than 10 billion",
  MAX_QUANTITY_1000: "Maximum quantity can be 10000",
  INVALID_PAN_NUMBER: "Please enter valid pan number",
  INVALID_EMAIL: "Please enter valid email",
  INVALID_PIN_CODE: "Please enter valid PIN code",
  INVALID_CIN_NUMBER: "Please enter valid CIN",
  INVALID_MOBILE_NUMBER: "Please enter valid mobile number",
  COMMENT_MAX_LENGTH: "Comment can be of 250 characters",
  COMMENT_MIN_LENGTH: "Please enter minimum 20 characters",
  COMMENT_MIN_LENGTH_5: "Please enter minimum 5 characters",
  ENTER_DOCUMENT_NAME: "Please enter document name",
  FILE_NAME_EXISTS: "The document with this name is already added.",
  PLEASE_UPLOAD_DOCUMENT: "Please upload document",
  MAX_COAPPLICANT_ERROR: "Maximum 5 co-applicants can be added",
  MAX_DIRECTOR_ERROR: "Maximum 5 directors can be added",
  MAX_MEMBER_ERROR: "Maximum 5 members can be added",
  MAX_GUARANTOR_ERROR: "Maximum 5 guarantors can be added",
  AADHARD_CARD_NOTE: "Note: Aadhar Card must include Front and Back picture",
  VOTER_ID_NOTE: "Note: Voter ID must include Front and Back picture",
  NO_COMMUNICATION_YET: "There has been no communication yet",
  GST_NUMBER_INVALID: "GST Number is Invalid",
  AADHAR_NUMBER_INVALID: "Aadhar Number is Invalid",
  FROM_DATE_MUST_BE_LESS_THAN_TO_DATE: "From date must be less than To date",
  WAIT_FOR_CREDIT_REVIEW_RESPONSE:
    "Please wait for credit review response for making any changes",
};
export const REQUIRED_MESSAGES = {
    TIN_REQUIRED:'TIN is required',
    // GST_REGISTRATION_REQUIRED:'GST Registration No. is required',
    REGISTERED_PLACE_REQUIRED:'Registered Office Place is required',
    ORGANIZATION_NAME_REQUIRED:'Organization Name is required',
    FIRST_NAME_REQUIRED:'First Name is required',
    CONTACT_PERSON_NAME_REQUIRED:'Contact Person Name is required',
    LAST_NAME_REQUIRED:'Last Name is required',
    MOBILE_NUMBER_REQUIRED:'Mobile Number is required',
    GENDER_REQUIRED:'Gender is required',
    PAN_NUMBER_REQUIRED:'PAN Number is required',
    DOB_REQUIRED:'Date of birth is required',
    DOI_REQUIRED:'Date of Incorporation is required',
    CITY_REQUIRED:'City is required',
    CIN_REQUIRED:'CIN is required',
    STATE_REQUIRED:'State is required',
    ADDRESS_REQUIRED:'Address is required',
    PIN_CODE_REQUIRED:'PIN Code is required',
    APPLICATION_CATEGORY_REQUIRED:'Application Category is required',
    COAPPLICANT_CATEGORY_REQUIRED:'Co-applicant Category is required',
    GUARANTOR_CATEGORY_REQUIRED:'Guarantor Category is required',
    APPLICATION_SUB_CATEGORY_REQUIRED:'Application Sub Category is required',
    BUSINESS_AREA_REQUIRED:'Business Area is required',
    PRODUCT_NAME_REQUIRED:'Product Segment is required',
    PRODUCT_MODAL_NUMBER_REQUIRED:'Product Description is required',
    CUSTOMER_NAME_REQUIRED:'Customer Name is required',
    QUANTITY_REQUIRED:'Quantity is required',
    FINANCE_TYPE:'Finance Type is required',
    LOAN_AMOUNT_REQUIRED:'Loan Amount is required',
    MAX_QUANTITY_MESSAGE:'The quantity required may not be greater than 10000',
    PAN_CARD_REQUIRED:'PAN card required',
    ADHAR_CARD_REQUIRED:'Aadhar card required',
    GST_NUMBER_REQUIRED:'GST number required',
    GST_REGISTRATION_REQUIRED:'GST registration required',
    TRUST_DEED_REQUIRED:'Trust deed required',
    SOCIETY_DEED_REQUIRED:'Society deed required',
    PARTNERSHIP_DEED_REQUIRED:'Partnership deed required',
    HUF_DEED_REQUIRED:'HUF deed required',
    LOAN_DURATION_REQUIRED:'Loan duration required',
    BANK_STATEMENT_REQUIRED:'Bank statement required',
    CUSTOMER_CONSENT_REQUIRED:'Customer consent required',
    INCORPORATE_CERTIFICATE_REQUIRED:'Incorporate certificate required',
    SHAREHOLDING_PATTERN_REQUIRED:'Shareholding pattern required',
    MSME_CERTIFICATE_REQUIRED:'MSME certificate required',
    RELATIONSHIP_REQUIRED:'Please select relationship with applicant',
    OTHER_RELATION_REQUIRED:'Please enter relationship with applicant',
    PLEASE_SAVE_MAIN_APPLICANT:'Please save Main Applicant documents',
    PLEASE_SAVE_ATLEASE_ONE_CO_APPLICANT:"Please save atleast one Co-applicant",
    PLEASE_SAVE_ATLEASE_ONE_GUARANTOR:"Please save atleast one Guarantor",
    PLEASE_ADD_COMMENT:'Please add justification comment',
    PLEASE_UPLOAD_ATLEAST_ONE_POA_DOCUMENT:'Atleast one POA Document is mandatory',
}
export const CONSTANTS = {
    CANCEL_APPLICANT:'Are you sure you want to leave?'
}
// export const REGEX = {
//     NUMBER_ONLY_REGEX:/^[0-9]*$/,
//     NOT_START_WITH_0_REGEX:/^[1-9]\d*$/,
//     MAX_AMOUNT_1000_REGEX:/^([1-9][0-9]{0,2}|1000/,
// }