import React, { Component, useEffect, useState } from "react";
import {
  Form,
  Select,
  DatePicker,
  Button,
  Row,
  Col,
  Input,
  Table,
  Tag,
  Space,
} from "antd";
import { EyeFilled, SyncOutlined } from "@ant-design/icons";
import ViewIcon from "../../assets/images/view.png";
import ReloadIcon from "../../assets/images/reload.png";
import EditIcon from "../../assets/images/edit.png";
import { Link } from "react-router-dom";
import { onCaseMasters, onGetLoggedStatusCases } from "../../redux/actions/AuthAction";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { loggedInUserData } from "../../helpers/utils";
import CaseListing from "../common/CaseListing";
const { Option } = Select;

const FilterNewCaseList = (props) => {
  return (
    <CaseListing listing='new-cases'/>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    userCases: auth.userCases ? auth.userCases : [],
    role: auth.role,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
  };
};
export default connect(mapStateToProps, null)(FilterNewCaseList);

// import React, { Component } from "react";
// import { Form, Select, DatePicker, Button, Row, Col, Input, Table, Tag, Space  } from "antd";
// import {EyeFilled, SyncOutlined} from '@ant-design/icons';
// import ViewIcon from '../../assets/images/view.png';
// import ReloadIcon from '../../assets/images/reload.png';
// import EditIcon from '../../assets/images/edit.png';
// import {Link} from "react-router-dom";
// const { Option } = Select;
// const columns = [
//   {
//     title: 'Case I',
//     dataIndex: 'Case',
//     key: 'Case',

//   },
//   {
//     title: 'Applicant Category',
//     dataIndex: 'ApplicantCategory',
//     key: 'ApplicantCategory',
//     sorter: (a, b) => a.ApplicantCategory - b.ApplicantCategory,
//   },
//   {
//     title: 'Business Area',
//     dataIndex: 'BusinessArea',
//     key: 'BusinessArea',
//     sorter: (a, b) => a.BusinessArea - b.BusinessArea,
//   },
//   {
//     title: 'Product Type',
//     dataIndex: 'ProductType',
//     key: 'ProductType',
//     sorter: (a, b) => a.ProductType - b.ProductType,
//   },
//   {
//     title: 'Product Name',
//     dataIndex: 'ProductName',
//     key: 'ProductName',
//     sorter: (a, b) => a.ProductName - b.ProductName,
//   },
//   {
//     title: 'Model No.',
//     dataIndex: 'ModelNo',
//     key: 'ModelNo',

//   },
//   {
//     title: 'Qty.',
//     dataIndex: 'Qty',
//     key: 'Qty',

//   },
//   {
//     title: 'Amount Require.',
//     dataIndex: 'AmountRequire',
//     key: 'AmountRequire',

//   },
//   {
//     title: 'Dealer Name',
//     dataIndex: 'DealerName',
//     key: 'DealerName',
//     sorter: (a, b) => a.DealerName - b.DealerName,
//   },
//   {
//     title: 'Dealer Location',
//     dataIndex: 'DealerLocation',
//     key: 'DealerLocation',
//     sorter: (a, b) => a.DealerLocation - b.DealerLocation,
//   },
//   {
//     title: 'Action',
//     key: 'action',
//     render: (text, record) => (
//       <Space size="middle">
//         <Link to='/view-cases'><img src={ViewIcon} alt='View' /></Link>
//         <Link to='/view-cases'><img src={EditIcon} alt='Edit' /></Link>
//         <Link to='/view-cases'><img src={ReloadIcon} alt='Reload' /></Link>
//       </Space>
//     ),
//   },
// ];

// const data = [
//   {
//     key: '1',
//     Case: '234',
//     ApplicantCategory: 'Individual',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['nice', 'developer'],
//   },
//   {
//     key: '2',
//     Case: '235',
//     ApplicantCategory: 'Corporate',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['loser'],
//   },
//   {
//     key: '3',
//     Case: '236',
//     ApplicantCategory: 'Corporate',
//     BusinessArea: 'Volvo Bus',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['cool', 'teacher'],
//   },

//   {
//     key: '4',
//     Case: '237',
//     ApplicantCategory: 'Individual',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['cool', 'teacher'],
//   },

//   {
//     key: '5',
//     Case: '238',
//     ApplicantCategory: 'Individual',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['cool', 'teacher'],
//   },

//   {
//     key: '6',
//     Case: '239',
//     ApplicantCategory: 'Individual',
//     BusinessArea: 'Eicher Trucks',
//     ProductType: 'Heavy Duty Truck',
//     ProductName:'Tipper',
//     ModelNo:'PRO6-01',
//     Qty:'1',
//     AmountRequire:'150000000',
//     DealerName:'Prem Auto Enterprise',
//     DealerLocation:'Agra',
//     tags: ['cool', 'teacher'],
//   },
// ];
// class FilterNewCaseList extends Component {
//   render() {
//     return (
//       <>
//         <div className="FilterList">
//           <div className="HeadWrap">
//             <h3>Filter the Case List</h3>
//           </div>
//         </div>
//         <div className="FilterForm">
//           <Form name="global_state" layout="inline">
//             <Row>
//               <Col>
//                 <Form.Item
//                   name="ApplicationCategory"
//                   label="Application Category"
//                 >
//                   <Select defaultValue="All">
//                     <Option value="Category1">Category 1</Option>
//                     <Option value="Category2">Category 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="BusinessArea" label="Business Area">
//                   <Select defaultValue="All">
//                     <Option value="BusinessArea1">Business Area 1</Option>
//                     <Option value="BusinessArea2">Business Area 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="ProductType" label="Product Type">
//                   <Select defaultValue="All">
//                     <Option value="ProductType">Product Type 1</Option>
//                     <Option value="ProductType">Product Type 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="ProductName" label="Product Name">
//                   <Select defaultValue="All">
//                     <Option value="ProductName">Product Name 1</Option>
//                     <Option value="ProductName">Product Name 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="ModelNo" label="Model No">
//                   <Select defaultValue="All">
//                     <Option value="9876543210">9876543210</Option>
//                     <Option value="9876542310">9876542310</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="DealerName" label="Dealer Name">
//                   <Select defaultValue="Select">
//                     <Option value="Dealer Name 1">Dealer Name 1</Option>
//                     <Option value="Dealer Name 2">Dealer Name 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="DealerLocation" label="Dealer Location">
//                   <Select defaultValue="All">
//                     <Option value="Dealer Location 1">Dealer Location 1</Option>
//                     <Option value="Dealer Location 2">Dealer Location 2</Option>
//                   </Select>
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="From" label="From">
//                   <DatePicker />
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <Form.Item name="To" label="To">
//                   <DatePicker />
//                 </Form.Item>
//               </Col>
//               <Col>
//                 <div className="BtnGroup">
//                   <Button>Apply Filters</Button>
//                   <Button>Clear All</Button>
//                 </div>
//               </Col>
//             </Row>
//           </Form>
//         </div>
//         <div className='CaseListData'>
//           <Form>
//             <Input.Group compact >
//               <Input.Search allowClear placeholder='Search on Case ID, Dealer Name, Product Name'/>
//             </Input.Group>
//           </Form>
//           <Table columns={columns} dataSource={data} pagination={false}/>
//         </div>
//       </>
//     );
//   }
// }
// export default FilterNewCaseList;
