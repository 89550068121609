import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { onCaseDetail } from "../redux/actions/CaseAction";
import { List, Avatar } from "antd";
import {
  getNotifications,
  readNotification,
} from "../redux/actions/NotificationAction";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import { BellOutlined, FieldTimeOutlined, CalendarOutlined } from "@ant-design/icons";

const NotificationList = (props) => {
  const params = useParams();
  const dispatch = useDispatch();
  const [htmlreport, sethtmlreport] = useState({});
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );

  useEffect(() => {
    dispatch(getNotifications());
  }, []);

  const data = [
    {
      title: "Ant Design Title 1",
    },
    {
      title: "Ant Design Title 2",
    },
    {
      title: "Ant Design Title 3",
    },
    {
      title: "Ant Design Title 4",
    },
  ];
  const onReadMessage = (i) => {
    dispatch(readNotification(i.id, onSuccess));
  };
  const onSuccess = () => {
    dispatch(getNotifications());
  };
  return (
    <>
      <React.Fragment>
        <div className="dashBoardWrap">
          <TopBarNav />
          <Navigation />
          <div className="InnerWrap NotificationsList">
            <h3>
              Notifications <BellOutlined size="large" />
            </h3>
            <List
              itemLayout="horizontal"
              dataSource={notifications.notifications}
              // pagination={{
              //   pageSize:5
              // }}
              
              renderItem={(item) => (
                <Link
                  style={{ cursor: "pointer" }}
                  to={`/viewOnGoingcase/${item.case_id}`}
                >
                  <List.Item className={item.is_read ? "read" : "unread"}>
                    <List.Item.Meta
                      onClick={() => onReadMessage(item)}
                      title={item.title}
                      description={item.msg}
                    />
                    <div className='timeblock'>
                    <FieldTimeOutlined /> {item.date_time ? item.date_time.split(" ")[0] : ""} <br />
                    <CalendarOutlined /> {item.hour_ago}
                    </div>
                   
                  </List.Item>
                </Link>
              )}
            />
          </div>
        </div>
      </React.Fragment>
    </>
  );
};
export default NotificationList;
