import React, { useState } from "react";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import uplaodIcon from "../../assets/images/uploadfile.png";
import ViewIcon from "../../assets/images/view.png";
import ReloadIcon from "../../assets/images/reload.png";
import EditIcon from "../../assets/images/edit.png";
import { Link, useHistory } from "react-router-dom";
import {
  onCaseLoad,
  onDownloadFleetReport,
  onDownloadTemplate,
  onFleetAnalysisLoad,
  onFleetAnalysisRun,
} from "../../redux/actions/FleetAnalysis";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";
import moment from "moment";
function Index() {
  const [fileList, setFileList] = useState([]);
  const [fleetListAfterClear, setFleetListAfterClear] = useState([]);
  const { Option } = Select;
  const dispatch = useDispatch();
  const [fleetList, setfleetList] = useState([]);
  const [searchedInput, setSearchedInput] = useState("");
  const [dataList, setdataList] = useState([]);
  const [cases, setCaseList] = useState([]);
  const [rcHide, setrcHide] = useState("none");
  const [uploadHide, setuploadHide] = useState("");
  const [rcRequired, setrcRequired] = useState(false);
  const [rcUploadRequired, setrcUploadRequired] = useState(true);

  const history = useHistory();
  const formData = new FormData();
  const onSuccess = (responseData) => {
    dispatch(onFleetAnalysisLoad(onloadScreen));
    form.resetFields(["rc_excel", "case_id", "rc_number", "is_manual"]);
    setFileList([]);
    setuploadHide("");
    setrcHide("none");
    setrcUploadRequired(true);
    setrcRequired(false);
    toast.success(responseData.data.message);
    // window.location.reload(true);
  };
  const onloadScreen = (responseData) => {
    setfleetList(responseData.data.data.fleet_list);
    setdataList(responseData.data.data.fleet_list);
    setCaseList(responseData.data.data.cases_list);
  };

  const downloadTemplate = () => {
    dispatch(onDownloadTemplate(onDownloaded));
  };
  const onDownloaded = (responseData) => {
    window.open(responseData.data.data);
    console.log(responseData.data.data);
  };
  const onLoadCase = (responseData) => {
    console.log(responseData);
  };
  const handleSubmitAnalysis = (data) => {
    formData.append("case_id", data.case_id);
    formData.append("is_manual", data.is_manual);
    if (data.rc_number === "" || data.rc_number === undefined) {
      const fileCheck = data.rc_excel.file.status;
      if (fileCheck === "removed") {
        toast.error("Please select file");
        return;
      }
      formData.append("rc_excel", data.rc_excel && data.rc_excel.file);
    } else {
      formData.append("rc_number", data.rc_number);
    }
    dispatch(onFleetAnalysisRun(formData, onSuccess));
  };
  useEffect(() => {
    dispatch(onFleetAnalysisLoad(onloadScreen));
  }, []);

  const onDownloadSuccess = (response) => {
    const url = response.data.file;
    const outputFilename = `${response.data.name}.xls`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
  };
  const downloadFleetReport = (case_id) => {
    dispatch(onDownloadFleetReport(case_id, onDownloadSuccess));
  };
  const columns = [
    {
      title: "Case Id",
      dataIndex: "case_id",
      key: "case_id",
      sorter: (a, b) => a.case_id - b.case_id,
    },
    {
      title: "Applicant Name",
      dataIndex: "applicant_name",
      key: "applicant_name",
    },
    {
      title: "Actual Count",
      dataIndex: "actual_count",
      key: "actual_count",
      //sorter: (a, b) => a.ActualCount - b.ActualCount,
    },
    {
      title: "Success Count",
      dataIndex: "success_count",
      key: "success_count",
      //sorter: (a, b) => a.SuccessCount - b.SuccessCount,
    },
    {
      title: "Last Updated",
      dataIndex: "uploaded_date",
      key: "uploaded_date",
      render: (text, row, record) => {
        return <div>{moment(row.uploaded_date).format("ll H:mm:ss")}</div>;
      },
      //sorter: (a, b) => a.UpdatedDate - b.UpdatedDate,
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              <Link to={`/Fleet-Analysis/${row.case_id}`}>
                <img
                  src={ViewIcon}
                  alt="View"
                  title="Click to view the run results"
                />
              </Link>
              <Button
                className="AddField"
                onClick={() => {
                  downloadFleetReport(row.case_id);
                }}
                title="Click to download consolidated results for this case ID"
              >
                Download
              </Button>
            </Space>
          </div>
        );
      },
    },
  ];

  const [form] = Form.useForm();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };
  const props = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      console.log(file.type);
      if (
        file.type === "application/kset" ||
        file.type === "application/vnd.ms-excel"
      ) {
        setFileList([file]);
        return false;
      } else {
        setFileList([]);
        toast.error(
          `Incorrect file format. Upload only .xls format excel. Download template if needed`
        );
        return false;
      }
    },
    fileList,
  };

  const filterCaseListing = (e) => {
    if (e.target.value === "") {
      console.log("hello");
      setfleetList(dataList);
    } else {
      const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
      const filteredCases = fleetList.filter((userCase) => {
        console.log(userCase);
        let casesById = userCase.case_id.toString().includes(searchedInput);
        return casesById;
      });
      setfleetList(filteredCases);
    }
  };

  const onCheckBoxChecked = (event) => {
    if (event.target.checked) {
      setuploadHide("none");
      setrcHide("");
      setrcUploadRequired(false);
      setrcRequired(true);
    } else {
      setuploadHide("");
      setrcHide("none");
      setrcUploadRequired(true);
      setrcRequired(false);
    }
  };

  return (
    <>
      <div className="FilterForm formexpandedfilter fleet-analy">
        <Form
          name="global_state"
          layout="inline"
          form={form}
          onFinish={handleSubmitAnalysis}
        >
          <Row align="top">
            <Col span={6}>
              <Form.Item
                name="case_id"
                className="required"
                label="Select Case*"
                rules={[{ required: true, message: "Please select case id" }]}
              >
                <Select
                  showSearch
                  placeholder="Select case id"
                  optionFilterProp="children"
                  onChange={handleChange}
                  dropdownStyle={{ minWidth: "50%", height: "auto" }}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {cases &&
                    cases.map((category, index) => (
                      <Option value={category.id} key={category.id}>
                        {category.name + ""}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item
                label="Enter Manualy*"
                name="rc_number"
                required={rcRequired}
                rules={[
                  { required: rcRequired, message: "RC number is required" },
                ]}
                style={{ display: rcHide }}
              >
                <input />
              </Form.Item>

              <Form.Item
                label="Upload Sheet*"
                name="rc_excel"
                required={rcUploadRequired}
                rules={[
                  {
                    required: rcUploadRequired,
                    message: "Please select excel sheet",
                  },
                ]}
                style={{ display: uploadHide }}
              >
                <Upload {...props} accept=".xls">
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="is_manual"
                style={{ marginTop: "35px" }}
                onChange={onCheckBoxChecked}
                valuePropName="checked"
              >
                <Checkbox>Check if you want to run manually</Checkbox>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Button
                size="middle"
                type="primary"
                htmlType="submit"
                className="AddField  "
                title="Click to run fleet analysis and wait for the process to complete"
                style={{ marginTop: "35px" }}
              >
                Run
              </Button>
            </Col>
            <Col span={6} flex="100px" style={{ marginTop: "35px" }}>
              <Button
                size="large"
                className="AddField"
                onClick={() => {
                  downloadTemplate();
                }}
                title="Click to download sample excel sheet"
              >
                Download Template
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="CaseListData CaseListDataMobile">
        <Row>
          <Col span={12}>
            <Form>
              <Input.Group compact>
                <Input.Search
                  allowClear
                  placeholder="Search On Case ID"
                  value={searchedInput}
                  onChange={(e) => {
                    setSearchedInput(e.target.value);
                    filterCaseListing(e);
                  }}
                />
              </Input.Group>
            </Form>
          </Col>
        </Row>

        <Table
          columns={columns}
          rowKey={(record) => record.case_id}
          dataSource={fleetList}
          pagination={true}
        />
      </div>
    </>
  );
}

export default Index;
