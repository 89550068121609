import React, { Component } from "react";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import ViewHunterCases from "../components/hunterListingCases";

function HunterApi() {
  return (
    <div>
      <div className="NewCasesWrap">
        <TopBarNav />
        <Navigation />
        <ViewHunterCases />
      </div>
    </div>
  );
}
export default HunterApi;
