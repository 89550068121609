import React, { Component } from "react";
import { Col, Row, Card, Input, Form, Upload, Button } from "antd";
import uplaodIcon from "../../../../assets/images/uploadfile.png";
import closeIcon from "../../../../assets/images/closesmall.png";
const { TextArea } = Input;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
class ComminucationForm extends Component {
  render() {
    return (
      <>
        <div className="CommunicationformWrap">
          <Form
            {...layout}
            name="communication"
          >
            <Row gutter={32}>
              <Col span={12} md={12} sm={24} xs={24}>
                <h4>Justification From Customer</h4>
                <Card>
                
                 <TextArea rows={4} placeholder="Enter your comment here" />
                 <Form.Item label='Upload Relevant Document' name='reldoc'> 
                    <Upload>
                        <Button> <img src={uplaodIcon} alt=''/></Button>
                        </Upload>
                        
                </Form.Item>
                <div className='ButtonAddon'>
                    <Button>Add Comment</Button>
                </div>
                <Card>
                    <p>Address on Aadhar and passport is not matching. please provide at least 1 supporting document where name is same as PAN and address  is same as Aadhar.</p>
                </Card>
                <p className='postedBlock'>Posted on 1/3/2021 by John Deo </p>
                <TextArea rows={4} placeholder="Enter your comment here" />
                <p className='postedBlock'><span>DL.JPEG <img src={closeIcon} alt='' /> </span>Posted on 1/3/2021 by John Deo </p>
                </Card>
               
              </Col>
              <Col span={12} md={12} sm={24} xs={24}>
                <h4>Suggestion/Review Comments/Recommendations from Credit </h4>
                <Card>
                
                  <TextArea rows={4} placeholder="Enter your comment here" />
                  <div className='ButtonAddon'>
                    <Button>Add Comment</Button>
                </div>
                <Card>
                    <p>Basis the documents provided and credit reports, this case is not doable. Credit  recommends that sales should drop this case as the customer look high risk profile. </p>
                </Card>
                <p className='postedBlock'>Posted on 1/3/2021 by John Deo </p>
                </Card>
               
              </Col>
            </Row>
          </Form>
        </div>
      </>
    );
  }
}
export default ComminucationForm;
