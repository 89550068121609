import { Button } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import EditIcon from "../../../assets/images/edit.png";
import DeleteIcon from "../../../assets/images/remove.png";
import {
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../../redux/actions/CaseAction";
import { onDeleteGuarantor } from "../../../redux/actions/GuarantorAction";
import { DeleteCoapplicantModal } from "../../common/modals/DeleteCoapplicantModal";
const GuarantorListing = (props) => {
  const {
    guarantors = [],
    caseDetail,
    editGuarantor,
    afterDeleteCoapplicant,
  } = props;
  const [
    deleteCoapplicantModalVisible,
    setDeleteCoapplicantModalVisible,
  ] = useState(false);
  const [coapplicantDeletionId, setCoapplicantDeletionId] = useState(false);
  const dispatch = useDispatch();
  const deleteCoapplicantConfirmation = () => {
    setDeleteCoapplicantModalVisible(false);
    deleteCoapplicant(coapplicantDeletionId);
  };
  const deleteCoapplicantCancel = () => {
    setDeleteCoapplicantModalVisible(false);
  };
  const deleteCoapplicant = (guarantorId) => {
    dispatch(
      onDeleteGuarantor(guarantorId, () => {
        afterDeleteCoapplicant(guarantorId);
        dispatch(
          onCaseDetail({ caseId: caseDetail.id }, (response) => {
            dispatch(onUpdateNewCreatedCase(response, () => {}));
          })
        );
      })
    );
  };
  return (
    <React.Fragment>
      <div className="SmallTableWrap SmallTableWrap-v2">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Guarantor Type</th>
              {/*<th>Name</th>*/}
              {/*<th>Relation with Applicant</th>*/}
              <th>Stage</th>
              {/*<th>Stage</th>*/}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {guarantors.map((guarantor, index) => (
              <tr key={guarantor.id}>
                <td>{guarantor.id}</td>
                <td>{guarantor.category_name}</td>
                {/*<td>{guarantor.first_name} {guarantor.last_name}</td>*/}
                {/*<td>{guarantor.coapplicant_applicant_relation}</td>*/}
                <td>{guarantor.stage}</td>
                {/*<td>{guarantor.stage}</td>*/}
                <td style={{ cursor: "pointer" }}>
                  <div className="btnWrap">
                    <Button
                      onClick={() => {
                        editGuarantor(guarantor);
                      }}
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCoapplicantDeletionId(guarantor.id);
                        setDeleteCoapplicantModalVisible(true);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}{" "}
          </tbody>
        </table>
        <DeleteCoapplicantModal
          deleteVisible={deleteCoapplicantModalVisible}
          deleteCancel={deleteCoapplicantCancel}
          deleteConfirm={deleteCoapplicantConfirmation}
          title={"Guarantor"}
        />
      </div>
    </React.Fragment>
  );
};
export default GuarantorListing;
