import React, { Component } from "react";
import { Tabs } from "antd";
import MainApplicantForm from './mainapplicantform'
import CoApplicantForm from './coapplicantform'
import Guarantorform from './guarantor'
const { TabPane } = Tabs;
class UploadDocument extends Component {
  render() {
    return (
      <>
      <div className='ModalFormInnerWrap'>
        <Tabs defaultActiveKey="1">
          <TabPane tab="Main Applicant" key="1">
            <MainApplicantForm/>
          </TabPane>
          <TabPane tab="Co - Applicant" key="2">
            <CoApplicantForm/>
          </TabPane>
          <TabPane tab="Guarantor" key="3">
            <Guarantorform/>
          </TabPane>
        </Tabs>
      </div>
        
      </>
    );
  }
}
export default UploadDocument;
