
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { useParams, useHistory, Redirect, Link } from "react-router-dom";
import {
  onChangeFirstTabKey,
  onChangeMiddleTab,
  onChangeSecondTabKey,
  onChangeThirdTabKey,
  onFirstTabChange,
} from "../../../../redux/actions/TabAction";
import { getCaseDetailForFI, getFieldInvestigationCase, onCheckLinkExpire } from '../../../../redux/actions/FieldInvestigation'
import { Col, Tabs, Row, Card, Button, Modal, Form, Select } from "antd";
import Mainapplicantform from "../MainapplicantForm/MainApplicantDetails";
import Coapplicantform from "../CoapplicantForm/CoapplicantForm";
import Guarantorform from "../GuarantorForm/GuarantorDetails";
import { onCaseDetail } from "../../../../redux/actions/CaseAction";
import { toast } from "react-toastify";
import siteLogo from "..//..//..//..//assets/images/logo-new.png";

const { Option } = Select;
const { TabPane } = Tabs;

export default function FieldInvestigationView(viewdata) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [form] = Form.useForm();
  const [hideButton, setHideButton] = useState(true)
  const firstTabKey = useSelector((state) => state.tabReducer.firstTabKey);
  const [message, setMessage] = useState("");
  const [caseId, setCaseId] = useState("");
  const [fiMainApplicant, setFiMainApplicant] = useState(true);
  const [fiCoApplicant, setFiCoApplicant] = useState(true);
  const [fiGuarantor, setFiGuarantor] = useState(true);
  const [mainApplicantView, setMainApplicantView] = useState(false);
  const params = useParams();


  const checkLink = (linkId) => {
    dispatch(onCheckLinkExpire(linkId, onSuccess));
  }
  const onSuccess = (responseData) => {
    setMessage(responseData.data.data.message);
    setHideButton(responseData.data.data.status);
    setCaseId(responseData.data.data.data.case_id);
    fiApplicant(responseData.data.data.data);
  }
  useEffect(() => {
    checkLink(params.linkId);
  }, [dispatch])

  const fiApplicant = (responseData) => {
    if (responseData.participant_type === "Main Applicant") {
      setMainApplicantView("Main Applicant");
      setFiMainApplicant(false);
      setFiCoApplicant(true);
      setFiGuarantor(true);
    }
    else if (responseData.participant_type === "Co Applicant") {
      setMainApplicantView("Co Applicant");
      setFiMainApplicant(false);
      setFiCoApplicant(false);
      setFiGuarantor(true);
    }
    else {
      setMainApplicantView("Guarantor");
      setFiMainApplicant(false);
      setFiCoApplicant(true);
      setFiGuarantor(false);
    }
    fetchData(responseData);
  }
  const fetchData = (responseData) => {
    dispatch(getCaseDetailForFI(params.fiId, onGetSuccess, OnError))
  };
  const onGetSuccess = (data) => {
  }
  const OnError = (error) => {
    toast.error(error);
  }
  const firstTabChange = (tab) => {
    dispatch(onChangeFirstTabKey(tab));
    dispatch(onChangeSecondTabKey("1"));
    dispatch(onChangeThirdTabKey("1"));
  };

  const onRequestView = () => {
    history.push(`/field-investigation-request-case-view/all/${params.agencyId}
    /${params.caseId}/${params.participantId}/${params.attemptId}/${params.fiId}/${params.linkId}`);
  }
  return (
    <>
      <div className="topBarWrap">
        <Row>
          <Col span={12} sm={12} xs={24} className="logowrap">
            {/* <img src={siteLogo} alt="logo" /> */}
            <div className="Branding">
              <div className="text">
                <div>
                  <img src={siteLogo} alt="logo" />
                </div>
                <div>
                  <h4>Volvo Financial Services</h4>
                  <p>INDIA</p>
                </div>
              </div>
            </div>
          </Col>
          {hideButton ? <Col>
            <div style={{ marginLeft: "475px", marginTop: "10px" }}>
              <h4>Case Id: {caseId}</h4>
            </div>
            <Button
              onClick={onRequestView}
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#202A44",
                color: "#fff",
                marginLeft: "500px",
                marginTop: "10px"
              }}> View all Request</Button>
          </Col>
            :
            <> </>}
        </Row>
      </div>
      <div className="FieldTabsWrap FieldDetailTabs">
        {hideButton ?
          <Tabs activekey={firstTabKey} onChange={firstTabChange}>
            <TabPane disabled={fiMainApplicant} tab="Main Applicant" key="1" >
              <Mainapplicantform applicantStatus={mainApplicantView} />
            </TabPane>
            <TabPane disabled={fiCoApplicant} tab="Co-Applicant" key="2">
              <Coapplicantform />
            </TabPane>
            <TabPane disabled={fiGuarantor} tab="Guarantor" key="3">
              <Guarantorform />
            </TabPane>
          </Tabs> :
          <Redirect to={{
            pathname: `/link/expired`,
            state: { message: message, color: "red" },
          }}>
          </Redirect >
        }
      </div>
    </>

  )
}
