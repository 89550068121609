import React, { Component } from "react";
import { Tabs, Card, Row, Col, Button } from "antd";
import DetailDocform from "../detaildocform";
import Coapplicantform from "../coapplicantform";
import LoanDetail from "../loandetail";
import DealerInfo from "../dealerinfocard";
import Guarantorform from "../guarantorform";
import MainApplicantFormKyc from "../kycreport/mainapplicantform"
const { TabPane } = Tabs;
class DetailDoc extends Component {
  render() {
    return (
      <>
        <div className="InnerTabsWrap">
         
          <Card>
          <div className='ActionButtonHeader'>
            <Button>Save</Button>
            <Button>Run KYC Check</Button>
           
          </div>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Details & documents" key="1">
                <DetailDocform />
                <div className="InfoCard">
                  <Row gutter={16}>
                    <Col span={8} md={8} xs={24} sm={24}>
                      <LoanDetail />
                    </Col>
                    <Col span={16} md={16} xs={24} sm={24}>
                      <DealerInfo />
                    </Col>
                  </Row>
                </div>
              </TabPane>
              <TabPane tab="KYC" key="2">
                <MainApplicantFormKyc/>
              </TabPane>
              <TabPane tab="Bureau Reports" key="3">
                {/* <Guarantorform /> */}
              </TabPane>
            </Tabs>
          </Card>
        </div>
      </>
    );
  }
}
export default DetailDoc;
