import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import { fetchHunterCaseDetailsApi, getHunterSummaryListAPI, loadDownloadFleetReportCasesApi, loadKycDoneCasesApi, loadRunHunterApi,downloadHunterSummaryApi} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    SHOW_LOADING,
    HIDE_LOADING,
    DOWNLOAD_HUNTER_REPORT,
    HUNTER_SUCCESS,
    KYC_DONE_CASES_LIST,
    SET_KYC_CASES,
    RUN_HUNTER,
    HUNTER_SUMMARY_LIST,
    SET_HUNTER_SUMMARY,
    GET_HUNTER_DETAIL_BY_CASE
} from "../constants/ActionTypes";
import { errorMessage, successMessage } from "../../helpers/utils";

function* downloadHunterReport(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(downloadHunterSummaryApi);
      if (response.status === 200 && response.data) {
        yield put({ type: HUNTER_SUCCESS, hunterData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }

  function* fetchLoadKycDoneCases(action) {
    
    try { 
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadKycDoneCasesApi);
      if (response.status === 200 && response.data) { 
        yield put({ type:HUNTER_SUCCESS, hunterData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }

  function* runHunter(action) { 
    try { 
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadRunHunterApi,action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type:HUNTER_SUCCESS, hunterData: response.data.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  function* hunterSummaryList(action) { 
    try { 
      yield put({ type: SHOW_LOADING });
      const response = yield call(getHunterSummaryListAPI);
      if (response.status === 200 && response.data) {
        yield put({ type:HUNTER_SUCCESS, hunterData: response.data.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
     
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  function* fetchHunterCaseDetails(action) { 
    try { 
      yield put({ type: SHOW_LOADING });
      const response = yield call(fetchHunterCaseDetailsApi,action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type:HUNTER_SUCCESS, hunterData: response.data.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }

  function* onDownloadHunterReport() {
    yield takeLatest(DOWNLOAD_HUNTER_REPORT,downloadHunterReport)
  }
  
  function* onKycDoneCasesLoad() {
    yield takeLatest(KYC_DONE_CASES_LIST, fetchLoadKycDoneCases)
  }
  
  function* onRunHunter() {
    yield takeLatest(RUN_HUNTER, runHunter)
  }
  function* onHunterSummaryList() {
    yield takeLatest(HUNTER_SUMMARY_LIST, hunterSummaryList)
  }

  function*  onHunterDetilsByCase(){
    yield takeLatest(GET_HUNTER_DETAIL_BY_CASE, fetchHunterCaseDetails)

  }
  export default function* hunterSaga() {
    yield all([ 
       fork(onDownloadHunterReport),
        fork(onKycDoneCasesLoad),
        fork(onRunHunter),
        fork(onHunterSummaryList),
        fork(onHunterDetilsByCase)
    ]);
  
}


