import React, { Component, useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import IconImage from "../../../../../assets/images/imageIcon.png";
import { useSelector } from "react-redux";
import { OCR_DOCUMENT_LABEL } from "../../../../../constants/constants";
const DrivingLicense = (props) => {
  const ocrResult = useSelector(state => state.auth.currentOcrResult);
  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  const [drivingLicenseOcrResult, setDrivingLicenseOcrResult] = useState({});
  
  
  const [drivingLicenseKycResult, setDrivingLicenseResult] = useState({});
  useEffect(() => {
    let drivingLicenseOCR = ocrResult.find((ocr_result)=>{
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE
    });
    if(drivingLicenseOCR){
      setDrivingLicenseOcrResult(drivingLicenseOCR);
    }
  }, [ocrResult])
  useEffect(() => {
    let drivingLicenseKyc = kycResult.find((ocr_result)=>{
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE
    });
    if(drivingLicenseKyc){
      setDrivingLicenseResult(drivingLicenseKyc)
    }
  }, [kycResult])
  return (
    <>
      <div className="CardBlock">
        <h3>Driving License</h3>
        <Card>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DL Number</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{drivingLicenseOcrResult && drivingLicenseOcrResult.dl_no}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{drivingLicenseOcrResult && drivingLicenseOcrResult.name}</p>
            </Col>
          </Row>
          <Row>
          <Col span={12} md={12} xs={24} sm={24}>
            <h4>Address</h4>
          </Col>
          <Col span={12} md={12} xs={24} sm={24}>
            <p>{drivingLicenseOcrResult && (drivingLicenseOcrResult.address?drivingLicenseOcrResult.address:'-')}</p>
          </Col>
        </Row>
          
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{drivingLicenseOcrResult.dob}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Blood Group</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {drivingLicenseKycResult &&
                  (drivingLicenseKycResult.blood_group
                    ? drivingLicenseKycResult.blood_group
                    : "-")}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Address Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{drivingLicenseKycResult.address_matching_result}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{drivingLicenseKycResult.dob_matching_result}</p>
            </Col>
          </Row>
          {/*<Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Image</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p><img src={IconImage} alt='icon'/></p>
            </Col>
          </Row>*/}
        </Card>
      </div>
    </>
  );
};
export default DrivingLicense;
