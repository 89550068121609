import {
  GET_BUREAU_REPORT_MASTER_FAILED,
  GET_BUREAU_REPORT_MASTER_SUCCESS,
  SET_BUREAU_REPORT_LISTING,
  SET_SELECTED_BUREAU_REPORT,
  GET_BUREAU_REPORT_LISTING_SUCCESS,
  GET_BUREAU_REPORT_LISTING_FAILED,
  GET_CIBIL_REPORT_MASTER_FAILED,
  GET_CIBIL_REPORT_MASTER_SUCCESS,
  BUREAU_DONE_CASES_LIST,
  SET_BUREAU_CASES,
} from "../constants/ActionTypes";

const initState = {
  bureauReportMasters: [],
  selectedBureauReport: null,
  beureauReportListing: [],
  cibilReportListing: [],
  beureauReportGeneratedCases: [],
};
export default function course(state = initState, action) {
  switch (action.type) {
    case GET_BUREAU_REPORT_MASTER_SUCCESS:
      return { ...state, bureauReportMasters: action.bureauReportMasters };
    case GET_BUREAU_REPORT_MASTER_FAILED:
      return { ...state, bureauReportMasters: [] };
    case GET_CIBIL_REPORT_MASTER_SUCCESS:
      return { ...state, cibilReportListing: action.bureauReportMasters };
    case GET_CIBIL_REPORT_MASTER_FAILED:
      return { ...state, cibilReportListing: [] };
    case SET_SELECTED_BUREAU_REPORT:
      return { ...state, selectedBureauReport: action.data };
    case SET_BUREAU_REPORT_LISTING:
      return { ...state, beureauReportListing: action.data };
    case GET_BUREAU_REPORT_LISTING_SUCCESS:
      return { ...state, beureauReportListing: action.bureauReportList };
    case GET_BUREAU_REPORT_LISTING_FAILED:
      return { ...state, beureauReportListing: [] };
    case SET_BUREAU_CASES:
      return {...state, beureauReportGeneratedCases: action.payload};

    default:
      return state;
  }
}
