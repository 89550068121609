import Modal from "antd/lib/modal/Modal";

export const DeleteCoapplicantModal = ({
  deleteVisible,
  deleteCancel,
  deleteConfirm,
  title,
}) => {
  return (
    <Modal
      visible={deleteVisible}
      onCancel={deleteCancel}
      className="KycCheck"
      onOk={deleteConfirm}
      destroyOnClose={true}
    >
      <p className="dangerconfirmationtext">
        Are you sure you want to delete this {title}?
      </p>
    </Modal>
  );
};
