import React, { Component, useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { OCR_DOCUMENT_LABEL } from "../../../../../constants/constants";
const VoterId = (props)=>{
  const ocrResult = useSelector(state => state.auth.currentOcrResult);
  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  const [voterIDOcrResult, setVoterIDOcrResult] = useState({});
  const [voterIDKycResult, setPanKycResult] = useState({});
  useEffect(() => {
    let voterIDOcr = ocrResult.find((ocr_result)=>{
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.VOTER_ID
    });
    if(voterIDOcr){
      setVoterIDOcrResult(voterIDOcr);
    }
  }, [ocrResult])
  useEffect(() => {
    let voterIDKyc = kycResult.find((ocr_result)=>{
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.VOTER_ID
    });
    if(voterIDKyc){
      setPanKycResult(voterIDKyc)
    }
  }, [kycResult])
  return (
    <>
      <div className="CardBlock">
        <h3>Voter ID</h3>
        <Card>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Voter ID Number</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{voterIDOcrResult && (voterIDOcrResult.voter_id?voterIDOcrResult.voter_id:'-')}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Name of Card Holder</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{voterIDOcrResult && (voterIDOcrResult.name?voterIDOcrResult.name:'-')}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Father</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{voterIDOcrResult && (voterIDOcrResult.father?voterIDOcrResult.father:'-')}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Gender</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{voterIDOcrResult &&(voterIDOcrResult.gender?voterIDOcrResult.gender:'-')}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{voterIDOcrResult && (voterIDOcrResult.dob?voterIDOcrResult.dob:'-')}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Assembly Constituency Detail of the Card Holder</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>-</p>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
}
export default VoterId;
