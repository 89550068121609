import React, { Component } from "react";
import MainApplicant from './mainapplicant';
import CoApplicant from './coapplicant';
import Guarantor from './guarantor'
import { Tabs } from "antd";
const { TabPane } = Tabs;
class BureauReport extends Component {
    render() {
      return (
        <>
          <div className='BureauReportWrap'>
              <div className="InnerTabsWrap">
                   <Tabs defaultActiveKey="1">
                        <TabPane tab="Main Applicant" key="1">
                            <MainApplicant/>
                        </TabPane>
                        <TabPane tab="Co Applicant" key="2">
                          <CoApplicant/>
                        </TabPane>
                        <TabPane tab="Guarantor" key="3">
                          <Guarantor/>
                        </TabPane>
                    </Tabs>
              </div>
           
          </div>
        </>
      )
    }
  }
export default BureauReport; 