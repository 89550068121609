import {
    SET_OCR_DOCS,
    SET_NON_OCR_DOCS,
    SET_RUN_OCR_RESPONSE
  } from "../constants/ActionTypes";
  const INITIAL_STATE = {
    ocrDocs: [],
    nonOcrDocs: [],
    runOcrResponse:null,
  };
  export default function auth(state = INITIAL_STATE, action) {
    switch (action.type) {
      case SET_OCR_DOCS:
        return {
          ...state,
          ocrDocs: action.data,
        };
      case SET_NON_OCR_DOCS:
        return {
          ...state,
          nonOcrDocs: action.data,
        };
      case SET_RUN_OCR_RESPONSE:
        return{
          ...state,
          runOcrResponse:action.data,
        }
      default:
        return state;
    }
  }
  