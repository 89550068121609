import { Form, Space, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ViewIcon from "../../assets/images/view.png";
import { loggedInUserData } from "../../helpers/utils";
import {
  onGetClosedCases,
  onGetUserByStateCity,
} from "../../redux/actions/CaseAction";
import { onGetCities } from "../../redux/actions/MasterData";
import CaseListing from "../caseListing";
import Filters from "../filters/filters";

const ClosedCases = () => {
  const [searchedInput, setSearchedInput] = useState("");
  const [closedCasesList, setClosedCasesList] = useState([]);
  const [userData, setUserData] = useState([]);
  const dispatch = useDispatch();
  const closedCases = useSelector((state) => state.auth.closedCases);
  const authMaster = useSelector((state) => state.auth.masterData);
  const creditUser = useSelector((state) => state.creditUser.creditUser);
  useEffect(() => {
    dispatch(onGetClosedCases('moved_to_los=1'));
  }, [dispatch]);
  useEffect(() => {
    setUserData(loggedInUserData());
  }, []);
  useEffect(() => {
    setClosedCasesList(closedCases);
  }, [closedCases]);
  const columns = [
    {
      title: "Case ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Category Name",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Business Area",
      dataIndex: "business_area",
      key: "business_area",
    },
    {
      title: "Created Date",
      dataIndex: "id",
      render: (text, row, record) => {
        return <div>{moment(row.created_at).format("ll")}</div>;
      },
    },
    {
      title: "Closed Date",
      dataIndex: "id",
      render: (text, row, record) => {
        return (
          <div>
            {row.approve_reject_at &&
              moment(row.approve_reject_at).format("ll")}
          </div>
        );
      },
    },
    {
      title: "Total TAT",
      dataIndex: "id",
      render: (text, row, record) => {
        return <div>{row.approve_reject_on}</div>;
      },
    },
    {
      title: "Case Status ",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Allocated to ",
      dataIndex: "sales_allocated_name",
      key: "sales_allocated_name",
      className: userData.user_type == "Credit" ? "show" : "hide",
    },

    {
      title: "Action",
      key: "action",
      render: (text, row, record) => (
        <Space size="middle">
          <Tooltip placement="topLeft" title="View" arrowPointAtCenter>
            <Link to={`/viewOnGoingcase/${row.id}`}>
              <img src={ViewIcon} alt="View" />
            </Link>
          </Tooltip>
          {/*{userData && userData.user_type === 'Sales' && <Link to={`/edit-case/${row.id}`}>
          <img src={EditIcon} alt="Edit" />
      </Link>}*/}
        </Space>
      ),
    },
  ];

  const getFiltered = (filterdata) => {
    dispatch(onGetClosedCases(filterdata));
  };
  const resetfilter = () => {
    dispatch(onGetClosedCases());
  };
  const filterCaseListing = (e) => {
    setSearchedInput(e.target.value);
    const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
    const filteredCases = closedCases.filter((userCase) => {
      let casesById = userCase.id.toString().includes(searchedInput);
      let casesByBusinessArea = userCase.business_area
        ? userCase.business_area.toLowerCase().includes(searchedInput)
        : "";
      let casesByStatus = userCase.status
        ? userCase.status.toLowerCase().includes(searchedInput)
        : "";

      return casesById || casesByBusinessArea || casesByStatus;
    });
    setClosedCasesList(filteredCases);
  };
  return (
    <>
      <Filters
        creditUser={creditUser}
        userData={userData}
        title="Filter the Closed Cases List"
        isClosed={true}
        reset={resetfilter}
        case_type="close"
        authMaster={authMaster}
        getFiltered={getFiltered}
      />
      <div className="CaseListData TableWrapAction">
        <CaseListing
          columns={columns}
          data={closedCasesList}
          case_type="close"
          searchedInput={searchedInput}
          filterCaseListing={filterCaseListing}
        />
      </div>
    </>
  );
};

export default ClosedCases;
