import React, { Component, useEffect, useState } from "react";
import { Tabs, Steps, Button, message } from "antd";
import MainApplicantForm from "./mainapplicantform";
import CoApplicantForm from "./coapplicantform";
import Guarantorform from "./guarantor";
import { Link, useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import Participant from "../../../lognewcase/participant";
import { toast } from "react-toastify";
import { REQUIRED_MESSAGES } from "../../../../constants/messages";
import { onChangeUploadDocumentActiveParticipantTab } from "../../../../redux/actions/TabAction";
import { onUpdateNewCreatedCase } from "../../../../redux/actions/CaseAction";
import AddParticipantTabs from "../../../case-participants/AddParticipantTab";
const { TabPane } = Tabs;
const { Step } = Steps;
const UploadDocument = (props) => {
  const {
    caseDetail,
    newlyCreatedCase,
    closeUploadDocumentsModalHandler
  } = props;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onChangeUploadDocumentActiveParticipantTab("1"));
  }, [dispatch]);
  useEffect(() => {
    dispatch(onUpdateNewCreatedCase(caseDetail, () => {}));
  }, [caseDetail, dispatch]);
  const handleTabChange = (key) => {
    
    if (key === "1") {
      dispatch(onChangeUploadDocumentActiveParticipantTab(key));
    } else if (key === "2") {
      if (
        newlyCreatedCase.main_applicant &&
        newlyCreatedCase.main_applicant.ocr_documents &&
        Array.isArray(newlyCreatedCase.main_applicant.ocr_documents) &&
        newlyCreatedCase.main_applicant.ocr_documents.length > 0
      ) {
        dispatch(onChangeUploadDocumentActiveParticipantTab(key));
      } else {
        toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_MAIN_APPLICANT);
      }
    } else if (key === "3") {
      if (
        newlyCreatedCase.main_applicant &&
        newlyCreatedCase.main_applicant.ocr_documents &&
        Array.isArray(newlyCreatedCase.main_applicant.ocr_documents) &&
        newlyCreatedCase.main_applicant.ocr_documents.length > 0
      ) {
        if (
          newlyCreatedCase.co_applicants &&
          Array.isArray(newlyCreatedCase.co_applicants) &&
          newlyCreatedCase.co_applicants.length > 0
        ) {
          dispatch(onChangeUploadDocumentActiveParticipantTab(key));
        } else {
          toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_ATLEASE_ONE_CO_APPLICANT);
        }
      } else {
        toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_MAIN_APPLICANT);
      }
    }
  };
  return (
    <React.Fragment>
      <div className="LogNewCasesWrap">
        <AddParticipantTabs upload_document={true} onCloseModal={closeUploadDocumentsModalHandler}/>
      </div>
      {/** STEPS CODE */}
    </React.Fragment>
  );
};
// <Tabs activeKey={uploadDocumentActiveKey} onChange={handleTabChange}>
//           <TabPane tab="Main Applicant" key="1">
//             {/*<MainAppplicant />*/}
//             <Participant
//               mode="upload_main_applicant_document"
//               type="Main Applicant"
//               caseId={caseDetail.id}
//               changeUploaded={props.changeUploaded}
//               modalType='upload_document'
//               caseDetail={caseDetail}
//               isNewCase={true}
//               closeUploadDocumentsModalHandler={closeUploadDocumentsModalHandler}
//               handleTabChange={handleTabChange}
//             />
//           </TabPane>
//           <TabPane tab="Co Applicant" key="2">
//             {/*<CoAppplicant />*/}
//             <Participant
//               type="Co Applicant"
//               // changeSubCategories={changeSubCategories}
//               caseId={caseDetail.id}
//               changeUploaded={props.changeUploaded}
//               modalType='upload_document'
//               caseDetail={caseDetail}
//               isNewCase={true}
//               closeUploadDocumentsModalHandler={closeUploadDocumentsModalHandler}
//               handleTabChange={handleTabChange}
//             />
//           </TabPane>
//           <TabPane tab="Guarantor" key="3">
//             {/*<Guarantor />*/}
//             <Participant
//               type="Guarantor"
//               // changeSubCategories={changeSubCategories}
//               caseId={caseDetail.id}
//               changeUploaded={props.changeUploaded}
//               modalType='upload_document'
//               caseDetail={caseDetail}
//               isNewCase={true}
//               closeUploadDocumentsModalHandler={closeUploadDocumentsModalHandler}
//               handleTabChange={handleTabChange}
//             />
//           </TabPane>
//         </Tabs>
// class UploadDocument extends Component {
//   render() {
//     return (
//       <>
//         <App />
//         {/* <div className='ModalFormInnerWrap'>
//         <Tabs defaultActiveKey="1">
//           <TabPane tab="Main Applicant" key="1">
//             <MainApplicantForm/>
//           </TabPane>
//           <TabPane tab="Co - Applicant" key="2">
//             <CoApplicantForm/>
//           </TabPane>
//           <TabPane tab="Guarantor" key="3">
//             <Guarantorform/>
//           </TabPane>
//         </Tabs>
//       </div> */}
//       </>
//     );
//   }
// }
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    uploadDocumentActiveKey: auth.uploadDocumentActiveKey,
    newlyCreatedCase: auth.newlyCreatedCase,
  };
};
export default connect(mapStateToProps, null)(UploadDocument);

// <Steps current={current} className="StepsWrap">
// {steps.map((item) => (
//   <Step key={item.title} title={item.title} />
// ))}
// </Steps>
// <div className="steps-content">
// <div className="ModalFormInnerWrap">{steps[current].content}</div>
// </div>
// <div className=" MainBtnWrap steps-action">
// <Button>Canel</Button>
// {current < steps.length - 1 && (
//   <Button onClick={() => next()}>Next</Button>
// )}
// {current === steps.length - 1 && (
//   <Link to={`/viewOnGoingcase/${params.id}`}>Next </Link>
// )}

// <Link to={`/viewOnGoingcase/${params.id}`}>Save and run OCR </Link>
// {/* {current < steps.length - 1 && (
//   <Button type="primary" onClick={() => next()}>
//     Next
//   </Button>
// )}
// {current === steps.length - 1 && (
//   <Button type="primary">
//     <Link to='/'>Done</Link>
//   </Button>
// )}
// {current > 0 && (
//   <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
//     Previous
//   </Button>
// )} */}
// </div>
