import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Link, useHistory, useParams } from "react-router-dom";
import ViewIcon from "../../assets/images/view.png";
import {
  onDownloadFleetReport,
  onDownloadFleetSummaryReport,
  onLoadFleetAnalysisByCase,
  onReRunFleetAnalysis,
} from "../../redux/actions/FleetAnalysis";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { confirm } from "react-confirm-box";
import { ConfirmationAlertBox } from "../common/modals/ConfirmationAlertBox";
import moment from "moment";

function FleetAnalysisViewByCase() {
  const dispatch = useDispatch();
  const [apiData, setApiData] = useState([]);
  const [applicantName, setapplicantName] = useState([]);
  const [alertBoxVisible, setalertBoxVisible] = useState(false);
  const [fleetId, setFleetId] = useState();
  const [rc_number, setRCNumber] = useState();

  
  const [searchedInput, setSearchedInput] = useState("");
  const [dataList, setDataList] = useState([]);

  const deleteCoapplicantCancel = () => {
    setalertBoxVisible(false);
  };

  const deleteCoapplicantConfirmation = () => {
    setalertBoxVisible(false);
  };
  const onloadScreen = (response) => {
    setApiData(response.data.data.fleet_data);
    setDataList(response.data.data.fleet_data);
    setapplicantName(response.data.data.applicant_name);
  };

  const history = useHistory();
  const parms = useParams();
  const id = parms.id;
  const fleetAnalysisList = () => {
    dispatch(onLoadFleetAnalysisByCase(id, onloadScreen));
  }
  useEffect(() => {
    fleetAnalysisList();
    // dispatch(onLoadFleetAnalysisByCase(id, onloadScreen));
  }, []);

  const reRun = (run_id) => {
    setalertBoxVisible(false);
    dispatch(onReRunFleetAnalysis(run_id, onSuccess));
  };
  const onSuccess = (responseData) => {
    toast.success(responseData.data.message);
    // dispatch(onLoadFleetAnalysisByCase(id, onloadScreen));
    fleetAnalysisList();
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "fleet_analysis_id",
      key: "fleet_analysis_id",
      className: "hide",
    },
    {
      title: "S.No.",
      render: (text, row, record) => {
        return <div>{record + 1}</div>;
      },
    },
    {
      title: "RC Number",
      dataIndex: "rc_number",
      key: "rc_number",
      // sorter: (a, b) => a.rc_number - b.rc_number,
    },
    {
      title: "Error Description",
      dataIndex: "error_description",
      key: "error_description",
      // sorter: (a, b) => a.rc_number - b.rc_number,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
       
    },
    {
      title: "Last Updated At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (text, row, record) => {
        return <div>{moment(row.updated_at).format("ll H:mm:ss")}</div>;
      },
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (text, row, record) => {
        if (row.status == "Failed") {
          return (
            <div>
              <Button
                className="AddField"
                onClick={() => {
                  setalertBoxVisible(true);
                  setFleetId(row.fleet_analysis_id);
                  setRCNumber(row.rc_number);

                }}
                title = "Click to re-run for this particular case"
              >
                Re-Run
              </Button>
            </div>
          );
        }
      },
    },
  ];

  const filterCaseListing = (e) => {
    if (e.target.value === "") {
      setApiData(dataList);
    } else {
      const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
      const filteredCases = apiData.filter((userCase) => {
        let casesById = userCase.rc_number.toString().toLowerCase().includes(searchedInput);
        let status = userCase.status.toString().toLowerCase().includes(searchedInput);
        return (casesById || status);
      });
      setApiData(filteredCases);
    }
  };

  const onDownloadSuccess = (response) => {
    const url = response.data.file;
    const outputFilename = `${response.data.name}.xlsx`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
  };
  const downloadFleetReport = (case_id) => {
    dispatch(onDownloadFleetReport(case_id, onDownloadSuccess));
  };
  const downloadFleetSummaryReport = (case_id) => {
    dispatch(onDownloadFleetSummaryReport(case_id, onDownloadSuccess));
  };

  

  return (
    <>
    <div className="GeneralTabsWrap LeadDetailTabs">
    <div className="CaseDetailHeader">
      <div className="InnerWrap">
        <Row>
          <Col span={4} xs={24} sm={24} md={12}>
                <p className="CaseId">
                  Case ID - {id}
                </p>
                <p className="CaseId">
                Applicant Name : {applicantName}
                </p>
              </Col>
          <Col span={4} xs={24} sm={24} md={12}>
          <div className="BtnWrap">
          <Button
                className="AddField"
                onClick={() => {
                  downloadFleetSummaryReport(id);
                }}
                title="Click to download summary results for this case ID"
              >
                Download Status Summary
          </Button>
          <Button
                className="AddField"
                onClick={() => {
                  downloadFleetReport(id);
                }}
                title="Click to download consolidated results for this case ID"
              >
                Download
            </Button>
            <Button
              className="AddField default"
              onClick={() => {
                history.push("/FleetAnalysis");
              }}
              title = "Click to go back to Fleet Analysis Summary page"
            >
              Back
            </Button>
            </div>
          </Col>
        </Row>
      </div>
      </div>
      </div>
      <div className="CaseListData CaseListDataMobile">
        <Row>
          <Col span={12}>
            <Form>
              <Input.Group compact>
                <Input.Search
                  allowClear
                  placeholder="Search On RC Number,Status"
                  value={searchedInput}
                  onChange={(e) => {
                    setSearchedInput(e.target.value);
                    filterCaseListing(e);
                  }}
                />
              </Input.Group>
            </Form>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={apiData}
          pagination={true}
          rowKey={(record) => record.fleet_analysis_id}
        />
      </div>
       
      <ConfirmationAlertBox
        deleteVisible={alertBoxVisible}
        deleteCancel={deleteCoapplicantCancel}
        deleteConfirm={() => {
          // reRun(fleetId);
        }}
        rc_number = {rc_number}
        fleetId = {fleetId}
        closeModal={() => {
          setalertBoxVisible(false); 
        }}
        fleetAnalysisList = {fleetAnalysisList}
        
        alertMessage={"Are you sure you want to re-run?"}
      />
  </>
  );
}
export default FleetAnalysisViewByCase;
