import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "../../../constants/messages";
import { onParticipantDetail } from "../../../redux/actions/ParticipantAction";
import AddCoApplicant from "./AddCoApplicant";
import CoApplicantListing from "./CoApplicantListing";
const AddCoApplicants = (props) => {
  const [coApplicants, setCoApplicants] = useState([]);
  const [toAddNewCoApplicant, setToAddNewCoApplicant] = useState(false);
  const [editCoApplicant, setToEditCoApplicant] = useState(false);
  const logNewCaseActiveKey = useSelector(
    (state) => state.auth.logNewCaseActiveKey
  );
  const newlyCreatedCase = useSelector((state) => state.auth.newlyCreatedCase);
  const [coapplicantMaxLengthError,setCoapplicantMaxLengthError] = useState('');
  const dispatch = useDispatch();
  useEffect(() => {
    if (toAddNewCoApplicant || editCoApplicant) {
      // window.scrollTo(0, 1000);
      document.getElementById("coapplicant_heading").scrollIntoView({behavior:'smooth'});
    }
  }, [toAddNewCoApplicant, editCoApplicant]);
  useEffect(() => {
    if (
      newlyCreatedCase &&
      newlyCreatedCase.co_applicants &&
      Array.isArray(newlyCreatedCase.co_applicants)
    ) {
      setCoApplicants(newlyCreatedCase.co_applicants);
      if (newlyCreatedCase.co_applicants.length === 0) {
        setToAddNewCoApplicant(false);
        setToEditCoApplicant(false);
      }
    }
  }, [newlyCreatedCase]);
  useEffect(() => {
    setToAddNewCoApplicant(false);
  }, [logNewCaseActiveKey]);
  const handleAddNewCoApplicant = () => {
    if(newlyCreatedCase && newlyCreatedCase.co_applicants && Array.isArray(newlyCreatedCase.co_applicants) && newlyCreatedCase.co_applicants.length!==5){
      setCoapplicantMaxLengthError('')
      setToAddNewCoApplicant(true);

    }else{
      setCoapplicantMaxLengthError(MESSAGES.MAX_COAPPLICANT_ERROR);
      window.scrollTo(0, window.screen.height);
    }
    setToEditCoApplicant(false);
  };

  const cancelAddNewCoApplicant = () => {
    setToAddNewCoApplicant(false);
    setToEditCoApplicant(false);
  };
  // const editCoApplicantHandler = (co_applicant) => {
  //   setCoapplicantMaxLengthError('');
  //   setToAddNewCoApplicant(false);
  //   dispatch(
  //     onParticipantDetail(
  //       {
  //         applicant_type: "Co Applicant",
  //         applicant_coapplicant_guarantor_id: co_applicant.id,
  //       },
  //       (updated_co_applicant) => {
  //         setToEditCoApplicant(updated_co_applicant);
  //       }
  //     )
  //   );
  // };
  const makeEditable = (co_applicant) => {
    setCoapplicantMaxLengthError('')
    setToAddNewCoApplicant(false);
    //setToEditCoApplicant(false);
    // setToEditCoApplicant(co_applicant);
    dispatch(
      onParticipantDetail(
        {
          applicant_type: "Co Applicant",
          applicant_coapplicant_guarantor_id: co_applicant.id,
        },
        (updated_co_applicant) => {
          
          setToEditCoApplicant(updated_co_applicant);
        }
      )
    );
  };

  return (
    <React.Fragment>
      {coApplicants.length > 0 && (
        <React.Fragment>
          <div className="HeaderPanelAction">
            <h3></h3>
            <div className="ButtonWrap">
              {(toAddNewCoApplicant || editCoApplicant) && (
                <Button className="AddField" onClick={cancelAddNewCoApplicant}>
                  Cancel
                </Button>
              )}
              <Button className="AddField" onClick={handleAddNewCoApplicant}>
                Add New Co-Applicant
              </Button>
            </div>
          </div>
          <CoApplicantListing
            coApplicants={newlyCreatedCase.co_applicants}
            editCoApplicant={makeEditable}
            caseDetail={newlyCreatedCase}
            afterDeleteCoapplicant = {(coapplicantId)=>{
              setCoapplicantMaxLengthError('')
              if(editCoApplicant && editCoApplicant.id && editCoApplicant.id ==coapplicantId ){
                setToEditCoApplicant(false)
              }
            }}
          />
          <p className='errorMessage'>{coapplicantMaxLengthError}</p>
        </React.Fragment>
      )}
      {coApplicants.length === 0 ? (
        <AddCoApplicant {...props} makeEditable={makeEditable} />
      ) : (
        toAddNewCoApplicant && <AddCoApplicant makeEditable={makeEditable} />
      )}
      {editCoApplicant && (
        <AddCoApplicant
          editCoApplicant={editCoApplicant}
          makeEditable={makeEditable}
        />
      )}
    </React.Fragment>
  );
};
export default AddCoApplicants;
