import React, { Component, useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,
  Radio,
  Select,
  Modal,
  Tabs,
  Card,
  Form,
  Tooltip,
} from "antd";
import { Link } from "react-router-dom";
import DetailDocform from "../../detaildocform";
import LoanDetail from "../../loandetail";
import { connect, useDispatch, useSelector } from "react-redux";
import DealerInfo from "../../dealerinfocard";
import MainApplicantFormKyc from "../../kycreport/mainapplicantform";
import ReAllocate from "../../../casedetail/modals/reallocate";
import SecondTab from "../secondtab/secondtab";
import Participant from "../../../lognewcase/participant";
import { onCaseDetail } from "../../../../redux/actions/CaseAction";
import {
  onResetParticipant,
  onParticipantDetail,
  onApproveRejectParticipant,
  onParticipantByCaseId,
} from "../../../../redux/actions/ParticipantAction";
import { onChangeSelectedUser } from "../../../../redux/actions/AuthAction";
import { onDeleteGuarantor } from "../../../../redux/actions/GuarantorAction";
import AddGuarantor from "../../../case-participants/guarantors/AddGuarantor";
import AskForJustificationModal from "../../../modals/askForJustificationModal";
import ThirdTab from "../thirdtab/thirdtab";
import {
  getClassNameByStatus,
  isCaseNotClosed,
  isCreditReviewPending,
  isCreditUser,
  isKycDone,
  isSalesUser,
  loggedInUserData,
} from "../../../../helpers/utils";
import { MESSAGES } from "../../../../constants/messages";
import {
  onJustificationComment,
  onFetchJustificationComment,
} from "../../../../redux/actions/CaseAction";
import { toast } from "react-toastify";
import DropCoapplicantGuarantorModal from "../../../modals/dropCoapplicantGuarantorModal";
import { APPLICANT_TYPE, STATUS ,STAGES,CATEGORY, CATEGORY_NAME} from "../../../../constants/constants";
import ApproveCoapplicantGuarantorModal from "../../../modals/ApproveCoapplicantGuarantorModal";
import { InformationModal } from "../../../modals/InformationModal";
import { onChangeSecondTabKey, onChangeThirdTabKey } from "../../../../redux/actions/TabAction";

// import {
//   onCaseDetail,
//   onChangeSelectedUser,
//   onDeleteGuarantor,
//   onResetParticipant,
// } from "../../../redux/actions/AuthAction";
const { TabPane } = Tabs;

const Guarantorform = (props) => {
  const { caseDetail, selectedUser } = props;
  const [reallocate, setReallocate] = useState(false);
  const [addGuarantor, setAddGuarantor] = useState(false);
  const [guarantors, setGuarantors] = useState([]);
  const [deleteGuarantorModalVisible, setDeleteGuarantorModalVisible] =
    useState(false);
  const [informationModal,setInformationModal] = useState(false);
  const [informationTitle, setInformationTitle] = useState(false);

  const [guarantorDeletionId, setGuarantorDeletionId] = useState(false);
  const [isMemberOrDirector, setIsMemberOrDirector] = useState(false);
  const [editGuarantor, setToEditGuarantor] = useState(false);
  const [maxGuarantorError, setMaxGuarantorError] = useState("");
  const [askJustification, setAskJustification] = useState(false);
  const [dropGuarantorModalVisible, setDropGuarantorModalVisible] =
    useState(false);
  const userData = loggedInUserData();
  const dispatch = useDispatch();
  const [form2] = Form.useForm();

  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  const [approveGuarantorModalVisible, setApproveGuarantorModal] =
    useState(false);
  const [guarantorApprovalId, setGuarantorApprovalId] = useState(false);
  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.guarantors &&
      Array.isArray(caseDetail.guarantors)
    ) {
      setGuarantors(caseDetail.guarantors);
      if (
        selectedUser&& Object.keys(selectedUser).length === 0 &&
        selectedUser.constructor === Object
      ) {
        if (caseDetail.guarantors.length > 0) {
          dispatch(onChangeSelectedUser(caseDetail.guarantors[0]));
        }else{
          dispatch(onChangeSelectedUser(null));

        }
      }
    }
  }, [caseDetail, selectedUser, dispatch]);
  useEffect(() => {
    if (
      selectedUser &&
      selectedUser.fk_category_id &&
      (selectedUser.fk_category_id.toString() === CATEGORY.CORPORATE ||
        selectedUser.fk_category_id.toString() === CATEGORY.HUF ||
        selectedUser.fk_category_id.toString() === CATEGORY.PROPRIETOR)
    ) {
      setIsMemberOrDirector(true);
    } else {
      setIsMemberOrDirector(false);
    }
  }, [selectedUser]);
  const makeEditable = (guarantor) => {
    dispatch(
      onParticipantDetail(
        {
          applicant_type: "Guarantor",
          applicant_coapplicant_guarantor_id: guarantor.id,
        },
        (guarantor) => {
          dispatch(onChangeSelectedUser(guarantor));
          setToEditGuarantor(guarantor);
        }
      )
    );
  };
  const openGuarantorModal = () => {
    if(isCreditReviewPending(caseDetail.status)){
      setInformationModal(true);
      setInformationTitle(MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE)
    }else{
      if (caseDetail.guarantors.length >= 5) {
        setMaxGuarantorError(MESSAGES.MAX_GUARANTOR_ERROR);
      } else {
        setToEditGuarantor(false);
        dispatch(onResetParticipant(true));
        setAddGuarantor(true);
      }
    }
    
  };
  const handleCancel = () => {
    setAddGuarantor(false);
    setToEditGuarantor(false);
    setAskJustification(false);
    form2.resetFields();
  };

  const closeModal = (newlyCreatedGuarantor) => {
    if (newlyCreatedGuarantor) {
      dispatch(
        onCaseDetail({ caseId: caseDetail.id }, (caseDetails) => {
          if (
            caseDetails &&
            caseDetails.guarantors &&
            Array.isArray(caseDetails.guarantors)
          ) {
            const newGuarantorDetails = caseDetails.guarantors.find(
              (guarantor) => {
                return (
                  guarantor.id.toString() ===
                  newlyCreatedGuarantor.id.toString()
                );
              }
            );
            dispatch(onChangeSelectedUser(newGuarantorDetails));
          }
        })
      );
    }
    setAddGuarantor(false);
  };
  const approveGuarantorConfirmation = () => {
    setApproveGuarantorModal(false);
    approveGuarantor(guarantorApprovalId);
  };
  const approveGuarantor = (guarantorId) => {
    let data = {
      applicant_type: APPLICANT_TYPE.GUARANTOR,
      participants_id: guarantorId,
      is_approved: "1",
    };
    dispatch(
      onApproveRejectParticipant(data, () => {
        dispatch(onCaseDetail({caseId:caseDetail.id},(caseDetail)=>{
          setGuarantors(caseDetail.guarantors);
          let updatedGuarantor = caseDetail.guarantors.find((guarantor)=>{
            return guarantor.id == guarantorId;
          });
          dispatch(onChangeSelectedUser(updatedGuarantor));
          dispatch(onChangeSecondTabKey("1"));
          dispatch(onChangeThirdTabKey("1"));
        }))
      })
    );
  };
  const approveGuarantorCancel = () => {
    setApproveGuarantorModal(false);
  };
  const ReAllocate = (reallocate) => {
    setReallocate(reallocate);
  };

  const deleteGuarantorConfirmation = () => {
    setDeleteGuarantorModalVisible(false);
    deleteGuarantor(guarantorDeletionId);
  };
  const deleteGuarantorCancel = () => {
    setDeleteGuarantorModalVisible(false);
  };
  const deleteGuarantor = (guarantorId) => {
    dispatch(
      onDeleteGuarantor(guarantorId, () => {
        dispatch(
          onCaseDetail({ caseId: caseDetail.id }, (caseDetail) => {
            if (
              selectedUser &&
              selectedUser.id &&
              selectedUser.id.toString() === guarantorId.toString()
            ) {
              if (
                caseDetail &&
                caseDetail.guarantors &&
                caseDetail.guarantors.length > 0
              ) {
                dispatch(onChangeSelectedUser(caseDetail.guarantors[0]));
              } else {
                dispatch(onChangeSelectedUser(null));
              }
            }
          })
        );
      })
    );
  };

  const saveJustificationComments = (data) => {
    dispatch(onJustificationComment(data, onSuccess, onError));
  };

  const onSuccess = (data) => {
    form2.resetFields();
    setAskJustification(false);
    let id = caseDetail.id;
    dispatch(onFetchJustificationComment(id));
  };
  const onError = (data) => {
    form2.resetFields();
    toast.error(data.message);
  };
  const dropGuarantorHandler = (guarantor) => {
    setGuarantorDeletionId(guarantor.id);
    setDropGuarantorModalVisible(true);
  };
  const dropGuarantorCancel = () => {
    setDropGuarantorModalVisible(false);
  };
  const dropGuarantorConfirmation = () => {
    setDropGuarantorModalVisible(false);
    dropGuarantor(guarantorDeletionId);
  };
  const dropGuarantor = () => {
    let data = {
      applicant_type: APPLICANT_TYPE.GUARANTOR,
      participants_id: guarantorDeletionId,
      is_approved: "0",
    };
    dispatch(
      onApproveRejectParticipant(data, (response) => {
        
        dispatch(onCaseDetail({caseId:caseDetail.id},(caseDetail)=>{
          setGuarantors(caseDetail.guarantors);
          let updatedGuarantor = caseDetail.guarantors.find((guarantor)=>{
            return guarantor.id == guarantorDeletionId;
          });
          dispatch(onChangeSelectedUser(updatedGuarantor));
          dispatch(onChangeSecondTabKey("1"));
          dispatch(onChangeThirdTabKey("1"));
        }))
      })
    );
  };
  const approveGuarantorHandler = (guarantor) => {
    setGuarantorApprovalId(guarantor.id);
    setApproveGuarantorModal(true);
  };
  return (
    <>
      <div className="HeaderPanelAction">
        <h3></h3>
        {userData &&
          isSalesUser(userData) &&
          isCaseNotClosed(caseDetail.status) && (
            <div className="ButtonWrap">
              <Button onClick={openGuarantorModal}>Add New Guarantor</Button>
            </div>
          )}
      </div>
      {maxGuarantorError && (
        <div className="text-left errorMessage">{maxGuarantorError}</div>
      )}
      <div className="TableWrap">
        <table>
          <thead>
            <tr>
              <th>Guarantor Type</th>
              <th>Name</th>
              <th>Status</th>
              <th>Stage</th>
              {caseDetail &&
                isCaseNotClosed(caseDetail.status) &&
                (isSalesUser(userData) ||
                  (isCreditUser(userData) &&
                    caseDetail.status === STATUS.CREDIT_REVIEW_PENDING)) && (
                  <th>Action</th>
                )}
            </tr>
          </thead>
          <tbody>
            {guarantors &&
            Array.isArray(guarantors) &&
            guarantors.length > 0 ? (
              guarantors.map((guarantor, index) => (
                <tr key={guarantor.id}>
                  <td>
                    <Radio.Group
                      name="radiogroup"
                      value={selectedUser && selectedUser.id}
                      onChange={(val) => {
                        dispatch(onChangeSelectedUser(guarantor));
                      }}
                    >
                      <Radio value={guarantor.id}>
                        {guarantor.category_name}
                      </Radio>
                    </Radio.Group>
                  </td>
                  <td width={'400px'}>
                    {guarantor.category_name === CATEGORY_NAME.INDIVIDUAL &&
                      (guarantor.first_name ? guarantor.first_name : "-")}{" "}
                    {guarantor.category_name === CATEGORY_NAME.INDIVIDUAL &&
                      guarantor.last_name}
                    {guarantor.category_name !== CATEGORY_NAME.INDIVIDUAL &&
                      guarantor.organization_name}
                  </td>
                  <td>
                    <div className={getClassNameByStatus(guarantor.status)}>
                      {guarantor.status}
                    </div>
                  </td>
                  <td>{guarantor.stage}</td>
                  {caseDetail &&
                    isCaseNotClosed(caseDetail.status) &&
                    (isSalesUser(userData) ||
                      (isCreditUser(userData) &&
                        caseDetail.status ===
                          STATUS.CREDIT_REVIEW_PENDING)) && (
                      <td>
                        {/*<Button>edit</Button>*/}
                        {isSalesUser(userData) && (
                          <React.Fragment>
                            {guarantor.status === STATUS.DROPPED && <Button
                              onClick={() => {
                                if (isCreditReviewPending(caseDetail.status)) {
                                  setInformationModal(true);
                                  setInformationTitle(
                                    MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE
                                  );
                                } else {
                                  approveGuarantorHandler(guarantor);
                                }
                              }}
                              disabled={
                                guarantor.status === STATUS.APPROVED ||
                                caseDetail.status === STATUS.REJECTED ||
                                caseDetail.status ===
                                  STATUS.CREDIT_BUREAU_APPROVED
                              }
                            >
                              Re-open
                            </Button>}
                            {guarantor.status !== STATUS.DROPPED && <Button
                              onClick={() => {
                                if (isCreditReviewPending(caseDetail.status)) {
                                  setInformationModal(true);
                                  setInformationTitle(
                                    MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE
                                  );
                                } else {
                                  dropGuarantorHandler(guarantor);
                                }
                              }}
                              disabled={
                                guarantor.status === STATUS.DROPPED ||
                                caseDetail.status === STATUS.REJECTED ||
                                caseDetail.status ===
                                  STATUS.CREDIT_BUREAU_APPROVED
                              }
                            >
                              Drop
                            </Button>}
                            <Button
                              onClick={() => {
                                if (isCreditReviewPending(caseDetail.status)) {
                                  setInformationModal(true);
                                  setInformationTitle(
                                    MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE
                                  );
                                } else {
                                  setGuarantorDeletionId(guarantor.id);
                                  setDeleteGuarantorModalVisible(true);
                                  setMaxGuarantorError("");
                                }
                              }}
                            >
                              Delete
                            </Button>
                          </React.Fragment>
                        )}
                        {/*<Button>RUN KYC</Button>*/}
                        {/*<Tooltip
                      placement="topLeft"
                      title={guarantor.status===STATUS.DROPPED ? "Guarantor Dropped":!isKycDone(guarantor.stage) ? "KYC Required" : ""}
                      arrowPointAtCenter
                    >
                      <Button
                        disabled={guarantor.status===STATUS.DROPPED}
                        className={
                          !isKycDone(guarantor.stage) ? "" : ""
                        }
                        onClick={() => {
                          if(!isKycDone(guarantor.stage)){
                            setInformationTitle('KYC should be done before asking for justification')
                            setInformationModal(true);
                          }
                          else if (
                            caseDetail.status === STAGES.CREDIT_REVIEW_PENDING
                          ) {
                            setInformationTitle("Please wait for credit review response")

                            setInformationModal(true);
                          } else {
                            setAskJustification(true);
                          }
                        }}
                        // onClick={() => setAskJustification(true)}
                      >
                        Ask for Justification
                      </Button>
                      </Tooltip>*/}
                      </td>
                    )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <p className="NoCardBox">No Guarantors</p>
                </td>
              </tr>
            )}{" "}
          </tbody>
        </table>
      </div>

      {/* ReAllocate */}
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={reallocate}
        onOk={() => ReAllocate(false)}
        onCancel={() => ReAllocate(false)}
        footer={
          <Button key="back" className="Btndefault">
            <Link to="/OnGoingCases">View Details </Link>
          </Button>
        }
        width={600}
        className="KycCheck KYCform"
        style={{ top: 50 }}
      >
        <ReAllocate />
      </Modal>
      {caseDetail &&
        caseDetail.guarantors &&
        caseDetail.guarantors.length > 0 &&
        selectedUser &&
        (isMemberOrDirector ? <SecondTab /> : <ThirdTab />)}
      <Modal
        title="Add New Guarantor"
        centered
        visible={addGuarantor}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        className="AddCoapplicant"
        style={{ top: 50 }}
        destroyOnClose={true}
      >
        {editGuarantor ? (
          <AddGuarantor
            editGuarantor={editGuarantor}
            makeEditable={makeEditable}
            add_new_guarantor_inside_modal={true}
            handleCancel={handleCancel}
          />
        ) : (
          <AddGuarantor
            makeEditable={makeEditable}
            add_new_guarantor_inside_modal={true}
            handleCancel={handleCancel}
          />
        )}
        {/*<Participant
          type="Guarantor"
          caseId={caseDetail.id}
          modalType="add_new_guarantor"
          closeModal={closeModal}
          closeUploadDocumentsModalHandler={closeModal}
        />*/}
        {/*<AddnewCoapplicant />*/}
      </Modal>

      <AskForJustificationModal
        caseDetail={caseDetail}
        handleOk={saveJustificationComments}
        form={form2}
        isModalVisible={askJustification}
        handleCancel={handleCancel}
        selectedApplicant={selectedApplicant}
      />
      <InformationModal
        isVisible={informationModal}
        informationContent={informationTitle}
        title={"Credit Review Pending"}
        handleOk={() => {
          setInformationModal(false);
        }}
      />
      {/* Ask For Justification */}

      <Modal
        visible={deleteGuarantorModalVisible}
        onCancel={deleteGuarantorCancel}
        className="KycCheck"
        onOk={deleteGuarantorConfirmation}
      >
        <p className="dangerconfirmationtext">
          Are you sure you want to delete this Guarantor?
        </p>
      </Modal>
      <DropCoapplicantGuarantorModal
        isModalVisible={dropGuarantorModalVisible}
        handleCancel={dropGuarantorCancel}
        handleOk={dropGuarantorConfirmation}
        isCoapplicantOrGuarantor={APPLICANT_TYPE.GUARANTOR}
      />
      <ApproveCoapplicantGuarantorModal
        isModalVisible={approveGuarantorModalVisible}
        handleCancel={approveGuarantorCancel}
        handleOk={approveGuarantorConfirmation}
        isCoapplicantOrGuarantor={APPLICANT_TYPE.GUARANTOR}
      />
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(Guarantorform);
