import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Form, Row, Select, Input, Modal } from "antd";
import { getVahanRequestDetails, getVahanRequestDetailsByid } from "../../../../redux/actions/VaahanAction";


export default function HptRequest(props) {
  const dispatch = useDispatch();
  const requestData= useSelector((state) => state?.vaahan?.vaahanDataByID?.hpt);



  return (
    <>
      <div className=" card card-body">
        <Row gutter={24}>
         
            <Col span={8} md={24} xs={24} sm={24}>
              <Row>
              <Col
                  span={12}
                  xs={24}
                  md={12}
                  sm={12}
                  style={{ paddingBottom: "18px" }}
                >
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <span className="font-weight-bold">Chassis No </span>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <spanp>
                        {requestData?.chachis_no
                          ? requestData?.chachis_no
                          : "-"}
                      </spanp>
                    </Col>
                  </Row>
                </Col>

                <Col span={8} md={12} xs={24} sm={12}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <span className="font-weight-bold">Engine No</span>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <span>
                        {requestData?.eng_no
                          ? requestData?.eng_no
                          : "-"}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={12}
                  xs={24}
                  md={12}
                  sm={12}
                  style={{ paddingBottom: "18px" }}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <span className="font-weight-bold">Registration No</span>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <span>
                        {requestData?.reg_no
                          ? requestData?.reg_no
                          : "-"}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} md={12} xs={24} sm={12}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <span className="font-weight-bold">Termination Date</span>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <span>
                        {requestData?.termination_date
                          ? requestData?.termination_date
                          : "-"}
                      </span>
                    </Col>
                  </Row>
                </Col>
                <Col span={8} md={12} xs={24} sm={12}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <span className="font-weight-bold">Reference No</span>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <span>
                        {requestData?.ref_number
                          ? requestData?.ref_number
                          : "-"}
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
        </Row>
      </div>
    </>
  );
}
