import { Button, Tabs, Card } from "antd";
import React, { Component } from "react";
import Crif from './crif';
import {Link} from "react-router-dom";
const { TabPane } = Tabs;
class MainApplicant extends Component {
  render() {
    return (
      <>
        <div className="HeaderPanelAction">
          <h3>Report Run Summary </h3>
          <Button><Link to="/BureauReport">Re-Run Report</Link></Button>
        </div>
        <div className="TableWrap">
          <table>
            <thead>
              <tr>
                <th>Bureau Name</th>
                <th>Score</th>
                <th>Report Generated on </th>
                <th>Run Parameters</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CIBIL</td>
                <td>256</td>
                <td>01/02/2021</td>
                <td>
                  <a>Click Here</a>
                </td>
                <td>
                  <Button>view report</Button>
                  <Button>Download PDF</Button>
                  <Button>Download Summary</Button>
                </td>
              </tr>
              <tr>
                <td>CIBIL</td>
                <td>256</td>
                <td>01/02/2021</td>
                <td>
                  <a>Click Here</a>
                </td>
                <td>
                  <Button>view report</Button>
                  <Button>Download PDF</Button>
                  <Button>Download Summary</Button>
                </td>
              </tr>
              <tr>
                <td>CIBIL</td>
                <td>256</td>
                <td>01/02/2021</td>
                <td>
                  <a>Click Here</a>
                </td>
                <td>
                  <Button>view report</Button>
                  <Button>Download PDF</Button>
                  <Button>Download Summary</Button>
                </td>
              </tr>
              <tr>
                <td>CIBIL</td>
                <td>256</td>
                <td>01/02/2021</td>
                <td>
                  <a>Click Here</a>
                </td>
                <td>
                  <Button>view report</Button>
                  <Button>Download PDF</Button>
                  <Button>Download Summary</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="InnerTabsApplicant">
            <Card>
          <Tabs defaultActiveKey="1">
            <TabPane tab="CRIf" key="1">
                <Crif/>
            </TabPane>
            <TabPane tab="CIBIL" key="2"></TabPane>
          </Tabs>
          </Card>
        </div>
      </>
    );
  }
}
export default MainApplicant;
