import React, { useEffect, useState } from "react";
import { Form, Col, Upload, Button, Input, Row } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import uplaodIcon from "../../assets/images/uploadfile.png";
// import {
//   onCaseDetail,
//   onChangeSelectedUser,
//   onDeleteNonOcrDocument,
//   onFirstTabChange,
//   onUploadNonOcrDocuments,
// } from "../../redux/actions/AuthAction";
import Modal from "antd/lib/modal/Modal";
import { toast } from "react-toastify";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileNameFromUrl,
  fileTypeFromName,
  firstCharacterOfEachWordUpperCase,
  isSalesUser,
  loggedInUserData,
} from "../../helpers/utils";
// import PreviewImageOrPDF from "./modals/PreviewImageOrPDF";
import { REGEX } from "../../constants/regex";
import { onCaseDetail } from "../../redux/actions/CaseAction";
import { onFirstTabChange } from "../../redux/actions/TabAction";
import { onChangeSelectedMemberDirector, onChangeSelectedUser } from "../../redux/actions/AuthAction";
import {
  onDeleteNonOcrDocument,
  onUploadNonOcrDocuments,
} from "../../redux/actions/NonOcrAction";
import PreviewImageOrPDF from "../case-participants/modals/PreviewImageOrPDF";
import { handlePreviewNonOcrDoc } from "../case-participants/helpers/handlePreview";
import { onMemberDirectorDetail } from "../../redux/actions/MemberAndDirectorAction";
const SavedNonOcrDocuments = (props) => {
  const {
    nonOcrDocuments = [],
    caseDetail,
    setNonOcrDocuments,
    firstTab,
  } = props;
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [previewVisible, setPreviewVisible] = useState(false);
  const secondTabKey = useSelector((state) => state.tabReducer.secondTabKey);
  const dispatch = useDispatch();
  const [nonOcrDeleteModalVisible, setNonOcrDeleteModalVisible] =
    useState(false);
  const userData = loggedInUserData();
  const updateNonOcrDocuments = () => {
    let selectedTab = firstTab;
    dispatch(
      onCaseDetail({ caseId: caseDetail.id }, (response) => {
        if (isMemberOrDirector) {
          dispatch(
            onMemberDirectorDetail(selectedUser.id, (member_director) => {
              dispatch(onChangeSelectedMemberDirector(member_director))
              setSelectedUser(member_director);
            })
          );
        } else {
          dispatch(
            onCaseDetail({ caseId: caseDetail.id }, (response) => {
              dispatch(onFirstTabChange(selectedTab));
              if (selectedUser.applicant_type === "Main Applicant") {
                dispatch(onChangeSelectedUser(response.main_applicant));
              } else if (selectedUser.applicant_type === "Co Applicant") {
                const updatedCoApplicant = response.co_applicants.find(
                  (co_applicant) => co_applicant.id === selectedUser.id
                );
                dispatch(onChangeSelectedUser(updatedCoApplicant));
              } else if (selectedUser.applicant_type === "Guarantor") {
                const updatedGuarantor = response.guarantors.find(
                  (guarantor) => guarantor.id === selectedUser.id
                );
                dispatch(onChangeSelectedUser(updatedGuarantor));
              }
            })
          );
        }
      })
    );
  };
  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  const [selectedUser, setSelectedUser] = useState({});
  const [isMemberOrDirector, setIsMemberOrDirector] = useState(false);
  const [isMemberOrDirectorModalOpen, setMemberOrDirectorModalOpen] =
    useState(false);
    const selectedMemberDirector = useSelector(
      (state) => state.auth.selectedMemberDirector
    );
  useEffect(() => {
    if (secondTabKey === "1") {
      setIsMemberOrDirector(false);
      setSelectedUser(selectedApplicant);
    } else {
      setIsMemberOrDirector(true);
      setSelectedUser(selectedMemberDirector);
    }
  }, [secondTabKey, selectedMemberDirector, selectedApplicant]);
  const uploadNonOcrDocument = (docName, docFile) => {
    const formData = new FormData();
    formData.append("fk_case_id", caseDetail.id); //77
    formData.append("applicant_type", selectedApplicant.applicant_type);
    formData.append("applicant_coapplicant_guarantor_id", selectedApplicant.id);
    if(isMemberOrDirector){
      formData.append("member_director_id", selectedUser.id);
    }
    formData.set("document_name", docName);
    formData.set("document", docFile);
    
    dispatch(
      onUploadNonOcrDocuments(formData, (res) => {
        updateNonOcrDocuments();
      })
    );
  };
  const addNonOcrDocument = () => {
    const newDoc = props.form.getFieldValue("DocName");
    props.form.setFields([
      {
        name: "DocName",
        errors: [],
      },
    ]);
    if (
      nonOcrDocuments.find((doc) => {
        return (
          (doc.name && doc.name === newDoc) ||
          (doc.document_name && doc.document_name === newDoc)
        );
      })
    ) {
      props.form.setFields([
        {
          name: "DocName",
          errors: ["The document with this name is already added."],
        },
      ]);
      return;
    }
    if (!newDoc) {
      props.form.setFields([
        {
          name: "DocName",
          errors: ["Please enter doc name"],
        },
      ]);
      return;
    }
    if (!props.form.getFieldValue("non_ocr_doc_pic")) {
      props.form.setFields([
        {
          name: "non_ocr_doc_pic",
          errors: ["Please upload document"],
        },
      ]);
      return;
    }
    uploadNonOcrDocument(
      newDoc,
      props.form.getFieldValue("non_ocr_doc_pic").file
    );
    props.form.resetFields(["DocName", "non_ocr_doc_pic"]);
  };
  const handlePreview = async (fileURL) => {
    setPreviewImage(false);
    setPreviewPdf(false);
    const fileName = fileNameFromUrl(fileURL);
    const fileType = fileTypeFromName(fileName);
    if (fileType === "pdf") {
      setPreviewPdf(fileURL);
    } else {
      setPreviewImage(fileURL);
    }
    setPreviewVisible(true);
  };
  const handleDeleteNonOcrDocument = () => {
    setNonOcrDeleteModalVisible(false);
    dispatch(
      onDeleteNonOcrDocument({ id: nonOcrDeleteModalVisible }, () => {
        let filteredNonOcrDocs = nonOcrDocuments.filter(function (obj) {
          return obj.id !== nonOcrDeleteModalVisible;
        });
        setNonOcrDocuments(filteredNonOcrDocs);
        updateNonOcrDocuments();
      })
    );
  };
  return (
    <React.Fragment>
      <Row gutter={32} className="UploadWrap">
        {userData && false && (
          <Col span={12} xs={24} md={12} sm={12}>
            <Form.Item label="Document Name" name="DocName">
              <Input
                onFocus={() => {
                  props.form.setFields([
                    {
                      name: "DocName",
                      errors: [],
                    },
                  ]);
                }}
                onChange={(e) => {
                  const input = e.target.value;
                  if (!REGEX.NAME_VALIDATION.test(input)) {
                    props.form.setFieldsValue({
                      DocName: firstCharacterOfEachWordUpperCase(
                        input.substring(0, input.length - 1)
                      ).replace(/[^a-zA-Z0-9&-/s ]/gm, ""),
                    });
                  } else {
                    props.form.setFieldsValue({
                      DocName: firstCharacterOfEachWordUpperCase(input).replace(
                        /[^a-zA-Z0-9&-/s ]/gm,
                        ""
                      ),
                    });
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Upload"
              name="non_ocr_doc_pic"
              onFocus={() => {
                props.form.setFields([
                  {
                    name: "non_ocr_doc_pic",
                    errors: [],
                  },
                ]);
              }}
            >
              <Upload
                maxCount={1}
                beforeUpload={(file, fileList) => {
                  if (checkFileTypeImageAndPdf(file)) {
                    if (checkFileSizeOneMB(file)) {
                      return false;
                    }
                  }
                  return Upload.LIST_IGNORE;
                }}
                showUploadList={{
                  showRemoveIcon: false,
                }}
                className="uploadfield"
              >
                <Button>
                  Upload <img src={uplaodIcon} alt="uplaodIcon" />
                </Button>
              </Upload>
            </Form.Item>
            <div className="text-right">
              <Button
                htmlType="button"
                className="AddField  mb-2"
                onClick={addNonOcrDocument}
              >
                Add
              </Button>
            </div>
            {/*<Button onClick={addNonOcrDocument}>Add</Button>*/}
          </Col>
        )}
      
      <Col span={12} xs={24} md={12} sm={12}>
        {nonOcrDocuments.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Document Name </th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {nonOcrDocuments.map((nonOcrDocument, index) => {
                
                return (
                  <tr key={index.toString()}>
                    <td>{nonOcrDocument.document_name}</td>
                    <td>
                      <Button
                        onClick={() => {
                          let document = {
                            id: nonOcrDocument.id,
                            doc: nonOcrDocument.document_path,
                            name: nonOcrDocument.document_name,
                          };
                          // handlePreview(nonOcrDocument.document_path);
                          handlePreviewNonOcrDoc(document, dispatch);
                        }}
                      >
                        View
                      </Button>
                      {/*{userData && isSalesUser(userData) && (
                        <Button
                          className="delete"
                          onClick={() => {
                            setNonOcrDeleteModalVisible(nonOcrDocument.id);
                          }}
                        >
                          Delete
                        </Button>
                        )}*/}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </Col>
      </Row>
      <PreviewImageOrPDF />
      <Modal
        visible={nonOcrDeleteModalVisible}
        onCancel={() => {
          setNonOcrDeleteModalVisible(false);
        }}
        className="KycCheck"
        onOk={handleDeleteNonOcrDocument}
      >
        <p className="dangerconfirmationtext">
          Are you sure you want to delete this document?
        </p>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
    isCaseSaved: auth.isNewlyCreatedCaseSaved,
    savedCase: auth.newlyCreatedCase,
    mainApplicant: auth.mainApplicant,
    coApplicant: auth.coApplicant,
    gurantor: auth.gurantor,
    caseDetail: auth.caseDetail,
    selectedUser: auth.selectedUser,
    firstTab: auth.firstTab,
  };
};
export default connect(mapStateToProps, null)(SavedNonOcrDocuments);
