import { Button, Col, Form, Input, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { onLoadFleetAnalysisByCase, onReRunFleetAnalysis } from "../../../redux/actions/FleetAnalysis";

export const ConfirmationAlertBox = ({
  deleteVisible,
  deleteCancel,
  deleteConfirm,
  alertMessage,
  rc_number,
  fleetId,
  closeModal,
  fleetAnalysisList
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleReRun = (data) => {
    console.log(data);
    dispatch(onReRunFleetAnalysis(data, onSuccess));
  };
  const onSuccess = (responseData) => {
    closeModal();
    toast.success(responseData.data.message);
    fleetAnalysisList();
    // dispatch(onLoadFleetAnalysisByCase(id, onloadScreen));
  }; 
  form.setFieldsValue({
    rc_number: rc_number,
    fleet_id : fleetId
 });
  return (
    <Modal
      visible={deleteVisible}
      onCancel={deleteCancel}
      className="KycCheck JustReqWrap"
      // onOk={deleteConfirm}
      destroyOnClose={true} 
      footer={[
        <Button key="Cancel" onClick={deleteCancel} style={{ marginLeft: 10 }}>
          Cancel
        </Button>,
        // <Button key="back" onClick={deleteConfirm} style={{ width: 104 }}>
        <Button type="primary" form="myForm" key="submit" htmlType="submit" > 
          Re-Run
        </Button>,
      ]}
      // style={{ top: 90 }} 
    >
      <p className="successconfirmationtext">
        {alertMessage}
        </p>
        <Row>
        <Col span = {7}></Col>
        <Col span = {8}>
          <Form
            name="global_state"
            layout="vertical"
            form={form}
            id="myForm"
            onFinish={handleReRun}
          >
            <Form.Item
              label="RC Number"
              name="rc_number"
              className="UserNameselector"
              rules={[
                { required: true, message: "RC number is required" },
              ]}  
              // value = {rc_number}
            >
            <Input />   
            

            </Form.Item> 
            <Form.Item
              label="Fleet ID"
              name="fleet_id"
              className="UserNameselector"
              hidden = {true}
            >
            <Input/>  
            </Form.Item>
             
          </Form>
        </Col>
        </Row>
    </Modal>
  );
};
