import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import { getNotificationsAPI ,readNotificationAPI} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    GET_NOTIFICATIONS,
    HIDE_LOADING,
    SHOW_LOADING,
    GET_NOTIFICATIONS_FAILED,
    GET_NOTIFICATIONS_SUCCESS,
    READ_NOTIFICATION
} from "../constants/ActionTypes";
import { errorMessage } from "../../helpers/utils";

function* onGetNotifications() {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getNotificationsAPI);
        yield put({type: HIDE_LOADING});
        yield put({type: GET_NOTIFICATIONS_SUCCESS, notifications: response.data.data});
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: GET_NOTIFICATIONS_FAILED});
    }
 }
function* actionGetNotifications() {
    yield takeLatest(GET_NOTIFICATIONS, onGetNotifications)
}


function* onReadNotification(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(readNotificationAPI,action.data);
        yield put({type: HIDE_LOADING});
        action.onSuccess();
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
 }
function* actionReadNotification() {
    yield takeLatest(READ_NOTIFICATION, onReadNotification)
}


export default function* authSaga() {
    yield all([
        fork(actionGetNotifications),
        fork(actionReadNotification)
    ]);
}
