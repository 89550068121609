import { Button, Tabs, Card } from "antd";
import React, { Component } from "react";
import Crif from "./crif";
import { Link } from "react-router-dom";
const { TabPane } = Tabs;
class ReportTab extends Component {
  render() {
    return (
      <>
        <div className="InnerTabsApplicant" id='bureaureportdetail'>
          <Card>
            
            <Tabs defaultActiveKey="1">
              <TabPane tab="Report" key="1">
                <Crif />
              </TabPane>
              {/* <TabPane tab="CIBIL" key="2"></TabPane> */}
            </Tabs>
          </Card>
        </div>
      </>
    );
  }
}
export default ReportTab;
