import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { onForgotPassword } from "../redux/actions/AuthAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { BackButton } from "../components/common/BackButton";
import { Link } from "react-router-dom";

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSuccess = () => {
    history.push("/");
  };
  const onFinish = (values) => {
    
    const data = {
      employee_id: values.employee_id,
    };
    dispatch(onForgotPassword(data, onSuccess));
  };
  const onFinishFailed = (errorInfo) => {
    
  };
  return (
    <React.Fragment>
      <div className="AuthWrap">
        <div className="InnerWrap">
          <div className="HeadWrap">
            <h3>Forgot Password</h3>
          </div>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="employee_id"
              label="Employee Id"
              rules={[
                {
                  required: true,
                  message: "Please enter employee id",
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="ChangePassword"
              >
                Submit
              </Button>
            </Form.Item>
            <Form.Item>
              <Link to="/login">
              <Button  type="primary" className="ChangePassword">Login</Button>
                
              </Link>
            </Form.Item>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ForgetPassword;
