import {
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SUB_CATEGORY,
} from "../constants/ActionTypes";
const INITIAL_STATE = {
  selectedCategory: undefined,
  selectedSubCategory: undefined,
};
export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.data,
      };
    case SET_SELECTED_SUB_CATEGORY:
      return {
        ...state,
        selectedSubCategory: action.data,
      };
    default:
      return state;
  }
}
