import React, { useEffect,useState } from "react";
import { Button, Card } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { onGetBureauReportExcel } from "../../../../../redux/actions/BureauReportAction";

const Crif = () => {
  const dispatch = useDispatch();

  const selectedBureauReport = useSelector(
    (state) => state.bureauReport.selectedBureauReport
  );
  useEffect(() => {
      // let id=selectedBureauReport.id;
  }, [selectedBureauReport]);

  const onSuccess=(response)=>{
      const outputFilename = `bureau_report_Id_${selectedBureauReport.id+'_case_id_'+selectedBureauReport.case_id+'_generated_on_'+selectedBureauReport.generated_on}.xlsx`;
      // const url = URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = response;
      link.setAttribute('download', outputFilename);
      document.body.appendChild(link);
      link.click();
  }
 
  const downloadSheet= ()=>{
    dispatch(onGetBureauReportExcel(selectedBureauReport.id,onSuccess));
  }

  return (
    <>
      <div className="HeaderPanelAction">
        <h3>Displaying Analysis ({selectedBureauReport.bureau_name})</h3>
        <div className="ButtonWrap">
          <Button onClick={downloadSheet}>
            Download Summary Report (excel)
          </Button>
        </div>
      </div>
      {selectedBureauReport && (
        <div className="TableWrap bolddata bureau-report-summary-table">
          <h3>Bureau Report ID {selectedBureauReport.id}
          <div style={{float: "right"}}>
          {selectedBureauReport.report_for=='Individual' || !selectedBureauReport.report_for?
          'All summary amounts in INR'
          :
          'All summary amounts in crores (INR)'}
              </div>
          </h3>
      
          <Card>
            <table>
              <thead>
                <tr>
                  <th>CIBIL/CRIF Score</th>
                  <th>Risk Category</th>
                  <th style={{width:'300px'}}>Scoring Factors/Risk Description</th>
                  <th>Total Loans (Count)</th>
                  <th>Active Loans (Count)</th>
                  <th>Total Sanctioned Amount</th>
                  <th>Total Principal Outstanding</th>
                  <th>Total Account With Overdue (Count)</th>
                  <th>Amount Of Overdue</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{selectedBureauReport.score}</td>
                  <td>{selectedBureauReport.risk_category}</td>
                  <td>{selectedBureauReport.risk_description}</td>
                  <td>{selectedBureauReport.total_loans_count}</td>
                  <td>{selectedBureauReport.active_loans_count}</td>
                  <td>{selectedBureauReport.total_sanctioned_amount}</td>
                  <td>{selectedBureauReport.total_principal_outstanding}</td>
                  <td>{selectedBureauReport.total_acoount_overdue_count}</td>
                  <td>{selectedBureauReport.amount_of_overdue}</td>
                </tr>
              </tbody>
            </table>
          </Card>
        </div>
      )}
      {selectedBureauReport &&
        Array.isArray(selectedBureauReport.triggers) &&
        selectedBureauReport.triggers.map((trigger, index) => (
          <div className=" BoxCardTitle bureau-report-summary-table-2">
            <Card title={`Trigger ${index + 1}`}>
              <div className="TableWrap">
                <table>
                  <thead>
                    <tr>
                      <th>Trigger Point</th>
                      <th>Account Type</th>
                      <th>Ownership</th>
                      <th>Sanctioned Date</th>
                      <th>Sanctioned Amount / Disbursed Amount</th>
                      <th>Current Balance</th>
                      <th>Loan Status (Active/Closed)</th>
                      <th>Closure Date (If Closed)</th>
                      <th>Overdue Amount</th>
                      <th>Report Date / Last Paid Date</th>
                      <th>Trigger Month</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{trigger.trigger_point}</td>
                      <td>{trigger.account_type}</td>
                      <td>{trigger.ownership}</td>
                      <td>{trigger.sanctioned_date?trigger.sanctioned_date:'N/A'}</td>
                      <td>{trigger.sanctioned_amount}</td>
                      <td>{trigger.current_balance}</td>
                      <td>{trigger.loan_status}</td>
                      <td>{trigger.closer_date?trigger.closer_date:'N/A'}</td>
                      <td>{trigger.overdue}</td>
                      <td>{trigger.last_reported_date?trigger.last_reported_date:'N/A'}</td>
                      <td>{trigger.trigger_month}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </Card>

            {trigger.year_data &&
              trigger.year_data.map((year) => (
                <div className="TableSmall bp-table-small">
                  <table>
                    <thead>
                      <tr>
                        {Object.keys(year).map((key) => (
                          <th>{key}</th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {Object.keys(year).map((key) => (
                          <td>{year[key]}</td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </div>
              ))}
          </div>
        ))}
    </>
  );
};
export default Crif;
