export const isCaseStatusLogged = (caseDetail) => {
  if (
    caseDetail &&
    caseDetail.main_applicant &&
    caseDetail.main_applicant.ocr_documents &&
    Array.isArray(caseDetail.main_applicant.ocr_documents) &&
    caseDetail.main_applicant.ocr_documents.length === 0
  ) {
    return true;
  }
};
