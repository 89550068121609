import React, { useEffect, useState } from "react";
import { Form, Select, Button, Input, Modal } from "antd";
import { MESSAGES } from "../../constants/messages";
import {
  loggedInUserData
} from "../../helpers/utils";
import { useDispatch } from "react-redux";
import { onGetUserByStateCity } from "../../redux/actions/CaseAction";
const ReAllocationModal = (props) => {
  const { Option } = Select;
  const [stateId, setStateId] = useState("");
  const [listofusers,setListofusers] = useState([])
  const dispatch = useDispatch()
  const [form] = Form.useForm();
  const userData = loggedInUserData();
  useEffect(() => {
    setStateId('')
    // props.getUserbystatecity();
    // const onSuccessUsers = (data) => {
    //   setListofusers(()=>{return data});
    // };
    // dispatch(onGetUserByStateCity(onSuccessUsers));

    // form.resetFields();
  }, [props.isModalVisible]);

  const onSelectStateChange = (e) => {
    setStateId(e)
    form.setFieldsValue({
      city: undefined,
    });
    props.getCity(e);
  }
  const onSelectCityChange = (e) => {
    if (stateId) {
      props.getUserbystatecity(stateId, e);
    }
  }
  const onSubmit = (value) => {
    props.handleOk(value)
  }
  const onCancel = () => {
    form.resetFields();
    props.handleCancel()
  }
  return (
    <>
      <Modal
        title="Case Re-Allocation"
        visible={props.isModalVisible}
        // onOk={props.handleOk}
        onCancel={onCancel}
        className='JustReqWrap'
        footer={[
          <Button key="back" onClick={onCancel}>
            Cancel
          </Button>,
          <Button type="primary" form="myForm" key="submit" htmlType="submit" >
            Submit
          </Button>,
        ]}
        style={{ top: 90 }}
        destroyOnClose={true}
      >
        <Form
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="vertical"
          form={form}
          id="myForm"
          onFinish={onSubmit}
        >
          <Form.Item label="Select User" className="applicantName" name="user_id"
            rules={[
              { required: true, message: "Please select user" },
            ]} >
            <Select placeholder='Please select user' >
              {props.listofusers && props.listofusers.map((user) => (
                  <Option value={user.id} key={user.id}>
                    {user.first_name} {user.last_name} ({user.employee_id})
                  </Option>)
              )}
            </Select>
          </Form.Item>

          <Form.Item
            name="reason"
            className="UserNameselector"
            label="Reason For Re-Allocation"
            rules={[
              {
                required: true,
                message: 'Please enter the reason of re-allocation',
              },
              {
                max: 250,
                message: MESSAGES.COMMENT_MAX_LENGTH,
              },]}
          >
            <Input.TextArea placeholder='Please enter the reason'/>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ReAllocationModal;
