// export const BASE_URL = 'https://medicorumslpapi.siplsolutions.com/api/v1';
// export const BASE_URL = 'https://efsvfsapi.siplsolutions.com/api/';
export const BASE_URL = 'https://efsvfsapi.siplsolutions.com/api/';
export const DEFAULT_CURRENCY = '$';
export const API = {
    login: `login`,
    forgetPassword:'forget-password',
    cities:'cities',
    states:'states',
    updateMainApplicant:'main-applicant/detail/store',
    updateCoApplicant:'co-applicant/detail/store',
    updateGuarantor:'guarantor/detail/store',
    addParticipant:'applicant-coapplicant-guarantor/store',
    runOcr:'run-ocr',
    deleteNonOcrDocument:'delete-non-ocr-document',
    deleteOcrDocument:'delete-ocr-document',
    getParticipantDetail:'applicant/detail',
    runKycCheck:'run-kyc',
    dashboardAPI:'dashboard',
    deleteCoApplicant:'delete-coapplicant',
    participantApproveReject:'participants-approve-reject',
    participantByCaseId:'get-applicants-by-case',
    deleteGuarantor:'delete-guarantor',
    creditReviewPendingCases:'credit-review-pending-cases',
    creditRequest:'credit-request',
    getCreditRequest:'get-credit-request',
    commentByCredit:'comment-by-credit',
    getCustomerJustification :'get-customer-justification',
    addMemberOrDirector:'member-director/store',
    deleteMemberOrDirector:'delete-member-director',
    getMemberDirector:'member-director/detail',
    getMemberDirectorList:'get-member-director-list',
    creditUsers:'credit-users',
    salesOnGoingCases:'cases',
    customerJustification:'customer-justification',
    salesUsers:'sales-users',
    getBureauReportListing:'bureau-report-list',
    getBureauReportMasters :'get-bureau-report-masters',
    generateBureauReport:'generate-bureau-report',
    bureauReportDetail:'get-bureau-report',
    userOcrResult:'user-ocr-result',
    getUserOcrResult:'user-ocr-result',
    getBureauReportHtml:'get-bureau-report-html',
    salesCreditUsersForLocation:'sales-credit-users-for-location',
    caseReallocation:'case-reallocation',
    getRequestBureauParameters:'get-request-bureau-parameters',
    getNotifications :'get-notifications',
    readNotification:'read-notification',
    caseApproveReject:'case-approve-reject',
    closedCases:'closed-cases',
    bureauSummaryReport:'bureau-summary-report',
    systemRecommendation:'system-recommendation',
    getCibilReportMasters :'get-cibil-report-masters',
    generateCibilBureauReport:'generate-cibil-bureau-report',
    cibilCities:'cibil-cities',
    userBureauAddress:'user-bureau-address',
    getBureauReportExcel:'get-bureau-report-excel',
    getCaseDetailExcel:'download-case-summary-report',
    ssoLogin:'sso-login',
    checkParams:'check-params',
    runFleetAnalysis:'run-fleet-analysis',
    loadFleetApi:'fleet-analysis-list',
    loadFleetByyCaseApi:'fleet-analysis-list-by-case',
    reRunFleetAnalysisApi:'re-run-fleet-analysis',
    getCasesApi:'get-cases-list',
    downloadFleetReportApi:'download-fleet-excel-report',
    downloadFleetTemplateReportApi:'download-template',
    downloadFleetSummaryReportApi : 'download-fleet-summary-excel-report',


    
    //Api created by yadu
    loadKycDoneCases:'kyc-done-cases',
    hunterSummaryList:"hunter-summary-list",
    loadRunHunter:'run-hunter',
    getBureauReportGeneratedCases :'bureau-report-generated-cases',
    hunterCaseDetail:'hunter-details',
    downloadHunterSummary:'download-hunter-summary',

    //Api created by Shashikant
    getAgent :'get-agent',
    addFieldInvestigation : 'send-fi-request',
    addFieldInvestigationOTP : 'send-otp',
    addFieldInvestigationOTPVerify : 'verify-otp',
    linkExpireCheck : 'check-fi-link-expire',
    getFieldInvestigationAll : 'get-fi-requests',
    getFieldInvestigationCase : 'fi-details',
    submitFieldInvestigationResponse : "submit-fi-response",
    getCaseForFI : 'view-fi-details',
    
    //vaahan api
    getVahanaDetails : 'vahan-listing',
    uploadVaahanStoreDetails:'store-vahan-details',
    getVahanRequestDetail:"vahan-request-details",
    runVahanHypothecation:"run-hypothecation",
    getVahanaDetailsByID:"vahan-details",
}

export const WEBURL = 'https://www.creditone.vfsco.in/'