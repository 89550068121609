import React, { useState } from "react";
import { Form, Row, Col, Upload, Button, Input } from "antd";
import { connect, useDispatch } from "react-redux";
import uplaodIcon from "../../assets/images/uploadfile.png";
import { onUpdateNonOcrDocs } from "../../redux/actions/AuthAction";
import Modal from "antd/lib/modal/Modal";
import { toast } from "react-toastify";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileNameFromUrl,
  fileTypeFromName,
  firstCharacterOfEachWordUpperCase,
} from "../../helpers/utils";
import { REGEX } from "../../constants/regex";
import PreviewImageOrPDF from "./modals/PreviewImageOrPDF";
import { REQUIRED_MESSAGES } from "../../constants/messages";
const MainAppplicant = (props) => {
  const {
    nonOcrDocuments,
    applicantCategory,
    applicantSubCategory,
    fixedNonOcrDocs,
    setFixedNonOcrDocs,
  } = props;

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const handlePreviewRequiredNonOcr = (file) => {
    if (!file.url && !file.preview) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (file.type === "application/pdf") {
          setPreviewPdf(reader.result);
        } else {
          setPreviewImage(reader.result);
        }
      };
    } else {
      if (file.fileType === "pdf") {
        setPreviewPdf(file.url);
      } else {
        setPreviewImage(file.url || file.preview);
      }
    }
    setPreviewVisible(true);
  };
  const handlePreview = async (document) => {
    setPreviewPdf(false);
    setPreviewImage(false);
    if (document && document.doc && document.doc.file) {
      var reader = new FileReader();
      reader.readAsDataURL(document.doc.file);
      reader.onloadend = function (e) {
        if (document.doc.file.type === "application/pdf") {
          setPreviewPdf(reader.result);
        } else {
          setPreviewImage(reader.result);
        }
      };
    } else {
      const fileName = fileNameFromUrl(document.doc);
      const fileType = fileTypeFromName(fileName);
      if (fileType === "pdf") {
        setPreviewPdf(document.doc);
      } else {
        setPreviewImage(document.doc);
      }
    }
    setPreviewVisible(true);
  };
  const beforeUpload = (file) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file)) {
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const addNonOcrDocuments = (nonOcrDoc) => {
    const newDoc = props.form.getFieldValue("DocName");
    props.form.setFields([
      {
        name: "DocName",
        errors: [],
      },
    ]);
    if (props.nonOcrDocuments.find((doc) => doc.name === newDoc)) {
      props.form.setFields([
        {
          name: "DocName",
          errors: ["The document with this name is already added."],
        },
      ]);
      return;
    }
    if (!newDoc) {
      props.form.setFields([
        {
          name: "DocName",
          errors: ["Please enter doc name"],
        },
      ]);
      return;
    }
    if (!props.form.getFieldValue("non_ocr_doc_pic")) {
      props.form.setFields([
        {
          name: "non_ocr_doc_pic",
          errors: ["Please upload document"],
        },
      ]);
      return;
    }

    props.setNonOcrDocuments(
      props.nonOcrDocuments.concat({
        name: props.form.getFieldValue("DocName"),
        doc: props.form.getFieldValue("non_ocr_doc_pic"),
      })
    );
    props.form.resetFields(["DocName", "non_ocr_doc_pic"]);
  };
  const addRequiredNonOcrDocs = (file, list, docName) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file)) {
        // setFileList({
        //   ...fileList,
        //   [docName]: file,
        // });
        setFixedNonOcrDocs({
          ...fixedNonOcrDocs,
          [docName]: list,
        });
        return false;
      }
    }
    return Upload.LIST_IGNORE;
    // props.setNonOcrDocuments(
    //   props.nonOcrDocuments.concat({
    //     name: docName,
    //     doc: file,
    //     notToList:true,
    //   })
    // );
  };
  const GetNonOcrField = (label, name, errorMessage) => {
    // let defaultSelectedNonOcr = fixedNonOcrDocs.find((fixedNonOcrDoc)=>{
    //   return fixedNonOcrDoc.name === label
    // });
    //

    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item label={label} name={name} required={true}>
          <Upload
            maxCount={1}
            beforeUpload={(file, list) => {
              props.form.setFields([
                {
                  name,
                  errors: [],
                },
              ]);

              const result = addRequiredNonOcrDocs(file, list, label);
              if (result) {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            fileList={fixedNonOcrDocs[label]}
            onPreview={handlePreviewRequiredNonOcr}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  return (
    <>
      <Row gutter={32}>
        <Col span={24}>
          <h4>Non OCR Compatible Documents</h4>
        </Col>
        {applicantCategory === "2" && applicantSubCategory && (
          <React.Fragment>
            {(applicantSubCategory === "1" || applicantSubCategory === "2") &&
              GetNonOcrField(
                "Partnership Deed",
                "partnership_deed",
                REQUIRED_MESSAGES.PARTNERSHIP_DEED_REQUIRED
              )}
            {(applicantSubCategory === "3" || applicantSubCategory === "4") && (
              <React.Fragment>
                {GetNonOcrField(
                  "Incorporation Certificate",
                  "incorporate_certificate",
                  REQUIRED_MESSAGES.INCORPORATE_CERTIFICATE_REQUIRED
                )}
                {GetNonOcrField(
                  "Shareholding Pattern",
                  "shareholding_pattern",
                  REQUIRED_MESSAGES.SHAREHOLDING_PATTERN_REQUIRED
                )}
              </React.Fragment>
            )}
            {applicantSubCategory === "5" &&
              GetNonOcrField(
                "Trust Deed",
                "trust_deed",
                REQUIRED_MESSAGES.TRUST_DEED_REQUIRED
              )}
            {applicantSubCategory === "6" &&
              GetNonOcrField(
                "Society Deed",
                "society_deed",
                REQUIRED_MESSAGES.SOCIETY_DEED_REQUIRED
              )}
            {applicantSubCategory !== "5" &&
              applicantSubCategory !== "6" &&
              GetNonOcrField(
                "Bank Statement",
                "bank_statement",
                REQUIRED_MESSAGES.BANK_STATEMENT_REQUIRED
              )}
          </React.Fragment>
        )}
        {applicantCategory === "3" && (
          <React.Fragment>
            {GetNonOcrField(
              "HUF Deed",
              "huf_deed",
              REQUIRED_MESSAGES.HUF_DEED_REQUIRED
            )}
          </React.Fragment>
        )}
        {(applicantCategory === "3" || applicantCategory === "4") &&
          GetNonOcrField(
            "Bank Statement",
            "bank_statement",
            REQUIRED_MESSAGES.BANK_STATEMENT_REQUIRED
          )}
        {GetNonOcrField(
          "Customer Consent",
          "customer_consent",
          REQUIRED_MESSAGES.CUSTOMER_CONSENT_REQUIRED
        )}
        {applicantCategory === "4" &&
          GetNonOcrField(
            "MSME Certificate",
            "msme_certificate",
            REQUIRED_MESSAGES.MSME_CERTIFICATE_REQUIRED
          )}

        {/*{<GetPartnershipDeedField />}
      {<GetBankStatementField />}
      {<GetIncorporateCertificateField />}
      {<GetShareholdingPatternField />}
      {<GetHufDeedField />}
      {<GetMsmeCertificateField />}
  {<GetCustomerConsentField />}*/}

        <PreviewImageOrPDF
          previewVisible={previewVisible}
          previewImage={previewImage}
          previewPdf={previewPdf}
          onCancel={() => {
            setPreviewImage(false);
            setPreviewPdf(false);
            setPreviewVisible(false);
          }}
        />
      </Row>
      <Row gutter={32}>
        <Col span={12} md={12} xs={24} sm={24}>
          <Form.Item name="DocName" label="Document Name">
            {/*<Col span={8} md={8} xs={24} sm={24}>
          <label>Document Name</label>
  </Col>*/}
            <Col>
              <Input
                onFocus={() => {
                  props.form.setFields([
                    {
                      name: "DocName",
                      errors: [],
                    },
                  ]);
                }}
                onChange={(e) => {
                  const input = e.target.value;
                  if (!REGEX.NAME_VALIDATION.test(input)) {
                    props.form.setFieldsValue({
                      DocName: firstCharacterOfEachWordUpperCase(
                        input.substring(0, input.length - 1)
                      ).replace(/[^a-zA-Z0-9&-/s ]/gm, ""),
                    });
                  } else {
                    props.form.setFieldsValue({
                      DocName: firstCharacterOfEachWordUpperCase(input).replace(
                        /[^a-zA-Z0-9&-/s ]/gm,
                        ""
                      ),
                    });
                  }
                }}
              />
            </Col>
          </Form.Item>
          <Form.Item
            label="Upload"
            name="non_ocr_doc_pic"
            onFocus={() => {
              props.form.setFields([
                {
                  name: "non_ocr_doc_pic",
                  errors: [],
                },
              ]);
            }}
          >
            {/*<Col span={8} md={8} xs={24} sm={24}>
            <label>Upload</label>
        </Col>*/}

            <Upload maxCount={1} beforeUpload={beforeUpload}>
              <Button>
                Upload <img src={uplaodIcon} alt="uplaodIcon" />
              </Button>
            </Upload>
          </Form.Item>
          <div className="AddField text-right mt-2 mb-2">
            <Button htmlType="button" onClick={addNonOcrDocuments}>
              Add
            </Button>
          </div>
        </Col>
        <Col span={12} md={12} xs={24} sm={24}>
          {nonOcrDocuments.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Document Name </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {nonOcrDocuments &&
                  nonOcrDocuments.map((document, index) => {
                    return (
                      <tr key={document.name}>
                        <td>{document.name}</td>
                        <td>
                          <Button
                            onClick={() => {
                              handlePreview(document);
                            }}
                          >
                            View
                          </Button>
                          <Button
                            onClick={() => {
                              if (document.id) {
                                props.deleteNonOcrDocumentFromBackEnd(
                                  document.id
                                );
                              }
                              props.removeNonOcrDocuments(index);
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
    isCaseSaved: auth.isNewlyCreatedCaseSaved,
    savedCase: auth.newlyCreatedCase,
    mainApplicant: auth.mainApplicant,
    coApplicant: auth.coApplicant,
    gurantor: auth.gurantor,
  };
};
export default connect(mapStateToProps, null)(MainAppplicant);
