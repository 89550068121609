import React, { Component } from "react";
import { Collapse } from 'antd';
const { Panel } = Collapse;
class ReAllocate extends Component {
    render() {
        return (
          <>
          <div className='CollapseWrap'>
              <h3 className='heading'>Reading the documents....</h3>
              <Collapse accordion>
                <Panel header="Main Applicant" key="1" className='completed'>
                    <ul>
                        <li className='completed'>PAN Card</li>
                        <li className='completed'>Aadhar Card</li>
                        <li className='completed'>Voter ID</li>
                        <li className='completed'>Driving License</li>
                        <li className='completed'>Passport</li>
                        <li className='completed'>Email Authentication</li>
                        <li className='completed'>Mobile No Authentication</li>
                    </ul>
                </Panel>
                <Panel header="Co - Applicant" key="2" className='completed'>
                <ul>
                        <li className='completed'>PAN Card</li>
                        <li className='completed'>Aadhar Card</li>
                        <li className='completed'>Voter ID</li>
                        <li className='completed'>Driving License</li>
                        <li className='completed'>Passport</li>
                        <li className='completed'>Email Authentication</li>
                        <li className='completed'>Mobile No Authentication</li>
                    </ul>
                </Panel>
                <Panel header="Guarantor 1" key="3" className='loading'>
                <ul>
                        <li className='completed'>PAN Card</li>
                        <li className='completed'>Aadhar Card</li>
                        <li className='loading'>Voter ID</li>
                        <li className='loading'>Driving License</li>
                        <li className='loading'>Passport</li>
                        <li className='loading'>Email Authentication</li>
                        <li className='loading'>Mobile No Authentication</li>
                    </ul>
                </Panel>
            </Collapse>
          </div>
          
          </>
        )
    }
}
export default ReAllocate;