import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Button, Form, Input, Row, Col } from "antd";
import { toast } from "react-toastify";
import { getFIAgent, onAddFieldInvestigation } from '../../../redux/actions/FieldInvestigation'

const { Option } = Select;

export const RunRequestBoxModel = ({ deleteVisible, deleteCancel, caseRow, caseStatus, name, viewlabel, index, closeModal }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [data, setFiData] = useState([]);
  const [selectedStateId, setSelectedStateID] = useState("");
  const [selectedCityId, setSelectedCityID] = useState("");
  const [selectedRegionId, setSelectedRegionId] = useState("");
  const caseData = useSelector((state) => state.fieldInvestigation.fiCase);

  useEffect(() => {
    dispatch(getFIAgent((data) => setFiData(data)));
  }, []);

  useEffect(() => { 

    if (caseStatus === '2' && caseRow?.length > 0) {
      if (caseRow[2] === "Main Applicant") {
        
        const mainapplicantFidetails = (viewlabel.label == 1)?caseData?.main_applicant?.fi_details?.['first_fi']?.[0]:caseData?.main_applicant?.fi_details?.['second_fi']?.[0];

        updateFormFields(mainapplicantFidetails);
      } else if (caseRow[2] === "Co Applicant") {
        const coapplicantFidetails = (viewlabel.label == 1)?caseData?.co_applicants[index]?.fi_details?.['first_fi']?.[0]:caseData?.co_applicants[index]?.fi_details?.['second_fi']?.[0];

        // const coapplicantFidetails = caseData?.co_applicants[index]?.fi_details[0];
        updateFormFields(coapplicantFidetails);
      } else if (caseRow[2] === "Guarantor") {
        const guarantorFidetails = (viewlabel.label == 1)?caseData?.guarantors[index]?.fi_details?.['first_fi']?.[0]:caseData?.guarantors[index]?.fi_details?.['second_fi']?.[0];

        // const guarantorFidetails = caseData?.guarantors[index]?.fi_details[0];
        updateFormFields(guarantorFidetails);
      }
    }
    else {
      form.resetFields(["region", "state", "city", "agency"]);
      setSelectedRegionId("")
      setSelectedStateID("");
      setSelectedCityID("");
    }
  }, [deleteVisible])

  const updateFormFields = (fiDetails) => {
    form.setFieldsValue({
      region: fiDetails.region_id,
      state: fiDetails.state_id,
      city: fiDetails.city_id,
      agency: fiDetails.fi_agency_id,
    });
    setSelectedRegionId(fiDetails.region_id)
    setSelectedStateID(fiDetails.state_id);
    setSelectedCityID(fiDetails.city_id);
  }

  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "region") {
      setSelectedRegionId(changedValues[formFieldName]);
      form.resetFields(["state"]);
      form.resetFields(["city"]);
      form.resetFields(["agency"]);
    }
    if (formFieldName === "state") {
      setSelectedStateID(changedValues[formFieldName]);
      form.resetFields(["city"]);
      form.resetFields(["agency"]);
    }
    if (formFieldName === "city") {
      setSelectedCityID(changedValues[formFieldName]);
      form.resetFields(["agency"]);
    }
  };
  const handleOnFinish = async (val) => {
    var formfidata = {
      fi_agency_id: val.agency,
      case_id: caseRow[0],
      participant_id: caseRow[1],
      participant_type: caseRow[2],
      fi_attempt_id: viewlabel.label,
      city_id: val.city,
      region_id: val.region,
      state_id: val.state,
      remark: val.remark ? val.remark : '',
      fi_id: "",
    };

    console.log(formfidata);
    dispatch(onAddFieldInvestigation(formfidata, onFISuccess));
  }
  const deleteCancelModel = () => {
    setSelectedStateID("")
    setSelectedRegionId("");
    setSelectedCityID("");
    form.resetFields();
    deleteCancel();
  }
  const onFISuccess = (data) => {
    form.resetFields();
    deleteCancel();
    toast.success("FI request sent successfully");
    setTimeout(() => {
      try {
        window.location.reload(true);
      } catch (error) {
        console.error(error);
      }
    }, 2000);
  };

  //let stateData = data?.data?.fi_states.filter((val) => val.fk_region_id === selectedRegionId)

  return (

    <Modal
      title={`Field Investigation Request for ${name}`}
      centered
      open={deleteVisible}
      onCancel={deleteCancel}
      className="ModalFormWrap AddCoapplicant"
      // onOk={deleteConfirm}
      destroyOnClose={true}
      bodyStyle={{ minHeight: 250 }}
      width={800}
      footer={null}
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          name="basic"
          // initialValues={
          //   defaultValues
          // }
          onValuesChange={handleFormValuesChange}
          autoComplete="off"
          onFinish={handleOnFinish}
        >
          {data?.data ?
            <Row gutter={12}>
              <Col span={12}>
                <Form.Item
                  label="Region"
                  name="region"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Region",
                    },
                  ]}
                >
                  <Select placeholder={"Select Region"}  >
                    {data?.data?.fi_regions?.map((val) => (
                      <Option key={val.id} value={val.id}>
                        {val.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="State"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: "Please Select State",
                    },
                  ]}
                >
                  <Select
                    placeholder={"Select State"}
                    disabled={selectedRegionId.length === 0}
                  >
                    {data?.data?.fi_states
                      .filter(
                        (val) =>
                          val.fk_region_id === selectedRegionId
                      )
                      .map((state) => (
                        <Option key={state.id} value={state.id}>
                          {state.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  label="City"
                  name="city"
                  rules={[
                    {
                      required: true,
                      message: "Please Select City",
                    },
                  ]}
                >
                  <Select
                    placeholder={"Select City"}
                    disabled={selectedStateId.length === 0}
                  >
                    {data?.data?.fi_cities
                      .filter(
                        (val) =>
                          val.fk_state_id === selectedStateId
                      )
                      .map((city) => (
                        <Option key={city.id} value={city.id}>
                          {city.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label="Field Investigation Agency"
                  name="agency"
                  rules={[
                    {
                      required: true,
                      message: "Please Select Field Investigation Agency",
                    },
                  ]}
                >
                  <Select
                    placeholder={"Select FI Agency"}
                    disabled={selectedCityId.length === 0}
                  >
                    {data?.data?.fi_agent
                      .filter(
                        (val) =>
                          val.fk_city_id === selectedCityId
                      )
                      .map((agent) => (
                        <Option key={agent.id} value={agent.id}>
                          {agent.agency_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              {caseStatus === '2' &&
                <Col span={12}>
                  <Form.Item
                    label="Field Investigation Notify remark"
                    name="remark"
                    rules={[
                      {
                        required: true,
                        message: "Please Fill Review",
                      },
                    ]}
                  >
                    <TextArea rows={2} placeholder="Write Notify remark" />
                  </Form.Item>
                </Col>
              }
              <Col span={12} style={{ marginLeft: "250px", marginTop: "20px" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{
                    backgroundColor: "#202A44",
                    color: "#fff",
                  }}
                >
                  Send Request
                </Button>
                <Button key="Cancel" onClick={deleteCancelModel} style={{ marginLeft: "10px", width: "130px" }}>
                  Cancel
                </Button>
              </Col>
            </Row>
            : <h1>Loading...</h1>}
        </Form>

      </div>
    </Modal>
  );
};
