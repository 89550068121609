const initialState = {
    sidebarShow: 'responsive',
};

export default function common(state = initialState, action) {
    switch (action.type) {
        case 'set':
            return {...state, sidebarShow: action.sidebarShow }
        case 'SHOW_LOADING':
            return {...state, loading: true }
        case 'HIDE_LOADING':
            return {...state, loading: false }
        default:
            return state
    }
}