import React, { useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
} from "antd";
import ViewIcon from "../../assets/images/view.png";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useCallback } from "react";
import moment from "moment";
import { getBureauReportGeneratedCasesList } from "../../redux/actions/BureauReportAction";
import { Link } from "react-router-dom";
import { RunRequestBoxModel } from "./RequestModels/RunRequestBoxModel";
import { loggedInUserData } from "../../helpers/utils";


function Index() {
  const dispatch = useDispatch();
  const [bureauList, setBureauList] = useState([]);
  const [searchedInput, setSearchedInput] = useState("");
  const [caseId, setCaseId] = useState("");
  const [caseStatus, setCaseStatus] = useState("1");
  const [alertBoxVisible, setalertBoxVisible] = useState(false);
  const userData = useSelector((state) => state.bureauReport.beureauReportGeneratedCases);
  const loginUserData = loggedInUserData();

  const getCases = useCallback(() => {
    dispatch(getBureauReportGeneratedCasesList());
  }, [dispatch])

  const filterCaseListing = (e) => {
    const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
    const filteredCases = userData.filter((userCase) => {
      let casesById = userCase.case_id.toString().includes(searchedInput);
      let casesByCustomerName = userCase.customer_name
        ? userCase.customer_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByFinanceType = userCase.finance_type
        ? userCase.finance_type.toLowerCase().includes(searchedInput)
        : "";
      let casesBySalesAllocation = userCase.sales_allocated_name
        ? userCase.sales_allocated_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByCreditAllocation = userCase.credit_allocated_name
        ? userCase.credit_allocated_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByApplicantCategory = userCase.category_name
        ? userCase.category_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByBusinessArea = userCase.business_area
        ? userCase.business_area.toLowerCase().includes(searchedInput)
        : "";
      let casesByFirstStatus = userCase.firstFiStatus
        ? userCase.firstFiStatus
          .toLowerCase()
          .includes(searchedInput)
        : "";
      let casesBySecondStatus = userCase.secondFiStatus
        ? userCase.secondFiStatus
          .toLowerCase()
          .includes(searchedInput)
        : "";
      return (
        casesById ||
        casesByCustomerName ||
        casesByFinanceType ||
        casesBySalesAllocation ||
        casesByCreditAllocation ||
        casesByApplicantCategory ||
        casesByBusinessArea ||
        casesByFirstStatus ||
        casesBySecondStatus
      );
    });
    setBureauList(filteredCases);
  };

  const deleteFieldModelCancel = () => {
    setalertBoxVisible(false);

  };

  useEffect(() => {
    getCases();
  }, [getCases]);
  useEffect(() => {
    setBureauList(userData);
  }, [userData]);



  const columns = [
    {
      title: "Case Id",
      dataIndex: "case_id",
      key: "case_id",
      sorter: (a, b) => a.case_id - b.case_id,
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      key: "customer_name",

    },
    {
      title: "Applicant Category",
      dataIndex: "category_name",
      key: "fk_category_id",

    },
    {
      title: "First Request status",
      dataIndex: "firstFiStatus",
      key: "firstFiStatus",
      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              {(row.firstFiStatus == "Not Started" || row.firstFiStatus === "In Progress") && <div
                className="AddField"
                setCaseId={row.case_id}
                title="Not Started"
                disabled
              >
                {row.total} - {row.firstFiStatus}
              </div>}

              {row.firstFiStatus === "Completed" &&  "Completed"}
            </Space>
          </div>
        );
      },
    },
    {
      title: "First FI Request Date",
      dataIndex: "first_fi_request_date",
      key: "first_fi_request_date",
      render: (text, row, record) => {  
        return <div>{row.first_fi_request_date?moment(row.first_fi_request_date).format("ll"):'N/A'}</div>;

      },
      //sorter: (a, b) => a.UpdatedDate - b.UpdatedDate,
    },
    {
      title: "First FI Compeltion Date",
      dataIndex: "first_fi_completion_date",
      key: "first_fi_completion_date",
      render: (text, row, record) => { 
        return <div>{row.first_fi_completion_date?moment(row.first_fi_completion_date).format("ll"):'N/A'}</div>;
      },
      //sorter: (a, b) => a.UpdatedDate - b.UpdatedDate,
    },
    {
      title: "Second Request status",
      dataIndex: "secondFiStatus",
      key: "secondFiStatus",
      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              {row.secondFiStatus === "Not Started" && <div
                className="AddField"
                setCaseId={row.case_id}
                title="First FI Not Done"
                disabled
              >
                {row.secondFiStatus}
              </div>}
              {row.secondFiStatus === "In Progress" && <div
                className="AddField"
                setCaseId={row.case_id}
                title="Not Started"
                disabled
              >
                {row.total} - {row.secondFiStatus}
              </div>}
              {row.secondFiStatus === "Completed" &&  "Completed"}

            </Space>
          </div>
        );
      },
    },
    {
      title: "Second FI Request Date",
      dataIndex: "second_fi_request_date",
      key: "second_fi_request_date",
      render: (text, row, record) => {  
        return <div>{row.second_fi_request_date?moment(row.second_fi_request_date).format("ll"):'N/A'}</div>;
      },
      //sorter: (a, b) => a.UpdatedDate - b.UpdatedDate,
    },
    {
      title: "Second FI Compeltion Date",
      dataIndex: "second_fi_completion_date",
      key: "second_fi_completion_date",
      render: (text, row, record) => { 
        return <div>{row.second_fi_completion_date?moment(row.second_fi_completion_date).format("ll"):'N/A'}</div>;
 
      },
      //sorter: (a, b) => a.UpdatedDate - b.UpdatedDate,
    },
    {
      title: "Sales Allocated to",
      dataIndex: "sales_allocated_name",
      key: "sales_allocated_name",
      className: loginUserData.user_type == "Credit" ? "show" : "hide",
    }, 
    {
      title: "Actions",
      dataIndex: "action",
      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              <Link to={`/field-investigation-case-details/${row.case_id}`}>
                <img
                  src={ViewIcon}
                  alt={row.caseId}
                  title="Click to view case details"

                />
              </Link>
              {loginUserData && loginUserData.user_type === "Sales" ? (
  <>    
              <Link to={{
                pathname: `/field-investigation-request-details/${row.case_id}`,
                state: { viewlabel: 1 }
              }} >
                
                {row.firstFiStatus === "Not Started" &&
                  <Button
                    className="AddField"
                    setCaseId={row.case_id}
                    setCaseStatus={"1"}
                    title="Send Request"
                    style={{ width: "140px", marginLeft: "30px" }}
                  >
                    First FI Request
                  </Button>
                }
                {row.firstFiStatus === "In Progress" &&
                  <Button
                    className="AddField"
                    setCaseId={row.case_id}
                    setCaseStatus={"1"}
                    title="Send Request"
                    style={{ width: "140px", marginLeft: "30px" }}

                  >
                    First FI Request
                  </Button>
                }
              </Link>
              <Link to={{
                pathname: `/field-investigation-request-details/${row.case_id}`,
                state: { viewlabel: 2 }
              }}  >
                {(row.firstFiStatus === "Completed" && row.secondFiStatus === "Not Started") &&
                  <Button
                    className="AddField"
                    setCaseId={row.case_id}
                    setCaseStatus={2}
                    title="Re Send Request"
                    style={{ width: "140px", marginLeft: "15px" }}
                  >
                    Second FI Request
                  </Button>
                }
                {row.secondFiStatus === "In Progress" &&
                  <Button
                    className="AddField"
                    setCaseId={row.case_id}
                    setCaseStatus={2}
                    title="Re Send Request"
                    style={{ width: "140px", marginLeft: "15px" }}
                  >
                    Second FI Request
                  </Button>
                }
                {row.secondFiStatus === "Completed" &&
                  <Button
                    className="AddField"
                    setCaseId={row.case_id}
                    setCaseStatus={2}
                    title="Both FI Done"
                    style={{ width: "140px", marginLeft: "15px" }}
                    disabled
                  >
                    Completed
                  </Button>
                }
              </Link>
              </>
              ) : (
                ""
              )}
            </Space>
          </div>
        );
      },
    },
  ];

  const [form] = Form.useForm();
  return (
    <>
      <div className="CaseListData CaseListDataMobile">
        <Row>
          <Col span={12}>
            <Form>
              <Input.Group compact>
                <Input.Search
                  allowClear
                  placeholder="Search On Case ID,Customer Name, Applicant Category, Business Area, Case Status"
                  value={searchedInput}
                  onChange={(e) => {
                    setSearchedInput(e.target.value);
                    filterCaseListing(e);
                  }}
                />
              </Input.Group>
            </Form>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={bureauList}
          pagination={true}
        />

      </div>
      <RunRequestBoxModel deleteVisible={alertBoxVisible}
        deleteCancel={deleteFieldModelCancel}
        caseId={caseId}
        caseStatus={caseStatus}
        closeModal={() => {
          setalertBoxVisible(false);
        }}
      />
    </>
  )
}

export default Index;