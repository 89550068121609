import { all, call, fork, put, takeLatest } from "@redux-saga/core/effects";
import { errorMessage, successMessage } from "../../helpers/utils";
import {
    participantApproveRejectAPI,
    participantByCaseIdAPI,
} from "../apis/Api";
import {
    APPROVE_REJECT_PARTICIPANT,
    HIDE_LOADING,
    SHOW_LOADING,
    GET_PARTICIPANT_BY_CASE_ID
} from "../constants/ActionTypes";

function* participantApproveReject(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(participantApproveRejectAPI, action.data);
    yield put({ type: HIDE_LOADING });
    // if (response.data) {
    //   successMessage(response.data.message);
    // }
    action.onSuccess(response);
  } catch (error) {
    
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* participantByCaseId(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(participantByCaseIdAPI, action.caseId);
    if(response.data){
      action.onSuccess(response.data.data);
    }
    yield put({ type: HIDE_LOADING });
    // if (response.data) {
    //   successMessage(response.data.message);
    // }
  } catch (error) {
    
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* actionApproveRejectParticipant() {
  yield takeLatest(APPROVE_REJECT_PARTICIPANT, participantApproveReject);
}
function* actionGetParticipantByCaseId() {
  yield takeLatest(GET_PARTICIPANT_BY_CASE_ID, participantByCaseId);
}
export default function* participantSaga() {
  yield all([fork(actionApproveRejectParticipant)]);
  yield all([fork(actionGetParticipantByCaseId)]);
}
