import React, { useEffect, useState } from "react";
import {
  Button,
  Space,
  Tooltip,Form
} from "antd";
import ViewIcon from "../../assets/images/view.png";
import EditIcon from "../../assets/images/edit.png";
import { Link } from "react-router-dom";
import { onFetchPendigCases } from "../../redux/actions/PendingCasesAction";
import { useDispatch, useSelector } from "react-redux";
import Filters from "../filters/filters";
import CaseListing from "../caseListing";
import ReAllocationModal from "../modals/reAllocationModal";
import { loggedInUserData } from "../../helpers/utils";
import { onFetchCreditUserList } from "../../redux/actions/CreditUserAction";
import moment from "moment";
import { onGetCities,onGetStates } from "../../redux/actions/MasterData";
import { onGetUserByStateCity,onCaseReallocation } from "../../redux/actions/CaseAction";

const PendingCreditReview = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [searchedInput,setSearchedInput] = useState("");
  const [pendingCasesList,setPendingCasesList] = useState([]);
  const [listofusers,setListofusers] = useState([]);
  const [selectedCaseId,setSelectedCaseId] = useState(0);

  const [userData,setUserData] = useState([]);
  const dispatch = useDispatch();
  const pendingCases = useSelector((state) => state.pendingcases.pendingCases);
  const authMaster = useSelector((state) => state.auth.masterData);
  const state = useSelector((state) => state.auth.states);
  const cities = useSelector((state) => state.auth.cities);
  const creditUser = useSelector((state) => state.creditUser.creditUser);
  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 10);
    dispatch(onGetStates());
    dispatch(onFetchPendigCases());
    dispatch(onFetchCreditUserList());
  }, [dispatch]);
  useEffect(() => {
    setUserData(loggedInUserData());
  }, [loggedInUserData]);

  useEffect(() => {
    setPendingCasesList(pendingCases);
  }, [pendingCases]);

  const columns = [
    {
      title: "Case ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      render: (text, row, record) => {
        return <div style={{wordBreak:'break-word'}}>{row.customer_name ? row.customer_name : ""}</div>;
      },
    },
    {
      title: "Applicant Category",
      dataIndex: "category_name",
      key: "category_name",
      // filterMultiple: false,
      // sorter: (a, b) =>
      //   a.category_name
      //     .toLowerCase()
      //     .localeCompare(b.category_name.toLowerCase()),
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Business Area",
      dataIndex: "business_area",
      key: "business_area",
      // filterMultiple: false,
      // sorter: (a, b) =>
      //   a.business_area
      //     .toLowerCase()
      //     .localeCompare(b.business_area.toLowerCase()),
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Finance Type",
      dataIndex: "finance_type",
      key: "finance_type",
    },{
      title: "Product Segment",
      dataIndex: "product_name",
      key: "product_name",
      // sorter: (a, b) => a.ProductName - b.ProductName,
    },
    {
      title: "Product Description",
      dataIndex: "model_no",
      key: "model_no",
    },
    {
      title: "Qty",
      dataIndex: "qty_required",
      render: (text, row, record) => {
        return <div>{row.qty_required.toLocaleString()}</div>;
      },
    },
    {
      title: <p>Amount Required(&#8377;)</p>,
      dataIndex: "finance_amount",
      render: (text, row, record) => {
        return <div>{row.finance_amount.toLocaleString()}</div>;
      },
    },
    {
      title: "Case Status ",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Pending Since Days",
      dataIndex: "credit_review_pending_on",
      key: "credit_review_pending_on",
      // sorter: (a, b) =>
      //   a.credit_review_pending_on.toLowerCase().localeCompare(b.credit_review_pending_on.toLowerCase()),
      // sortDirections: ["descend", "ascend"],
    },
    {
      title: "Allocated to ",
      dataIndex: "sales_allocated_name",
      key: "sales_allocated_name",
      className: userData.user_type == "Credit" ? "show" : "hide",
      // sorter: (a, b) =>
      //   a.sales_allocated_name
      //     .toLowerCase()
      //     .localeCompare(b.sales_allocated_name.toLowerCase()),
      // sortDirections: ["descend", "ascend"],
    }, {
      title: "Created Date",
      dataIndex: "id",
      render: (text, row, record) => {
        return <div>{moment(row.created_at).format("ll")}</div>;
      },
    },

    {
      title: "Action",
      key: "action",
      render: (text, row, record) => (
        <Space size="middle">
         <Tooltip placement="topLeft" title="View" arrowPointAtCenter>
           <Link to={`/viewOnGoingcase/${row.id}`}>
            <img src={ViewIcon} alt="View" />
          </Link>
          </Tooltip> 
          {/*{userData && userData.user_type === 'Sales' && <Link to={`/edit-case/${row.id}`}>
          <img src={EditIcon} alt="Edit" />
      </Link>}*/}
         {/* <Button onClick={() => reAllocate(row.id)}>Reallocate</Button>*/}
        </Space>
      ),
    },
  ];
  const reAllocate = (row) => {
    // 
    setSelectedCaseId(row);
    setIsModalVisible(true);
  };

  const handleOk = (values) => {
    values.case_id=selectedCaseId;
    dispatch(onCaseReallocation(values,onSuccessApi));
  };
 const onSuccessApi=(response)=>{
  form.resetFields();
  setIsModalVisible(false);
  dispatch(onFetchPendigCases());

 }
  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
  };
  const getFiltered = (filterdata) => {
    dispatch(onFetchPendigCases(filterdata));
  };
  const resetfilter = () => {
    dispatch(onFetchPendigCases());
  };
  const filterCaseListing = (e) => {
    setSearchedInput(e.target.value);
    const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
    const filteredCases = pendingCases.filter((userCase) => {
    
      let casesById = userCase.id.toString().includes(searchedInput);

      let casesByApplicantCategory = userCase.category_name
        ? userCase.category_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByBusinessArea = userCase.business_area
        ? userCase.business_area.toLowerCase().includes(searchedInput)
        : "";
      let casesByStatus = userCase.status
        ? userCase.status.toLowerCase().includes(searchedInput)
        : "";
      let casesByAllocatedTo = userCase.allocated_to
        ? userCase.allocated_to.toLowerCase().includes(searchedInput)
        : "";
        let casesByCustomerName = userCase.customer_name
        ? userCase.customer_name.toLowerCase().includes(searchedInput)
        : "";
      let casesByFinanceType = userCase.finance_type
        ? userCase.finance_type.toLowerCase().includes(searchedInput)
        : "";
      let casesByProductName = userCase.product_name
        ? userCase.product_name.toLowerCase().includes(searchedInput)
        : "";let casesByModelNumber = userCase.model_no
        ? userCase.model_no.toLowerCase().includes(searchedInput)
        : "";
      let casesByQuantity = userCase.qty_required
        ? userCase.qty_required
            .toString()
            .includes(searchedInput.replaceAll(",", ""))
        : "";
      let casesByAmountRequired = userCase.finance_amount
        ? userCase.finance_amount
            .toString()
            .includes(searchedInput.replaceAll(",", ""))
        : "";

      return (
        casesById ||
        casesByApplicantCategory ||
        casesByBusinessArea ||
        casesByStatus ||
        casesByCustomerName ||
        casesByFinanceType ||
        casesByProductName ||
        casesByApplicantCategory ||
        casesByBusinessArea ||
        casesByModelNumber ||
        casesByQuantity ||
        casesByAmountRequired||
        casesByAllocatedTo
      );
    });
    setPendingCasesList(filteredCases);
  };
  const getCity = (data) => {
    dispatch(onGetCities(data,onSuccessCity))
  }
  const onSuccessCity = (data) => {  };
  const onSuccessUsers = (data) => { 
    setListofusers(data);
   };
  const getUserbystatecity=()=>{
    dispatch(onGetUserByStateCity(onSuccessUsers))
  }
  return (
    <>
      <Filters
        creditUser={creditUser}
        userData={userData}
        title="Filter the Pending Case List"
        case_type="pending"
        reset={resetfilter}
        authMaster={authMaster}
        getFiltered={getFiltered}
      />
      <div className="CaseListData TableWrapAction">
        <CaseListing
          columns={columns}
          data={pendingCasesList}
          searchedInput={searchedInput}
          filterCaseListing={filterCaseListing}
        />
        <ReAllocationModal
          isModalVisible={isModalVisible}
          handleOk={handleOk}
          handleCancel={handleCancel}
          states={state}
          cities={cities}
          getCity={getCity}
          getUserbystatecity={getUserbystatecity}
          listofusers={listofusers}
        />
      </div>
    </>
  );
};

export default PendingCreditReview;
