import React, { Component } from "react";
class InfoMatching extends Component {
    render() {
      return (
        <>
        <div className='infoWrap'>
            <h4>Information Matching</h4>
            <div className='TableWrap'>
            <table>
                <thead>
                    <tr>
                        <th>Information On Entired In Case</th>
                        <th>PAN</th>
                        <th>Aadhar</th>
                        <th>DL</th>
                        <th>Voter Id</th>
                        <th>Passport</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td>75%</td>
                        <td>85%</td>
                        <td>95%</td>
                        <td>75%</td>
                        <td>65%</td>
                    </tr>
                    <tr>
                        <td>DOB</td>
                        <td>100%</td>
                        <td>0%</td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td></td>
                        <td>85%</td>
                        <td>75%</td>
                        <td></td>
                        <td>75%</td>
                    </tr>
                </tbody>
            </table>
            </div>
           
        </div>
            
        </>
      )
    }
}
export default InfoMatching;