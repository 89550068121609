import {
  RUN_OCR,
  SET_OCR_DOCS,
  SET_RUN_OCR_RESPONSE,
  UPDATE_OCR_DOCS,
  UPLOAD_OCR_DOCUMETS,
  GET_USER_OCR_RESULT2,
  RUN_NEW_OCR,
  SET_CURRENT_OCR_RESULT,
  SET_CURRENT_KYC_RESULT,
  DELETE_OCR_DOCUMENT
} from "../constants/ActionTypes";

/**
 * @method onUploadOcrDocuments
 * @description upload ocr docs
 * @param {*}
 * @returns
 */
export const onUploadOcrDocuments = (data, onSuccess) => ({
  type: UPLOAD_OCR_DOCUMETS,
  payload: data,
  onSuccess,
});
/**
 * @method onUpdateOcrDocs
 * @description update single ocr doc
 * @param {*}
 * @returns
 */
export const onUpdateOcrDocs = (userType, data) => {
  return {
    type: UPDATE_OCR_DOCS,
    data,
    userType,
  };
};
/**
 * @method onRunOcr
 * @description run ocr for a participant
 * @param {*}
 * @returns
 */
export const onRunOcr = (data, onSuccess) => {
  return {
    type: RUN_OCR,
    data,
    onSuccess,
  };
};
/**
 * @method onChangeOcrDocs
 * @description no api call
 * @param {*}
 * @returns
 */
export const onChangeOcrDocs = (data) => {
  return {
    type: SET_OCR_DOCS,
    data,
  };
};
export const onRunOcrResponse = (data) => {
  return {
    type: SET_RUN_OCR_RESPONSE,
    data,
  };
};
export const onUserOcrResult = (data,onSuccess) => {
  return {
    type: GET_USER_OCR_RESULT2,
    data,
    onSuccess,
  };
};
export const onRunNewOCR = (data,onSuccess)=>{
  return {
    type:RUN_NEW_OCR,
    data,
    onSuccess, 
  }
}
export const onSetCurrentOcrResult = (ocrResult)=>{
  return {
    type: SET_CURRENT_OCR_RESULT,
    data:ocrResult,
  }
}
export const onSetCurrentKycResult = (kycResult)=>{
  return {
    type: SET_CURRENT_KYC_RESULT,
    data:kycResult,
  }
}
/**
 * @method onDeleteOcrDocument
 * @description delete a  ocr doc
 * @param {*}
 * @returns
 */
 export const onDeleteOcrDocument = (data, onSuccess) => ({
  type: DELETE_OCR_DOCUMENT,
  data,
  onSuccess,
});