import React, { Component, useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Input,
  Form,
  Upload,
  Button,
  Radio,
  Select,
  Image,
} from "antd";
import uplaodIcon from "../../../assets/images/uploadfile.png";
import closeIcon from "../../../assets/images/closesmall.png";
import { connect } from "react-redux";
import moment from "moment";
import PreviewImageOrPDF from "../../common/modals/PreviewImageOrPDF";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileTypeFromName,
  isCaseNotClosed,
} from "../../../helpers/utils";
import { MESSAGES, REQUIRED_MESSAGES } from "../../../constants/messages";
import { Link } from "react-router-dom";
const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
let main_applicant = [];
let co_applicants = [];
let guarantors = [];
const JustificationForm = (props) => {
  const { caseDetail, justifComments } = props;
  const [justifications, setJustifications] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [attachement, setAttachment] = useState(false);
  const [showPdf, setShowPdf] = useState(false);
  const [value, setValue] = useState("");

  const [applicant, setApplicant] = useState(false);
  const [coapplicant, setCoapplicantt] = useState(false);
  const [guarantor, setGuarantors] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (caseDetail) {
      // main_applicant = [];
      // co_applicants = [];
      // guarantors = [];
      // if (caseDetail && caseDetail.co_applicants.length > 0) {
      //   caseDetail.co_applicants.map((applicant) => {
      //     if (
      //       applicant.stage !== "OCR Pending" &&
      //       applicant.stage !== "KYC Pending"
      //     ) {
      //       co_applicants.push(applicant);
      //     }
      //     // if(applicant.member_director && applicant.member_director.length>0){
      //     //   applicant.member_director.map((value) => {
      //     //     if(applicant.stage!=="OCR Pending" && applicant.stage!=="KYC Pending" ){
      //     //       co_applicants.push(value);

      //     //    }
      //     //   })
      //     // }
      //   });
      // }

      // if (caseDetail.main_applicant) {
      //   if (
      //     caseDetail.main_applicant.stage !== "OCR Pending" &&
      //     caseDetail.main_applicant.stage !== "KYC Pending"
      //   ) {
      //     main_applicant.push(caseDetail.main_applicant);
      //   }
      //   // if(caseDetail.main_applicant.member_director && caseDetail.main_applicant.member_director.length>0){
      //   //   caseDetail.main_applicant.member_director.map((applicant) => {
      //   //     if(applicant.stage!=="OCR Pending" && applicant.stage!=="KYC Pending" ){
      //   //         main_applicant.push(applicant);
      //   //     }
      //   //   })

      //   // }
      // }
      // if (caseDetail&& caseDetail.guarantors && caseDetail.guarantors.length > 0) {
      //   caseDetail.guarantors.map((applicant) => {
      //     if (
      //       applicant.stage !== "OCR Pending" &&
      //       applicant.stage !== "KYC Pending"
      //     ) {
      //       guarantors.push(caseDetail.guarantors);
      //     }
      //     // if(applicant.member_director && applicant.member_director.length>0){
      //     //   applicant.member_director.map((value) => {
      //     //     if(applicant.stage!=="OCR Pending" && applicant.stage!=="KYC Pending" ){
      //     //       guarantors.push(value);

      //     //    }
      //     //   })
      //     // }
      //   });
      // }

      setJustifications(
        caseDetail.justification ? caseDetail.justification : []
      );
    }
  }, [caseDetail]);

  const handlePreview = (file_name, url) => {
    // alert('p')

    setPreviewVisible(false);
    setPreviewImage(false);
    setPreviewPdf(false);
    const fileType = fileTypeFromName(file_name);
    if (fileType === "png" || fileType === "jpg" || fileType === "jpeg") {
      setPreviewImage(url);
    } else if (fileType === "pdf") {
      setPreviewPdf(url);
    }
    setPreviewVisible(true);
  };
  const beforeUpload = (file, list) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file, 10)) {
        setAttachment(list[0]);
        // setDefaultFileList({
        //   ...defaultFileList,
        //   [docName]: list,
        // });
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const handleAddCommentForCustomer = (values) => {
    const formData = new FormData();
    if (attachement) {
      formData.append("attachment", attachement);
    }
    // formData.append("applicant_type", values.applicant_type);
    formData.append("fk_case_id", caseDetail.id);
    // formData.append(
    //   "applicant_coapplicant_guarantor_id",
    //   values.applicant_coapplicant_guarantor_id
    // );

    formData.append("justification_type", 2);

    formData.append("comment", values.comment);
    props.saveJustificationComments(formData);
  };
  const onChange = (e) => {
    setValue(e.target.value);
    props.form.setFieldsValue({
      applicant_coapplicant_guarantor_id: undefined,
    });
    if (e.target.value == "Guarantor") {
      setGuarantors(true);
      setApplicant(false);
      setCoapplicantt(false);
    } else if (e.target.value == "Co Applicant") {
      setGuarantors(false);
      setApplicant(false);
      setCoapplicantt(true);
    } else {
      setGuarantors(false);
      setApplicant(true);
      setCoapplicantt(false);
    }
  };
  const handleCommentImagePreview = (image_link) => {
    setPreviewImage(image_link);
    setPreviewVisible(true);
  };
  const handlePreviewOcrDoc = (file) => {
    if (!file.url && !file.preview) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (file.type === "application/pdf") {
          setPreviewPdf(reader.result);
        } else {
          setPreviewImage(reader.result);
        }
      };
    } else {
      if (file.fileType === "pdf") {
        setPreviewPdf(file.url);
      } else {
        setPreviewImage(file.url || file.preview);
      }
    }
    setPreviewVisible(true);
  };
  return (
    <React.Fragment>
      <Col span={12} md={12} sm={24} xs={24}>
        <Form
          name="communication"
          onFinish={handleAddCommentForCustomer}
          form={props.form}
        >
          <h4>Justification From Customer</h4>
          <Card>
            {props.userType === "Sales" &&
            isCaseNotClosed(caseDetail.status) ? (
              <>
                <Form.Item
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.PLEASE_ADD_COMMENT,
                    },
                    {
                      max: 250,
                      message: MESSAGES.COMMENT_MAX_LENGTH,
                    },
                    {
                      min: 20,
                      message: MESSAGES.COMMENT_MIN_LENGTH,
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Enter your comment here"
                    size={250}
                  />
                </Form.Item>

                <Form.Item label="Upload Relevant Document" name="attachment">
                  <Upload
                    accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                    maxCount={1}
                    beforeUpload={(file, fileList, e) => {
                      // form.setFields([
                      //   {
                      //     name: "pan_card",
                      //     errors: [],
                      //   },
                      // ]);
                      const result = beforeUpload(file, fileList);
                      if (result) {
                        return Upload.LIST_IGNORE;
                      }
                      return false;
                    }}
                    onPreview={handlePreviewOcrDoc}
                  >
                    <Button>
                      {" "}
                      Upload <img src={uplaodIcon} alt="" />
                    </Button>
                  </Upload>
                </Form.Item>
                <div className="ButtonAddon">
                  <Button htmlType="submit">Add Comment</Button>
                </div>
              </>
            ) : (
              ""
            )}
            {/* <hr /> */}
            <div
              className={`CommentCard ${
                props.justifComments &&
                props.justifComments.length &&
                props.justifComments.length > 3
                  ? "CommentCardScroll"
                  : ""
              }`}
            >
              {justifComments.map((justification, index) => {
                return (
                  <React.Fragment key={index.toString()}>
                    {" "}
                    <p className="commentlist">
                      <p style={{ wordWrap: "break-word" }}>
                        {justification.comment}

                        {justification.file_name && (
                          <React.Fragment>
                            <p className="text-right">
                              <span
                                onClick={() => {
                                  handlePreview(
                                    justification.file_name,
                                    `https://efsvfsprod.s3.ap-south-1.amazonaws.com/${justification.file_path}`
                                  );
                                }}
                              >
                                {fileTypeFromName(justification.file_name) ===
                                "pdf" ? (
                                  <Button>View PDF</Button>
                                ) : (
                                  <Image
                                    width={50}
                                    src={`https://efsvfsprod.s3.ap-south-1.amazonaws.com/${justification.file_path}`}
                                    preview={false}
                                    style={{ cursor: "pointer" }}
                                  />
                                )}
                              </span>
                            </p>
                          </React.Fragment>
                        )}
                      </p>
                      <p className="postedBlock">
                        <i>
                          Posted on{" "}
                          {moment(justification.created_at).format(
                            "ddd MMM D YYYY, h:mm A"
                          )}{" "}
                          by {justification.created_by_name}{" "}
                        </i>
                        <br />
                        <b>
                          {" "}
                          {justification.justification_type === 1
                            ? "Asked From customer"
                            : "Justification received from customer"}{" "}
                        </b>
                      </p>
                      <hr />
                    </p>
                  </React.Fragment>
                );
              })}
              {justifComments.length === 0 && (
                <p className="mb-0 mt-2" style={{ "text-align": "center" }}>
                  <b>{MESSAGES.NO_COMMUNICATION_YET}</b>
                </p>
              )}
            </div>
          </Card>
        </Form>
      </Col>
      <PreviewImageOrPDF
        previewImage={previewImage}
        previewPdf={previewPdf}
        previewVisible={previewVisible}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      />
    </React.Fragment>
  );
};

export default JustificationForm;
