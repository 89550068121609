import { DASHBOARD } from "../constants/ActionTypes";

/**
 * @method onDashboardData
 * @description get dashboard data
 * @param {*}
 * @returns
 */
export const onDashboardData = (data)=>({
    type:DASHBOARD,
    data
  });