import React, { Component, useEffect } from "react";
import TopBarNav from '../components/topbar';
import Navigation from '../components/navigation';
// import OnGoingCaseList from '../components/ongoingcaselist'
import OngoingCaseTabs from '../components/ongoincasetabs';
import { useDispatch } from "react-redux";
import { onCaseDetail } from "../redux/actions/CaseAction";
const OnGoingCases = ()=> {
   
    return (
        <>
        <div className='NewCasesWrap'>
           <TopBarNav/>
            <Navigation/>
            <OngoingCaseTabs/>
            {/* <OnGoingCaseList/> */}
        </div>
        
        </>
      )
}
export default OnGoingCases;