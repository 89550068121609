import React, { useCallback, useEffect, useState } from "react";
import { Form, Row, Col, Select, Upload, Button, Input, Card } from "antd";
import uplaodIcon from "../../../assets/images/uploadfile.png";
import NonOcrDocuments from "../../common/NonOcrDocuments";
import { connect } from "react-redux";
import MemberOrDirectorListing from "./MemberOrDirectorListing.js";
import { REQUIRED_MESSAGES } from "../../../constants/messages";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  firstCharacterOfEachWordUpperCase,
} from "../../../helpers/utils";
import { REGEX } from "../../../constants/regex";
import { values } from "lodash";
import PreviewImageOrPDF from "../../common/modals/PreviewImageOrPDF";
import { OCR_DOCUMENT_LABEL } from "../../../constants/constants";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const AddIndividual = (props) => {
  const { members, resetIndividualModal } = props;
  const [form] = Form.useForm();
  const [nonOcrDocuments, setNonOcrDocuments] = useState([]);
  const [fixedNonOcrDocuments, setFixedNonOcrDocuments] = useState([]);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  useEffect(() => {
    if (resetIndividualModal) {
      form.resetFields();
    }
  }, [resetIndividualModal, form]);
  const [fileList, setFileList] = useState({
    pan_card: [],
    aadhar_card: [],
    driving_license: [],
    passport: [],
    voter_id: [],
  });
  const beforeUpload = (file, list, docName) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file)) {
        setFileList({
          ...fileList,
          [docName]: list,
        });
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const beforeUploadNonOcr = (file, docName) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file)) {
        setFixedNonOcrDocuments(
          fixedNonOcrDocuments.concat({
            name: docName,
            doc: file,
          })
        );
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const addNonOcrDocuments = (nonOcrDoc) => {
    const newDoc = form.getFieldValue("DocName");
    form.setFields([
      {
        name: "DocName",
        errors: [],
      },
    ]);
    if (nonOcrDocuments.find((doc) => doc.name === newDoc)) {
      form.setFields([
        {
          name: "DocName",
          errors: ["The document with this name is already added."],
        },
      ]);
      return;
    }
    if (!newDoc) {
      form.setFields([
        {
          name: "DocName",
          errors: ["Please enter doc name"],
        },
      ]);
      return;
    }
    if (!form.getFieldValue("non_ocr_doc_pic")) {
      form.setFields([
        {
          name: "non_ocr_doc_pic",
          errors: ["Please upload document"],
        },
      ]);
      return;
    }
    setNonOcrDocuments(
      nonOcrDocuments.concat({
        name: form.getFieldValue("DocName"),
        doc: form.getFieldValue("non_ocr_doc_pic"),
      })
    );
    form.resetFields(["DocName", "non_ocr_doc_pic"]);
  };
  const GetOcrDocumentField = (label, name, errorMessage) => {
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item
          label={label}
          name={name}
          rules={[
            {
              required: errorMessage ? true : false,
              message: errorMessage,
            },
          ]}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file, fileList, e) => {
              // form.setFields([
              //   {
              //     name: "pan_card",
              //     errors: [],
              //   },
              // ]);
              const result = beforeUpload(file, fileList, name);
              if (result) {
                return Upload.LIST_IGNORE;
              }
              return false;
            }}
            fileList={fileList[name]}
            onPreview={handlePreviewOcrDoc}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const addMember = (values) => {
    let member = {
      ocrDocuments: fileList,
      nonOcrDocuments,
      fixedNonOcrDocuments,
      customer_name: values.customer_name,
      key: members.length,
    };

    props.setMembers(member);
  };
  const handlePreviewOcrDoc = (file) => {
    if (!file.url && !file.preview) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (file.type === "application/pdf") {
          setPreviewPdf(reader.result);
        } else {
          setPreviewImage(reader.result);
        }
      };
    } else {
      if (file.fileType === "pdf") {
        setPreviewPdf(file.url);
      } else {
        setPreviewImage(file.url || file.preview);
      }
    }
    setPreviewVisible(true);
  };
  const GetNonOcrField = (label, name, errorMessage) => {
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item
          label={label}
          name={name}
          rules={[
            {
              required: true,
              message: errorMessage,
            },
          ]}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file) => {
              beforeUploadNonOcr(file, label);
              return false;
            }}
            // beforeUpload={(file) => {
            //   return false;
            // }}
            onPreview={handlePreviewOcrDoc}
          >
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const GetAddNewDocField = () => {
    return (
      <Row gutter={32}>
        <Col span={12} md={12} xs={24} sm={24}>
          <Form.Item name="DocName" label="Document Name">
            {/*<Col span={8} md={8} xs={24} sm={24}>
        <label>Document Name</label>
</Col>*/}
            <Col>
              <Input
                onFocus={() => {
                  form.setFields([
                    {
                      name: "DocName",
                      errors: [],
                    },
                  ]);
                }}
                onChange={(e) => {
                  const input = e.target.value;
                  if (!REGEX.NAME_VALIDATION.test(input)) {
                    form.setFieldsValue({
                      DocName: firstCharacterOfEachWordUpperCase(
                        input.substring(0, input.length - 1)
                      ).replace(/[^a-zA-Z0-9&-/s ]/gm, ""),
                    });
                  } else {
                    form.setFieldsValue({
                      DocName: firstCharacterOfEachWordUpperCase(input).replace(
                        /[^a-zA-Z0-9&-/s ]/gm,
                        ""
                      ),
                    });
                  }
                }}
              />
            </Col>
          </Form.Item>
          <Form.Item
            label="Upload"
            name="non_ocr_doc_pic"
            onFocus={() => {
              form.setFields([
                {
                  name: "non_ocr_doc_pic",
                  errors: [],
                },
              ]);
            }}
          >
            {/*<Col span={8} md={8} xs={24} sm={24}>
          <label>Upload</label>
      </Col>*/}

            <Upload maxCount={1} beforeUpload={beforeUpload}>
              <Button>
                Upload <img src={uplaodIcon} alt="uplaodIcon" />
              </Button>
            </Upload>
          </Form.Item>
          <div className="AddField text-right mt-2 mb-2">
            <Button htmlType="button" onClick={addNonOcrDocuments}>
              Add
            </Button>
          </div>
        </Col>
        <Col span={12} md={12} xs={24} sm={24}>
          {nonOcrDocuments.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Document Name </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {nonOcrDocuments &&
                  nonOcrDocuments.map((document, index) => {
                    return (
                      <tr key={document.name}>
                        <td>{document.name}</td>
                        <td>
                          <Button
                            onClick={() => {
                              // handlePreview(document);
                            }}
                          >
                            View
                          </Button>
                          <Button
                            onClick={() => {
                              if (document.id) {
                                // deleteNonOcrDocumentFromBackEnd(
                                //   document.id
                                // );
                              }
                              // removeNonOcrDocuments(index);
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    );
  };
  return (
    <>
      <Form {...layout} onFinish={addMember} form={form}>
        <Row gutter={32}>
          <Col span={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="Customer's Name"
              name="customer_name"
              rules={[
                {
                  required: true,
                  message: REQUIRED_MESSAGES.CUSTOMER_NAME_REQUIRED,
                },
              ]}
            >
              <Input
                placeholder="Enter Customer Name"
                onChange={(e) => {
                  const input = e.target.value;
                  if (!REGEX.NAME_VALIDATION.test(input)) {
                    form.setFieldsValue({
                      // 'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1))).replace( /[^a-zA-Z0-9 ]/gm, '')
                      customer_name: firstCharacterOfEachWordUpperCase(
                        input.substring(0, input.length - 1)
                      ),
                    });
                  } else {
                    form.setFieldsValue({
                      customer_name: firstCharacterOfEachWordUpperCase(input),
                    });
                  }
                }}
              />
            </Form.Item>
          </Col>

          <Col span={24} md={24} xs={24} sm={24}>
            <h4>OCR Compatible Documents</h4>
          </Col>
          {GetOcrDocumentField(
            OCR_DOCUMENT_LABEL.PAN_CARD,
            "pan_card",
            REQUIRED_MESSAGES.PAN_CARD_REQUIRED
          )}
          {GetOcrDocumentField(OCR_DOCUMENT_LABEL.VOTER_ID, "voter_id")}
          {GetOcrDocumentField(
            OCR_DOCUMENT_LABEL.AADHAR_CARD,
            "aadhar_card",
            REQUIRED_MESSAGES.ADHAR_CARD_REQUIRED
          )}
          {GetOcrDocumentField("Driving License", "driving_license")}
          {GetOcrDocumentField("Passport", "passport")}
        </Row>
        <Row gutter={32}>
          <Col span={24}>
            <h4>Non OCR Compatible Documents</h4>
          </Col>
          {GetNonOcrField(
            "Customer Consent",
            "customer_consent",
            REQUIRED_MESSAGES.CUSTOMER_CONSENT_REQUIRED
          )}
        </Row>
        {GetAddNewDocField()}
        <div className="MainBtnWrap">
          <Button className="plane">Cancel</Button>
          <Button
            className="SaveBtn"
            htmlType="submit"
            type="primary"
            onClick={() => {
              // checkAadharCardValidity();
              // checkPanCardValidity();
            }}
          >
            Save
          </Button>
        </div>
      </Form>
      <PreviewImageOrPDF
        previewVisible={previewVisible}
        previewImage={previewImage}
        previewPdf={previewPdf}
        onCancel={() => {
          setPreviewImage(false);
          setPreviewPdf(false);
          setPreviewVisible(false);
        }}
      />
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
    isCaseSaved: auth.isNewlyCreatedCaseSaved,
    savedCase: auth.newlyCreatedCase,
    mainApplicant: auth.mainApplicant,
    coApplicant: auth.coApplicant,
    gurantor: auth.gurantor,
    caseDetail: auth.caseDetail,
    newlyCreatedCase: auth.newlyCreatedCase,
    toReset: auth.toReset,
    uploadDocumentActiveKey: auth.uploadDocumentActiveKey,
    resetIndividualModal: auth.resetIndividualModal,
  };
};
export default connect(mapStateToProps, null)(AddIndividual);
