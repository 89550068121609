import { Col, Row } from "antd";
import React from "react";
import { CATEGORY_NAME } from "../../constants/constants";
import { getAge } from "../../helpers/utils";

export const CustomerDetailHeader = (props) => {
  const { mainApplicant } = props;
  return (
    <div className="headerWrap">
      <Row gutter="32">
        {/* <Col span={24} md={12} xs={24} sm={24}>
          <Row className="align-items-center"> */}
        <Col span={6} md={6} xs={24} sm={24}>
          {mainApplicant &&
            mainApplicant.category_name &&
            mainApplicant.category_name === CATEGORY_NAME.INDIVIDUAL && (
              <React.Fragment>
                {" "}
                <h3 className="mb-0">Customer’s Full Name</h3>
                <p className="mb-0">
                  {mainApplicant.first_name ? mainApplicant.first_name : ""}{" "}
                  {mainApplicant.middle_name ? mainApplicant.middle_name : ""}{" "}
                  {mainApplicant.last_name ? mainApplicant.last_name : ""}
                </p>
              </React.Fragment>
            )}
          {mainApplicant &&
            mainApplicant.category_name &&
            mainApplicant.category_name !== CATEGORY_NAME.INDIVIDUAL && (
              <React.Fragment>
                {" "}
                <h3 className="mb-0">Organization Name</h3>
                <p className="mb-0">
                  {mainApplicant.organization_name ? mainApplicant.organization_name : ""}{" "}
                </p>
              </React.Fragment>
            )}
        </Col>
        {mainApplicant && 
          mainApplicant.category_name &&
          mainApplicant.category_name === CATEGORY_NAME.INDIVIDUAL && (
            <Col span={6} md={6} xs={24} sm={24}>
              <h3 className="mb-0">Age and Gender</h3>
              <p className="mb-0">
                {mainApplicant.dob ? `${getAge(mainApplicant.dob)} years` : "-"}{" "}
                {mainApplicant.gender ? `, ${mainApplicant.gender}` : "-"}
              </p>
            </Col>
          )}
          {mainApplicant &&
            mainApplicant.category_name &&
            mainApplicant.category_name !== CATEGORY_NAME.INDIVIDUAL && (
              <Col span={6} md={6} xs={24} sm={24}>
                <h3 className="mb-0">Date of Incorporation</h3>
                <p className="mb-0">
                  {mainApplicant.date_of_incorporation ? `${mainApplicant.date_of_incorporation}` : "-"}{" "}
                </p>
              </Col>
            )}
        <Col span={6} md={6} xs={24} sm={24}>
          <h3 className="mb-0">Mobile No.</h3>
          <p className="mb-0">
            {mainApplicant.mobile_no ? mainApplicant.mobile_no : "-"}
          </p>
        </Col>
        <Col span={6} md={6} xs={24} sm={24}>
          <h3 className="mb-0">PAN No.</h3>
          <p className="mb-0">
            {mainApplicant.pan_no ? mainApplicant.pan_no : "-"}
          </p>
        </Col>
        {/* </Row>
        </Col> */}
        {/* <Col span={6} md={6} xs={24} sm={12}>
          <Row className="align-items-center">
            <Col span={12} md={12} xs={24} sm={24}>
              
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
             
            </Col>
          </Row>
        </Col>
        <Col span={6} md={6} xs={24} sm={12}>
          <Row className="align-items-center">
            <Col span={12} md={12} xs={24} sm={24}>
             
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
            
            </Col>
          </Row>
        </Col>
        <Col span={6} md={6} xs={24} sm={12}>
          <Row className="align-items-center">
            <Col span={12} md={12} xs={24} sm={24}>
             
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
             
            </Col>
          </Row>
        </Col> */}
      </Row>
    </div>
  );
};
