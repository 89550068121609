import React, { useEffect } from "react";
import { Form, Select, Button, Col, Input, Radio, Modal } from "antd";
import { MESSAGES, REQUIRED_MESSAGES } from "../../constants/messages";

const RequestCreditReview = (props) => {
  const { Option } = Select;

  useEffect(() => {
    window.scrollTo(0, 10);
  }, []);

  return (
    <>
      <Modal
        title="Credit Review Request"
        visible={props.isModalVisible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        footer={[
          <Button key="back" onClick={props.handleCancel}>
            Cancel
          </Button>,
          <Button form="myForm" key="submit" htmlType="submit">
            Submit
          </Button>,
        ]}
        width={600}
        className="JustReqWrap"
        style={{ top: 100 }}
        destroyOnClose={true}
      >
        <Form
          form={props.form}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          layout="horizontal"
          onFinish={props.handleOk}
          id="myForm"
        >
          <h4>Enter a reason why you think review from credit is required.</h4>
          <Form.Item
            name="comment"
            className="UserNameselector"
            placeholder="Please Review The Case"
            // label="Comment"
            rules={[
              {
                required: true,
                message: "Please add reason",
              },
              {
                max: 250,
                message: MESSAGES.COMMENT_MAX_LENGTH,
              },{
                min:5,
                message:MESSAGES.COMMENT_MIN_LENGTH_5,
                validateTrigger:true
              }
            ]}
          >
            <Input.TextArea rows={4} style={{ resize: "none" }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default RequestCreditReview;
