import { DELETE_GUARANTOR, UPDATE_GUARANTOR } from "../constants/ActionTypes";

/**
 * @method onDeleteGuarantor
 * @description delete a guarantor
 * @param {*}
 * @returns
 */
export const onDeleteGuarantor = (guarantorId, onSuccess) => {
  return {
    type: DELETE_GUARANTOR,
    id: guarantorId,
    onSuccess,
  };
};
/**
 * @method onUpdateGuarantor
 * @description update a guarantor
 * @param {*}
 * @returns
 */
export const onUpdateGuarantor = (data, onSuccess) => {
  return {
    type: UPDATE_GUARANTOR,
    data,
    onSuccess,
  };
};
