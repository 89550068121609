import React, { useEffect } from "react";
import { Col, Row, Button, Table } from "antd";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import { useParams } from "react-router-dom";
import matchResponse from "../components/hunterListingCases/fakeResponseTest/matchResponse.json";
import nomatchResponse from "../components/hunterListingCases/fakeResponseTest/noMatchResponse.json";
import errorResponse from "../components/hunterListingCases/fakeResponseTest/errorResponse.json";
import HunterViewByCase from "../components/hunterListingCases/hunterViewByCase";

export default function HunterView() {
  return (
    <>
      <TopBarNav/>
      <Navigation />
      <HunterViewByCase/>
    </>
  );
}
