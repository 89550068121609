import { OCR_DOCUMENT_LABEL } from "../../../constants/constants";
import { fileNameFromUrl, fileTypeFromName } from "../../../helpers/utils";

export const updateDefaultOcrDocs = (ocr_documents) => {
    let defaultFiles = {};
    ocr_documents.forEach((ocr_document, index) => {
      let uid = (index + 1).toString();
      let name = fileNameFromUrl(ocr_document.document_path);
      let fileType = fileTypeFromName(name);
      let url = ocr_document.document_path;
      let status = "done";
      let doc_array = [
        {
          uid,
          name,
          fileType,
          url,
          status:`uploaded on ${ocr_document.uploaded_on} by ${ocr_document.uploaded_by}`,
        },
      ];
      if (ocr_document.document_name === 'PAN Card') {
        defaultFiles = {
          ...defaultFiles,
          pan_card: doc_array,
          
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
        defaultFiles = {
          ...defaultFiles,
          aadhar_card: doc_array,
        };
      } else if (ocr_document.document_name === 'Driving License') {
        
        defaultFiles = {
          ...defaultFiles,
          driving_license: doc_array,
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.PASSPORT) {
        defaultFiles = {
          ...defaultFiles,
          passport: doc_array,
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.VOTER_ID) {
        defaultFiles = {
          ...defaultFiles,
          voter_id: doc_array,
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.GST_REGISTRATION) {
        defaultFiles = {
          ...defaultFiles,
          gst_registration: doc_array,
        };
      }
    });
    return defaultFiles;
    // setFileList((defaultFileList) => {
    //   return { ...defaultFileList, ...defaultFiles };
    // });
  };
  export const updateDefaultRequiredNonOcrDocs = (non_ocr_documents) => {
    let defaultFiles = {};
    non_ocr_documents.forEach((ocr_document, index) => {
      let uid = (index + 1).toString();
      let name = fileNameFromUrl(ocr_document.document_path);
      let fileType = fileTypeFromName(name);
      let url = ocr_document.document_path;
      let status = "done";
      let doc_array = [
        {
          uid,
          name,
          fileType,
          url,
          status:`uploaded on ${ocr_document.uploaded_on} by ${ocr_document.uploaded_by}`,
          
        },
      ];
      defaultFiles = {
        ...defaultFiles,
        [ocr_document.document_name]: doc_array,
      };
    });
    return defaultFiles;
  };
  export const updatedDefaultNonOcrDocs = (non_ocr_documents) => {
    let nonOcrDocs = [];
    non_ocr_documents.forEach((non_ocr_document) => {
      nonOcrDocs = [
        ...nonOcrDocs,
        {
          name: non_ocr_document.document_name,
          doc: non_ocr_document.document_path,
          id: non_ocr_document.id,
        },
      ];
    });
    return nonOcrDocs
  };
  export const updateDefaultDocs = (ocr_documents,non_ocr_documents,non_ocr_required_documents)=>{
    let uploadedOcrDocs = updateDefaultOcrDocs(ocr_documents);
    let uploadedNonOcrDocuments = updatedDefaultNonOcrDocs(non_ocr_documents);
    let uploadedRequiredNonOcrDocuments = updateDefaultRequiredNonOcrDocs(non_ocr_required_documents);
    return {
      uploadedOcrDocs,
      uploadedNonOcrDocuments,
      uploadedRequiredNonOcrDocuments
    }

  }

  export const updatedApplicantDocuments = (applicant)=>{
    const {ocr_documents,non_ocr_required_documents,non_ocr_documents} = applicant;
    let uploadedOcrDocuments = updateDefaultOcrDocs(ocr_documents);
    let uploadedRequiredNonOcrDocuments = updateDefaultRequiredNonOcrDocs(non_ocr_required_documents);
    let uploadedNonOcrDocuments = updatedDefaultNonOcrDocs(non_ocr_documents);
    return {
      uploadedOcrDocuments,
      uploadedRequiredNonOcrDocuments,
      uploadedNonOcrDocuments
    }
  }