import { Button, Empty, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onFetchBureauReportListing, onGetBureauReportListing } from "../../redux/actions/BureauReportAction";
import RunParametersModal from "../modals/runParametersModal";
import { onBureauReportParameters } from "../../redux/actions/BureauReportAction";
import { Link, useParams } from "react-router-dom";

const { TabPane } = Tabs;
let gurantor_data_array=[]
const BureauReportGuarantorList = (props) => {
  const { userId, guarantorList, caseId, onSetGuarantorData, selectedCoapplicantId } = props;
  const [selectionType] = useState("radio");
  const dispatch = useDispatch();
  const [userParameters, setUserParameters] = useState([]);
  const [selectedBureauReportId, setSelectedBureauReportId] = useState([]);

  const [bureauReportListing, setBureauReportListing] = useState([]);
  const [visible, setVisible] = useState(false);

  const rowSelection = {

    onChange: (selectedRowKeys, selectedRows) => {
      const index = gurantor_data_array.findIndex((x) => x.gurantor_id === selectedRows[0].user_id);
      if(index != -1) {
        gurantor_data_array.splice(index, 1); // from index remove 1 element
      }
      // setSelectedBureauReportId(record),
      props.onSetGuarantorBRId(selectedRowKeys[0]);
     let gurantor_data={
        gurantor_id:selectedRows[0].user_id,
        gurantor_bureau_report_id:selectedRows[0].id
      }
      gurantor_data_array.push(gurantor_data);
      // props.onSetGuarantorBRId(gurantor_data_array);
      // console.log(co_applicant_data_array,'co_applicant_data_array')
      onSetGuarantorData(gurantor_data_array);

    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };
  const CreditBureaucolumns = [
    {
      title: "Bureau Name",
      dataIndex: "bureau_name",
    },
    {
      title: "Score",
      dataIndex: "score",
    },
    {
      title: "Report Generated on ",
      dataIndex: "generated_on",
    },
    {
      title: "Run Parameters ",
      dataIndex: "parameter",
      render: (text, record) => <a onClick={() => { openparamModal(record.id) }}>Click here</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <React.Fragment>
          <Link
            target="_blank"
            to={`/download-report/${record.id}`}
          >
            View Report
          </Link>
        </React.Fragment>
      ),
    },
  ];

  const openparamModal = (id) => {

    setUserParameters([]);
    dispatch(onBureauReportParameters(id, onSuccess));
    setVisible(true)
  }
  const onSuccess = (response) => {
    setUserParameters(response);
  }

  return (
    <div>
      {guarantorList && Array.isArray(guarantorList) && guarantorList.map((co_applicant) => {
        return (
          <>
           <div>Bureau Report list for {co_applicant.first_name
                    ? co_applicant.first_name
                    : "-"}{" "}
                  {co_applicant.middle_name
                    ? co_applicant.middle_name
                    : ""}{" "}
                  {co_applicant.last_name ? co_applicant.last_name : ""}{" "} </div>
            <Table
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              columns={CreditBureaucolumns}
              dataSource={co_applicant.viewReport}
              pagination={false}
            />
            <RunParametersModal
              visible={visible}
              userParameters={userParameters}
              selectedApplicant={selectedCoapplicantId}
              onCancel={() => setVisible(false)}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description="No Bureau Reports Generated"
                  />
                ),
              }}
            /> </>
        )
      })}
    </div>
  );
};
export default BureauReportGuarantorList;
