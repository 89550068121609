import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal, Form, Input } from "antd";
import { useHistory, useParams, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  onAddFieldInvestigationOTP,
  onAddFieldInvestigationOTPVerify,
  onCheckLinkExpire,
} from "../../../../redux/actions/FieldInvestigation";
import FieldInvestigationView from "./FieldInvestigationView";
export default function OtpModel() {
  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const [form] = Form.useForm();
  const [otpBoxVisible, setOtpBoxVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [hideButton, setHideButton] = useState(false);
  const [titleMessage, setTitleMessage] = useState(["Enter OTP"]);
  const [visible, setVisible] = useState(false);

  const openOtpModelCancel = () => {
    setOtpBoxVisible(true);
  };
  const deleteOtpModelCancel = () => {
    setOtpBoxVisible(false);
    window.close();
  };
  const checkLink = (linkId) => {
    dispatch(onCheckLinkExpire(linkId, onSuccess));
  };
  const onSuccess = (responseData) => {
    setMessage(responseData.data.data.message);
    setHideButton(responseData.data.data.status);
    if (responseData.data.data.status == false) {
      history.push("/link/expired",{ message: message, color: "red" });
    }
    else{
      setOtpBoxVisible(true);
      sendotp();
    }
  };
  const handleOnFinish = (val) => {
    let submitotp = {
      agency_id: params.agencyId,
      case_id: params.caseId,
      agency_email: params.email,
      participant_id: params.participantId,
      otp: val.otp,
      attempt_id: params.attemptId,
    };
    dispatch(onAddFieldInvestigationOTPVerify(submitotp, onFIOTPSuccess));
  };

  const sendotp = () => {
    let formdata = {
      agency_id: params.agencyId,
      case_id: params.caseId,
      participant_id: params.participantId,
      agency_email: params.email,
      attempt_id: params.attemptId,
    };
    dispatch(onAddFieldInvestigationOTP(formdata, onFISuccess));
  };
  const onFIOTPSuccess = (data) => {
    if (data.data.data.message == "Success") {
      toast.success("OTP matched successfully");
      setOtpBoxVisible(false);
      setVisible(true);
      //history.push(`/field-investigation-view/${params.fiId}/${params.caseId}/${params.participantId}`);
    } else {
      toast.error("Invalid OTP");
    }
  };
  const onFISuccess = (data) => {};
  useEffect(() => {
    checkLink(params.linkId);
  }, []);
  return (
    <div>
      <Modal
        title={titleMessage}
        centered
        open={otpBoxVisible}
        onCancel={deleteOtpModelCancel}
        className="ModalFormWrap AddCoapplicant"
        destroyOnClose={true}
        footer={null}
      >
        {hideButton ? (
          <div>
            <p className="font-weight-bold" style={{ textAlign: "center" }}>
              {`*OTP is sent on your registered email ${params.email}.`}
            </p>
            <Form
              form={form}
              layout="vertical"
              name="basic"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFinish={handleOnFinish}
            >
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: "Please Enter Valid OTP",
                  },
                  {
                    //pattern: /^[0-9]{4}$/,
                    max: 4,
                    message: "Please Enter Only 4 Digits",
                  },
                ]}
              >
                <Input type="number" maxLength={4} />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#202A44",
                  color: "#fff",
                  marginLeft: "100px",
                  width: "130px",
                }}
              >
                Confirm
              </Button>
              <Button
                key="Cancel"
                onClick={deleteOtpModelCancel}
                style={{ marginLeft: "10px", width: "130px" }}
              >
                Cancel
              </Button>
            </Form>
          </div>
        ) : (
          <Redirect
            to={{
              pathname: `/link/expired`,
              state: { message: message, color: "red" },
            }}
          ></Redirect>
        )}
      </Modal>

      {visible ? <FieldInvestigationView viewdata={params} /> : <> </>}
    </div>
  );
}
