import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router";
import { connect, useDispatch, useSelector } from "react-redux";
import Login from "../pages/login";
import ForgetPassword from "../pages/forgetPassword";
// import ResetPassword from "../pages/ResetPassword";
import Home from "../pages/home";
import NewCases from "../pages/newcases";
// import ViewCase from "../pages/casedetail";
import OnGoingCases from "../pages/ongoingcaeses";
import LogNewCases from "../pages/lognewcases";
import GeneratedReport from "../pages/genratedbureaureport";
import PendingCredit from "../pages/pendingcredit";
import ViewOngoingCases from "../pages/viewongoingcases";
import ResetPassword from "../pages/ResetPassword";
import {
  isEicherUser,
  isLoggedIn,
  isSalesUser,
  loggedInUserData,
} from "../helpers/utils";
import Viewcases from "../pages/viewcase";
import ViewOngoingCaseTabs from "../pages/ongoincasesview";
import { useHistory } from "react-router-dom";
// import { onResetNewCaseCreated } from "../redux/actions/AuthAction";
import Addnewcase from "../pages/addnewcase";
import {
  onResetNewCaseCreated,
  onSetResetNewCaseCreated,
} from "../redux/actions/CaseAction";
import AddCase from "../components/cases/AddCase";
import EditCase from "../components/cases/EditCase";
import ongoingcasedetailview from "../pages/ongoingcasedetailview";
import DownloadBureauReport from "../pages/downloadBureauReport";
import NotificationList from "../pages/notificationList";
import ClosedCases from "../pages/ClosedCasesList";
import SSOLogin from "../pages/SSOLogin";
import ToBeMoveToLOS from "../pages/ToBeMovedToLOS";
import FleetAnalysis from "../pages/FleetAnalysis";
import FleetAnalysisViewList from "../pages/FleetAnalysisViewList";
import HunterApi from "../pages/HunterApi";
import HunterView from "../pages/HunterView";
import FieldInvestigation from "../pages/fieldInvestigation";
import FieldInvestigationActionView from "../components/fieldInvestigation/FieldInvestigationActionView"
import OtpModel from "../components/fieldInvestigation/field-agency-view/AgencyView/otpModel";
import FieldInvestigationView from "../components/fieldInvestigation/field-agency-view/AgencyView/FieldInvestigationView";
import FieldInvestigationAllCasesView from "../components/fieldInvestigation/field-agency-view/AgencyAllRequest/FieldInvestigationAllCasesView";
import FieldInvestigationRequestView from "../components/fieldInvestigation/FieldInvestigationRequestView";
import ExpirePage from "../components/fieldInvestigation/field-agency-view/ExpireLink/ExpirePage";
import Vaahan from "../pages/vaahan";
import VaahanView from "../pages/vaahanRequestView";

const AppRouter = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  // const [userData,setUserData] = useState({});
  const userData = loggedInUserData();
  const isuserLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const userPermissions = useSelector((state) => state.auth.userPermissions);
  // const userData = useSelector((state) => state. auth.userData.user_detail);

  // useEffect(() => {
  //   if(userInfo){
  //     setUserData(userInfo)

  //   }
  // }, [userInfo]);

  const newlyCreatedCase = useSelector((state) => {
    if (state && state.auth) {
      return state.auth.newlyCreatedCase;
    }
    return {};
  });
  history.listen((location, action) => {
    if (location.pathname !== "/NewCases" && newlyCreatedCase) {
      dispatch(onResetNewCaseCreated());
    } else if (location.pathname.startsWith("/edit-case/")) {
      dispatch(onSetResetNewCaseCreated(true));
    }
  });

  let routes;
  if (isLoggedIn()) {
    routes = (
      <Switch>
        {userData && userData.first_time_login === 1 && (
          <React.Fragment>
            <Route exact to="/reset-password" component={ResetPassword} />
            <Redirect to="/reset-password" />
          </React.Fragment>
        )}
        {/*<Route path="/" exact component={Home} />*/}
        <Route exact path="/dashboard" component={Home} />
        {userData && isEicherUser(userData) && (
          <Route exact path="/new-cases" component={NewCases} />
        )}
        <Route exact path="/viewcase/:id" component={Viewcases} />
        {/*<Route path="/view-cases" component={Viewcases} />*/}
        <Route exact path="/OnGoingCases" component={OnGoingCases} />
        {/*<Route exact path="/NewCases" component={Addnewcase} />*/}
        {/*{userData && userData.user_type==="Sales" && userData.group_name ==="Volvo" ? 
         <Route exact path="/NewCases" component={LogNewCases} />
         :''}*/}
        {/*{userData &&BureauReport
          userData.user_type === "Sales" &&
          userData.group_name === "Volvo" && (
            <Route exact path="/NewCases" component={AddCase} />
          )}*/}
        {/*{isSalesUser(userData) && <Route exact path="/edit-case/:id" component={EditCase} />}*/}
        <Route exact path="/NewCases" component={AddCase} />
        <Route exact path="/edit-case/:id" component={EditCase} />
        <Route exact path="/BureauReport/:id" component={GeneratedReport} />
        <Route exact path="/PendingCreditReview" component={PendingCredit} />
        <Route exact path="/ViewOnGoingCases" component={ViewOngoingCases} />
        {/*<Route path="/viewOnGoingcase/:id" component={ViewOngoingCaseTabs} />*/}
        <Route path="/viewOnGoingcase/:id" component={ongoingcasedetailview} />
        <Route path="/viewOnGoingcase" component={ViewOngoingCaseTabs} />
        <Route path="/download-report/:id" component={DownloadBureauReport} />
        <Route path="/notifications" component={NotificationList} />
        <Route path="/ClosedCases" component={ClosedCases} />
        <Route path="/FleetAnalysis" component={FleetAnalysis} />
        <Route path="/Fleet-Analysis/:id" component={FleetAnalysisViewList} />
        <Route path="/to-be-moved-to-los" component={ToBeMoveToLOS} />

        {/* Hunter Request */}
        <Route path="/hunter" component={HunterApi} />
        <Route path="/hunter-response-view/:id" component={HunterView} />
        
        {/* Vaahan */}
        <Route path="/vaahan" component={Vaahan} />
        <Route path="/vaahan-request-view/:id" component={VaahanView} />

        {/* Field Investigation */}
        <Route path="/field-investigation" component={FieldInvestigation} />
        <Route path="/field-investigation-case-details/:caseId" component={FieldInvestigationActionView} />
        <Route path="/field-investigation-request-details/:caseId" component={FieldInvestigationRequestView} />
        <Route exact path="/fi-user-confirmation/:email/:agencyId/:caseId/:participantId/:attemptId/:fiId/:linkId" component={OtpModel} />
        <Route exact path="/field-investigation-view/:agencyId/:caseId/:participantId/:attemptId/:fiId/:linkId" component={FieldInvestigationView} />
        <Route exact path="/field-investigation-request-case-view/all/:agencyId/:caseId/:participantId/:attemptId/:fiId/:linkId" component={FieldInvestigationAllCasesView} />
        <Route exact path="/link/expired" component={ExpirePage} />
        <Redirect to="/dashboard" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/" component={Login} />
        <Route path="/ssologin" component={SSOLogin} />
        <Route exact path="/forget-password" component={ForgetPassword} />
        <Route exact path="/fi-user-confirmation/:email/:agencyId/:caseId/:participantId/:attemptId/:fiId/:linkId" component={OtpModel} />
        <Route exact path="/field-investigation-view/:agencyId/:caseId/:participantId/:attemptId/:fiId/:linkId" component={FieldInvestigationView} />
        <Route exact path="/field-investigation-request-case-view/all/:agencyId/:caseId/:participantId/:attemptId/:fiId/:linkId" component={FieldInvestigationAllCasesView} />
        <Route exact path="/link/expired" component={ExpirePage} />
        <Redirect to="/" />
      </Switch>
    );
  }
  return routes;
  //routes
};
const mapStateToProps = (store) => {
  const { auth } = store;

  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData ? auth.userData.user_detail : {},
    role: auth.role,
    isNewlyCreatedCaseSaved: auth.isNewlyCreatedCaseSaved,
  };
};
export default connect(mapStateToProps, null)(AppRouter);
