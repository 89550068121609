import React, { Component } from "react";
import { Card, Row, Col } from 'antd';
class PanCard extends Component {
    render() {
      return (
        <>
            <div className='CardBlock'>
                <h3>PAN Card</h3>
                <Card>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>PAN Number</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>AHMPC45o6C</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Entity Name Linked to the PAN</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Tanvish Kalra</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>PAN Status</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Active</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Duplicate PAN</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>No</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Name Matching With Given Name</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Yes</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>DOB Matching</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Yes</p>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
      )
    }
}
export default PanCard;