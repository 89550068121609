import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Input,
  Modal,
  Upload,
  message,
} from "antd";
import { toast } from "react-toastify";
import { DownloadOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
const { Option } = Select;
const { TextArea } = Input;
const Panel = Collapse.Panel;
const customPanelStyle = {
  background: 'none',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};
export default function GuarantorDetails(viewlabel, label) {
  const caseData = useSelector((state) => state.fieldInvestigation.fiCase);
  const [openImage, setopenImage] = useState(false);
  const [imgurl, setimageurl] = useState("");
  const [form] = Form.useForm();


  const handleDownload = (imgurl) => { 
    const link = document.createElement('a');
    link.href = imgurl;
    link.target = '_blank';
    link.download = imgurl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleCancel = () => {
    setopenImage(false);
  };


  return (
    <>
      {caseData?.guarantors === "undefined" ? (
        <>
          <div className="card card-body">
            <h3 style={{ textAlign: "center" }}>No Guarantors</h3>
          </div>
        </>
      ) : (
        caseData?.guarantors.length === 0 ? <>
          <div className="card card-body">
            <h3 style={{ textAlign: "center" }}>No Guarantors</h3>
          </div>
        </> :
          <div>
            {label != '' ?
              <div className="card card-body">
                <div>
                <h4 style={{marginBottom:'0'}}>{viewlabel?.viewlabel[2]} - {caseData.id}</h4>
                </div>
                <hr  />
                {caseData?.guarantors?.map((val, index) => {
                  return (
                    <div key={index}>
                      <Collapse className="vfs-collapse">
                        <Panel
                          header={`Guarantor - ${index + 1}`}
                          key="1"
                          style={customPanelStyle}
                        >
                          <Row gutter={24}>
                            <Form
                              form={form}
                              layout="inline"
                              autoComplete="off"
                              name="basic"
                              initialValues={{
                                remember: true,
                              }}
                              style={{ width: "100%" }}
                            >
                              <Col span={8} md={8} xs={24} sm={24} style={{ paddingBottom: "18px" }}>
                                <Row>
                                  {val?.category_name === "Individual" ? (
                                    <>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          First Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {val?.first_name
                                            ? val?.first_name
                                            : "-"}
                                        </p>
                                      </Col>

                                      <Col span={8} md={8} xs={24} sm={24}>
                                        <Row>
                                          <Col span={12} xs={24} sm={12}>
                                            <p className="font-weight-bold">
                                              Last Name
                                            </p>
                                          </Col>
                                          <Col span={12} xs={24} sm={12}>
                                            <p>
                                              {val?.last_name
                                                ? val?.last_name
                                                : "-"}
                                            </p>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </>
                                  ) : (
                                    <>
                                      <Col span={12} xs={24} sm={12}>
                                        <p
                                          style={{ marginBottom: "0" }}
                                          className="font-weight-bold"
                                        >
                                          Organization Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p style={{ marginBottom: "0" }}>
                                          {val?.organization_name
                                            ? val?.organization_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </>
                                  )}
                                </Row>
                              </Col>
                            </Form>
                          </Row>
                          <Collapse className="vfs-collapse">
                            <Panel
                              header="First FI details"
                              key="2"
                              style={customPanelStyle}
                            >
                              {val?.fi_details?.first_fi?.map((data, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      style={{
                                        borderBottom:
                                          "1px solid rgba(0,0,0,.1)",
                                        marginBottom: "15px",
                                      }}
                                    >
                                      <Row gutter={24}>
                                        <Col span={8} md={8} xs={24} sm={24}>
                                          <Row>
                                            <Col span={12} xs={24} sm={12}>
                                              <p className="font-weight-bold">
                                                Agency Name
                                              </p>
                                            </Col>
                                            <Col span={12} xs={24} sm={12}>
                                              <p>
                                                {data?.agency_name
                                                  ? data?.agency_name
                                                  : "-"}
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col span={8} md={8} xs={24} sm={24}>
                                          <Row>
                                            <Col span={12} xs={24} sm={12}>
                                              <p className="font-weight-bold">
                                                City Name
                                              </p>
                                            </Col>
                                            <Col span={12} xs={24} sm={12}>
                                              <p>
                                                {data?.city_name
                                                  ? data?.city_name
                                                  : "-"}
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col span={8} md={8} xs={24} sm={24}>
                                          <Row>
                                            <Col span={12} xs={24} sm={12}>
                                              <p className="font-weight-bold">
                                                State Name
                                              </p>
                                            </Col>
                                            <Col span={12} xs={24} sm={12}>
                                              <p>
                                                {data?.state_name
                                                  ? data?.state_name
                                                  : "-"}
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col span={8} md={8} xs={24} sm={24}>
                                          <Row>
                                            <Col span={12} xs={24} sm={12}>
                                              <p className="font-weight-bold">
                                                Region Name
                                              </p>
                                            </Col>
                                            <Col span={12} xs={24} sm={12}>
                                              <p>
                                                {data?.region_name
                                                  ? data?.region_name
                                                  : "-"}
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col span={8} md={8} xs={24} sm={24}>
                                          <Row>
                                            <Col span={12} xs={24} sm={12}>
                                              <p className="font-weight-bold">
                                                Feedback Type
                                              </p>
                                            </Col>
                                            <Col span={12} xs={24} sm={12}>
                                              <p>
                                                {data?.feedback_type
                                                  ? data?.feedback_type
                                                  : "-"}
                                              </p>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                      <Row
                                        gutter={24}
                                        style={{ marginBottom: "0px" }}
                                      >
                                        <Col span={12} xs={24} sm={4}>
                                          <p
                                            className="font-weight-bold"
                                            style={{ marginBottom: "0px" }}
                                          >
                                            Remark
                                          </p>
                                        </Col>
                                        <Col
                                          span={12}
                                          xs={24}
                                          sm={20}
                                          style={{
                                            paddingLeft: "0",
                                            marginLeft: "-2px",
                                          }}
                                        >
                                          <p>
                                            {data?.response_remark
                                              ? data?.response_remark
                                              : "-"}
                                          </p>
                                        </Col>
                                      </Row>

                                      <Row
                                        gutter={24}
                                        style={{ marginBottom: "0px" }}
                                      >
                                        <Col span={12} xs={24} sm={4}>
                                          <p className="font-weight-bold">
                                            Fi Attachments
                                          </p>
                                        </Col>
                                        <Col
                                          span={12}
                                          xs={24}
                                          sm={20}
                                          style={{
                                            paddingLeft: "0",
                                            marginLeft: "0px",
                                          }}
                                        >
                                          <p>
                                            {data?.fi_attachments?.map(
                                              (file, index) => {
                                                return (
                                                  <>
                                                    <div key={index}>
                                                      <Row
                                                        span={12}
                                                        xs={24}
                                                        sm={12}
                                                      >
                                                        <p
                                                          id={file[index]}
                                                          onClick={() =>
                                                            handleDownload(file)
                                                          }
                                                          style={{
                                                            color: "blue",
                                                            fontWeight: "bold",  
                                                            fontSize: "14px",  
                                                          }}
                                                        >
                                                          <a title = 'click to open or download the file'> Document-{index+1}</a>
                                                        </p>
                                                      </Row>
                                                    </div>
                                                  </>
                                                );
                                              }
                                            )}
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </>
                                );
                              })}
                            </Panel>
                            <Panel
                              header="Second FI details"
                              key="1"
                              style={customPanelStyle}
                            >
                              {val?.fi_details?.second_fi?.map(
                                (data, index) => {
                                  return (
                                    <>
                                      <div
                                        key={index}
                                        style={{
                                          borderBottom:
                                            "1px solid rgba(0,0,0,.1)",
                                          marginBottom: "15px",
                                        }}
                                      >
                                        <Row gutter={24}>
                                          <Col span={8} md={8} xs={24} sm={24}>
                                            <Row>
                                              <Col span={12} xs={24} sm={12}>
                                                <p className="font-weight-bold">
                                                  Agency Name
                                                </p>
                                              </Col>
                                              <Col span={12} xs={24} sm={12}>
                                                <p>
                                                  {data?.agency_name
                                                    ? data?.agency_name
                                                    : "-"}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col span={8} md={8} xs={24} sm={24}>
                                            <Row>
                                              <Col span={12} xs={24} sm={12}>
                                                <p className="font-weight-bold">
                                                  City Name
                                                </p>
                                              </Col>
                                              <Col span={12} xs={24} sm={12}>
                                                <p>
                                                  {data?.city_name
                                                    ? data?.city_name
                                                    : "-"}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col span={8} md={8} xs={24} sm={24}>
                                            <Row>
                                              <Col span={12} xs={24} sm={12}>
                                                <p className="font-weight-bold">
                                                  State Name
                                                </p>
                                              </Col>
                                              <Col span={12} xs={24} sm={12}>
                                                <p>
                                                  {data?.state_name
                                                    ? data?.state_name
                                                    : "-"}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col span={8} md={8} xs={24} sm={24}>
                                            <Row>
                                              <Col span={12} xs={24} sm={12}>
                                                <p className="font-weight-bold">
                                                  Region Name
                                                </p>
                                              </Col>
                                              <Col span={12} xs={24} sm={12}>
                                                <p>
                                                  {data?.region_name
                                                    ? data?.region_name
                                                    : "-"}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                          <Col span={8} md={8} xs={24} sm={24}>
                                            <Row>
                                              <Col span={12} xs={24} sm={12}>
                                                <p className="font-weight-bold">
                                                  Feedback Type
                                                </p>
                                              </Col>
                                              <Col span={12} xs={24} sm={12}>
                                                <p>
                                                  {data?.feedback_type
                                                    ? data?.feedback_type
                                                    : "-"}
                                                </p>
                                              </Col>
                                            </Row>
                                          </Col>
                                        </Row>
                                        <Row
                                          gutter={24}
                                          style={{ marginBottom: "0px" }}
                                        >
                                          <Col span={12} xs={24} sm={4}>
                                            <p
                                              className="font-weight-bold"
                                              style={{ marginBottom: "0px" }}
                                            >
                                              Remark
                                            </p>
                                          </Col>
                                          <Col
                                            span={12}
                                            xs={24}
                                            sm={20}
                                            style={{
                                              paddingLeft: "0",
                                              marginLeft: "-2px",
                                            }}
                                          >
                                            <p>
                                              {data?.response_remark
                                                ? data?.response_remark
                                                : "-"}
                                            </p>
                                          </Col>
                                        </Row>

                                        <Row
                                          gutter={24}
                                          style={{ marginBottom: "0px" }}
                                        >
                                          <Col span={12} xs={24} sm={4}>
                                            <p className="font-weight-bold">
                                              Fi Attachments
                                            </p>
                                          </Col>
                                          <Col
                                            span={12}
                                            xs={24}
                                            sm={20}
                                            style={{
                                              paddingLeft: "0",
                                              marginLeft: "0px",
                                            }}
                                          >
                                            <p>
                                              {data?.fi_attachments?.map(
                                                (file, index) => {
                                                  return (
                                                    <>
                                                      <div key={index}>
                                                        <Row
                                                          span={12}
                                                          xs={24}
                                                          sm={12}
                                                        >
                                                          <p
                                                            id={file[index]}
                                                            onClick={() =>
                                                              handleDownload(file)
                                                            }
                                                            style={{
                                                              color: "blue",
                                                              fontWeight: "bold",  
                                                              fontSize: "14px",  
                                                            }}
                                                          >
                                                            <a title = 'click to open or download the file'> Document-{index+1}</a>
                                                          </p>
                                                        </Row>
                                                      </div>
                                                    </>
                                                  );
                                                }
                                              )}
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </>
                                  );
                                }
                              )}
                            </Panel>
                          </Collapse>
                        </Panel>
                      </Collapse>

                      
                    </div>
                  );
                })}
                <Modal
                  open={openImage}
                  onCancel={handleCancel}
                  centered
                  footer={null}
                  width={700}
                  height={700}
                //bodyStyle={{ height: 700 }}
                >

                  {imgurl.endsWith('.pdf') ? (
                    <iframe title="Preview" src={imgurl} style={{ width: '100%', height: '60vh', marginTop: "5%" }} />
                  ) : (
                    <img alt="Preview" style={{ width: '100%', height: '60vh' }} src={imgurl} />
                  )}

                  {/* <a href={imgurl} target="_blank" rel="noreferrer" onClick={handleDownload}>
              <DownloadOutlined />
            </a> */}

                  {/* <object data={imgurl} width="650px" height="650px"></object> */}
                </Modal>
              </div>
              :
              <> </>
            }
          </div>
      )}
    </>
  );
}
