import React, { Component } from "react";
import { Form, Input, Button } from "antd";
// import { onResetPassword } from "../redux/actions/AuthAction";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { onResetPassword } from "../redux/actions/AuthAction";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onFinish = (values) => {
    const data = {
      password:values.password,
      password_confirmation:values.confirm,
    }
    dispatch(onResetPassword(data,onSuccess))
  };
  const onSuccess = (responseData) => {
    history.push('/dashboard');
    
  };
  const onFinishFailed = (errorInfo) => {

  };
  return (
    <React.Fragment>
      <div className="AuthWrap">
        <div className="InnerWrap">
          <div className="HeadWrap">
            <h3>Reset Password</h3>
          </div>
          <Form
            layout="vertical"
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={["password"]}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="ChangePassword"
              >
                Change password
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ResetPassword;
