import {
  ADD_PARTICIPANT,
  APPROVE_REJECT_PARTICIPANT,
  GET_PARTICIPANT_DETAIL,
  RESET_PARTICIPANT,
  GET_PARTICIPANT_BY_CASE_ID
} from "../constants/ActionTypes";

/**
 * @method onResetParticipant
 * @description no api call
 * @param {*}
 * @returns
 */
export const onResetParticipant = (toReset) => {
  return {
    type: RESET_PARTICIPANT,
    toReset,
  };
};
/**
 * @method onParticipantDetail
 * @description get participant details
 * @param {*}
 * @returns
 */
export const onParticipantDetail = (data, onSuccess) => ({
  type: GET_PARTICIPANT_DETAIL,
  data,
  onSuccess,
});
/**
 * @method onAddParticipant
 * @description add participant(main applicant,co-applicant or guarantor)
 * @param {*}
 * @returns
 */
export const onAddParticipant = (data, onSuccess) => {
  return {
    type: ADD_PARTICIPANT,
    data,
    onSuccess,
  };
};

export const onApproveRejectParticipant = (data,onSuccess) => {
  return {
    type: APPROVE_REJECT_PARTICIPANT,
    data,
    onSuccess,
  };
};
export const onParticipantByCaseId = (caseId,onSuccess) => {
  return {
    type: GET_PARTICIPANT_BY_CASE_ID,
    caseId,
    onSuccess,
  };
};