import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import { getPendingcaseAPI,} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    HIDE_LOADING,
    SHOW_LOADING,
    CREDIT_PENDING_CASES,
    CREDIT_PENDING_CASES_SUCCESS,
    CREDIT_PENDING_CASES_FAILED
} from "../constants/ActionTypes";
import { errorMessage, successMessage } from "../../helpers/utils";

function* fetchPendingCases(action) {

    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getPendingcaseAPI,action.payload);
        yield put({type: HIDE_LOADING});
        yield put({type: CREDIT_PENDING_CASES_SUCCESS, pendingCases: response.data.data});
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: CREDIT_PENDING_CASES_FAILED});
    }
}

function* actionPendingCases() {
    yield takeLatest(CREDIT_PENDING_CASES, fetchPendingCases)
}

export default function* authSaga() {
    yield all([
        fork(actionPendingCases),
    ]);
}
