import { Button, Checkbox } from "antd";
import Table from "rc-table/lib/Table";
import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import BureauReportGuarantorList from "./BureauReportGuarantorList";
import PlusIcon from "../../assets/images/plus.png";
import { APPLICANT_TYPE } from "../../constants/constants";
import { onGetBureauReportListing } from "../../redux/actions/BureauReportAction";
import {
  getClassNameByStatus
} from "../../helpers/utils";
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {},
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};
let guarantorList=[];

export const GuarantorListing = (props) => {
  const { guarantors } = props;
  const [selectedCoapplicant, setSelectedCoApplicant] = useState(false);
  const [selectedCoapplicantId, setSelectedCoApplicantId] = useState(false);
  const [selectedGuarantorData, setSelectedGuarantorData] = useState([]);

  const [selectionType] = useState("radio");
  const dispatch = useDispatch();
  const [viewReport, setViewReport] = useState(null);

  useEffect(() => {
    guarantorList=[];
  }, []);

  const onSetGuarantorData = (data) => {
    console.log(data,'lkk')
    setSelectedGuarantorData(data);
    props.onSetGuarantorBRId(data);
  };

  const viewBureauReport = (co_applicant,e) => {
    setSelectedCoApplicantId(co_applicant);
    props.setGuarantorId(co_applicant.id);
    let data = {
      user_id: co_applicant.id,
      member_type: APPLICANT_TYPE.GUARANTOR,
      case_id: co_applicant.fk_case_id,
    };
    if(e.checked){
      setSelectedCoApplicant(e.value);
      props.setGuarantorId(e.value);
      dispatch(
        onGetBureauReportListing(data, (bureauList) => {
          // 
          co_applicant.viewReport=bureauList;
          guarantorList.push(co_applicant);
          setViewReport(bureauList);
        })
      );
    }else{
      const index = guarantorList.findIndex((x) => x.id === e.value);
      if(index != -1) {
        guarantorList.splice(index, 1); // from index remove 1 element
      }
      const index2 = selectedGuarantorData.findIndex((x) => x.gurantor_id === e.value);
      let apiData=selectedGuarantorData;
      if(index2 != -1) {
        apiData.splice(index, 1); // from index remove 1 element
      }
     props.onSetGuarantorBRId(apiData);

      setSelectedCoApplicant('');
      // props.setGuarantorId('');
      setViewReport(null);
      // props.onSetGuarantorBRId('')
    }
  };

  return (
    <div className="InnerWrap">
     
      <table>
        <thead>
          <tr>
            <th>Applicant Category</th>
            <th>Guarantor Name</th>
            {/* <th>Relation with Applicant</th> */}
            <th>Status</th>
            {/*<th>Action</th>*/}
          </tr>
        </thead>
        <tbody>
          {guarantors &&
            Array.isArray(guarantors) &&
            guarantors.map((guarantor) => {
              return (
                <tr key={guarantor.id}>
                  <td>
                  <Checkbox  name="radiogroup"
                      name="radiogroup"
                      value={guarantor.id}
                      disabled={guarantor.status==='Dropped'?true:false}
                      onChange={(e) => {
                        viewBureauReport(guarantor,e.target);
                      }}
                      >
                     <p>{guarantor.category_name}</p>

                  </Checkbox>
                    {/* <Radio.Group
                      name="radiogroup"
                      value={selectedCoapplicant}
                      disabled={guarantor.status==='Dropped'?true:false}
                      onChange={(e) => {
                        
                        setSelectedCoApplicant(e.target.value);
                        viewBureauReport(guarantor);
                        props.setGuarantorId(e.target.value);
                      }}
                    >
                      <Radio value={guarantor.id}>
                        <p>{guarantor.category_name}</p>
                      </Radio>
                    </Radio.Group> */}
                  </td>
                  <td>{guarantor.first_name
                    ? guarantor.first_name
                    : "-"}{" "}
                  {guarantor.middle_name
                    ? guarantor.middle_name
                    : ""}{" "}
                  {guarantor.last_name ? guarantor.last_name : ""}{" "}</td>
                  {/* <td>{guarantor.guarantor_applicant_relation}</td> */}
                  <td className={getClassNameByStatus(guarantor.status)}>{guarantor.status}</td>
                  {/*<td>
                    <Button
                      onClick={() => {
                        viewBureauReport(co_applicant);
                      }}
                    >
                      view report
                    </Button>
                    </td>*/}
                </tr>
              );
            })}
        </tbody>
      </table>
      {guarantorList && Array.isArray(guarantorList) && (
        <BureauReportGuarantorList
          userId={selectedCoapplicant}
          member_type={APPLICANT_TYPE.CO_APPLICANT}
          case_id={selectedCoapplicant.fk_case_id}
          bureauReportData={viewReport}
          guarantorList={guarantorList}
          selectedCoapplicantId={selectedCoapplicantId}
          onSetGuarantorBRId={props.onSetGuarantorBRId}
          onSetGuarantorData={onSetGuarantorData}
        />
      )}
    </div>
  );
};
