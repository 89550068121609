import React from "react";
import { connect } from "react-redux";
import CaseListing from "../common/CaseListing";
const OnGoingCaseList = (props) => {
  return (
    <CaseListing listing='ongoingcases'/>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    onGoingCases: auth.userCases ? auth.userCases : [],
    role: auth.role,
  };
};
export default connect(mapStateToProps, null)(OnGoingCaseList);
