import React, { Component, useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  CATEGORY,
  OCR_DOCUMENT_LABEL,
} from "../../../../../constants/constants";
const InfoMatching = (props) => {
  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  const [selectedUser, setSelectedUser] = useState({});

  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  const secondTabKey = useSelector((state) => state.tabReducer.secondTabKey);
  const selectedMemberDirector = useSelector(
    (state) => state.auth.selectedMemberDirector
  );

  useEffect(() => {
    //

    if (secondTabKey === "1") {
      setSelectedUser(selectedApplicant);
    } else {
      setSelectedUser(selectedMemberDirector);
    }
  }, [selectedMemberDirector, selectedApplicant, secondTabKey]);
  return (
    <React.Fragment>
      <div className="infoWrap infoWrap-kyc infoWrap-kyc-v2">
        <h4>Information Matching</h4>
        <div className="TableWrap">
          <div></div>
          <table>
            <thead className="kyc-summary-header">
              <tr>
                <th className="kyc-summary-inner-paragraph" colSpan={30}>
                  The comparison between provided applicant details with that of
                  POA and POI documents
                </th>
              </tr>
              <tr>
                <th style={{ border: "none" }}>
                  Information from Case Details
                </th>
                {kycResult &&
                  Array.isArray(kycResult) &&
                  kycResult.map((kyc_doc) => {
                    return (
                      <th>
                        {kyc_doc.document_name === "PAN Card"
                          ? "PAN Card"
                          : kyc_doc.document_name}
                      </th>
                    );
                  })}
                {/*<th style={{ borderRadius: "0px" }}>PAN</th>
                <th>Aadhar</th>
                <th>DL</th>
                <th>Voter Id</th>
  <th>Passport</th>*/}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {selectedUser &&
                  selectedUser.fk_category_id &&
                  selectedUser.fk_category_id != 1
                    ? "Organization Name"
                    : "Name"}
                </td>
                {kycResult &&
                  Array.isArray(kycResult) &&
                  kycResult.map((kyc_doc) => {
                    return <td>{kyc_doc.name_matching_score}%</td>;
                  })}
              </tr>
              <tr>
                <td>
                  {selectedUser &&
                  selectedUser.fk_category_id &&
                  selectedUser.fk_category_id != 1
                    ? "Date of Incorporation"
                    : "DOB"}
                </td>
                {kycResult &&
                  Array.isArray(kycResult) &&
                  kycResult.map((kyc_doc) => {
                    return (
                      <td>
                        {/*{selectedUser &&
                        selectedUser.fk_category_id &&
                        selectedUser.fk_category_id != CATEGORY.INDIVIDUAL
                          ? kyc_doc.document_name !==
                            OCR_DOCUMENT_LABEL.PAN_CARD
                            ? `${kyc_doc.dob_matching_score}%`
                            : "-"
                        : `${kyc_doc.dob_matching_score}%`}*/}
                        {kyc_doc.dob_matching_score}%
                      </td>
                    );
                  })}
              </tr>
              <tr>
                <td>Address</td>
                {kycResult &&
                  Array.isArray(kycResult) &&
                  kycResult.map((kyc_doc) => {
                    return <td>{kyc_doc.address_matching_score}{kyc_doc.document_name !== OCR_DOCUMENT_LABEL.PAN_CARD && '%'}</td>;
                  })}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(InfoMatching);
