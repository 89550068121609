import React, { Component } from "react";
import { Card, Input  } from "antd";
const { TextArea } = Input;
class CreditReview extends Component {
    render() {
      return (
        <>
        <div className='TextWrap'>
            <h4>Enter a reason why you think review from credit is required</h4>
           
            <TextArea rows={4} placeholder='Please Review The Case'/>
            
        </div>
        </>
      )
    }
}
export default CreditReview;