import React, { Component } from "react";
import { Tabs, Card, Row, Col } from "antd";
import DetailDocform from "../detaildocform";
import MainApplicantFormKyc from "../kycreport/mainapplicantform";
import BureauReport from "../bureaureport";
import SecondTab from "../secondtab/secondtab";
import { useDispatch, useSelector } from "react-redux";
import { onChangeMiddleTab } from "../../../redux/actions/TabAction";
const { TabPane } = Tabs;
const MiddleTab = (props) => {
  const { memberOrDirectors = [], applicant_id} = props;
  const middleTab = useSelector((state) => state.auth.middleTab);
  const dispatch = useDispatch()
  const changeMiddleTab = (key,x) => {
    dispatch(onChangeMiddleTab(key))
  };
  return (
    <React.Fragment>
      <div className="InnerTabsWrap">
        <Card>
          <Tabs
            activeKey={middleTab}
            onChange={(key, x) => {
              changeMiddleTab(key, x);
            }}
          >
            <TabPane tab="Original Applicant" key="1">
              <SecondTab type={'original_applicant'}
              applicant_id applicant_type='Main Applicant'/>
            </TabPane>
            {memberOrDirectors.map((memberOrDirector, index) => (
              <TabPane
                tab={`${memberOrDirector.member_type} ${index + 1}`}
                key={(index + 2).toString()}
              >
                <SecondTab
                  type={memberOrDirector.member_type}
                  applicant_id={memberOrDirector.id}
                />
              </TabPane>
            ))}
          </Tabs>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default MiddleTab;
