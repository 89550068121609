import {
  GET_NOTIFICATIONS,
  READ_NOTIFICATION
  } from "../constants/ActionTypes";
  
  export const getNotifications = () => {
    return {
      type: GET_NOTIFICATIONS,
    };
  };


  export const readNotification = (data,onSuccess) => {
    return {
      type: READ_NOTIFICATION,
      data:data,
      onSuccess
    };
  };

  

 