import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import { loadDownloadFleetReportCasesApi, loadDownloadFleetSummaryReportCasesApi, loadDownloadFleetTemplateCasesApi, loadFleetApi, loadFleetByCaseApi, loadGetCasesApi, loadReRunFleetAnalysisApi, runFleetApi} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    FLEET_ANALYSIS, 
    SHOW_LOADING,
    HIDE_LOADING,
    FLEET_SUCCESS,
    FLEET_ANALYSIS_LIST,
    FLEET_ANALYSIS_LIST_BY_CASE,
    FLEET_ANALYSIS_RE_RUN, 
    DOWNLOAD_FLEET_REPORT,
    DOWNLOAD_FLEET_TEMPLATE,
    DOWNLOAD_FLEET_SUMMARY_REPORT,
} from "../constants/ActionTypes";
import { errorMessage, successMessage } from "../../helpers/utils";

function* fetchRunFleetAnalysis(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(runFleetApi, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: FLEET_SUCCESS, fleetData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }

  function* fetchLoadFleetAnalysis(action) {
    try { 
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadFleetApi, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: FLEET_SUCCESS, fleetData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }

  function* fetchLoadFleetAnalysisByCase(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadFleetByCaseApi, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: FLEET_SUCCESS, fleetData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }

  function* fetchReRunFleetAnalysis(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadReRunFleetAnalysisApi, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: FLEET_SUCCESS, fleetData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }

  function* fetchCases(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadGetCasesApi, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: FLEET_SUCCESS, fleetData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  
  function* downloadFleet(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadDownloadFleetReportCasesApi, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: FLEET_SUCCESS, fleetData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  
  function* downloadFleetTemplate(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadDownloadFleetTemplateCasesApi, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: FLEET_SUCCESS, fleetData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  
  function* downloadFleetSummary(action) {
    try {
      yield put({ type: SHOW_LOADING });
      const response = yield call(loadDownloadFleetSummaryReportCasesApi, action.payload);
      if (response.status === 200 && response.data) {
        yield put({ type: FLEET_SUCCESS, fleetData: response.data });
      }
      yield put({ type: HIDE_LOADING });
      action.onSuccess({ data: response.data });
    } catch (error) {
      errorMessage(error);
      yield put({ type: HIDE_LOADING }); 
    }
  }
  

function* actionRunFleetAnalysis() {
    yield takeLatest(FLEET_ANALYSIS, fetchRunFleetAnalysis)
}

function* onFleetAnalysisLoad() {
  yield takeLatest(FLEET_ANALYSIS_LIST, fetchLoadFleetAnalysis)
}

function* onLoadFleetAnalysisByCase() {
  yield takeLatest(FLEET_ANALYSIS_LIST_BY_CASE, fetchLoadFleetAnalysisByCase)
}
function* onReRunFleetAnalysis() {
  yield takeLatest(FLEET_ANALYSIS_RE_RUN, fetchReRunFleetAnalysis)
}

function* onDownloadFleetReport() {
  yield takeLatest(DOWNLOAD_FLEET_REPORT,downloadFleet)
}

function* onDownloadTemplate() {
  yield takeLatest(DOWNLOAD_FLEET_TEMPLATE,downloadFleetTemplate)
}

function* onDownloadFleetSummaryReport() {
  yield takeLatest(DOWNLOAD_FLEET_SUMMARY_REPORT,downloadFleetSummary)
}





export default function* fleetAnalysisSaga() {
    yield all([
        fork(actionRunFleetAnalysis),
        fork(onFleetAnalysisLoad),
        fork(onLoadFleetAnalysisByCase),
        fork(onReRunFleetAnalysis), 
        fork(onDownloadFleetReport),
        fork(onDownloadTemplate),
        fork(onDownloadFleetSummaryReport),

        

        
        
    ]);
}
