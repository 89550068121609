import React, { Component } from "react";
import TopBarNav from '../components/topbar';
import Navigation from '../components/navigation';
import PendingCreditReview from '../components/pedingcreditreiew'
class PendingCredit extends Component {
    render() {
        return (
          <>
          <div className='NewCasesWrap'>
             <TopBarNav/>
              <Navigation/>
              <PendingCreditReview/>
          </div>
          
          </>
        )
    }
}
export default PendingCredit;