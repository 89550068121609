import React, { useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Upload,
  Input,
  Radio,
  DatePicker,
  Select,
  message,
} from "antd";
import { useDispatch, connect } from "react-redux";
import moment from "moment";
import SavedNonOcrDocuments from "../../../common/SavedNonOcrDocuments";
import Modal from "antd/lib/modal/Modal";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  disabledFutureDates,
  fileNameFromUrl,
  fileTypeFromName,
  firstCharacterOfEachWordUpperCase,
  getBase64,
} from "../../../../helpers/utils";
import { MESSAGES, REQUIRED_MESSAGES } from "../../../../constants/messages";
// import {
//   onCaseDetail,
//   onCaseMasters,
//   onChangeSelectedUser,
//   onFirstTabChange,
//   onGetCities,
//   onGetStates,
//   onRunKyc,
//   onRunOcr,
//   onUpdateCoApplicant,
//   onUpdateGuarantor,
//   onUpdateMainApplicant,
//   onUploadOcrDocuments,
// } from "../../../redux/actions/AuthAction";
import RunOcr from "../../../lognewcase/modals/runSingleOcr";
import NumberFormat from "react-number-format";
import { REGEX } from "../../../../constants/regex";
import { OCR_DOCUMENT_LABEL, STAGES } from "../../../../constants/constants";
import { toast } from "react-toastify";
import PreviewImageOrPDF from "../../../common/modals/PreviewImageOrPDF";
import {
  onRunOcr,
  onUploadOcrDocuments,
} from "../../../../redux/actions/OcrAction";
import { onCaseDetail } from "../../../../redux/actions/CaseAction";
import { onFirstTabChange } from "../../../../redux/actions/TabAction";
import { onChangeSelectedUser } from "../../../../redux/actions/AuthAction";
import { onUpdateMainApplicant } from "../../../../redux/actions/MainApplicantAction";
import { onUpdateCoApplicant } from "../../../../redux/actions/CoApplicantAction";
import { onUpdateGuarantor } from "../../../../redux/actions/GuarantorAction";
import { onRunKyc } from "../../../../redux/actions/KycAction";
import {
  onCaseMasters,
  onGetCities,
  onGetStates,
} from "../../../../redux/actions/MasterData";

const layout = {
  labelCol: { span: 11, sm: 24, md: 11 },
  wrapperCol: { span: 13, sm: 24, md: 13 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const onFinishFailed = (errorInfo) => {};
const { Option } = Select;
const OriginalApplicantDetail = (props) => {
  const dispatch = useDispatch();
  const { selectedUser, caseDetail, firstTab, states, caseMasters } = props;
  const [subCategories, setSubCategories] = useState([]);
  const [cities, setCities] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [relationship, setRelationship] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [runOcr, setRunOcr] = useState(false);
  const [ocrResponse, setOcrResponse] = useState([]);

  const [ocrDocuments, setOcrDocuments] = useState({
    pan_card: [],
    aadhar_card: [],
    passport: [],
    voter_id: [],
    driving_license: [],
  });
  const [nonOcrDocuments, setNonOcrDocuments] = useState([]);
  const [form] = Form.useForm();

  const handleCategorySelect = useCallback(
    (selectedCategory) => {
      form.setFieldsValue({ fk_sub_category: undefined });
      const subCategories = caseMasters.subcategories
        .filter((subCategory) => {
          return subCategory.fk_category_id == selectedCategory;
        })
        .map((data) => {
          return (
            <Select.Option key={data.id} value={data.id.toString()}>
              {data.subcategory_name}
            </Select.Option>
          );
        });
      setSubCategories(subCategories);
    },
    [caseMasters.subcategories, form]
  );

  useEffect(() => {
    dispatch(onCaseMasters((caseMasters) => {}));
    dispatch(onGetStates());
  }, [dispatch]);
  useEffect(() => {
    if (selectedUser) {
      if (selectedUser.ocr_documents && selectedUser.ocr_documents.length > 0) {
        let docs = {};
        selectedUser.ocr_documents.forEach((ocrDoc, index) => {
          let uid = (index + 1).toString();
          let name = fileNameFromUrl(ocrDoc.document_path);
          let fileType = fileTypeFromName(name);
          let url = ocrDoc.document_path;
          let status = "done";
          let doc_array = [
            {
              uid,
              name,
              fileType,
              url,
              status,
            },
          ];
          if (ocrDoc.document_name === OCR_DOCUMENT_LABEL.PAN_CARD) {
            docs.pan_card = doc_array;
          } else if (ocrDoc.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
            docs.aadhar_card = doc_array;
          } else if (ocrDoc.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE) {
            docs.driving_license = doc_array;
          } else if (ocrDoc.document_name === OCR_DOCUMENT_LABEL.PASSPORT) {
            docs.passport = doc_array;
          } else if (ocrDoc.document_name === OCR_DOCUMENT_LABEL.VOTER_ID) {
            docs.voter_id = doc_array;
          }
        });
        setOcrDocuments((o) => {
          return { ...o, ...docs };
        });
      }
      if (
        selectedUser.non_ocr_documents &&
        selectedUser.non_ocr_documents.length > 0
      ) {
        setNonOcrDocuments(selectedUser.non_ocr_documents);
      }
    }
  }, [selectedUser]);
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      fk_sub_category: undefined,
    });
    let initVals = {
      first_name: selectedUser.first_name ? `${selectedUser.first_name}` : "",
      middle_name: selectedUser.middle_name
        ? `${selectedUser.middle_name}`
        : "",
      last_name: selectedUser.last_name ? `${selectedUser.last_name}` : "",
      mobile_no: selectedUser.mobile_no ? selectedUser.mobile_no : "",
      gender: selectedUser.gender,
      pan_no: selectedUser.pan_no ? selectedUser.pan_no : "",
      address_line1: selectedUser.address_line1
        ? selectedUser.address_line1
        : "",
      address_line2: selectedUser.address_line2
        ? selectedUser.address_line2
        : "",
      address_line3: selectedUser.address_line3
        ? selectedUser.address_line3
        : "",
      landmark: selectedUser.landmark ? selectedUser.landmark : "",
      pin: selectedUser.pin ? selectedUser.pin : "",
      state: selectedUser.state,
      city: selectedUser.city,
      fk_category: selectedUser.fk_category_id
        ? selectedUser.fk_category_id.toString()
        : "",
      fk_sub_category:
        selectedUser.fk_subcategory_id && selectedUser.fk_subcategory_id != 0
          ? selectedUser.fk_subcategory_id.toString()
          : undefined,
      relationship: selectedUser.coapplicant_applicant_relation,
      other_relation: selectedUser.other_relation,
      email: selectedUser.email,
    };

    handleCategorySelect(selectedUser.fk_category_id);
    setRelationship(selectedUser.coapplicant_applicant_relation);
    form.setFieldsValue(initVals);

    if (selectedUser.dob) {
      form.setFieldsValue({
        dob: moment(selectedUser.dob),
      });
    }
  }, [form, selectedUser, ocrDocuments, handleCategorySelect]);
  const renderCities = useCallback(
    (stateId) => {
      dispatch(
        onGetCities(stateId, (cities) => {
          if (cities) {
            const options = cities.map((data) => {
              return (
                <Select.Option key={data.city_name} value={data.city_name}>
                  {data.city_name}
                </Select.Option>
              );
            });
            setCities(options);
          }
        })
      );
    },
    [dispatch]
  );
  useEffect(() => {
    if (selectedUser.state) {
      if (states && Array.isArray(states)) {
        const initialState = states.find((state) => {
          return state.state_name === selectedUser.state;
        });
        if (initialState && initialState.id) {
          renderCities(initialState.id);
        }
      }
    }
  }, [selectedUser.state, states, renderCities]);
  const renderStateOptions = () => {
    if (states && states.length !== 0) {
      const options = states.map((data) => {
        return (
          <Select.Option key={data.state_name} value={data.state_name}>
            {data.state_name}
          </Select.Option>
        );
      });
      return options;
    }
  };

  const beforeUpload = (file, list, docName) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file)) {
        const formData = new FormData();
        formData.append("fk_case_id", caseDetail.id); //77
        formData.append("applicant_type", selectedUser.applicant_type);
        formData.append("applicant_coapplicant_guarantor_id", selectedUser.id);
        formData.append(docName, file);
        dispatch(
          onUploadOcrDocuments(formData, (response) => {
            let selectedTab = firstTab;
            dispatch(
              onCaseDetail({ caseId: caseDetail.id }, (response) => {
                dispatch(onFirstTabChange(selectedTab));
                if (selectedUser.applicant_type === "Main Applicant") {
                  dispatch(onChangeSelectedUser(response.main_applicant));
                } else if (selectedUser.applicant_type === "Co Applicant") {
                  const updatedCoApplicant = response.co_applicants.find(
                    (co_applicant) => co_applicant.id === selectedUser.id
                  );
                  dispatch(onChangeSelectedUser(updatedCoApplicant));
                } else if (selectedUser.applicant_type === "Guarantor") {
                  const updatedGuarantor = response.guarantors.find(
                    (guarantor) => guarantor.id === selectedUser.id
                  );
                  dispatch(onChangeSelectedUser(updatedGuarantor));
                }
              })
            );
          })
        );
        setOcrDocuments((o) => {
          return { ...o, [docName]: list };
        });
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const handlePreview = async (file) => {
    setPreviewPdf(false);
    setPreviewImage(false);
    setPreviewVisible(false);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    const fileName = fileNameFromUrl(file.url);
    const fileType = fileTypeFromName(fileName);
    if (fileType === "pdf") {
      setPreviewPdf(file.url || file.preview);
    } else {
      setPreviewImage(file.url || file.preview);
    }
    setPreviewVisible(true);
  };
  const onFinish = (values) => {
    let data = {
      fk_case_id: selectedUser.fk_case_id,
      id: selectedUser.id,
      first_name: values.first_name,
      middle_name: values.middle_name,
      last_name: values.last_name,
      gender: values.gender,
      dob: moment(values.dob).format("YYYY-MM-DD"),
      pan_no: values.pan_no,
      mobile_no: values.mobile_no,
      email: values.email,
      address_line1: values.address_line1,
      address_line2: values.address_line2,
      address_line3: values.address_line3,
      landmark: values.landmark,
      pin: values.pin,
      state: values.state,
      city: values.city,
    };
    if (selectedUser.applicant_type === "Main Applicant") {
      dispatch(
        onUpdateMainApplicant(data, (data) => {
          dispatch(
            onCaseDetail({ caseId: selectedUser.fk_case_id }, (response) => {
              dispatch(onChangeSelectedUser(response.main_applicant));
            })
          );
        })
      );
    } else if (selectedUser.applicant_type === "Co Applicant") {
      data = {
        ...data,
        // fk_category_id:selectedUser.fk_category_id,
        // fk_subcategory_id:selectedUser.fk_subcategory_id,
        // coapplicant_applicant_relation:selectedUser.coapplicant_applicant_relation,
        // other_relation:selectedUser.other_relation && selectedUser.other_relation!=='undefined'?selectedUser.other_relation:'',
        fk_category_id: values.fk_category ? values.fk_category.toString() : "",
        fk_subcategory_id: values.fk_sub_category
          ? values.fk_sub_category.toString()
          : values.fk_subcategory_id,
        coapplicant_applicant_relation: values.relationship,
        other_relation: values.other_relation ? values.other_relation : "",
      };
      dispatch(
        onUpdateCoApplicant(data, (data) => {
          // onChangeSelectedUser()
          dispatch(
            onCaseDetail({ caseId: selectedUser.fk_case_id }, (response) => {
              const updatedCoApplicant = response.co_applicants.find(
                (co_applicant) => co_applicant.id === selectedUser.id
              );

              dispatch(onChangeSelectedUser(updatedCoApplicant));
            })
          );
        })
      );
    } else if (selectedUser.applicant_type === "Guarantor") {
      data = {
        ...data,
        fk_category_id: values.fk_category,
        fk_subcategory_id: values.fk_sub_category,
      };

      dispatch(
        onUpdateGuarantor(data, (data) => {
          // onChangeSelectedUser()
          dispatch(
            onCaseDetail({ caseId: selectedUser.fk_case_id }, (response) => {
              //
              const updatedGuarantor = response.guarantors.find(
                (guarantor) => guarantor.id === selectedUser.id
              );
              dispatch(onChangeSelectedUser(updatedGuarantor));
            })
          );
        })
      );
    }
  };
  const handleSelectState = (selectedState) => {
    let state = states.find((state) => {
      return state.state_name === selectedState;
    });
    renderCities(state.id);
    // dispatch(
    // onGetCities(state.id, (cities) => {
    //     if (cities) {
    //       const options = cities.map((data) => {
    //         return (
    //           <Select.Option key={data.city_name} value={data.city_name}>
    //             {data.city_name}
    //           </Select.Option>
    //         );
    //       });
    //       setCities(options);
    //     }
    //   })
    // );
    form.setFieldsValue({ city: undefined });
  };

  // useEffect(()=>{
  //
  //   // let state = states.find((state) => {
  //   //   return state.state_name === selectedState
  //   // });
  // },[states,selectedUser])
  const updateSelectedUser = () => {
    dispatch(
      onCaseDetail({ caseId: caseDetail.id }, (response) => {
        if (firstTab === "2") {
          dispatch(onChangeSelectedUser(response.main_applicant));
        } else if (firstTab === "3") {
          let co_applicant = response.co_applicants.find((co_applicant) => {
            return co_applicant.id == selectedUser.id;
          });
          dispatch(onChangeSelectedUser(co_applicant));
        } else if (firstTab === "4") {
          let guarantor = response.guarantors.find((guarantor) => {
            return guarantor.id == selectedUser.id;
          });
          dispatch(onChangeSelectedUser(guarantor));
        }
      })
    );
  };
  const runKycCheck = () => {
    if (selectedUser) {
      let data = {
        fk_case_id: selectedUser.fk_case_id,
        applicant_type: selectedUser.applicant_type,
        applicant_coapplicant_guarantor_id: selectedUser.id,
      };
      dispatch(
        onRunKyc(data, (response) => {
          setOcrResponse(response);

          updateSelectedUser();
          setRunOcr(true);
        })
      );
    }
  };
  const runOcrCheck = () => {
    let data = {
      fk_case_id: caseDetail.id,
      applicant_type: selectedUser.applicant_type,
      applicant_coapplicant_guarantor_id: selectedUser.id,
    };
    // setRunOcr(true);
    dispatch(
      onRunOcr(data, (response) => {
        setOcrResponse(response);

        updateSelectedUser();
        setRunOcr(true);
      })
    );
  };
  const nameValidation = (e) => {
    const field_name = e.target.id.replace("basic_", "");
    const input = e.target.value;
    if (!REGEX.NAME_VALIDATION.test(input)) {
      form.setFieldsValue({
        [field_name]: input
          .substring(0, input.length - 1)
          .replace(/[^a-zA-Z0-9&-/s ]/gm, ""),
      });
    }
  };
  const KycCheck = (runOcr) => {
    setRunOcr(runOcr);
  };
  return (
    <>
      <div className="FormWrap">
        <Form
          {...layout}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Row gutter={32} className="StatusHighlight mt-0 pt-0">
            {/*<Col span={4} xs={24} sm={24} md={12}>
            <p className="CaseId">
              
              <span>
                Status <span>Bureau Reports Generated</span>
              </span>
            </p>
  </Col>*/}
            {/*<Col span={12} xs={24} sm={24} md={12}><b>Stage</b> : {selectedUser.stage}</Col>*/}
            <Col span={24} xs={24} sm={24} md={24} className="ButtonWrap">
              <Button htmlType="submit">Save</Button>
              {(!selectedUser.stage ||
                selectedUser.stage === STAGES.OCR_PENDING) && (
                <Button onClick={runOcrCheck}>Run OCR</Button>
              )}
              {selectedUser.stage === STAGES.KYC_PENDING && (
                <Button onClick={runKycCheck}>Run KYC</Button>
              )}
              {selectedUser.stage === STAGES.BUREAU_REPORT_PENDING && (
                <Button onClick={runKycCheck}>Run Bureau Report</Button>
              )}
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={8} md={8} xs={24} sm={24}>
              {(selectedUser.applicant_type === "Co Applicant" ||
                selectedUser.applicant_type === "Guarantor") && (
                <React.Fragment>
                  <Form.Item
                    label={selectedUser.applicant_type + " type"}
                    name="fk_category"
                    className="TopTabBox"
                    rules={[
                      {
                        required: true,
                        message:
                          REQUIRED_MESSAGES.APPLICATION_CATEGORY_REQUIRED,
                      },
                    ]}
                  >
                    <Select
                      defaultValue={selectedUser.fk_category_id.toString()}
                      onChange={handleCategorySelect}
                    >
                      {caseMasters.categories.map((category, index) => (
                        <Option
                          value={category.id.toString()}
                          key={category.id.toString()}
                        >
                          {category.category_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    label="Sub Category"
                    name="fk_sub_category"
                    rules={[
                      {
                        required: subCategories.length > 0,
                        message:
                          REQUIRED_MESSAGES.APPLICATION_SUB_CATEGORY_REQUIRED,
                      },
                    ]}
                    className="TopTabBox"
                  >
                    <Select
                      placeholder={"Please select sub category"}
                      disabled={subCategories.length === 0}
                    >
                      {subCategories}
                    </Select>
                  </Form.Item>
                </React.Fragment>
              )}
              {selectedUser.applicant_type === "Co Applicant" && (
                <React.Fragment>
                  <Form.Item
                    label="Relationship with applicant"
                    name="relationship"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.RELATIONSHIP_REQUIRED,
                      },
                    ]}
                    className="TopTabBox"
                  >
                    <Select
                      placeholder={"Relationship with applicant"}
                      onSelect={(val) => {
                        setRelationship(val);
                      }}
                    >
                      <Option value="Brother">Brother</Option>
                      <Option value="Sister">Sister</Option>
                      <Option value="Mother">Mother</Option>
                      <Option value="Father">Father</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                  </Form.Item>
                  {relationship === "Other" && (
                    <Form.Item
                      label="Other"
                      name="other_relation"
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGES.OTHER_RELATION_REQUIRED,
                        },
                      ]}
                    >
                      <Input placeholder="relationship" />
                    </Form.Item>
                  )}
                </React.Fragment>
              )}
              <Form.Item
                label="First name"
                name="first_name"
                id="first_name"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.FIRST_NAME_REQUIRED,
                  },
                ]}
              >
                <Input placeholder="First Name" onChange={nameValidation} />
              </Form.Item>
              <Form.Item
                label="Middle name"
                name="middle_name"
                onChange={nameValidation}
              >
                <Input placeholder="Middle Name" />
              </Form.Item>
              <Form.Item
                label="Last name"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.LAST_NAME_REQUIRED,
                  },
                ]}
              >
                <Input onChange={nameValidation} placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                label="Gender"
                name="gender"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.GENDER_REQUIRED,
                  },
                ]}
              >
                <Radio.Group name="radiogroup">
                  <Radio value={"MALE"}>Male</Radio>
                  <Radio value={"FEMALE"}>Female</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item
                label="PAN No."
                name="pan_no"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.PAN_NUMBER_REQUIRED,
                  },
                  {
                    pattern: REGEX.PAN_NUMBER_REGEX,
                    message: MESSAGES.INVALID_PAN_NUMBER,
                    validateTrigger: true,
                  },
                ]}
              >
                <Input placeholder="ABCDE1234F" maxLength={10} />
              </Form.Item>
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <Form.Item
                label="Address Line 1"
                name="address_line1"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.ADDRESS_REQUIRED,
                  },
                ]}
              >
                <Input placeholder="Address" />
              </Form.Item>
              <Form.Item label="Address Line 2" name="address_line2">
                <Input placeholder="Address" />
              </Form.Item>
              <Form.Item label="Address Line 3" name="address_line3">
                <Input placeholder="Address" />
              </Form.Item>
              <Form.Item label="Landmark" name="landmark">
                <Input placeholder="Landmark" />
              </Form.Item>
              <Form.Item
                label="PIN Code"
                name="pin"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.PIN_CODE_REQUIRED,
                    validateTrigger: true,
                  },
                  {
                    pattern: /^[1-9][0-9]{5}$/,
                    message: MESSAGES.INVALID_PIN_CODE,
                    validateTrigger: true,
                  },
                ]}
              >
                <NumberFormat
                  format="######"
                  placeholder="Pin Code"
                  class="ant-input"
                />
                {/*<Input placeholder="Postal Code" maxLength={6}/>*/}
              </Form.Item>
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <Form.Item
                label="State"
                name="state"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.STATE_REQUIRED,
                  },
                ]}
              >
                <Select
                  onSelect={handleSelectState}
                  placeholder="Please Select State"
                >
                  {renderStateOptions()}
                </Select>
              </Form.Item>
              <Form.Item
                label="City"
                name="city"
                rules={[
                  { required: true, message: REQUIRED_MESSAGES.CITY_REQUIRED },
                ]}
              >
                <Select placeholder="Please Select City">{cities}</Select>
              </Form.Item>
              <Form.Item
                label="Date of Birth"
                name="dob"
                rules={[
                  { required: true, message: REQUIRED_MESSAGES.DOB_REQUIRED },
                ]}
              >
                <DatePicker disabledDate={disabledFutureDates} inputReadOnly />
              </Form.Item>
              <Form.Item
                label="Mobile No"
                name="mobile_no"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.MOBILE_NUMBER_REQUIRED,
                  },
                ]}
              >
                <NumberFormat
                  placeholder="9986437788"
                  type="tel"
                  format="+91##########"
                  class="ant-input"
                />
                {/*<Input placeholder="12467989" />*/}
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: MESSAGES.INVALID_EMAIL,
                    validateTrigger: true,
                  },
                ]}
              >
                <Input placeholder="xyz@gmail.com" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32} className="UploadWrap">
            <Col span={24}>
              <h3>Upload Scanned Copy / Photo Of OCR Documents</h3>
            </Col>
            <Col span={8} xs={24} md={8} sm={12}>
              <Form.Item label="PAN Card" name="pan_card">
                <Upload
                  maxCount={1}
                  fileList={ocrDocuments.pan_card}
                  beforeUpload={(file, fileList) => {
                    const result = beforeUpload(file, fileList, "pan_card");
                    if (result) {
                      return Upload.LIST_IGNORE;
                    }
                    return false;
                  }}
                  onPreview={handlePreview}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                >
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={8} sm={12}>
              <Form.Item label="Driving License" name="driving_license">
                <Upload
                  maxCount={1}
                  beforeUpload={(file, fileList) => {
                    const result = beforeUpload(
                      file,
                      fileList,
                      "driving_license"
                    );
                    if (result) {
                      return Upload.LIST_IGNORE;
                    }
                    return false;
                  }}
                  fileList={ocrDocuments.driving_license}
                  onPreview={handlePreview}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                >
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>

            <Col span={8} xs={24} md={8} sm={12}>
              <Form.Item label="Passport" name="Passport">
                <Upload
                  maxCount={1}
                  fileList={ocrDocuments.passport}
                  beforeUpload={(file, fileList) => {
                    const result = beforeUpload(file, fileList, "passport");
                    if (result) {
                      return Upload.LIST_IGNORE;
                    }
                    return false;
                  }}
                  onPreview={handlePreview}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                >
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={8} sm={12}>
              <Form.Item label="Aadhar Card" name="aadhar_card">
                <Upload
                  maxCount={1}
                  fileList={ocrDocuments.aadhar_card}
                  beforeUpload={(file, fileList) => {
                    const result = beforeUpload(file, fileList, "aadhar_card");
                    if (result) {
                      return Upload.LIST_IGNORE;
                    }
                    return false;
                  }}
                  onPreview={handlePreview}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                >
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={8} xs={24} md={8} sm={12}>
              <Form.Item label="Voter ID" name="VoterID">
                <Upload
                  maxCount={1}
                  fileList={ocrDocuments.voter_id}
                  beforeUpload={(file, fileList) => {
                    const result = beforeUpload(file, fileList, "voter_id");
                    if (result) {
                      return Upload.LIST_IGNORE;
                    }
                    return false;
                  }}
                  onPreview={handlePreview}
                  showUploadList={{
                    showRemoveIcon: false,
                  }}
                >
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>
            {/*{nonOcrDocuments.map((nonOcrDoc, index) => {
              return (
                <Col span={8} xs={24} md={8} sm={12}>
                  <Form.Item
                    label={firstCharacterOfEachWordUpperCase(
                      nonOcrDoc.document_name
                    )}
                    name={nonOcrDoc.document_name}
                  >
                    <Upload
                      maxCount={1}
                      defaultFileList={[
                        {
                          uid: index,
                          name: nonOcrDoc.document_name,
                          status: "done",
                          url: nonOcrDoc.document_path,
                        },
                      ]}
                      onPreview={handlePreview}
                      showUploadList={{
                        showRemoveIcon: false,
                      }}
                    >
                      <Button>Upload </Button>
                    </Upload>
                  </Form.Item>
                </Col>
              );
            })}*/}
          </Row>
          <Row gutter={32} className="UploadWrap">
            <Col span={24}>
              <h3>Upload Scanned Copy / Photo Of Non OCR Documents</h3>
            </Col>
            <SavedNonOcrDocuments
              nonOcrDocuments={nonOcrDocuments}
              setNonOcrDocuments={(docs) => {
                setNonOcrDocuments(docs);
              }}
              form={form}
            />
            {/*<Col span={12} xs={24} md={12} sm={12}>
            <Form.Item label="Document Name" name="DocName" className='DocColumn'>
                <Upload maxCount={1}>
                  <Button>Upload </Button>
                </Upload>
                <Button>Add</Button>
              </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12} sm={12}>
            <table>
              <thead>
                <tr>
                  <th>Document Name </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Doc 1</td>
                  <td>
                    <Button>View</Button>
                    <Button className='delete'>Delete</Button>
                  </td>
                </tr>
                <tr>
                  <td>Doc 2</td>
                  <td>
                    <Button>View</Button>
                    <Button className='delete'>Delete</Button>
                  </td>
                </tr>
              </tbody>
            </table>
            </Col>
      */}
          </Row>
        </Form>
        <Modal
          title="Document Uploaded Successfully"
          centered
          visible={runOcr}
          onOk={() => KycCheck(false)}
          onCancel={() => KycCheck(false)}
          footer={null}
          width={600}
          className="KycCheck"
          style={{ top: 50 }}
        >
          <RunOcr ocrResponse={ocrResponse} />
        </Modal>
        {/*<Modal
          visible={previewVisible}
          // title={previewTitle}
          footer={null}
          onCancel={() => {
            setPreviewVisible(false);
          }}
        >
          {previewImage && <img alt="example" style={{ width: "100%" }} src={previewImage} />}
          {previewPdf && (
            <iframe
              alt=""
              title="a"
              src={previewPdf}
              style={{ width: 500, height: 500 }}
              frameborder="0"
            />
          )}
          </Modal>*/}
        <PreviewImageOrPDF
          previewVisible={previewVisible}
          previewImage={previewImage}
          previewPdf={previewPdf}
          onCancel={() => {
            setPreviewImage(false);
            setPreviewPdf(false);
            setPreviewVisible(false);
          }}
        />
      </div>
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
    states: auth.states,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
  };
};
export default connect(mapStateToProps, null)(OriginalApplicantDetail);
