import {all, call, fork, put, takeLatest} from 'redux-saga/effects';
import { getBureauReportListingAPI,getBureauReportMasterAPI,generateBureauReportAPI,
    getBureauReportHtmlAPI,getBureauReportDetailAPI,getBureauReportParametersAPI,getCibilReportMasterAPI,
    getBureauReportSummaryAPI,generateCibilBureauReportAPI,getBureauReportExcelAPI,
    generateBureauReportCheckParamAPI,getBureauReportGeneratedCasesApi} from "../apis/Api";
import {toast} from 'react-toastify';
import {
    HIDE_LOADING,
    SHOW_LOADING,
    GET_BUREAU_REPORT_LISTING,
    GET_BUREAU_REPORT_MASTER,
    GET_BUREAU_REPORT_MASTER_FAILED,
    GET_BUREAU_REPORT_MASTER_SUCCESS,
    GET_CIBIL_REPORT_MASTER,
    GET_CIBIL_REPORT_MASTER_FAILED,
    GET_CIBIL_REPORT_MASTER_SUCCESS,
    GENERATE_BUREAU_REPORT,
    VIEW_BUREAU_REPORT,
    GET_BUREAU_REPORT_LISTING_SUCCESS,
    GET_BUREAU_REPORT_LISTING_FAILED,
    SET_SELECTED_BUREAU_REPORT,
    GET_BUREAU_REPORT_HTML,
    GET_BUREAU_REPORT_PARAMETERS,
    GET_BUREAU_REPORT_LIST,GET_BUREAU_REPORT_SUMMARY,
    GENERATE_CIBIL_REPORT,GET_BUREAU_REPORT_EXCEL,
    GENERATE_BUREAU_REPORT_CHECK,
    SET_BUREAU_CASES,
    BUREAU_DONE_CASES_LIST
    
} from "../constants/ActionTypes";
import { errorMessage, successMessage } from "../../helpers/utils";

function* onGenerateBureauReportListing(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportListingAPI,action.data);
        yield put({type: HIDE_LOADING});
        yield put({type: GET_BUREAU_REPORT_LISTING_SUCCESS, bureauReportList: response.data.data});
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: GET_BUREAU_REPORT_LISTING_FAILED});
    }
}
function* onViewBureauReportDetail(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportDetailAPI,action.bureauReportId);
        yield put({type: HIDE_LOADING});
        if(response.data){
            yield put({type:SET_SELECTED_BUREAU_REPORT,data:response.data.data})
           
            action.onSuccess(response.data.data);
        }else{
            yield put({type:SET_SELECTED_BUREAU_REPORT,data:null})
        }
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* actionFetchBureauReportListing() {
    yield takeLatest(GET_BUREAU_REPORT_LISTING, onGenerateBureauReportListing)
}
function* actionViewBureauReport() {
    yield takeLatest(VIEW_BUREAU_REPORT, onViewBureauReportDetail)
}
function* onGetBureauReportList(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportListingAPI,action.data);
        yield put({type: HIDE_LOADING});
        if(response.data){
            action.onSuccess(response.data.data)
        }
        // yield put({type: GET_BUREAU_REPORT_LISTING_SUCCESS, bureauReportList: response.data.data});
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        // yield put({type: GET_BUREAU_REPORT_LISTING_FAILED});
    }
}
function* actionBureauReportList() {
    yield takeLatest(GET_BUREAU_REPORT_LIST, onGetBureauReportList)
}

function* onFectchBureauReportMasters(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportMasterAPI,action.data);
        yield put({type: HIDE_LOADING});
        yield put({type: GET_BUREAU_REPORT_MASTER_SUCCESS, bureauReportMasters: response.data.data});
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: GET_BUREAU_REPORT_MASTER_FAILED});
    }
}

function* actionBureauReportMasters() {
    yield takeLatest(GET_BUREAU_REPORT_MASTER, onFectchBureauReportMasters)
}

function* onFectchCibilReportMasters(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getCibilReportMasterAPI);
        yield put({type: HIDE_LOADING});
        yield put({type: GET_CIBIL_REPORT_MASTER_SUCCESS, bureauReportMasters: response.data.data});
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        yield put({type: GET_CIBIL_REPORT_MASTER_FAILED});
    }
}

function* actionCibilReportMasters() {
    yield takeLatest(GET_CIBIL_REPORT_MASTER, onFectchCibilReportMasters)
}

function* onGenerateBureauReport(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(generateBureauReportAPI,action.data);
        yield put({type: HIDE_LOADING});
        toast.success(response.data.message);
        if(typeof response.data.message!=='string' &&response.data.message.length && response.data.message.length>0){
            response.data.message.map((msg) => {
             toast.error(msg.DESCRIPTION);
            })
        }else if(response.data.message.DESCRIPTION){
            toast.error(response.data.message.DESCRIPTION);
        } 
        action.onApiSuccess(response.data);
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* actionGenerateBureauReport() {
    yield takeLatest(GENERATE_BUREAU_REPORT, onGenerateBureauReport)
}

function* onGenerateBureauCheckParams(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(generateBureauReportCheckParamAPI,action.data);
        yield put({type: HIDE_LOADING});
        // toast.success(response.data.message);
        
        action.onApiCheckSuccess(action.data,response.data);
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* actionGenerateBureauCheckParams() {
    yield takeLatest(GENERATE_BUREAU_REPORT_CHECK, onGenerateBureauCheckParams)
}

function* onGenerateCibilReport(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(generateCibilBureauReportAPI,action.data);
        yield put({type: HIDE_LOADING});
        toast.success(response.data.message);
        if(typeof response.data.message!=='string' &&response.data.message.length && response.data.message.length>0){
            response.data.message.map((msg) => {
             toast.error(msg.DESCRIPTION);
            })
        }else if(response.data.message.DESCRIPTION){
            toast.error(response.data.message.DESCRIPTION);
        } 
        action.onApiSuccess(response.data);
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* actionGenerateCibilReport() {
    yield takeLatest(GENERATE_CIBIL_REPORT, onGenerateCibilReport)
}


function* onGetBureauReportHtml(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportHtmlAPI,action.data);
        yield put({type: HIDE_LOADING});
        if(response.data){
            action.onSuccess(response.data.data);
        }else{
            action.onSuccess([])
        }
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* actionGetBureauReportHtml() {
    yield takeLatest(GET_BUREAU_REPORT_HTML, onGetBureauReportHtml)
}


function* onBureauReportParameters(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportParametersAPI,action.data);
        yield put({type: HIDE_LOADING});
        if(response.data){
            action.onSuccess(response.data.data);
        }else{
            action.onSuccess([])
        }
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* actionBureauReportParameters() {
    yield takeLatest(GET_BUREAU_REPORT_PARAMETERS, onBureauReportParameters)
}

function* onBureauReportSummary(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportSummaryAPI,action.data);
        yield put({type: HIDE_LOADING});
            action.onSuccess(response.data.data);
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* actionBureauReportSummary() {
    yield takeLatest(GET_BUREAU_REPORT_SUMMARY, onBureauReportSummary)
}

function* onBureauReportExcel(action) {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportExcelAPI,action.data);
        yield put({type: HIDE_LOADING});
            action.onSuccess(response.data.file);
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
    }
}
function* actionBureauReportExcel() {
    yield takeLatest(GET_BUREAU_REPORT_EXCEL, onBureauReportExcel)
}

function* onFetchBureauReportGeneratedCases() {
    try {
        yield put({type: SHOW_LOADING});
        const response =  yield call(getBureauReportGeneratedCasesApi);
        if(response.data){
            yield put({ type:SET_BUREAU_CASES, payload: response.data.data });
            yield put({type: HIDE_LOADING});
        }
        // yield put({type: GET_BUREAU_REPORT_LISTING_SUCCESS, bureauReportList: response.data.data});
    } catch (error) {
        
        errorMessage(error);
        yield put({type: HIDE_LOADING});
        // yield put({type: GET_BUREAU_REPORT_LISTING_FAILED});
    }
}
function* actionFetchBureauReportGeneratedCases() {
    yield takeLatest(BUREAU_DONE_CASES_LIST, onFetchBureauReportGeneratedCases)
}

export default function* authSaga() {
    yield all([
        fork(actionFetchBureauReportListing),
        fork(actionBureauReportMasters),
        fork(actionGenerateBureauReport),
        fork(actionViewBureauReport),
        fork(actionGetBureauReportHtml),
        fork(actionBureauReportParameters),
        fork(actionBureauReportList),
        fork(actionBureauReportSummary),
        fork(actionCibilReportMasters),
        fork(actionGenerateCibilReport),
        fork(actionBureauReportExcel),
        fork(actionGenerateBureauCheckParams),
        fork(actionFetchBureauReportGeneratedCases)
    ]);
}
