import React, { Component } from "react";
import { Col, Tabs, Row, Card } from "antd";
// import LeadDetail from "./leaddetails";
import DetailDoc from "./detailsdocuments";
import LeadDetail from "../ongoincasetabs2/leaddetails";
import { connect, useDispatch } from "react-redux";
import { isVolvoUser, loggedInUserData } from "../../helpers/utils";

const { TabPane } = Tabs;
const ViewCaseTabs = (props)=> {
  const userData = loggedInUserData();
  return (
    <>
      <div className="GeneralTabsWrap LeadDetailTabs">
        {/* <div className='popbox'>
            <Card className='popcard'>
              <h3>System Recommendation</h3>
              <h4>Basis The Co -Applicant2 Details The Loan Can Be Sanctioned To The User. The Case Looks Like Doable At This Stage. </h4>
            </Card>
        </div> */}
        <Tabs defaultActiveKey="1">
          <TabPane tab={(userData && isVolvoUser(userData)?'Case Details':'Lead Details')} key="1">
            <LeadDetail />
          </TabPane>
          <TabPane tab="Main Applicant" key="2">
            <Card>
              <h2 className="NodataCard">The Applicant information is driven from the documents. Please upload the relevant POA and POI documents to proceed further.</h2>
            </Card>
          </TabPane>
          <TabPane tab="Co-Applicant" key="3">
            <Card>
              <h2 className="NodataCard">The Applicant information is driven from the documents. Please upload the relevant POA and POI documents to proceed further.</h2>
            </Card>
          </TabPane>
          <TabPane tab="Guarantor" key="4">
            <Card>
              <h2 className="NodataCard">The Applicant information is driven from the documents. Please upload the relevant POA and POI documents to proceed further.</h2>
            </Card>
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
const mapStateToProps = (store) => {
  const { auth } = store;
  
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    role:auth.role,
  };
};
export default connect(mapStateToProps, null)(ViewCaseTabs);
