import React, { Component } from "react";
import { Card, Row, Col } from 'antd';
class AddharCard extends Component {
    render() {
      return (
        <>
            <div className='CardBlock'>
                <h3>Aadhar Card</h3>
                <Card>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Aadhar Number</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>AHMPC45o6C</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Name as mensioned in Aadhar</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Tanvish Kalra</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Aadhar Validity</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Valid</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Address Matching</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Yes</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Name Matching With Given Name</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Yes</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>DOB Matching</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Yes</p>
                        </Col>
                    </Row>
                </Card>
            </div>
        </>
      )
    }
}
export default AddharCard;