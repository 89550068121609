import {
  all,
  call,
  put,
  takeLatest,
  fork,
  select,
  takeMaybe,
} from "redux-saga/effects";
import { toast } from "react-toastify";
import {
  LOGIN,
  SHOW_LOADING,
  HIDE_LOADING,
  SET_PERMISSION_DATA,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  LOGOUT,
  FORGOT,
  RESET_PASSWORD,
  CASE_MASTERS,
  SET_CASE_MASTERS,
  SET_CASES,
  CASES,
  CASE_DETAIL,
  ADD_NEW_CASE,
  UPLOAD_OCR_DOCUMETS,
  SET_CURRENT_TAB,
  NEW_CASE_CREATED,
  UPLOAD_NON_OCR_DOCUMETS,
  SET_CASE_DETAIL,
  STATES,
  CITIES,
  SET_STATES,
  SET_CITIES,
  SSO_LOGIN,
  UPDATE_MAIN_APPLICANT,
  RUN_OCR,
  UPDATE_CO_APPLICANT,
  UPDATE_GUARANTOR,
  ON_GOING_CASES,
  ADD_PARTICIPANT,
  DELETE_NON_OCR_DOCUMENT,
  GET_PARTICIPANT_DETAIL,
  RUN_KYC,
  CURRENT_CASE,
  DASHBOARD,
  SET_DASHBOARD_DATA,
  DELETE_COAPPLICANT,
  DELETE_GUARANTOR,
  UPDATE_NEW_CASE_2,
  UPDATE_NEW_CASE,
  SALES_ON_GOING_CASES,
  GET_USER_OCR_RESULT2,
  RUN_NEW_OCR,
  CLOSED_CASES,
  CLOSED_CASES_SUCCESS,
  CLOSED_CASES_FAILED,
  CIBIL_CITIES,
  SET_CIBIL_CITIES,
  DELETE_OCR_DOCUMENT,
  SET_USER_PERMISSIONS,
} from "../constants/ActionTypes";
import { errorMessage, successMessage } from "../../helpers/utils";
import {
  userLogin,
  forgotPasswordAPI,
  resetPasswordAPI,
  getCaseMastersAPI,
  getNewCasesAPI,
  getCaseDetailsAPI,
  addNewCaseAPI,
  uploadOCRDocumensAPI,
  uploadNonOCRDocumensAPI,
  getCitiesAPI,
  getStatesAPI,
  updateMainApplicantAPI,
  updateCoApplicantAPI,
  updateGuarantorAPI,
  runOcrAPI,
  getOnGoingCasesAPI,
  addParticipantAPI,
  deleteNonOcrDocumentAPI,
  getParticipantDetailAPI,
  runKycCheckAPI,
  getDashboardDataAPI,
  deleteCoapplicantAPI,
  deleteGuarantorAPI,
  getSalesOnGoingCasesAPI,
  getUserOcrResultAPI,
  getClosedCasesAPI,
  getCibilCitiesAPI,
  ssoLoginAPI,
  deleteOcrDocumentAPI,
  logoutUserAPI,
} from "../apis/Api";
function* loginUser(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(userLogin, action.payload);
    if (response.status === 200 && response.data) {
      yield put({ type: LOGIN_SUCCESS, userData: response.data });
    }
    yield put({ type: HIDE_LOADING });
    action.onSuccess({ data: response.data });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    // yield put({ type: LOGIN_FAILED });
  }
}
function* ssoLogin(action) {
  console.log("action: ", action);
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(ssoLoginAPI, action.payload);
    console.log("response: ", response);
    if (response.status === 200 && response.data) {
      yield put({ type: LOGIN_SUCCESS, userData: response.data });
    }
    yield put({ type: HIDE_LOADING });
    action.onSuccess({ data: response.data });
  } catch (error) {
    console.log("error: ", error);

    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    action.onError();
    // yield put({ type: LOGIN_FAILED });
  }
}

function* forgotPassword(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(forgotPasswordAPI, action.payload);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      successMessage(response.data.message);
    }
    action.onSuccess();
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* logoutUser(action) {
  try {
    yield put({ type: SHOW_LOADING });
    // const userData = yield call(logoutUserAPI);
    yield put({ type: LOGOUT_SUCCESS });
    yield put({ type: HIDE_LOADING });
    action.onSuccess();
  } catch (error) {
    console.log("error: ", error);
    // errorMessage(error);
    yield put({ type: HIDE_LOADING });

    action.onError();
  }
}

function* resetPassword(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const userData = yield call(resetPasswordAPI, action.payload);
    toast.success(userData.data.message);
    yield put({ type: HIDE_LOADING });
    action.onSuccess();
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

// function* resetPassword(action) {
//
//   try {
//     yield put({ type: SHOW_LOADING });
//     //
//     const response = yield call(resetPasswordAPI, action.payload);
//     yield put({ type: HIDE_LOADING });
//     action.onSuccess();
//   } catch (error) {
//     errorMessage(error);
//     yield put({ type: HIDE_LOADING });
//   }
// }
function* getCaseMasters(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getCaseMastersAPI);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      action.onSuccess(response.data.data);
      yield put({ type: SET_CASE_MASTERS, data: response.data.data });
    }
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    // yield put({ type: LOGIN_FAILED });
  }
}
function* getUserCases(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getNewCasesAPI, action.payload);

    if (response.data) {
      yield put({ type: SET_CASES, data: response.data.data });
    }
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    errorMessage(error);

    yield put({ type: HIDE_LOADING });
    // yield put({ type: LOGIN_FAILED });
  }
}
function* getOnGoingCases(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getOnGoingCasesAPI, action.payload);

    if (response.data) {
      yield put({ type: SET_CASES, data: response.data.data });
    }
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    // yield put({ type: LOGIN_FAILED });
  }
}
function* getSalesOnGoingCases(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getSalesOnGoingCasesAPI, action.payload);

    if (response.data) {
      yield put({ type: SET_CASES, data: response.data.data });
    }
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    // yield put({ type: LOGIN_FAILED });
  }
}
function* getCaseDetails(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getCaseDetailsAPI, action.payload);

    yield put({ type: HIDE_LOADING });
    if (response.data) {
      yield put({ type: SET_CASE_DETAIL, caseDetail: response.data.data });
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    errorMessage(error);
    if (error.response && error.response.status === 400 && action.onError) {
      action.onError();
    } else if (error.response && error.response.status === 401) {
      yield put({ type: LOGIN_FAILED });
    }
  }
}
function* addNewCase(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(addNewCaseAPI, action.payload);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      if (action.isSaveAndNextClicked) {
        yield put({ type: NEW_CASE_CREATED, data: response.data.data });
        yield put({ type: CURRENT_CASE, data: response.data.data });
      }
      successMessage(response.data.message);
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    errorMessage(error);

    if (error.response && error.response.status === 401) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* uploadOCRDocuments(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(uploadOCRDocumensAPI, action.payload);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data.data);
    }
    // action.onSuccess(response.data);
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* uploadNonOCRDocuments(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(uploadNonOCRDocumensAPI, action.payload);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data);
    }
    // action.onSuccess(response.data);
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* changeCurrentTab(action) {
  try {
    yield put({ type: SHOW_LOADING });
    yield put({ type: SET_CURRENT_TAB, tab: action.payload });
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* getCities(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getCitiesAPI, action.stateId);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      yield put({ type: SET_CITIES, cities: response.data.data });
      action.onSuccess(response.data.data);
    }
    // action.onSuccess(response.data);
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* getCibilCities(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getCibilCitiesAPI, action.stateId);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      yield put({ type: SET_CIBIL_CITIES, cibilcities: response.data.data });
      // action.onSuccess(response.data.data);
    }
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* getStates(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getStatesAPI, action.stateId);

    if (response.data) {
      yield put({ type: SET_STATES, states: response.data.data });
    }
    yield put({ type: HIDE_LOADING });
    // action.onSuccess(response.data);
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* updateMainApplicant(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(updateMainApplicantAPI, action.data);
    yield put({ type: HIDE_LOADING });

    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* updateCoApplicant(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(updateCoApplicantAPI, action.data);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* updateGuarantor(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(updateGuarantorAPI, action.data);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* addParticipant(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(addParticipantAPI, action.data);
    yield put({ type: HIDE_LOADING });

    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    errorMessage(error);

    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* runOcr(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(runOcrAPI, action.data);
    yield put({ type: HIDE_LOADING });

    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data);
    }
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* runNewOCR(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(runOcrAPI, action.data);
    yield put({ type: HIDE_LOADING });

    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data);
    }
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* deleteNonOcrDocument(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(deleteNonOcrDocumentAPI, action.data);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess();
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });

    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
  }
}
function* deleteOcrDocument(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(deleteOcrDocumentAPI, action.data);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess();
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });

    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
  }
}

function* getParticipantDetail(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getParticipantDetailAPI, action.data);
    yield put({ type: HIDE_LOADING });

    if (response.data) {
      // successMessage(response.data.message);
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* runKycCheck(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(runKycCheckAPI, action.data);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      successMessage(response.data.message);
      action.onSuccess(response.data.result);
    }
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* getDashboardData(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getDashboardDataAPI, action.data);

    if (response.data) {
      yield put({ type: SET_DASHBOARD_DATA, data: response.data.data });
      // successMessage(response.data.message);
      // action.onSuccess(response.data.result);
    }
    yield put({ type: HIDE_LOADING });
  } catch (error) {
    errorMessage(error);
    if (
      error.response &&
      error.response.status &&
      error.response.status === 401
    ) {
      yield put({ type: LOGIN_FAILED });
    }
    yield put({ type: HIDE_LOADING });
  }
}
function* deleteCoapplicant(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(deleteCoapplicantAPI, action.id);

    yield put({ type: HIDE_LOADING });

    action.onSuccess();
    if (response.data) {
      successMessage(response.data.message);
    }
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* deleteGuarantor(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(deleteGuarantorAPI, action.id);
    yield put({ type: HIDE_LOADING });
    action.onSuccess();
    if (response.data) {
      successMessage(response.data.message);
    }
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* getClosedCases(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getClosedCasesAPI, action.payload );
    yield put({ type: HIDE_LOADING });
    yield put({ type: CLOSED_CASES_SUCCESS, closedCases: response.data.data });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    yield put({ type: CLOSED_CASES_FAILED });
  }
}
function* updateNewCaseCreated(action) {
  try {
    yield put({ type: UPDATE_NEW_CASE, data: action.data });
    action.onSuccess();
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* getUserOcrResult(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getUserOcrResultAPI, action.data);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* setUserOcrPersmissions(action) {
  try {
    yield put({ type: SHOW_LOADING });
    yield put({ type: SET_PERMISSION_DATA ,data:action.data});
    yield put({ type: HIDE_LOADING });
    action.onSuccess();
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* actionLogin() {
  yield takeLatest(LOGIN, loginUser);
}
function* actionLogout() {
  yield takeLatest(LOGOUT, logoutUser);
}
function* actionForgot() {
  yield takeLatest(FORGOT, forgotPassword);
}
// function* actionResetPassword() {
//   yield takeLatest(RESET, resetPassword);
// }
function* actionResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPassword);
}
function* actionCaseMasters() {
  yield takeLatest(CASE_MASTERS, getCaseMasters);
}
function* actionUserCases() {
  yield takeLatest(CASES, getUserCases);
}
function* actionOnGoingCases() {
  yield takeLatest(ON_GOING_CASES, getOnGoingCases);
}
function* actionOnGetClosedCases() {
  yield takeLatest(CLOSED_CASES, getClosedCases);
}
function* actionSSOLogin() {
  console.log("SSO_LOGIN: ", SSO_LOGIN);

  yield takeLatest(SSO_LOGIN, ssoLogin);
}

function* actionOnSalesGoingCases() {
  yield takeLatest(SALES_ON_GOING_CASES, getSalesOnGoingCases);
}
function* actionCaseDetails() {
  yield takeLatest(CASE_DETAIL, getCaseDetails);
}
function* actionAddNewCase() {
  yield takeLatest(ADD_NEW_CASE, addNewCase);
}
function* actionUploadOCRDocuments() {
  yield takeLatest(UPLOAD_OCR_DOCUMETS, uploadOCRDocuments);
}
function* actionRunOcr() {
  yield takeLatest(RUN_OCR, runOcr);
}
function* actionRunNewOCR() {
  yield takeLatest(RUN_NEW_OCR, runNewOCR);
}
function* actionUploadNonOCRDocuments() {
  yield takeLatest(UPLOAD_NON_OCR_DOCUMETS, uploadNonOCRDocuments);
}
function* actionChangeCurrentTab() {
  yield takeMaybe(SET_CURRENT_TAB, changeCurrentTab);
}
function* actionGetStates() {
  yield takeLatest(STATES, getStates);
}
function* actionGetCities() {
  yield takeLatest(CITIES, getCities);
}

function* actionGetCibilCities() {
  yield takeLatest(CIBIL_CITIES, getCibilCities);
}

function* actionUpdateMainApplicant() {
  yield takeLatest(UPDATE_MAIN_APPLICANT, updateMainApplicant);
}
function* actionUpdateCoApplicant() {
  yield takeLatest(UPDATE_CO_APPLICANT, updateCoApplicant);
}
function* actionUpdateGuarantor() {
  yield takeLatest(UPDATE_GUARANTOR, updateGuarantor);
}
function* actionAddParticipant() {
  yield takeLatest(ADD_PARTICIPANT, addParticipant);
}
function* actionDeleteNonOcrDocument() {
  yield takeLatest(DELETE_NON_OCR_DOCUMENT, deleteNonOcrDocument);
}
function* actionDeleteOcrDocument() {
  yield takeLatest(DELETE_OCR_DOCUMENT, deleteOcrDocument);
}
function* actionGetParticipantDetail() {
  yield takeLatest(GET_PARTICIPANT_DETAIL, getParticipantDetail);
}
function* actionRunKyc() {
  yield takeLatest(RUN_KYC, runKycCheck);
}
function* actionDashboardData() {
  yield takeLatest(DASHBOARD, getDashboardData);
}
function* actionDeleteCoapplicant() {
  yield takeLatest(DELETE_COAPPLICANT, deleteCoapplicant);
}
function* actionDeleteGuarantor() {
  yield takeLatest(DELETE_GUARANTOR, deleteGuarantor);
}
function* actionUpdateNewCase() {
  yield takeLatest(UPDATE_NEW_CASE_2, updateNewCaseCreated);
}
function* actionGetUserOcrResult() {
  yield takeLatest(GET_USER_OCR_RESULT2, getUserOcrResult);
}
function* actionSetUserPersmissions() {
  yield takeLatest(SET_USER_PERMISSIONS, setUserOcrPersmissions);
}

export default function* authSaga() {
  yield all([
    fork(actionLogin),
    fork(actionLogout),
    fork(actionForgot),
    fork(actionResetPassword),
    fork(actionCaseMasters),
    fork(actionUserCases),
    fork(actionOnGoingCases),
    fork(actionOnSalesGoingCases),
    fork(actionCaseDetails),
    fork(actionAddNewCase),
    fork(actionUploadOCRDocuments),
    fork(actionUploadNonOCRDocuments),
    fork(actionChangeCurrentTab),
    fork(actionGetStates),
    fork(actionGetCities),
    fork(actionUpdateMainApplicant),
    fork(actionUpdateCoApplicant),
    fork(actionUpdateGuarantor),
    fork(actionRunOcr),
    fork(actionAddParticipant),
    fork(actionDeleteNonOcrDocument),
    fork(actionDeleteOcrDocument),
    fork(actionGetParticipantDetail),
    fork(actionRunKyc),
    fork(actionDashboardData),
    fork(actionDeleteCoapplicant),
    fork(actionDeleteGuarantor),
    fork(actionUpdateNewCase),
    fork(actionGetUserOcrResult),
    fork(actionRunNewOCR),
    fork(actionOnGetClosedCases),
    fork(actionGetCibilCities),
    fork(actionSSOLogin),
    fork(actionSetUserPersmissions),
  ]);
}
