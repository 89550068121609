import React, { Component, useEffect } from "react";
import { Tabs, Card, Row, Col } from "antd";
import DetailDocform from "../../detaildocform";
import MainApplicantFormKyc from "../../kycreport/mainapplicantform";
import BureauReport from "../../bureaureport";
import ReportRunSummary from "../../bureaureport/report-run-summary";
import ReportTab from "../../bureaureport/reports-tab";
import { useDispatch, useSelector } from "react-redux";
import { onParticipantDetail } from "../../../../redux/actions/ParticipantAction";
import { onMemberDirectorDetail } from "../../../../redux/actions/MemberAndDirectorAction";
import { onChangeSelectedUser } from "../../../../redux/actions/AuthAction";
import { onChangeThirdTabKey } from "../../../../redux/actions/TabAction";
import { CATEGORY } from "../../../../constants/constants";
import CorporateAndHUFDetailDocForm from "../../detaildocform/CorporateAndHUFDetailDocForm";
const { TabPane } = Tabs;
const ThirdTab = (props) => {
  const { type, applicant_id, applicant_type } = props;
  const thirdTabKey = useSelector((state) => state.tabReducer.thirdTabKey);
  const selectedBureauReport = useSelector(
    (state) => state.bureauReport.selectedBureauReport
  );
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  const secondTabKey = useSelector((state) => state.tabReducer.secondTabKey);
  const selectedUser = useSelector((state) => state.auth.selectedUser);
  

  const dispatch = useDispatch();
  useEffect(() => {
    // window.scrollTo(0, 700);
    dispatch(onChangeThirdTabKey("1"));
  }, [dispatch]);

  const changeThirdTab = (tab) => {
    dispatch(onChangeThirdTabKey(tab));
  };
  // useEffect(()=>{
  //   if(){

  //   }
  // },[thirdTabKey])
  return (
    <React.Fragment>
      <div className="InnerTabsWrap">
        <Card>
          <Tabs activeKey={thirdTabKey} onChange={changeThirdTab}>
            <TabPane tab="Details & documents" key="1">
              {secondTabKey == "1" &&
              selectedUser &&
              selectedUser.fk_category_id &&
              (selectedUser.fk_category_id.toString() === CATEGORY.CORPORATE ||
                selectedUser.fk_category_id.toString() === CATEGORY.HUF ||
                selectedUser.fk_category_id.toString() ===
                  CATEGORY.PROPRIETOR) ? (
                <CorporateAndHUFDetailDocForm />
              ) : (
                selectedUser &&
                 <DetailDocform />
              )}
              {/*<DetailDocform />/^[0-9]{14}$/ cin_number*/}
            </TabPane>
            <TabPane tab="KYC" key="2">
              {selectedUser && <MainApplicantFormKyc />}
            </TabPane>
            <TabPane tab="Bureau Reports" key="3">
              {/* <Guarantorform /> */}
              <div className="BureauReportWrap">
                <div className="InnerTabsWrap">
                  {selectedUser && <ReportRunSummary />}
                  {selectedUser && selectedBureauReport && <ReportTab />}
                </div>
              </div>
              {/*<BureauReport />*/}
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default ThirdTab;
