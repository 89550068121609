import React, { Component, useEffect,useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { onCaseDetail } from "../redux/actions/CaseAction";
import { onFetchBureauReportHtml } from "../redux/actions/BureauReportAction";
import { Button ,Result,Space,Spin} from "antd";

const DownloadBureauReport = (props)=> {
  const params = useParams();
  const dispatch = useDispatch();
  const [htmlreport, sethtmlreport] = useState({});

  useEffect(() => {
    dispatch(onFetchBureauReportHtml(params.id,onSuccess))
    
  },[dispatch]);
  const onSuccess=(response)=>{

    sethtmlreport(response)
    document.title = response.title;
  }
  const openPrint=()=>{
    var printButton = document.getElementById("printpagebutton");
    printButton.style.visibility = 'hidden';
    window.print();
    printButton.style.visibility = 'visible';

  }

    return (
        <>
        
        <div className='InnerWrap' id="cibilreport">
          { htmlreport && htmlreport.response_html &&(
           <>
           <div className="buttonPosition">
           <Button type="primary" className="print" id="printpagebutton" onClick={()=>{openPrint()}}>Download</Button>
           </div>
           <div className="pdfwrap" dangerouslySetInnerHTML={{ __html: htmlreport.response_html }} ></div>
           </>
          )}
        
       </div>
        </>
      )
}
export default DownloadBureauReport;