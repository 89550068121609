import React, { useEffect, useState } from "react";
import {
  Modal,Button
} from "antd";

const ConfirmModal = (props) => {
  const { openConfirm, handleCancelConfirm, handleOk ,confimMessage} = props;
  return (
    <>
      <Modal
        visible={openConfirm}
        onCancel={handleCancelConfirm}
        className="KycCheck"
        onOk={handleOk}
        destroyOnClose={true}
        footer={[
          <Button key="back" onClick={handleCancelConfirm}>
            No
          </Button>,
          <Button  type="primary"  onClick={handleOk}>
            Yes
          </Button>
        ]}
      >
        <p className="confirmationtext">
         {confimMessage}
        </p>
      </Modal>
    </>
  );
};

export default ConfirmModal;
