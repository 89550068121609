import React, { Component } from "react";
import { Card, Row, Col } from "antd";

class LoanDetail extends Component {
  render() {
    return (
      <>
        <div className="CardDetailWrap">
          <h3>Product and Loan Detail</h3>

          <Card>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Business Area</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Eicher Trucks</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Product Type</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Heavy Duty Trucks</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Product Name</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Tipper</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Product Model No.</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Pro 6019T</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Type of financing</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Loan</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Loan Amount Required</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>15,00,000</p>
              </Col>
            </Row>
          </Card>
        </div>
      </>
    );
  }
}
export default LoanDetail;
