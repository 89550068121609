import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  addRequestCreditReviewAPI,
  getRequestCommentAPI,
  getuserOcrResultAPI,
  addCommentByCreditAPI,
  getJustificationCommentAPI,
  addJustificationCommentAPI,
  getCaseReallocationAPI,
  getSalesCreditUsersForLocationAPI,
  getCaseApproveRejectAPI,
  getsystemRecommendationAPI,
  getCaseDetailExcelAPI
} from "../apis/Api";
import { toast } from "react-toastify";
import {
  HIDE_LOADING,
  SHOW_LOADING,
  ADD_REQUEST_REVIEW,
  LOGIN_FAILED,
  GET_REQUEST_COMMENTS,
  GET_REQUEST_COMMENTS_FAILED,
  GET_REQUEST_COMMENTS_SUCCESS,
  ADD_COMMENT_BY_CREDIT,
  GET_JUSTIFICATION_COMMENTS,
  GET_JUSTIFICATION_COMMENTS_SUCCESS,
  GET_JUSTIFICATION_COMMENTS_FAILED,
  ADD_JUSTIFICATION_COMMENTS,
  GET_USER_OCR_RESULT,
  GET_USER_OCR_RESULT_SUCCESS,
  GET_USER_OCR_RESULT_FAILED,
  GET_USER_BY_STATE_CITY,
  CASE_REALLOCATION,
  CASE_REJECT_APPROVE,
  SYSTEM_RECOMMENDATION,
  GET_CASE_DETAIL_EXCEL,
} from "../constants/ActionTypes";
import { errorMessage } from "../../helpers/utils";

function* addRequestReview(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(addRequestCreditReviewAPI, action.payload);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      toast.success(response.data.message);
      action.onSuccess(response.data.data);
    }
  } catch (error) {
    yield put({ type: HIDE_LOADING });
    errorMessage(error);
  }
}

function* actionRequestCreditReview() {
  yield takeLatest(ADD_REQUEST_REVIEW, addRequestReview);
}

function* getRequestComment(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getRequestCommentAPI, action.payload);
    yield put({ type: HIDE_LOADING });
    yield put({
      type: GET_REQUEST_COMMENTS_SUCCESS,
      pendingCases: response.data.data,
    });
    if (action.onSuccess) {
      action.onSuccess();
    }
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    yield put({ type: GET_REQUEST_COMMENTS_FAILED });
  }
}

function* actionRequestComment() {
  yield takeLatest(GET_REQUEST_COMMENTS, getRequestComment);
}

function* getJustificationComment(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getJustificationCommentAPI, action.payload);
    yield put({ type: HIDE_LOADING });
    yield put({
      type: GET_JUSTIFICATION_COMMENTS_SUCCESS,
      pendingCases: response.data.data,
    });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    yield put({ type: GET_JUSTIFICATION_COMMENTS_FAILED });
  }
}

function* actionJustificationComment() {
  yield takeLatest(GET_JUSTIFICATION_COMMENTS, getJustificationComment);
}

function* addCommentByCredit(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const userData = yield call(addCommentByCreditAPI, action.payload);
    toast.success(userData.data.message);
    yield put({ type: HIDE_LOADING });
    action.onSuccessreturn();
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* actionCommentByCredit() {
  yield takeLatest(ADD_COMMENT_BY_CREDIT, addCommentByCredit);
}

function* addAddJustificationComment(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(addJustificationCommentAPI, action.payload);
    if (response.data && response.data.status) {
      toast.success(response.data.message);
    } else {
      toast.error(response.data.message);
    }
    yield put({ type: HIDE_LOADING });
    action.onSuccess(response.data.data);
  } catch (error) {
    yield put({ type: HIDE_LOADING });

    errorMessage(error);
    action.onSuccess();
  }
}

function* actionAddJustificationComment() {
  yield takeLatest(ADD_JUSTIFICATION_COMMENTS, addAddJustificationComment);
}

function* getuserOcrResult(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getuserOcrResultAPI, action.payload);
    yield put({ type: HIDE_LOADING });
    yield put({
      type: GET_USER_OCR_RESULT_SUCCESS,
      userOcrResult: response.data.data,
    });
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
    yield put({ type: GET_USER_OCR_RESULT_FAILED });
  }
}

function* actionUserOcrResult() {
  yield takeLatest(GET_USER_OCR_RESULT, getuserOcrResult);
}

function* getUserByStateCity(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getSalesCreditUsersForLocationAPI);
    yield put({ type: HIDE_LOADING });
    action.onSuccessUsers(response.data.data);
  } catch (error) {
    console.log(error, "error");

    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* onCaseDetailExcel(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getCaseDetailExcelAPI, action.data);
    yield put({ type: HIDE_LOADING });
    action.onSuccess(response.data.file);
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* actionUserByStateCity() {
  yield takeLatest(GET_USER_BY_STATE_CITY, getUserByStateCity);
}
function* actionCaseDetailExcel() {
  yield takeLatest(GET_CASE_DETAIL_EXCEL, onCaseDetailExcel);
}
function* onCaseReallocation(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getCaseReallocationAPI, action.data);
    yield put({ type: HIDE_LOADING });
    toast.success(response.data.message);
    action.onSuccessApi(response.data.data);
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* actionCaseReallocation() {
  yield takeLatest(CASE_REALLOCATION, onCaseReallocation);
}

function* onCaseApproveReject(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getCaseApproveRejectAPI, action.data);
    yield put({ type: HIDE_LOADING });
    toast.success(response.data.message);
    action.onSuccess(response.data.data);
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* actionCaseApproveReject() {
  yield takeLatest(CASE_REJECT_APPROVE, onCaseApproveReject);
}

function* onSystemRecommendation(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getsystemRecommendationAPI, action.data);
    yield put({ type: HIDE_LOADING });
    // toast.success(response.data.message);
    action.onSuccessCheck(response.data);
  } catch (error) {
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* actionSystemRecommendation() {
  yield takeLatest(SYSTEM_RECOMMENDATION, onSystemRecommendation);
}

export default function* authSaga() {
  yield all([
    fork(actionRequestCreditReview),
    fork(actionRequestComment),
    fork(actionCommentByCredit),
    fork(actionJustificationComment),
    fork(actionAddJustificationComment),
    fork(actionUserOcrResult),
    fork(actionUserByStateCity),
    fork(actionCaseReallocation),
    fork(actionCaseApproveReject),
    fork(actionSystemRecommendation),
    fork(actionCaseDetailExcel),
  ]);
}
