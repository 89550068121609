import {
    CREDIT_USER_SUCCESS,
    CREDIT_USER_FAILED
} from "../constants/ActionTypes";

const initState = {
    creditUser: []
}
export default function course(state = initState, action) {
    switch (action.type) {
        case CREDIT_USER_SUCCESS:
            return {...state, creditUser: action.creditUser};
        case CREDIT_USER_FAILED:
            return {...state, creditUser: []};
     
        default:
            return state
    }
}