import {combineReducers} from 'redux'
import auth from './AuthReducer'
import fleet from './FleetAnalysisReducer'

import common from './CommonReducer'
import pendingcases from './PendingCasesReducer'
import categoryAndSubCategory from "./CategoryAndSubCategoryReducer";
import ocrAndNonOcrReducer from "./OcrAndNonOcrReducer";
import caseReducer from './CaseReducer'
import modalReducer from "./ModalReducer";
import tabReducer from './TabReducer'
import creditUser from './CreditUserReducer'
import bureauReport from './BureauReportReducer'
import notifications from './NotificationReducer'
import hunter from './HunterReducer'
import fieldInvestigation from './FieldInvestigationReducer'
import vaahan from './VaahanReducer'


export default combineReducers({
    auth,
    common,
    pendingcases,
    categoryAndSubCategory,
    ocrAndNonOcrReducer,
    caseReducer,
    modalReducer,
    tabReducer,
    creditUser,
    bureauReport,
    notifications,
    fleet,
    hunter,
    fieldInvestigation, 
    vaahan
})
