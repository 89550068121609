import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Form, Row, Select, Input, Modal, Upload, message } from "antd";
import { toast } from "react-toastify";
import { RunRequestBoxModel } from "..//..//RequestModels/RunRequestBoxModel";

export default function CoApplicantDetails(viewlabel) {
  const caseData = useSelector((state) => state.fieldInvestigation.fiCase);
  const [form] = Form.useForm();
  const [alertBoxVisible, setalertBoxVisible] = useState(false);
  const [caseRow, setCaseRow] = useState("");

  const sendRequest = (e) => {
    setCaseRow(e);
    setalertBoxVisible(true);
  };

  const deleteFieldModelCancel = () => {
    setalertBoxVisible(false);

  };

  return (
    <>
      {caseData?.co_applicants.length === "undefined" ? <>
        <div className="card card-body">
          <h3 style={{ textAlign: "center" }}>No Co-Applicants</h3>
        </div>
      </> :
        caseData?.co_applicants.length === 0 ? <>
          <div className="card card-body">
            <h3 style={{ textAlign: "center" }}>No Co-Applicants</h3>
          </div>
        </> :
          <div className="card card-body">
            {caseData?.co_applicants?.map((val, index) => {
              return (
                <>
                  <h5>Co Applicant {index + 1}</h5>
                  <br />
                  <Row gutter={24}>
                    <Col span={6} md={6} xs={16} sm={16}>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">First Name</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.first_name ? val.first_name : "-"}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">Mobile No</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.mobile_no ? val.mobile_no : "-"}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">City</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.city ? val.city : "-"}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">Relation</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>
                            {val.coapplicant_applicant_relation
                              ? val.coapplicant_applicant_relation
                              : "-"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6} md={6} xs={16} sm={16}>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">Last Name</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.last_name ? val.last_name : "-"}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">Gender</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.gender ? val.gender : "-"}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">Postal Code</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.pin ? val.pin : "-"}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">Pan No.</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.pan_no ? val.pan_no : "-"}</p>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6} md={6} xs={16} sm={16}>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">
                            Date of Birth
                          </p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.dob ? val.dob : "-"}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">State</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>{val.state ? val.state : "-"}</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={12} xs={24} sm={12}>
                          <p className="font-weight-bold">Address</p>
                        </Col>
                        <Col span={12} xs={24} sm={12}>
                          <p>
                            {val.address_line1 ? val.address_line1 : "-"}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={6} md={6} xs={16} sm={16}>
                      <h6 className="font-weight-bold ">Documents</h6>
                      {val?.kyc_result.map((value) => {
                        return (
                          <>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">
                                  {value.document_name}
                                </p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{value.identity_no ? value.identity_no : "-"}</p>
                              </Col>
                            </Row>
                          </>
                        );
                      })}
                    </Col>

                    <Col span={6} md={6} xs={16} sm={16}>
                      {val?.fi_status === 0 && <Button
                        className="AddField"
                        //setCaseId={row.case_id}
                        title="Send Request"
                        style={{ marginRight: "-950px", float: "right" }}
                        onClick={e => sendRequest([caseData.id, val.id,
                        val.applicant_type, "1", val.first_name, viewlabel, ( index)])}
                      >
                        Send Request
                      </Button>
                      }
                      {(val?.fi_status === 1  || val?.fi_status === 2 ) && <Button
                        className="AddField"
                        //setCaseId={row.case_id}
                        title="Re-send Request"
                        //disabled
                        style={{ marginRight: "-950px", float: "right" }}
                        onClick={e => sendRequest([caseData.id, val.id,
                        val.applicant_type, "2", val.first_name, viewlabel,( index)])}
                      >
                        Re-send Request
                      </Button>}
                      {val?.fi_status === 3   && <p
                        title="Not Started"
                        style={{ marginRight: "-950px", float: "right", color: "green", fontSize: "20px" }}
                      >
                        Completed
                      </p>
                      }
                    </Col>
                  </Row>
                  {" "}
                </>
              );
            })}

            <RunRequestBoxModel deleteVisible={alertBoxVisible}
              deleteCancel={deleteFieldModelCancel}
              caseRow={caseRow}
              caseStatus={caseRow[3]}
              name={caseRow[4]}
              viewlabel={caseRow[5]}
              index ={caseRow[6]}
              closeModal={() => {
                setalertBoxVisible(false);
              }}
            />
          </div>

      }
    </>
  )
}
