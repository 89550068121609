import { CASE_MASTERS, CITIES, STATES,CIBIL_CITIES } from "../constants/ActionTypes";

/**
 * @method onCaseMasters
 * @description get all static case masters data
 * @param {*} onSuccess
 * @returns
 */
 export const onCaseMasters = (onSuccess) => ({
    type: CASE_MASTERS,
    onSuccess,
  });
  /**
 * @method onGetStates
 * @description get all states
 * @param {*} onSuccess
 * @returns
 */
  export const onGetStates = (onSuccess) => {
    return {
      type: STATES,
    };
  };
  /**
 * @method onGetCities
 * @description get cities by state id
 * @param {*} onSuccess
 * @returns
 */
  export const onGetCities = (stateId,onSuccess) => {
    return {
      type: CITIES,
      stateId,
      onSuccess
    };
  };

   /**
 * @method onGetCities
 * @description get cities by state id and bureau name
 * @param {*} onSuccess
 * @returns
 */
    export const onGetCibilCities = (stateId) => {
      return {
        type: CIBIL_CITIES,
        stateId,
      };
    };
  