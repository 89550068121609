import React, { useState, useEffect } from "react";
import { Row, Menu, Col, Button, Drawer, Dropdown } from "antd";
//import siteLogo from "../../assets/images/logolarge.png";
import siteLogo from "../../assets/images/logo-new.png";
import SignoutIcon from "../../assets/images/log-out.png";
import thumbnailuser from "../../assets/images/john.png";
import { NavLink, Link } from "react-router-dom";
import { MenuOutlined, DownOutlined, LogoutOutlined } from "@ant-design/icons";
import PlusIcon from "../../assets/images/plus.svg";
import { useDispatch, connect, useSelector } from "react-redux";
// import { onLogout, onResetNewCaseCreated } from "../../redux/actions/AuthAction";
import { useHistory } from "react-router";
import Modal from "antd/lib/modal/Modal";
import { loggedInUserData } from "../../helpers/utils";
import { onResetNewCaseCreated } from "../../redux/actions/CaseAction";
import { onLogout } from "../../redux/actions/AuthAction";
import { BellOutlined, UserOutlined, EyeOutlined } from "@ant-design/icons";
import { Badge, Avatar } from "antd";
import PageLoading from '../../components/PageLoading'
import {
  getNotifications,
  readNotification,
} from "../../redux/actions/NotificationAction";
import axios from "axios";
import { SSO_LOGIN_QA, SSO_LOGOUT_PRODUCTION, SSO_LOGOUT_QA } from "../../utils/constants";

const TopBar = (props) => {
 
  const dispatch = useDispatch();
  const { isNewlyCreatedCaseSaved } = props;
  const newlyCreatedCase = useSelector((state) => state.auth.newlyCreatedCase);
  const userPermissions = useSelector((state) => state.auth.userPermissions);
  
  const [caseResetConfirmation, setCaseResetConfirmation] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState("");
  // const userDetail = loggedInUserData() || {};
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  // 
  const { userData = {} } = props;
  
  const history = useHistory();
  const onSuccess = () => {
    dispatch(getNotifications());
  };
  const onReadMessage = (notification) => {
    dispatch(readNotification(notification.id, onSuccess));
  };
  // const [isNotificationsDropdownVisible,setNotificationDropdownVisibilty] = useState(false)
  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch]);
  let menus = {};
  menus =
    notifications &&
    notifications.notifications &&
    notifications.notifications.map((notification, i) => {
      // 
      return (
        <Menu.Item
          key={notification.id}
          className={
            notification.is_read === 1
              ? "read notifydetail"
              : "unread notifydetail"
          }
          multiple
          style={{ cursor: "auto" }}
          // className='notifydetail'
          onClick={() => {
            if (!notification.is_read) {
              onReadMessage(notification);
            }
          }}
        >
          <Link to={`/viewOnGoingcase/${notification.case_id}`}>
            {" "}
            <h6 className="mb-0">{notification.title}</h6>
            <div >
              {" "}
              <small>{notification.msg}</small>
            </div>
            <div  className='DaysAgoText'>
              <small >{notification.hour_ago}</small>
            </div>
          </Link>
        </Menu.Item>
      );
    });
  const profileMenu = (
    <Menu>
      <Menu.Item key="0">
        {/* <Avatar
          shape="square"
          src={thumbnailuser}
          size="large"
          icon={<UserOutlined />}
        /> */}
        {userData?.first_name} {userData?.last_name}({userData?.user_type}-
        {userData?.group_name})
      </Menu.Item>
      {/* <Menu.Divider />
      <Menu.Item key="1">
        <a>Account & Settings</a>
        </Menu.Item>

      <Menu.Item key="3">3rd menu item</Menu.Item> */}
    </Menu>
  );
  const menu = (
    <React.Fragment>
      <Menu
        className="NotificationBox"
        selectedKeys={selectedNotification}
        onClick={(e) => {}}
      >
        {menus}
      </Menu>
      {notifications &&
      notifications.notifications &&
      notifications.notifications &&
      notifications.notifications.length > 0 ? (
        <Link to="/notifications" className="viewallnotification">
          See All
        </Link>
      ) : (
        <div className="noNotify">No New Notifications</div>
      )}
    </React.Fragment>
  );
  const logout = () => {
    dispatch(
      onLogout(() => {
        localStorage.removeItem("token");
          localStorage.removeItem("userInfo");
        // window.location.href= SSO_LOGOUT_PRODUCTION
        // window.location.href=SSO_LOGOUT_QA
       },()=>{
        localStorage.removeItem("token");
        localStorage.removeItem("userInfo");
        window.location.reload();
        // window.location.href= SSO_LOGOUT_PRODUCTION
        // window.location.href=SSO_LOGOUT_QA

      })
    );
    // history.push("/login");
  };
  const MobileNav = () => {
    const [visible, setVisible] = useState(false);
    const showDrawer = () => {
      setVisible(true);
    };
    const onClose = () => {
      setVisible(false);
    };
    const confirmReset = () => {
      
      if (newlyCreatedCase) {
        setCaseResetConfirmation(true);
      }
    };
    const cancelCaseReset = () => {
      setCaseResetConfirmation(false);
    };
    const resetNewCase = () => {
      dispatch(onResetNewCaseCreated());
      setCaseResetConfirmation(false);
    };
    return (
      <React.Fragment>
        <Button type="primary" onClick={showDrawer}>
          <MenuOutlined />
        </Button>
        {userData && userData.permissions && userData.permissions.Case && userData.permissions.Case.add && <Button className="LogNewCaseMobile" onClick={confirmReset}>
          <NavLink to="/NewCases" activeClassName="active">
            <img src={PlusIcon} alt="" /> Add New Case
          </NavLink>
        </Button>}
        <Drawer
          placement="right"
          closable={false}
          onClose={onClose}
         open={visible}
          className="MobileMenu"
        >
          <div className="logowrap">
            <div className="Branding test">
              <div className="text">
                <div>
                  <img src={siteLogo} alt="logo" />
                </div>
                <div>
                  <h4>Volvo Financial Services</h4>
                  <p>INDIA</p>
                </div>
              </div>
            </div>
          </div>
          <ul mode="horizontal">
            <li>
              <NavLink to="/dashboard"> Home</NavLink>
            </li>
            <li>
              <NavLink to="/new-cases"> New Cases</NavLink>
            </li>
            <li>
              <NavLink to="/OnGoingCases"> On Going Cases</NavLink>
            </li>
            <li>
              <NavLink to="/PendingCreditReview">
                Pending with Credit for Review
              </NavLink>
            </li>
            <li>
              <NavLink to="/to-be-moved-to-los"> To be moved to LOS</NavLink>
            </li>
            <li>
              <NavLink to="/ClosedCases"> Closed Cases</NavLink>
            </li>
            <li>
              <NavLink to="/FleetAnalysis"> Fleet Analysis</NavLink>
            </li>

            {/* created hunter and field invegtigation top bar by yadu  */}
            <li>
              <NavLink  to="/hunter">Hunter</NavLink>
            </li>
            <li>
              <NavLink  to="/field-investigation">Field Investigation</NavLink>
            </li>
            <li>
              <NavLink  to="/vaahan">Vahan</NavLink>
            </li>
            <li>
              <Button onClick={logout} className='BtnSignOut'> SignOut</Button>
            </li>
          </ul>
        </Drawer>
        <Modal
          title={
            <p className="confirmationtext">
              Do you want to leave current process and create a new case?
              <div>(saved information will not be deleted)</div>
            </p>
          }
          centered
          visible={caseResetConfirmation}
          onOk={resetNewCase}
          onCancel={() => cancelCaseReset(false)}
          width={600}
          className="KycCheck"
          style={{ top: 50 }}
        ></Modal>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <div className="NavWrap">
        <div className="MobileNav">
          <MobileNav />
        </div>
      </div>
      <div className="topBarWrap">
        <Row>
          <Col span={12} sm={12} xs={24} className="logowrap">
            {/* <img src={siteLogo} alt="logo" /> */}
            <div className="Branding">
              <div className="text">
                <div>
                  <img src={siteLogo} alt="logo" />
                </div>
                <div>
                  <h4>Volvo Financial Services</h4>
                  <p>INDIA</p>
                </div>
              </div>
            </div>
          </Col>
          
          <Col span={12} sm={12} xs={24}>
          
            <div className="SignOutBlock">
            {userData && userData.is_admin == 1 && <div>
              <a href={userData.admin_login_url} target="_blank" className='LoginAsAdmin'>Login as Admin</a>
              </div>}
              <div className="badgeStyle">
              
                <Dropdown
                  overlay={menu}
                  placement="bottomRight"
                  arrow
                  trigger={["click"]}
                  overlayClassName="notificationboxlayer"
                >
                  <Badge count={notifications.unread_count}>
                    <BellOutlined
                      size="large"
                      style={{ fontSize: "150%", cursor: "pointer" }}
                      // alt='Notifications'
                      title="Notifications"
                    />
                  </Badge>
                </Dropdown>
              </div>
              <div className="UserInfo">
                <p>
                  {/*<img src={thumbnailuser} alt="" />*/}

                  {/*{userData.first_name}{" "}
  {userData.last_name}({userData.user_type})*/}
                </p>
                <Dropdown
                  overlay={profileMenu}
                  trigger={["click"]}
                  arrow
                  placement="bottomCenter"
                  overlayClassName="UserDropdown"
                >
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    <UserOutlined
                      shape="square"
                      // src={thumbnailuser}
                      size="large"
                      // icon={<UserOutlined />}
                      style={{ fontSize: "150%", cursor: "pointer" }}
                      title="Profile"
                    />
                  </a>
                </Dropdown>
              </div>
              <Button title="Sign Out" onClick={logout}>
                {/* <img src={SignoutIcon} alt="" /> */}
                <LogoutOutlined />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isNewlyCreatedCaseSaved: auth.isNewlyCreatedCaseSaved,
    userData: auth.userData ? auth.userData.user_detail : {},
  };
};
export default connect(mapStateToProps, null)(TopBar);
