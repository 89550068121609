import React, { Component, useState } from "react";
import { Button, Menu, Drawer } from "antd";
import PlusIcon from "../../assets/images/plus.svg";
import { Link, NavLink, useHistory } from "react-router-dom";
import { MenuOutlined } from "@ant-design/icons";
import siteLogo from "../../assets/images/logolarge.png";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import {
  isCreditUser,
  isEicherUser,
  isSalesUser,
  isVolvoUser,
  loggedInUserData,
} from "../../helpers/utils";
import { onResetNewCaseCreated } from "../../redux/actions/CaseAction";
import { STATUS } from "../../constants/constants";
// import { onResetNewCaseCreated } from "../../redux/actions/AuthAction";
// const MobileNav = () => {
//   const [visible, setVisible] = useState(false);
//   const showDrawer = () => {
//     setVisible(true);
//   };
//   const onClose = () => {
//     setVisible(false);
//   };
//   return (
//     <>
//       <Button type="primary" onClick={showDrawer}>
//         <MenuOutlined />
//       </Button>
//       <Drawer
//         placement="right"
//         closable={false}
//         onClose={onClose}
//         visible={visible}
//         className="MobileMenu"
//       >
//         <div className="logowrap">
//           <img src={siteLogo} alt="logo" />
//         </div>
//         <ul mode="horizontal">
//           <li>
//             <NavLink to="/dashboard"> Home</NavLink>
//           </li>
//           <li>
//             <NavLink to="/new-cases"> New Cases</NavLink>
//           </li>
//           <li>
//             <NavLink to="/OnGoingCases"> On Going Cases</NavLink>
//           </li>
//           <li>
//             <NavLink to="/PendingCreditReview">
//               Pending with Credit for Review
//             </NavLink>
//           </li>
//           <li>
//             <NavLink to="/dashboard"> To be posted to LOS</NavLink>
//           </li>
//           <li>
//             <NavLink to="/"> SignOut</NavLink>
//           </li>
//         </ul>
//       </Drawer>
//     </>
//   );
// };
const Navigation = (props) => {
  // const {userData} = props
  const [current, setCurrent] = useState("1");
  const [caseResetConfirmation, setCaseResetConfirmation] = useState(false);
  const newlyCreatedCase = useSelector((state) => state.auth.newlyCreatedCase);
  const userData = loggedInUserData();

  const { isNewlyCreatedCaseSaved } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  const confirmReset = () => {
    if (newlyCreatedCase) {
      setCaseResetConfirmation(true);
    }
  };
  const cancelCaseReset = () => {
    setCaseResetConfirmation(false);
  };
  const resetNewCase = () => {
    dispatch(onResetNewCaseCreated());
    setCaseResetConfirmation(false);
  };
  return (
    <React.Fragment>
      <div className="NavWrap DeskNav">
        {/*<div className="MobileNav">
          <MobileNav />
        </div>*/}
        <div className="InnerNavWrap DeskNav">
        
          <Menu
            onClick={handleClick}
            selectedKeys={[current]}
            mode="horizontal"
            
          >
            <Menu.Item key="1">
              <NavLink to="/dashboard" activeClassName="active">
                {" "}
                Home
              </NavLink>
            </Menu.Item>
            {userData && isEicherUser(userData) && (
              <Menu.Item key="2">
                <NavLink
                  to="/new-cases"
                  isActiveactiveClassName="active"
                  isActive={(match, location) => {
                    if (
                      location.pathname === "/new-cases" ||
                      location.pathname.startsWith("/viewcase/")
                    ) {
                      return true;
                    }
                  }}
                >
                  {" "}
                  New Cases
                </NavLink>
              </Menu.Item>
            )}
            <Menu.Item key="3">
              <NavLink
                to="/OnGoingCases"
                activeClassName="active"
                isActive={(match, location) => {
                  if (
                    location.pathname === "/OnGoingCases" ||
                    (location.pathname.startsWith("/viewOnGoingcase/") &&
                      caseDetail.status !== STATUS.CREDIT_REVIEW_PENDING &&
                      caseDetail.status !== STATUS.REJECTED &&
                      caseDetail.status !== STATUS.CREDIT_BUREAU_APPROVED) ||
                    (location.pathname.startsWith("/BureauReport/") &&
                      caseDetail.status !== STATUS.CREDIT_REVIEW_PENDING)
                  ) {
                    return true;
                  }
                  if (
                    userData &&
                    isVolvoUser(userData) &&
                    (location.pathname === "/OnGoingCases" ||
                      location.pathname.startsWith("/viewcase/"))
                  ) {
                    return true;
                  }
                }}
              >
                {" "}
                On Going Cases
              </NavLink>
            </Menu.Item>
            <Menu.Item key="4">
              <NavLink
                to="/PendingCreditReview"
                activeClassName="active"
                isActive={(match, location) => {
                  if (
                    location.pathname === "/PendingCreditReview" ||
                    (location.pathname.startsWith("/viewOnGoingcase/") &&
                      caseDetail.status === "Credit Review Pending") ||
                    (location.pathname.startsWith("/BureauReport/") &&
                      caseDetail.status === STATUS.CREDIT_REVIEW_PENDING &&
                      isCreditUser(userData))
                  ) {
                    return true;
                  }
                }}
              >
                Pending with Credit for Review
              </NavLink>
            </Menu.Item>
            <Menu.Item key="5">
              <NavLink to="/to-be-moved-to-los" activeClassName="active">
                {" "}
                To be moved to LOS
              </NavLink>
            </Menu.Item>
            <Menu.Item key="6">
              <NavLink
                to="/closedcases"
                activeClassName="active"
                isActive={(match, location) => {
                  if (
                    location.pathname === "/closedcases" ||
                    ((location.pathname.startsWith("/viewOnGoingcase/") ||
                      location.pathname.startsWith("/viewcase/")) &&
                      (caseDetail.status === STATUS.REJECTED ||
                        caseDetail.status === STATUS.CREDIT_BUREAU_APPROVED))
                  ) {
                    return true;
                  }
                }}
              >
                {" "}
                Closed Cases
              </NavLink>
            </Menu.Item>
            <Menu.Item key="7">
              <NavLink
                to="/FleetAnalysis"
                activeClassName="active"
                isActive={(match, location) => {
                  if (
                    location.pathname === "/FleetAnalysis" ||
                    location.pathname.startsWith("/Fleet-Analysis/")
                  ) {
                    return true;
                  }
                }}
              >
                {" "}
                Fleet Analysis
              </NavLink>
            </Menu.Item>
            <Menu.Item key="8">
              <NavLink
                to="/hunter"
                activeClassName="active"
                isActive={(match, location) => {
                  if (location.pathname === "/hunter" ||
                  location.pathname.startsWith("/hunter-response-view/")) {
                    return true;
                  }
                }}
              >
               
                Hunter
              </NavLink>
            </Menu.Item>
            <Menu.Item key="9">
              <NavLink
                to="/field-investigation"
                activeClassName="active"
                isActive={(match, location) => {
                  if (location.pathname === "/field-investigation" ||
                  location.pathname.startsWith("/field-investigation")) {
                    return true;
                  }
                }}
              >
               
                Field Investigation
              </NavLink>
            </Menu.Item>
            <Menu.Item key="10">
              <NavLink
                to="/vaahan"
                activeClassName="active"
                isActive={(match, location) => {
                  if (location.pathname === "/vaahan") {
                    return true;
                  }
                }}
              >
                Vahan
              </NavLink>
            </Menu.Item>
          </Menu>
          {userData && userData.user_type === "Sales" && (
            <Button onClick={confirmReset} className="addButton">
              <NavLink
                to={{
                  pathname: "/NewCases",
                }}
                activeClassName="active"
                
              >
                <img src={PlusIcon} alt="" /> Add New Case
              </NavLink>
            </Button>
          )}
        </div>
      </div>
      <Modal
        // title={<p className='confirmationtext'>Do you want to discard the process and create a new one?</p>}
        title=""
        centered
        open={caseResetConfirmation}
        onOk={resetNewCase}
        onCancel={() => cancelCaseReset(false)}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <p className="confirmationtext">
          Do you want to leave current process and create a new case?
          <div>(saved information will not be deleted)</div>
        </p>
      </Modal>
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isNewlyCreatedCaseSaved: auth.isNewlyCreatedCaseSaved,
    userData: auth.userData ? auth.userData.user_detail : {},
  };
};
export default connect(mapStateToProps, null)(Navigation);
