import React from "react";
import { Button, Radio } from "antd";
import { connect, useDispatch } from "react-redux";
import { onChangeSelectedUser } from "../../../redux/actions/AuthAction";
import EditIcon from "../../../assets/images/edit.png";
import ViewIcon from "../../../assets/images/view.png";
import RemoveIcon from "../../../assets/images/remove.png";
const CoapplicantList = (props) => {
  const { selectedUser, members, handleEdit, isMemberOrDirectorButton } = props;

  return (
    <>
      <div className="SmallTableWrap SmallTableWrap-v2">
        {members.length > 0 && (
          <>
            <p>
              {isMemberOrDirectorButton === "member"
                ? "Added Members"
                : "Added Directors"}
            </p>
            <table>
              <thead>
                <tr>
                  <th>
                    {isMemberOrDirectorButton === "member"
                      ? "Member Name"
                      : "Director Name"}
                  </th>
                  {/*<th>Relation with Applicant</th>*/}
                  <th>Status</th>
                  {/*<th>Stage</th>*/}
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {members.map((member, index) => (
                  <tr key={index.toString()}>
                    <td>
                      {member.first_name
                        ? member.first_name
                        : member.customer_name}
                    </td>
                    <td>Added</td>
                    {/*<td>{co_applicant.stage}</td>*/}
                    <td>
                      <Button>
                        <img src={ViewIcon} alt="View" />
                      </Button>
                      <Button>
                        <img src={EditIcon} alt="Edit" />
                      </Button>
                      <Button
                        onClick={() => {
                          props.deleteMemberOrDirector(member);
                        }}
                      >
                        <img src={RemoveIcon} alt="Remove" />
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}{" "}
              </tbody>
            </table>
          </>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(CoapplicantList);
