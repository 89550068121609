import React, { useEffect, useState } from "react";
import { Modal, Button, Row, Col, Form, Checkbox } from "antd";

const RunParametersModal = (props) => {
  let name = '';

  let paramData = props.userParameters;
  useEffect(() => {

  }, [paramData]);

  if (paramData.applicant_first_name && paramData.applicant_middle_name && paramData.applicant_last_name) {
    name = paramData.applicant_first_name + ' ' + paramData.applicant_middle_name + ' ' + paramData.applicant_last_name;
  } else if (paramData.applicant_first_name && paramData.applicant_last_name) {
    name = paramData.applicant_first_name + ' ' + paramData.applicant_last_name;
  } else if (paramData.applicant_first_name) {
    name = paramData.applicant_first_name;
  }
  return (
    <>
      <Modal
        title="Bureau Report Parameters"
        centered
        visible={props.visible}
        onOk={props.onCancel}
        onCancel={props.onCancel}
        className="KycCheck"
        footer={[
          <Button type="primary" key="back" onClick={props.onCancel}>
            Ok
          </Button>,

        ]}
        style={{ top: 90 }}
        width={600}
        destroyOnClose={true}
      >
        <div className="BureauFormWrap">
          <Row gutter={32}>
            <Col span={24} >
              <div className="LeftInfoArea">
                {props.userParameters.case_id ?
                  <>
                    <h3><b>Selected Parameters</b></h3>
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Report For</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p className='mb-0'>{paramData.report_for}</p>
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>{paramData.user_type}</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          {paramData.bureau_name === "CRIF" ?
                            <p className='mb-0'>{paramData.applicant_name}</p>
                            :
                            <p className='mb-0'>{name}</p>}
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item className='mb-0'>
                      <Row gutter={16} className='mb-0'>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Bureau Name</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <Checkbox checked={true}>{paramData.bureau_name}</Checkbox>
                        </Col>
                      </Row>
                    </Form.Item>
                    {paramData.bureau_name === "CRIF" && (
                      <Form.Item className='mb-0'>
                        <Row gutter={16} className='mb-0'>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <label>Class of Activity</label>
                          </Col>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <p className='mb-0'>{paramData.class_of_activity} </p>
                          </Col>
                        </Row>
                      </Form.Item>)}

                    {paramData.bureau_name === "CIBIL" && (
                      <Form.Item>
                        <Row gutter={16}>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <label>Score Type</label>
                          </Col>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <p>{paramData.score_type_name}</p>
                          </Col>
                        </Row>
                      </Form.Item>
                    )}
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label> {
                            paramData.report_for === "Corporate" || paramData.report_for === "Proprietor" || paramData.report_for === "HUF"
                              ? "Date of registration"
                              : "Date of Birth"
                          } </label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          {
                            paramData.report_for === "Corporate" || paramData.report_for === "Proprietor" || paramData.report_for === "HUF"
                              ? <p>{paramData.date_of_registration}</p>
                              : <p>{paramData.date_of_birth}</p>
                          }
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Identification Type</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p className='mb-0'>{paramData.document}</p>
                        </Col>
                      </Row>
                    </Form.Item>

                    <Form.Item>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>{paramData.document} No</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p className='mb-0'>{paramData.document_no} </p>
                        </Col>
                      </Row>
                    </Form.Item>
                    {paramData.report_for === "Corporate" || paramData.report_for === "Proprietor" || paramData.report_for === "HUF" ?
                      <>
                        <Form.Item className='mb-0'>
                          <Row gutter={16}>
                            <Col span={12} md={12} sm={12} xs={12}>
                              <label>Enquiry Amount</label>
                            </Col>
                            <Col span={12} md={12} sm={12} xs={12}>
                              <p className='mb-0'>{paramData.amount}</p>
                            </Col>
                          </Row>
                        </Form.Item>

                        <Form.Item className='mb-0'>
                          <Row gutter={16}>
                            <Col span={12} md={12} sm={12} xs={12}>
                              <label>Legal Constitutions</label>
                            </Col>
                            <Col span={12} md={12} sm={12} xs={12}>
                              <p className='mb-0'>{paramData.legal_constitution_name}</p>
                            </Col>
                          </Row>
                        </Form.Item>
                      </>
                      : ''}
                    {paramData.bureau_name === "CRIF" && (
                      <Form.Item>
                        <Row gutter={16}>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <label>Types of Loan</label>
                          </Col>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <p>{paramData.loan_name}</p>
                          </Col>
                        </Row>
                      </Form.Item>
                    )}
                    {paramData.report_for === "Individual" ?

                      <Form.Item className='mb-0'>
                        <Row gutter={16}>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <label>Gender</label>
                          </Col>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <p className='mb-0'>{paramData.gender_name ? paramData.gender_name : paramData.gender}</p>
                          </Col>
                        </Row>
                      </Form.Item>
                      : ''}
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Phone no</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p className='mb-0'>{paramData.phone_no}</p>

                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Address as Mentioned on</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p>{paramData.address_mentioned}</p>
                        </Col>
                      </Row>
                    </Form.Item>
                    {paramData.bureau_name === "CRIF" && (
                      <Form.Item className='mb-0'>
                        <Row gutter={16}>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <label>Address Type</label>
                          </Col>
                          <Col span={12} md={12} sm={12} xs={12}>
                            <p className='mb-0'>{paramData.address_type_name}</p>
                          </Col>
                        </Row>
                      </Form.Item>
                    )}
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>Address</label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p className='mb-0'>{paramData.address}</p>
                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>State </label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p className='mb-0'>{paramData.state_name ? paramData.state_name : paramData.state}</p>

                        </Col>
                      </Row>
                    </Form.Item>
                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>City </label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p className='mb-0'>{paramData.city}</p>

                        </Col>
                      </Row>
                    </Form.Item>

                    <Form.Item className='mb-0'>
                      <Row gutter={16}>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <label>PIN Code </label>
                        </Col>
                        <Col span={12} md={12} sm={12} xs={12}>
                          <p className='mb-0'>{paramData.pin_code}</p>
                        </Col>
                      </Row>
                    </Form.Item>
                  </>
                  : <p>No parameters found for this report!</p>}
              </div>
            </Col>
          </Row>
        </div>
      </Modal>

    </>
  );
};

export default RunParametersModal;
