import React, { Component, useEffect } from "react";
import { Tabs, Card, Row, Col } from "antd";
import DetailDocform from "../../detaildocform";
import MainApplicantFormKyc from "../../kycreport/mainapplicantform";
import BureauReport from "../../bureaureport";
import { useDispatch, useSelector } from "react-redux";
import { onParticipantDetail } from "../../../../redux/actions/ParticipantAction";
import { onMemberDirectorDetail } from "../../../../redux/actions/MemberAndDirectorAction";
import {
  onChangeSelectedUser,
  onChangeSelectedMemberDirector,
} from "../../../../redux/actions/AuthAction";
import OriginalApplicantDetailDocForm from "../original-applicant-detail-doc-form";
import {
  onChangeSecondTabKey,
  onChangeThirdTabKey,
} from "../../../../redux/actions/TabAction";
import MemberDirectorDetailForm from "../member-director";
import ThirdTab from "../thirdtab/thirdtab";
import { CATEGORY } from "../../../../constants/constants";
const { TabPane } = Tabs;
const SecondTab = (props) => {
  const selectedUser = useSelector((state) => state.auth.selectedUser);

  const secondTabKey = useSelector((state) => state.tabReducer.secondTabKey);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onChangeSecondTabKey("1"));
    dispatch(onChangeThirdTabKey("1"));
  }, [dispatch]);
  const changeSecondTab = (key) => {
    dispatch(onChangeSecondTabKey(key));
    dispatch(onChangeThirdTabKey("1"));
    if (key === "1") {
    } else {
      dispatch(
        onMemberDirectorDetail(key, (member_director_detail) => {
          
          dispatch(onChangeSelectedMemberDirector(member_director_detail));
        })
      );
    }
  };
  return (
    <React.Fragment>
      <div className="InnerTabsWrap">
        <Card>
          <Tabs activeKey={secondTabKey} onChange={changeSecondTab}>
            <TabPane tab="Original Applicant" key="1">
              <ThirdTab />
              {/*<DetailDocform />*/}
            </TabPane>
            {selectedUser &&
              selectedUser.fk_category_id &&
              (selectedUser.fk_category_id.toString() === CATEGORY.HUF ||
                selectedUser.fk_category_id.toString() === CATEGORY.CORPORATE ||
                selectedUser.fk_category_id.toString() ===
                  CATEGORY.PROPRIETOR) &&
              selectedUser.member_director &&
              selectedUser.member_director.map((member, index) => {
                return (
                  <TabPane
                    tab={`${member.member_type} ${index + 1}`}
                    key={member.id}
                  >
                    <ThirdTab />
                  </TabPane>
                );
              })}
          </Tabs>
        </Card>
      </div>
    </React.Fragment>
  );
};
export default SecondTab;
