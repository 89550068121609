import React from 'react'
import TopBarNav from '../components/topbar';
import Navigation from '../components/navigation';
import ViewBureauReportCases from '../components/fieldInvestigation';

export default function FieldInvestigation() {
  return (
    <div>
    <div className='NewCasesWrap'>
           <TopBarNav/>
            <Navigation/>
         <ViewBureauReportCases/>
        </div>
  </div>
  )
}

