import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Upload,
} from "antd";
import Modal from "antd/lib/modal/Modal";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CATEGORY, STAGES } from "../../../constants/constants";
import { MESSAGES, REQUIRED_MESSAGES } from "../../../constants/messages";
import { REGEX } from "../../../constants/regex";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  disabledFutureDates,
  fileNameFromUrl,
  fileTypeFromName,
  getBase64,
  isCaseNotClosed,
  isCreditReviewPending,
  isCreditUser,
  isNotDropped,
  isOcrDone,
  isSalesUser,
  loggedInUserData,
} from "../../../helpers/utils";
import {
  onChangeSelectedMemberDirector,
  onChangeSelectedUser,
} from "../../../redux/actions/AuthAction";
import { onCaseDetail } from "../../../redux/actions/CaseAction";
import { onUpdateCoApplicant } from "../../../redux/actions/CoApplicantAction";
import { onUpdateGuarantor } from "../../../redux/actions/GuarantorAction";
import { onRunKyc } from "../../../redux/actions/KycAction";
import { onUpdateMainApplicant } from "../../../redux/actions/MainApplicantAction";
import {
  onCaseMasters,
  onGetCities,
  onGetStates,
} from "../../../redux/actions/MasterData";
import { onMemberDirectorDetail } from "../../../redux/actions/MemberAndDirectorAction";
// import PreviewImageOrPDF from "../../common/modals/PreviewImageOrPDF";
import {
  onRunOcr,
  onRunOcrResponse,
  onUploadOcrDocuments,
} from "../../../redux/actions/OcrAction";
import { onParticipantDetail } from "../../../redux/actions/ParticipantAction";
import {
  onChangeThirdTabKey,
  onFirstTabChange,
} from "../../../redux/actions/TabAction";
import RunOcr from "../../case-participants/applicant-ocr";
import AddCoApplicant from "../../case-participants/co-applicants/AddCoApplicant";
import AddGuarantor from "../../case-participants/guarantors/AddGuarantor";
import { handlePreviewOcrDoc } from "../../case-participants/helpers/handlePreview";
import {
  updateDefaultOcrDocs,
  updateDefaultRequiredNonOcrDocs,
} from "../../case-participants/helpers/UpdateDefaultDocuments";
import {
  closeRunOcrModal,
  getNonOcrFields,
  getOcrFields,
} from "../../case-participants/helpers/utils";
import AddIndividualForm from "../../case-participants/individual/AddIndividualForm";
import AddMainApplicant from "../../case-participants/main-applicant/AddMainApplicant";
import PreviewImageOrPDF from "../../case-participants/modals/PreviewImageOrPDF";
import SavedNonOcrDocuments from "../../common/SavedNonOcrDocuments";
import RunKyc from "../../lognewcase/modals/runSingleOcr";
import { InformationModal } from "../../modals/InformationModal";
const layout = {
  labelCol: { span: 24, sm: 24, md: 24 },
  wrapperCol: { span: 24, sm: 24, md: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 },
};
const onFinishFailed = (errorInfo) => {};
const { Option } = Select;
const DetailDocform = (props) => {
  const dispatch = useDispatch();
  const { caseDetail, firstTab, states, caseMasters } = props;
  const [subCategories, setSubCategories] = useState([]);
  const [cities, setCities] = useState(null);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [relationship, setRelationship] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [runOcr, setRunOcr] = useState(false);
  const [kycResponse, setKycResponse] = useState(false);
  const [ocrResponse, setOcrResponse] = useState(false);
  //
  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  const [selectedUser, setSelectedUser] = useState({});
  const firstTabKey = useSelector((state) => state.tabReducer.firstTabKey);
  const secondTabKey = useSelector((state) => state.tabReducer.secondTabKey);
  const selectedMemberDirector = useSelector(
    (state) => state.auth.selectedMemberDirector
  );
  const [isMemberOrDirector, setIsMemberOrDirector] = useState(false);
  const [isMemberOrDirectorModalOpen, setMemberOrDirectorModalOpen] =
    useState(false);
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
  const [requiredNonOcrDocuments, setRequiredNonOcrDocuments] = useState([]);
  const [toEditParticipant, setEditParticipantDetails] = useState(false);
  const [editParticipantData, setEditParticipantData] = useState(false);
  const [isFormDisable, setIsFormDisable] = useState(false);
  const [
    corporateAddressSameAsRegisteredAddress,
    setCorporateAddressSameAsRegisteredAddress,
  ] = useState(false);
  const userPermissions = useSelector(state => state.auth.userPermissions)

  const userData = loggedInUserData();
  const [cities2, setCities2] = useState([]);
  const [informationModal, setInformationModal] = useState(false);
  const [informationTitle, setInformationTitle] = useState(false);
  useEffect(() => {
    if (secondTabKey === "1") {
      setIsMemberOrDirector(false);
      setSelectedUser(selectedApplicant);
    } else {
      setIsMemberOrDirector(true);
      setSelectedUser(selectedMemberDirector);
    }
  }, [secondTabKey, selectedMemberDirector, selectedApplicant]);
  useEffect(() => {
    if (
      selectedApplicant &&
      isNotDropped(selectedApplicant.status) &&
      isCaseNotClosed(caseDetail.status) &&
      userData &&
      isSalesUser(userData) &&
      selectedUser.stage &&
      selectedUser.stage !== STAGES.OCR_PENDING &&
      !isCreditReviewPending(caseDetail.status)
    ) {
      setIsFormDisable(false);
    } else {
      setIsFormDisable(true);
    }
  }, [
    caseDetail.status,
    selectedUser.stage,
    userData,
    selectedUser,
    selectedApplicant,
  ]);
  const [ocrDocuments, setOcrDocuments] = useState({
    pan_card: [],
    aadhar_card: [],
    passport: [],
    voter_id: [],
    driving_license: [],
  });
  const [nonOcrDocuments, setNonOcrDocuments] = useState([]);
  const [form] = Form.useForm();

  const handleCategorySelect = useCallback(
    (selectedCategory) => {
      form.setFieldsValue({ fk_sub_category: undefined });
      const subCategories = caseMasters.subcategories
        .filter((subCategory) => {
          return subCategory.fk_category_id == selectedCategory;
        })
        .map((data) => {
          return (
            <Select.Option key={data.id} value={data.id.toString()}>
              {data.subcategory_name}
            </Select.Option>
          );
        });
      setSubCategories(subCategories);
    },
    [caseMasters.subcategories, form]
  );

  useEffect(() => {
    dispatch(onCaseMasters((caseMasters) => {}));
    dispatch(onGetStates());
  }, [dispatch]);
  useEffect(() => {
    if (selectedUser) {
      // selectedUser.ocr_documents && selectedUser.ocr_documents.length > 0
      if (
        selectedUser.ocr_documents &&
        Array.isArray(selectedUser.ocr_documents)
      ) {
        let docs = {};
        let uploadedOcrDocuments = updateDefaultOcrDocs(
          selectedUser.ocr_documents
        );

        setOcrDocuments((ocrDocuments) => {
          return { ...uploadedOcrDocuments };
          // return {  ...uploadedOcrDocuments };
        });
      }
      if (selectedUser.non_ocr_required_documents) {
        let uploadedRequiredNonOcrDocuments = updateDefaultRequiredNonOcrDocs(
          selectedUser.non_ocr_required_documents
        );
        setRequiredNonOcrDocuments((requiredDocs) => {
          // return {  ...uploadedRequiredNonOcrDocuments };
          return { ...uploadedRequiredNonOcrDocuments };
        });
      }
      if (
        selectedUser.non_ocr_documents
        //&& selectedUser.non_ocr_documents.length > 0
      ) {
        setNonOcrDocuments(selectedUser.non_ocr_documents);
      }
    }
  }, [selectedUser]);
  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      fk_sub_category: undefined,
    });
    // console.log(selectedUser,'selectedUser')
    let initVals = {
      first_name: selectedUser.first_name ? `${selectedUser.first_name}` : "",
      middle_name: selectedUser.middle_name
        ? `${selectedUser.middle_name}`
        : "",
      last_name: selectedUser.last_name ? `${selectedUser.last_name}` : "",
      mobile_no: selectedUser.mobile_no ? selectedUser.mobile_no : "",
      gender: selectedUser.gender,
      pan_no: selectedUser.pan_no ? selectedUser.pan_no : "",
      address_line1: selectedUser.address_line1
        ? selectedUser.address_line1
        : "",
      address_line2: selectedUser.address_line2
        ? selectedUser.address_line2
        : "",
      address_line3: selectedUser.address_line3
        ? selectedUser.address_line3
        : "",
      landmark: selectedUser.landmark ? selectedUser.landmark : "",
      pin: selectedUser.pin ? selectedUser.pin : "",
      state: selectedUser.state ? selectedUser.state : undefined,
      city: selectedUser.city ? selectedUser.city : undefined,
      // date_of_incorporation: moment(selectedUser.date_of_incorporation).format("YYYY-MM-DD"),

      fk_category: selectedUser.fk_category_id
        ? selectedUser.fk_category_id.toString()
        : "",
      fk_sub_category:
        selectedUser.fk_subcategory_id && selectedUser.fk_subcategory_id != 0
          ? selectedUser.fk_subcategory_id.toString()
          : undefined,
      relationship: selectedUser.coapplicant_applicant_relation,
      other_relation: selectedUser.other_relation,
      email: selectedUser.email,
      organization_name: selectedUser.organization_name
        ? selectedUser.organization_name
        : "",
      registration_no: selectedUser.registration_no
        ? selectedUser.registration_no
        : "",
      registered_office_place: selectedUser.registered_office_place
        ? selectedUser.registered_office_place
        : "",
      cin_no: selectedUser.cin ? selectedUser.cin : "",
      gst_registration_no: selectedUser.gst_registration_no
        ? selectedUser.gst_registration_no
        : "",
      tin_no: selectedUser.tin ? selectedUser.tin : "",
      same_as_reg_address: selectedUser.same_as_reg_address,
      corp_address_line1: selectedUser.corp_address_line1
        ? selectedUser.corp_address_line1
        : "",
      corp_address_line2: selectedUser.corp_address_line2
        ? selectedUser.corp_address_line2
        : "",
      corp_address_line3: selectedUser.corp_address_line3
        ? selectedUser.corp_address_line3
        : "",
      corp_city: selectedUser.corp_city ? selectedUser.corp_city : undefined,
      corp_state: selectedUser.corp_state ? selectedUser.corp_state : undefined,
      corp_landmark: selectedUser.corp_landmark
        ? selectedUser.corp_landmark
        : "",
      corp_pin: selectedUser.corp_pin ? selectedUser.corp_pin : "",
      contact_person_name: selectedUser.contact_person_name
        ? selectedUser.contact_person_name
        : "",
      trade_license_number: selectedUser.trade_license_number
        ? selectedUser.trade_license_number
        : "",
      designation:selectedUser.contact_persion_desigation
    };
    setSelectedCategory(
      selectedUser.fk_category_id ? selectedUser.fk_category_id.toString() : ""
    );
    setSelectedSubCategory(
      selectedUser.fk_subcategory_id && selectedUser.fk_subcategory_id != 0
        ? selectedUser.fk_subcategory_id.toString()
        : undefined
    );
    handleCategorySelect(selectedUser.fk_category_id);
    setRelationship(selectedUser.coapplicant_applicant_relation);
    form.setFieldsValue(initVals);

    if (selectedUser.dob) {
      form.setFieldsValue({
        dob: moment(selectedUser.dob)._isValid
          ? moment(selectedUser.dob)
          : null,
      });
    }
    if (selectedUser.date_of_incorporation) {
      form.setFieldsValue({
        date_of_incorporation: moment(selectedUser.date_of_incorporation),
      });
    }
    if (selectedUser.same_as_reg_address) {
      setCorporateAddressSameAsRegisteredAddress(true);
    }
  }, [form, selectedUser, ocrDocuments, handleCategorySelect]);
  useEffect(() => {
    if (corporateAddressSameAsRegisteredAddress) {
      let values = form.getFieldsValue();
      form.setFieldsValue({
        corp_address_line1: values.address_line1,
        corp_address_line2: values.address_line2,
        corp_address_line3: values.address_line3,
        corp_city: values.city,
        corp_state: values.state,
        corp_landmark: values.landmark,
        corp_pin: values.pin,
      });
    } else {
      form.setFieldsValue({
        same_as_reg_address: false,
      });
      // form.resetFields([
      //   'corp_address_line1',
      //   'corp_address_line2',
      //   'corp_city',
      //   'corp_state',
      //   'corp_landmark'
      // ])
    }
  }, [corporateAddressSameAsRegisteredAddress, form]);
  const renderCities = useCallback(
    (stateId, city) => {
      dispatch(
        onGetCities(stateId, (cities) => {
          if (cities) {
            const options = cities.map((data) => {
              return (
                <Select.Option key={data.city_name} value={data.city_name}>
                  {data.city_name}
                </Select.Option>
              );
            });
            setCities(options);
          }
        })
      );
    },
    [dispatch]
  );
  const renderCities2 = useCallback(
    (stateId, city) => {
      dispatch(
        onGetCities(stateId, (cities) => {
          if (cities) {
            const options = cities.map((data) => {
              return (
                <Select.Option key={data.city_name} value={data.city_name}>
                  {data.city_name}
                </Select.Option>
              );
            });
            setCities2(options);
          }
        })
      );
    },
    [dispatch]
  );
  useEffect(() => {
    if (selectedUser && selectedUser.state) {
      if (states && Array.isArray(states)) {
        const initialState = states.find((state) => {
          return state.state_name === selectedUser.state;
        });
        console.log('initialState: ', initialState);
        if (initialState && initialState.id) {
          renderCities(initialState.id, "1");
        }
        // const initialState2 = states.find((state) => {
        //   return state.state_name === selectedUser.corp_state;
        // });
        // console.log('initialState2: ', initialState2);
        // if (initialState2 && initialState2.id) {
        //   renderCities(initialState2.id, "2");
        // }
      }
    }
  }, [selectedUser, states, renderCities]);
  useEffect(() => {
    if (selectedUser && selectedUser.state) {
      if (states && Array.isArray(states)) {
        const initialState2 = states.find((state) => {
          return state.state_name === selectedUser.corp_state;
        });
        console.log('initialState2: ', initialState2);
        if (initialState2 && initialState2.id) {
          renderCities2(initialState2.id, "2");
        }
      }
    }
  }, [selectedUser, states, renderCities2]);
  const renderStateOptions = () => {
    if (states && states.length !== 0) {
      const options = states.map((data) => {
        return (
          <Select.Option key={data.state_name} value={data.state_name}>
            {data.state_name}
          </Select.Option>
        );
      });
      return options;
    }
  };
  const closeOcrModal = () => {
    closeRunOcrModal(dispatch);
    setOcrResponse(false);
  };
  const beforeUpload = (file, list, docName) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file)) {
        const formData = new FormData();
        formData.append("fk_case_id", caseDetail.id); //77
        formData.append("applicant_type", selectedApplicant.applicant_type);
        formData.append(
          "applicant_coapplicant_guarantor_id",
          selectedApplicant.id
        );
        formData.append(docName, file);
        if (isMemberOrDirector) {
          formData.append("member_director_id", selectedUser.id);
        }
        dispatch(
          onUploadOcrDocuments(formData, (response) => {
            let selectedTab = firstTabKey;
            if (isMemberOrDirector) {
              dispatch(
                onMemberDirectorDetail(selectedUser.id, (member_director) => {
                  setSelectedUser(member_director);
                })
              );
            } else {
              dispatch(
                onCaseDetail({ caseId: caseDetail.id }, (response) => {
                  dispatch(onFirstTabChange(selectedTab));
                  if (selectedUser.applicant_type === "Main Applicant") {
                    dispatch(onChangeSelectedUser(response.main_applicant));
                  } else if (selectedUser.applicant_type === "Co Applicant") {
                    const updatedCoApplicant = response.co_applicants.find(
                      (co_applicant) => co_applicant.id === selectedUser.id
                    );
                    dispatch(onChangeSelectedUser(updatedCoApplicant));
                  } else if (selectedUser.applicant_type === "Guarantor") {
                    const updatedGuarantor = response.guarantors.find(
                      (guarantor) => guarantor.id === selectedUser.id
                    );
                    dispatch(onChangeSelectedUser(updatedGuarantor));
                  }
                })
              );
            }
          })
        );
        setOcrDocuments((o) => {
          return { ...o, [docName]: list };
        });
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const handlePreview = async (file) => {
    setPreviewPdf(false);
    setPreviewImage(false);
    setPreviewVisible(false);
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    const fileName = fileNameFromUrl(file.url);
    const fileType = fileTypeFromName(fileName);
    if (fileType === "pdf") {
      setPreviewPdf(file.url || file.preview);
    } else {
      setPreviewImage(file.url || file.preview);
    }
    setPreviewVisible(true);
  };
  const onFinish = (values) => {
    if (isCreditReviewPending(caseDetail.status)) {
      setInformationModal(true);
      setInformationTitle(MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE);
    } else {
      const runKycCheck = () => {
        if (selectedUser) {
          let data = {
            fk_case_id: selectedUser.fk_case_id,
            applicant_type: selectedApplicant.applicant_type,
            applicant_coapplicant_guarantor_id: selectedApplicant.id,
          };
          if (isMemberOrDirector) {
            data.member_director_id = selectedUser.id;
          }
          dispatch(
            onRunKyc(data, async (response) => {
              await updateSelectedUser();
              setKycResponse(response);

              // updateSelectedUser();

              setRunOcr(true);
            })
          );
        }
      };
      let data = {
        fk_case_id: selectedUser.fk_case_id,
        id: selectedUser.id,
        first_name: values.first_name,
        middle_name: values.middle_name,
        contact_person_name: values.contact_person_name,
        last_name: values.last_name,
        gender: values.gender,
        // dob: moment(values.dob).format("YYYY-MM-DD"),
        pan_no: values.pan_no,
        mobile_no: values.mobile_no,
        email: values.email,
        address_line1: values.address_line1,
        address_line2: values.address_line2,
        address_line3: values.address_line3,
        landmark: values.landmark,
        pin: values.pin,
        state: values.state,
        city: values.city,
        organization_name: values.organization_name,
        registered_office_place: values.registered_office_place,
        registration_no: values.registration_no,
        date_of_incorporation: moment(values.date_of_incorporation).format(
          "YYYY-MM-DD"
        ),
        cin: values.cin_no,
        gst_registration_no: values.gst_registration_no,
        tin: values.tin_no,
        same_as_reg_address: values.same_as_reg_address ? 1 : 0,
        corp_address_line1: values.corp_address_line1,
        corp_address_line2: values.corp_address_line2,
        corp_address_line3: values.corp_address_line3,
        corp_city: values.corp_city,
        corp_state: values.corp_state,
        corp_landmark: values.corp_landmark,
        corp_pin: values.corp_pin,
        trade_license_number: values.trade_license_number,
        designation:values.designation
      };
      if (selectedUser.applicant_type === "Main Applicant") {
        dispatch(
          onUpdateMainApplicant(data, async (data) => {
            await runKycCheck();
            // dispatch(
            //   onCaseDetail({ caseId: selectedUser.fk_case_id }, (response) => {
            //     debugger
            //     dispatch(onChangeSelectedUser(response.main_applicant));
            //   })
            // );
          })
        );
      } else if (selectedUser.applicant_type === "Co Applicant") {
        data = {
          ...data,
          // fk_category_id:selectedUser.fk_category_id,
          // fk_subcategory_id:selectedUser.fk_subcategory_id,
          // coapplicant_applicant_relation:selectedUser.coapplicant_applicant_relation,
          // other_relation:selectedUser.other_relation && selectedUser.other_relation!=='undefined'?selectedUser.other_relation:'',
          fk_category_id: values.fk_category
            ? values.fk_category.toString()
            : "",
          fk_subcategory_id: values.fk_sub_category
            ? values.fk_sub_category.toString()
            : "",
          coapplicant_applicant_relation: values.relationship,
          other_relation: values.other_relation ? values.other_relation : "",
        };
        dispatch(
          onUpdateCoApplicant(data, async (data) => {
            // onChangeSelectedUser()
            await runKycCheck();
            // dispatch(
            //   onCaseDetail({ caseId: selectedUser.fk_case_id }, (response) => {
            //     const updatedCoApplicant = response.co_applicants.find(
            //       (co_applicant) => co_applicant.id === selectedUser.id
            //     );

            //     dispatch(onChangeSelectedUser(updatedCoApplicant));
            //   })
            // );
          })
        );
      } else if (selectedUser.applicant_type === "Guarantor") {
        data = {
          ...data,
          fk_category_id: values.fk_category,
          fk_subcategory_id: values.fk_sub_category
            ? values.fk_sub_category.toString()
            : "",
        };

        dispatch(
          onUpdateGuarantor(data, async (data) => {
            await runKycCheck();
          })
        );
      }
    }
  };
  const handleSelectState = (selectedState, city) => {
    let state = states.find((state) => {
      return state.state_name === selectedState;
    });
    if(city === '1'){

      renderCities(state.id, city);
    }else{
      renderCities2(state.id, city);

    }
    form.setFieldsValue({ city: undefined });
  };
  const updateSelectedUser = () => {
    if (isMemberOrDirector) {
      dispatch(
        onMemberDirectorDetail(selectedUser.id, (member_director) => {
          setSelectedUser(member_director);
          dispatch(onChangeSelectedMemberDirector(member_director));
        })
      );
    } else {
      dispatch(
        onCaseDetail({ caseId: caseDetail.id }, (response) => {
          if (firstTabKey === "2") {
            dispatch(onChangeSelectedUser(response.main_applicant));
          } else if (firstTabKey === "3") {
            let co_applicant = response.co_applicants.find((co_applicant) => {
              return co_applicant.id == selectedApplicant.id;
            });
            dispatch(onChangeSelectedUser(co_applicant));
          } else if (firstTabKey === "4") {
            let guarantor = response.guarantors.find((guarantor) => {
              return guarantor.id == selectedApplicant.id;
            });
            dispatch(onChangeSelectedUser(guarantor));
          }
        })
      );
    }
  };
  const runKycCheck = () => {
    if (selectedUser) {
      let data = {
        fk_case_id: selectedUser.fk_case_id,
        applicant_type: selectedApplicant.applicant_type,
        applicant_coapplicant_guarantor_id: selectedApplicant.id,
      };
      if (isMemberOrDirector) {
        data.member_director_id = selectedUser.id;
      }
      dispatch(
        onRunKyc(data, (response) => {
          setKycResponse(response);

          updateSelectedUser();
          setRunOcr(true);
        })
      );
    }
  };
  const closeEditParticipantDetailsModal = () => {
    savedEditedParticipantDetails();
    setEditParticipantDetails(false);
  };
  const runOcrCheck = () => {
    let data = {
      fk_case_id: caseDetail.id,
      applicant_type: selectedApplicant.applicant_type,
      applicant_coapplicant_guarantor_id: selectedApplicant.id,
    };
    if (isMemberOrDirector) {
      data.member_director_id = selectedUser.id;
    }
    // setRunOcr(true);
    dispatch(
      onRunOcr(data, (response) => {
        // setOcrResponse(response);
        let ocrResponse = {
          ocr_success: response.result,
          fk_category_id: isMemberOrDirector
            ? "1"
            : selectedUser.fk_category_id
            ? selectedUser.fk_category_id.toString()
            : "",
          applicant_type: selectedApplicant.applicant_type,
          applicant_coapplicant_guarantor_id: selectedApplicant.id,
          isMemberOrDirector: isMemberOrDirector
            ? selectedUser.member_type
            : "",
          ...response,
        };
        if (isMemberOrDirector) {
          ocrResponse.member_director_id = selectedUser.id;
        }
        dispatch(onRunOcrResponse(ocrResponse));
        setOcrResponse(true);
        updateSelectedUser();
        setRunOcr(true);
      })
    );
  };
  const nameValidation = (e) => {
    const field_name = e.target.id.replace("basic_", "");
    const input = e.target.value;
    if (!REGEX.NAME_VALIDATION.test(input)) {
      form.setFieldsValue({
        [field_name]: input
          .substring(0, input.length - 1)
          .replace(/[^a-zA-Z0-9&-/s ]/gm, ""),
      });
    }
  };
  const KycCheck = (runOcr) => {
    setKycResponse(runOcr);
  };
  const GetDirectorMemberButton = () => {
    const openMemberDirectorModal = () => {
      setMemberOrDirectorModalOpen(true);
    };
    if (!isMemberOrDirector) {
      if (
        selectedUser &&
        selectedUser.fk_category_id &&
        (selectedUser.fk_category_id.toString() === "2" ||
          selectedUser.fk_category_id.toString() === "3")
      ) {
        return (
          <Button onClick={openMemberDirectorModal}>
            Add{" "}
            {selectedUser.fk_category_id.toString() === "2"
              ? "Director"
              : "Member"}{" "}
            Details
          </Button>
        );
      }
    }
  };
  const updateCaseDetail = () => {
    let data = {
      applicant_type: selectedUser.applicant_type,
      applicant_coapplicant_guarantor_id: selectedUser.id,
      
    };
    dispatch(
      onParticipantDetail(data, (participantDetail) => {
        dispatch(onChangeSelectedUser(participantDetail, () => {}));
      })
    );
  };
  const GetOcrDocumentField = ({ label, name, message }) => {
   
      return (
        // className='viewonly'
        <Col span={8} xs={24} md={8} sm={12} className="viewonly">
          <Form.Item label={label} name={name}>
            <Upload
              maxCount={1}
              fileList={ocrDocuments[name]}
              beforeUpload={(file, fileList) => {
                const result = beforeUpload(file, fileList, name);
                if (result) {
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
              // disabled={userData && isCreditUser(userData)}
              // disabled
              // onPreview={handlePreview}
              onPreview={(file, fileList) => {
                handlePreviewOcrDoc(file, dispatch);
              }}
              showUploadList={{
                showRemoveIcon: false,
              }}
            >
              {/*<Button>Upload </Button*/}
            </Upload>
            <p>
              {ocrDocuments[name]
                ? ocrDocuments[name][0]
                  ? ocrDocuments[name][0].status
                  : ""
                : ""}
            </p>
          </Form.Item>
        </Col>
      );
  };
  const GetOcrDocuments = () => {
    
    return (
      <Row gutter={32} className="UploadWrap">
        <Col span={24}>
          <h3>Uploaded Scanned Copy / Photo Of OCR Documents</h3>
        </Col>
        {isMemberOrDirector
          ? getOcrFields("1", "").map((ocr_field) => {
            if(ocrDocuments[ocr_field.name]){
              return (
                <GetOcrDocumentField
                  label={ocr_field.label}
                  name={ocr_field.name}
                  message={ocr_field.message}
                />
              );
            }
            })
          : getOcrFields(selectedCategory, selectedSubCategory).map(
              (ocr_field) => {
                if(ocrDocuments[ocr_field.name]){
                return (
                  <GetOcrDocumentField
                    label={ocr_field.label}
                    name={ocr_field.name}
                    message={ocr_field.message}
                  />
                );
                }
              }
            )}
      </Row>
    );
  };

  const GetNonOcrDocumentField = ({ label, name, message }) => {
    if (
      requiredNonOcrDocuments &&
      requiredNonOcrDocuments[label] &&
      Array.isArray(requiredNonOcrDocuments[label]) &&
      requiredNonOcrDocuments[label].length > 0
    ) {
      return (
        <Col span={8} xs={24} md={8} sm={12} className="viewonly">
          <Form.Item label={label} name={name}>
            <Upload
              maxCount={1}
              fileList={requiredNonOcrDocuments[label]}
              beforeUpload={(file, fileList) => {
                const result = beforeUpload(file, fileList, name);
                if (result) {
                  return Upload.LIST_IGNORE;
                }
                return false;
              }}
              // disabled={userData && isCreditUser(userData)}
              disabled
              // onPreview={handlePreview}
              onPreview={(file) => {
                handlePreviewOcrDoc(file, dispatch);
              }}
              showUploadList={{
                showRemoveIcon: false,
              }}
            >
              {/*<Button>Upload </Button>*/}
            </Upload>
            <p>
              {requiredNonOcrDocuments[label]
                ? requiredNonOcrDocuments[label][0]
                  ? requiredNonOcrDocuments[label][0].status
                  : ""
                : ""}
            </p>
          </Form.Item>
        </Col>
      );
    } else {
      return <React.Fragment></React.Fragment>;
    }
  };
  const RequiredNonOcrDocument = () => {
    return (
      <React.Fragment>
        {isMemberOrDirector
          ? getNonOcrFields("1", "").map((non_ocr_field) => {
            if(requiredNonOcrDocuments &&
              requiredNonOcrDocuments[non_ocr_field.label] &&
              Array.isArray(requiredNonOcrDocuments[non_ocr_field.label]) &&
              requiredNonOcrDocuments[non_ocr_field.label].length > 0){
                return (
                  <GetNonOcrDocumentField
                    label={non_ocr_field.label}
                    name={non_ocr_field.name}
                    message={non_ocr_field.message}
                  />
                );
            }
             
            })
          : getNonOcrFields(selectedCategory, selectedSubCategory).map(
              (non_ocr_field) => {
                if(requiredNonOcrDocuments &&
                  requiredNonOcrDocuments[non_ocr_field.label] &&
                  Array.isArray(requiredNonOcrDocuments[non_ocr_field.label]) &&
                  requiredNonOcrDocuments[non_ocr_field.label].length > 0){
                return (
                  <GetNonOcrDocumentField
                    label={non_ocr_field.label}
                    name={non_ocr_field.name}
                    message={non_ocr_field.message}
                  />
                );
                  }
              }
            )}
      </React.Fragment>
    );
  };
  const savedEditedParticipantDetails = (applicant) => {
    updateSelectedUser();
    // dispatch(
    //   onParticipantDetail(
    //     {
    //       applicant_type: selectedApplicant.applicant_type,
    //       applicant_coapplicant_guarantor_id: applicant.id,
    //     },
    //     (co_applicant) => {
    //       setEditParticipantData(co_applicant)
    //
    //       dispatch(onChangeSelectedUser(co_applicant));
    //     }
    //   )
    // );
  };

  const updateMemberDirector = (member_director) => {
    dispatch(
      onMemberDirectorDetail(selectedUser.id, (member_director) => {
        setSelectedUser(member_director);
      })
    );
  };
  const editCurrentSelectedParticipant = () => {
    if (isMemberOrDirector) {
      setEditParticipantData(selectedUser);
      setEditParticipantDetails(true);
    } else {
      setEditParticipantData(selectedApplicant);
      setEditParticipantDetails(true);
    }
  };
  const closeKycModal = () => {
    setKycResponse(false);
    dispatch(onChangeThirdTabKey("2"));
  };
  return (
    <React.Fragment>
      <div className="FormWrap ViewField">
        <Form
          {...layout}
          name="basic"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={form}
        >
          <Row gutter={32} className="StatusHighlight mt-0 pt-0">
            <Col span={24} xs={24} sm={24} md={24} className="ButtonWrap">
              {selectedUser && selectedUser.stage && (
                <p>
                  <b>Applicant Stage</b> : {selectedUser.stage}
                </p>
              )}
              {userData && userData.permissions && userData.permissions.Case && userData.permissions.Case.edit && selectedApplicant &&
                isNotDropped(selectedApplicant.status) &&
                isCaseNotClosed(caseDetail.status) && (
                  <React.Fragment>
                    {userData &&
                      selectedUser &&
                      isSalesUser(userData) &&
                      selectedUser.stage &&
                      selectedUser.stage !== STAGES.OCR_PENDING && (
                        <Button
                          htmlType="submit"
                          onClick={() => {
                            if (isCreditReviewPending(caseDetail.status)) {
                              setInformationModal(true);
                              setInformationTitle(
                                MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE
                              );
                            } else {
                              form
                                .validateFields()
                                .then((res) => {})
                                .catch((res) => {
                                  toast.error('Please fill all the mandatory details correctly')
                                  if (
                                    res &&
                                    res.values &&
                                    res.errorFields &&
                                    Array.isArray(res.errorFields) &&
                                    res.errorFields[0] &&
                                    res.errorFields[0].name &&
                                    Array.isArray(res.errorFields[0].name) &&
                                    res.errorFields[0].name[0]
                                  ) {
                                    console.log(
                                      "es.errorFields[0].name[0]",
                                      res.errorFields[0].name[0]
                                    );
                                    console.log(
                                      document.getElementById(
                                        res.errorFields[0].name[0]
                                      )
                                    );
                                    // document.getElementById(
                                    //   res.errorFields[0].name[0]
                                    // ) &&
                                    //   document
                                    //     .getElementById(
                                    //       res.errorFields[0].name[0]
                                    //     )
                                    //     .scrollIntoView({ behavior: "smooth" });
                                  }

                                  // toast.error('Please fill all the mandatory fields')
                                });
                            }
                          }}
                        >
                          Save and Run KYC
                        </Button>
                      )}
                    {userData &&
                      isSalesUser(userData) &&
                      selectedUser &&
                      !isOcrDone(selectedUser.stage) &&
                      !isCreditReviewPending(caseDetail.status) && (
                        <Button onClick={runOcrCheck}>
                          {(selectedUser && !selectedUser.stage) ||
                          selectedUser.stage === STAGES.OCR_PENDING
                            ? "Run OCR"
                            : "Re-Run OCR"}
                        </Button>
                      )}
                    {userData &&
                      isSalesUser(userData) &&
                      !isCreditReviewPending(caseDetail.status) && (
                        <Button onClick={editCurrentSelectedParticipant}>
                          Edit Participant Details
                        </Button>
                      )}
                  </React.Fragment>
                )}
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={8} md={8} xs={24} sm={24}>
              {selectedUser.fk_category_id && (
                // (selectedUser.applicant_type === "Co Applicant" ||
                //   selectedUser.applicant_type === "Guarantor")&&
                <React.Fragment>
                  <Form.Item
                    label={"Applicant Type"}
                    name="fk_category"
                    id="fk_category"
                    className="TopTabBox"
                    rules={[
                      {
                        required: true,
                        message:
                          REQUIRED_MESSAGES.APPLICATION_CATEGORY_REQUIRED,
                      },
                    ]}
                  >
                    <Select
                      defaultValue={selectedUser.fk_category_id.toString()}
                      onChange={handleCategorySelect}
                      disabled
                    >
                      {caseMasters.categories.map((category, index) => (
                        <Option
                          value={category.id.toString()}
                          key={category.id.toString()}
                        >
                          {category.category_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </React.Fragment>
              )}
              {selectedUser.fk_category_id &&
                selectedUser.applicant_type === "Co Applicant" && (
                  <React.Fragment>
                    <Form.Item
                      label="Relationship with applicant"
                      name="relationship"
                      // id="relationship"
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGES.RELATIONSHIP_REQUIRED,
                        },
                      ]}
                      className="TopTabBox"
                    >
                      <Select
                        placeholder={"Relationship with applicant"}
                        onSelect={(val) => {
                          setRelationship(val);
                        }}
                        // disabled={isCreditUser(userData)}
                        disabled={isFormDisable}
                        id="relationship"
                      >
                        {caseMasters.relationships.map((relation) => (
                          <Option
                            value={relation.name.toString()}
                            key={relation.id.toString()}
                          >
                            {relation.name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </React.Fragment>
                )}
              <div className="labelbottom">
                {relationship === "Others" && (
                  <Form.Item
                    label="Other"
                    name="other_relation"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.OTHER_RELATION_REQUIRED,
                      },
                      {
                        max: 50,
                      },
                    ]}
                  >
                    <Input
                      id="other_relation"
                      placeholder="relationship"
                      maxLength={50}
                      // disabled={isCreditUser(userData)}
                      disabled={isFormDisable}
                    />
                  </Form.Item>
                )}
                <div id="organization_name">
                  <Form.Item
                    label="Organization Name"
                    name="organization_name"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.ORGANIZATION_NAME_REQUIRED,
                      },
                    ]}
                  >
                    <Input
                      placeholder="Please Enter Organization Name"
                      // disabled={isCreditUser(userData)}
                      disabled={isFormDisable}
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="labelbottom" id="date_of_incorporation">
                <Form.Item
                  label={"Date of Incorporation"}
                  name="date_of_incorporation"
                  rules={[
                    { required: true, message: REQUIRED_MESSAGES.DOI_REQUIRED },
                  ]}
                >
                  <DatePicker
                    disabledDate={disabledFutureDates}
                    inputReadOnly
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                  />
                </Form.Item>
              </div>
              <div className="labelbottom">
                <Form.Item
                  label="GST Registration No."
                  name="gst_registration_no"
                  id="gst_registration_no"
                  rules={[
                    // {
                    //   required: true,
                    //   message: REQUIRED_MESSAGES.GST_REGISTRATION_REQUIRED,
                    // },
                    {
                      pattern:
                        /^([0][1-9]|[1-2][0-9]|[3][0-7])([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
                      message: "Invalid GST number",
                      validateTrigger: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="Please Enter GST Regsitration No."
                    // maxLength={10}
                    disabled
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <div className="labelbottom">
                <Form.Item
                  label="Applicant Sub Type"
                  name="fk_sub_category"
                  id="fk_sub_category"
                  rules={[
                    {
                      required: subCategories.length > 0,
                      message:
                        REQUIRED_MESSAGES.APPLICATION_SUB_CATEGORY_REQUIRED,
                    },
                  ]}
                  className="TopTabBox"
                >
                  <Select
                    placeholder={"Please select sub category"}
                    // disabled={subCategories.length === 0}
                    disabled
                  >
                    {subCategories}
                  </Select>
                </Form.Item>
              </div>
              {/*<p>{selectedApplicant.address_taken_from}</p>*/}
              <Form.Item
                label="Registration No."
                name="registration_no"
                id="registration_no"
              >
                <Input
                  placeholder="Please Enter Registration No."
                  // disabled={isCreditUser(userData)}
                  disabled={isFormDisable}
                />
              </Form.Item>
              <Form.Item
                label="CIN"
                name="cin_no"
                // rules={[
                //   // { required: true, message: REQUIRED_MESSAGES.CIN_REQUIRED },
                //   {
                //     pattern:
                //       /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/,
                //     message: MESSAGES.INVALID_CIN_NUMBER,
                //     validateTrigger: true,
                //   },
                // ]}
              >
                <Input
                  placeholder="Please Enter the CIN"
                  // disabled={isCreditUser(userData)}
                  disabled={isFormDisable}
                />
              </Form.Item>
              <div id="tin_no">
                <Form.Item
                  label="TIN"
                  name="tin_no"

                  // rules={[
                  //   {
                  //     required: true,
                  //     message: REQUIRED_MESSAGES.TIN_REQUIRED,
                  //   },
                  // ]}
                >
                  <Input
                    placeholder="Please Enter the TIN"
                    maxLength={11}
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                  />
                </Form.Item>
              </div>
            </Col>

            <Col span={8} md={8} xs={24} sm={24}>
              {/*<Form.Item
                label={"Applicant Name"}
                name="first_name"
                id="first_name"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.FIRST_NAME_REQUIRED,
                  },
                ]}
              >
                <Input placeholder="First Name" onChange={nameValidation} />
              </Form.Item>*/}
              <Form.Item
                label="Registered Office Place"
                name="registered_office_place"
                id="registered_office_place"
                // rules={[
                //   {
                //     required: true,
                //     message: REQUIRED_MESSAGES.REGISTERED_PLACE_REQUIRED,
                //   },
                // ]}
              >
                <Input
                  placeholder="Please Enter the Registered Office Place"
                  // disabled={isCreditUser(userData)}
                  disabled={isFormDisable}
                />
              </Form.Item>

              <div className="labelbottom">
                <Form.Item
                  label={"PAN No."}
                  name="pan_no"
                  id="pan_no"
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: REQUIRED_MESSAGES.PAN_NUMBER_REQUIRED,
                  //   },
                  // ]}
                >
                  <Input
                    placeholder="PAN number fetched from PAN card will show here"
                    disabled
                    // disabled={isFormDisable}
                  />
                </Form.Item>
              </div>
              {/*<p>{selectedUser.dob_taken_from}</p>*/}
              <div id="email">
                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: MESSAGES.INVALID_EMAIL,
                      validateTrigger: true,
                    },
                  ]}
                  onChange={(e) => {
                    const enteredEmail = e.target.value;
                    let trimmedEmail = enteredEmail.trim();
                    form.setFieldsValue({ email: trimmedEmail });
                  }}
                >
                  <Input
                    placeholder="xyz@gmail.com"
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                  />
                </Form.Item>
                {selectedUser.fk_category_id == CATEGORY.PROPRIETOR && (
                  <Form.Item
                    label="Trade License Number"
                    name="trade_license_number"
                    id="trade_license_number"
                    // rules={[
                    //   {
                    //     required: true,
                    //     message: REQUIRED_MESSAGES.TIN_REQUIRED,
                    //   },
                    // ]}
                  >
                    <Input
                      placeholder="Please Enter the Trade License Number"
                      maxLength={11}
                      // disabled={isCreditUser(userData)}
                      disabled={isFormDisable}
                    />
                  </Form.Item>
                )}
              </div>
              {/*<p>{selectedApplicant.mobile_taken_from}</p>*/}
            </Col>
          </Row>

          <h3>
            <u>Registered Address</u>
          </h3>
          <Row gutter={32}>
            <Col span={8} md={8} xs={24} sm={24}>
              {selectedUser.fk_category_id && (
                // (selectedUser.applicant_type === "Co Applicant" ||
                //   selectedUser.applicant_type === "Guarantor")&&
                <React.Fragment>
                  <div id="address_line1">
                    <Form.Item
                      label={"Address Line 1"}
                      name="address_line1"
                      className="TopTabBox"
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGES.ADDRESS_REQUIRED,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Address"
                        onChange={(e) => {
                          if (corporateAddressSameAsRegisteredAddress) {
                            form.setFieldsValue({
                              corp_address_line1: e.target.value,
                            });
                          }

                          // setCorporateAddressSameAsRegisteredAddress(false);
                        }}
                        // disabled={isCreditUser(userData)}
                        disabled={isFormDisable}
                      />
                    </Form.Item>
                  </div>
                  <div id="landmark">
                    <Form.Item label="Landmark" name="landmark">
                      <Input
                        placeholder="Landmark"
                        onChange={(e) => {
                          if (corporateAddressSameAsRegisteredAddress) {
                            form.setFieldsValue({
                              corp_landmark: e.target.value,
                            });
                          }

                          // setCorporateAddressSameAsRegisteredAddress(false);
                        }}
                        // disabled={isCreditUser(userData)}
                        disabled={isFormDisable}
                      />
                    </Form.Item>
                  </div>
                  <div id="city">
                    <Form.Item
                      label="City"
                      name="city"
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGES.CITY_REQUIRED,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please Select City"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        onChange={(value) => {
                          if (corporateAddressSameAsRegisteredAddress) {
                            form.setFieldsValue({
                              corp_city: value,
                            });
                          }

                          //setCorporateAddressSameAsRegisteredAddress(false);
                        }}
                        // disabled={isCreditUser(userData)}
                        disabled={isFormDisable}
                      >
                        {cities}
                      </Select>
                    </Form.Item>
                  </div>
                </React.Fragment>
              )}
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <div className="labelbottom">
                <Form.Item
                  label="Address Line 2"
                  name="address_line2"
                  id="address_line2"
                >
                  <Input
                    placeholder="Address"
                    onChange={(e) => {
                      if (corporateAddressSameAsRegisteredAddress) {
                        form.setFieldsValue({
                          corp_address_line2: e.target.value,
                        });
                      }

                      // setCorporateAddressSameAsRegisteredAddress(false);
                    }}
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                  />
                </Form.Item>
              </div>
              {/*<p>{selectedApplicant.address_taken_from}</p>*/}
              <div id="pin">
                <Form.Item
                  label="PIN Code"
                  name="pin"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.PIN_CODE_REQUIRED,
                      validateTrigger: true,
                    },
                    {
                      pattern: /^[1-9][0-9]{5}$/,
                      message: MESSAGES.INVALID_PIN_CODE,
                      validateTrigger: true,
                    },
                  ]}
                >
                  <NumberFormat
                    format="######"
                    placeholder="PIN Code"
                    class="ant-input"
                    onChange={(e) => {
                      if (corporateAddressSameAsRegisteredAddress) {
                        form.setFieldsValue({
                          corp_pin: e.target.value,
                        });
                      }

                      // setCorporateAddressSameAsRegisteredAddress(false);
                    }}
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                  />
                  {/*<Input placeholder="Postal Code" maxLength={6}/>*/}
                </Form.Item>
              </div>
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <div id="address_line3">
                <Form.Item label="Address Line 3" name="address_line3">
                  <Input
                    placeholder="Address Line 3"
                    onChange={(e) => {
                      if (corporateAddressSameAsRegisteredAddress) {
                        form.setFieldsValue({
                          corp_address_line3: e.target.value,
                        });
                      }

                      // setCorporateAddressSameAsRegisteredAddress(false);
                    }}
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                  />
                </Form.Item>
              </div>
              <div id="state">
                <Form.Item
                  label="State"
                  name="state"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.STATE_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    onSelect={(value) => {
                      handleSelectState(value, "1");
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    placeholder="Please Select State"
                    onChange={(value) => {
                      if (corporateAddressSameAsRegisteredAddress) {
                        form.setFieldsValue({
                          corp_state: value,
                          corp_city: undefined,
                        });
                      }
                      // setCorporateAddressSameAsRegisteredAddress(false);
                    }}
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                  >
                    {renderStateOptions()}
                  </Select>
                </Form.Item>
              </div>
              {/*<p>{selectedApplicant.mobile_taken_from}</p>*/}
            </Col>
          </Row>
          <h3>
            <u>Corporate Address</u>
          </h3>
          <Row gutter={32}>
            <Col span={24} md={24} xs={24} sm={24}>
              <Form.Item name="same_as_reg_address" valuePropName="checked">
                <Checkbox
                  name="same_as_reg_address"
                  onChange={(e) => {
                    setCorporateAddressSameAsRegisteredAddress(
                      e.target.checked
                    );
                  }}
                  // disabled={isCreditUser(userData)}
                  disabled={isFormDisable}
                >
                  {" "}
                  Same as Registered Address
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              {selectedUser.fk_category_id && (
                // (selectedUser.applicant_type === "Co Applicant" ||
                //   selectedUser.applicant_type === "Guarantor")&&
                <React.Fragment>
                  <div id="corp_address_line1">
                    <Form.Item
                      label={"Address Line 1"}
                      name="corp_address_line1"
                      className="TopTabBox"
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGES.ADDRESS_REQUIRED,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Address"
                        disabled={
                          corporateAddressSameAsRegisteredAddress ||
                          // isCreditUser(userData)
                          isFormDisable
                        }
                      />
                    </Form.Item>
                  </div>
                  <Form.Item
                    label="Landmark"
                    name="corp_landmark"
                    id="corp_landmark"
                  >
                    <Input
                      placeholder="Landmark"
                      disabled={
                        corporateAddressSameAsRegisteredAddress ||
                        // isCreditUser(userData)
                        isFormDisable
                      }
                    />
                  </Form.Item>
                  <div id="corp_city">
                    <Form.Item
                      label="City"
                      name="corp_city"
                      rules={[
                        {
                          required: true,
                          message: REQUIRED_MESSAGES.CITY_REQUIRED,
                        },
                      ]}
                    >
                      <Select
                        placeholder="Please Select City"
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        filterSort={(optionA, optionB) =>
                          optionA.children
                            .toLowerCase()
                            .localeCompare(optionB.children.toLowerCase())
                        }
                        disabled={
                          corporateAddressSameAsRegisteredAddress ||
                          // isCreditUser(userData)
                          isFormDisable
                        }
                      >
                        {cities2}
                      </Select>
                    </Form.Item>
                  </div>
                </React.Fragment>
              )}
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <div className="labelbottom">
                <Form.Item
                  label="Address Line 2"
                  name="corp_address_line2"
                  id="corp_address_line2"
                >
                  <Input
                    placeholder="Address"
                    disabled={
                      corporateAddressSameAsRegisteredAddress ||
                      // isCreditUser(userData)
                      isFormDisable
                    }
                  />
                </Form.Item>
              </div>
              {/*<p>{selectedApplicant.address_taken_from}</p>*/}
              <div id="corp_pin">
                <Form.Item
                  label="PIN Code"
                  name="corp_pin"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.PIN_CODE_REQUIRED,
                      validateTrigger: true,
                    },
                    {
                      pattern: /^[1-9][0-9]{5}$/,
                      message: MESSAGES.INVALID_PIN_CODE,
                      validateTrigger: true,
                    },
                  ]}
                >
                  <NumberFormat
                    format="######"
                    placeholder="PIN Code"
                    class="ant-input"
                    disabled={
                      corporateAddressSameAsRegisteredAddress ||
                      // isCreditUser(userData)
                      isFormDisable
                    }
                  />
                  {/*<Input placeholder="Postal Code" maxLength={6}/>*/}
                </Form.Item>
              </div>
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <Form.Item
                label="Address Line 3"
                name="corp_address_line3"
                id="corp_address_line3"
              >
                <Input
                  placeholder="Address Line 3"
                  disabled={
                    corporateAddressSameAsRegisteredAddress ||
                    // isCreditUser(userData)
                    isFormDisable
                  }
                />
              </Form.Item>
              <div id="corp_state">
                <Form.Item
                  label="State"
                  name="corp_state"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.STATE_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    onChange={(value) => {
                      handleSelectState(value, "2");
                      form.setFieldsValue({
                        corp_city: undefined,
                      });
                    }}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children
                        .toLowerCase()
                        .localeCompare(optionB.children.toLowerCase())
                    }
                    placeholder="Please Select State"
                    disabled={
                      corporateAddressSameAsRegisteredAddress ||
                      // isCreditUser(userData)
                      isFormDisable
                    }
                  >
                    {renderStateOptions()}
                  </Select>
                </Form.Item>
              </div>
              {/*<p>{selectedApplicant.mobile_taken_from}</p>*/}
            </Col>
          </Row>
          <h3>
            <u>Contact Details</u>
          </h3>
          <Row gutter={32}>
            <Col span={8} md={8} xs={24} sm={24}>
              {selectedUser.fk_category_id && (
                // (selectedUser.applicant_type === "Co Applicant" ||
                //   selectedUser.applicant_type === "Guarantor")&&
                <React.Fragment>
                  <div id="first_name">
                    <Form.Item
                      label={"Contact Person Name"}
                      name="contact_person_name"
                      rules={[
                        {
                          required: true,
                          message:
                            REQUIRED_MESSAGES.CONTACT_PERSON_NAME_REQUIRED,
                        },
                      ]}
                    >
                      <Input
                        placeholder="Contact Person Name"
                        onChange={nameValidation}
                        // disabled={isCreditUser(userData)}
                        disabled={isFormDisable}
                      />
                    </Form.Item>
                  </div>
                </React.Fragment>
              )}
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <div  id="designation" className="labelbottom">
                <Form.Item
                  label="Designation"
                  name="designation"
                  id="designation"
                >
                  <Input
                    placeholder="Designation"
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={8} md={8} xs={24} sm={24}>
              <div id="mobile_no">
                <Form.Item
                  label="Mobile No"
                  name="mobile_no"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.MOBILE_NUMBER_REQUIRED,
                    },
                    {
                      validator(_, value) {
                        if (!value || value !== "+910000000000") {
                          return Promise.resolve();
                        }
                        return Promise.reject(MESSAGES.INVALID_MOBILE_NUMBER);
                      },
                    },
                  ]}
                >
                  <NumberFormat
                    placeholder="Mobile Number"
                    type="tel"
                    format="+91##########"
                    class="ant-input"
                    // disabled={isCreditUser(userData)}
                    disabled={isFormDisable}
                    // isAllowed={(values) => {
                    //

                    //   if (values.value.startsWith("0000000000")) {
                    //     form.setFields([
                    //     {
                    //       name:'mobile_no',
                    //       errors:[MESSAGES.INVALID_MOBILE_NUMBER]
                    //     }
                    //     ]);
                    //     return false
                    //   }
                    //   return true
                    // }}
                  />
                  {/*<Input placeholder="12467989" />*/}
                </Form.Item>
              </div>
              {/*<p>{selectedApplicant.mobile_taken_from}</p>*/}
            </Col>
          </Row>

          <GetOcrDocuments />

          <Row gutter={32} className="UploadWrap">
            <Col span={24}>
              <h3>Uploaded Scanned Copy / Photo Of Non OCR Documents</h3>
            </Col>
            <RequiredNonOcrDocument />
          </Row>
          <SavedNonOcrDocuments
            nonOcrDocuments={nonOcrDocuments}
            setNonOcrDocuments={(docs) => {
              setNonOcrDocuments(docs);
            }}
            form={form}
          />
        </Form>
        <Modal
          title="KYC RESULT"
          centered
          visible={kycResponse}
          onOk={closeKycModal}
          onCancel={closeKycModal}
          footer={null}
          width={600}
          className="KycCheck"
          style={{ top: 50 }}
        >
          <RunKyc
            ocrResponse={kycResponse}
            data={{
              applicant_type: isMemberOrDirector
                ? selectedUser.member_type
                : selectedUser.applicant_type,
              fk_category_id: isMemberOrDirector
                ? "1"
                : selectedUser.fk_category_id,
            }}
          />
        </Modal>
        <Modal
          title="Document Uploaded Successfully"
          centered
          visible={ocrResponse}
          // visible={true}
          onOk={closeOcrModal}
          onCancel={closeOcrModal}
          footer={[
            <Button
              className="JustReqWrap"
              type="primary"
              onClick={closeOcrModal}
            >
              Ok
            </Button>,
          ]}
          width={600}
          className="KycCheck"
          style={{ top: 50 }}
        >
          <RunOcr />
        </Modal>
        {/*<Modal
          visible={previewVisible}
          // title={previewTitle}
          footer={null}
          onCancel={() => {
            setPreviewVisible(false);
          }}
        >
          {previewImage && <img alt="example" style={{ width: "100%" }} src={previewImage} />}
          {previewPdf && (
            <iframe
              alt=""
              title="a"
              src={previewPdf}
              style={{ width: 500, height: 500 }}
              frameborder="0"
            />
          )}
          </Modal>*/}
        <PreviewImageOrPDF />
        {/*<PreviewImageOrPDF
          previewVisible={previewVisible}
          previewImage={previewImage}
          previewPdf={previewPdf}
          onCancel={() => {
            setPreviewImage(false);
            setPreviewPdf(false);
            setPreviewVisible(false);
          }}
        />*/}
        <Modal
          title={selectedCategory === "2" ? "Director" : "Member"}
          centered
          visible={isMemberOrDirectorModalOpen}
          destroyOnClose={true}
          // onOk={() => setModal2Visible(false)}
          onCancel={() => setMemberOrDirectorModalOpen(false)}
          footer={null}
          width={1000}
          className="ModalFormWrap AddCoapplicant"
          style={{ top: 50 }}
        >
          <AddIndividualForm
            data={{
              applicant_type: selectedUser.applicant_type,
              member_type: selectedCategory === "2" ? "Director" : "Member",
              applicant_id: selectedUser.id,
            }}
            closeDirectorMemberModal={() => setMemberOrDirectorModalOpen(false)}
            updateEditCoApplicant={updateCaseDetail}
          />
        </Modal>
        <Modal
          title={
            isMemberOrDirector
              ? selectedApplicant.fk_category_id == "2"
                ? "Edit Director Details"
                : "Edit Member Details"
              : `Edit ${selectedUser.applicant_type} Details`
          }
          centered
          visible={toEditParticipant}
          destroyOnClose={true}
          // onOk={() => setModal2Visible(false)}
          onCancel={() => {
            savedEditedParticipantDetails();
            setEditParticipantDetails(false);
          }}
          footer={null}
          width={1000}
          className="ModalFormWrap AddCoapplicant"
          style={{ top: 50 }}
        >
          {isMemberOrDirector && (
            <AddIndividualForm
              data={{
                applicant_type: selectedApplicant.applicant_type,
                member_type: selectedUser.member_type,
                applicant_id: selectedApplicant.id,
              }}
              memberOrDirector={editParticipantData}
              closeDirectorMemberModal={() => setEditParticipantDetails(false)}
              updateEditCoApplicant={updateMemberDirector}
            />
          )}
          {!isMemberOrDirector &&
            selectedUser.applicant_type === "Co Applicant" && (
              <AddCoApplicant
                editCoApplicant={editParticipantData}
                makeEditable={savedEditedParticipantDetails}
                add_new_coapplicant_inside_modal={true}
                handleCancel={closeEditParticipantDetailsModal}
                closeModal={true}
              />
            )}
          {!isMemberOrDirector &&
            selectedUser.applicant_type === "Main Applicant" && (
              <AddMainApplicant
                editMainApplicant={editParticipantData}
                makeEditable={savedEditedParticipantDetails}
                // add_new_coapplicant_inside_modal={true}
                handleCancel={closeEditParticipantDetailsModal}
                closeModal={true}
              />
            )}
          {!isMemberOrDirector &&
            selectedUser.applicant_type === "Guarantor" && (
              <AddGuarantor
                editGuarantor={editParticipantData}
                makeEditable={savedEditedParticipantDetails}
                add_new_coapplicant_inside_modal={true}
                handleCancel={closeEditParticipantDetailsModal}
                closeModal={true}
              />
            )}
        </Modal>
        <InformationModal
          isVisible={informationModal}
          informationContent={informationTitle}
          title={"Credit Review Pending"}
          handleOk={() => {
            setInformationModal(false);
          }}
        />
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
    states: auth.states,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
  };
};
export default connect(mapStateToProps, null)(DetailDocform);
