import { INITIAL_STATE } from "../../constants/constants";
import {
  DOWNLOAD_HUNTER_REPORT,
  DOWNLOAD_HUNTER_SUMMARY_REPORT,
  KYC_DONE_CASES_LIST,
  RUN_HUNTER,
  HUNTER_SUMMARY_LIST,
  SET_HUNTER_SUMMARY,
  GET_HUNTER_DETAIL_BY_CASE
} from "../constants/ActionTypes";

const DEFAULT_STATE = INITIAL_STATE;
export default function hunter(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case DOWNLOAD_HUNTER_REPORT:
      return {
        ...state,
        hunterData: action.hunterData,
      };
    case KYC_DONE_CASES_LIST:
      return {
        ...state,
        hunterData: action.hunterData,
      };
    case RUN_HUNTER:
      return {
        hunterData: action.hunterData,
      };
    case HUNTER_SUMMARY_LIST:
      return {
        ...state,
        hunterData: action.hunterData,
      };
    case GET_HUNTER_DETAIL_BY_CASE:
      return{
        ...state,
        hunterData: action.hunterData,
      }
    default:
      return state;
  }
}
