import {
  ADD_MEMBER_DIRECTOR,
  DELETE_MEMBER_DIRECTOR,
  GET_MEMBER_DIRECTOR_DETAIL,
  GET_MEMBER_DIRECTOR_LIST,
} from "../constants/ActionTypes";

/**
 * @method onAddParticipant
 * @description add participant(main applicant,co-applicant or guarantor)
 * @param {*}
 * @returns
 */
export const onAddMemberOrDirector = (data, onSuccess) => ({
  type: ADD_MEMBER_DIRECTOR,
  data,
  onSuccess,
});
export const onDeleteMemberOrDirector = (id, onSuccess) => {
  return {
    type: DELETE_MEMBER_DIRECTOR,
    id,
    onSuccess,
  };
};
export const onMemberDirectorDetail = (id, onSuccess) => ({
  type: GET_MEMBER_DIRECTOR_DETAIL,
  id,
  onSuccess,
});
export const onGetMemberDirectorList = (data, onSuccess) => ({
  type: GET_MEMBER_DIRECTOR_LIST,
  data,
  onSuccess,
});
