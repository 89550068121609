import React, { Component, useEffect, useState } from "react";
import { Col, Tabs, Row, Card, Button, Modal, Form } from "antd";
import LeadDetail from "../components/ongoincasetabs2/leaddetails";
// import DetailDoc from "../components/ongoincasetabs2/detaildocform";
import Coapplicantform from "../components/ongoincasetabs2/case-detail/coapplicantform";
// import Guarantorform from "../components/demotabs/guarantorform";
import Guarantorform from "../components/ongoincasetabs2/case-detail/guarantorform";
import BureauReportForm from "../components/ongoingcaselist/ongoingcasestabs/modals/bureaureport";
import AskJustification from "../components/ongoingcaselist/ongoingcasestabs/modals/askjustification";
import ReAllocate from "../components/casedetail/modals/reallocate";
import ComminucationForm from "../components/casedetail/communication";
import { Link, useParams, useHistory } from "react-router-dom";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import Mainapplicantform from "../components/ongoincasetabs2/case-detail/mainapplicantform";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  onChangeFirstTabKey,
  onChangeMiddleTab,
  onChangeSecondTabKey,
  onChangeThirdTabKey,
  onFirstTabChange,
} from "../redux/actions/TabAction";
import {
  onCaseDetail,
  onResetNewCaseCreated,
  onUpdateNewCreatedCase,
  onRequestReview,
  onGetUserByStateCity,
  onCaseReallocation,
  onCaseRejectApprove,
  onGetCaseDetailExcel,
} from "../redux/actions/CaseAction";
import { onGetCities, onGetStates } from "../redux/actions/MasterData";

import { onChangeSelectedUser } from "../redux/actions/AuthAction";
// import { onCaseDetail, onChangeSelectedUser, onFirstTabChange, onSecondTabChange } from "../redux/actions/AuthAction";
import RequestCreditReview from "../components/modals/requestCreditReview";
import {
  isCaseClosed,
  isCaseNotClosed,
  isCreditReviewPending,
  isCreditUser,
  isKycDone,
  isSalesUser,
  isVolvoUser,
  loggedInUserData,
} from "../helpers/utils";
import ReAllocationModal from "../components/modals/reAllocationModal";
import { STAGES, STATUS } from "../constants/constants";
import { toast } from "react-toastify";
import { closeRunOcrModal } from "../components/case-participants/helpers/utils";
import RunOcr from "../components/case-participants/applicant-ocr";
import RejectCaseModal from "../components/modals/RejectCaseModal";
import { InformationModal } from "../components/modals/InformationModal";

const { TabPane } = Tabs;
const OnGoingCaseDetailView = (props) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const params = useParams();
  const { caseDetail = {}, firstTab } = props;
  const [bureauReport, setBureauReport] = useState(false);
  const [creditReview, setCreditReview] = useState(false);
  const [reallocate, setReallocate] = useState(false);
  const [justification, setJustification] = useState(false);
  const [reason, setReason] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [rejectCaseOpen, setRejectCaseOpen] = useState(false);
  const [bureauReportNotDoneModal, setBureauReportNotDoneModal] =
    useState(false);
  const [modalTitle, setModalTitle] = useState(false);
  const [userType, setUserType] = useState("");
  const userData = loggedInUserData();
  const [listofusers, setListofusers] = useState([]);
  const firstTabKey = useSelector((state) => state.tabReducer.firstTabKey);
  const state = useSelector((state) => state.auth.states);
  const cities = useSelector((state) => state.auth.cities);
  const ocrResponse = useSelector(
    (state) => state.ocrAndNonOcrReducer.runOcrResponse
  );
  const userPermissions = useSelector((state) => state.auth.userPermissions);

  const dispatch = useDispatch();
  const creditreview = (creditReview) => {
    setCreditReview(creditReview);
  };
  useEffect(() => {
    dispatch(onGetStates());
  }, [dispatch]);
  useEffect(() => {
    return function () {
      dispatch(onResetNewCaseCreated());
      dispatch(onGetStates());
    };
  }, [dispatch]);
  useEffect(() => {
    setUserType(userData.user_type);
    // dispatch(onFirstTabChange("1"));
    dispatch(onChangeFirstTabKey("1"));
    dispatch(onChangeSecondTabKey("1"));
    dispatch(onChangeThirdTabKey("1"));

    const onSuccess = (caseDetail) => {
      if (
        caseDetail &&
        caseDetail.status &&
        caseDetail.status === "Case Logged"
      ) {
        props.history.push(`/viewcase/${caseDetail.id}`);
      } else {
        dispatch(onUpdateNewCreatedCase(caseDetail, () => {}));
      }
      // dispatch(onChangeSelectedUser(caseDetail.main_applicant));
    };
    // dispatch(onSecondTabChange("1"))
    const onError = () => {
      props.history.push("/OnGoingCases");
    };
    if (params.id) {
      const data = {
        caseId: params.id,
      };
      dispatch(onCaseDetail(data, onSuccess, onError));
    }
  }, [dispatch, params.id, props.history, userData.user_type]);
  // useEffect(() => {
  //   // Specify how to clean up after this effect:
  //   return function removeNewlyCreatedCase() {
  //     dispatch(onResetNewCaseCreated())
  //   };
  // });
  const handleCancel = (data) => {
    setBureauReport(false);
    setJustification(false);
    setCreditReview(false);
    setReallocate(false);
    form.resetFields();
  };
  const closeReallocationModal = () => {
    setReallocate(false);
  };
  const handleSubmit = (data) => {
    setCreditReview(false);
    data.fk_case_id = caseDetail.id;
    dispatch(
      onRequestReview(
        data,
        () => {
          dispatch(
            onCaseDetail({ caseId: caseDetail.id }, () => {
              toast.success(data.message);
            })
          );
        },
        onError
      )
    );
    form.resetFields();
  };
  const onError = (data) => {};
  const firstTabChange = (tab) => {
    dispatch(onChangeFirstTabKey(tab));
    dispatch(onChangeSecondTabKey("1"));
    dispatch(onChangeThirdTabKey("1"));
    if (tab === "2") {
      dispatch(onChangeSelectedUser(caseDetail.main_applicant));
    } else if (tab === "3") {
      dispatch(
        onChangeSelectedUser(
          caseDetail.co_applicants.length > 0 ? caseDetail.co_applicants[0] : {}
        )
      );
    } else if (tab === "4") {
      dispatch(
        onChangeSelectedUser(
          caseDetail.guarantors.length > 0 ? caseDetail.guarantors[0] : {}
        )
      );
    } else if (tab === "5") {
      dispatch(onCaseDetail({ caseId: caseDetail.id }, () => {}));
    }
  };
  const handleCaseApprove = () => {};
  const handleCaseReject = () => {
    if (caseDetail.bureau_report_generated == 0) {
      setModalTitle(
        "Please generate atleast one bureau report for main-applicant and one bureau report for either co-applicant or guarantor"
      );
      setBureauReportNotDoneModal(true);
    } else {
      setModalTitle("");
      setBureauReportNotDoneModal(false);
      setRejectCaseOpen(true);
    }
  };
  const handleCancelReject = () => {
    setRejectCaseOpen(false);
  };
  const handleRejectForm = (values) => {
    values.case_id = caseDetail.id;

    values.is_approved = 0;
    dispatch(onCaseRejectApprove(values, onSuccess));
    setRejectCaseOpen(false);
  };

  const onSuccess = (response) => {
    props.history.push("/closedcases");
  };
  const handleOk = (values) => {
    values.case_id = caseDetail.id;

    dispatch(onCaseReallocation(values, onSuccessApi));
  };

  const onSuccessApi = (response) => {
    form.resetFields();
    setReallocate(false);
    props.history.push("/OnGoingCases");
  };
  const getCity = (data) => {
    dispatch(onGetCities(data, onSuccessCity));
  };
  const onSuccessCity = (data) => {};
  const onSuccessUsers = (data) => {
    setListofusers(data);
  };
  const getUserbystatecity = () => {
    dispatch(onGetUserByStateCity(onSuccessUsers));
  };
  const checkBureauReportDone = () => {
    if (caseDetail.bureau_report_generated == 0) {
      setModalTitle(
        "Please generate bureau report for all added participants. Note: There should be atleast either one co-applicant or one guarantor"
      );
      setBureauReportNotDoneModal(true);
    } else {
      setModalTitle("");
      setBureauReportNotDoneModal(false);
      history.push(`/BureauReport/${caseDetail.id}`);
    }
  };
  const openReallocationModal = () => {
    setReallocate(true);
    const onSuccessUsers = (data) => {
      setListofusers(() => {
        return data;
      });
    };
    dispatch(onGetUserByStateCity(onSuccessUsers));
  };
  const onDownloadCaseDetailSuccess = (response) => {
    const outputFilename = `Case_Detail_${caseDetail.id}.xlsx`;
    // const url = URL.createObjectURL(new Blob([response]));
    const link = document.createElement("a");
    link.href = response;
    link.setAttribute("download", outputFilename);
    document.body.appendChild(link);
    link.click();
  };

  const downloadCaseDetailSheet = () => {
    dispatch(onGetCaseDetailExcel(caseDetail.id, onDownloadCaseDetailSuccess));
  };
  return (
    <React.Fragment>
      <TopBarNav />
      <Navigation />
      <div className="GeneralTabsWrap LeadDetailTabs">
        <div className="CaseDetailHeader">
          <div className="InnerWrap">
            <Row>
              <Col span={4} xs={24} sm={24} md={12}>
                <p className="CaseId">
                  Case ID - {caseDetail.id}{" "}
                  <span>
                    <span>{caseDetail.status}</span>
                    <span>{caseDetail.created_on}</span>
                  </span>
                </p>
              </Col>
              <Col span={4} xs={24} sm={24} md={12}>
                <div className="BtnWrap">
                  {isCaseClosed(caseDetail.status) && (
                    <Button
                      onClick={() => {
                        setReason(caseDetail.approve_reject_reason);
                        setIsModalVisible(true);
                      }}
                    >
                      View Reason
                    </Button>
                  )}
                  {((!isCreditReviewPending(caseDetail.status) &&
                    isSalesUser(userData)) ||
                    (isCreditReviewPending(caseDetail.status) &&
                      isCreditUser(userData))) &&
                    caseDetail.status !== STATUS.REJECTED &&
                    caseDetail.status !== STATUS.CREDIT_BUREAU_APPROVED && (
                      <React.Fragment>
                        {userData &&
                          userData.permissions &&
                          userData.permissions.Reallocate_case &&
                          userData.permissions.Reallocate_case.add && (
                            <Button onClick={openReallocationModal}>
                              Re-Allocate
                            </Button>
                          )}

                        {userType === "Sales" && (
                          <Button
                            onClick={() => {
                              console.log("HIIII");
                              if (
                                !caseDetail.credit_review ||
                                caseDetail.credit_review == 0
                              ) {
                                console.log("HIIII2222");
                                setModalTitle(
                                  "Please generate bureau report for all added participants"
                                );
                                setBureauReportNotDoneModal(true);
                              } else {
                                console.log("HIIII3333");
                                setModalTitle("");
                                creditreview(true);
                              }
                            }}
                          >
                            Request Credit for Review
                          </Button>
                        )}
                        <Button onClick={checkBureauReportDone}>
                          {/*<Link
                            to={
                              caseDetail.bureau_report_generated != 0 &&
                              `/BureauReport/${caseDetail.id}`
                            }
                          >
                            Approve
                          </Link>*/}
                          Approve
                        </Button>
                        <Button onClick={handleCaseReject}>Reject</Button>
                      </React.Fragment>
                    )}
                  {/*<Button onClick={handleCaseApprove}>Approve</Button>*/}
                  <Button onClick={downloadCaseDetailSheet}>Download</Button>
                  <Button>
                    <Link
                      to={
                        caseDetail.status === STATUS.CREDIT_REVIEW_PENDING
                          ? "/PendingCreditReview"
                          : isCaseNotClosed(caseDetail.status)
                          ? "/OnGoingCases"
                          : "/closedcases"
                      }
                    >
                      Back
                    </Link>
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="popbox">
          {/* <Card className="popcard">
            <h3>System Recommendation</h3>
            <h4>
              Basis The Co -Applicant2 Details The Loan Can Be Sanctioned To The
              User. The Case Looks Like Doable At This Stage.{" "}
            </h4>
          </Card> */}
        </div>
        <Tabs activeKey={firstTabKey} onChange={firstTabChange}>
          <TabPane
            tab={
              userData && isVolvoUser(userData)
                ? "Case Details"
                : "Lead Details"
            }
            key="1"
          >
            <LeadDetail />
          </TabPane>
          <TabPane tab="Main Applicant" key="2">
            <Mainapplicantform />
          </TabPane>
          <TabPane tab="Co-Applicant" key="3">
            <Coapplicantform />
          </TabPane>
          <TabPane tab="Guarantor" key="4">
            <Guarantorform />
          </TabPane>
          {/*{(caseDetail && caseDetail.kyc_done == 1 && isSalesUser(userData)) ||
          caseDetail.status === STATUS.CREDIT_REVIEW_PENDING ||
          !isCaseNotClosed(caseDetail.status) ? (
            <TabPane tab="Communication" key="5">
              <ComminucationForm />
              {isSalesUser(userData)}
            </TabPane>
          ) : (
            ""
          )}*/}
          <TabPane tab="Communication" key="5">
            <ComminucationForm />
            {isSalesUser(userData)}
          </TabPane>
        </Tabs>
      </div>

      {/* Reject case Modal */}
      <RejectCaseModal
        isModalVisible={rejectCaseOpen}
        handleOk={handleRejectForm}
        handleCancel={handleCancelReject}
      />
      {/* Reject case Modal */}

      {/* Run Bureao Reports */}
      <Modal
        title="Bureau Report Generation"
        centered
        visible={bureauReport}
        onOk={() => setBureauReport(false)}
        onCancel={() => setBureauReport(false)}
        footer={null}
        width={1000}
        className="BeureauReport"
        style={{ top: 50 }}
      >
        <BureauReportForm />
      </Modal>
      <Modal
        title="Justification Requirements"
        centered
        visible={justification}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button className="filled" onClick={handleCancel}>
            <Link to="/viewonGoincasecommunication"> Submit</Link>
          </Button>,
        ]}
        width={1000}
        className="JustReqWrap"
        style={{ top: 50 }}
      >
        <AskJustification />
      </Modal>
      <RequestCreditReview
        isModalVisible={creditReview}
        handleCancel={handleCancel}
        handleOk={handleSubmit}
        form={form}
      />

      <ReAllocationModal
        isModalVisible={reallocate}
        handleOk={handleOk}
        handleCancel={closeReallocationModal}
        states={state}
        cities={cities}
        getCity={getCity}
        getUserbystatecity={getUserbystatecity}
        listofusers={listofusers}
      />
      {/* ReAllocate */}
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={ocrResponse}
        // visible={true}
        onOk={() => closeRunOcrModal(dispatch)}
        onCancel={() => closeRunOcrModal(dispatch)}
        footer={[
          <Button
            className="JustReqWrap"
            type="primary"
            onClick={() => closeRunOcrModal(dispatch)}
          >
            Ok
          </Button>,
        ]}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunOcr />
      </Modal>
      <InformationModal
        isVisible={bureauReportNotDoneModal}
        informationContent={modalTitle}
        title={"Credit Review Pending"}
        handleOk={() => {
          setBureauReportNotDoneModal(false);
        }}
      />
      <InformationModal
        isVisible={isModalVisible}
        informationContent={reason}
        // title={toAddMemberDirector}
        handleOk={() => {
          setReason("");
          setIsModalVisible(false);
        }}
      />
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
  };
};
export default connect(mapStateToProps, null)(OnGoingCaseDetailView);
