import React, { Component, useEffect, useState } from "react";
import { Col, Tabs, Row, Card, Button, Modal, Form } from "antd";
import LeadDetail from "../components/ongoincasetabs2/leaddetails";
// import DetailDoc from "../components/ongoincasetabs2/detaildocform";
import Coapplicantform from "../components/ongoincasetabs2/coapplicantform";
// import Guarantorform from "../components/demotabs/guarantorform";
import Guarantorform from "../components/ongoincasetabs2/guarantorform";
import BureauReportForm from "../components/ongoingcaselist/ongoingcasestabs/modals/bureaureport";
import AskJustification from "../components/ongoingcaselist/ongoingcasestabs/modals/askjustification";
import ReAllocate from "../components/casedetail/modals/reallocate";
import ComminucationForm from "../components/casedetail/communication";
import { Link, useParams } from "react-router-dom";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import Mainapplicantform from "../components/ongoincasetabs2/mainapplicantform";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  onChangeMiddleTab,
  onFirstTabChange,
} from "../redux/actions/TabAction";
import {
  onCaseDetail,
  onGetUserByStateCity,
  onCaseReallocation,
} from "../redux/actions/CaseAction";
import { onChangeSelectedUser } from "../redux/actions/AuthAction";
// import { onCaseDetail, onChangeSelectedUser, onFirstTabChange, onSecondTabChange } from "../redux/actions/AuthAction";
import { onRequestReview } from "../redux/actions/CaseAction";
import RequestCreditReview from "../components/modals/requestCreditReview";
import { loggedInUserData } from "../helpers/utils";
import ReAllocationModal from "../components/modals/reAllocationModal";
import { toast } from "react-toastify";
import { onGetCities, onGetStates } from "../redux/actions/MasterData";

const { TabPane } = Tabs;
const ViewOngoingCaseTabs = (props) => {
  const [form] = Form.useForm();
  const params = useParams();
  const { caseDetail = {}, firstTab } = props;
  const [bureauReport, setBureauReport] = useState(false);
  const [creditReview, setCreditReview] = useState(false);
  const [reallocate, setReallocate] = useState(false);
  const [justification, setJustification] = useState(false);
  const [listofusers, setListofusers] = useState([]);
  const [userType, setUserType] = useState("");
  const userData = loggedInUserData();
  const state = useSelector((state) => state.auth.states);
  const cities = useSelector((state) => state.auth.cities);
  const dispatch = useDispatch();
  // const askJustification = (justification) => {
  //   setJustification(justification)
  // }
  const creditreview = (creditReview) => {
    setCreditReview(creditReview);
  };
  // const ReAllocate = (reallocate) => {
  //   this.setState({ reallocate });
  // }
  useEffect(() => {
    setUserType(userData.user_type);
    dispatch(onFirstTabChange("1"));
    const onSuccess = (caseDetail) => {
      // dispatch(onChangeSelectedUser(caseDetail.main_applicant));
    };
    // dispatch(onSecondTabChange("1"))
    const onError = () => {
      props.history.push("/OnGoingCases");
    };
    if (params.id) {
      const data = {
        caseId: params.id,
      };
      dispatch(onCaseDetail(data, onSuccess, onError));
    }
  }, [dispatch, params.id, props.history, userData.user_type]);

  const handleCancel = (data) => {
    setBureauReport(false);
    setJustification(false);
    setCreditReview(false);
    setReallocate(false);
    form.resetFields();
  };
  const handleSubmit = (data) => {
    setCreditReview(false);
    data.fk_case_id = caseDetail.id;
    dispatch(
      onRequestReview(
        data,
        () => {
          dispatch(
            onCaseDetail({ caseId: caseDetail.id }, () => {
              toast.success(data.message);
            })
          );
        },
        onError
      )
    );
    form.resetFields();
  };
  const onError = (data) => {};

  const handleOk = (values) => {
    values.case_id = caseDetail.id;

    dispatch(onCaseReallocation(values, onSuccessApi));
  };

  const onSuccessApi = (response) => {
    form.resetFields();
    setReallocate(false);
    props.history.push("/OnGoingCases");
  };
  const getCity = (data) => {
    dispatch(onGetCities(data, onSuccessCity));
  };
  const onSuccessCity = (data) => {};
  const onSuccessUsers = (data) => {
    setListofusers(data);
  };
  const getUserbystatecity = () => {
    dispatch(onGetUserByStateCity(onSuccessUsers));
  };
  return (
    <React.Fragment>
      <TopBarNav />
      <Navigation />
      <div className="GeneralTabsWrap LeadDetailTabs">
        {/* <div className='popbox'>
            <Card className='popcard'>
              <h3>System Recommendation</h3>
              <h4>Basis The Co -Applicant2 Details The Loan Can Be Sanctioned To The User. The Case Looks Like Doable At This Stage. </h4>
            </Card>
        </div> */}
        <div className="CaseDetailHeader">
          <div className="InnerWrap">
            <Row>
              <Col span={4} xs={24} sm={24} md={12}>
                <p className="CaseId">
                  Case ID - {caseDetail.id}{" "}
                  <span>
                    Status <span>{caseDetail.status}</span>
                  </span>
                </p>
              </Col>
              <Col span={4} xs={24} sm={24} md={12}>
                <div className="BtnWrap">
                  {/* <Button onClick={() => this.setModal2Visible(true)}>
                  Upload Documents
                </Button> */}
                  <Button onClick={() => setReallocate(true)}>
                    Re-Allocate
                  </Button>
                  {/* <Button  onClick={() => this.bureauReport(true)}>Run Bureao Reports</Button> */}
                  {/* <Button onClick={() => this.askJustification(true)}>Ask for Justification</Button>  */}
                  {userType === "Sales" ? (
                    <Button onClick={() => creditreview(true)}>
                      Request Credit for Review
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="popbox">
          <Card className="popcard">
            <h3>System Recommendation</h3>
            <h4>
              Basis The Co -Applicant2 Details The Loan Can Be Sanctioned To The
              User. The Case Looks Like Doable At This Stage.{" "}
            </h4>
          </Card>
        </div>
        <Tabs
          defaultActiveKey={firstTab}
          activeKey={firstTab}
          onChange={(tab) => {
            dispatch(onChangeMiddleTab("1"));
            dispatch(onFirstTabChange(tab));
            if (tab === "2") {
              dispatch(onChangeSelectedUser(caseDetail.main_applicant));
            } else if (tab === "3") {
              dispatch(
                onChangeSelectedUser(
                  caseDetail.co_applicants.length > 0
                    ? caseDetail.co_applicants[0]
                    : {}
                )
              );
            } else if (tab === "4") {
              dispatch(
                onChangeSelectedUser(
                  caseDetail.guarantors.length > 0
                    ? caseDetail.guarantors[0]
                    : {}
                )
              );
            }
          }}
        >
          <TabPane tab="Lead Details" key="1">
            <LeadDetail />
          </TabPane>
          <TabPane tab="Main Applicant" key="2">
            <Mainapplicantform />
          </TabPane>
          <TabPane tab="Co-Applicant" key="3">
            <Coapplicantform />
          </TabPane>
          <TabPane tab="Guarantor" key="4">
            <Guarantorform />
          </TabPane>
          <TabPane tab="Communication" key="5">
            <ComminucationForm />
          </TabPane>
        </Tabs>
      </div>
      {/* Run Bureao Reports */}
      <Modal
        title="Bureau Report Generation"
        centered
        visible={bureauReport}
        onOk={() => setBureauReport(false)}
        onCancel={() => setBureauReport(false)}
        footer={null}
        width={1000}
        className="BeureauReport"
        style={{ top: 50 }}
      >
        <BureauReportForm />
      </Modal>
      {/* Run Bureao Reports */}
      {/* Ask For Justification */}
      <Modal
        title="Justification Requirements "
        centered
        visible={justification}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button className="filled" onClick={handleCancel}>
            <Link to="/viewonGoincasecommunication"> Submit</Link>
          </Button>,
        ]}
        width={1000}
        className="JustReqWrap"
        style={{ top: 50 }}
      >
        <AskJustification />
      </Modal>
      {/* Ask For Justification */}

      {/* Credit Review */}
      {caseDetail && caseDetail.kyc_done == 1 ? (
        <RequestCreditReview
          isModalVisible={creditReview}
          handleCancel={handleCancel}
          handleOk={handleSubmit}
          form={form}
        />
      ) : (
        ""
      )}
      <ReAllocationModal
        isModalVisible={reallocate}
        handleOk={handleOk}
        handleCancel={handleCancel}
        states={state}
        cities={cities}
        getCity={getCity}
        getUserbystatecity={getUserbystatecity}
        listofusers={listofusers}
      />

      {/* ReAllocate */}
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
  };
};
export default connect(mapStateToProps, null)(ViewOngoingCaseTabs);
