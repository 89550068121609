import React, { Component, useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { OCR_DOCUMENT_LABEL } from "../../../../../constants/constants";
const AddharCard = (props) => {
  const ocrResult = useSelector((state) => state.auth.currentOcrResult);
  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  const [aadharOcrResult, setAadharOcrResult] = useState({});
  const [aadharKycResult, setPanKycResult] = useState({});
  useEffect(() => {
    let aadharOCR = ocrResult.find((ocr_result) => {
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD;
    });
    if (aadharOCR) {
      setAadharOcrResult(aadharOCR);
    }
  }, [ocrResult]);
  useEffect(() => {
    let aadharKyc = kycResult.find((ocr_result) => {
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD;
    });
    if (aadharKyc) {
      setPanKycResult(aadharKyc);
    }
  }, [kycResult]);
  return (
    <>
      <div className="CardBlock">
        <h3>Aadhar Card</h3>
        <Card>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Aadhar Number</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharOcrResult
                  ? aadharOcrResult.aadhaar_no
                    ? aadharOcrResult.aadhaar_no
                    : "-"
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharOcrResult
                  ? aadharOcrResult.name
                    ? aadharOcrResult.name
                    : "-"
                  : "-"}
              </p>
            </Col>
          </Row>
          {aadharOcrResult && aadharOcrResult.father && (
            <Row>
              <Col span={12} md={12} xs={24} sm={24}>
                <h4>Father Name</h4>
              </Col>
              <Col span={12} md={12} xs={24} sm={24}>
                <p>
                  {aadharOcrResult
                    ? aadharOcrResult.father
                      ? aadharOcrResult.father
                      : "-"
                    : "-"}
                </p>
              </Col>
            </Row>
          )}
          {aadharOcrResult && aadharOcrResult.husband && (
            <Row>
              <Col span={12} md={12} xs={24} sm={24}>
                <h4>Husband Name</h4>
              </Col>
              <Col span={12} md={12} xs={24} sm={24}>
                <p>
                  {aadharOcrResult
                    ? aadharOcrResult.husband
                      ? aadharOcrResult.husband
                      : "-"
                    : "-"}
                </p>
              </Col>
            </Row>
          )}
          {aadharOcrResult && aadharOcrResult.mother && (
            <Row>
              <Col span={12} md={12} xs={24} sm={24}>
                <h4>Mother Name</h4>
              </Col>
              <Col span={12} md={12} xs={24} sm={24}>
                <p>
                  {aadharOcrResult
                    ? aadharOcrResult.mother
                      ? aadharOcrResult.mother
                      : "-"
                    : "-"}
                </p>
              </Col>
            </Row>
          )}
          {aadharOcrResult && aadharOcrResult.spouse && (
            <Row>
              <Col span={12} md={12} xs={24} sm={24}>
                <h4>Spouse Name</h4>
              </Col>
              <Col span={12} md={12} xs={24} sm={24}>
                <p>
                  {aadharOcrResult
                    ? aadharOcrResult.spouse
                      ? aadharOcrResult.spouse
                      : "-"
                    : "-"}
                </p>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharOcrResult
                  ? aadharOcrResult.dob
                    ? aadharOcrResult.dob
                    : "-"
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Gender</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharOcrResult
                  ? aadharOcrResult.gender
                    ? aadharOcrResult.gender
                    : "-"
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Address</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharOcrResult
                  ? aadharOcrResult.address
                    ? aadharOcrResult.address
                    : "-"
                  : "-"}
              </p>
            </Col>
          </Row>
          {aadharOcrResult && aadharOcrResult.state &&           <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>State</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{aadharOcrResult?aadharOcrResult.state?aadharOcrResult.state:'-':'-'}</p>
            </Col>
          </Row>}
          {aadharOcrResult && aadharOcrResult.state_code &&           <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>State Code</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{aadharOcrResult?aadharOcrResult.state_code?aadharOcrResult.state_code:'-':'-'}</p>
            </Col>
          </Row>}
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>PIN</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharOcrResult
                  ? aadharOcrResult.pin
                    ? aadharOcrResult.pin
                    : "-"
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Address Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharKycResult.address_matching_result
                  ? aadharKycResult.address_matching_result
                  : "-"}
              </p>
            </Col>
          </Row>

          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Name Matching With Given Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharKycResult.name_matching_result
                  ? aadharKycResult.name_matching_result
                  : "-"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>
                {aadharKycResult.dob_matching_result
                  ? aadharKycResult.dob_matching_result
                  : "-"}
              </p>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default AddharCard;
