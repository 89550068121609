import React, { Component, useEffect, useState } from "react";
import { Collapse, Row, Col, Card, Button, Modal, Form } from "antd";
import InfoMatching from "./informationmatching";
import InformationValidity from "./informationvalidity";
import PanCard from "./pancard";
import VoterId from "./voterid";
import DrivingLicense from "./drivinglicense";
import AddharCard from "./aadharcard";
import ReAllocate from "../../../casedetail/modals/reallocate";
import RunKycCheck from "../../../casedetail/modals/runkyccheck";
import BureauReportForm from "../../../ongoingcaselist/ongoingcasestabs/modals/bureaureport";
import AskJustification from "../../../casedetail/modals/askjustification";
import CreditReview from "../../../casedetail/modals/creditreview";
import { Link } from "react-router-dom";
import RunKyc from "../../../lognewcase/modals/runSingleOcr";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  APPLICANT_TYPE,
  OCR_DOCUMENT_LABEL,
  STAGES,
  STATUS,
} from "../../../../constants/constants";
// import RunOcr from "../../../lognewcase/modals/runSingleOcr";
import {
  onCaseDetail,
  onGetUsercrResult,
  onUpdateNewCreatedCase,
} from "../../../../redux/actions/CaseAction";
import {
  onFetchBureauReportMaster,
  onGenerateBureauReport,
  onFetchBureauReportListing,
  onFetchCibilReportMaster,
  onGenerateCibilReport,
  onGetBureauReportCheckParam
} from "../../../../redux/actions/BureauReportAction";
import moment from "moment";
import {
  onChangeSelectedMemberDirector,
  onChangeSelectedUser,
} from "../../../../redux/actions/AuthAction";
import RunOcr from "../../../case-participants/applicant-ocr";
import { onRunKyc } from "../../../../redux/actions/KycAction";
import {
  onRunOcr,
  onRunOcrResponse,
  onSetCurrentKycResult,
  onSetCurrentOcrResult,
  onUserOcrResult,
} from "../../../../redux/actions/OcrAction";
import { onMemberDirectorDetail } from "../../../../redux/actions/MemberAndDirectorAction";
import AskForJustificationModal from "../../../modals/askForJustificationModal";
import { onJustificationComment } from "../../../../redux/actions/CaseAction";
import { toast } from "react-toastify";
import {
  isCaseNotClosed,
  isCreditUser,
  isKycDone,
  isNotDropped,
  isOcrDone,
  isSalesUser,
  loggedInUserData,
} from "../../../../helpers/utils";
import { onChangeThirdTabKey } from "../../../../redux/actions/TabAction";
import { onGetCities, onGetCibilCities } from "../../../../redux/actions/MasterData";
import { InformationModal } from "../../../modals/InformationModal";
import Passport from "./passport";
import GSTRegistration from "./gstregistration";
import { MESSAGES } from "../../../../constants/messages";
import ConfirmModal from "../../../ongoingcaselist/ongoingcasestabs/modals/bureaureport/ConfirmModal";

const { Panel } = Collapse;
const KycDetail = (props) => {
  const { caseDetail, firstTab } = props;
  const userData = loggedInUserData();
  const dispatch = useDispatch();
  const [askJustification, setAskJustification] = useState(false);
  const [bureauReport, setBureauReport] = useState(false);
  const [reallocate, setReallocation] = useState(false);
  const [creditReview, setCreditReview] = useState(false);
  const [runKycCheck, setRunKycCheck] = useState(false);
  const [runOcr, setRunOcr] = useState(false);
  const [ocrResponse, setOcrResponse] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  // const [kycResult, setKycResult] = useState(false);
  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  const [panCardDetails, setPanCardDetails] = useState(false);
  const [aadharCardDetails, setAadharCardDetails] = useState(false);
  const [drivingLicenseDetails, setDrivingLicenseDetails] = useState(false);
  const [voterIDDetails, setVoterIDDetails] = useState(false);
  const [passportDetails, setPassportDetails] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [bureauSubmitData, setBureauSubmitData] = useState({});
  const [confimMessage, SetConfimMessage] = useState(false);
  const [buttonDisable, SetButtonDisable] = useState(false);

  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  const [selectedUser, setSelectedUser] = useState({});
  const secondTabKey = useSelector((state) => state.tabReducer.secondTabKey);
  const selectedMemberDirector = useSelector(
    (state) => state.auth.selectedMemberDirector
  );
  const [isMemberOrDirector, setIsMemberOrDirector] = useState(false);
  const firstTabKey = useSelector((state) => state.tabReducer.firstTabKey);
  const bureauReportMasters = useSelector(
    (state) => state.bureauReport.bureauReportMasters
  );
  const cibilReportMasters = useSelector(
    (state) => state.bureauReport.cibilReportListing
  );
  // const userPermissions = useSelector(state => state.auth.userPermissions)

  const states = useSelector((state) => state.auth.states);
  const city = useSelector((state) => state.auth.cities);
  const cibilcities = useSelector((state) => state.auth.cibilcities);
  const ocrresult = useSelector((state) => state.caseReducer.ocrresultData);

  const [kycResponse, setKycResponse] = useState(false);
  const [form2] = Form.useForm();
  const [form] = Form.useForm();
  const [informationModal, setInformationModal] = useState(false);
  const [userOcrResult, setUserOcrResult] = useState([]);
  const thirdTabKey = useSelector((state) => state.tabReducer.thirdTabKey);

  useEffect(() => {
    let ind = false;
    if (selectedApplicant.category_name === "Individual") {
      ind = true;
    }
    dispatch(onFetchBureauReportMaster(ind));
    dispatch(onFetchCibilReportMaster());
  }, [dispatch, selectedApplicant]);
  useEffect(() => {
    //
    // setKycResult(false);
    if (secondTabKey === "1") {
      setIsMemberOrDirector(false);
      setSelectedUser(selectedApplicant);
    } else {
      setIsMemberOrDirector(true);
      setSelectedUser(selectedMemberDirector);
    }
  }, [secondTabKey, selectedMemberDirector, selectedApplicant]);
  useEffect(() => {
    if (thirdTabKey === "2") {
      if (selectedApplicant.applicant_type && selectedApplicant.id) {
        let data = {
          applicant_type: selectedApplicant.applicant_type,
          applicant_coapplicant_guarantor_id: selectedApplicant.id,
        };
        if (isMemberOrDirector) {
          data = {
            ...data,
            member_director_id: selectedUser.id,
          };
        }
        dispatch(
          onUserOcrResult(data, (userOcrResult) => {
            dispatch(onSetCurrentOcrResult(userOcrResult));
            setUserOcrResult(() => userOcrResult);
          })
        );
      }
    }
  }, [thirdTabKey, isMemberOrDirector]);
  useEffect(() => {
    if (
      selectedUser &&
      selectedUser.kyc_result &&
      Array.isArray(selectedUser.kyc_result) &&
      selectedUser.kyc_result.length > 0
    ) {
      dispatch(onSetCurrentKycResult(selectedUser.kyc_result));
      // setKycResult(selectedUser.kyc_result);
      selectedUser.kyc_result.map((kycDocument) => {
        if (kycDocument.document_name === OCR_DOCUMENT_LABEL.PAN_CARD) {
          setPanCardDetails(kycDocument);
        } else if (
          kycDocument.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD
        ) {
          setAadharCardDetails(kycDocument);
        } else if (
          kycDocument.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE
        ) {
          setDrivingLicenseDetails(kycDocument);
        } else if (kycDocument.document_name === OCR_DOCUMENT_LABEL.VOTER_ID) {
          setVoterIDDetails(kycDocument);
        } else if (kycDocument.document_name === OCR_DOCUMENT_LABEL.PASSPORT) {
          setPassportDetails(kycDocument);
        }
      });
    }
  }, [selectedUser]);

  const handleCancel = () => {
    setAskJustification(false);
    setCreditReview(false);
    setReallocation(false);
    setBureauReport(false);
    form2.resetFields();
    form.resetFields();
  };
  const updateSelectedUser = () => {
    if (isMemberOrDirector) {
      dispatch(
        onMemberDirectorDetail(selectedUser.id, (member_director) => {
          setSelectedUser(member_director);
          dispatch(onChangeSelectedMemberDirector(member_director));
        })
      );
    } else {
      dispatch(
        onCaseDetail({ caseId: caseDetail.id }, (response) => {
          if (firstTabKey === "2") {
            dispatch(onChangeSelectedUser(response.main_applicant));
          } else if (firstTabKey === "3") {
            let co_applicant = response.co_applicants.find((co_applicant) => {
              return co_applicant.id == selectedUser.id;
            });
            dispatch(onChangeSelectedUser(co_applicant));
          } else if (firstTabKey === "4") {
            let guarantor = response.guarantors.find((guarantor) => {
              return guarantor.id == selectedUser.id;
            });
            dispatch(onChangeSelectedUser(guarantor));
          }
        })
      );
    }
  };
  const handleRunKyc = () => {
    if (selectedUser) {
      let data = {
        fk_case_id: selectedUser.fk_case_id,
        applicant_type: selectedApplicant.applicant_type,
        applicant_coapplicant_guarantor_id: selectedApplicant.id,
      };
      if (isMemberOrDirector) {
        data.member_director_id = selectedUser.id;
      }
      dispatch(
        onRunKyc(data, (response) => {
          setKycResponse(response);

          updateSelectedUser();
          // setRunOcr(true);
        })
      );
    }
  };
  // const handleRunKyc = () => {
  //   if (selectedUser) {
  //     //
  //     let data = {
  //       fk_case_id: selectedUser.fk_case_id,
  //       applicant_type: selectedApplicant.applicant_type,
  //       applicant_coapplicant_guarantor_id: selectedApplicant.id,
  //     };
  //     if (isMemberOrDirector) {
  //       //
  //       data.member_director_id = selectedUser.id;
  //     }
  //     dispatch(
  //       onRunKyc(data, (response) => {
  //         setOcrResponse(true);
  //         setModalTitle("KYC Result");
  //         updateSelectedUser();
  //         setRunOcr(true);
  //       })
  //     );
  //   }
  // };
  const handleRunOcr = () => {
    let data = {
      fk_case_id: caseDetail.id,
      applicant_type: selectedUser.applicant_type,
      applicant_coapplicant_guarantor_id: selectedApplicant.id,
    };
    if (isMemberOrDirector) {
      data.member_director_id = selectedUser.id;
    }

    // setRunOcr(true);
    dispatch(
      onRunOcr(data, (response) => {
        // setOcrResponse(response);
        let ocrResponse = {
          ocr_success: response.result,
          fk_category_id: isMemberOrDirector
            ? "1"
            : selectedUser.fk_category_id
              ? selectedUser.fk_category_id.toString()
              : "",
          applicant_type: selectedApplicant.applicant_type,
          applicant_coapplicant_guarantor_id: selectedApplicant.id,
          isMemberOrDirector: isMemberOrDirector
            ? selectedUser.member_type
            : "",
          ...response,
        };
        if (isMemberOrDirector) {
          ocrResponse.member_director_id = selectedUser.id;
        }

        dispatch(onRunOcrResponse(ocrResponse));
        setOcrResponse(true);
        updateSelectedUser();
        // setRunOcr(true);
      })
    );
    // dispatch(
    //   onRunOcr(data, (response) => {
    //     setModalTitle("OCR Result");
    //     setOcrResponse(response);
    //     updateSelectedUser();
    //     setRunOcr(true);
    //   })
    // );
  };
  const closeOcrModal = (close) => {
    setRunOcr(close);
  };
  const openBureauReportForm = () => {
    SetButtonDisable(false);
    let data = {
      applicant_type: selectedApplicant.applicant_type,
      applicant_coapplicant_guarantor_id: selectedApplicant.id,
    };
    if (isMemberOrDirector) {
      data = {
        ...data,
        member_director_id: selectedUser.id,
      };
    }
    dispatch(onGetUsercrResult(data));
    setBureauReport(true);
  };

  const saveJustificationComments = (data) => {
    // data.fk_case_id=caseDetail.id;
    dispatch(onJustificationComment(data, onSuccess, onError));
  };
  const onSuccess = (data) => {
    form2.resetFields();
    dispatch(
      onCaseDetail({ caseId: caseDetail.id }, (caseDetail) => {
        dispatch(onChangeSelectedUser(caseDetail.main_applicant))
        setAskJustification(false);
      })
    );
  };
  const onError = (data) => {
    form2.resetFields();
    toast.error(data.message);
  };
  const fetchReport = (data) => {
    SetButtonDisable(true)
    data.user_type = selectedUser.member_type
      ? selectedUser.member_type
      : selectedUser.applicant_type;
    if (data.amount) {
      data.amount = data.amount
        .replaceAll(",", "")
        .replace("&#8377", "")
        .replace(/[^\x00-\x7F]/g, "");
    }
    if (data.bureau_name == "CIBIL") {
      switch (data.gender) {
        case "MALE":
          data.gender = "1";
          break;
        case "FEMALE":
          data.gender = "2";
          break;
        default:
          data.gender = "3";
      }
    }
    if (data.date_of_birth) {
      const dob = moment(data.date_of_birth);
      data.date_of_birth = dob.format("DD-MM-YYYY");
    }
    if (data.date_of_registration) {
      const date = moment(data.date_of_registration);
      data.date_of_registration = date.format("DD-MM-YYYY");
    }
    // 

    data.case_id = caseDetail.id;
    data.user_id = selectedUser.id;
    data.name_as_mentioned_on = data.applicant_name;

    if (
      (data.state === "" || data.state === undefined)
    ) {
      const state = states.find(
        (element) => element.state_name === selectedUser.state
      );
      data.state = state ? state.state_code : data.state;
    }

    dispatch(onGetBureauReportCheckParam(data, onApiCheckSuccess, onApiError));
  };
  const onApiCheckSuccess = (data, responseData) => {
    setBureauSubmitData(data);
    setOpenConfirm(false);
    if (responseData.status) {
      if (data.bureau_name === 'CIBIL') {
        dispatch(onGenerateCibilReport(data, onApiSuccess, onApiError));
      } else {
        dispatch(onGenerateBureauReport(data, onApiSuccess, onApiError));
      }
    } else {
      setOpenConfirm(true);
      SetButtonDisable(false);
      SetConfimMessage(responseData.message)
    }

  };
  const handleConfirmOk = (data) => {

    if (bureauSubmitData.bureau_name === 'CIBIL') {
      dispatch(onGenerateCibilReport(bureauSubmitData, onApiSuccess, onApiError));
    } else {
      dispatch(onGenerateBureauReport(bureauSubmitData, onApiSuccess, onApiError));
    }
  }
  const handleCancelConfirm = () => {
    setOpenConfirm(false);
  }
  const getCity = (data, bureauname) => {
    if (bureauname === "CIBIL") {
      dispatch(onGetCibilCities(data, onSuccessCity));
    } else {
      dispatch(onGetCities(data, onSuccessCity));
    }
  };
  const onSuccessCity = (data) => { };
  const onApiSuccess = (data) => {
    SetButtonDisable(false);
    dispatch(
      onCaseDetail({ caseId: caseDetail.id }, (updatedCase) => {
        form.resetFields();
        setBureauReport(false);
        let apidata = {
          user_id: selectedUser.id,
          member_type: selectedUser.member_type
            ? selectedUser.member_type
            : selectedUser.applicant_type,
          case_id: caseDetail.id,
        };
        dispatch(onFetchBureauReportListing(apidata));
        dispatch(onChangeThirdTabKey("3"));
      })
    );
  };
  const onApiError = (data) => {
    SetButtonDisable(false);
    toast.error(data.message);
  };

  return (
    <React.Fragment>
      <Card>
        <div className="ActionButtonHeader">
          {((selectedUser.stage &&
            selectedUser.stage !== STAGES.KYC_PENDING &&
            selectedUser.stage !== STAGES.OCR_PENDING &&
            caseDetail.status !== STATUS.REJECTED &&
            caseDetail.status !== STATUS.CREDIT_BUREAU_APPROVED &&
            isNotDropped(selectedApplicant.status) &&
            isSalesUser(userData) &&
            isCaseNotClosed(caseDetail.status)) ||
            (isCreditUser(userData) &&
              caseDetail.status === STATUS.CREDIT_REVIEW_PENDING)) && (
            <React.Fragment>
              {!isMemberOrDirector &&
                selectedApplicant.applicant_type ===
                  APPLICANT_TYPE.MAIN_APPLICANT &&
                isSalesUser(userData) && (
                  <Button
                    // disabled={caseDetail.status === STAGES.CREDIT_REVIEW_PENDING}
                    onClick={() => {
                      if (
                        caseDetail.status === STAGES.CREDIT_REVIEW_PENDING &&
                        isSalesUser(userData)
                      ) {
                        setInformationModal(true);
                      } else {
                        setAskJustification(true);
                      }
                    }}
                  >
                    Ask for justification
                  </Button>
                )}
              {userData && userData.permissions && userData.permissions.Bureau_Reports && userData.permissions.Bureau_Reports.run && (
                <Button onClick={openBureauReportForm}>
                  Run Bureau Reports
                </Button>
              )}
            </React.Fragment>
          )}
        </div>
        {isKycDone(selectedUser.stage) ? (
          <Collapse defaultActiveKey={["1", "2"]}>
            <Panel header="KYC Summary" key="1">
              <Row gutter="64">
                <Col span={12} md={12} xs={24} sm={12}>
                  <InfoMatching
                  // kycResult={selectedUser.kyc_result}
                  // panCardDetails={panCardDetails}
                  // aadharCardDetails={aadharCardDetails}
                  // selectedUser={selectedUser}
                  />
                </Col>
                <Col span={12} md={12} xs={24} sm={12}>
                  <InformationValidity
                    kycResult={selectedUser.kyc_result}
                    panCardDetails={panCardDetails}
                    aadharCardDetails={aadharCardDetails}
                    userOcrResult={userOcrResult}
                  />
                </Col>
              </Row>
            </Panel>
            <Panel header="KYC Details" key="2" className="kyc-detail-v2">
              <Row gutter="32">
                {kycResult &&
                  Array.isArray(kycResult) &&
                  kycResult.map((kyc_doc) => (
                    <Col span={8} md={8} sm={24} xs={24}>
                      {kyc_doc.document_name ===
                        OCR_DOCUMENT_LABEL.PAN_CARD && <PanCard />}
                      {kyc_doc.document_name ===
                        OCR_DOCUMENT_LABEL.PASSPORT && <Passport />}
                      {kyc_doc.document_name ===
                        OCR_DOCUMENT_LABEL.VOTER_ID && <VoterId />}
                      {kyc_doc.document_name ===
                        OCR_DOCUMENT_LABEL.DRIVING_LICENSE && (
                        <DrivingLicense />
                      )}

                      {kyc_doc.document_name ===
                        OCR_DOCUMENT_LABEL.AADHAR_CARD && <AddharCard />}
                      {kyc_doc.document_name ===
                        OCR_DOCUMENT_LABEL.GST_REGISTRATION && (
                        <GSTRegistration />
                      )}
                    </Col>
                  ))}
              </Row>
            </Panel>
          </Collapse>
        ) : (
          <Card>
            <h2 className="NodataCard">
              {isOcrDone(selectedUser.stage)
                ? "KYC PENDING"
                : "PLEASE PERFORM OCR TO PROCEED KYC"}
            </h2>
          </Card>
        )}
      </Card>
      {/* ReAllocate */}
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={reallocate}
        onOk={() => setReallocation(false)}
        onCancel={() => setReallocation(false)}
        footer={
          <Button key="back" onClick={handleCancel} className="Btndefault">
            <Link to="/viewOnGoingcase">View Details </Link>
          </Button>
        }
        width={600}
        className="KycCheck KYCform"
        style={{ top: 50 }}
      >
        <ReAllocate />
      </Modal>
      {/* ReAllocate */}

      {/* Run KYC */}
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={runKycCheck}
        onOk={() => setRunKycCheck(false)}
        onCancel={() => setRunKycCheck(false)}
        footer={null}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunKycCheck />
      </Modal>
      {/* Run KYC */}

      {/* Run Bureao Reports */}
      <Modal
        title="Bureau Report Generation"
        centered
        visible={bureauReport}
        onOk={() => setBureauReport(false)}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        className="BeureauReport"
        style={{ top: 50 }}
        destroyOnClose={true}
      >
        <BureauReportForm
          selectedApplicant={selectedUser}
          fetchReport={fetchReport}
          bureauReportMasters={bureauReportMasters}
          cibilReportMasters={cibilReportMasters}
          states={states}
          form={form}
          cities={city}
          cibilcities={cibilcities}
          caseDetail={caseDetail}
          ocrresult={ocrresult}
          getCity={getCity}
          buttonDisable={buttonDisable}
        />
      </Modal>
      {/* Run Bureao Reports */}
      {/*** Confirmation Modal * */}
      <ConfirmModal
        openConfirm={openConfirm}
        handleCancelConfirm={handleCancelConfirm}
        handleOk={handleConfirmOk}
        confimMessage={confimMessage}
      />

      {/* Ask For Justification */}
      {/* <Modal
        title="Justification Requirements "
        centered
        visible={askJustification}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button className="filled" onClick={handleCancel}>
            <Link to="/viewonGoincasecommunication"> Submit</Link>
          </Button>,
        ]}
        width={1000}
        className="JustReqWrap"
        style={{ top: 50 }}
      >
        <AskJustification />
      </Modal> */}
      <Modal
        title="KYC RESULT"
        centered
        visible={kycResponse}
        onOk={() => setKycResponse(false)}
        onCancel={() => setKycResponse(false)}
        footer={[<Button onClick={() => setKycResponse(false)}>Ok</Button>]}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunKyc
          ocrResponse={kycResponse}
          data={{
            applicant_type: isMemberOrDirector
              ? selectedUser.member_type
              : selectedUser.applicant_type,
            fk_category_id: isMemberOrDirector
              ? "1"
              : selectedUser.fk_category_id,
          }}
        />
      </Modal>

      <AskForJustificationModal
        caseDetail={caseDetail}
        handleOk={saveJustificationComments}
        form={form2}
        isModalVisible={askJustification}
        handleCancel={handleCancel}
        selectedApplicant={selectedApplicant}
      />
      {/* Ask For Justification */}
      {/* Credit Review */}
      <Modal
        title="Credit Review Request"
        centered
        visible={creditReview}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button className="filled" onClick={handleCancel}>
            <Link to="/OnGoingCases"> Submit</Link>
          </Button>,
        ]}
        width={1000}
        className="JustReqWrap"
        style={{ top: 50 }}
      >
        <CreditReview />
      </Modal>
      <Modal
        title={modalTitle}
        centered
        visible={runOcr}
        onOk={() => setRunOcr(false)}
        onCancel={() => setRunOcr(false)}
        footer={null}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunOcr />
      </Modal>
      <InformationModal
        isVisible={informationModal}
        informationContent={MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE}
        title={"Credit Review Pending"}
        handleOk={() => {
          setInformationModal(false);
        }}
      />
      {/* Ask For Justification */}
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail ? auth.caseDetail : {},
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser ? auth.selectedUser : {},
  };
};
export default connect(mapStateToProps, null)(KycDetail);
