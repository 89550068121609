import {
    CREDIT_PENDING_CASES
} from "../constants/ActionTypes";


// export const onFetchPendigCases = () => ({
//     type: CREDIT_PENDING_CASES
// });

export const onFetchPendigCases = (data) => ({
    type: CREDIT_PENDING_CASES,
    payload:data,

  });