import React, { Component } from "react";
import { Tabs, Card, Row, Col } from "antd";
import Mainapplicantform from "../mainapplicantform";
import Coapplicantform from '../coapplicantform'
import LoanDetail from "../../loandetail";
import DealerInfo from "../../dealerinfocard";
import Guarantorform from "../guarantorform"
const { TabPane } = Tabs;
class Mainapplicant extends Component {
  render() {
    return (
      <>
        <div className="InnerTabsWrap">
          <Card>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Main Applicant" key="1">
                <Mainapplicantform />
              </TabPane>
              <TabPane tab="Co Applicant" key="2">
                <Coapplicantform/>
              </TabPane>
              <TabPane tab="Guarantor" key="3">
                <Guarantorform/>
              </TabPane>
            </Tabs>
          </Card>
          <div className='InfoCard'>
            <Row gutter={16}>
                <Col span={8} md={8} xs={24} sm={24}>
                <LoanDetail />
                </Col>
                <Col span={16} md={16} xs={24} sm={24}>
                <DealerInfo />
                </Col>
            </Row>
          </div>
         
        </div>
      </>
    );
  }
}
export default Mainapplicant;
