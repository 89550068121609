import React, { useState } from "react";
import { Button, Radio } from "antd";
import { connect, useDispatch, useSelector } from "react-redux";
import { onChangeSelectedUser } from "../../../redux/actions/AuthAction";
import EditIcon from "../../../assets/images/edit.png";
import ViewIcon from "../../../assets/images/view.png";
import RemoveIcon from "../../../assets/images/remove.png";
import AddIndividualForm from "../individual/AddIndividualForm";
import { DeleteCoapplicantModal } from "../../common/modals/DeleteCoapplicantModal";
import Modal from "antd/lib/modal/Modal";
import {
  onDeleteMemberOrDirector,
  onMemberDirectorDetail,
} from "../../../redux/actions/MemberAndDirectorAction";
import {
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../../redux/actions/CaseAction";
import { runMemberDirectorOcr } from "../helpers/SaveApplicant";
import { isOcrDone } from "../../../helpers/utils";
import { MEMBER_TYPE } from "../../../constants/constants";
const MemberDirectorListing = (props) => {
  const {
    members = [],
    title,
    makeEditable,
    applicant,
    onAddNewMemberDirector,
    applicant_type,
  } = props;
  
  const [toEditMemberOrDirector, setToEditMemberOrDirector] = useState(false);
  const [memberOrDirector, setMemberOrDirector] = useState(false);

  const newlyCreatedCase = useSelector((state) => {
    if (state && state.auth) {
      return state.auth.newlyCreatedCase;
    }
    return {};
  });
  const dispatch = useDispatch();
  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteDirectorMemberId, setDeleteDirectorMemberId] = useState(false);
  const deleteConfirmation = () => {
    dispatch(
      onDeleteMemberOrDirector(deleteDirectorMemberId, (response) => {
        dispatch(
          onCaseDetail({ caseId: newlyCreatedCase.id }, (response) => {
            dispatch(
              onUpdateNewCreatedCase(response, () => {
                if (makeEditable) {
                  makeEditable(applicant);
                }
                setDeleteVisible(false);
              })
            );
          })
        );
      })
    );
  };
  const deleteCancel = () => {
    setDeleteVisible(false);
  };
  // const deleteDirectorMember = (memberDirectorId) => {
  //   dispatch(
  //     onDeleteMemberOrDirector(memberDirectorId, (response) => {
  //       dispatch(
  //         onCaseDetail({ caseId: newlyCreatedCase.id }, (response) => {
  //           dispatch(onUpdateNewCreatedCase(response));
  //         })
  //       );
  //     })
  //   );
  // };
  const editMemberOrDirector = (member) => {
    dispatch(
      onMemberDirectorDetail(member.id, (member_director) => {
        setMemberOrDirector(member_director);
        setToEditMemberOrDirector(true);
      })
    );
  };
  const closeDirectorMemberModal = () => {
    setToEditMemberOrDirector(false);
  };
  const onUpdateMemberDirector = (member) => {
    setMemberOrDirector(member);
  };
  return (
    <React.Fragment>
      <div className="SmallTableWrap SmallTableWrap-v2">
        {members.length > 0 && (
          <React.Fragment>
            <h3>Added {title}</h3>
            <table>
              <thead>
                <tr>
                  {title !== MEMBER_TYPE.PROPRIETOR && <th>S.no</th>}
                  <th>{title} ID</th>
                  <th>{title} Name</th>
                  {/*<th>Relation with Applicant</th>*/}
                  <th>Status</th>
                  <th>Stage</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {members.map((member, index) => (
                  <tr key={index.toString()}>
                    {title !== MEMBER_TYPE.PROPRIETOR && <td>{index + 1}</td>}
                    <td>{member.id}</td>
                    <td>
                      {member.first_name
                        ? member.first_name
                        : member.customer_name
                        ? member.customer_name
                        : ""}{" "}
                      {member.last_name}
                    </td>
                    <td>{member.status}</td>
                    <td>{member.stage}</td>
                    {/*<td>{co_applicant.stage}</td>*/}
                    <td>
                      {/*<Button>
                        <img src={ViewIcon} alt="View" />
                      </Button>*/}
                      <Button
                        onClick={() => {
                          editMemberOrDirector(member);
                        }}
                      >
                        View & Edit
                      </Button>{" "}
                      <Button
                        onClick={() => {
                          runMemberDirectorOcr(
                            member,
                            dispatch,
                            applicant,
                            onAddNewMemberDirector
                          );
                        }}
                      >
                        {isOcrDone(member.stage) ? "Re-Run OCR" : "Run OCR"}
                      </Button>{" "}
                      <Button
                        onClick={() => {
                          setDeleteDirectorMemberId(member.id);
                          setDeleteVisible(true);
                        }}
                      >
                        <img src={RemoveIcon} alt="Remove" />
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}{" "}
              </tbody>
            </table>
          </React.Fragment>
        )}
      </div>
      <Modal
        title={title}
        centered
        visible={toEditMemberOrDirector}
        destroyOnClose={true}
        // onOk={() => setModal2Visible(false)}
        onCancel={closeDirectorMemberModal}
        footer={null}
        width={1000}
        className="ModalFormWrap AddCoapplicant"
        style={{ top: 50 }}
      >
        <AddIndividualForm
          data={{
            applicant_type: applicant.applicant_type
              ? applicant.applicant_type
              : applicant_type,
            member_type: title,
            applicant_id: applicant.id,
          }}
          memberOrDirector={memberOrDirector}
          closeDirectorMemberModal={closeDirectorMemberModal}
          onAddNewMemberDirector={onUpdateMemberDirector}
        />
      </Modal>
      <DeleteCoapplicantModal
        deleteVisible={deleteVisible}
        deleteCancel={deleteCancel}
        deleteConfirm={deleteConfirmation}
        title={title}
      />
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(MemberDirectorListing);
