import { Col, Row } from "antd";
import React from "react";
import CommonLoanDetail from "../ongoincasetabs2/common-loan-detail";
import DealerInfo from "../ongoincasetabs2/dealerinfocard";
import EicherUserDetail from "./EicherUserDetail";

export default function EicherCaseDetail(props) {
  const { caseDetail } = props;
  return (
    <React.Fragment>
      <EicherUserDetail caseDetail={caseDetail} />
      <div className="InfoCard">
        <Row gutter={16}>
          <Col span={10} md={10} xs={24} sm={24}>
            <CommonLoanDetail caseDetail={caseDetail}/>
          </Col>
          <Col span={14} md={14} xs={24} sm={24}>
            <DealerInfo caseDetail={caseDetail}/>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
