import { Alert, Button } from "antd";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useHistory ,useLocation} from "react-router-dom";
import { onSSOLogin } from "../redux/actions/AuthAction";
import { capitalizeFirstLetter, isLoggedIn } from "../helpers/utils";
import { toast } from "react-toastify";
import { WEBURL } from "../redux/constants/Config";


export default function SSOLogin() {
  const { hash } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  console.log('hash: ' , hash.replace('#access_token=',''));
  
  const onSuccess = (responseData) => {
    if (responseData.data) {
      localStorage.setItem(
        "token",
        `${capitalizeFirstLetter(responseData.data.token_type)} ${
          responseData.data.access_token
        }`
      );
      localStorage.setItem(
        "userInfo",
        JSON.stringify(responseData.data.user_detail)
      );

      // history.push('/dashboard');
      window.location.reload();
    }
  };
  const onError = (responseData) => {
    console.log('responseData: onError', responseData);
   
    // if (responseData.data) {
      
    //   // window.location.reload();
    // }
  };
  useEffect(() => {
    let data = {
      jwt_token:hash.replace('#access_token=','')
    }
    if(hash.startsWith('#access_token=')){
      dispatch(onSSOLogin(data,onSuccess,onError));
    }else{
      toast.error('Access Denied');
      history.push(WEBURL);
      // history.push(
      //   "https://federate.volvo.com/as/authorization.oauth2?response_type=token&client_id=vfs-india-credit-portal&redirect_uri=https://www.creditone.vfsco.in/ssologin"
      // );

    }
  }, [hash,dispatch,history])
  // console.log('history: ', history,window.location.hash.substring(1));
  return (
    <div>
     
    </div>
  );
}
