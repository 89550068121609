import { Button, Radio,Checkbox } from "antd";
import Table from "rc-table/lib/Table";
import React, { useState,useEffect } from "react";
import { useDispatch } from "react-redux";
import BureauReportListing from "./BureauReportListing";
import PlusIcon from "../../assets/images/plus.png";
import { APPLICANT_TYPE } from "../../constants/constants";
import { onGetBureauReportListing } from "../../redux/actions/BureauReportAction";
import {
  getClassNameByStatus
} from "../../helpers/utils";

const coapplicantColumns = [
  {
    title: "Co-Applicant Name",
    dataIndex: "first_name",
  },
  {
    title: "Relation with Applicant",
    dataIndex: "coapplicant_applicant_relation",
  },
  {
    title: "Credit Score ",
    dataIndex: "generated_on",
  },
  {
    title: "Last Date of Report Run ",
    dataIndex: "parameter",
    render: (text) => <a>Click here</a>,
  },
  {
    title: "Status",
    key: "action",
    render: (text, record) => (
      <React.Fragment>
        <Button>view</Button>
      </React.Fragment>
    ),
  },
  {
    title: "Action",
    key: "action",
    render: (text, record) => (
      <React.Fragment>
        <Button>View Report</Button>
      </React.Fragment>
    ),
  },
];

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {},
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};
let applicantlist=[];
export const CoApplicantListing = (props) => {
  const { co_applicants } = props;
  const [selectedCoapplicant, setSelectedCoApplicant] = useState('');
  const [selectedCoapplicantId, setSelectedCoApplicantId] = useState({});
  const [selectedCoapplicantData, setSelectedCoApplicantData] = useState([]);

  const [selectionType] = useState("radio");
  const dispatch = useDispatch();
  const [viewReport, setViewReport] = useState(null);

  const dataSource = [
    {
      key: '1',
      name: 'Mike',
      age: 32,
      address: '10 Downing Street',
    },
    {
      key: '2',
      name: 'John',
      age: 42,
      address: '10 Downing Street',
    },
  ];
  
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Age',
      dataIndex: 'age',
      key: 'age',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
  ];
  
  useEffect(() => {
    applicantlist=[];
  }, []);
  const onSetCoApplicantData = (data) => {
    console.log(data,'lkk')
    setSelectedCoApplicantData(data);
    props.onSetCoApplicantBRId(data);
  };
  const viewBureauReport = (co_applicant,e) => {
    setSelectedCoApplicantId(co_applicant);
    let data = {
      user_id: co_applicant.id,
      member_type: APPLICANT_TYPE.CO_APPLICANT,
      case_id: co_applicant.fk_case_id,
    };
    if(e.checked){
      // props.onSetCoApplicantId(e.value);
      setSelectedCoApplicant(e.value);
      dispatch(
        onGetBureauReportListing(data, (bureauList) => {
          bureauList.applicantid=co_applicant.id;
          co_applicant.viewReport=bureauList;
          applicantlist.push(co_applicant);
          setViewReport(bureauList);

        })
      );
    }else{
      const index = applicantlist.findIndex((x) => x.id === e.value);
      const index2 = selectedCoapplicantData.findIndex((x) => x.co_applicant_id === e.value);

      let apiData=selectedCoapplicantData;
      if(index != -1) {
        applicantlist.splice(index, 1); // from index remove 1 element
      }
      if(index2 != -1) {
        apiData.splice(index, 1); // from index remove 1 element
      }
     props.onSetCoApplicantBRId(apiData);
     
      setSelectedCoApplicant('');
      // props.onSetCoApplicantId();
      // props.onSetCoApplicantBRId();
      setViewReport(null);
    }
  
  };

  return (
    <div className="InnerWrap">
      {/*{co_applicants && <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={coapplicantColumns}
        dataSource={co_applicants}
        pagination={false}
        rowKey={"id"}
      />}*/}
      <table>
        <thead>
          <tr>
            <th>Applicant Category</th>
            <th>Co Applicant Name</th>
            <th>Relation with Applicant</th>
            <th>Status</th>
            {/*<th>Action</th>*/}
          </tr>
        </thead>
        <tbody>
          {co_applicants &&
            Array.isArray(co_applicants) &&
            co_applicants.map((co_applicant) => {
              return ( <>
                <tr key={co_applicant.id}>
                  <td>
                  {/* <Checkbox.Group style={{ width: '100%' }} onChange={onChange}>
                  </Checkbox.Group> */}
                  <Checkbox name="radiogroup"
                      value={co_applicant.id}
                      disabled={co_applicant.status==='Dropped'?true:false}
                      onChange={(e) => {
                        viewBureauReport(co_applicant,e.target);
                      }}>
                    <p>{co_applicant.category_name}</p>

                  </Checkbox>
                    {/* <Radio.Group
                      name="radiogroup"
                      value={selectedCoapplicant}
                      disabled={co_applicant.status==='Dropped'?true:false}
                      onChange={(e) => {
                        
                        setSelectedCoApplicant(e.target.value);
                        viewBureauReport(co_applicant);
                        props.onSetCoApplicantId(e.target.value);
                      }}
                    >
                      <Radio value={co_applicant.id}>
                        <p>{co_applicant.category_name}</p>
                      </Radio>
                    </Radio.Group> */}
                  </td>
                  <td>{co_applicant.first_name
                    ? co_applicant.first_name
                    : "-"}{" "}
                  {co_applicant.middle_name
                    ? co_applicant.middle_name
                    : ""}{" "}
                  {co_applicant.last_name ? co_applicant.last_name : ""}{" "}</td>
                  <td>{co_applicant.coapplicant_applicant_relation}</td>
                  <td className={getClassNameByStatus(co_applicant.status)}>{co_applicant.status}</td>
                  
                </tr>

                {/* <tr><Table dataSource={dataSource} columns={columns} /></tr> */}
                </>
              );
            })}
        </tbody>
      </table>
      {applicantlist && Array.isArray(applicantlist) && (
        <BureauReportListing
          userId={selectedCoapplicant}
          member_type={APPLICANT_TYPE.CO_APPLICANT}
          case_id={selectedCoapplicant.fk_case_id}
          bureauReportData={viewReport}
          selectedCoapplicantId={selectedCoapplicantId}
          onSetCoApplicantBRId={props.onSetCoApplicantBRId}
          applicantlist={applicantlist}
          onSetCoApplicantData={onSetCoApplicantData}
        />
      )}
    </div>
  );
};
