import React, { Component } from "react";
import CheckIcon from '../../../../../assets/images/completedcheck.png';
class InformationValidity extends Component {
    render() {
      return (
        <>
        <div className='infoWrap'>
            <h4>Information Validity</h4>
           <div className='TableWrap'>
           <table>
                <thead>
                    <tr>
                        <th>Document Detail</th>
                        <th>Valid and Active</th>
                        
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>PAN</td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                       
                    </tr>
                    <tr>
                        <td>Aadhar</td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                       
                    </tr>
                    <tr>
                        <td>Passport</td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                       
                    </tr>
                    <tr>
                        <td>Email</td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                       
                    </tr>
                    <tr>
                        <td>DL</td>
                        <td><p className='exprireBlue'>Expiring in Dec 2021</p></td>
                       
                    </tr>
                </tbody>
            </table>
           </div>
           
        </div>
            
        </>
      )
    }
}
export default InformationValidity;