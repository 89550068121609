import {
  APPLICANT_TYPE,
  NON_OCR_DOCUMENT_LABEL,
  OCR_DOCUMENT_LABEL,
} from "../../../constants/constants";
import { MESSAGES, REQUIRED_MESSAGES } from "../../../constants/messages";
import { REGEX } from "../../../constants/regex";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileNameFromUrl,
  fileTypeFromName,
  firstCharacterOfEachWordUpperCase,
} from "../../../helpers/utils";
import {
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../../redux/actions/CaseAction";
import { onDeleteNonOcrDocument } from "../../../redux/actions/NonOcrAction";
import { onRunOcrResponse } from "../../../redux/actions/OcrAction";
export const getUploadedOcrDocuments = (ocr_documents) => {
  let defaultFiles = {};
  ocr_documents.forEach((ocr_document, index) => {
    let uid = (index + 1).toString();
    let name = fileNameFromUrl(ocr_document.document_path);
    let fileType = fileTypeFromName(name);
    let url = ocr_document.document_path;
    let status = "done";
    let doc_array = [
      {
        uid,
        name,
        fileType,
        url,
        status,
      },
    ];
    if (ocr_document.document_name === OCR_DOCUMENT_LABEL.PAN_CARD) {
      defaultFiles = {
        ...defaultFiles,
        pan_card: doc_array,
      };
    } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
      defaultFiles = {
        ...defaultFiles,
        aadhar_card: doc_array,
      };
    } else if (
      ocr_document.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE
    ) {
      defaultFiles = {
        ...defaultFiles,
        driving_licence: doc_array,
      };
    } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.PASSPORT) {
      defaultFiles = {
        ...defaultFiles,
        passport: doc_array,
      };
    } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.VOTER_ID) {
      defaultFiles = {
        ...defaultFiles,
        voter_id: doc_array,
      };
    } else if (
      ocr_document.document_name === OCR_DOCUMENT_LABEL.GST_REGISTRATION
    ) {
      defaultFiles = {
        ...defaultFiles,
        gst_registration: doc_array,
      };
    }
  });
  return defaultFiles;
};
export const getOcrFields = (
  applicantCategory,
  applicantSubCategory,
  applicantType
) => {
  let ocrFields = [];
  if (applicantType && applicantType === APPLICANT_TYPE.MAIN_APPLICANT) {
    ocrFields = ocrFields.concat([
      {
        label: OCR_DOCUMENT_LABEL.PAN_CARD,
        name: "pan_card",
        message: REQUIRED_MESSAGES.PAN_CARD_REQUIRED,
      },
    ]);
  } else {
    ocrFields = ocrFields.concat([
      {
        label: OCR_DOCUMENT_LABEL.PAN_CARD,
        name: "pan_card",
        //message: REQUIRED_MESSAGES.PAN_CARD_REQUIRED,
      },
    ]);
  }
  if (applicantCategory === "1") {
    ocrFields = ocrFields.concat([
      {
        label: OCR_DOCUMENT_LABEL.DRIVING_LICENSE,
        name: "driving_license",
      },
      {
        label: OCR_DOCUMENT_LABEL.AADHAR_CARD,
        name: "aadhar_card",
        // message: REQUIRED_MESSAGES.ADHAR_CARD_REQUIRED,
      },
      {
        label: OCR_DOCUMENT_LABEL.VOTER_ID,
        name: "voter_id",
      },
      {
        label: OCR_DOCUMENT_LABEL.PASSPORT,
        name: "passport",
      },
    ]);
  }
  // else {
  //   ocrFields=ocrFields.concat([
  //     { label: OCR_DOCUMENT_LABEL.GST_REGISTRATION, name: "gst_registration",message:REQUIRED_MESSAGES.GST_REGISTRATION_REQUIRED },
  //   ]);
  // }
  return ocrFields;
};
export const getNonOcrFields = (applicantCategory, applicantSubCategory) => {
  let nonOcrFields = [];
  let bank_statement = {
    label: "Bank Statement",
    name: "bank_statement",
    // message: REQUIRED_MESSAGES.BANK_STATEMENT_REQUIRED,
  };
  let partnership_deed = {
    label: "Partnership Deed",
    name: "partnership_deed",
    // message: REQUIRED_MESSAGES.PARTNERSHIP_DEED_REQUIRED,
  };
  let incorporate_certificate = {
    label: "Incorporation Certificate",
    name: "incorporate_certificate",
    // message: REQUIRED_MESSAGES.INCORPORATE_CERTIFICATE_REQUIRED,
  };
  let shareholding_pattern = {
    label: "Shareholding Pattern",
    name: "shareholding_pattern",
    // message: REQUIRED_MESSAGES.SHAREHOLDING_PATTERN_REQUIRED,
  };
  let trust_deed = {
    label: "Trust Deed",
    name: "trust_deed",
    // message: REQUIRED_MESSAGES.TRUST_DEED_REQUIRED,
  };
  let society_deed = {
    label: "Society Deed",
    name: "society_deed",
    // message: REQUIRED_MESSAGES.SOCIETY_DEED_REQUIRED,
  };
  let huf_deed = {
    label: "HUF Deed",
    name: "huf_deed",
    // message: REQUIRED_MESSAGES.HUF_DEED_REQUIRED,
  };
  let msme_certificate = {
    label: "MSME CERTIFICATE",
    name: "msme_certificate",
    // message:REQUIRED_MESSAGES.MSME_CERTIFICATE_REQUIRED
  };
  nonOcrFields = nonOcrFields.concat([
    {
      label: "Customer Consent",
      name: "customer_consent",
      message: REQUIRED_MESSAGES.CUSTOMER_CONSENT_REQUIRED,
    },
  ]);
  if (applicantCategory !== "1") {
    nonOcrFields = nonOcrFields.concat([
      {
        label: "GST Registration",
        name: "gst_registration",
        // message: REQUIRED_MESSAGES.GST_REGISTRATION_REQUIRED,
      },
    ]);
  }
  if (applicantCategory === "2") {
    if (applicantSubCategory) {
      if (applicantSubCategory === "1" || applicantSubCategory === "2") {
        nonOcrFields = nonOcrFields.concat([partnership_deed, bank_statement]);
      } else if (
        applicantSubCategory === "3" ||
        applicantSubCategory === "4" ||
        applicantSubCategory === "5"
      ) {
        nonOcrFields = nonOcrFields.concat([
          bank_statement,
          incorporate_certificate,
          shareholding_pattern,
        ]);
      }
      // else if (applicantSubCategory === "5") {
      //   nonOcrFields = nonOcrFields.concat([trust_deed]);
      // }
      else if (applicantSubCategory === "6") {
        nonOcrFields = nonOcrFields.concat([society_deed]);
      } else if (applicantSubCategory === "7") {
        nonOcrFields = nonOcrFields.concat([trust_deed]);
      }
    }
  } else if (applicantCategory === "3") {
    nonOcrFields = nonOcrFields.concat([bank_statement, huf_deed]);
  } else if (applicantCategory === "4") {
    nonOcrFields = nonOcrFields.concat([bank_statement, msme_certificate]);
  }
  return nonOcrFields;
};
const checkOcrFieldValidity = (fieldName, errorMessage, ocrDocuments) => {
  if (
    (!ocrDocuments[fieldName] ||
      !Array.isArray(ocrDocuments[fieldName]) ||
      ocrDocuments[fieldName].length === 0) &&
    errorMessage
  ) {
    return true;
  }
};
const checkNonOcrFieldValidity = (
  fieldName,
  label,
  errorMessage,
  nonOcrDocuments
) => {
  if (
    !nonOcrDocuments[label] ||
    !Array.isArray(nonOcrDocuments[label]) ||
    nonOcrDocuments[label].length === 0
  ) {
    return true;
  }
};
export const checkValidation = (
  applicantCategory,
  applicantSubCategory,
  ocrDocuments,
  nonOcrDocuments,
  applicant_type,
  aadhar_no
) => {
  let errors = [];
  getOcrFields(applicantCategory, applicantSubCategory, applicant_type).forEach(
    (ocr_field) => {
      const isFieldInvalid = checkOcrFieldValidity(
        ocr_field.name,
        ocr_field.message,
        ocrDocuments
      );

      if (isFieldInvalid) {
        errors.push(ocr_field);
      }
    }
  );
  getNonOcrFields(applicantCategory, applicantSubCategory).forEach(
    (non_ocr_field) => {
      // && non_ocr_field.label === "Customer Consent"
      if (applicant_type === APPLICANT_TYPE.MAIN_APPLICANT) {
        if (non_ocr_field.label === NON_OCR_DOCUMENT_LABEL.CUSTOMER_CONSENT) {
          const isFieldInvalid = checkNonOcrFieldValidity(
            non_ocr_field.name,
            non_ocr_field.label,
            non_ocr_field.message,
            nonOcrDocuments
          );
          if (isFieldInvalid) {
            errors.push(non_ocr_field);
          }
        }
      }
      // if (non_ocr_field.label === NON_OCR_DOCUMENT_LABEL.GST_REGISTRATION) {
      //   const isFieldInvalid = checkNonOcrFieldValidity(
      //     non_ocr_field.name,
      //     non_ocr_field.label,
      //     non_ocr_field.message,
      //     nonOcrDocuments
      //   );
      //   if (isFieldInvalid) {
      //     errors.push(non_ocr_field);
      //   }
      // }
    }
  );
  if (
    ocrDocuments.aadhar_card &&
    ocrDocuments.aadhar_card.length > 0 &&
    !aadhar_no
  ) {
    errors.push({
      name: "aadhaar_no",
      message: "Please enter aadhar number",
    });
    // form.setFields([{ name: 'aadhar_no', errors: ['Please enter aadhar number'] }]);
  } else if (
    aadhar_no &&
    (!ocrDocuments.aadhar_card ||
      (ocrDocuments.aadhar_card && ocrDocuments.aadhar_card.length === 0))
  ) {
    errors.push({
      name: "aadhar_card",
      message: "Please upload aadhar card",
    });
  } 
  return errors;
};
export const handleDocumentName = (input) => {
  // const input = e.target.value;
  if (!REGEX.NAME_VALIDATION.test(input)) {
    return firstCharacterOfEachWordUpperCase(
      input.substring(0, input.length - 1)
    ).replace(/[^a-zA-Z0-9&-/s ]/gm, "");
  } else {
    return firstCharacterOfEachWordUpperCase(input).replace(
      /[^a-zA-Z0-9&-/s ]/gm,
      ""
    );
  }
};
export const checkAtleastOnePOADocumentUploaded = (ocrDocuments) => {
  let isAadharCardUploaded =
    ocrDocuments &&
    ocrDocuments.aadhar_card &&
    Array.isArray(ocrDocuments.aadhar_card) &&
    ocrDocuments.aadhar_card.length > 0;
  let isDrivingLicenseUploaded =
    ocrDocuments &&
    ocrDocuments.driving_license &&
    Array.isArray(ocrDocuments.driving_license) &&
    ocrDocuments.driving_license.length > 0;
  let isVoterIDUploaded =
    ocrDocuments &&
    ocrDocuments.voter_id &&
    Array.isArray(ocrDocuments.voter_id) &&
    ocrDocuments.voter_id.length > 0;
  let isPassportUploaded =
    ocrDocuments &&
    ocrDocuments.passport &&
    Array.isArray(ocrDocuments.passport) &&
    ocrDocuments.passport.length > 0;
  if (
    isAadharCardUploaded ||
    isDrivingLicenseUploaded ||
    isVoterIDUploaded ||
    isPassportUploaded
  ) {
    return true;
  }
};
export const addNonRequiredNonOcrDoc = (
  documentName,
  uploadedDocument,
  nonRequiredNonOcrDocuments
) => {
  if (!documentName) {
    return {
      name: "DocName",
      errors: [MESSAGES.ENTER_DOCUMENT_NAME],
    };
  }
  if (nonRequiredNonOcrDocuments.find((doc) => doc.name === documentName)) {
    return {
      name: "DocName",
      errors: [MESSAGES.FILE_NAME_EXISTS],
    };
  }
  if (!uploadedDocument) {
    return {
      name: "non_ocr_doc_pic",
      errors: [MESSAGES.PLEASE_UPLOAD_DOCUMENT],
    };
  }

  return false;
};
export const deleteNonOcrDocument = (
  document,
  nonRequiredNonOcrDocuments,
  deleteIndex,
  caseId,
  dispatch,
  removeNonOcr
) => {
  if (document.id) {
    dispatch(
      onDeleteNonOcrDocument({ id: document.id }, () => {
        dispatch(
          onCaseDetail({ caseId: caseId }, (caseDetail) => {
            dispatch(
              onUpdateNewCreatedCase(caseDetail, () => {
                let docs = nonRequiredNonOcrDocuments.filter((doc, index) => {
                  return !doc.id || doc.id !== document.id;
                });
                removeNonOcr(docs);
              })
            );
          })
        );
      })
    );
  } else {
    let docs = nonRequiredNonOcrDocuments.filter((doc, index) => {
      return index !== deleteIndex;
    });
    return docs;
  }
};
export const closeRunOcrModal = (dispatch) => {
  dispatch(onRunOcrResponse(false));
};
export const deleteMemberDirectorNonOcrDocument = (
  document,
  documents,
  deletionIndex,
  dispatch,
  filteredDocs,
  caseId
) => {
  if (document.id) {
    dispatch(
      onDeleteNonOcrDocument({ id: document.id }, () => {
        let docs = documents.filter((doc, index) => {
          return !doc.id || doc.id !== document.id;
        });
        dispatch(
          onCaseDetail({ caseId: caseId }, (caseDetail) => {
            dispatch(
              onUpdateNewCreatedCase(caseDetail, () => {
                filteredDocs(docs);
              })
            );
          })
        );
      })
    );
  } else {
    let docs = documents.filter((doc, index) => {
      return index !== deletionIndex;
    });
    filteredDocs(docs);
  }
};
