import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import Topbar from "../topbar";
import Navigation from "../navigation";
import {
  onChangeFirstTabKey,
  onChangeMiddleTab,
  onChangeSecondTabKey,
  onChangeThirdTabKey,
  onFirstTabChange,
} from "../../redux/actions/TabAction";
import { Col, Tabs, Row, Card, Button, Modal, Form, Select } from "antd";
import Mainapplicantform from "./case-details/MainapplicantForm/MainApplicantDetails";
import Coapplicantform from "./case-details/CoapplicantForm/CoapplicantForm";
import Guarantorform from "./case-details/GuarantorForm/GuarantorDetails";
import { getFieldInvestigationCase } from "../../redux/actions/FieldInvestigation";


const { Option } = Select;
const { TabPane } = Tabs;

export default function FieldInvestigationActionView() {
  const params = useParams();
  const location = useLocation()
  const viewlabel = (location.state?.viewlabel) ? (location.state?.viewlabel) : "";
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [showLabel, setShowLabel] = useState([false, "", "View Details"]);
  const [visible, setVisible] = useState(false);
  const firstTabKey = useSelector((state) => state.tabReducer.firstTabKey);
  const caseData = useSelector((state) => state.fieldInvestigation.fiCase);

  const fetchData = () => {
    const data = {
      id: params.caseId,
      attempt_id: viewlabel ? viewlabel : ""
    };
    dispatch(getFieldInvestigationCase(data, onSuccess))
  };
  const onSuccess = (responseData) => {
    setVisible(true)
  }
  const fIView = () => {
    if (viewlabel === 1) {
      setShowLabel([true, "0", "First FI Details"])
    }
    else if (viewlabel === 2) {
      setShowLabel([true, "1", "Second FI Details"])
    }
    else {
      setShowLabel([false, "0", "View Details"])
    }
  }

  useEffect(() => {
    fIView();
    fetchData();
  }, [dispatch])

  const firstTabChange = (tab) => {
    dispatch(onChangeFirstTabKey(tab));
    dispatch(onChangeSecondTabKey("1"));
    dispatch(onChangeThirdTabKey("1"));
  };

  return (
    <>
      <Topbar />
      <Navigation />
      {/* {visible ? */}
        <div className="FieldTabsWrap FieldDetailTabs">
          <Tabs activekey={firstTabKey} onChange={firstTabChange}>
            <TabPane tab="Main Applicant" key="1" >
              <Mainapplicantform label={viewlabel} viewlabel={showLabel} />
            </TabPane>
            <TabPane tab="Co-Applicant" key="2">
              <Coapplicantform label={viewlabel} viewlabel={showLabel} />
            </TabPane>
            <TabPane tab="Guarantor" key="3">
              <Guarantorform label={viewlabel} viewlabel={showLabel} />
            </TabPane>
          </Tabs>
        </div>
        {/* : <> </>} */}
    </>

  )
}
