import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { isCaseClosed, isCaseNotClosed, isCreditReviewPending, isCreditUser, loggedInUserData } from "../../helpers/utils";
import {
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../redux/actions/CaseAction";
import AddParticipantTabs from "../case-participants/AddParticipantTab";
import AddCase from "./AddCase";
import EditCaseForm from "./forms/EditCaseForm";

const EditCase = (props) => {
  const userData = loggedInUserData()
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const newlyCreatedCase = useSelector((state) => {
    if (state && state.auth) {
      return state.auth.newlyCreatedCase;
    }
    return {};
  });

  useEffect(() => {
    const onSuccess = (caseDetail) => {
      dispatch(onUpdateNewCreatedCase(caseDetail, (response) => {
        
        
      }));
    };
    const onError = ()=>{
      history.push('/')
    }
    if (params.id) {
      const data = {
        caseId: parseInt(params.id),
      };
      dispatch(onCaseDetail(data, onSuccess,onError));
    }
  }, [dispatch, params.id,history]);
  useEffect(()=>{
    if(userData && newlyCreatedCase){
      if(isCaseClosed(newlyCreatedCase.status)){
        history.push('/closedcases')
      }else if(isCreditReviewPending(newlyCreatedCase.status)){
        history.push('/PendingCreditReview')
      }else if(isCreditUser(userData)){
        history.push('/');
      }
    }
  },[history,userData,newlyCreatedCase])
  return (
    <React.Fragment>
      {newlyCreatedCase && <EditCaseForm editOnly={true} />}
      {newlyCreatedCase && newlyCreatedCase.fk_category_id && (
        <div className="LogNewCasesWrap" id="addnextdetails">
          <AddParticipantTabs />
        </div>
      )}
    </React.Fragment>
  );
};
export default EditCase;
