import { Button, Table, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onFetchBureauReportListing, onGetBureauReportListing } from "../../redux/actions/BureauReportAction";

 
  const MainApplicantBureauReportListing = (props) => {
    const {userId,applicantType,caseId ,creditBureauColumns,mainApplicant,caseDetail} = props;
    const [selectionType] = useState("radio");
    const dispatch = useDispatch();
    const [bureauReportListing,setBureauReportListing] = useState([]);
    useEffect(()=>{
        let data = {
          user_id: caseDetail.main_applicant.id,
          member_type: 'Main Applicant',
          case_id: caseDetail.id,
        };
        dispatch(onGetBureauReportListing(data,(bureauList)=>{
            
            setBureauReportListing(bureauList)
        }))
    },[dispatch,mainApplicant,caseDetail])
    
    const rowSelection = {
      onChange: (selectedRowKeys, selectedRows) => {
        props.onSetApplicantBRId(selectedRowKeys[0]);
      },
      getCheckboxProps: (record) => ({
        disabled: record.name === "Disabled User",
        // Column configuration not to be checked
        name: record.name,
      }),
    };
    return (
      <div>
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={creditBureauColumns}
          dataSource={bureauReportListing}
          pagination={false}
          rowKey={'id'}
          locale={{ emptyText: 'No Bureau Reports Generated' }}
        />
      </div>
    );
  };
export default MainApplicantBureauReportListing;
