import React from "react";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import PageLoading from "./components/PageLoading";
import AppRouter from "./routers/AppRouter";
import history from "./utils/history";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";

const App = (props) => {
  const pageLoading = useSelector((state) => state.common.loading);
  
  return (
      <BrowserRouter history={history}>
        <ToastContainer />
        {pageLoading && <PageLoading loading={true} />}
        <AppRouter />
      </BrowserRouter>
  );
};

export default App;
