import {
  LOGIN,
  FORGOT,
  LOGOUT,
  RESET_PASSWORD,
  CHANGE_SELECTED_USER,
  SET_CURRENT_CASE,
  RESET_INDIVIDUAL_MODAL,
  CHANGE_SELECTED_MEMBER_DIRECTOR,
  SSO_LOGIN,
  SET_USER_PERMISSIONS
} from "../constants/ActionTypes";

/**
 * @method onLogin
 * @description login user
 * @param {* employeeId,password } onSuccess
 * @returns
 */
export const onLogin = (data, onSuccess) => ({
  type: LOGIN,
  payload: data,
  onSuccess,
});
/**
 * @method onSSOLogin
 * @description login user
 * @param {* employeeId,password } onSuccess
 * @returns
 */
 export const onSSOLogin = (data, onSuccess,onError) => ({
  type: SSO_LOGIN,
  payload: data,
  onSuccess,
  onError,
});
/**
 * @method onForgotPassword
 * @description send mail to email associated with employee id
 * @param {* employeeId } onSuccess
 * @returns
 */
export const onForgotPassword = (data, onSuccess) => ({
  type: FORGOT,
  payload: data,
  onSuccess,
});
/**
 * @method onResetPassword
 * @description password reset of first time login user.
 * @param {* password,password_confirmation} onSuccess
 * @returns
 */
export const onResetPassword = (data, onSuccess) => {
  return {
    type: RESET_PASSWORD,
    payload: data,
    onSuccess,
  };
};
/**
 * @method onLogout
 * @description logout user
 * @param {*} onSuccess
 * @returns
 */
export const onLogout = (onSuccess,onError) => ({
  type: LOGOUT,
  onSuccess: onSuccess,
  onError:onError,
});

//currently not usable
// /**
//  * @method onChangeMenuItem
//  * @description change tab
//  * @param {*tabIndex}
//  * @returns
//  */
// export const onChangeMenuItem = (tab) => {
//   return {
//     type: SET_CURRENT_TAB,
//     payload: tab,
//   };
// };


export const onChangeSelectedUser = (user) => {
  return {
    type: CHANGE_SELECTED_USER,
    user,
  };
};

//not usable yet
// export const onSetPageKey = (key) => ({
//   type:SET_PAGE_KEY,
//   key,
// });



export const onSetCurrentCase = (currentCase={})=>({
  type:SET_CURRENT_CASE,
  data:currentCase,
})
export const onResetIndividualModal = (toReset)=>{
  return {
    type:RESET_INDIVIDUAL_MODAL,
    toReset,
  }
}
export const onChangeSelectedMemberDirector = (user) => {
  return {
    type: CHANGE_SELECTED_MEMBER_DIRECTOR,
    data:user,
  };
};

/**
 * @method onSetUserPermissions
 * @description 
 * @param {*} 
 * @returns
 */
 export const onSetUserPermissions = (data, onSuccess) => {
   console.log('data: 22222222222222222222', data);
   return {
  type: SET_USER_PERMISSIONS,
  data,
  onSuccess: onSuccess,
}
};