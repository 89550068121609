import React, { useState } from "react";
import { Form, Row, Col, Upload, Button, Input } from "antd";
import { connect, useDispatch } from "react-redux";
import uplaodIcon from "../../assets/images/uploadfile.png";
import { onUpdateNonOcrDocs } from "../../redux/actions/AuthAction";
import Modal from "antd/lib/modal/Modal";
import { toast } from "react-toastify";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
} from "../../helpers/utils";
const UploadNonOcrDocuments = (props) => {
  const { nonOcrDocuments } = props;
  const dispatch = useDispatch();
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const handlePreview = async (file) => {
    var reader = new FileReader();
    var url = reader.readAsDataURL(file.doc.file);
    reader.onloadend = function (e) {
      setPreviewImage(reader.result);
    }.bind(this);
    setPreviewVisible(true);
  };
  const beforeUpload = (file) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file)) {
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  return (
    <Row gutter={32}>
      <Col span={24}>
        <h4>Non OCR Compatible Documents</h4>
      </Col>
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item label="Document Name" name="DocName">
          <Input
            onFocus={() => {
              props.form.setFields([
                {
                  name: "DocName",
                  errors: [],
                },
              ]);
            }}
          />
        </Form.Item>
        <Form.Item
          label="Upload"
          name="non_ocr_doc_pic"
          onFocus={() => {
            props.form.setFields([
              {
                name: "non_ocr_doc_pic",
                errors: [],
              },
            ]);
          }}
        >
          <Upload maxCount={1} beforeUpload={beforeUpload}>
            <Button>
              Upload <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
        <div className="AddField text-right mt-2 mb-2">
          <Button htmlType="button" onClick={props.addNonOcrDocuments}>
            Add
          </Button>
        </div>
      </Col>
      <Col span={12} md={12} xs={24} sm={24}>
        {nonOcrDocuments.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>Document Name </th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {nonOcrDocuments &&
                nonOcrDocuments.map((document, index) => {
                  return (
                    <tr key={document.name}>
                      <td>{document.name}</td>
                      <td>
                        <Button
                          onClick={() => {
                            if (document.doc && document.doc.file) {
                              handlePreview(document);
                            } else {
                              setPreviewImage(document.doc);
                              setPreviewVisible(true);
                            }
                          }}
                        >
                          View
                        </Button>
                        <Button
                          onClick={() => {
                            if (document.id) {
                              props.deleteNonOcrDocumentFromBackEnd(
                                document.id
                              );
                            }
                            props.removeNonOcrDocuments(index);
                          }}
                        >
                          Delete
                        </Button>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
      </Col>
      <Modal
        visible={previewVisible}
        // title={previewTitle}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>
    </Row>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
    isCaseSaved: auth.isNewlyCreatedCaseSaved,
    savedCase: auth.newlyCreatedCase,
    mainApplicant: auth.mainApplicant,
    coApplicant: auth.coApplicant,
    gurantor: auth.gurantor,
  };
};
export default connect(mapStateToProps, null)(UploadNonOcrDocuments);
