import React, { Component } from "react";
import { Card } from "antd";
class AskJustification extends Component {
    render() {
      return (
        <>
        <div className='TextWrap'>
            <h4>Enter a reason why justification is required</h4>
            <Card>
                <p>Address on Aadhar and passport is not matching. please provide at  least 1 supporting document where name is same as PAN and address is same as Aadhar.</p>
            </Card>
        </div>
        </>
      )
    }
}
export default AskJustification;