import { UPDATE_MAIN_APPLICANT } from "../constants/ActionTypes";
 /**
 * @method onUpdateMainApplicant
 * @description update a main applicant
 * @param {*}
 * @returns
 */
export const onUpdateMainApplicant = (data,onSuccess) => {
    return {
      type: UPDATE_MAIN_APPLICANT,
      data,
      onSuccess
    };
  };