import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Input, Row, Select, Space, Table } from "antd";
import ViewIcon from "../../assets/images/view.png";
import moment from "moment";
import { Link } from "react-router-dom";
import {
  onLoadVaahanDetails,
  onUploadStoreVaahanDetails,
  runHypothecation,
} from "../../redux/actions/VaahanAction";
import { Alert, Modal, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import VahanRequestDetailModal from "../modals/vahanRequestDetailModal";
import { base64ToExcel, base64ToPdf, dataURLtoFile, getBase64 } from "../../helpers/utils";

function Index() {
  const formData = new FormData();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [tableData, setTableData] = useState([]);
  const [searchedInput, setSearchedInput] = useState("");
  const [dataList, setdataList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [uploadExcelSheet, setUploadExcelSheet] = useState(true);
  const [uploadHide, setuploadHide] = useState("");
  const [fileList, setFileList] = useState([]);
  const [templateName, setTemplateName] = useState("vahan_details_store");
  const [visible, setVisible] = useState(false);
  const [requestData, setRequestData] = useState([]);
  const [requestType, setRequestType] = useState("");
  const [downloadErrorReport, setDownloadErrorReport] = useState(false);
  const [errorLink, setErrorLink] = useState("");
  const [errorFileName, setErrorFileName] = useState("");

  const columns = [
    {
      title: "Vaahan Request ID",
      dataIndex: "vahan_request_id",
      key: "vahan_request_id",
      sorter: (a, b) => a.vahan_request_id - b.vahan_request_id,
    },
    {
      title: "Chassis No",
      dataIndex: "chachis_no",
      key: "vehicle_number",
      render: (text) => text || "N/A", // Display value if present, otherwise "N/A"
    },
    {
      title: "Engine No",
      dataIndex: "eng_no",
      key: "eng_no",
      render: (text) => text || "N/A", // Display value if present, otherwise "N/A"
    },
    {
      title: "Registration No",
      dataIndex: "reg_no",
      key: "reg_no",
      render: (text) => text || "N/A", // Display value if present, otherwise "N/A"
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",

      render: (text, row, record) => {
        return <div>{moment(row.created_at).format("ll")}</div>;
      },
    },
    {
      title: "Updated Date",
      dataIndex: "updated_at",
      key: "updated_at",

      render: (text, row, record) => {
        return <div>{moment(row.updated_at).format("ll")}</div>;
      },
    },
    {
      title: "HPA Status/Date",
      dataIndex: "hpa_status",
      key: "hpa_status",

      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              {row?.hpa_status === 0 ? (
                <Button
                  className="AddField"
                  onClick={() => runRequest(row, "HPA")}
                >
                  RUN
                </Button>
              ) : (
                `Completed  ${moment(
                  row?.hpa_date
                ).format('DD/MM/YYYY')} `
              )}
            </Space>
          </div>
        );
      },
    },
    {
      title: "HPC Status/Date",
      dataIndex: "hpc_status",
      key: "hpc_status",

      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              {row?.hpc_status === 0 ? (
                <Button
                  className="AddField"
                  onClick={() => runRequest(row, "HPC")}
                >
                  RUN
                </Button>
              ) : (
                `Completed  ${moment(
                  row?.hpc_date
                ).format('DD/MM/YYYY')} `
              )}
            </Space>
          </div>
        );
      },
    },
    {
      title: "HPT Status/Date",
      dataIndex: "hpt_status",
      key: "hpt_status",

      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              {row?.hpt_status === 0 ? (
                <Button
                  className="AddField"
                  onClick={() => runRequest(row, "HPT")}
                >
                  RUN
                </Button>
              ) : (
                `Completed  ${moment(
                  row?.hpt_date
                ).format('DD/MM/YYYY')} `
              )}
            </Space>
          </div>
        );
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      key: "vahan_request_id",

      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              <Link to={`/vaahan-request-view/${row.vahan_request_id}`}>
                <img
                  src={ViewIcon}
                  alt={row.vahan_request_id}
                  title="Click to view vaahan request"
                />
              </Link>
            </Space>
          </div>
        );
      },
    },
  ];

  const handleFileSubmit = (data) => {
    const fileCheck = data.stor_vaahan_details.file.status;
    if (fileCheck === "removed") {
      toast.error("Please select file");
      return;
    } else {
      formData.append(
        "file",
        data.stor_vaahan_details && data.stor_vaahan_details.file
      );
    }
    dispatch(onUploadStoreVaahanDetails(formData, onFileUploadSuccess));
  };

  const props = {
    onRemove: (file) => {
      setFileList([]);
    },
    beforeUpload: (file) => {
      console.log(file.type);
      if (
        file.type === "application/kset" ||
        file.type === "application/vnd.ms-excel"
      ) {
        setFileList([file]);
        return false;
      } else {
        setFileList([]);
        toast.error(
          `Incorrect file format. Upload only .xls format excel. Download template if needed`
        );
        return false;
      }
    },
    fileList,
  };

  const onSuccess = (response) => {
    const resData = response?.data?.data;
    setTableData(resData);
    setdataList(resData);
    
  };

  const onFileUploadSuccess = (response) => {
    console.log(response,"lklk")
    if (response.data.status === true) {
      setIsModalVisible(false);
      setFileList([]);
      setUploadExcelSheet(true);
      loadDetails();
      toast.success(response.data.message);
    }
    if (response.data.status === false) {
      toast.error(response.data.message);
      setDownloadErrorReport(true);
      setErrorLink(response.data.error_report.file);
      setErrorFileName(response.data.error_report.name);
    }
  };

  const loadDetails = () => {
    dispatch(onLoadVaahanDetails(onSuccess));
  };

  const handleDownloadTemplate = (e) => {
    const defaultFileName = `${templateName}.xls`;
    let fileName = `${templateName}.xls`;
    const link = e.target;
    link.setAttribute("download", fileName);
    link.href = link.href.replace(defaultFileName, fileName);
  };
  const onDownloaded = () => {
    base64ToExcel(errorLink,errorFileName)
    setIsModalVisible(false);
      setFileList([]);
      setUploadExcelSheet(true);
      setErrorLink("");
      setDownloadErrorReport(false);
      setErrorFileName("");
  };

  const filterVaahanListing = (e) => {
    if (e.target.value === "") {
      setTableData(dataList);
    } else {
      const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
      const filteredCases = dataList.filter((vaahanCase) => {
        let requestId = vaahanCase.vahan_request_id
          .toString()
          .includes(searchedInput);
        return requestId;
      });
      setTableData(filteredCases);
    }
  };

  const handleFileUpload = () => {
    setIsModalVisible(true);
  };

  const handleFileUploadClose = () => {
    setIsModalVisible(false);
    setFileList([]);
    form.resetFields();
  };
  const handleCancel = (data) => {
    setVisible(false);
    setRequestData([]);
    setRequestType("");
    form.resetFields();
  };

  const onRunHypoSuccess = (response) => {
    const resData = response?.data;
    console.log(resData);
    if (resData?.status) {
      toast.success(resData.message);
      setVisible(false);
      form.resetFields();
    }
  };

  const handleSubmit = (data) => {
    if (data?.type === "HPA") {
      if (data?.from_date) {
        data.from_date = moment(data?.from_date).format("YYYY-MM-DD");
      }
      if (data?.upto_date) {
        data.upto_date = moment(data?.upto_date).format("YYYY-MM-DD");
      }
    } else if (data?.type === "HPC") {
      if (data?.from_date) {
        data.from_date = moment(data?.from_date).format("YYYY-MM-DD");
      }
      if (data?.upto_date) {
        data.upto_date = moment(data?.upto_date).format("YYYY-MM-DD");
      }
    } else if (data?.type === "HPT") {
      data.termination_date = moment(data?.from_date).format("YYYY-MM-DD");
    }
    dispatch(runHypothecation(data, onRunHypoSuccess));
  };
  const runRequest = (data, type) => {
    setRequestType(type);
    setVisible(true);
    setRequestData(data);
  };

  useEffect(() => {
    loadDetails();
  }, []);

  return (
    <>
      <div className="CaseListData CaseListDataMobile">
        <Row>
          <Col span={22}>
            <Form>
              <Input.Group compact>
                <Input.Search
                  allowClear
                  placeholder="Search On Vaahan Request ID"
                  value={searchedInput}
                  onChange={(e) => {
                    setSearchedInput(e.target.value);
                    filterVaahanListing(e);
                  }}
                />
              </Input.Group>
            </Form>
          </Col>
          <Col span={2}>
            <Button
              size="large"
              className="AddField"
              title="Click to upload excel sheet"
              onClick={handleFileUpload}
            >
              Upload Sheet
            </Button>
          </Col>
        </Row>
        <Table columns={columns} dataSource={tableData} pagination={true} />
      </div>
      <Modal
        title="Upload File"
        visible={isModalVisible}
        onOk={handleFileSubmit}
        onCancel={handleFileUploadClose}
        // className="KycCheck"
        className="JustReqWrap"
        footer={[
          <Button key="back" onClick={handleFileUploadClose}>
            Cancel
          </Button>,
          <Button
            form="myNewForm"
            key="submit"
            htmlType="submit"
          >
            Submit
          </Button>
        ]}
        
        destroyOnClose={true}
      >
        <Form
          name="global_state"
          form={form}
          onFinish={handleFileSubmit}
          id="myNewForm"
          style={{display:"flex",flexDirection:"row",marginLeft:"6%"}}

        >
            <Col span={12}>
              <Form.Item
                label="Upload Sheet*"
                name="stor_vaahan_details"
                required={uploadExcelSheet}
                rules={[
                  {
                    required: uploadExcelSheet,
                    message: "Please select excel sheet",
                  },
                ]}
                style={{ display: uploadHide,marginTop:"20px" }}
              >
                <Upload {...props} accept=".xls">
                  <Button icon={<UploadOutlined />}>Select File</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Button
                size="large"
                className="AddField"
                title="Click to download sample excel sheet"
                style={{marginTop:"20px",height:"32px",marginLeft:"25px"}}
              >
                <a
                  href={`./assets/${templateName}.xls`}
                  onClick={handleDownloadTemplate}
                >
                  Download Template
                </a>
              </Button>
            </Col>
        </Form>
        {downloadErrorReport &&   <Col span={24}>
            <Button
                size="large"
                className="AddField"
                title="Click to download error report"
                style={{height:"32px",marginLeft:"34%"}}
                onClick={onDownloaded}
              >
                  Download Error Report
              </Button>
            </Col>}
      </Modal>
      {visible && (
        <VahanRequestDetailModal
          isModalVisible={visible}
          handleCancel={handleCancel}
          handleOk={handleSubmit}
          data={requestData}
          form={form}
          type={requestType}
        />
      )}
    </>
  );
}

export default Index;
