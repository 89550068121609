import React, { Component, useEffect } from "react";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import BureauGeneratedReport from "../components/bureaureportgenerated";
import { useHistory, useParams } from "react-router";
import { onCaseDetail } from "../redux/actions/CaseAction";
import { useDispatch } from "react-redux";
const GeneratedReport = (props) => {
  const params = useParams();
  const history = useHistory()
  const dispatch = useDispatch();
  // useEffect(() => {
  //   const onSuccess = (caseDetail)=>{
  //     if(caseDetail && caseDetail.bureau_report_generated && caseDetail.bureau_report_generated == 1){

  //     }else{
  //       history.push('/')
  //     }
  //   }
  //   const onError = ()=>{
  //     history.push('/')
  //   }
  //   if (params && params.id) {
  //     dispatch(onCaseDetail({ caseId: params.id }, onSuccess,onError));
  //   }
  // }, [dispatch]);

  return (
    <>
      <div className="dashBoardWrap">
        <TopBarNav />
        <Navigation />
        <BureauGeneratedReport />
      </div>
    </>
  );
};
export default GeneratedReport;
