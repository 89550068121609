import React, { Component, useEffect, useState } from "react";
import { Collapse } from "antd";
import InvalidIcon from '../../../../assets/images/invalid.png'
import CheckIcon from '../../../../assets/images/completedcheck.png'
import { OCR_DOCUMENT_LABEL } from "../../../../constants/constants";
const { Panel } = Collapse;
const RunOcr = (props) => {
  const { ocrResponse=[],data} = props;
  
  const [panCardStatus,setPanCardStatus] = useState('');
  const [aadharCardStatus,setAadharCardStatus] = useState('');
  const [voterIdStatus,setVoterIdStatus] = useState('');
  const [drivingLicenseStatus,setDrivingLicenseStatus] = useState('');
  const [passportStatus,setPassportStatus] = useState('');
  const [gstRegistrationStatus,setGstRegistrationStatus] = useState('');

  useEffect(()=>{
    ocrResponse.forEach((ocrRes)=>{
      
      if(ocrRes.message==='Success'){
        ocrRes.message='completed'
      }
      if(ocrRes.type=== 'PAN Card'){
        setPanCardStatus(ocrRes.message)
      }
      else if(ocrRes.type===OCR_DOCUMENT_LABEL.AADHAR_CARD){
        setAadharCardStatus(ocrRes.message);
      }else if(ocrRes.type===OCR_DOCUMENT_LABEL.DRIVING_LICENSE){
        setDrivingLicenseStatus(ocrRes.message);
      }else if(ocrRes.type===OCR_DOCUMENT_LABEL.PASSPORT){
        setPassportStatus(ocrRes.message);
      }else if(ocrRes.type===OCR_DOCUMENT_LABEL.VOTER_ID){
        setVoterIdStatus(ocrRes.message)
      }else if(ocrRes.type===OCR_DOCUMENT_LABEL.GST_REGISTRATION){
        setGstRegistrationStatus(ocrRes.message)
      }
    })
  },[ocrResponse])
  return (
    <>
      <div className="CollapseWrap">
        <h3 className="heading">
        <pre>
        <div>
          <img src={CheckIcon} alt="valid" />  Success {"  "}{" "}
          <img src={InvalidIcon} alt="valid" />  Failed
        </div>
        </pre>
        </h3>
        <Collapse accordion defaultActiveKey={1}>
          <Panel header={(data&& data.applicant_type)?data.applicant_type:''} key="1" >
            <ul>
              {panCardStatus && <li className={panCardStatus}>PAN Card</li>}
              {aadharCardStatus && <li className={aadharCardStatus}>Aadhar Card</li>}
              {voterIdStatus && <li className={voterIdStatus}>Voter ID</li>}
              {drivingLicenseStatus && <li className={drivingLicenseStatus}>Driving License</li>}
              {passportStatus && <li className={passportStatus}>Passport</li>}
              {/*<li className="completed">Email Authentication</li>
  <li className="loading">Mobile No Authentication</li>*/}
  {gstRegistrationStatus && ocrResponse && ocrResponse.fk_category_id != "1" && (
    <li className={gstRegistrationStatus}>GST Registration</li>
  )}
            </ul>
            
          </Panel>
        </Collapse>
      </div>
    </>
  );
};
export default RunOcr;
