import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Col,
  Form,
  Row,
  Select,
  Input,
  Modal,
} from "antd";
import { CaretDownOutlined, DownloadOutlined } from '@ant-design/icons';
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import { Collapse } from 'antd';
const { Option } = Select;
const { TextArea } = Input;
const Panel = Collapse.Panel;
const customPanelStyle = {
  background: 'none',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};
export default function MainApplicantDetails(viewlabel, label) {
  const dispatch = useDispatch();
  const caseData = useSelector((state) => state.fieldInvestigation.fiCase);
  const [form] = Form.useForm();
  const [openImage, setopenImage] = useState(false);
  const [imgurl, setimageurl] = useState("");

  const handleImage = (e, value) => {
    setopenImage(true);
    setimageurl(e);
  }
  const handleCancel = () => {
    setopenImage(false);
  };

  const handleDownload = (imgurl) => { 
    const link = document.createElement('a');
    link.href = imgurl;
    link.target = '_blank';
    link.download = imgurl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {caseData?.main_applicant === undefined ? (
        <>
          <div className="card card-body">
            <h3 style={{ textAlign: "center" }}>No Main Applicant</h3>
          </div>
        </>
      ) : (
        <div>
          {label != "" ? (
            <div className=" card card-body">
              <div>
                <h4 style={{ marginBottom: "0" }}>
                  {viewlabel?.viewlabel[2]} - {caseData.id}
                </h4>
                <hr />
              </div>

              <Row gutter={24}>
                <Form
                  form={form}
                  layout="inline"
                  autoComplete="off"
                  name="basic"
                  initialValues={{
                    remember: true,
                  }}
                  style={{ width: "100%" }}
                >
                  <Col span={8} md={24} xs={24} sm={24}>
                    <Row>
                      {caseData?.main_applicant?.category_name ===
                      "Individual" ? (
                        <>
                          <Col
                            span={12}
                            xs={24}
                            md={12}
                            sm={12}
                            style={{ paddingBottom: "18px" }}
                          >
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <span className="font-weight-bold">
                                  First Name
                                </span>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <spanp>
                                  {caseData?.main_applicant?.first_name
                                    ? caseData?.main_applicant?.first_name
                                    : "-"}
                                </spanp>
                              </Col>
                            </Row>
                          </Col>

                          <Col span={8} md={12} xs={24} sm={12}>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <span className="font-weight-bold">
                                  Last Name
                                </span>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <span>
                                  {caseData?.main_applicant?.last_name
                                    ? caseData?.main_applicant?.last_name
                                    : "-"}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col span={12} xs={24} sm={12}>
                            <p className="font-weight-bold">
                              Organization Name
                            </p>
                          </Col>
                          <Col span={12} xs={24} sm={12}>
                            <p>
                              {caseData?.main_applicant?.organization_name
                                ? caseData?.main_applicant?.organization_name
                                : "-"}
                            </p>
                          </Col>
                        </>
                      )}
                    </Row>
                    <hr style={{ marginTop: "0" }} />
                  </Col>
                </Form>
              </Row>
              <Collapse className="vfs-collapse">
                <Panel
                  header="First FI Details"
                  key="1"
                  style={customPanelStyle}
                >
                  {caseData?.main_applicant?.fi_details?.first_fi?.map(
                    (data, index) => {
                      return (
                        <>
                          <Row>
                            <Col span={12} md={24} xs={24} sm={24}>
                              <div
                                style={{
                                  borderBottom: "1px solid rgba(0,0,0,.1)",
                                  marginBottom: "15px",
                                }}
                              >
                                <Row gutter={24}>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          Agency Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.agency_name
                                            ? data?.agency_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          City Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.city_name
                                            ? data?.city_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          State Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.state_name
                                            ? data?.state_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          Region Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.region_name
                                            ? data?.region_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          Feedback Type
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.feedback_type
                                            ? data?.feedback_type
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row
                                  gutter={24}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <Col span={12} xs={24} sm={4}>
                                    <p
                                      className="font-weight-bold"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      Remark
                                    </p>
                                  </Col>
                                  <Col
                                    span={12}
                                    xs={24}
                                    sm={20}
                                    style={{
                                      paddingLeft: "0",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    <p>
                                      {data?.response_remark
                                        ? data?.response_remark
                                        : "-"}
                                    </p>
                                  </Col>
                                </Row>

                                <Row
                                  gutter={24}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <Col span={12} xs={24} sm={4}>
                                    <p className="font-weight-bold">
                                      Fi Attachments
                                    </p>
                                  </Col>
                                  <Col
                                    span={12}
                                    xs={24}
                                    sm={20}
                                    style={{
                                      paddingLeft: "0",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    <p>
                                      {data?.fi_attachments?.map(
                                        (file, index) => {
                                          return (
                                            <>
                                              <div key={index}>
                                                <Row span={12} xs={24} sm={12}>
                                                  <p
                                                    id={file[index]}
                                                    onClick={() =>
                                                      handleDownload(file)
                                                    }
                                                    style={{
                                                      color: "blue",
                                                      fontWeight: "bold",  
                                                      fontSize: "14px",  
                                                    }}
                                                  >
                                                    <a title = 'click to open or download the file'> Document-{index+1}</a>
                                                  </p>
                                                </Row>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  )}
                </Panel>
                <Panel
                  header="Second FI Details"
                  key="2"
                  style={customPanelStyle}
                >
                  {caseData?.main_applicant?.fi_details?.second_fi?.map(
                    (data, index) => {
                      return (
                        <>
                          <Row>
                            <Col span={12} md={24} xs={24} sm={24}>
                              <div
                                style={{
                                  borderBottom: "1px solid rgba(0,0,0,.1)",
                                  marginBottom: "15px",
                                }}
                              >
                                <Row gutter={24}>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          Agency Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.agency_name
                                            ? data?.agency_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          City Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.city_name
                                            ? data?.city_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          State Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.state_name
                                            ? data?.state_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          Region Name
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.region_name
                                            ? data?.region_name
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                  <Col span={8} md={8} xs={24} sm={24}>
                                    <Row>
                                      <Col span={12} xs={24} sm={12}>
                                        <p className="font-weight-bold">
                                          Feedback Type
                                        </p>
                                      </Col>
                                      <Col span={12} xs={24} sm={12}>
                                        <p>
                                          {data?.feedback_type
                                            ? data?.feedback_type
                                            : "-"}
                                        </p>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                                <Row
                                  gutter={24}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <Col span={12} xs={24} sm={4}>
                                    <p
                                      className="font-weight-bold"
                                      style={{ marginBottom: "0px" }}
                                    >
                                      Remark
                                    </p>
                                  </Col>
                                  <Col
                                    span={12}
                                    xs={24}
                                    sm={20}
                                    style={{
                                      paddingLeft: "0",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    <p>
                                      {data?.response_remark
                                        ? data?.response_remark
                                        : "-"}
                                    </p>
                                  </Col>
                                </Row>

                                <Row
                                  gutter={24}
                                  style={{ marginBottom: "0px" }}
                                >
                                  <Col span={12} xs={24} sm={4}>
                                    <p className="font-weight-bold">
                                      Fi Attachments
                                    </p>
                                  </Col>
                                  <Col
                                    span={12}
                                    xs={24}
                                    sm={20}
                                    style={{
                                      paddingLeft: "0",
                                      marginLeft: "0px",
                                    }}
                                  >
                                    <p>
                                      {data?.fi_attachments?.map(
                                        (file, index) => {
                                          return (
                                            <>
                                              <div key={index}>
                                                <Row span={12} xs={24} sm={12}>
                                                  <p
                                                    id={file[index]}
                                                    onClick={() =>
                                                      handleDownload(file)
                                                    }
                                                    style={{
                                                      color: "blue",
                                                      fontWeight: "bold",  
                                                      fontSize: "14px",  
                                                    }}
                                                  >
                                                   <a title = 'click to open or download the file'> Document-{index+1}</a>
                                                  </p>
                                                </Row>
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </>
                      );
                    }
                  )}
                </Panel>
              </Collapse>
              {/* Accordion FI  Start */}
              

              {/* Accordion FI  End */}
            </div>
          ) : (
            <> </>
          )}

          <Modal
            open={openImage}
            onCancel={handleCancel}
            centered
            footer={null}
            width={700}
            height={700}
            //bodyStyle={{ height: 700 }}
          >
            {imgurl.endsWith(".pdf") ? (
              <iframe
                title="Preview"
                src={imgurl}
                style={{ width: "100%", height: "60vh", marginTop: "5%" }}
              />
            ) : (
              <img
                alt="Preview"
                style={{ width: "100%", height: "60vh" }}
                src={imgurl}
              />
            )}

            {/* <a href={imgurl} target="_blank" rel="noreferrer" onClick={handleDownload}>
              <DownloadOutlined />
            </a> */}

            {/* <object data={imgurl} width="650px" height="650px"></object> */}
          </Modal>
        </div>
      )}
    </>
  );
}
