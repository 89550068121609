import { Button, Radio, Tabs, Card, Modal } from "antd";
import React, { Component } from "react";
import Crif from './crif';
import AddnewCoapplicant from '../../modals/addcoapplicant';
import {Link} from "react-router-dom";
const { TabPane } = Tabs;
class Guarantor extends Component {
  state = {
    addcoapplicant: false,
  };
  coapplicant(addcoapplicant) {
    this.setState({ addcoapplicant });
  }
  handleCancel = () => {
    this.setState({ addcoapplicant: false });
  };
  render() {
    return (
      <>
       <div className="HeaderPanelAction">
          <h3></h3>
          <div className='ButtonWrap'>
          <Button><Link to="/BureauReport">Re-Run Report</Link></Button>
            <Button onClick={() => this.coapplicant(true)}>Add New Co Applicant</Button>
          </div>
         
        </div>
        <div className="TableWrap">
          <table>
            <thead>
              <tr>
                <th>Co Applicant Name</th>
                <th>Relation with Applicant</th>
                <th>Credit Score</th>
                <th>Last Date of Report Run</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                <Radio.Group name="radiogroup" defaultValue={1}>
                    <Radio value={1}>Avichal Rawat </Radio>
                  
                  </Radio.Group>
                </td>
                <td>Son</td>
                <td>325</td>
                <td>01/03/2021</td>
                <td>Active</td>
                <td>
                  <Button>Drop</Button>
                  <Button>Approve</Button>
                </td>
              </tr>
           
            </tbody>
          </table>
        </div>
        <div className="HeaderPanelAction">
          <h3>Report Run Summary For Avichal Rawat </h3>
        </div>
        <div className="TableWrap">
          <table>
            <thead>
              <tr>
                <th>Bureau Name</th>
                <th>Score</th>
                <th>Report Generated on </th>
                <th>Run Parameters</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>CIBIL</td>
                <td>256</td>
                <td>01/02/2021</td>
                <td>
                  <a>Click Here</a>
                </td>
                <td>
                  <Button>view report</Button>
                  <Button>Download PDF</Button>
                  <Button>Download Summary</Button>
                </td>
              </tr>
              <tr>
                <td>CIBIL</td>
                <td>256</td>
                <td>01/02/2021</td>
                <td>
                  <a>Click Here</a>
                </td>
                <td>
                  <Button>view report</Button>
                  <Button>Download PDF</Button>
                  <Button>Download Summary</Button>
                </td>
              </tr>
              <tr>
                <td>CIBIL</td>
                <td>256</td>
                <td>01/02/2021</td>
                <td>
                  <a>Click Here</a>
                </td>
                <td>
                  <Button>view report</Button>
                  <Button>Download PDF</Button>
                  <Button>Download Summary</Button>
                </td>
              </tr>
              <tr>
                <td>CIBIL</td>
                <td>256</td>
                <td>01/02/2021</td>
                <td>
                  <a>Click Here</a>
                </td>
                <td>
                  <Button>view report</Button>
                  <Button>Download PDF</Button>
                  <Button>Download Summary</Button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="InnerTabsApplicant">
            <Card>
          <Tabs defaultActiveKey="1">
            <TabPane tab="CRIf" key="1">
                <Crif/>
            </TabPane>
            <TabPane tab="CIBIL" key="2"></TabPane>
          </Tabs>
          </Card>
        </div>

      {/* Add New Co-Applicant */}
      <Modal
          title="Add New Co-Applicant"
          centered
          visible={this.state.addcoapplicant}
          onCancel={this.handleCancel}
          footer={[
            <Button  onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button  className='filled'  onClick={this.handleCancel}>
            Save
          </Button>,
           <Button  className='filled'  onClick={this.handleCancel}>
           Save and Clear
         </Button>
          ]}
          width={1000}
          className='AddCoapplicant'
          style={{ top: 50 }}
        >
         <AddnewCoapplicant/>
        </Modal>
         {/* Add New Co-Applicant */}

      </>
    );
  }
}
export default Guarantor;
