import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Link, useParams, useHistory,useLocation } from "react-router-dom";
import Topbar from "../topbar";
import Navigation from "../navigation";
import {
  onChangeFirstTabKey,
  onChangeMiddleTab,
  onChangeSecondTabKey,
  onChangeThirdTabKey,
  onFirstTabChange,
} from "../../redux/actions/TabAction";
import { Col, Tabs, Row, Card, Button, Modal, Form, Select } from "antd";
import Mainapplicantform from "./request-button/MainapplicantForm/MainApplicantDetails";
import Coapplicantform from "./request-button/CoapplicantForm/CoapplicantForm";
import Guarantorform from "./request-button/GuarantorForm/GuarantorDetails";
import { getFieldInvestigationCase } from "../../redux/actions/FieldInvestigation";


const { Option } = Select;
const { TabPane } = Tabs;

export default function FieldInvestigationActionView() {
  const params = useParams();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const location = useLocation()
  const viewlabel = (location.state?.viewlabel) ? (location.state?.viewlabel) : "";
  const firstTabKey = useSelector((state) => state.tabReducer.firstTabKey);
 

  const id = useParams();

  const onSuccess = (responseData) => {
  }
  const fetchData = () => {
    const data = {
      id: params.caseId,
      attempt_id: viewlabel
    };
   dispatch(getFieldInvestigationCase(data, onSuccess))
  };

  useEffect(() => {
    fetchData();
  }, [])

  const firstTabChange = (tab) => {
    dispatch(onChangeFirstTabKey(tab));
    dispatch(onChangeSecondTabKey("1"));
    dispatch(onChangeThirdTabKey("1"));
  };

  return (
    <>
      <Topbar />
      <Navigation />
      <div className="FieldTabsWrap FieldDetailTabs">
        <Tabs activekey={firstTabKey} onChange={firstTabChange}>
          <TabPane tab="Main Applicant" key="1" >
            <Mainapplicantform label = {viewlabel}/>
          </TabPane>
          <TabPane tab="Co-Applicant" key="2">
            <Coapplicantform label = {viewlabel}/>
          </TabPane>
          <TabPane tab="Guarantor" key="3">
            <Guarantorform label = {viewlabel}/>
          </TabPane>
        </Tabs>
      </div>
    </>

  )
}
