import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Input,
  Form,
  Button,
  Modal,
} from "antd";
import { MESSAGES } from "../../constants/messages";

const { TextArea } = Input;

const RejectCaseModal = (props) => {
  const { isModalVisible, handleCancel, handleOk } =props;
  return (
    <>
    <Modal
        title="Reject Reason"
        visible={isModalVisible}
        // visible={true}
        onCancel={handleCancel}
        className='JustReqWrap'
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button type="primary" form="myForm" key="submit" htmlType="submit" >
            Submit
          </Button>,
        ]}
        style={{ top: 90 }}
        destroyOnClose={true}
      >
        <Form
          id="myForm"
          name="communication"
          onFinish={handleOk}
          form={props.form}
        >
        <div style={{textAlign:'end', color:'red',fontWeight:'bold'}}>*</div>
          <Form.Item
              name="reason"
              rules={[
                {
                  required: true,
                  message: 'Please specify the reason',
                },
                {
                  max: 250,
                  message: MESSAGES.COMMENT_MAX_LENGTH,
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Enter your reason here"
                size={250}
              />
            </Form.Item>
        </Form>
        
      </Modal>
    </>
  );
};

export default RejectCaseModal;
