import Modal from "antd/lib/modal/Modal";
const PreviewImageOrPDF = ({
  previewImage,
  previewPdf,
  previewVisible,
  onCancel,
}) => {
  return (
    <Modal
      visible={previewVisible}
      footer={null}
      onCancel={onCancel}
      className="KycCheck"
      style={{ top: 80 }}
    >
      {previewImage && (
        <img alt="example" style={{ width: "100%",height:'550px' }} src={previewImage} className="mt-2"/>
      )}
      {previewPdf && (
        <iframe
          alt=""
          title="a"
          src={previewPdf}
          style={{ width: 500, height: 500 }}
          frameborder="0"
        />
      )}
    </Modal>
  );
};
export default PreviewImageOrPDF;
