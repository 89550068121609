import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CATEGORY } from "../../../../constants/constants";
import SecondTab from "../secondtab/secondtab";
import ThirdTab from "../thirdtab/thirdtab";
import MainApplicantSummary from "./MainApplicantSummary";
const Mainapplicantform = () => {
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  const [isMemberOrDirector, setIsMemberOrDirector] = useState(false);
  const [isProprietor, setIsProprietor] = useState(false);
  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  useEffect(() => {
    if (
      caseDetail && caseDetail.fk_category_id 
      // &&
      // caseDetail.main_applicant &&
      // caseDetail.main_applicant.fk_category_id 
    ) {
      if (
        caseDetail.fk_category_id == CATEGORY.CORPORATE || caseDetail.fk_category_id == CATEGORY.HUF
      ) {
        setIsMemberOrDirector(true);
        // setMemberOrDirectors(caseDetail.main_applicant.member_director);
      }else if(caseDetail.fk_category_id == CATEGORY.PROPRIETOR){
        setIsProprietor(true);
      }else{
        setIsMemberOrDirector(false);
        setIsProprietor(false)
      }
      // if((caseDetail.main_applicant.fk_category_id.toString() === CATEGORY.CORPORATE ||
      // caseDetail.main_applicant.fk_category_id.toString() === CATEGORY.HUF)){
      //   setIsMemberOrDirector(true);
      //   setMemberOrDirectors(caseDetail.main_applicant.member_director);
      // }else if(caseDetail.main_applicant.fk_category_id.toString() === CATEGORY.PROPRIETOR){
      //   setIsProprietor(true);
      // }
      
    }
  }, [caseDetail,selectedApplicant]);

  return <>{(isMemberOrDirector||isProprietor) ? <SecondTab /> : <ThirdTab />}</>;
};
export default Mainapplicantform;
