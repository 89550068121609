import { RUN_KYC } from "../constants/ActionTypes";

/**
 * @method onRunKyc
 * @description kyc of any participant,dealer or member
 * @param {* } onSuccess
 * @returns
 */
export const onRunKyc = (data,onSuccess)=>({
    type:RUN_KYC,
    data,
    onSuccess,
  })