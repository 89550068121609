import moment from "moment";
import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckIcon from "../../../../../assets/images/completedcheck.png";
import InvalidIcon from "../../../../../assets/images/invalid.png";
import { OCR_DOCUMENT_LABEL } from "../../../../../constants/constants";
const InformationValidity = (props) => {
  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  const [selectedUser, setSelectedUser] = useState({});
  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  const secondTabKey = useSelector((state) => state.tabReducer.secondTabKey);
  const selectedMemberDirector = useSelector( (state) => state.auth.selectedMemberDirector);

  
  useEffect(() => {
    //
    if (secondTabKey === "1") {
      setSelectedUser(selectedApplicant);
    } else {
      setSelectedUser(selectedMemberDirector);
    }
  }, [ selectedMemberDirector, selectedApplicant,secondTabKey]);
  return (
    <React.Fragment>
      <div className="infoWrap">
        <h4>Information Validity</h4>
        <div className="TableWrap">
          <table>
            <thead>
              <tr>
                <th>Document Detail</th>
                <th>Valid and Active</th>
              </tr>
            </thead>
            <tbody>
              {kycResult && Array.isArray(kycResult) && kycResult.map((kyc_doc) => (
                <tr>
                  <td>{kyc_doc.document_name}</td>
                  <td>
                    {kyc_doc.valid_and_active}{" "}
                    {/*{kyc_doc.document_name === OCR_DOCUMENT_LABEL.PASSPORT &&
                      passportExpiryDate} {

                      }*/}
                  </td>
                </tr>
              ))}{" "}
            </tbody>
          </table>
        </div>
      </div>
    </React.Fragment>
  );
};
export default InformationValidity;
