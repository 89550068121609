import { fileNameFromUrl, fileTypeFromName } from "../../../helpers/utils";
import { onPreviewImageOrPdfVisibility, onSetPreviewImage, onSetPreviewPdf ,onSetPreviewLoader} from "../../../redux/actions/ModalAction";

export const handlePreviewNonOcrDoc =  (document,dispatch) => {

  
  dispatch(onPreviewImageOrPdfVisibility(false));
  dispatch(onSetPreviewImage(false));
  dispatch(onSetPreviewPdf(false))
  if (document && document.doc && document.doc.file) {
    var reader = new FileReader();
    reader.readAsDataURL(document.doc.file);
    reader.onloadend = function (e) {
      if (document.doc.file.type === "application/pdf") {
        dispatch(onSetPreviewPdf(reader.result))
      } else {
        dispatch(onSetPreviewImage(reader.result));
      }
    };
  } else {
    const fileName = fileNameFromUrl(document.doc);
    const fileType = fileTypeFromName(fileName);
    if (fileType === "pdf") {
      dispatch(onSetPreviewPdf(document.doc))
    } else {
      dispatch(onSetPreviewImage(document.doc));
    }
  }
  dispatch(onPreviewImageOrPdfVisibility(true));
};
export const handlePreviewOcrDoc = (file,dispatch)=>{
  
  
  dispatch(onSetPreviewImage(false));
  dispatch(onSetPreviewPdf(false))
  dispatch(onPreviewImageOrPdfVisibility(false));
  if (!file.url && !file.preview) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e){
      
    }
    reader.onloadend = function (e) {
      if (file.type === "application/pdf") {
        dispatch(onSetPreviewPdf(reader.result))
      } else {
        dispatch(onSetPreviewImage(reader.result));
      }
    };
  } else {
    if (file.fileType === "pdf") {
      dispatch(onSetPreviewPdf(file.url))
    } else {
      
      dispatch(onSetPreviewImage(file.url || file.preview));
    }
  }
  dispatch(onPreviewImageOrPdfVisibility(true));
}
export const closePreviewModal = (dispatch)=>{
  dispatch(onSetPreviewImage(false));
  dispatch(onSetPreviewPdf(false));
    dispatch(onPreviewImageOrPdfVisibility(false));
}