import React, { Component } from "react";
import { Card, Row, Col } from "antd";

class DealerInfo extends Component {
  render() {
    return (
      <>
        <div className="CardDetailWrap">
          <h3>Dealer Information </h3>
          <Card>
            <Row>
              {/* <Col span={12} xs={24} sm={12}></Col>
              <Col span={12} xs={24} sm={12}>
                <h4 className="text-center">Address</h4>
              </Col> */}

              <Col span={12} xs={24} sm={12}>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Dealer Name</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>Tanvish Kalra</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Dealer Code</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>A24</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Mobile No</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>12467989</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>PAN No.</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>12467989</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Date of Birth</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>1/1/1999</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>City</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>Indore</p>
                  </Col>
                </Row>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Address Line 1</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>Plot no 47, PU4 commercials</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Address Line 2</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>Scheme no 54</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Address Line 3</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>Some text</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Landmark</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>Behind C21 mall</p>
                  </Col>
                </Row>
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>Postal Code</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>450201</p>
                  </Col>
                </Row>
              
                <Row>
                  <Col span={12} xs={24} sm={12}>
                    <h4>State</h4>
                  </Col>
                  <Col span={12} xs={24} sm={12}>
                    <p>MP</p>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </div>
      </>
    );
  }
}
export default DealerInfo;
