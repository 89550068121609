import { DELETE_COAPPLICANT, UPDATE_CO_APPLICANT ,DROP_COAPPLICANT} from "../constants/ActionTypes";
 /**
 * @method onDeleteCoapplicant
 * @description delete a co-applicant
 * @param {*}
 * @returns
 */
export const onDeleteCoapplicant = (coapplicantId,onSuccess) => {
    return {
      type: DELETE_COAPPLICANT,
      id:coapplicantId,
      onSuccess,
    };
  };
 /**
 * @method onUpdateCoApplicant
 * @description update a co-applicant
 * @param {*}
 * @returns
 */
  export const onUpdateCoApplicant = (data,onSuccess) => {
    return {
      type: UPDATE_CO_APPLICANT,
      data,
      onSuccess
    };
  };
