import { ADD_NEW_CASE, CASES, CASE_DETAIL, 
  ON_GOING_CASES, REMOVE_NEW_CASE, 
  RESET_NEW_CASE, UPDATE_NEW_CASE_2,
  ADD_REQUEST_REVIEW,GET_REQUEST_COMMENTS,
  ADD_COMMENT_BY_CREDIT,GET_JUSTIFICATION_COMMENTS,
  SALES_ON_GOING_CASES,ADD_JUSTIFICATION_COMMENTS,
  RESET_CASE_DETAIL,
  TO_RESET_NEW_CASE,GET_USER_OCR_RESULT,
  GET_USER_BY_STATE_CITY,CASE_REALLOCATION,
  CASE_REJECT_APPROVE,
  CLOSED_CASES,SYSTEM_RECOMMENDATION,
  GET_CASE_DETAIL_EXCEL
 } from "../constants/ActionTypes";

/**
 * @method onGetLoggedStatusCases
 * @description get cases of logged in user
 * @param {*} onSuccess
 * @returns
 */
 export const onGetLoggedStatusCases = (data,onSuccess) => ({
    type: CASES,
    payload:data,
    onSuccess,
  });
  /**
 * @method onGetInProgessStatusCases
 * @description get cases of logged in user
 * @param {*} onSuccess
 * @returns
 */
 export const onGetInProgessStatusCases = (data,onSuccess) => ({
    type: ON_GOING_CASES,
    payload:data,
    onSuccess,
  });
    /**
 * @method onGetClosedCases
 * @description get closed cases approved & reject
 * @param {*} onSuccess
 * @returns
 */
 export const onGetClosedCases = (data,data2={}) => ({
  type: CLOSED_CASES,
  payload:data,
});
  /**
 * @method onCaseDetail
 * @description get detail of a case by its id
 * @param {*caseId} onSuccess
 * @returns
 */
export const onCaseDetail = (data, onSuccess,onError) => {
  
    return {
      type: CASE_DETAIL,
      payload: data,
      onSuccess,
      onError,
    };
  };
  export const onResetCaseDetail = (data, onSuccess,onError) => {
  
    return {
      type: RESET_CASE_DETAIL,
    };
  };
/**
 * @method onAddNewCase
 * @description log new case
 * @param {*fk_category_id,fk_subcategory_id,fk_business_area_id,fk_product_id,fk_product_model_id,finance_type,finance_amount,qty_required} onSuccess
 * @returns
 */
 export const onAddNewCase = (data,onSuccess,isSaveAndNextClicked) => ({
    type: ADD_NEW_CASE,
    payload: data,
    onSuccess,
    isSaveAndNextClicked,
  });
/**
 * @method onUpdateNewCreatedCase
 * @description update case detail (volvo can update all parameters and eicher can update loan_amount and qty)
 * @param {*fk_category_id,fk_subcategory_id,fk_business_area_id,fk_product_id,fk_product_model_id,finance_type,finance_amount,qty_required} onSuccess
 * @returns
 */
  export const onUpdateNewCreatedCase = (data,onSuccess)=>({
    // type:UPDATE_NEW_CASE,
    type:UPDATE_NEW_CASE_2,
    data,
    onSuccess,
  });
/**
 * @method onResetNewCaseCreated
 * @description no api call only reset from reducer
 * @param {*fk_category_id,fk_subcategory_id,fk_business_area_id,fk_product_id,fk_product_model_id,finance_type,finance_amount,qty_required} onSuccess
 * @returns
 */
  export const onResetNewCaseCreated = (onSuccess) => {
    return {
      type: RESET_NEW_CASE,
      onSuccess
    };
  };
/**
 * @method onLogNewCaseClicked
 * @description no api call only discard case from reducer
 * @param {*fk_category_id,fk_subcategory_id,fk_business_area_id,fk_product_id,fk_product_model_id,finance_type,finance_amount,qty_required} onSuccess
 * @returns
 */
  export const onLogNewCaseClicked = () => ({
    type: REMOVE_NEW_CASE,
  });



  /**
 * @method onRequestReview
 * @description Request credit for review
 * @param {*fk_case_id,comment} onSuccess
 * @returns
 */
 export const onRequestReview = (data,onSuccess,onError) => ({
  type: ADD_REQUEST_REVIEW,
  payload: data,
  onSuccess,
  onError,
});

  /**
 * @method onFetchRequestComments
 * @description Request and Comments 
 * @param {*case_id} onSuccess
 * @returns
 */
   export const onFetchRequestComments = (data,onSuccess) => ({
    type: GET_REQUEST_COMMENTS,
    payload: data,
    onSuccess
  });
   /**
 * @method onCommentbyCredit
 * @description comment by credit
 * @param {*fk_case_id,comment} onSuccess
 * @returns
 */
    export const onCommentbyCredit = (data,onSuccessreturn,onErrorReturn) => ({
      type: ADD_COMMENT_BY_CREDIT,
      payload: data,
      onSuccessreturn,
      onErrorReturn,
    });

      /**
 * @method onJustificationComment
 * @description Ask for justification
 * @param {*ffk_case_id,fk_applicant_id,comment,attachment} onSuccess
 * @returns
 */
    export const onJustificationComment = (data,onSuccess,onError) => ({
    type: ADD_JUSTIFICATION_COMMENTS,
    payload: data,
    onSuccess,
    onError
  });
  
    
    /**
 * @method onFetchJustificationComment
 * @description Justification Comments 
 * @param {*case_id} onSuccess
 * @returns
 */
     export const onFetchJustificationComment = (data) => ({
      type: GET_JUSTIFICATION_COMMENTS,
      payload: data
    });
    
  export const onGetAllStatusCases = (data,onSuccess)=>({
    type:SALES_ON_GOING_CASES,
    payload:data,
  })
  

  /**
 * @method onResetNewCaseCreated
 * @description no api call only reset from reducer
 * @param {*fk_category_id,fk_subcategory_id,fk_business_area_id,fk_product_id,fk_product_model_id,finance_type,finance_amount,qty_required} onSuccess
 * @returns
 */
   export const onSetResetNewCaseCreated = (data) => {
    return {
      type: TO_RESET_NEW_CASE,
      data
    };
  };

    /**
 * @method onGetUsercrResult
 * @description api call to get selected user's OCR details
 * @param {*applicant_type,applicant_coapplicant_guarantor_id,member_id (it is optional)} onSuccess
 * @returns
 */
     export const onGetUsercrResult = (data) => {
      return {
        type: GET_USER_OCR_RESULT,
        payload:data
      };
    };

   /**
 * @method onCaseReallocation
 * @description api call for case reallocation
 * @param {*case_id,user_id} onSuccess
 * @returns
 */
    export const onCaseReallocation = (data,onSuccessApi) => {
      return {
        type: CASE_REALLOCATION,
        data:data,
        onSuccessApi
      };
    };

  /**
 * @method onGetUserByStateCity
 * @description api call to get user list by state city
 * @param {*stateId, cityId} onSuccess
 * @returns
 */
  export const onGetUserByStateCity = (onSuccessUsers) => {
  return {
    type: GET_USER_BY_STATE_CITY,
    onSuccessUsers
  };
};

/**
 * @method onCaseRejectApprove
 * @description api call to get user list by state city
 * @param {*case_id, main_applicant_id,main_applicant_bureau_report_id,co_applicant_id,
 * co_applicant_bureau_report_id,gurantor_id,gurantor_bureau_report_id,reason,is_approved} onSuccess
 * @returns
 */
  export const onCaseRejectApprove = (data,onSuccess) => {
    return {
      type: CASE_REJECT_APPROVE,
      data,
      onSuccess
    };
  };
  
  /**
 * @method onViewRecommendation
 * @description api call to get system recommendation
 * @param {*case_id, bureau_report_ids} onSuccess
 * @returns
 */
   export const onViewRecommendation = (data,onSuccessCheck) => {
    return {
      type: SYSTEM_RECOMMENDATION,
      data,
      onSuccessCheck
    };
  };
  
  export const onGetCaseDetailExcel = (data,onSuccess)=>{
    return {
      type:GET_CASE_DETAIL_EXCEL,
      data,
      onSuccess
    }
  }