import React, { useCallback, useEffect, useState } from "react";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import { Col, Tabs, Row, Form, Select, Button, Modal, Input } from "antd";
import { connect, useDispatch } from "react-redux";
// import {
//   onAddNewCase,
//   onCaseDetail,
//   onCaseMasters,
//   onChangeLogNewCaseActiveParticipantTab,
// } from "../redux/actions/AuthAction";
import { MESSAGES, REQUIRED_MESSAGES } from "../constants/messages";
import { REGEX } from "../constants/regex";
import AddParticipant from "../components/lognewcase/participant";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import AddParticipants from "../components/participants/AddParticipants";
import {firstCharacterOfEachWordUpperCase} from '../helpers/utils' 
import { onAddNewCase } from "../redux/actions/CaseAction";
import { onChangeLogNewCaseActiveParticipantTab } from "../redux/actions/TabAction";
import { onCaseMasters } from "../redux/actions/MasterData";
const { TabPane } = Tabs;
const layout = {
  labelCol: { span: 12, sm: 24 },
  wrapperCol: { span: 12, sm: 24 },
};
const { Option } = Select;
const LogNewCases = (props) => {
  const dispatch = useDispatch();
  const {
    caseMasters = [],
    isNewlyCreatedCaseSaved,
    newlyCreatedCase,
    logNewCaseActiveKey,
    currentCase
  } = props;
  
  const [isNext, setIsNext] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [productModals, setProductModals] = useState([]);
  const [products, setProducts] = useState([]);
  const [form] = Form.useForm();
  useEffect(() => {
    dispatch(onCaseMasters((caseMasters) => {}));
  }, [dispatch]);
  const changeSubCategories = useCallback(
    (val, cb) => {
      if (
        caseMasters.subcategories &&
        Array.isArray(caseMasters.subcategories) &&
        caseMasters.subcategories.length > 0
      ) {
        const filteredSubCategories = caseMasters.subcategories.filter(
          (subCategory) => {
            return subCategory.fk_category_id.toString() === val.toString();
          }
        );
        cb(filteredSubCategories);
      }
    },
    [caseMasters.subcategories]
  );
  const changeProductNames = useCallback(
    (val) => {
      if (
        caseMasters.products &&
        Array.isArray(caseMasters.products) &&
        caseMasters.products.length > 0
      ) {
        const filteredProducts = caseMasters.products.filter((product) => {
          return product.fk_business_area_id === val;
        });
        setProducts(filteredProducts);
      }
    },
    [caseMasters.products]
  );
  const changeProductModals = useCallback(
    (val) => {
      if (
        caseMasters.product_models &&
        Array.isArray(caseMasters.product_models) &&
        caseMasters.product_models.length > 0
      ) {
        const filteredProductModels = caseMasters.product_models.filter(
          (product) => {
            return product.fK_product_id === val;
          }
        );
        setProductModals(filteredProductModels);
      }
    },
    [caseMasters.product_models]
  );
  useEffect(() => {
    if (
      caseMasters.categories &&
      Array.isArray(caseMasters.categories) &&
      caseMasters.categories.length > 0
    ) {
      if (!isNewlyCreatedCaseSaved) {
        form.setFieldsValue({
          application_category: caseMasters.categories[0].id.toString(),
        });
      }
      if (
        caseMasters.subcategories &&
        Array.isArray(caseMasters.subcategories) &&
        caseMasters.subcategories.length > 0
      ) {
        changeSubCategories(
          isNewlyCreatedCaseSaved
            ? newlyCreatedCase.fk_category_id.toString()
            : caseMasters.categories[0].id.toString(),
          (filteredSubCategories) => {
            setSubCategories(filteredSubCategories);
          }
        );
      }
      if (
        caseMasters.business_areas &&
        Array.isArray(caseMasters.business_areas) &&
        caseMasters.business_areas.length > 0
      ) {
        if (
          caseMasters.products &&
          Array.isArray(caseMasters.products) &&
          caseMasters.products.length > 0
        ) {
          if (isNewlyCreatedCaseSaved) {
            changeProductNames(newlyCreatedCase.fk_business_area_id);
            changeProductModals(newlyCreatedCase.fk_product_id);
          } else {
            // changeProductNames(caseMasters.business_areas[0].id);
          }
        }
      }
    }
    if (
      caseMasters.business_areas &&
      Array.isArray(caseMasters.business_areas) &&
      caseMasters.business_areas.length > 0 &&
      !isNewlyCreatedCaseSaved
    ) {
      form.setFieldsValue({
        business_area: caseMasters.business_areas[0].id,
      });
      changeProductNames(caseMasters.business_areas[0].id);
    }
  }, [
    caseMasters.categories,
    caseMasters.business_areas,
    form,
    caseMasters.subcategories,
    caseMasters.products,
    isNewlyCreatedCaseSaved,
    newlyCreatedCase,
    changeSubCategories,
    changeProductNames,
    changeProductModals,
  ]);
  const setInitialValues = useCallback(() => {
    form.setFieldsValue({
      customer_name: newlyCreatedCase.customer_name,
      application_category: newlyCreatedCase.fk_category_id.toString(),
      application_sub_category:
        newlyCreatedCase.fk_subcategory_id !== 0
          ? newlyCreatedCase.fk_subcategory_id.toString()
          : undefined,
      business_area: newlyCreatedCase.fk_business_area_id,
      product_name: newlyCreatedCase.fk_product_id,
      product_model: newlyCreatedCase.fk_product_model_id,
      finance_type: newlyCreatedCase.finance_type,
      loan_amount: newlyCreatedCase.finance_amount,
      quantities: newlyCreatedCase.qty_required,
    });
  }, [form, newlyCreatedCase]);
  useEffect(() => {
    if (isNewlyCreatedCaseSaved) {
      setInitialValues();
    } else {
      form.resetFields();
    }
  }, [isNewlyCreatedCaseSaved, setInitialValues, form]);
  const onSuccess = () => {
    dispatch(onChangeLogNewCaseActiveParticipantTab("1"))
    if (!isNext) {
      return props.history.push("/new-cases");
    }
    if (isEditMode) {
      setEditMode(false);
    }
  };
  const saveCase = (values) => {
    
    let data = {
      customer_name: values.customer_name,
      fk_category_id: values.application_category,
      fk_subcategory_id: values.application_sub_category
        ? values.application_sub_category
        : "",
      fk_business_area_id: values.business_area,
      fk_product_id: values.product_name,
      fk_product_model_id: values.product_model,
      finance_type: values.finance_type,
      finance_amount: values.loan_amount.replaceAll(",", "").replace("&#8377","").replace(/[^\x00-\x7F]/g,""),
      qty_required: values.quantities.replaceAll(",", ""),
    };
    if (isNewlyCreatedCaseSaved) {
      data = {
        ...data,
        id: newlyCreatedCase.id,
      };
    }
    dispatch(onAddNewCase(data, onSuccess, isNext));
    // setCaseSaved(true);
  };
  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "application_category") {
      form.setFieldsValue({ application_sub_category: undefined });
    } else if (formFieldName === "business_area") {
      form.setFieldsValue({ product_name: undefined });
      form.setFieldsValue({ product_model: undefined });
    } else if (formFieldName === "product_name") {
      form.setFieldsValue({ product_model: undefined });
    }
  };
  const GetBusinessAreas = () => {
    let business_areas = [];
    business_areas = caseMasters.business_areas
      ? caseMasters.business_areas
      : [];
    let isBusinessAreas = Array.isArray(business_areas)
      ? business_areas.length > 0
      : false;
    return (
      <Form.Item
        label="Business Area"
        name="business_area"
        rules={[
          {
            required: true,
            message: REQUIRED_MESSAGES.BUSINESS_AREA_REQUIRED,
          },
        ]}
      >
        <Select
          onSelect={(val) => {
            changeProductNames(val);
            setProductModals([]);
            form.setFieldsValue({ product_model: undefined });
          }}
          disabled={isNewlyCreatedCaseSaved && !isEditMode}
          placeholder='Please Select Business Area'
        >
          {isBusinessAreas &&
            business_areas.map((business_area) => (
              <Option value={business_area.id} key={business_area.id}>
                {business_area.business_area}({business_area.brand})
              </Option>
            ))}
        </Select>
      </Form.Item>
    );
  };
  const changeActiveKey = (key,isOk) => {
    if (key === "1") {
      dispatch(onChangeLogNewCaseActiveParticipantTab(key));
    } else if (key === "2") {
      if (
        (newlyCreatedCase.main_applicant &&
        newlyCreatedCase.main_applicant.ocr_documents &&
        Array.isArray(newlyCreatedCase.main_applicant.ocr_documents) &&
        newlyCreatedCase.main_applicant.ocr_documents.length > 0) || isOk
      ) {
        dispatch(onChangeLogNewCaseActiveParticipantTab(key));
      } else {
        toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_MAIN_APPLICANT);
      }
    } else if (key === "3") {
      if (
        (newlyCreatedCase.main_applicant &&
        newlyCreatedCase.main_applicant.ocr_documents &&
        Array.isArray(newlyCreatedCase.main_applicant.ocr_documents) &&
        newlyCreatedCase.main_applicant.ocr_documents.length > 0)
      ) {
        if (
          (newlyCreatedCase.co_applicants &&
          Array.isArray(newlyCreatedCase.co_applicants) &&
          newlyCreatedCase.co_applicants.length > 0) || isOk
        ) {
          dispatch(onChangeLogNewCaseActiveParticipantTab(key));
        } else {
          toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_ATLEASE_ONE_CO_APPLICANT);
        }
      } else {
        toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_MAIN_APPLICANT);
      }
    }
  };
  return (
    <React.Fragment>
      <div className="LogNewCasesWrap">
        <TopBarNav />
        <Navigation />
        <div className="Selector">
          <Form
            form={form}
            onFinish={saveCase}
            {...layout}
            name="category"
            onValuesChange={handleFormValuesChange}
          >
            <div className="HeaderHighlight">
              <h4>
                {isNewlyCreatedCaseSaved
                  ? `Case ${newlyCreatedCase.id}`
                  : "Add New Case"}
              </h4>
              <div className="ButtonWrap">
                {!isEditMode && !isNewlyCreatedCaseSaved && (
                  <Button
                    onClick={() => {
                      props.history.goBack();
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {!isNewlyCreatedCaseSaved && (
                  <React.Fragment>
                    {/*<Button type="primary" htmlType="submit">
                      Save
                </Button>*/}
                    <Button
                      type="primary"
                      htmlType="submit"
                      onClick={() => setIsNext(true)}
                    >
                      Save and Next
                    </Button>
                  </React.Fragment>
                )}
                {isNewlyCreatedCaseSaved && (
                  <React.Fragment>
                    {" "}
                    {isEditMode && (
                      <React.Fragment>
                        <Button
                          onClick={() => {
                            setInitialValues();
                            setEditMode(false);
                          }}
                        >
                          Cancel
                        </Button>
                        {/*<Button
                          htmlType="submit"
                          onClick={() => setIsNext(false)}
                        >
                          Update
                        </Button>*/}
                        <Button
                          htmlType="submit"
                          onClick={() => setIsNext(true)}
                        >
                          Update and Next
                        </Button>
                      </React.Fragment>
                    )}
                    {!isEditMode && (
                      <React.Fragment>
                        {" "}
                        <Button
                          onClick={() => {
                            setEditMode(!isEditMode);
                          }}
                        >
                          Edit
                        </Button>
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
            <Row gutter={32}>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Customer's Name"
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.CUSTOMER_NAME_REQUIRED,
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Customer Name"
                    disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    onChange = {(e)=>{
                      const input = e.target.value;
                      if(!(REGEX.NAME_VALIDATION).test(input)){
                        form.setFieldsValue({
                          // 'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1))).replace( /[^a-zA-Z0-9 ]/gm, '')
                          'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1)))
                        })
                      }else{
                        form.setFieldsValue({
                          'customer_name':firstCharacterOfEachWordUpperCase(input)
                        })
                      }
                    }}
                  />
                </Form.Item>
              </Col>

              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Application Category"
                  name="application_category"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.APPLICATION_CATEGORY_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    // defaultValue={form.getFieldValue("application_category") }
                    onSelect={(val) => {
                      changeSubCategories(val, (filteredSubCategories) => {
                        
                        
                        setSubCategories(filteredSubCategories);
                      });
                      form.setFields([
                        {
                          name:'application_sub_category',
                          errors:[]
                        }
                      ])
                    }}
                    disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    placeholder={"Please Select Application Category"}
                  >
                    {caseMasters.categories.map((category, index) => (
                      <Option value={category.id.toString()} key={category.id}>
                        {category.category_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Sub Category"
                  name="application_sub_category"
                  shouldUpdate={(prevValues, currValues) => {
                    return (
                      currValues.application_category !=
                      prevValues.application_category
                    );
                  }}
                  rules={[
                    {
                      required: subCategories.length !== 0,
                      message: REQUIRED_MESSAGES.APPLICATION_SUB_CATEGORY_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder={"Please Select Sub Category"}
                    disabled={
                      subCategories.length === 0 ||
                      (isNewlyCreatedCaseSaved && !isEditMode)
                    }
                  >
                    {subCategories.map((subCategory) => (
                      <Option value={subCategory.id.toString()} key={subCategory.id}>
                        {subCategory.subcategory_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <React.Fragment>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Loan/Lease Amount Required"
                    name="loan_amount"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.LOAN_AMOUNT_REQUIRED,
                      },
                    ]}
                    // extra="Note: Maximum loan amount is &#8377;10,000,000,000"
                  >
                    <NumberFormat
                      placeholder="Loan Amount"
                      class='ant-input'
                      name="Loan Amount"
                      prefix="&#8377;"
                      isAllowed={(values) => {
                        const { formattedValue, floatValue, value } = values;
                        if (value.startsWith("0")) {
                          return false;
                        }
                        return formattedValue === "" || floatValue <= 10000000000;
                      }}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator={true}
                      decimalScale={0}
                      className="ant-input"
                      disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    />
                    {/*<Input disabled={isNewlyCreatedCaseSaved && !isEditMode} />*/}
                  </Form.Item>
                </Col>
                <Col span={6} md={6} sm={12} xs={24}>
                  {GetBusinessAreas()}
                </Col>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Product Name"
                    name="product_name"
                    onClick={() => {
                      
                      if (!form.getFieldValue("product_name")) {
                        form.setFields([
                          {
                            name: "product_name",
                            errors: [],
                          },
                        ]);
                      }
                    }}
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.PRODUCT_NAME_REQUIRED,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please Select Product Name"
                      disabled={isNewlyCreatedCaseSaved && !isEditMode}
                      onSelect={changeProductModals}
                    >
                      {products.map((product) => (
                        <Option value={product.id} key={product.id}>
                          {product.product_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Product Model No."
                    name="product_model"
                    rules={[
                      {
                        required: true,
                        message:
                          REQUIRED_MESSAGES.PRODUCT_MODAL_NUMBER_REQUIRED,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Please Select Modal No"
                      onClick={() => {
                        if (!form.getFieldValue("product_name")) {
                          form.setFields([
                            {
                              name: "product_name",
                              errors: ["Please select product segment first"],
                            },
                          ]);
                        }
                      }}
                      disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    >
                      {productModals.map((productModal) => (
                        <Option value={productModal.id} key={productModal.id}>
                          {productModal.model_no}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Quantity Required"
                    name="quantities"
                    // extra={"Note: Maximum quantity is 10,000"}
                    disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.QUANTITY_REQUIRED,
                      },
                      // {pattern: new RegExp("^([1-9][0-9]{0,2}|10000)$"),message:'only numbers allowed'}
                    ]}
                  >
                  <NumberFormat
                      placeholder="Quantity Required"
                      name="quantities"
                      class='ant-input'
                      isAllowed={(values) => {
                        
                        const { formattedValue, floatValue, value } = values;
                        if (value.startsWith("0")) {
                          return false;
                        }
                        return formattedValue === "" || floatValue <= 10000;
                      }}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator={true}
                      decimalScale={0}
                      className="ant-input"
                      disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    />
                    {/*<Input disabled={isNewlyCreatedCaseSaved && !isEditMode} />*/}
                  </Form.Item>
                </Col>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Type of financing"
                    disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.FINANCE_TYPE,
                      },
                    ]}
                    name="finance_type"
                  >
                    <Select
                      placeholder="Please Select Finance Type"
                      disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    >
                      <Option value="loan">loan</Option>
                      <Option value="lease">lease</Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/*<Col span={6} md={6} sm={12} xs={24}>
                <Form.Item 
                  label="Phone Number"
                  name='phone_number'
                >
                <NumberFormat 
                  placeholder="Phone Number" 
                  name="telephone"
                  isAllowed={(values) => {
                    const { formattedValue, floatValue } = values;
                    return formattedValue === "" || floatValue <= 100000;
                  }}
                  onError={'Max amount 100000'}
                  thousandSeparator={true}
                  onValueChange={(value)=>{
                    
                }} className='ant-input'/>
                </Form.Item>
              </Col>*/}
              </React.Fragment>
            </Row>
          </Form>
        </div>
        {/*{currentCase && !isEditMode && <AddParticipants />}*/}
        {isNewlyCreatedCaseSaved && !isEditMode && (
          <Tabs activeKey={logNewCaseActiveKey} onChange={changeActiveKey}>
            <TabPane tab="Main Applicant" key="1">
              <AddParticipant
                type="Main Applicant"
                caseId={newlyCreatedCase.id}
                participant={newlyCreatedCase.main_applicant}
                isNewCase={true}
                handleTabChange={changeActiveKey}
              />
            </TabPane>
            <TabPane tab="Co Applicant" key="2">
              <AddParticipant
                type="Co Applicant"
                changeSubCategories={changeSubCategories}
                caseId={newlyCreatedCase.id}
                participant={newlyCreatedCase.co_applicants}
                isNewCase={true}
                handleTabChange={changeActiveKey}
              />
            </TabPane>
            <TabPane tab="Guarantor" key="3">
              <AddParticipant
                type="Guarantor"
                changeSubCategories={changeSubCategories}
                caseId={newlyCreatedCase.id}
                participant={newlyCreatedCase.guarantors}
                isNewCase={true}
                handleTabChange={changeActiveKey}
              />
            </TabPane>
          </Tabs>
        )}
      </div>
      {/* Run KYC */}
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
    mainApplicant: auth.mainApplicant,
    coApplicant: auth.coApplicant,
    gurantor: auth.gurantor,
    isNewlyCreatedCaseSaved: auth.isNewlyCreatedCaseSaved,
    newlyCreatedCase: auth.newlyCreatedCase,
    pageKey: auth.pageKey,
    logNewCaseActiveKey: auth.logNewCaseActiveKey,
    currentCase: auth.currentCase,
  };
};
export default connect(mapStateToProps, null)(LogNewCases);
