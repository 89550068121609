import React from 'react'
import TopBarNav from '../components/topbar';
import Navigation from '../components/navigation';
import FleetAnalysisViewByCase from '../components/viewfleetAnalysis/FleetAnalysisViewByCase';
function FleetAnalysisViewList() {
  return (
    <div>
        <TopBarNav/>
        <Navigation/>
        <FleetAnalysisViewByCase/>
    </div>
  )
}

export default FleetAnalysisViewList
