import React, { Component } from "react";
import { Card, Form, Input, Button, Checkbox, Upload, Select } from "antd";
import uplaodIcon from '../../../../../assets/images/uploadfile.png';
import {Link} from 'react-router-dom';
const { Option } = Select;
const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };
  const onFinish = (values) => {
    
  };
  
  const onFinishFailed = (errorInfo) => {
    
  };
class MainApplicant extends Component {
 
  render() {
      
    return (
        
      <>
        <div className="DocumentFormWrap">
         
          <Form
              {...layout}
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
            >
                 <Form.Item
                label="Application Category"
                name="ApplicantCat"
                className='applicantype ApplicantSelector'
              >
                 <Select defaultValue="Individual">
                    
                    <Option value="Individual">Individual</Option>
                    <Option value="Individual1">Individual1</Option>
                    <Option value="Individual2">Individual</Option>
                    
                </Select>
              </Form.Item>
              <Form.Item
                label="Application Sub Category"
                name="ApplicantSubCat"
                className='applicantype ApplicantSelector'
              >
                 <Select defaultValue="Individual">
                    
                    <Option value="Individual">Individual</Option>
                    <Option value="Individual1">Individual1</Option>
                    <Option value="Individual2">Individual</Option>
                    
                </Select>
              </Form.Item>
               <h4 className='heading'>Upload Scanned Copy / Photo Of The Following Documents</h4>
          <Card title="OCR Compatible Documents">
       
              <Form.Item
                label="PAN Card"
                name="pan"
              >
                 <Upload
                  maxCount={1}
                >
                  <Button>Upload  <img src={uplaodIcon} alt='uplaodIcon'/></Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Aadhar Card"
                name="Aadhar"
              >
                 <Upload
                  maxCount={1}
                >
                  <Button>Upload  <img src={uplaodIcon} alt='uplaodIcon'/></Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Voter ID"
                name="VoterID"
              >
                 <Upload
                  maxCount={1}
                >
                  <Button>Upload  <img src={uplaodIcon} alt='uplaodIcon'/></Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Driving License"
                name="DL"
              >
                 <Upload
                  maxCount={1}
                >
                  <Button>Upload  <img src={uplaodIcon} alt='uplaodIcon'/></Button>
                </Upload>
              </Form.Item>
              
              <Form.Item
                label="Passport"
                name="Passport"
              >
                 <Upload
                  maxCount={1}
                >
                  <Button>Upload  <img src={uplaodIcon} alt='uplaodIcon'/></Button>
                </Upload>
              </Form.Item>

             
          
          </Card>
          <Card title="OCR Non Compatible Documents">
         
              <Form.Item
                label="Customer Consent"
                name="CustomerConsent"
              >
                 <Upload
                  maxCount={1}
                >
                  <Button>Upload  <img src={uplaodIcon} alt='uplaodIcon'/></Button>
                </Upload>
              </Form.Item>

              <Form.Item
                label="Aadhar Card"
                name="AadharCard"
              >
                 <Upload
                  maxCount={1}
                >
                  <Button>Upload  <img src={uplaodIcon} alt='uplaodIcon'/></Button>
                </Upload>
              </Form.Item>
              <div className='btnWrap'>
                <Button>Add</Button>
              </div>
              <table>
                <thead>
                  <tr>
                    <th>Document Name </th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Doc 1</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Doc 2</td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
          </Card>
          {/* <div className='MainBtnWrap'>
            <Button>Canel</Button>
            <Button>Next</Button>
            <Link to="/OnGoingCases">Save and run OCR </Link>
          </div> */}
          </Form>
        </div>
      </>
    );
  }
}
export default MainApplicant;
