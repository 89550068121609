import { MESSAGES } from "../constants/messages";
import { toast } from "react-toastify";
import moment from "moment";
import { GROUP_NAME, STAGES, STATUS, USER_TYPE } from "../constants/constants";
export const errorMessage = (error, message = MESSAGES.DefaultError) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    const responseError = error.response.data.message.error
      ? error.response.data.message.error
      : error.response.data.message;
    if (Array.isArray(responseError)) {
      responseError.forEach((errorMessage) => {
        toast.error(errorMessage);
      });
    } else {
      toast.error(responseError);
    }
  } else {
    if (error && error.toJSON().message === "Network Error") {
      toast.error(MESSAGES.INTERNET_DISCONNECTED_ERROR);
    } else if (error.response && error.response.status === 401) {
      localStorage.removeItem("token");
      // toast.error("Please login!");
    } else {
      toast.error(message);
    }
  }
};
export const successMessage = (message = MESSAGES.DefaultSuccess) => {
  toast.success(message);
};
export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
export const calculateAgeFromDob = (string) => {
  const today = new Date();
  const birthDate = new Date(string);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};
export const isLoggedIn = () => {
  const token = localStorage.getItem("token");
  if (token && token !== null && token !== "") {
    return true;
  }
  return false;
};

export const loggedInUserData = () => {
  const userData = localStorage.getItem("userInfo");
  if (userData && userData !== null && userData !== "") {
    return JSON.parse(userData);
  }
  return "";
};
export const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};
export const toDataUrl = (url, callback) => {
  var xhr = new XMLHttpRequest();
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open("GET", url);
  xhr.responseType = "blob";
  xhr.send();
};

export const base64ToExcel = (base64Data, fileName) => {
  try {
    const encodedInvoice = base64Data.split(';base64,').pop();
    console.log(encodedInvoice);
    var binary = window.atob(encodedInvoice);
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.style.display = "none";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error converting and downloading Excel:", error);
  }
};

export const firstCharacterOfEachWordUpperCase = (str) => {
  var splitStr = str.split(" ");
  for (var i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(" ");
};
export const disabledFutureDates = (current) => {
  //For Example DOB
  return current && current > moment().endOf("day");
};
export const myFunction = (file) => {
  var reader = new FileReader();
  // it's onload event and you forgot (parameters)
  reader.onload = function (e) {
    var image = document.createElement("img");
    // the result image data
    return e.target.result;
    // document.body.appendChild(image);
  };
  // you have to declare the file loading
  reader.readAsDataURL(file);
};
export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
export const numberWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const checkFileTypeImageAndPdf = (file) => {
  if (
    file.type === "image/png" ||
    file.type === "image/jpg" ||
    file.type === "image/jpeg" ||
    file.type === "application/pdf"
  ) {
    return true;
  } else {
    toast.error(`Only .png , .jpg , .jpeg and .pdf type files allowed`);
  }
};
export const checkFileSizeOneMB = (file,size) => {
  const isLt2M = file.size / 1024 / 1024 <= size;
  if (!isLt2M) {
    toast.error(`File size should not exceeds ${size} MB`);
    return false;
  }
  return true;
};
export const fileNameFromUrl = (url) => {
  let fileName = url.split("/").pop();
  // let fileName = 'sahjahs_jksjka_90'
  let index = fileName.indexOf("_");
  let isUnderScoreExists = index !== -1;
  if (isUnderScoreExists) {
    fileName = fileName.substr(fileName.indexOf("_") + 1);
  }
  return fileName;
};
export const fileTypeFromName = (fileName) => {
  var re = /(?:\.([^.]+))?$/;
  return re.exec(fileName)[1];
};
export const checkLoanAmountAllowed = (values) => {
  const { formattedValue, floatValue, value } = values;
  if (value.startsWith("0")) {
    return false;
  }
  if (formattedValue === "" || floatValue <= 10000000000) {
    return true;
  }
};
export const checkQuantityAllowed = (values) => {
  const { formattedValue, floatValue, value } = values;
  if (value.startsWith("0")) {
    return false;
  }
  return formattedValue === "" || floatValue <= 10000;
};
export const checkLoanDurationAllowed = (values) => {
  const { formattedValue, floatValue, value } = values;
  if (value.startsWith("0")) {
    return false;
  }
  return formattedValue === "" || floatValue <= 84;
};
export const isKycDone = (stage)=>{
  return stage && stage !== STAGES.OCR_PENDING && stage !== STAGES.KYC_PENDING
}
export const getClassNameByStatus = (status)=>{
  if(status){
    if(status === STATUS.APPROVED){
      return 'Approved'
    }else if(status === STATUS.DROPPED){
      return 'Dropped'
    }else{
      return 'Added'
    }
  }else{
    return ''
  }
}
export const isOcrDone = (stage)=>{
  
  return stage && stage !== STAGES.OCR_PENDING 
}
export const isNotDropped = (status)=>{
  
  return status && status !== STATUS.DROPPED 
}
export const isCaseNotClosed = (status)=>{
  return status && status !== STATUS.REJECTED && status !== STATUS.CREDIT_BUREAU_APPROVED
}
export const isCaseClosed = (status)=>{
  return status && (status === STATUS.REJECTED || status === STATUS.CREDIT_BUREAU_APPROVED)
}

export const isCreditReviewPending = (status)=>{
  
  return status && status === STAGES.CREDIT_REVIEW_PENDING 
}

export const isAllowed = (status,userData)=>{
  
  return (
    status &&
    status !== STATUS.APPROVED &&
    status !== STATUS.REJECTED && 
    userData.user_type === USER_TYPE.SALES
  ); 
}
export function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}
export const isSalesUser = (userData) =>
  userData && userData.user_type === USER_TYPE.SALES;
export const isCreditUser = (userData) =>
  userData && userData.user_type === USER_TYPE.CREDIT;
export const isEicherUser = (userData) =>
  userData && userData.group_name === GROUP_NAME.EICHER;
export const isVolvoUser = (userData) =>
  userData && userData.group_name === GROUP_NAME.VOLVO;
