import React from "react";
import { Button, Radio } from "antd";
import { connect, useDispatch } from "react-redux";
import { onChangeSelectedUser } from "../../../redux/actions/AuthAction";
import EditIcon from "../../../assets/images/edit.png";
const CoapplicantList = (props) => {
  const { selectedUser, coApplicants, handleEdit } = props;
  return (
    <>
      <div className="SmallTableWrap SmallTableWrap-v2">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Co Applicant Type</th>
              {/*<th>Name</th>*/}
              {/*<th>Relation with Applicant</th>*/}
              <th>Status</th>
              {/*<th>Stage</th>*/}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {coApplicants.map((co_applicant, index) => (
              <tr key={co_applicant.id}>
                <td>{co_applicant.id}</td>
                <td>{co_applicant.category_name}</td>
                {/*<td>{co_applicant.first_name} {co_applicant.last_name}</td>*/}
                {/*<td>{co_applicant.coapplicant_applicant_relation}</td>*/}
                <td>{co_applicant.status}</td>
                {/*<td>{co_applicant.stage}</td>*/}
                <td
                  onClick={() => {
                    handleEdit(co_applicant);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <Button>
                    <img src={EditIcon} alt="Edit" />
                  </Button>
                </td>
              </tr>
            ))}{" "}
          </tbody>
        </table>
      </div>
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(CoapplicantList);
