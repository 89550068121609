import React, { Component, useEffect, useState } from "react";
import { Collapse } from "antd";
import { OCR_DOCUMENT_LABEL } from "../../../constants/constants";
const { Panel } = Collapse;
const RunOcr = (props) => {
  const { ocrResponse=[]} = props;
  
  const [panCardStatus,setPanCardStatus] = useState('failed');
  const [aadharCardStatus,setAadharCardStatus] = useState('failed');
  const [voterIdStatus,setVoterIdStatus] = useState('failed');
  const [drivingLicenseStatus,setDrivingLicenseStatus] = useState('failed');
  const [passportStatus,setPassportStatus] = useState('failed');
  useEffect(()=>{
    ocrResponse.forEach((ocrRes)=>{
      
      if(ocrRes.message==='Success'){
        ocrRes.message='completed'
      }
      if(ocrRes.type==='PAN Card'){
        setPanCardStatus(ocrRes.message)
      }
      else if(ocrRes.type===OCR_DOCUMENT_LABEL.AADHAR_CARD){
        setAadharCardStatus(ocrRes.message);
      }else if(ocrRes.type===OCR_DOCUMENT_LABEL.DRIVING_LICENSE){
        setDrivingLicenseStatus(ocrRes.message);
      }else if(ocrRes.type===OCR_DOCUMENT_LABEL.PASSPORT){
        setPassportStatus(ocrRes.message);
      }else if(ocrRes.type===OCR_DOCUMENT_LABEL.VOTER_ID){
        setVoterIdStatus(ocrRes.message)
      }
    })
  },[ocrResponse])
  return (
    <>
      <div className="CollapseWrap">
        <h3 className="heading">Reading Documents...</h3>
        <Collapse accordion defaultActiveKey={1}>
          <Panel header="Main Applicant" key="1" className="completed">
            <ul>
              <li className={panCardStatus}>PAN Card</li>
              <li className={aadharCardStatus}>Aadhar Card</li>
              <li className={voterIdStatus}>Voter ID</li>
              <li className={drivingLicenseStatus}>Driving License</li>
              <li className={passportStatus}>Passport</li>
              {/*<li className="completed">Email Authentication</li>
  <li className="loading">Mobile No Authentication</li>*/}
            </ul>
          </Panel>
        </Collapse>
      </div>
    </>
  );
};
export default RunOcr;
