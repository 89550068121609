import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Input,
  Form,
  Upload,
  Button,
  Radio,
  Select,
  Modal,
} from "antd";
import uplaodIcon from "../../assets/images/uploadfile.png";
// import PreviewImageOrPDF from "../../common/modals/PreviewImageOrPDF";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileTypeFromName,
} from "../../helpers/utils";
import { MESSAGES, REQUIRED_MESSAGES } from "../../constants/messages";
const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const ApproveCoapplicantGuarantorModal = (props) => {
  const { isModalVisible, handleCancel, handleOk, isCoapplicantOrGuarantor } =
    props;
  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={handleCancel}
        className="KycCheck"
        onOk={handleOk}
      >
        <p className="confirmationtext">
          Are you sure you want to approve this {isCoapplicantOrGuarantor}?
        </p>
      </Modal>
    </>
  );
};

export default ApproveCoapplicantGuarantorModal;
