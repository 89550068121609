import {
    GET_NOTIFICATIONS_FAILED,
    GET_NOTIFICATIONS_SUCCESS
} from "../constants/ActionTypes";

const initState = {
    notifications: [],
    
}
export default function course(state = initState, action) {
    switch (action.type) {
        case GET_NOTIFICATIONS_SUCCESS:
            return {...state, notifications: action.notifications};
        case GET_NOTIFICATIONS_FAILED:
            return {...state, notifications: []};
        default:
            return state
    }
}