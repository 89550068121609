import {
    CREDIT_PENDING_CASES_SUCCESS,
    CREDIT_PENDING_CASES_FAILED
} from "../constants/ActionTypes";

const initState = {
    pendingCases: []
}
export default function course(state = initState, action) {
    switch (action.type) {
        case CREDIT_PENDING_CASES_SUCCESS:
            return {...state, pendingCases: action.pendingCases};
        case CREDIT_PENDING_CASES_FAILED:
            return {...state, pendingCases: []};
     
        default:
            return state
    }
}