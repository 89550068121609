import React, { Component } from "react";
import TopBarNav from '../components/topbar'
import Navigation from '../components/navigation'
import CaseDetailHeader from '../components/ongoingcaselist/ongoingcasestabs/header'
import CasesTabs from '../components/ongoingcaselist/ongoingcasestabs'
class ViewOngoingCases extends Component {
    render() {
        return (
          <>
            <div className='CaseDetailWrap'>
                <TopBarNav/>
                <Navigation/>
                <CaseDetailHeader/>
                <CasesTabs/>
            </div>
          
          </>
        )
    }
}
export default ViewOngoingCases;