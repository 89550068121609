import React, { Component, useEffect, useState } from "react";
import {  Row,  Form } from "antd";
import { connect } from "react-redux";
import PreviewImageOrPDF from "../../common/modals/PreviewImageOrPDF";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileTypeFromName,
  loggedInUserData
} from "../../../helpers/utils";
import JustificationForm from "./justificationForm";
import SuggestionReviewForm from "./SuggestionReviewForm";
import {onFetchRequestComments,onCommentbyCredit,onFetchJustificationComment,onJustificationComment, onCaseDetail } from "../../../redux/actions/CaseAction";
import {useDispatch, useSelector} from 'react-redux'
import {toast} from 'react-toastify';
import { onChangeFirstTabKey } from "../../../redux/actions/TabAction";
import { onChangeSelectedUser } from "../../../redux/actions/AuthAction";

const Comminucation = (props) => {
  const dispatch = useDispatch();
  const userData = loggedInUserData();
  const { caseDetail } = props;
  const [justifications, setJustifications] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [attachement,setAttachment] = useState(false);
  const requestComments = useSelector(state => state.caseReducer.caseComments);
  const justifComments = useSelector(state => state.caseReducer.justificationComments);
  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    if (caseDetail && caseDetail.id) {
      let id=caseDetail.id;
      dispatch(onFetchRequestComments(id));
      dispatch(onFetchJustificationComment(id));
      setJustifications(
        caseDetail.justification ? caseDetail.justification : []
      );
    }
  }, [caseDetail,dispatch]);

 
  const saveJustificationComments = (data,) => {
    // data.fk_case_id=caseDetail.id;
    dispatch(onJustificationComment(data, onSuccess,onError));
  }; 
  const onSuccess = (data) => {
    form2.resetFields();
    let id=caseDetail.id;
    dispatch(
      onCaseDetail({ caseId: caseDetail.id }, (caseDetail) => {
        dispatch(onChangeSelectedUser(caseDetail.main_applicant))
        dispatch(onFetchJustificationComment(id));
      })
    );
    
  }
  const onError = (data) => {
    toast.error(data.message);
  }
  const saveSuggestion = (data) => {
    dispatch(onCommentbyCredit(data,onSuccessreturn,onErrorReturn));
  };
  const onSuccessreturn = (data) => {
    let id=caseDetail.id;
    
    form.resetFields();
    dispatch(onFetchRequestComments(id,()=>{
      dispatch(onCaseDetail({caseId:id},()=>{
        dispatch(onChangeFirstTabKey('1'))
      }))
    }));
   
  }
  const onErrorReturn = (data) => {
    toast.error(data.message);
  }
  return (
    <React.Fragment>
      <div className="CommunicationformWrap">
        <Row gutter={32}>
          <JustificationForm caseDetail={caseDetail}
          saveJustificationComments={saveJustificationComments}
          justifComments={justifComments}  userType={userData.user_type} 
          form={form2}/>
          <SuggestionReviewForm 
            caseDetail={caseDetail}
            requestComments={requestComments}
            userType={userData.user_type} 
            saveSuggestion={saveSuggestion} 
            form={form}
            />
         
        </Row>
      </div>
      <PreviewImageOrPDF
        previewVisible={previewVisible}
        previewImage={previewImage}
        previewPdf={previewPdf}
        onCancel={() => {
          setPreviewImage(false);
          setPreviewPdf(false);
          setPreviewVisible(false);
        }}
      />
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(Comminucation);
