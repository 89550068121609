import { DELETE_NON_OCR_DOCUMENT, SET_NON_OCR_DOCS, UPDATE_NON_OCR_DOCS, UPLOAD_NON_OCR_DOCUMETS } from "../constants/ActionTypes";
/**
 * @method onDeleteNonOcrDocument
 * @description delete a non ocr doc
 * @param {*}
 * @returns
 */
export const onDeleteNonOcrDocument = (data, onSuccess) => ({
  type: DELETE_NON_OCR_DOCUMENT,
  data,
  onSuccess,
});
/**
 * @method onUploadNonOcrDocuments
 * @description upload non ocr docs
 * @param {*}
 * @returns
 */
export const onUploadNonOcrDocuments = (data, onSuccess) => ({
  type: UPLOAD_NON_OCR_DOCUMETS,
  payload: data,
  onSuccess,
});
/**
 * @method onUpdateNonOcrDocs
 * @description update a non ocr doc
 * @param {*}
 * @returns
 */
export const onUpdateNonOcrDocs = (userType, data) => {
  return {
    type: UPDATE_NON_OCR_DOCS,
    data,
    userType,
  };
};
 /**
 * @method onChangeNonOcrDocs
 * @description no api call
 * @param {*}
 * @returns
 */
  export const onChangeNonOcrDocs = (data)=>{
    return {
      type:SET_NON_OCR_DOCS,
      data,
    }
  }