import React, { Component, useCallback, useEffect, useState } from "react";
import { Alert, Button, Collapse, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getOcrFields } from "../helpers/utils";
import CheckIcon from "../../../assets/images/completedcheck.png";
import InvalidIcon from "../../../assets/images/invalid.png";
import {
  CATEGORY,
  OCR_DOCUMENT_LABEL,
  STAGES,
} from "../../../constants/constants";
import { Link } from "react-router-dom";
import { onUserOcrResult } from "../../../redux/actions/OcrAction";
const { Panel } = Collapse;
const RunOcr = (props) => {
  const ocrResponse = useSelector(
    (state) => state.ocrAndNonOcrReducer.runOcrResponse
  );

  const [panCardStatus, setPanCardStatus] = useState("");
  const [aadharCardStatus, setAadharCardStatus] = useState("");
  const [voterIdStatus, setVoterIdStatus] = useState("");
  const [drivingLicenseStatus, setDrivingLicenseStatus] = useState("");
  const [passportStatus, setPassportStatus] = useState("");
  const [gstRegistrationStatus, setGstRegistrationStatus] = useState("");
  // const [ocrFields, setOcrFields] = useState([]);
  // const [ocrResult, setOcrResult] = useState([]);
  // const dispatch = useDispatch();
  useEffect(() => {
    if (ocrResponse.ocr_success && Array.isArray(ocrResponse.ocr_success)) {
      ocrResponse.ocr_success.forEach((ocrRes) => {
        let status = ocrRes.message === "Success" ? "completed" : "failed";

        // if (ocrRes.message === "Success") {
        //   ocrRes.message = "completed";
        // }else{
        //   ocrRes.message='failed'
        // }

        if (ocrRes.type === "PAN Card") {
          setPanCardStatus(status);
        } else if (ocrRes.type === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
          setAadharCardStatus(status);
        } else if (ocrRes.type === OCR_DOCUMENT_LABEL.DRIVING_LICENSE) {
          setDrivingLicenseStatus(status);
        } else if (ocrRes.type === OCR_DOCUMENT_LABEL.PASSPORT) {
          setPassportStatus(status);
        } else if (ocrRes.type === OCR_DOCUMENT_LABEL.VOTER_ID) {
          setVoterIdStatus(status);
        } else if (ocrRes.type === OCR_DOCUMENT_LABEL.GST_REGISTRATION) {
          setGstRegistrationStatus(status);
        }
      });
    }
  }, [ocrResponse]);
  // const getUserOcrResult = useCallback(() => {
  //   if (ocrResponse && ocrResponse.user_detail) {
  //     let data = {
  //       applicant_type: ocrResponse.applicant_type,
  //       applicant_coapplicant_guarantor_id:
  //         ocrResponse.applicant_coapplicant_guarantor_id,
  //       member_id: ocrResponse.member_director_id
  //         ? ocrResponse.member_director_id
  //         : "",
  //     };
  //     dispatch(
  //       onUserOcrResult(data, (ocr_result) => {
  //
  //         setOcrResult(() => {
  //           return ocr_result;
  //         });
  //       })
  //     );
  //   }
  // }, [dispatch, ocrResponse]);
  // useEffect(() => {
  //   if (ocrResponse && ocrResponse.fk_category_id) {
  //     console.log(
  //       "getOcrFields(ocrResponse.fk_category_id): ",
  //       getOcrFields(ocrResponse.fk_category_id)
  //     );
  //     let fields = getOcrFields(ocrResponse.fk_category_id);
  //
  //     setOcrFields(fields);
  //   }
  // }, [ocrResponse]);
  const getClassName = () => {};
  const GetData = (ocrFields) => {
    return (
      <React.Fragment>
        <ul>
          {ocrFields.ocrFields.map((ocrField, index) => {
            return <li key={index.toString()}>{ocrField.label}</li>;
          })}
        </ul>
      </React.Fragment>
    );
  };
  const showDocumentDetails = (ocr_res) => {
    if (ocr_res.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
      return (
        <div>
          <table>
            <tr>
              <td>
                <label>Name</label>
              </td>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <td>
                <label>Aadhar Number</label>
              </td>
              <td>{ocr_res.aadhaar_no}</td>
            </tr>

            <tr>
              <td>
                <label>Address</label>
              </td>
              <td>{ocr_res.address}</td>
            </tr>
            {ocr_res.state && (
              <tr>
                <td>
                  <label>State</label>
                </td>
                <td>{ocr_res.state}</td>
              </tr>
            )}
            {ocr_res.landmark && (
              <tr>
                <td>
                  <label>Landmark</label>
                </td>
                <td>{ocr_res.landmark}</td>
              </tr>
            )}
            {/*<tr>
              <td><label>State</label></td>
              <td>{ocr_res.state}</td>
            </tr>
            <tr>
              <td><label>City</label></td>
              <td>{ocr_res.city}</td>
            </tr>*/}
            <tr>
              <td>
                <label>DOB</label>
              </td>
              <td>{ocr_res.dob}</td>
            </tr>
            {/*<tr>
              <td><label>Father Name</label></td>
              <td>{ocr_res.father}</td>
            </tr>*/}
            <tr>
              <td>
                <label>Gender</label>
              </td>
              <td>{ocr_res.gender}</td>
            </tr>
            {/*{ocr_res.gender === "FEMALE" && (
              <tr>
                <td><label>Husband</label></td>
                <td>{ocr_res.husband ? ocr_res.husband : "N/A"}</td>
              </tr>
            )}*/}
            {/*<tr>
              <td><label>Mother Name </label></td>
              <td>{ocr_res.mother}</td>
            </tr>*/}
            <tr>
              <td>
                <label>PIN Code</label>
              </td>
              <td>{ocr_res.pin}</td>
            </tr>
          </table>
        </div>
      );
    } else if (ocr_res.document_name === "PAN Card") {
      return (
        <div>
          <table>
            <tr>
              <td>
                <label>Name</label>
              </td>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <td>
                <label>PAN Number</label>
              </td>
              <td>{ocr_res.pan_no}</td>
            </tr>
            {ocr_res.doi && (
              <tr>
                <td>
                  <label>Date of Issue</label>
                </td>
                <td>{ocr_res.doi}</td>
              </tr>
            )}
            {ocr_res.dob && (
              <tr>
                <td>
                  <label>DOB/DOI</label>
                </td>
                <td>{ocr_res.dob}</td>
              </tr>
            )}
            {ocr_res.father && (
              <tr>
                <td>
                  <label>Father Name</label>
                </td>
                <td>{ocr_res.father}</td>
              </tr>
            )}
            {/*<tr>
              <td><label>Gender</label></td>
              <td>{ocr_res.gender}</td>
            </tr>
            {ocr_res.gender === "FEMALE" && (
              <tr>
                <td><label>Husband</label></td>
                <td>{ocr_res.husband ? ocr_res.husband : "N/A"}</td>
              </tr>
            )}
            <tr>
              <td><label>Mother Name </label></td>
              <td>{ocr_res.mother}</td>
            </tr>*/}
          </table>
        </div>
      );
    } else if (ocr_res.document_name === "Voter ID") {
      return (
        <div>
          <table>
            <tr>
              <td>
                <label>Voter ID</label>
              </td>
              <td>{ocr_res.voter_id}</td>
            </tr>
            <tr>
              <td>
                <label>Name</label>
              </td>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <td>
                <label>Father Name</label>
              </td>
              <td>{ocr_res.father}</td>
            </tr>
            <tr>
              <td>
                <label>Gender</label>
              </td>
              <td>{ocr_res.gender ? ocr_res.gender : "-"}</td>
            </tr>
          </table>
        </div>
      );
    } else if (ocr_res.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE) {
      return (
        <div>
          <table>
            <tr>
              <td>
                <label>Driving License No</label>
              </td>
              <td>{ocr_res.dl_no}</td>
            </tr>
            <tr>
              <td>
                <label>Name</label>
              </td>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <td>
                <label>DOB</label>
              </td>
              <td>{ocr_res.dob}</td>
            </tr>
          </table>
        </div>
      );
    } else if (ocr_res.document_name === OCR_DOCUMENT_LABEL.PASSPORT) {
      return (
        <div>
          <table>
            <tr>
              <td>
                <label>Name</label>
              </td>
              <td>{ocr_res.name ? ocr_res.name : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>Passport Number</label>
              </td>
              <td>{ocr_res.passport_no ? ocr_res.passport_no : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>Father Name</label>
              </td>
              <td>{ocr_res.father ? ocr_res.father : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>Mother Name</label>
              </td>
              <td>{ocr_res.mother ? ocr_res.mother : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>Address</label>
              </td>
              <td>{ocr_res.address ? ocr_res.address : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>State</label>
              </td>
              <td>{ocr_res.state ? ocr_res.state : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>City</label>
              </td>
              <td>{ocr_res.city ? ocr_res.city : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>Gender</label>
              </td>
              <td>{ocr_res.gender ? ocr_res.gender : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>DOB</label>
              </td>
              <td>{ocr_res.dob ? ocr_res.dob : "-"}</td>
            </tr>

            <tr>
              <td>
                <label>Date of Issue</label>
              </td>
              <td>{ocr_res.doi ? ocr_res.doi : "-"}</td>
            </tr>
            <tr>
              <td>
                <label>Date of Expiry</label>
              </td>
              <td>{ocr_res.doe ? ocr_res.doe : "-"}</td>
            </tr>
          </table>
        </div>
      );
    } else if (ocr_res.document_name === OCR_DOCUMENT_LABEL.GST_REGISTRATION) {
      return (
        <div>
          <table>
            <tr>
              <td>
                <label>GST No.</label>
              </td>
              <td>{ocr_res.gst_registration_no}</td>
            </tr>
            <tr>
              <td>
                <label>Organization Name</label>
              </td>
              <td>{ocr_res.organization_name}</td>
            </tr>
            <tr>
              <td>
                <label>Date of Incorporation</label>
              </td>
              <td>
                {ocr_res.date_of_incorporation
                  ? ocr_res.date_of_incorporation
                  : "-"}
              </td>
            </tr>
            <tr>
              <td>
                <label>Address</label>
              </td>
              <td>{ocr_res.address ? ocr_res.address : "-"}</td>
            </tr>
          </table>
        </div>
      );
    }
  };
  return (
    <>
      <div className="CollapseWrap CollapseWrap-v2">
        <h3 className="heading">
          OCR Result{" "}
          <pre>
            <div>
              <img src={CheckIcon} alt="valid" /> OCR Success {"  "}{" "}
              <img src={InvalidIcon} alt="valid" /> OCR Failed
            </div>
          </pre>
        </h3>
        {ocrResponse &&
          ocrResponse.result &&
          Array.isArray(ocrResponse.result) && ocrResponse.result.length>0 && <Collapse accordion defaultActiveKey={1}>
          <Panel
            header={
              ocrResponse.isMemberOrDirector
                ? ocrResponse.isMemberOrDirector
                : ocrResponse.applicant_type
            }
            key="1"
          >
            <ul>
              {ocrResponse.result.map((ocr_result, index) => {
                  return (
                    <li
                      className={
                        ocr_result.message === "Success"
                          ? "completed"
                          : "failed"
                      }
                      key={index.toString()}
                    >
                      {ocr_result.type}
                    </li>
                  );
                })}
              {/*<li className="completed">Email Authentication</li>
  <li className="loading">Mobile No Authentication</li>*/}
            </ul>
          </Panel>
        </Collapse>}
        {ocrResponse &&
          ocrResponse.result &&
          Array.isArray(ocrResponse.result) &&
          ocrResponse.result.map((ocr_result, index) => {
            return (
              ocr_result.error && (
                <Alert
                  message={`${ocr_result.type} Error`}
                  description={ocr_result.error}
                  type="error"
                  key={index.toString()}
                />
              )
            );
          })}
        <Collapse accordion className="mt-2">
          {ocrResponse &&
            ocrResponse.user_ocr_result &&
            Array.isArray(ocrResponse.user_ocr_result) &&
            ocrResponse.user_ocr_result.map((ocr_res, index) => {
              if (
                ocr_res.document_name &&
                ocr_res.document_name != OCR_DOCUMENT_LABEL.GST_REGISTRATION
              ) {
                return (
                  <Panel
                    header={`View ${
                      ocr_res.document_name
                        ? ocr_res.document_name === "PAN Card"
                          ? "PAN Card"
                          : ocr_res.document_name
                        : ""
                    } Details`}
                    key={index.toString()}
                  >
                    {showDocumentDetails(ocr_res)}
                  </Panel>
                );
              }
            })}
        </Collapse>
        {ocrResponse &&
          ocrResponse.user_detail &&
          ocrResponse.user_detail.fk_category_id &&
          ocrResponse.user_detail.fk_category_id != CATEGORY.INDIVIDUAL && (
            <React.Fragment>
              <Collapse accordion className="mt-2">
                {ocrResponse &&
                  ocrResponse.user_ocr_result &&
                  Array.isArray(ocrResponse.user_ocr_result) &&
                  ocrResponse.user_ocr_result.map((ocr_res, index) => {
                    if (
                      ocr_res.document_name &&
                      ocr_res.document_name ===
                        OCR_DOCUMENT_LABEL.GST_REGISTRATION
                    ) {
                      return (
                        <React.Fragment>
                          <h3 className="heading">
                            Details Fetched From GST Number Provided{" "}
                            {/*<pre>
                  <div>
                    <img src={CheckIcon} alt="valid" /> GST Success {"  "}{" "}
                    <img src={InvalidIcon} alt="valid" /> GST Failed
                  </div>
                </pre>*/}
                          </h3>
                          <Panel
                            header={`View ${
                              ocr_res.document_name
                                ? ocr_res.document_name === "PAN Card"
                                  ? "PAN Card"
                                  : ocr_res.document_name
                                : ""
                            } Details`}
                            key={index.toString()}
                          >
                            {showDocumentDetails(ocr_res)}
                          </Panel>
                        </React.Fragment>
                      );
                    }
                  })}
              </Collapse>
            </React.Fragment>
          )}
      </div>
    </>
  );
};
export default RunOcr;
