import { INITIAL_STATE } from "../../constants/constants";
import {
  CHANGE_MIDDLE_TAB,
  CHANGE_SELECTED_MEMBER_DIRECTOR,
  CHANGE_SELECTED_USER,
  CLOSED_CASES_FAILED,
  CLOSED_CASES_SUCCESS,
  CURRENT_CASE,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOG_NEW_CASE_ACTIVE_KEY,
  NEW_CASE_CREATED,
  REMOVE_NEW_CASE,
  RESET_CASE_DETAIL,
  RESET_INDIVIDUAL_MODAL,
  RESET_NEW_CASE,
  RESET_PARTICIPANT,
  SET_CASES,
  SET_CASE_DETAIL,
  SET_CASE_MASTERS,
  SET_CIBIL_CITIES,
  SET_CITIES,
  SET_CURRENT_CASE,
  SET_CURRENT_KYC_RESULT,
  SET_CURRENT_OCR_RESULT,
  SET_CURRENT_TAB,
  SET_DASHBOARD_DATA,
  SET_FIRST_TAB,
  SET_LOADER,
  SET_PAGE_KEY,
  SET_PERMISSION_DATA,
  SET_SECOND_TAB,
  SET_STATES,
  TO_RESET_NEW_CASE,
  UPDATE_NEW_CASE,
  UPDATE_NON_OCR_DOCS,
  UPDATE_OCR_DOCS,
  UPLOAD_DOCUMENT_ACTIVE_KEY,
} from "../constants/ActionTypes";
const DEFAULT_STATE = INITIAL_STATE;
export default function auth(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        userData: action.userData,
        isLoggedIn: true,
        role:
          action.userData &&
          action.userData.user_detail &&
          action.userData.user_detail.roles,
      };
    case LOGIN_FAILED:
      return INITIAL_STATE;
    case LOGOUT_SUCCESS:
      return INITIAL_STATE;
    case SET_CASE_MASTERS:
      return {
        ...state,
        masterData: {
          caseMasters: action.data,
        },
      };
    case SET_CASES:
      return {
        ...state,
        userCases: action.data,
      };
    case SET_CURRENT_TAB:
      return {
        ...state,
        currentTab: action.payload,
      };
    case NEW_CASE_CREATED:
      return {
        ...state,
        newlyCreatedCase: action.data,
        isNewlyCreatedCaseSaved: true,
      };
    case CURRENT_CASE:
      return {
        ...state,
        currentCase: action.data,
      };
    case SET_CURRENT_CASE: {
      return {
        ...state,
        currentCase: action.data,
      };
    }
    case REMOVE_NEW_CASE:
      return {
        ...state,
        newlyCreatedCase: INITIAL_STATE.newlyCreatedCase,
        isNewlyCreatedCaseSaved: INITIAL_STATE.isNewlyCreatedCaseSaved,
      };
    case UPDATE_OCR_DOCS:
      if (action.userType === "Main Applicant") {
        return {
          ...state,
          mainApplicant: {
            ...state.mainApplicant,
            ...action.data,
          },
        };
      } else if (action.userType === "Co Applicant") {
        return {
          ...state,
          coApplicant: {
            ...state.coApplicant,
            ...action.data,
          },
        };
      } else if (action.userType === "Guarantor") {
        return {
          ...state,
          gurantor: {
            ...state.gurantor,
            ...action.data,
          },
        };
      } else {
        return state;
      }
    case UPDATE_NON_OCR_DOCS:
      if (action.userType === "Main Applicant") {
        return {
          ...state,
          mainApplicant: {
            ...state.mainApplicant,
            ...action.data,
          },
        };
      } else if (action.userType === "Co Applicant") {
        return {
          ...state,
          coApplicant: {
            ...state.coApplicant,
            ...action.data,
          },
        };
      } else if (action.userType === "Guarantor") {
        return {
          ...state,
          gurantor: {
            ...state.gurantor,
            ...action.data,
          },
        };
      } else {
        return state;
      }
    case SET_FIRST_TAB:
      return {
        ...state,
        firstTab: action.tab,
      };
    case SET_SECOND_TAB:
      return {
        ...state,
        secondTab: action.tab,
      };
    case SET_CASE_DETAIL:
      return {
        ...state,
        caseDetail: action.caseDetail,
      };
    case RESET_CASE_DETAIL:
      return {
        ...state,
        caseDetail: {},
      };
    case CHANGE_SELECTED_USER:
      return {
        ...state,
        selectedUser: action.user,
      };
    case CHANGE_SELECTED_MEMBER_DIRECTOR:
      return {
        ...state,
        selectedMemberDirector: action.data,
      };
    case SET_STATES:
      return {
        ...state,
        states: action.states,
      };
    case SET_CITIES:
      return {
        ...state,
        cities: action.cities,
      };
    case SET_CIBIL_CITIES:
      return {
        ...state,
        cibilcities: action.cibilcities,
      };
    case RESET_NEW_CASE:
      return {
        ...state,
        newlyCreatedCase: null,
        isNewlyCreatedCaseSaved: false,
        logNewCaseActiveKey: "1",
      };
    case TO_RESET_NEW_CASE:
      return {
        ...state,
        toResetNewCase: action.data,
      };
    case SET_PAGE_KEY:
      return {
        ...state,
        pageKey: action.key,
      };
    case UPDATE_NEW_CASE:
      return {
        ...state,
        newlyCreatedCase: action.data,
      };
    case LOG_NEW_CASE_ACTIVE_KEY:
      return {
        ...state,
        logNewCaseActiveKey: action.key,
      };
    case UPLOAD_DOCUMENT_ACTIVE_KEY:
      return {
        ...state,
        uploadDocumentActiveKey: action.key,
      };
    case SET_DASHBOARD_DATA:
      return {
        ...state,
        dashboardData: action.data,
      };
    case RESET_PARTICIPANT:
      return {
        ...state,
        toReset: action.toReset,
      };
    case RESET_INDIVIDUAL_MODAL:
      return {
        ...state,
        resetIndividualModal: action.toReset,
      };
    case CHANGE_MIDDLE_TAB:
      return {
        ...state,
        middleTab: action.tab,
      };
    case SET_LOADER:
      return {
        ...state,
        loader: action.data,
      };
    case CLOSED_CASES_SUCCESS:
      return { ...state, closedCases: action.closedCases };
    case CLOSED_CASES_FAILED:
      return { ...state, closedCases: [] };
    case SET_CURRENT_OCR_RESULT:
      return {
        ...state,
        currentOcrResult: action.data,
      };
    case SET_CURRENT_KYC_RESULT:
      return {
        ...state,
        currentKycResult: action.data,
      };
    case SET_PERMISSION_DATA:
      console.log("action.data: $$$$$$$$$$$$$$$", action.data);
      return {
        ...state,
        userPermissions: {
          isCaseAdditionPermitted:
            action.data && action.data.Case && action.data.Case.add,
          isCaseEditPermitted:
            action.data && action.data.Case && action.data.Case.edit,
          isCaseDeletionPermitted:
            action.data && action.data.Case && action.data.Case.delete,
          isCaseViewPermitted:
            action.data && action.data.Case && action.data.Case.view,
          isBureauReportRunAllowed : action.data && action.data.Bureau_Reports && action.data.Bureau_Reports.run,
          isCaseReallocationAllowed : action.data && action.data.Reallocate_case && action.data.Reallocate_case.add  
        },
        // userPermissions:action.data,
      };
    default:
      return state;
  }
}
