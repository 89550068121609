import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
} from "../../../helpers/utils";

export const beforeUploadOcrAndRequiredNonOcrDocument = (
  file,
  list,
  docName,
  ocrDocuments,
  callback
) => {
  if (checkFileTypeImageAndPdf(file)) {
    if (checkFileSizeOneMB(file,5)) {
    return {
      ...ocrDocuments,
      [docName]: list,
    };
    }
  }
  return;
};
export const beforeUploadRequiredNonOcrDocument = (
  file,
  list,
  docName,
  ocrDocuments,
  callback
) => {
  if (checkFileTypeImageAndPdf(file)) {
    if (checkFileSizeOneMB(file,10)) {
    return {
      ...ocrDocuments,
      [docName]: list,
    };
    }
  }
  return;
};
export const onRemoveOcrAndRequiredNonOcrDocument = (
  fileName,
  ocrOrNonOcrDocuments
) => {
  return {
    ...ocrOrNonOcrDocuments,
    [fileName]: [],
  };
};
export const beforeUploadNonRequiredOcrDocument = (file, fileList) => {
  if (checkFileTypeImageAndPdf(file)) {
    if (checkFileSizeOneMB(file,10)) {
      return false;
    }
  }
  return true;
};
