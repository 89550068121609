import React, { useEffect, useState } from 'react';
import { Col, Tabs, Row, Card, Button, Modal, Form, Select } from "antd";
import siteLogo from "..//..//..//..//assets/images/logo-new.png";

export default function ExpirePage(props) {
    const { message, color } = props.location.state;
    return (
        <div>
            <div className="topBarWrap">
                <Row>
                    <Col span={12} sm={12} xs={24} className="logowrap">
                        {/* <img src={siteLogo} alt="logo" /> */}
                        <div className="Branding">
                            <div className="text">
                                <div>
                                    <img src={siteLogo} alt="logo" />
                                </div>
                                <div>
                                    <h4>Volvo Financial Services</h4>
                                    <p>INDIA</p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="FieldTabsWrap FieldDetailTabs">
                {message ?
                    <h3
                        className="font-weight-bold"
                        style={{ textAlign: "center", color: color }}
                    >{message} </h3>
                    :
                    <h3
                        className="font-weight-bold"
                        style={{ textAlign: "center", color: "red" }}
                    >{`Your FI has been submitted or Link Expired`} </h3>}
            </div>
        </div>
    )
}
