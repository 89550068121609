import React, { Component } from "react";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import FilterNewCaseList from "../components/filterNewCaseList";
class NewCases extends Component {
  render() {
    return (
      <>
        <div className="NewCasesWrap">
          <TopBarNav />
          <Navigation />
          <FilterNewCaseList />
        </div>
      </>
    );
  }
}
export default NewCases;
