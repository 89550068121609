import React, { Component, useEffect, useState, useCallback } from "react";
import { Button, Radio, Select, Modal, Tabs, Table, Form, Tooltip } from "antd";
import { Link } from "react-router-dom";

import AddnewCoapplicant from "../../../casedetail/modals/addcoapplicant";
import SecondTab from "../../case-detail/secondtab/secondtab";
import { connect, useDispatch, useSelector } from "react-redux";
import Participant from "../../../lognewcase/participant";
import { onCaseDetail } from "../../../../redux/actions/CaseAction";
import { onChangeSelectedUser } from "../../../../redux/actions/AuthAction";
import { onDeleteCoapplicant } from "../../../../redux/actions/CoApplicantAction";
import {
  onApproveRejectParticipant,
  onParticipantDetail,
  onResetParticipant,
} from "../../../../redux/actions/ParticipantAction";
import ThirdTab from "../thirdtab/thirdtab";
import AddCoApplicant from "../../../case-participants/co-applicants/AddCoApplicant";
import {
  getClassNameByStatus,
  isCaseNotClosed,
  isCreditReviewPending,
  isCreditUser,
  isKycDone,
  isSalesUser,
  loggedInUserData,
} from "../../../../helpers/utils";
import {
  APPLICANT_TYPE,
  CATEGORY,
  CATEGORY_NAME,
  STAGES,
  STATUS,
} from "../../../../constants/constants";
import { MESSAGES } from "../../../../constants/messages";
import {
  onChangeFirstTabKey,
  onChangeSecondTabKey,
  onChangeThirdTabKey,
} from "../../../../redux/actions/TabAction";
import {
  onJustificationComment,
  onFetchJustificationComment,
} from "../../../../redux/actions/CaseAction";
import { onParticipantByCaseId } from "../../../../redux/actions/ParticipantAction.js";

import { toast } from "react-toastify";
import AskForJustificationModal from "../../../modals/askForJustificationModal";
import DeleteCoapplicantGuarantorModal from "../../../modals/deleteCoapplicantGuarantorModal";
import DropCoapplicantGuarantorModal from "../../../modals/dropCoapplicantGuarantorModal";
import ApproveCoapplicantGuarantorModal from "../../../modals/ApproveCoapplicantGuarantorModal";
import { InformationModal } from "../../../modals/InformationModal";

// import {
//   onCaseDetail,
//   onChangeSelectedUser,
//   onDeleteCoapplicant,
//   onResetParticipant,
// } from "../../../redux/actions/AuthAction";
const Coapplicantform = (props) => {
  const { caseDetail, selectedUser } = props;
  const [addcoapplicant, setAddCoapplicant] = useState(false);
  const [co_applicants, setCoapplicants] = useState([]);
  const [deleteCoapplicantModalVisible, setDeleteCoapplicantModalVisible] =
    useState(false);
  const [dropCoapplicantModalVisible, setDropCoapplicantModalVisible] =
    useState(false);
  const [approveCoapplicantModalVisible, setApproveCoapplicantModal] =
    useState(false);

  const [askJustification, setAskJustification] = useState(false);
  const [coapplicantDeletionId, setCoapplicantDeletionId] = useState(false);
  const [isMemberOrDirector, setIsMemberOrDirector] = useState(false);
  const [editCoApplicant, setToEditCoApplicant] = useState(false);
  const [maxCoapplicantError, setMaxCoapplicantError] = useState("");
  const [informationModal, setInformationModal] = useState(false);
  const [informationTitle, setInformationTitle] = useState(false);
  const dispatch = useDispatch();
  const userData = loggedInUserData();
  const [form2] = Form.useForm();
  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  const [coapplicantApprovalId, setCoapplicantApprovalId] = useState(false);
  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.co_applicants &&
      Array.isArray(caseDetail.co_applicants)
    ) {
      setCoapplicants(caseDetail.co_applicants);
      if (
        selectedUser &&
        Object.keys(selectedUser).length === 0 &&
        selectedUser.constructor === Object
      ) {
        if (caseDetail.co_applicants.length > 0) {
          dispatch(onChangeSelectedUser(caseDetail.co_applicants[0]));
        }
      }
    }
    // dispatch(onParticipantByCaseId(caseDetail.id,(participants)=>{
    //   setCoapplicants(participants.co_applicants);
    // }))
  }, [dispatch, caseDetail, selectedUser]);
  useEffect(() => {
    if (
      selectedUser &&
      selectedUser.fk_category_id &&
      (selectedUser.fk_category_id.toString() === CATEGORY.CORPORATE ||
        selectedUser.fk_category_id.toString() === CATEGORY.HUF ||
        selectedUser.fk_category_id.toString() === CATEGORY.PROPRIETOR)
    ) {
      setIsMemberOrDirector(true);
    } else {
      setIsMemberOrDirector(false);
    }
  }, [selectedUser]);
  const handleCancel = () => {
    setAddCoapplicant(false);
    setAskJustification(false);
    form2.resetFields();
  };
  const closeModal = (newlyCreatedCoApplicant) => {
    if (newlyCreatedCoApplicant) {
      dispatch(
        onCaseDetail({ caseId: caseDetail.id }, (caseDetails) => {
          if (
            caseDetails &&
            caseDetails.co_applicants &&
            Array.isArray(caseDetails.co_applicants)
          ) {
            const newCoApplicantAllDetails = caseDetails.co_applicants.find(
              (co_applicant) => {
                return (
                  co_applicant.id.toString() ===
                  newlyCreatedCoApplicant.id.toString()
                );
              }
            );
            dispatch(onChangeSelectedUser(newCoApplicantAllDetails));
          }
        })
      );
    }
    setAddCoapplicant(false);
  };
  const deleteCoapplicantConfirmation = () => {
    setDeleteCoapplicantModalVisible(false);
    deleteCoapplicant(coapplicantDeletionId);
  };
  const dropCoapplicantConfirmation = () => {
    setDropCoapplicantModalVisible(false);
    dropCoapplicant(coapplicantDeletionId);
  };
  const approveCoapplicantConfirmation = () => {
    setApproveCoapplicantModal(false);
    approveCoapplicant(coapplicantApprovalId);
  };
  const approveCoapplicant = (coapplicantId) => {
    let data = {
      applicant_type: APPLICANT_TYPE.CO_APPLICANT,
      participants_id: coapplicantId,
      is_approved: "1",
    };
    dispatch(
      onApproveRejectParticipant(data, () => {
        
        dispatch(
          onCaseDetail({ caseId: caseDetail.id }, (caseDetail) => {
            setCoapplicants(caseDetail.co_applicants);
            let updatedCoapplicant = caseDetail.co_applicants.find((co_applicant)=>{
              return co_applicant.id == coapplicantId;
            });
            dispatch(onChangeSelectedUser(updatedCoapplicant));
            dispatch(onChangeSecondTabKey("1"));
            dispatch(onChangeThirdTabKey("1"));
          })
        );
      })
    );
  };
  const deleteCoapplicantCancel = () => {
    setDeleteCoapplicantModalVisible(false);
  };
  const dropCoapplicantCancel = () => {
    setDropCoapplicantModalVisible(false);
  };
  const approveCoapplicantCancel = () => {
    setApproveCoapplicantModal(false);
  };
  const dropCoapplicant = () => {
    let data = {
      applicant_type: APPLICANT_TYPE.CO_APPLICANT,
      participants_id: coapplicantDeletionId,
      is_approved: "0",
    };
    dispatch(
      onApproveRejectParticipant(data, (response) => {
        
        dispatch(
          onCaseDetail({ caseId: caseDetail.id }, (caseDetail) => {
            
            setCoapplicants(caseDetail.co_applicants);
            let updatedCoapplicant = caseDetail.co_applicants.find((co_applicant)=>{
              return co_applicant.id == coapplicantDeletionId;
            });
            dispatch(onChangeSelectedUser(updatedCoapplicant));
            dispatch(onChangeSecondTabKey("1"));
            dispatch(onChangeThirdTabKey("1"));
          })
        );
      })
    );
  };
  const deleteCoapplicant = (coappplicantId) => {
    dispatch(
      onDeleteCoapplicant(coappplicantId, () => {
        dispatch(
          onCaseDetail({ caseId: caseDetail.id }, (caseDetail) => {
            if (
              selectedUser &&
              selectedUser.id &&
              selectedUser.id.toString() === coappplicantId.toString()
            ) {
              if (
                caseDetail &&
                caseDetail.co_applicants &&
                caseDetail.co_applicants.length > 0
              ) {
                dispatch(onChangeSelectedUser(caseDetail.co_applicants[0]));
              } else {
                dispatch(onChangeSelectedUser(null));
              }
            }
          })
        );
      })
    );
  };
  const openCoapplicantModal = () => {
    // alert(caseDetail.co_applicants.length);
    if(isCreditReviewPending(caseDetail.status)){
      setInformationModal(true);
      setInformationTitle(MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE)
    }else{
      if (caseDetail.co_applicants.length >= 5) {
        setMaxCoapplicantError(MESSAGES.MAX_COAPPLICANT_ERROR);
      } else {
        setToEditCoApplicant(false);
        dispatch(onResetParticipant(true));
        setAddCoapplicant(true);
      }
    }
    
  };
  const makeEditable = (co_applicant) => {
    // setToAddNewCoApplicant(false);
    // setToEditCoApplicant(false);
    dispatch(
      onParticipantDetail(
        {
          applicant_type: "Co Applicant",
          applicant_coapplicant_guarantor_id: co_applicant.id,
        },
        (co_applicant) => {
          dispatch(onChangeSelectedUser(co_applicant));
          setToEditCoApplicant(co_applicant);
        }
      )
    );
  };

  const saveJustificationComments = (data) => {
    dispatch(onJustificationComment(data, onSuccess, onError));
  };

  const onSuccess = (data) => {
    form2.resetFields();
    setAskJustification(false);
    let id = caseDetail.id;
    dispatch(onFetchJustificationComment(id));
  };
  const onError = (data) => {
    form2.resetFields();
    toast.error(data.message);
  };
  const dropCoApplicantHandler = (co_applicant) => {
    setCoapplicantDeletionId(co_applicant.id);
    setDropCoapplicantModalVisible(true);
  };
  const approveCoapplicantHandler = (co_applicant) => {
    setCoapplicantApprovalId(co_applicant.id);
    setApproveCoapplicantModal(true);
  };
  return (
    <>
      <div className="HeaderPanelAction">
        <h3></h3>
        {userData &&
          isSalesUser(userData) &&
          isCaseNotClosed(caseDetail.status) && (
            <div className="ButtonWrap">
              <Button onClick={openCoapplicantModal}>
                Add New Co-applicant
              </Button>
            </div>
          )}
      </div>
      {maxCoapplicantError && (
        <div className="text-left errorMessage">{maxCoapplicantError}</div>
      )}
      <div className="TableWrap">
        <table>
          <thead>
            <tr>
              <th>Co Applicant Type</th>
              <th style={{width:'15%'}}>Name</th>
              <th>Relation with Applicant</th>
              <th>Status</th>
              <th>Stage</th>
              {caseDetail &&
                isCaseNotClosed(caseDetail.status) &&
                (isSalesUser(userData) ||
                  (isCreditUser(userData) &&
                    caseDetail.status === STATUS.CREDIT_REVIEW_PENDING)) && (
                  <th>Action</th>
                )}
            </tr>
          </thead>
          <tbody>
            {co_applicants &&
            Array.isArray(co_applicants) &&
            co_applicants.length > 0 ? (
              co_applicants.map((co_applicant, index) => (
                <tr key={co_applicant.id}>
                  <td>
                    <Radio.Group
                      name="radiogroup"
                      value={selectedUser && selectedUser.id}
                      onChange={(val) => {
                        dispatch(onChangeSelectedUser(co_applicant));
                        dispatch(onChangeSecondTabKey("1"));
                        dispatch(onChangeThirdTabKey("1"));
                      }}
                    >
                      <Radio value={co_applicant.id}>
                        {co_applicant.category_name}
                      </Radio>
                    </Radio.Group>
                  </td>
                  <td>
                    {co_applicant.category_name === CATEGORY_NAME.INDIVIDUAL &&
                    (co_applicant.first_name
                      ? co_applicant.first_name
                      : "-")}{" "}
                    {co_applicant.category_name === CATEGORY_NAME.INDIVIDUAL &&
                      co_applicant.last_name}
                      {co_applicant.category_name !== CATEGORY_NAME.INDIVIDUAL &&
                        (co_applicant.organization_name)}
                  </td>
                  <td>
                    {co_applicant.coapplicant_applicant_relation}
                    {co_applicant.coapplicant_applicant_relation === "Others" &&
                      `(${co_applicant.other_relation})`}
                  </td>
                  <td>
                    <div className={getClassNameByStatus(co_applicant.status)}>
                      {co_applicant.status}
                    </div>
                  </td>
                  <td>{co_applicant.stage}</td>
                  {caseDetail &&
                    isCaseNotClosed(caseDetail.status) &&
                    (isSalesUser(userData) ||
                      (isCreditUser(userData) &&
                        caseDetail.status ===
                          STATUS.CREDIT_REVIEW_PENDING)) && (
                      <td>
                        {/*<Button>edit</Button>*/}
                        {isSalesUser(userData) && (
                          <React.Fragment>
                            {co_applicant.status === 'Dropped' && <Button
                              onClick={() => {
                                if(isCreditReviewPending(caseDetail.status)){
                                  setInformationModal(true);
                                  setInformationTitle(MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE)
                                }else{
                                  approveCoapplicantHandler(co_applicant);
                                }
                              }}
                              disabled={
                                co_applicant.status === STATUS.APPROVED ||
                                caseDetail.status === STATUS.REJECTED ||
                                caseDetail.status ===
                                  STATUS.CREDIT_BUREAU_APPROVED
                              }
                            >
                              Re-open
                            </Button>}
                            {co_applicant.status !== 'Dropped' && <Button
                              onClick={() => {
                                if(isCreditReviewPending(caseDetail.status)){
                                  setInformationModal(true);
                                  setInformationTitle(MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE)
                                }else{
                                  dropCoApplicantHandler(co_applicant);
                                }
                              }}
                              disabled={
                                co_applicant.status === STATUS.DROPPED ||
                                caseDetail.status === STATUS.REJECTED ||
                                caseDetail.status ===
                                  STATUS.CREDIT_BUREAU_APPROVED
                              }
                            >
                              Drop
                            </Button>}
                            <Button
                              onClick={() => {
                                if(isCreditReviewPending(caseDetail.status)){
                                  setInformationModal(true);
                                  setInformationTitle(MESSAGES.WAIT_FOR_CREDIT_REVIEW_RESPONSE)
                                }else{
                                  setCoapplicantDeletionId(co_applicant.id);
                                  setDeleteCoapplicantModalVisible(true);
                                  setMaxCoapplicantError("");
                                }
                                
                              }}
                            >
                              Delete
                            </Button>
                          </React.Fragment>
                        )}
                        {/*{(!selectedUser.stage ||
                      selectedUser.stage === STAGES.OCR_PENDING) && (
                      <Button >Run OCR</Button>
                    )}
                    {selectedUser.stage === STAGES.KYC_PENDING && (
                      <Button >Run KYC</Button>
                    )}*/}
                        {/*<Tooltip
                          placement="topLeft"
                          title={
                            co_applicant.status === STATUS.DROPPED
                              ? "Co-applicant Dropped"
                              : !isKycDone(co_applicant.stage)
                              ? "KYC Required"
                              : ""
                          }
                          arrowPointAtCenter
                        >
                          <Button
                            disabled={co_applicant.status === STATUS.DROPPED}
                            className={!isKycDone(co_applicant.stage) ? "" : ""}
                            onClick={() => {
                              if (!isKycDone(co_applicant.stage)) {
                                setInformationTitle(
                                  "KYC should be done before asking for justification"
                                );
                                setInformationModal(true);
                              } else if (
                                caseDetail.status ===
                                  STAGES.CREDIT_REVIEW_PENDING &&
                                isSalesUser(userData)
                              ) {
                                setInformationTitle(
                                  "Please wait for credit review response"
                                );
                                setInformationModal(true);
                              } else {
                                setAskJustification(true);
                              }
                            }}
                            // onClick={() => setAskJustification(true)}
                          >
                            Ask for Justification
                          </Button>
                          </Tooltip>*/}
                      </td>
                    )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <p className="NoCardBox">No Co-Applicants</p>
                </td>
              </tr>
            )}{" "}
          </tbody>
        </table>
      </div>
      {caseDetail &&
        caseDetail.co_applicants &&
        caseDetail.co_applicants.length > 0 &&
        selectedUser &&
        (isMemberOrDirector ? <SecondTab /> : <ThirdTab />)}
      {/* Add New Co-Applicant */}
      <Modal
        title="Add New Co-Applicant"
        centered
        visible={addcoapplicant}
        onCancel={handleCancel}
        footer={null}
        // footer={[
        //   <Button onClick={this.handleCancel}>Cancel</Button>,
        //   <Button className="filled" onClick={this.handleCancel}>
        //     Save
        //   </Button>,
        //   <Button className="filled" onClick={this.handleCancel}>
        //     Save and Clear
        //   </Button>,
        // ]}
        width={1000}
        className="AddCoapplicant"
        style={{ top: 50 }}
        destroyOnClose={true}
      >
        {editCoApplicant ? (
          <AddCoApplicant
            editCoApplicant={editCoApplicant}
            makeEditable={makeEditable}
            add_new_coapplicant_inside_modal={true}
            handleCancel={handleCancel}
          />
        ) : (
          <AddCoApplicant
            makeEditable={makeEditable}
            add_new_coapplicant_inside_modal={true}
            handleCancel={handleCancel}
          />
        )}
        {/*<Participant
          type="Co Applicant"
          caseId={caseDetail.id}
          modalType="add_new_coapplicant"
          closeModal={closeModal}
          closeUploadDocumentsModalHandler={closeModal}
        />*/}
        {/*<AddnewCoapplicant />*/}
      </Modal>

      <AskForJustificationModal
        caseDetail={caseDetail}
        handleOk={saveJustificationComments}
        form={form2}
        isModalVisible={askJustification}
        handleCancel={handleCancel}
        selectedApplicant={selectedApplicant}
      />
      <InformationModal
        isVisible={informationModal}
        informationContent={informationTitle}
        title={"Credit Review Pending"}
        handleOk={() => {
          setInformationModal(false);
        }}
      />
      {/* Ask For Justification */}
      <DeleteCoapplicantGuarantorModal
        isModalVisible={deleteCoapplicantModalVisible}
        handleCancel={deleteCoapplicantCancel}
        handleOk={deleteCoapplicantConfirmation}
        isCoapplicantOrGuarantor={APPLICANT_TYPE.CO_APPLICANT}
      />
      <DropCoapplicantGuarantorModal
        isModalVisible={dropCoapplicantModalVisible}
        handleCancel={dropCoapplicantCancel}
        handleOk={dropCoapplicantConfirmation}
        isCoapplicantOrGuarantor={APPLICANT_TYPE.CO_APPLICANT}
      />
      <ApproveCoapplicantGuarantorModal
        isModalVisible={approveCoapplicantModalVisible}
        handleCancel={approveCoapplicantCancel}
        handleOk={approveCoapplicantConfirmation}
        isCoapplicantOrGuarantor={APPLICANT_TYPE.CO_APPLICANT}
      />
      {/*<Modal
        visible={deleteCoapplicantModalVisible}
        onCancel={deleteCoapplicantCancel}
        className="KycCheck"
        onOk={deleteCoapplicantConfirmation}
      >
        <p className="dangerconfirmationtext">
          Are you sure you want to delete this Co-applicant?
        </p>
      </Modal>*/}
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(Coapplicantform);
