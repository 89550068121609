import React, { Component } from "react";
import { Row, Col, Button, Modal } from "antd";
import UploadDocument from '../modals/uploadDoument';
import RunKycCheck from '../modals/runkyccheck'
import BureauReportForm from '../modals/bureaureport'
import AskJustification from '../modals/askjustification'
class CaseDetailHeader extends Component {
  state = {
    uploaddocument: false,
    runkyc:false,
    BureauReport:false,
    visible: false,
  };
  setModal2Visible(uploaddocument) {
    this.setState({ uploaddocument });
  }
  KycCheck(runkyc) {
    this.setState({ runkyc });
  }
  bureauReport(BureauReport) {
    this.setState({ BureauReport });
  }
  askJustification(Justification) {
    this.setState({ Justification });
  }
  handleCancel = () => {
    this.setState({ Justification: false });
  };
  render() {
    return (
      <>
        <div className="CaseDetailHeader">
          <div className="InnerWrap">
            <Row>
              <Col span={4} xs={24} sm={24} md={12}>
                <p className="CaseId">
                  Case ID - 234{" "}
                  <span>
                    Status <span>Bureau Reports generated</span>
                  </span>
                </p>
              </Col>
              <Col span={4} xs={24} sm={24} md={12}>
                <div className="BtnWrap">
                  <Button>
                    Submit
                  </Button>
                 
                </div>
              </Col>
            </Row>
          </div>
        </div>
        {/* Upload Documents Modal */}
        <Modal
          title="Step 1 - Upload Documents"
          centered
          visible={this.state.uploaddocument}
          onOk={() => this.setModal2Visible(false)}
          onCancel={() => this.setModal2Visible(false)}
          footer={null}
          width={1000}
          className='ModalFormWrap'
          style={{ top: 50 }}
        >
         <UploadDocument/>
        </Modal>
        {/* Upload Documents Modal */}
        {/* Run KYC */}
        <Modal
          title="Document Uploaded Successfully"
          centered
          visible={this.state.runkyc}
          onOk={() => this.KycCheck(false)}
          onCancel={() => this.KycCheck(false)}
          footer={null}
          width={600}
          className='KycCheck'
          style={{ top: 50 }}
        >
         <RunKycCheck/>
        </Modal>
        {/* Run KYC */}

         {/* Run Bureao Reports */}
         <Modal
          title="Bureau Report Generation"
          centered
          visible={this.state.BureauReport}
          onOk={() => this.bureauReport(false)}
          onCancel={() => this.bureauReport(false)}
          footer={null}
          width={1000}
          className='BeureauReport'
          style={{ top: 50 }}
        >
         <BureauReportForm/>
        </Modal>
        {/* Run Bureao Reports */}

         {/* Ask For Justification */}
         <Modal
          title="Justification Requirements "
          centered
          visible={this.state.Justification}
          onCancel={this.handleCancel}
          footer={[
            <Button  onClick={this.handleCancel}>
              Cancel
            </Button>,
            <Button  className='filled'  onClick={this.handleCancel}>
            Submit
          </Button>
          ]}
          width={1000}
          className='JustReqWrap'
          style={{ top: 50 }}
        >
          <AskJustification/>
        </Modal>
         {/* Ask For Justification */}
      </>

    );
  }
}
export default CaseDetailHeader;
