import { first, last } from "lodash";
import {
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../../redux/actions/CaseAction";
import { onAddMemberOrDirector } from "../../../redux/actions/MemberAndDirectorAction";
import {
  onRunNewOCR,
  onRunOcr,
  onRunOcrResponse,
} from "../../../redux/actions/OcrAction";
import { onAddParticipant } from "../../../redux/actions/ParticipantAction";

export const saveApplicant = (
  data,
  dispatch,
  makeEditable,
  runOcr,
  handleCancel
) => {
  const {
    fk_case_id,
    applicant_type,
    user_id,
    fk_category_id,
    fk_subcategory_id,
    ocr_documents,
    non_ocr_documents,
    non_ocr_required_documents,
    coapplicant_applicant_relation,
    other_relation,
    gst_registration_no,
    aadhaar_unmasked,
  } = data;
  const formData = new FormData();
  formData.append("fk_case_id", fk_case_id); //77
  formData.append("applicant_type", applicant_type);
  formData.append("user_id", user_id);
  formData.append("fk_category_id", fk_category_id);
  formData.append("gst_registration_no", gst_registration_no?gst_registration_no:'');
  formData.append("aadhaar_unmasked", aadhaar_unmasked?aadhaar_unmasked:'');
  formData.append(
    "fk_subcategory_id",
    fk_subcategory_id ? fk_subcategory_id : ""
  );
  if (Object.keys(ocr_documents).length > 0) {
    Object.keys(ocr_documents).forEach((doc) => {
      if (Array.isArray(ocr_documents[doc]) && ocr_documents[doc].length > 0) {
        if (ocr_documents[doc][0] instanceof File) {
          formData.append(doc, ocr_documents[doc][0]);
        }
      }
    });
  }
  if (Object.keys(non_ocr_required_documents).length > 0) {
    Object.keys(non_ocr_required_documents).forEach((doc) => {
      if (
        Array.isArray(non_ocr_required_documents[doc]) &&
        non_ocr_required_documents[doc].length > 0
      ) {
        if (non_ocr_required_documents[doc][0] instanceof File) {
          formData.append("non_ocr_document_name[]", doc);
          formData.append(
            "non_ocr_document[]",
            non_ocr_required_documents[doc][0]
          );
          formData.append("non_ocr_document_required[]", "1");
        }
      }
    });
  }
  non_ocr_documents.forEach((nonOcrDoc) => {
    if (nonOcrDoc.name && nonOcrDoc.doc.file) {
      formData.append("non_ocr_document_name[]", nonOcrDoc.name);
      formData.append("non_ocr_document[]", nonOcrDoc.doc.file);
      formData.append("non_ocr_document_required[]", "0");
    }
  });
  if (applicant_type === "Co Applicant") {
    formData.append(
      "coapplicant_applicant_relation",
      coapplicant_applicant_relation
    );
    formData.append("other_relation", other_relation);
  }
  dispatch(
    onAddParticipant(formData, (applicant) => {
      if (runOcr) {
        let data = {
          fk_case_id: fk_case_id,
          applicant_type: applicant_type,
          applicant_coapplicant_guarantor_id: applicant.id,
        };
        // setRunOcr(true);
        dispatch(
          onRunOcr(data, (ocrResponse) => {
            dispatch(
              onRunOcrResponse({
                ocr_success: ocrResponse.result,
                fk_category_id,
                applicant_type,
                applicant_coapplicant_guarantor_id: applicant.id,
                ...ocrResponse,
              })
            );
            dispatch(
              onCaseDetail({ caseId: fk_case_id }, (response) => {
                dispatch(
                  onUpdateNewCreatedCase(response, () => {
                    if (
                      makeEditable
                      // &&(applicant_type === "Co Applicant" ||
                      //   applicant_type === "Guarantor")
                    ) {
                      makeEditable(applicant);
                    }
                    if (handleCancel) {
                      handleCancel();
                    }
                  })
                );
              })
            );
          })
        );
      } else {
        dispatch(
          onCaseDetail({ caseId: fk_case_id }, (response) => {
            dispatch(
              onUpdateNewCreatedCase(response, () => {
                if (
                  makeEditable
                  // &&(applicant_type === "Co Applicant" ||
                  //   applicant_type === "Guarantor")
                ) {
                  makeEditable(applicant);
                }
                if (handleCancel) {
                  handleCancel();
                }
              })
            );
          })
        );
      }
    })
  );
};
export const saveMemberOrDirector = (
  data,
  dispatch,
  closeDirectorMemberModal,
  updateEditCoApplicant,
  onAddNewMemberDirector,
  handleCancel,
  runOcr,
  sendOcrResponse,
) => {
  const formData = new FormData();
  const {
    aadhaar_no,
    fk_case_id,
    applicant_type,
    member_type,
    applicant_coapplicant_guarantor_id,
    ocr_documents,
    non_ocr_documents,
    non_ocr_required_documents,
    member_director_id,
    member_director_name,
    aadhaar_unmasked,
    // first_name,
    // last_name,
  } = data;
  const afterAddMemberDirector = (member_director,ocrResponse)=>{
    dispatch(
      onCaseDetail({ caseId: fk_case_id }, (newCaseDetail) => {
        // let updatedParticipant = {};
        // updatedParticipant.main_applicant = response;
        dispatch(
          onUpdateNewCreatedCase(newCaseDetail, () => {
            if (
              updateEditCoApplicant &&
              (applicant_type === "Co Applicant" ||
                applicant_type === "Guarantor")
            ) {
              updateEditCoApplicant({
                id: applicant_coapplicant_guarantor_id,
              });
            } else if (updateEditCoApplicant) {
              updateEditCoApplicant({
                id: applicant_coapplicant_guarantor_id,
              });
            }
            if (onAddNewMemberDirector) {
              //alert('onAddMemberOrDirector')
              onAddNewMemberDirector(member_director);
            }
            if (handleCancel) {
              handleCancel();
            }
            
            if(false){
              let res = {
                ocr_success: ocrResponse.result,
                fk_category_id:'1',
                applicant_type,
                applicant_coapplicant_guarantor_id: applicant_coapplicant_guarantor_id.id,
                ...ocrResponse,
              };
              sendOcrResponse(res)
            }else{
              if (closeDirectorMemberModal) {
                closeDirectorMemberModal();
              }
            }
          })
        );
      })
    );
  }
  formData.append("fk_case_id", fk_case_id);
  formData.append("applicant_type", applicant_type);
  formData.append("member_type", member_type);
  // formData.append("aadhaar_no", aadhaar_no);
  formData.append("aadhaar_unmasked", aadhaar_unmasked?aadhaar_unmasked:'');
  formData.append(
    "applicant_coapplicant_guarantor_id",
    applicant_coapplicant_guarantor_id
  );
  formData.append("member_director_id", member_director_id);
  formData.append("member_director_name", member_director_name);
  // formData.append("first_name", first_name);
  // formData.append("last_name", last_name);
  if (Object.keys(ocr_documents).length > 0) {
    Object.keys(ocr_documents).forEach((doc) => {
      if (Array.isArray(ocr_documents[doc]) && ocr_documents[doc].length > 0) {
        if (ocr_documents[doc][0] instanceof File) {
          formData.append(doc, ocr_documents[doc][0]);
        }
      }
    });
  }
  if (Object.keys(non_ocr_required_documents).length > 0) {
    Object.keys(non_ocr_required_documents).forEach((doc) => {
      if (
        Array.isArray(non_ocr_required_documents[doc]) &&
        non_ocr_required_documents[doc].length > 0
      ) {
        if (non_ocr_required_documents[doc][0] instanceof File) {
          formData.append("non_ocr_document_name[]", doc);
          formData.append(
            "non_ocr_document[]",
            non_ocr_required_documents[doc][0]
          );
          formData.append("non_ocr_document_required[]", "1");
        }
      }
    });
  }
  non_ocr_documents.forEach((nonOcrDoc) => {
    if (nonOcrDoc.name && nonOcrDoc.doc.file) {
      formData.append("non_ocr_document_name[]", nonOcrDoc.name);
      formData.append("non_ocr_document[]", nonOcrDoc.doc.file);
      formData.append("non_ocr_document_required[]", "0");
    }
  });
  dispatch(
    onAddMemberOrDirector(formData, (member_director) => {
      if (false) {
        let data = {
          fk_case_id: member_director.fk_case_id,
          applicant_type: member_director.applicant_type,
          applicant_coapplicant_guarantor_id:
            applicant_coapplicant_guarantor_id,
          member_director_id: member_director.id,
        };
        // setRunOcr(true);
        dispatch(onRunOcr(data, (ocrResponse) => {
          afterAddMemberDirector(member_director,ocrResponse);
        }));
      }else{
        afterAddMemberDirector(member_director)
      }
      
    })
  );
};
export const runMemberDirectorOcr = (
  member_director,
  dispatch,
  applicant,
  onAddNewMemberDirector
) => {
  let data = {
    fk_case_id: member_director.fk_case_id,
    applicant_type: member_director.applicant_type,
    applicant_coapplicant_guarantor_id: applicant.id,
    member_director_id: member_director.id,
  };
  // setRunOcr(true);
  dispatch(
    onRunOcr(data, (response) => {
      if (onAddNewMemberDirector) {
        onAddNewMemberDirector(member_director);
      }
      dispatch(
        onRunOcrResponse({
          ocr_success: response.result,
          fk_category_id: "1",
          applicant_type: member_director.applicant_type,
          isMemberOrDirector: member_director.member_type,
          applicant_coapplicant_guarantor_id: applicant.id,
          ...response,
        })
      );
    })
  );
};
