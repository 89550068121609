import {
    Button, Input, Modal, Select
} from "antd";
import React from "react";

const ConfirmationModal = (props) => {
  const { isModalVisible, title } = props;
  return (
    <>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        className="JustReqWrap"
        footer={[
          <Button onClick={props.handleCancel}>
            No
          </Button>,
          <Button onClick={props.handleOk}>
            Yes
          </Button>,
        ]}
        style={{ top: 90 }}
        width={600}
        destroyOnClose={true}
      >
      </Modal>
    </>
  );
};

export default ConfirmationModal;
