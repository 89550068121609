import React, { useEffect } from "react";
import { Col, Row, Button, Table, Tabs } from "antd";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import TabPane from "antd/lib/tabs/TabPane";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getVahanRequestDetails, getVahanRequestDetailsByid } from "../../redux/actions/VaahanAction";
import HpaRequest from "./vaahanRequestDetails/HPA/HpaRequest";
import { onChangeFirstTabKey, onChangeSecondTabKey, onChangeThirdTabKey } from "../../redux/actions/TabAction";
import HpcRequest from "./vaahanRequestDetails/HPC/HpcRequest";
import HptRequest from "./vaahanRequestDetails/HPT/HptRequest";

export default function VaahanRequestView() {
  const parms = useParams();
  const id = parms.id;
  const [requestData, setRequestData] = useState([]);
  const firstTabKey = useSelector((state) => state.tabReducer.firstTabKey);
  const dispatch = useDispatch();


  const onSuccess = (response) => {
    const resData = response?.data?.data;
    setRequestData(resData);
  };
  const loadCaseDetails = () => {
  dispatch(getVahanRequestDetailsByid(id,onSuccess))
  };
  useEffect(() => {
    loadCaseDetails();
  }, []);
  
  const firstTabChange = (tab) => {
    dispatch(onChangeFirstTabKey(tab));
    dispatch(onChangeSecondTabKey("1"));
    dispatch(onChangeThirdTabKey("1"));
  };

  return (
    <>
      <div className="GeneralTabsWrap hunterTabs">
        <div className="CaseDetailHeader">
          <div className="InnerWrap">
            <Row>
              <Col span={4} xs={24} sm={24} md={16}>
                <p className="CaseId mx-4">
                  <span>
                    <span>Vahan Request ID -{requestData?.vahan_request_id}</span>
                  </span>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </div>

      <div className="FieldTabsWrap FieldDetailTabs">
        <Tabs activekey={firstTabKey} onChange={firstTabChange}>
          <TabPane tab="HPA" key="1">
            <HpaRequest  type={"HPA"}/>
          </TabPane>
          <TabPane tab="HPC" key="2">
          <HpcRequest  type={"HPA"}/>
           
          </TabPane>
          <TabPane tab="HPT" key="3">
          <HptRequest type={"HPA"}/>
            
          </TabPane>
        </Tabs>
      </div>
    </>
  );
}
