import {
  SET_SELECTED_CATEGORY,
  SET_SELECTED_SUB_CATEGORY,
} from "../constants/ActionTypes";

/**
 * @method onChangeSelectedCategory
 * @description selected category for add ocr document
 * @param {*}
 * @returns
 */
export const onChangeSelectedCategory = (selectedCategory) => {
  return {
    type: SET_SELECTED_CATEGORY,
    data: selectedCategory,
  };
};
/**
 * @method onChangeSelectedCategory
 * @description selected category for add ocr document
 * @param {*}
 * @returns
 */
export const onChangeSelectedSubCategory = (selectedSubCategory) => {
  return {
    type: SET_SELECTED_SUB_CATEGORY,
    data: selectedSubCategory,
  };
};
