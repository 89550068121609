import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  addMemberOrDirectorAPI,
  deleteMemberOrDirectorAPI,
  getMemberDirectorDetailAPI,
  getMemberDirectorListAPI
} from "../apis/Api";
import { toast } from "react-toastify";
import {
  HIDE_LOADING,
  SHOW_LOADING,
  ADD_MEMBER_DIRECTOR,
  DELETE_MEMBER_DIRECTOR,
  GET_MEMBER_DIRECTOR_DETAIL,
  GET_MEMBER_DIRECTOR_LIST,
} from "../constants/ActionTypes";
import { errorMessage, successMessage } from "../../helpers/utils";

function* addMemberOrDirector(action) {
  for (var pair of action.data.entries()) {
    
  }
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(addMemberOrDirectorAPI, action.data);
    yield put({ type: HIDE_LOADING });
    if (response.data) {
      toast.success(response.data.message)
        action.onSuccess(response.data.data);
    }
    // yield put({type: CREDIT_PENDING_CASES_SUCCESS, pendingCases: response.data.data});
  } catch (error) {
    
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* deleteMemberOrDirector(action) {
    
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(deleteMemberOrDirectorAPI, action.id);
    yield put({ type: HIDE_LOADING });
    if (response) {
        successMessage(response.message);
      action.onSuccess();
    }
    action.onSuccess()
    // yield put({type: CREDIT_PENDING_CASES_SUCCESS, pendingCases: response.data.data});
  } catch (error) {
    
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* getMemberDirectorDetail(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getMemberDirectorDetailAPI, action.id);
    
    yield put({ type: HIDE_LOADING });
    if (response.data) {
        action.onSuccess(response.data.data);
    }
    // yield put({type: CREDIT_PENDING_CASES_SUCCESS, pendingCases: response.data.data});
  } catch (error) {
    
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}
function* getMemberDirectorList(action) {
  try {
    yield put({ type: SHOW_LOADING });
    const response = yield call(getMemberDirectorListAPI, action.data);
    
    yield put({ type: HIDE_LOADING });
    if (response.data) {
        action.onSuccess(response.data.data);
    }
    // yield put({type: CREDIT_PENDING_CASES_SUCCESS, pendingCases: response.data.data});
  } catch (error) {
    
    errorMessage(error);
    yield put({ type: HIDE_LOADING });
  }
}

function* actionAddMemberOrDirector() {
  yield takeLatest(ADD_MEMBER_DIRECTOR, addMemberOrDirector);
}
function* actionDeleteMemberOrDirector() {
  yield takeLatest(DELETE_MEMBER_DIRECTOR, deleteMemberOrDirector);
}
function* actionGetMemberDirectorDetail(){
  yield takeLatest(GET_MEMBER_DIRECTOR_DETAIL, getMemberDirectorDetail);
  
}
function* actionGetMemberDirectorList(){
  yield takeLatest(GET_MEMBER_DIRECTOR_LIST, getMemberDirectorList);
  
}

export default function* authSaga() {
  yield all([
    fork(actionAddMemberOrDirector),
    fork(actionDeleteMemberOrDirector),
    fork(actionGetMemberDirectorDetail),
    fork(actionGetMemberDirectorList),
  ]);
}
