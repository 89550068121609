import { Alert, Button, Collapse, Modal } from "antd";
import React, { useEffect } from "react";
import CheckIcon from "../../../assets/images/completedcheck.png";
import InvalidIcon from "../../../assets/images/invalid.png";
import { OCR_DOCUMENT_LABEL } from "../../../constants/constants";
const { Panel } = Collapse;
const OCRResultModal = (props) => {
  const { ocrResponse,handleOk ,isVisible} = props;
  
  useEffect(() => {
    if (ocrResponse.ocr_success && Array.isArray(ocrResponse.ocr_success)) {
      ocrResponse.ocr_success.forEach((ocrRes) => {
        let status = ocrRes.message === "Success" ? "completed" : "failed";
        
      });
    }
  }, [ocrResponse]);
  const showDocumentDetails = (ocr_res) => {
    if (ocr_res.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
      return (
        <div>
          <table>
            <tr>
              <th>Name</th>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <th>Aadhar Number</th>
              <td>{ocr_res.aadhaar_no}</td>
            </tr>
            <tr>
              <th>Address</th>
              <td>{ocr_res.address}</td>
            </tr>
            {/*<tr>
              <th>State</th>
              <td>{ocr_res.state}</td>
            </tr>
            <tr>
              <th>City</th>
              <td>{ocr_res.city}</td>
            </tr>*/}
            <tr>
              <th>DOB</th>
              <td>{ocr_res.dob}</td>
            </tr>
            {/*<tr>
              <th>Father Name</th>
              <td>{ocr_res.father}</td>
            </tr>*/}
            <tr>
              <th>Gender</th>
              <td>{ocr_res.gender}</td>
            </tr>
            {/*{ocr_res.gender === "FEMALE" && (
              <tr>
                <th>Husband</th>
                <td>{ocr_res.husband ? ocr_res.husband : "N/A"}</td>
              </tr>
            )}*/}
            {/*<tr>
              <th>Mother Name </th>
              <td>{ocr_res.mother}</td>
            </tr>*/}
            <tr>
              <th>PIN Code</th>
              <td>{ocr_res.pin}</td>
            </tr>
          </table>
        </div>
      );
    } else if (ocr_res.document_name === "PAN Card") {
      return (
        <div>
          <table>
            <tr>
              <th>Name</th>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <th>Date of Issue</th>
              <td>{ocr_res.doi}</td>
            </tr>
            <tr>
              <th>DOB</th>
              <td>{ocr_res.dob}</td>
            </tr>
            <tr>
              <th>Father Name</th>
              <td>{ocr_res.father}</td>
            </tr>
            {/*<tr>
              <th>Gender</th>
              <td>{ocr_res.gender}</td>
            </tr>
            {ocr_res.gender === "FEMALE" && (
              <tr>
                <th>Husband</th>
                <td>{ocr_res.husband ? ocr_res.husband : "N/A"}</td>
              </tr>
            )}
            <tr>
              <th>Mother Name </th>
              <td>{ocr_res.mother}</td>
            </tr>*/}
            <tr>
              <th>PAN Number</th>
              <td>{ocr_res.pan_no}</td>
            </tr>
          </table>
        </div>
      );
    } else if (ocr_res.document_name === "Voter ID") {
      return (
        <div>
          <table>
            <tr>
              <th>Voter ID</th>
              <td>{ocr_res.voter_id}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <th>Father Name</th>
              <td>{ocr_res.father}</td>
            </tr>
            <tr>
              <th>Gender</th>
              <td>{ocr_res.gender ? ocr_res.gender : "-"}</td>
            </tr>
          </table>
        </div>
      );
    } else if (ocr_res.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE) {
      return (
        <div>
          <table>
            <tr>
              <th>Driving License No</th>
              <td>{ocr_res.dl_no}</td>
            </tr>
            <tr>
              <th>Name</th>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <th>DOB</th>
              <td>{ocr_res.dob}</td>
            </tr>
          </table>
        </div>
      );
    } else if (ocr_res.document_name === OCR_DOCUMENT_LABEL.PASSPORT) {
      return (
        <div>
          <table>
            <tr>
              <th>Name</th>
              <td>{ocr_res.name}</td>
            </tr>
            <tr>
              <th>Passport Number</th>
              <td>{ocr_res.passport_no}</td>
            </tr>
            <tr>
              <th>DOB</th>
              <td>{ocr_res.dob}</td>
            </tr>
            <tr>
              <th>Gender</th>
              <td>{ocr_res.gender ? ocr_res.gender : "-"}</td>
            </tr>
            <tr>
              <th>Date of Issue</th>
              <td>{ocr_res.doi ? ocr_res.doi : "-"}</td>
            </tr>
            <tr>
              <th>Date of Expiry</th>
              <td>{ocr_res.doe ? ocr_res.doe : "-"}</td>
            </tr>
          </table>
        </div>
      );
    }
  };
  return (
    <>
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={isVisible && ocrResponse}
        // visible={true}
        onOk={handleOk}
        onCancel={handleOk}
        footer={[
          <Button className="JustReqWrap" type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
        destroyOnClose={true}
      >
        <div className="CollapseWrap">
          <h3 className="heading">
            OCR Result{" "}
            <pre>
              <div>
                <img src={CheckIcon} alt="valid" /> OCR Success {"  "}{" "}
                <img src={InvalidIcon} alt="valid" /> OCR Failed
              </div>
            </pre>
          </h3>
          <Collapse accordion defaultActiveKey={1}>
            <Panel
              header={
                ocrResponse && (ocrResponse.isMemberOrDirector
                  ? ocrResponse.isMemberOrDirector
                  : ocrResponse.applicant_type)
              }
              key="1"
            >
              <ul>
                {ocrResponse &&
                  ocrResponse.result &&
                  Array.isArray(ocrResponse.result) &&
                  ocrResponse.result.map((ocr_result, index) => {
                    return (
                      <li
                        className={
                          ocr_result.message === "Success"
                            ? "completed"
                            : "failed"
                        }
                        key={index.toString()}
                      >
                        {ocr_result.type}
                      </li>
                    );
                  })}
                {/*<li className="completed">Email Authentication</li>
<li className="loading">Mobile No Authentication</li>*/}
              </ul>
            </Panel>
          </Collapse>
          {ocrResponse &&
            ocrResponse.result &&
            Array.isArray(ocrResponse.result) &&
            ocrResponse.result.map((ocr_result, index) => {
              return (
                ocr_result.error && (
                  <Alert
                    message={`${ocr_result.type} Error`}
                    description={ocr_result.error}
                    type="error"
                    key={index.toString()}
                  />
                )
              );
            })}
          <Collapse accordion className="mt-2">
            {ocrResponse &&
              ocrResponse.user_ocr_result &&
              Array.isArray(ocrResponse.user_ocr_result) &&
              ocrResponse.user_ocr_result.map((ocr_res, index) => {
                return (
                  <Panel
                    header={`View ${
                      ocr_res.document_name
                        ? ocr_res.document_name === "PAN Card"
                          ? "PAN Card"
                          : ocr_res.document_name
                        : ""
                    } Details`}
                    key={index.toString()}
                  >
                    {showDocumentDetails(ocr_res)}
                  </Panel>
                );
              })}
          </Collapse>
        </div>
      </Modal>
    </>
  );
};
export default OCRResultModal;
