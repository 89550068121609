import {all} from 'redux-saga/effects';
import authSaga from "./authSaga";
import fleetAnalysisSaga from "./fleetAnalysisSaga";

import pendingCasesSaga from "./pendingCasesSaga";
import caseSaga from "./caseSaga";
import memberAndDirectorSaga from "./memberAndDirectorSaga";
import creditUserSaga from "./creditUserSaga";
import bureauReportSaga from "./bureauReportSaga";
import participantSaga from "./participantSaga";
import notificationSaga from "./notificationSaga";
import hunterSaga from "./hunterSaga";
import fieldInvestigationSaga from './fieldInvestigationSaga'
import vaahanSaga from './vaahanSaga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        pendingCasesSaga(),
        caseSaga(),
        memberAndDirectorSaga(),
        bureauReportSaga(),
        creditUserSaga(),
        participantSaga(),
        notificationSaga(),
        fleetAnalysisSaga(),
        hunterSaga(),
        fieldInvestigationSaga(),
        vaahanSaga(),

        
    ]);
}
