import React, { useCallback, useEffect, useState } from "react";
import {  Form, Row, Col, Select, Upload, Button, Input } from "antd";
import uplaodIcon from "../../assets/images/uploadfile.png";
// import NonOcrDocuments from "../../common/NonOcrDocuments";
import Modal from "antd/lib/modal/Modal";
import { connect, useDispatch } from "react-redux";
import { REQUIRED_MESSAGES } from "../../constants/messages";
// import { onAddParticipant, onDeleteNonOcrDocument, onRunOcr, onUpdateNewCreatedCase } from "../../redux/actions/AuthAction";
// import RunOcr from "../modals/runSingleOcr";
import RunOcr from "./modals/RunOcr";
import { OCR_DOCUMENT_LABEL, RELATIONSHIPS } from "../../constants/constants";
// import CoApplicantList from "../../common/co-applicant/CoApplicantList";
// import GuarantorList from "../../common/guarantor/Guarantor";
import { toast } from "react-toastify";
import UploadNonOcrDocuments from "./UploadNonOcrDocuments";
import { checkFileSizeOneMB, checkFileTypeImageAndPdf } from "../../helpers/utils";
import { onAddParticipant } from "../../redux/actions/ParticipantAction";
import { onRunOcr } from "../../redux/actions/OcrAction";
import { onUpdateNewCreatedCase } from "../../redux/actions/CaseAction";
import { onDeleteNonOcrDocument } from "../../redux/actions/NonOcrAction";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;
const AddNewParticipant = (props) => {
  const { caseMasters,caseDetail,newlyCreatedCase,currentCase} = props;
  const [runOcr, setRunOcr] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [relationship, setRelationship] = useState("");
  const [form] = Form.useForm();
  const [fileList, setFileList] = useState({
    pan_card: [],
  });
  const [isNext, setIsNext] = useState(false);
  const [nonOcrDocuments, setNonOcrDocuments] = useState([]);
  const [ocrResponse, setOcrResponse] = useState([]);
  const [defaultFileList, setDefaultFileList] = useState({
    pan_card: [],
    aadhar_card: [],
    driving_license: [],
    passport: [],
    voter_id: [],
  });
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [showForm,setShowForm] = useState(true);
  const [isEdit,setIsEdit] = useState(false);
  const [userId,setUserId] = useState('')
  const dispatch = useDispatch();
  
  const updateDefaultOcrDocs = (ocr_documents)=>{
    let defaultFiles = {};
    ocr_documents.forEach((ocr_document) => {
      if (ocr_document.document_name === OCR_DOCUMENT_LABEL.PAN_CARD) {
        defaultFiles = {
          ...defaultFiles,
          pan_card: [
            {
              uid: "1",
              name: "pan_card.png",
              status: "done",
              // response: 'Server Error 500', // custom error message to show
              url: ocr_document.document_path,
            },
          ],
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
        defaultFiles = {
          ...defaultFiles,
          aadhar_card: [
            {
              uid: "2",
              name: "aadhar_card.png",
              status: "done",
              url: ocr_document.document_path,
            },
          ],
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.DRIVING_LICENSE) {
        defaultFiles = {
          ...defaultFiles,
          driving_license: [
            {
              uid: "3",
              name: "driving_license.png",
              status: "done",
              url: ocr_document.document_path,
            },
          ],
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.PASSPORT) {
        defaultFiles = {
          ...defaultFiles,
          passport: [
            {
              uid: "4",
              name: "passport.png",
              status: "done",
              url: ocr_document.document_path,
            },
          ],
        };
      } else if (ocr_document.document_name === OCR_DOCUMENT_LABEL.VOTER_ID) {
        defaultFiles = {
          ...defaultFiles,
          voter_id: [
            {
              uid: "5",
              name: "voter_id.png",
              status: "done",
              url: ocr_document.document_path,
            },
          ],
        };
      }
    });
    setDefaultFileList((defaultFileList) => {
      return { ...defaultFileList, ...defaultFiles };
    });
    setFileList((defaultFileList) => {
      return { ...defaultFileList, ...defaultFiles };
    });
  }
  const updatedDefaultNonOcrDocs = (non_ocr_documents)=>{
    let nonOcrDocs = [];
    non_ocr_documents.forEach((non_ocr_document)=>{
      nonOcrDocs = [
        ...nonOcrDocs,
        {
          name: non_ocr_document.document_name,
          doc: non_ocr_document.document_path,
          id:non_ocr_document.id,
        }
      ]
    });
    setNonOcrDocuments(nonOcrDocs)
  }
  useEffect(() => {
    if (props.type === "Main Applicant") {
      if (currentCase && currentCase.main_applicant && currentCase.main_applicant.ocr_documents && currentCase.main_applicant.ocr_documents.length > 0) {
        updateDefaultOcrDocs(currentCase.main_applicant.ocr_documents)
      }
      if (currentCase && currentCase.main_applicant && currentCase.main_applicant.non_ocr_documents && currentCase.main_applicant.non_ocr_documents.length > 0) {
        updatedDefaultNonOcrDocs(currentCase.main_applicant.non_ocr_documents)
      }
    }else if(props.type === "Co Applicant"){
      if (currentCase && currentCase.co_applicants && currentCase.co_applicants.length>0 ) {
        let co_applicant = currentCase.co_applicants.find((co_applicant)=>{
          return co_applicant.id==userId
        });
        if(co_applicant){
          updatedDefaultNonOcrDocs(co_applicant.non_ocr_documents)
        }
      }
    }else if(props.type === "Guarantor"){
      if (currentCase && currentCase.guarantors && currentCase.guarantors.length>0 ) {
        let guarantor = currentCase.guarantors.find((guarantor)=>{
          return guarantor.id==userId
        });
        if(guarantor){
          updatedDefaultNonOcrDocs(guarantor.non_ocr_documents)
        }
      }
    }
}, [ props.type,props.isNewCase,currentCase,userId]);
  const changeSubCategories = useCallback(
    (val, cb) => {
      if (
        caseMasters.subcategories &&
        Array.isArray(caseMasters.subcategories) &&
        caseMasters.subcategories.length > 0
      ) {
        const filteredSubCategories = caseMasters.subcategories.filter(
          (subCategory) => {
            return subCategory.fk_category_id == val;
          }
        );
        cb(filteredSubCategories);
      }
    },
    [caseMasters.subcategories]
  );
  useEffect(() => {
    if (
      caseMasters.categories &&
      Array.isArray(caseMasters.categories) &&
      caseMasters.categories.length > 0
    ) {
      if (props.type === "Co Applicant" || props.type === "Guarantor") {
        changeSubCategories(
          caseMasters.categories[0].id,
          (filteredSubCategories) => {
            setSubCategories(filteredSubCategories);
          }
        );
        form.setFieldsValue({
          applicant_category: (caseMasters.categories[0].id).toString(),
        });
      }
    }
  }, [caseMasters.categories, form, props.type, changeSubCategories]);
  // useEffect(()=>{
  //   if(props.modalType && props.modalType==='upload_document'){
  //     form.resetFields();
  //     setDefaultFileList([]);
  //     setNonOcrDocuments([]);
  //     setFileList([])
  //   }
  // },[props.modalType,form])
 
  const KycCheck = (runOcr) => {
    setRunOcr(runOcr);
  };
  const handleFormValuesChange = (changedValues) => {
    const formFieldName = Object.keys(changedValues)[0];
    if (formFieldName === "applicant_category") {
      form.setFieldsValue({ application_sub_category: undefined });
    } else if (formFieldName === "business_area") {
      form.setFieldsValue({ product_name: undefined });
    } else if (formFieldName === "product_name") {
      form.setFieldsValue({ product_model: undefined });
    }
  };
  useEffect(()=>{
    if(currentCase && props.mode==='upload_main_applicant_document' && props.type==='Main Applicant'){
      form.setFieldsValue({
        applicant_category:currentCase.fk_category_id.toString()
      })
      changeSubCategories(currentCase.fk_category_id, (filteredSubCategories) => {
        setSubCategories(filteredSubCategories);
        form.setFieldsValue({
          application_sub_category:filteredSubCategories.length>0?currentCase.fk_subcategory_id.toString():undefined
        })
      });
    }
  },[currentCase,props.mode,changeSubCategories,form,props.type])
  const beforeUpload = (file, list, docName) => {
    if (checkFileTypeImageAndPdf(file)) {
      if(checkFileSizeOneMB(file)){
        setFileList({
          ...fileList,
          [docName]: file,
        });
        setDefaultFileList({
          ...defaultFileList,
          [docName]: list,
        });
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const onRemoveOcrDoc = (fileName) => {
    setDefaultFileList({
      ...defaultFileList,
      [fileName]: [],
    });
  };
  const addNonOcrDocuments = (nonOcrDoc) => {
    const newDoc = form.getFieldValue("DocName");
    form.setFields([
      {
        name: "DocName",
        errors: [],
      },
    ]);
    if (nonOcrDocuments.find((doc) => doc.name === newDoc)) {
      form.setFields([
        {
          name: "DocName",
          errors: ["The document with this name is already added."],
        },
      ]);
      return;
    }
    if (!newDoc) {
      form.setFields([
        {
          name: "DocName",
          errors: ["Please enter doc name"],
        },
      ]);
      return;
    }
    if (!form.getFieldValue("non_ocr_doc_pic")) {
      form.setFields([
        {
          name: "non_ocr_doc_pic",
          errors: ["Please upload document"],
        },
      ]);
      return;
    }
    setNonOcrDocuments(
      nonOcrDocuments.concat({
        name: form.getFieldValue("DocName"),
        doc: form.getFieldValue("non_ocr_doc_pic"),
      })
    );
    form.resetFields(["DocName", "non_ocr_doc_pic"]);
  };
  const removeNonOcrDocuments = (deleteIndex) => {
    let docs = nonOcrDocuments.filter((doc, index) => {
      return index !== deleteIndex;
    });
    setNonOcrDocuments(docs);
  };
  const deleteNonOcrDocumentFromBackEnd = (deleteIndex) => {
    dispatch(onDeleteNonOcrDocument({ id: deleteIndex }, () => {}));
    if(props.type==='Main Applicant'){
      let updatedNonOcrDocs = newlyCreatedCase.main_applicant.non_ocr_documents.filter((non_ocr_doc)=>{
        return non_ocr_doc.id !== deleteIndex
      });
      let newlyCreatedCaseObj = {
        ...newlyCreatedCase,
        main_applicant:{
          ...newlyCreatedCase.main_applicant,
          non_ocr_documents:updatedNonOcrDocs
        }
      }
      updateDefaultOcrDocs(updatedNonOcrDocs)
      dispatch(onUpdateNewCreatedCase(newlyCreatedCaseObj,()=>{}))
    }else if(props.type==='Co Applicant'){
      let co_applicants = newlyCreatedCase.co_applicants.filter((co_applicant)=>co_applicant.id != userId)
      let co_applicant = newlyCreatedCase.co_applicants.find((co_applicant)=>co_applicant.id==userId);
      let updatedNonOcrDocs = co_applicant.non_ocr_documents.filter((non_ocr_doc)=>{
        return non_ocr_doc.id !== deleteIndex
      });
      co_applicant.non_ocr_documents=updatedNonOcrDocs;
      let newlyCreatedCaseObj = {
        ...newlyCreatedCase,
        co_applicants:[
          // ...newlyCreatedCase.co_applicants,
          ...co_applicants,
          co_applicant,
        ]
      }
      updateDefaultOcrDocs(updatedNonOcrDocs)
      dispatch(onUpdateNewCreatedCase(newlyCreatedCaseObj,()=>{}))
    }else if(props.type==='Guarantor'){
      let guarantors = newlyCreatedCase.guarantors.filter((guarantor)=>guarantor.id != userId)
      let guarantor = newlyCreatedCase.guarantors.find((guarantor)=>guarantor.id==userId);
      let updatedNonOcrDocs = guarantor.non_ocr_documents.filter((non_ocr_doc)=>{
        return non_ocr_doc.id !== deleteIndex
      });
      guarantor.non_ocr_documents=updatedNonOcrDocs;
      let newlyCreatedCaseObj = {
        ...newlyCreatedCase,
        guarantors:[
          // ...newlyCreatedCase.guarantors,
          ...guarantors,
          guarantor,
        ]
      }
      updateDefaultOcrDocs(updatedNonOcrDocs)
      dispatch(onUpdateNewCreatedCase(newlyCreatedCaseObj,()=>{}))
    }
  };
  const checkPanCardValidity = ()=>{
    if(!defaultFileList.pan_card || !Array.isArray(defaultFileList.pan_card) ||defaultFileList.pan_card.length === 0 ){
      form.setFields([
        {
          name: "pan_card",
          errors: [REQUIRED_MESSAGES.PAN_CARD_REQUIRED],
        },
      ]);
      return true;
    }
  }
  const checkAadharCardValidity = ()=>{
    if(!defaultFileList.aadhar_card || !Array.isArray(defaultFileList.aadhar_card) ||defaultFileList.aadhar_card.length === 0 ){
      form.setFields([
        {
          name: "aadhar_card",
          errors: [REQUIRED_MESSAGES.ADHAR_CARD_REQUIRED],
        },
      ]);
      return true;
    }
  }
  const onFinish = (values) => {
    const isPanCardInvalid = checkPanCardValidity();
    const isAadharCardInvalid = checkAadharCardValidity();
    if(isPanCardInvalid|| isAadharCardInvalid){
      return;
    }
    const formData = new FormData();
    formData.append("fk_case_id", currentCase.id); //77
    formData.append("applicant_type", props.type);
    
    if (props.type === "Main Applicant") {
      formData.append("user_id", currentCase.main_applicant.id);
      if(props.isNewCase){
        formData.append("fk_category_id", currentCase.fk_category_id);
        formData.append("fk_subcategory_id", (currentCase.fk_subcategory_id && currentCase.fk_subcategory_id!=0)?currentCase.fk_subcategory_id:'');
      }
    } else {
      formData.append("fk_category_id", values.applicant_category);
      formData.append("fk_subcategory_id", values.application_sub_category?values.application_sub_category:'');
      formData.append("user_id", userId);
    }
    if (props.type === "Co Applicant") {
      formData.append("coapplicant_applicant_relation", values.relationship);
      formData.append("other_relation", values.other_relation?values.other_relation:'');
    }
    
    
    if (Object.keys(fileList).length > 0) {
      Object.keys(fileList).forEach((doc) => {
        if(Array.isArray(fileList[doc])){
        }else{
          formData.append(doc, fileList[doc]);
        }
      });
    }
    nonOcrDocuments.forEach((nonOcrDoc) => {
      if(nonOcrDoc.name && nonOcrDoc.doc.file){
        formData.append("non_ocr_document_name[]", nonOcrDoc.name);
        formData.append("non_ocr_document[]", nonOcrDoc.doc.file);
      }
    });
    dispatch(
      onAddParticipant(formData, (response) => {
        if (isNext) {
          let data = {
            fk_case_id: props.caseId,
            applicant_type: props.type,
            applicant_coapplicant_guarantor_id: response.id,
          };
          setRunOcr(true);
          dispatch(
            onRunOcr(data, (response) => {
              setOcrResponse(response);
            })
          );
          setIsNext(false);
        }
        if(props.isNewCase){
          let updatedParticipant = {};
          if(props.type==='Main Applicant'){
            updatedParticipant.main_applicant = response;
          }else{
            if(props.type==='Co Applicant'){
              let isAdded = newlyCreatedCase.co_applicants.find((co_applicant)=>co_applicant.id === response.id)
              if(isAdded){
                updatedParticipant.co_applicants = newlyCreatedCase.co_applicants.map((co_applicant)=>{
                  if(co_applicant.id === response.id){
                    return response;
                  }else return co_applicant;
                })
              }else{
                updatedParticipant.co_applicants = [
                  ...newlyCreatedCase.co_applicants,
                  response
                ]
              }
              
            }else{
              let isAdded = newlyCreatedCase.guarantors.find((guarantor)=>guarantor.id === response.id)
              if(isAdded){
                updatedParticipant.guarantors = newlyCreatedCase.guarantors.map((guarantor)=>{
                  if(guarantor.id === response.id){
                    return response;
                  }else return guarantor;
                })
              }else{
                updatedParticipant.guarantors = [
                  ...newlyCreatedCase.guarantors,
                  response
                ]
              }
            }
            form.resetFields();
            setDefaultFileList([]);
            // setShowForm(false);
          }
          
          dispatch(onUpdateNewCreatedCase({
            ...newlyCreatedCase,
            ...updatedParticipant,
          },()=>{}))
        }if(props.modalType==='add_new_coapplicant'||props.modalType==='add_new_guarantor'){
          
          setDefaultFileList([]);
          form.resetFields();
          form.setFieldsValue({
            applicant_category:"1"
          })
          props.closeModal();
        }
        if (props.changeUploaded) {
          props.changeUploaded();
        }
      })
    );
  };
  const handlePreview = async (file) => {
    
    if (!file.url && !file.preview) {
      
      var reader = new FileReader();
      var url = reader.readAsDataURL(file);
    
       reader.onloadend = function (e) {
         setPreviewImage(reader.result);
        }.bind(this);
        setPreviewVisible(true)
    }else{
    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    }
  };
  useEffect(()=>{
    if(props.type==='Co Applicant' && newlyCreatedCase){
      if(newlyCreatedCase.co_applicants && newlyCreatedCase.co_applicants.length>0){
        setShowForm(false);
      }
    }else if(props.type==='Guarantor' && newlyCreatedCase){
      if(newlyCreatedCase.guarantors && newlyCreatedCase.guarantors.length>0){
        setShowForm(false);
      }
    }
  },[props.type,newlyCreatedCase]);
  const handleEdit = (applicant)=>{
    
    
    setShowForm(true)
    setIsEdit(true)
    setUserId(applicant.id);
    
    changeSubCategories(applicant.fk_category_id, (filteredSubCategories) => {
      setSubCategories(filteredSubCategories);
    });
    form.setFieldsValue({
      applicant_category:(applicant.fk_category_id).toString(),
      application_sub_category:(applicant.fk_subcategory_id && applicant.fk_subcategory_id!=='undefined')?(applicant.fk_subcategory_id).toString():undefined
    });
    if(props.type==='Co Applicant'){
      setRelationship(applicant.coapplicant_applicant_relation);
      form.setFieldsValue({
        relationship:applicant.coapplicant_applicant_relation,
        other_relation:applicant.other_relation
        // application_sub_category:applicant.fk_subcategory_id
      });
    }
    setDefaultFileList([]);
    setFileList([])
    updateDefaultOcrDocs(applicant.ocr_documents)
  }
  const resetForm = ()=>{
    setShowForm(true);
    setUserId('');
    setDefaultFileList([]);
    setFileList([])
    form.resetFields();
    form.setFieldsValue({
      applicant_category:'1'
    });
    updatedDefaultNonOcrDocs([])
  }
  const documents = [
    {
      name:'pan_card',
      label:OCR_DOCUMENT_LABEL.PAN_CARD
    },{
      name:'driving_license',
      label:OCR_DOCUMENT_LABEL.DRIVING_LICENSE
    },{
      name:'aadhar_card',
      label:OCR_DOCUMENT_LABEL.AADHAR_CARD
    },{
      name:'voter_id',
      label:OCR_DOCUMENT_LABEL.VOTER_ID
    },{
      name:'passport',
      label:OCR_DOCUMENT_LABEL.PASSPORT
    }];
  return (
    <>
      <Form
        {...layout}
        name="mainapplicant"
        form={form}
        onValuesChange={handleFormValuesChange}
        onFinish={onFinish}
      >
        <Row gutter={32}>
          {(props.type === "Co Applicant" ||
            props.type === "Guarantor") && (
            <React.Fragment>
              <Col span={8} md={8} sm={12} xs={24}>
                <Form.Item
                  label={props.type + " type"}
                  name="applicant_category"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.APPLICATION_CATEGORY_REQUIRED,
                    },
                  ]}
                  className="TopTabBox"
                >
                  <Select
                    // defaultValue={caseDetail && props.type==='Main Applicant' && (caseDetail.fk_category_id).toString()}
                    // value={applicationCategory}
                    onSelect={(val) => {
                      changeSubCategories(val, (filteredSubCategories) => {
                        setSubCategories(filteredSubCategories);
                      });
                      form.setFields([
                        {
                          name: "application_sub_category",
                          errors: [],
                        },
                      ]);
                    }}
                  >
                    {caseMasters.categories && caseMasters.categories.map((category, index) =>{
                      
                      return (
                        <Option value={(category.id).toString()} key={category.id}>
                          {category.category_name}
                        </Option>
                      )
                    } )}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8} md={8} sm={12} xs={24}>
                <Form.Item
                  label="Sub Category"
                  name="application_sub_category"
                  rules={[
                    {
                      required: subCategories.length !== 0,
                      message:
                        REQUIRED_MESSAGES.APPLICATION_SUB_CATEGORY_REQUIRED,
                    },
                  ]}
                  className="TopTabBox"
                >
                  <Select
                    placeholder={"Please select sub category"}
                    disabled={subCategories.length === 0}
                    // defaultValue={caseDetail && props.type==='Main Applicant'}
                  >
                    {subCategories.map((subCategory) => (
                      <Option value={(subCategory.id).toString()} key={subCategory.id.toString()}>
                        {subCategory.subcategory_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </React.Fragment>
          )}
          {props.type === "Co Applicant" && (
            <React.Fragment>
              <Col span={8} md={8} sm={12} xs={24}>
                <Form.Item
                  label="Relationship with applicant"
                  name="relationship"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.RELATIONSHIP_REQUIRED,
                    },
                  ]}
                  className="TopTabBox"
                >
                  <Select
                    placeholder={"Relationship with applicant"}
                    onSelect={(val) => {
                      setRelationship(val);
                    }}
                  >
                    {RELATIONSHIPS.map((relation)=>
                      <Option value={relation} key={relation}>{relation}</Option>
                    )}
                  </Select>
                </Form.Item>
              </Col>
              
                  {relationship === "Other" && (
                    <Col span={8} md={8} sm={12} xs={24}>
                  <Form.Item label="Other" name='other_relation' rules={[
                    {
                      required: true,
                      message:
                        REQUIRED_MESSAGES.OTHER_RELATION_REQUIRED,
                    },
                  ]} >
                    <Input placeholder="relationship" />
                  </Form.Item></Col>
              )}
                
              
            </React.Fragment>
          )}
          <Col span={24} md={24} xs={24} sm={24}>
            <h4>OCR Compatible Documents</h4>
          </Col>
          {documents.map((document)=>{
            return (
              <Col span={12} md={12} xs={24} sm={24}>
                <Form.Item label={document.label} name={document.name}>
                  <Upload
                    maxCount={1}
                    fileList={defaultFileList[document.name]}
                    beforeUpload={(file, fileList, e) => {
                      form.setFields([
                        {
                          name: document.name,
                          errors: [],
                        },
                      ]);
                      beforeUpload(file, fileList, document.name);
                      return false;
                    }}
                    name={document.name}
                    onRemove={() => {
                      onRemoveOcrDoc(document.name);
                    }}
                    onPreview={handlePreview}
                    showUploadList={{
                      showRemoveIcon: false,
                    }}
                  >
                    <Button>
                      Upload <img src={uplaodIcon} alt="uplaodIcon" />
                    </Button>
                  </Upload>
                </Form.Item>
              </Col>
            );
          })}
        </Row>
        <UploadNonOcrDocuments
          form={form}
          addNonOcrDocuments={addNonOcrDocuments}
          removeNonOcrDocuments={removeNonOcrDocuments}
          nonOcrDocuments={nonOcrDocuments}
          handlePreview={handlePreview}
          deleteNonOcrDocumentFromBackEnd={deleteNonOcrDocumentFromBackEnd}
        />
      
        <div className="MainBtnWrap">
          {/*<Button className="plane">Cancel</Button>*/}
          {props.type==='Co Applicant' && props.isNewCase && newlyCreatedCase.co_applicants.length>0 && showForm && <Button className="plane" onClick={()=>{
            setShowForm(false);
          }}>Cancel</Button>}
          {props.type==='Guarantor' && props.isNewCase && newlyCreatedCase.guarantors.length>0 && showForm && <Button className="plane" onClick={()=>{
            setShowForm(false);
          }}>Cancel</Button>}
          <Button className="SaveBtn" htmlType="submit" type="primary">
            Save
          </Button>
          <Button
            className="filled"
            onClick={() => {
              setIsNext(true);
              // setRunOcr(true);
            }}
            htmlType="submit"
          >
            Save and Run OCR
          </Button>
        </div>
      </Form>
          
      
      {/* Run KYC */}
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={runOcr}
        onOk={() => KycCheck(false)}
        onCancel={() => KycCheck(false)}
        footer={null}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunOcr ocrResponse={ocrResponse} />
      </Modal>
      <Modal
        visible={previewVisible}
        // title={previewTitle}
        footer={null}
        onCancel={() => {
          setPreviewVisible(false);
        }}
      >
        <img alt="example" style={{ width: "100%" }} src={previewImage} />
      </Modal>

      {/* Run KYC */}
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
    isCaseSaved: auth.isNewlyCreatedCaseSaved,
    savedCase: auth.newlyCreatedCase,
    mainApplicant: auth.mainApplicant,
    coApplicant: auth.coApplicant,
    gurantor: auth.gurantor,
    caseDetail:auth.caseDetail,
    newlyCreatedCase:auth.newlyCreatedCase,
    currentCase:auth.currentCase
  };
};
export default connect(mapStateToProps, null)(AddNewParticipant);
