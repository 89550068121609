import { Button, Tabs, Card, Modal, Form } from "antd";
import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import Crif from "./crif";
import { Link } from "react-router-dom";
import {
  onFetchBureauReportMaster,
  onGenerateBureauReport,
  onChangeSelectedBureauReportView,
  onFetchBureauReportListing,
  onSetBureauReportListing,
  onViewBureauReport,
  onFetchCibilReportMaster,
  onGenerateCibilReport,
  onGetBureauReportCheckParam,
  onFetchBureauReportHtml,
} from "../../../../redux/actions/BureauReportAction";
import {
  onCaseDetail,
  onGetUsercrResult,
} from "../../../../redux/actions/CaseAction";
import moment from "moment";
import {
  onGetCities,
  onGetCibilCities,
} from "../../../../redux/actions/MasterData";
import { onChangeThirdTabKey } from "../../../../redux/actions/TabAction";
import BureauReportForm from "../../../ongoingcaselist/ongoingcasestabs/modals/bureaureport";
import ConfirmModal from "../../../ongoingcaselist/ongoingcasestabs/modals/bureaureport/ConfirmModal";

import { STAGES, STATUS } from "../../../../constants/constants";
import RunParametersModal from "../../../modals/runParametersModal";
import {
  onBureauReportParameters,
  onGetBureauReportListing,
} from "../../../../redux/actions/BureauReportAction";
import {
  isCaseNotClosed,
  isCreditReviewPending,
  isCreditUser,
  isKycDone,
  isNotDropped,
  isOcrDone,
  isSalesUser,
  loggedInUserData,
} from "../../../../helpers/utils";

const { TabPane } = Tabs;
const ReportRunSummary = (props) => {
  const userData = loggedInUserData();
  const [visible, setVisible] = useState(false);
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  const selectedApplicant = useSelector((state) => state.auth.selectedUser);
  const [selectedUser, setSelectedUser] = useState({});
  const [userParameters, setUserParameters] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [bureauSubmitData, setBureauSubmitData] = useState({});
  const [confimMessage, SetConfimMessage] = useState(false);
  const [buttonDisable, SetButtonDisable] = useState(false);

  const secondTabKey = useSelector((state) => state.tabReducer.secondTabKey);
  const beureauReportListing = useSelector(
    (state) => state.bureauReport.beureauReportListing
  );
  const selectedBureauReport = useSelector(
    (state) => state.bureauReport.selectedBureauReport
  );
  const cibilReportMasters = useSelector(
    (state) => state.bureauReport.cibilReportListing
  );
  const [bureauReport, setBureauReport] = useState(false);
  const bureauReportMasters = useSelector(
    (state) => state.bureauReport.bureauReportMasters
  );
  const states = useSelector((state) => state.auth.states);
  const city = useSelector((state) => state.auth.cities);
  const cibilcities = useSelector((state) => state.auth.cibilcities);
  // const userPermissions = useSelector(state => state.auth.userPermissions)

  const ocrresult = useSelector((state) => state.caseReducer.ocrresultData);
  const selectedMemberDirector = useSelector(
    (state) => state.auth.selectedMemberDirector
  );
  const [form] = Form.useForm();

  const [isMemberOrDirector, setIsMemberOrDirector] = useState(false);

  const dispatch = useDispatch();

  const openPDFFILES = (idss) => {
    dispatch(onFetchBureauReportHtml(idss, onSuccessss));
  };

  const onSuccessss = (response) => {
    const newTab = window.open("", "_blank");

    newTab.document.open();

    newTab.document.write(
      '<div style="text-align: right;margin: 20px;"><button id="printpagebutton" style="visibility: visible;color: #fff;color: #fff;border-color: #1890ff;background: #1890ff;text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);user-select: none;touch-action: manipulation;height: 32px;padding: 4px 15px;font-size: 14px;border-radius: 2px;line-height: 1.5715;position: relative;display: inline-block;font-weight: 400;white-space: nowrap;text-align: center;cursor: pointer;" onclick="printPage()">Download</button></div>'
    );
    newTab.document.write(response.response_html);
    newTab.document.write(`<script>
    function printPage() {
      var printButton = document.getElementById("printpagebutton");
      printButton.style.visibility = 'hidden';
      window.print();
      printButton.style.visibility = 'visible';
    }
    </script>`);
    newTab.document.title = response.title;
    newTab.document.close();
  };

  useEffect(() => {
    let ind = false;
    if (selectedUser.category_name === "Individual") {
      ind = true;
    }
    dispatch(onFetchBureauReportMaster(ind));
    dispatch(onFetchCibilReportMaster());

    let data = {
      user_id: selectedUser.id,
      member_type: selectedUser.applicant_type,
      case_id: caseDetail.id,
    };
    dispatch(
      onGetBureauReportListing(data, (bureauList) => {
        //
        // setViewReport(bureauList);
      })
    );
  }, [selectedUser]);
  useEffect(() => {
    //
    // dispatch(onSetBureauReportListing([]));
    dispatch(onChangeSelectedBureauReportView(null));
    if (secondTabKey === "1") {
      setIsMemberOrDirector(false);
      setSelectedUser(selectedApplicant);
    } else {
      setIsMemberOrDirector(true);
      setSelectedUser(selectedMemberDirector);
    }
  }, [secondTabKey, selectedMemberDirector, selectedApplicant, dispatch]);
  useEffect(() => {
    if (selectedUser) {
      let data = {
        user_id: selectedUser.id,
        member_type: isMemberOrDirector
          ? selectedUser.member_type
          : selectedUser.applicant_type,
        case_id: caseDetail.id,
      };
      dispatch(onFetchBureauReportListing(data));
    }
  }, [selectedUser, caseDetail, dispatch, isMemberOrDirector]);
  const changeSelectedBureauReport = (bureau_report_id) => {
    dispatch(
      onViewBureauReport(bureau_report_id, () => {
        document
          .getElementById("bureaureportdetail")
          .scrollIntoView({ behavior: "smooth" });
      })
    );
  };

  const handleCancel = () => {
    setBureauReport(false);
    form.resetFields();
  };
  const openBureauReportForm = () => {
    SetButtonDisable(false);
    let data = {
      applicant_type: selectedApplicant.applicant_type,
      applicant_coapplicant_guarantor_id: selectedApplicant.id,
    };
    if (isMemberOrDirector) {
      data = {
        ...data,
        member_director_id: selectedUser.id,
      };
    }
    dispatch(onGetUsercrResult(data));
    setBureauReport(true);
  };

  const fetchReport = (data) => {
    SetButtonDisable(true);
    data.user_type = selectedUser.member_type
      ? selectedUser.member_type
      : selectedUser.applicant_type;
    if (data.bureau_name == "CIBIL") {
      switch (data.gender) {
        case "MALE":
          data.gender = "1";
          break;
        case "FEMALE":
          data.gender = "2";
          break;
        default:
          data.gender = "3";
      }
    }
    if (data.amount) {
      data.amount = data.amount
        .replaceAll(",", "")
        .replace("&#8377", "")
        .replace(/[^\x00-\x7F]/g, "");
    }
    if (data.date_of_birth) {
      const dob = moment(data.date_of_birth);
      data.date_of_birth = dob.format("DD-MM-YYYY");
    }
    if (data.date_of_registration) {
      const date = moment(data.date_of_registration);
      data.date_of_registration = date.format("DD-MM-YYYY");
    }
    data.case_id = caseDetail.id;
    data.user_id = selectedUser.id;
    data.name_as_mentioned_on = data.applicant_name;

    if (data.state === "" || data.state === undefined) {
      const state = states.find(
        (element) => element.state_name === selectedUser.state
      );
      data.state = state ? state.state_code : data.state;
    }

    setOpenConfirm(false);
    dispatch(onGetBureauReportCheckParam(data, onApiCheckSuccess, onApiError));
  };
  const onApiCheckSuccess = (data, responseData) => {
    setBureauSubmitData(data);
    setOpenConfirm(false);
    if (responseData.status) {
      if (data.bureau_name === "CIBIL") {
        dispatch(onGenerateCibilReport(data, onApiSuccess, onApiError));
      } else {
        dispatch(onGenerateBureauReport(data, onApiSuccess, onApiError));
      }
    } else {
      setOpenConfirm(true);
      SetButtonDisable(false);
      SetConfimMessage(responseData.message);
    }
  };
  const handleConfirmOk = (data) => {
    setOpenConfirm(false);

    if (bureauSubmitData.bureau_name === "CIBIL") {
      dispatch(
        onGenerateCibilReport(bureauSubmitData, onApiSuccess, onApiError)
      );
    } else {
      dispatch(
        onGenerateBureauReport(bureauSubmitData, onApiSuccess, onApiError)
      );
    }
  };

  const handleCancelConfirm = () => {
    setOpenConfirm(false);
  };
  const getCity = (data, bureauname) => {
    if (bureauname === "CIBIL") {
      dispatch(onGetCibilCities(data, onSuccessCity));
    } else {
      dispatch(onGetCities(data, onSuccessCity));
    }
  };

  const onSuccessCity = (data) => {};
  const onApiSuccess = (data) => {
    SetButtonDisable(false);
    dispatch(
      onCaseDetail({ caseId: caseDetail.id }, (updatedCase) => {
        form.resetFields();
        setBureauReport(false);
        // member_type: isMemberOrDirector
        // ? selectedUser.member_type
        // : selectedUser.applicant_type,
        let apidata = {
          user_id: selectedUser.id,
          member_type: isMemberOrDirector
            ? selectedUser.member_type
            : selectedUser.applicant_type,
          case_id: caseDetail.id,
        };
        dispatch(onFetchBureauReportListing(apidata));
        dispatch(onChangeThirdTabKey("3"));
      })
    );
  };
  const onApiError = (data) => {
    SetButtonDisable(false);
    // toast.error(data.message);
  };

  const openparamModal = (id) => {
    setUserParameters([]);
    dispatch(onBureauReportParameters(id, onSuccess));
    setVisible(true);
  };
  const onSuccess = (response) => {
    setUserParameters(response);
  };
  return (
    <React.Fragment>
      <div className="HeaderPanelAction  justify-sm-start">
        {beureauReportListing &&
          Array.isArray(beureauReportListing) &&
          beureauReportListing.length > 0 && <h3>Report Run Summary </h3>}
        {/*<Button>
          <Link to="/BureauReport">
            {(beureauReportListing &&
              Array.isArray(beureauReportListing) &&
              beureauReportListing.length) > 0
              ? "Re-Run Report"
              : "Run Bureau Report"}
            </Link>
        </Button>*/}
        {userData &&
          userData.permissions &&
          userData.permissions.Bureau_Reports &&
          userData.permissions.Bureau_Reports.run &&
          selectedUser.stage &&
          selectedUser.stage !== STAGES.KYC_PENDING &&
          selectedUser.stage !== STAGES.OCR_PENDING &&
          isNotDropped(selectedApplicant.status) &&
          isCaseNotClosed(caseDetail.status) &&
          userData &&
          (isSalesUser(userData) ||
            (isCreditUser(userData) &&
              isCreditReviewPending(caseDetail.status))) && (
            <Button onClick={openBureauReportForm}>
              {(beureauReportListing &&
                Array.isArray(beureauReportListing) &&
                beureauReportListing.length) > 0
                ? "Fetch New Report"
                : "Run Bureau Report"}
            </Button>
          )}
      </div>
      {/* Run Bureao Reports */}
      <Modal
        title="Bureau Report Generation"
        centered
        visible={bureauReport}
        onOk={() => setBureauReport(false)}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        className="BeureauReport"
        style={{ top: 50 }}
        destroyOnClose={true}
      >
        <BureauReportForm
          selectedApplicant={selectedUser}
          fetchReport={fetchReport}
          bureauReportMasters={bureauReportMasters}
          cibilReportMasters={cibilReportMasters}
          states={states}
          form={form}
          cities={city}
          cibilcities={cibilcities}
          caseDetail={caseDetail}
          ocrresult={ocrresult}
          getCity={getCity}
          buttonDisable={buttonDisable}
        />
      </Modal>
      {/* Run Bureao Reports */}

      {/*** Confirmation Modal * */}
      <ConfirmModal
        openConfirm={openConfirm}
        handleCancelConfirm={handleCancelConfirm}
        handleOk={handleConfirmOk}
        confimMessage={confimMessage}
      />

      {beureauReportListing &&
      Array.isArray(beureauReportListing) &&
      beureauReportListing.length > 0 ? (
        <div className="TableWrap">
          <table>
            <thead>
              <tr>
                <th>S.No</th>
                <th>ID</th>
                <th>Bureau Name</th>
                <th>Score</th>
                <th>Report Generated on </th>
                <th>Run Parameters</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {beureauReportListing.map((bureauReport, index) => (
                <tr
                  className={
                    selectedBureauReport &&
                    selectedBureauReport.id == bureauReport.id
                      ? "bureauReportViewActive"
                      : ""
                  }
                >
                  <td>{index + 1}</td>
                  <td>{bureauReport.id}</td>
                  <td>{bureauReport.bureau_name}</td>
                  <td>{bureauReport.score}</td>
                  <td>{bureauReport.generated_on}</td>
                  <td>
                    <Button
                      type="link"
                      onClick={() => {
                        openparamModal(bureauReport.id);
                      }}
                    >
                      Click Here
                    </Button>
                  </td>
                  <td>
                    <Button
                      onClick={() => {
                        changeSelectedBureauReport(bureauReport.id);
                      }}
                    >
                      View Report
                    </Button>
                    {bureauReport.bureau_name === "CIBIL" ? (
                      <Link
                        target="_blank"
                        to={`/download-report/${bureauReport.id}`}
                      >
                        <Button> Download PDF</Button>
                      </Link>
                    ) : (
                      <Button
                        onClick={() => {
                          openPDFFILES(bureauReport.id);
                        }}
                      >
                        {" "}
                        Download PDF
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <Card>
          <h2 className="NodataCard">
            {isOcrDone(selectedUser.stage)
              ? !isKycDone(selectedUser.stage)
                ? "PLEASE PERFORM KYC TO FURTHER PROCEED"
                : "BUREAU REPORT PENDING"
              : "PLEASE PERFORM OCR TO FURTHER PROCEED"}
          </h2>
        </Card>
      )}
      <RunParametersModal
        visible={visible}
        userParameters={userParameters}
        selectedApplicant={selectedUser}
        onCancel={() => setVisible(false)}
      />
    </React.Fragment>
  );
};
export default ReportRunSummary;
