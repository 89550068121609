import { Button, Modal, Tabs } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { REQUIRED_MESSAGES } from "../../constants/messages";
import {
  onChangeSelectedCategory,
  onChangeSelectedSubCategory,
} from "../../redux/actions/CategoryAndSubCategory";
import { onRunOcrResponse } from "../../redux/actions/OcrAction";
import { onChangeLogNewCaseActiveParticipantTab } from "../../redux/actions/TabAction";
import RunOcr from "./applicant-ocr";
import AddCoApplicants from "./co-applicants/AddCoApplicants";
import AddGuarantors from "./guarantors/AddGuarantors";
import { closeRunOcrModal } from "./helpers/utils";
import AddMainApplicant from "./main-applicant/AddMainApplicant";
const { TabPane } = Tabs;
const AddParticipantTabs = (props) => {
  // const { caseDetails } = props;
  const ocrResponse = useSelector(
    (state) => state.ocrAndNonOcrReducer.runOcrResponse
  );

  const logNewCaseActiveKey = useSelector(
    (state) => state.auth.logNewCaseActiveKey
  );
  const newlyCreatedCase = useSelector((state) => state.auth.newlyCreatedCase);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(onRunOcrResponse(false));
  }, [dispatch]);
  const changeCategories = (key) => {
    let selectedCategory, selectedSubCategory;
    if (key === "1" && newlyCreatedCase.fk_category_id) {
      selectedCategory = newlyCreatedCase.fk_category_id.toString();
      selectedSubCategory = newlyCreatedCase.fk_subcategory_id.toString();
    } else if (key === "2") {
    } else if (key === "3") {
    }
    dispatch(onChangeSelectedCategory(selectedCategory));
    dispatch(onChangeSelectedSubCategory(selectedSubCategory));
  };
  const changeTab = (key) => {
    if (key === "1") {
      dispatch(onChangeLogNewCaseActiveParticipantTab(key));
    } else if (key === "2") {
      if (
        newlyCreatedCase.main_applicant &&
        newlyCreatedCase.main_applicant.ocr_documents &&
        Array.isArray(newlyCreatedCase.main_applicant.ocr_documents) &&
        newlyCreatedCase.main_applicant.ocr_documents.length > 0
      ) {
        dispatch(onChangeLogNewCaseActiveParticipantTab(key));
      } else {
        toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_MAIN_APPLICANT);
      }
    } else if (key === "3") {
      if (
        newlyCreatedCase.main_applicant &&
        newlyCreatedCase.main_applicant.ocr_documents &&
        Array.isArray(newlyCreatedCase.main_applicant.ocr_documents) &&
        newlyCreatedCase.main_applicant.ocr_documents.length > 0
      ) {
        dispatch(onChangeLogNewCaseActiveParticipantTab(key));
        // if (
        //   newlyCreatedCase.co_applicants &&
        //   Array.isArray(newlyCreatedCase.co_applicants) &&
        //   newlyCreatedCase.co_applicants.length > 0
        // ) {
        //   dispatch(onChangeLogNewCaseActiveParticipantTab(key));
        // } else {
        //   toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_ATLEASE_ONE_CO_APPLICANT);
        // }
      } else {
        toast.error(REQUIRED_MESSAGES.PLEASE_SAVE_MAIN_APPLICANT);
      }
    }
  };
  const changeActiveKey = (key) => {
    changeCategories(key);
    changeTab(key);
  };

  return (
    <React.Fragment>
      <Tabs activeKey={logNewCaseActiveKey} onChange={changeActiveKey}>
        <TabPane tab="Main Applicant" key="1">
          <AddMainApplicant {...props} />
        </TabPane>
        <TabPane tab="Co Applicant" key="2">
          <AddCoApplicants {...props} />
        </TabPane>
        <TabPane tab="Guarantor" key="3">
          <AddGuarantors {...props} />
        </TabPane>
      </Tabs>
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={ocrResponse}
        // visible={true}
        onOk={() => closeRunOcrModal(dispatch)}
        onCancel={() => closeRunOcrModal(dispatch)}
        footer={[
          <Button
          className='JustReqWrap'
          type="primary"
            onClick={() => closeRunOcrModal(dispatch)}
          >
            Ok
          </Button>,
        ]}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunOcr />
      </Modal>
    </React.Fragment>
  );
};
export default AddParticipantTabs;
