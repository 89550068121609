import React, { Component } from "react";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import VaahanListing from "../components/vaahanListing";
 function Vaahan() {
    return (
        <div>
          <div className="NewCasesWrap">
            <TopBarNav />
            <Navigation />
            <VaahanListing/>
          </div>
        </div>
      );
    }
    export default Vaahan;