import React, { Component } from "react";
import { Button, Card } from "antd";
class Crif extends Component {
  render() {
    return (
      <>
        <div className="HeaderPanelAction">
          <h3>Displaying Analysis </h3>
          <div className="ButtonWrap">
            <Button>Download Summary Report (excel)</Button>
            <Button>Download Credit Bureau Report</Button>
            <Button>Download Credit Bureau Report</Button>
          </div>
        </div>
        <div className="TableWrap bolddata">
          <Card>
            <table>
              <thead>
                <tr>
                  <th>CIBIL/CRIF Score</th>
                  <th>Risk Category</th>
                  <th>Risk Description </th>
                  <th>Total Loans (Count)</th>
                  <th>Active Loans (Count)</th>
                  <th>Total Sanctioned Amount</th>
                  <th>Total Principal Outstanding</th>
                  <th>Total Account With Overdue (Count)</th>
                  <th>Total Account With Overdue (Count)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>777</td>
                  <td>D</td>
                  <td>Low Risk</td>
                  <td>1</td>
                  <td>0</td>
                  <td>1500000</td>
                  <td>0</td>
                  <td>0</td>
                  <td>0</td>
                </tr>
              </tbody>
            </table>
          </Card>
       
        </div>
        <div className=" BoxCardTitle">
            <Card title="Trigger 1 ">
                <div className='TableWrap'>
                <table>
                    <thead>
                        <tr>
                            <th>Trigger Point</th>
                            <th>Account Type</th>
                            <th>Ownership</th>
                            <th>Sanctioned Date</th>
                            <th>Santioned Amount / Disbursed Amount</th>
                            <th>Current Balance</th>
                            <th>Loan Status (Active/Closed)</th>
                            <th>Closure Date (If Closed)</th>
                            <th>Overdue</th>
                            <th>Report Date / Last Paid Date</th>
                            <th>Tigger Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> Sub </td>
                            <td>Business Loan</td>
                            <td>Individual</td>
                            <td>27/07/2016</td>
                            <td>1500000</td>
                            <td>53135</td>
                            <td>No Suit Filed</td>
                            <td></td>
                            <td>0</td>
                            <td>28/08/2019</td>
                            <td>jan/19</td>
                        </tr>
                    </tbody>
                </table>
                </div>
               
            </Card>
            <div className='TableSmall'>
             <table>
                 <thead>
                     <tr>
                         <th>Jan/19</th>
                         <th>Feb/19</th>
                         <th>Mar/19</th>
                         <th>Apr/19</th>
                         <th>May/19</th>
                         <th>Jun/19</th>
                         <th>Jul/19</th>
                         <th>Aug/19</th>
                         <th>Sep/19</th>
                         <th>Oct/19</th>
                         <th>Nov/19</th>
                        
                     </tr>
                 </thead>
                 <tbody>
                     <tr>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                     </tr>
                 </tbody>
             </table>
         </div>
        </div>
        <div className=" BoxCardTitle">
            <Card title="Trigger 2 ">
                <div className='TableWrap'>
                <table>
                    <thead>
                        <tr>
                            <th>Trigger Point</th>
                            <th>Account Type</th>
                            <th>Ownership</th>
                            <th>Sanctioned Date</th>
                            <th>Santioned Amount / Disbursed Amount</th>
                            <th>Current Balance</th>
                            <th>Loan Status (Active/Closed)</th>
                            <th>Closure Date (If Closed)</th>
                            <th>Overdue</th>
                            <th>Report Date / Last Paid Date</th>
                            <th>Tigger Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> Sub </td>
                            <td>Business Loan</td>
                            <td>Individual</td>
                            <td>27/07/2016</td>
                            <td>1500000</td>
                            <td>53135</td>
                            <td>No Suit Filed</td>
                            <td></td>
                            <td>0</td>
                            <td>28/08/2019</td>
                            <td>jan/19</td>
                        </tr>
                    </tbody>
                </table>
                </div>
               
            </Card>
            <div className='TableSmall'>
             <table>
                 <thead>
                     <tr>
                         <th>Jan/19</th>
                         <th>Feb/19</th>
                         <th>Mar/19</th>
                         <th>Apr/19</th>
                         <th>May/19</th>
                         <th>Jun/19</th>
                         <th>Jul/19</th>
                         <th>Aug/19</th>
                         <th>Sep/19</th>
                         <th>Oct/19</th>
                         <th>Nov/19</th>
                        
                     </tr>
                 </thead>
                 <tbody>
                     <tr>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                     </tr>
                 </tbody>
             </table>
         </div>
        </div>
        <div className=" BoxCardTitle">
            <Card title="Trigger 3 ">
                <div className='TableWrap'>
                <table>
                    <thead>
                        <tr>
                            <th>Trigger Point</th>
                            <th>Account Type</th>
                            <th>Ownership</th>
                            <th>Sanctioned Date</th>
                            <th>Santioned Amount / Disbursed Amount</th>
                            <th>Current Balance</th>
                            <th>Loan Status (Active/Closed)</th>
                            <th>Closure Date (If Closed)</th>
                            <th>Overdue</th>
                            <th>Report Date / Last Paid Date</th>
                            <th>Tigger Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td> Sub </td>
                            <td>Business Loan</td>
                            <td>Individual</td>
                            <td>27/07/2016</td>
                            <td>1500000</td>
                            <td>53135</td>
                            <td>No Suit Filed</td>
                            <td></td>
                            <td>0</td>
                            <td>28/08/2019</td>
                            <td>jan/19</td>
                        </tr>
                    </tbody>
                </table>
                </div>
               
            </Card>
            <div className='TableSmall'>
             <table>
                 <thead>
                     <tr>
                         <th>Jan/19</th>
                         <th>Feb/19</th>
                         <th>Mar/19</th>
                         <th>Apr/19</th>
                         <th>May/19</th>
                         <th>Jun/19</th>
                         <th>Jul/19</th>
                         <th>Aug/19</th>
                         <th>Sep/19</th>
                         <th>Oct/19</th>
                         <th>Nov/19</th>
                        
                     </tr>
                 </thead>
                 <tbody>
                     <tr>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                         <td>XXX/SUB</td>
                     </tr>
                 </tbody>
             </table>
         </div>
        </div>
      </>
    );
  }
}
export default Crif;
