import {
  SET_PREVIEW_MODAL_VISIBILITY,
  SET_PREVIEW_IMAGE,
  SET_PREVIEW_PDF,
  SET_PREVIEW_LOADER,
} from "../constants/ActionTypes";

/**
 * @method onPreviewImageOrPdfVisibility
 * @description
 * @param {*}
 * @returns
 */
export const onPreviewImageOrPdfVisibility = (data) => ({
  type: SET_PREVIEW_MODAL_VISIBILITY,
  data,
});
/**
 * @method onSetPreviewImage
 * @description
 * @param {*}
 * @returns
 */
export const onSetPreviewImage = (data) => ({
  type: SET_PREVIEW_IMAGE,
  data,
});
/**
 * @method onPreviewImageOrPdfVisibility
 * @description
 * @param {*}
 * @returns
 */
 export const onSetPreviewPdf = (data) => ({
  type: SET_PREVIEW_PDF,
  data,
});
/**
 * @method onPreviewImageOrPdfVisibility
 * @description
 * @param {*}
 * @returns
 */
 export const onSetPreviewLoader = (data) => ({
  type: SET_PREVIEW_LOADER,
  data,
});