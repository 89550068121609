import React, { Component, useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { useSelector } from "react-redux";

const DealerInfo = (props) => {
  // const caseDetail = useSelector((state) => state.auth.caseDetail);
  const { caseDetail } = props;
  const [leadInfo, setLeadInfo] = useState({});
  useEffect(() => {
    if (caseDetail && caseDetail.leadInfo) {
      setLeadInfo(caseDetail.leadInfo);
    }
  }, [caseDetail]);

  return (
    <>
      <div className="CardDetailWrap">
        <h3>Dealer Information </h3>
        <Card>
          <Row>
            <Col span={12} xs={24} sm={12}>
              <Row>
                <Col span={12} xs={24} sm={12}>
                  <p className="CardDetailWrapp">Dealer ID</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>{leadInfo.dealer_id ? leadInfo.dealer_id : "-"}</p>
                </Col>
              </Row>
              <Row>
                <Col span={12} xs={24} sm={12}>
                  <p>Dealer Name</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>{leadInfo.dealer_name ? leadInfo.dealer_name : "-"}</p>
                </Col>
              </Row>
              <Row>
                <Col span={12} xs={24} sm={12}>
                  <p>Dealer Code</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>{leadInfo.dealer_id ? leadInfo.dealer_id : "-"}</p>
                </Col>
              </Row>
              <Row>
                <Col span={12} xs={24} sm={12}>
                  <p>City</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>{leadInfo.dealer_city ? leadInfo.dealer_city : "-"}</p>
                </Col>
              </Row>
              
            </Col>
            <Col span={12} xs={24} sm={12}>
              <Row>
                <Col span={12} xs={24} sm={12}>
                  <p>Postal Code</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>{leadInfo.dealer_pin ? leadInfo.dealer_pin : "-"}</p>
                </Col>
              </Row>

              <Row>
                <Col span={12} xs={24} sm={12}>
                  <p>State</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>{leadInfo.dealer_state ? leadInfo.dealer_state : "-"}</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={12} xs={24} sm={12}>
            <Row style={{paddingBottom:'15px'}}>
                <Col span={12} xs={24} sm={12}>
                  <p>Sales Consultant ID</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>
                    {leadInfo.sales_consultant_id
                      ? leadInfo.sales_consultant_id
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={12} xs={24} sm={12}>
                  <p>Sales Consultant First Name</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>
                    {leadInfo.sales_consultant_name_Ist
                      ? leadInfo.sales_consultant_name_Ist
                      : "-"}
                  </p>
                </Col>
              </Row>
              
            </Col>
            <Col span={12} xs={24} sm={12}>
            <Row>
                <Col span={14} xs={24} sm={12}>
                  <p>Sales Consultant Contact Number</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>
                    {leadInfo.sales_contact_number
                      ? leadInfo.sales_contact_number
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col span={12} xs={24} sm={12}>
                  <p>Sales Consultant Second Name</p>
                </Col>
                <Col span={12} xs={24} sm={12}>
                  <p>
                    {leadInfo.sales_consultant_name_2nd
                      ? leadInfo.sales_consultant_name_2nd
                      : "-"}
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default DealerInfo;
