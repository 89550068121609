import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row } from "antd";
import { toast } from "react-toastify";
import { CaretDownOutlined } from "@ant-design/icons";

export const HunterRequestForm = () => {
  const caseData = useSelector((state) => state.auth.caseDetail);

  return (
    <>
      <div className="accordion" id="hunterForm">
        <div className="card">
          <div className="card-header" id="mainapplicantDetail">
            <h5 className="mb-0">
              <div className="row">
                <button
                  className="btn btn-link"
                  type="button"
                  data-toggle="collapse"
                  data-target="#mainapplicant"
                  aria-expanded="true"
                  aria-controls="mainapplicant"
                  style={{ color: "#202A44", fontWeight: "bold" }}
                >
                  Main Applicant
                </button>
                <button
                  className="btn btn-link "
                  type="button"
                  data-toggle="collapse"
                  data-target="#mainapplicant"
                  aria-expanded="true"
                  aria-controls="mainapplicant"
                  style={{ float: "right", marginLeft: "1250px" }}
                >
                  <CaretDownOutlined style={{ color: "#202A44" }} />
                </button>
              </div>
            </h5>
          </div>
          <div id="mainapplicant" className="collapse show multi-collapse">
            <div className=" card card-body">
              <Row gutter={24}>
                <Col span={6} md={6} xs={16} sm={16}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">First Name</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.first_name
                          ? caseData?.main_applicant?.first_name
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  {caseData?.main_applicant?.category_name == "Corporate" && (
                    <Row>
                      <Col span={12} xs={24} sm={12}>
                        <p className="font-weight-bold">Organization Name</p>
                      </Col>
                      <Col span={12} xs={24} sm={12}>
                        <p>
                          {caseData?.main_applicant?.organization_name
                            ? caseData?.main_applicant?.organization_name
                            : "-"}
                        </p>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Mobile No</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.mobile_no
                          ? caseData?.main_applicant?.mobile_no
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">City</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.city
                          ? caseData?.main_applicant?.city
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Email</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.email
                          ? caseData?.main_applicant?.email
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={6} md={6} xs={16} sm={16}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Last Name</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.last_name
                          ? caseData?.main_applicant?.last_name
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Gender</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.gender
                          ? caseData?.main_applicant?.gender
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Postal Code</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.pin
                          ? caseData?.main_applicant?.pin
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Pan No.</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.pan_no
                          ? caseData?.main_applicant?.pan_no
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={6} md={6} xs={16} sm={16}>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Participant Type</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.category_name
                          ? caseData?.main_applicant?.category_name
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Date of Birth</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.dob
                          ? caseData?.main_applicant?.dob
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">State</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.state
                          ? caseData?.main_applicant?.state
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12} xs={24} sm={12}>
                      <p className="font-weight-bold">Address</p>
                    </Col>
                    <Col span={12} xs={24} sm={12}>
                      <p>
                        {caseData?.main_applicant?.address_line1
                          ? caseData.main_applicant.address_line1
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col span={6} md={6} xs={16} sm={16} style={{padding:'0', background:'#f9f9f9', border:'1px solid #e5e5e5', borderRadius:'10px' }}>
                  <h6 className="font-weight-bold " style={{textAlign:'center', padding:'10px 0', background:"#f1f1f1", borderBottom:'1px solid #e5e5e5'  }}>Documents</h6>
                  {caseData?.main_applicant?.kyc_result.map((val) => {
                    return (
                      <>
                        <Row style={{padding:'0px 12px'}}>
                          <Col span={12} xs={24} sm={12}>
                            <p className="font-weight-bold">
                              {val.document_name}
                            </p>
                          </Col>
                          <Col span={12} xs={24} sm={12}>
                            <p>{val.identity_no ? val.identity_no : "-"}</p>
                          </Col>
                        </Row>
                      </>
                    );
                  })}
                </Col>
              </Row>
            </div>
          </div>
        </div>
        {caseData?.co_applicants && (
          <>
            <div className="card">
              <div className="card-header" id="co_applicantDetail">
                <h5 className="mb-0">
                  <div className="row">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#co_applicant"
                      aria-expanded="false"
                      aria-controls="co_applicant"
                      style={{ color: "#202A44", fontWeight: "bold" }}
                    >
                      Co-Applicants
                    </button>
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#co_applicant"
                      aria-expanded="false"
                      aria-controls="co_applicant"
                      style={{ float: "right", marginLeft: "1257px" }}
                    >
                      <CaretDownOutlined style={{ color: "#202A44" }} />
                    </button>
                  </div>
                </h5>
              </div>
              <div id="co_applicant" className="collapse multi-collapse">
                <div className="card card-body">
                  {caseData?.co_applicants?.map((val, index) => {
                    return (
                      <>
                        <h5>Co Applicant {index + 1}</h5>
                        <br />
                        <Row gutter={24}>
                          <Col span={6} md={6} xs={16} sm={16}>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">First Name</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.first_name ? val.first_name : "-"}</p>
                              </Col>
                            </Row>
                            {val.category_name == "Corporate" && (
                              <Row>
                                <Col span={12} xs={24} sm={12}>
                                  <p className="font-weight-bold">
                                    Organization Name
                                  </p>
                                </Col>
                                <Col span={12} xs={24} sm={12}>
                                  <p>
                                    {val.organization_name
                                      ? val.organization_name
                                      : "-"}
                                  </p>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Mobile No</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.mobile_no ? val.mobile_no : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">City</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.city ? val.city : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Relation</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>
                                  {val.coapplicant_applicant_relation
                                    ? val.coapplicant_applicant_relation
                                    : "-"}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={6} md={6} xs={16} sm={16}>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Last Name</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.last_name ? val.last_name : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Gender</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.gender ? val.gender : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Postal Code</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.pin ? val.pin : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Pan No.</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.pan_no ? val.pan_no : "-"}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={6} md={6} xs={16} sm={16}>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">
                                  Participant Type
                                </p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>
                                  {val.category_name ? val.category_name : "-"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">
                                  Date of Birth
                                </p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.dob ? val.dob : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">State</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.state ? val.state : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Address</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>
                                  {val.address_line1 ? val.address_line1 : "-"}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={6} md={6} xs={16} sm={16} style={{padding:'0', background:'#f9f9f9', border:'1px solid #e5e5e5', borderRadius:'10px' }}>
                            <h6 className="font-weight-bold " style={{textAlign:'center', padding:'10px 0', background:"#f1f1f1", borderBottom:'1px solid #e5e5e5'  }}>Documents</h6>
                            {val?.kyc_result.map((value) => {
                              return (
                                <>
                                  <Row style={{padding:'0px 12px'}}>
                                    <Col span={12} xs={24} sm={12}>
                                      <p className="font-weight-bold">
                                        {value.document_name}
                                      </p>
                                    </Col>
                                    <Col span={12} xs={24} sm={12}>
                                      <p>
                                        {value.identity_no
                                          ? value.identity_no
                                          : "-"}
                                      </p>
                                    </Col>
                                  </Row>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                        <hr />{" "}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
        {caseData?.guarantors && (
          <>
            <div className="card">
              <div className="card-header" id="guarantorDetail">
                <h5 className="mb-0">
                  <div className="row">
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#guarantor"
                      aria-expanded="false"
                      aria-controls="guarantor"
                      style={{ color: "#202A44", fontWeight: "bold" }}
                    >
                      Guarantors
                    </button>
                    <button
                      className="btn btn-link collapsed"
                      type="button"
                      data-toggle="collapse"
                      data-target="#guarantor"
                      aria-expanded="false"
                      aria-controls="guarantor"
                      style={{ float: "right", marginLeft: "1278px" }}
                    >
                      <CaretDownOutlined style={{ color: "#202A44" }} />
                    </button>
                  </div>
                </h5>
              </div>
              <div
                id="guarantor"
                className="collapse multi-collapse"
                aria-labelledby="guarantorDetail"
                data-parent="#accordionExample"
              >
                <div className="card card-body">
                  {caseData?.guarantors?.map((val, index) => {
                    return (
                      <>
                        <h5>Guarantor {index + 1}</h5>
                        <br />
                        <Row gutter={24}>
                          <Col span={6} md={6} xs={16} sm={16}>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">First Name </p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.first_name ? val.first_name : "-"}</p>
                              </Col>
                            </Row>
                            {val.category_name == "Corporate" && (
                              <Row>
                                <Col span={12} xs={24} sm={12}>
                                  <p className="font-weight-bold">
                                    Organization Name
                                  </p>
                                </Col>
                                <Col span={12} xs={24} sm={12}>
                                  <p>
                                    {val.organization_name
                                      ? val.organization_name
                                      : "-"}
                                  </p>
                                </Col>
                              </Row>
                            )}
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Mobile No</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.mobile_no ? val.mobile_no : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">City</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.city ? val.city : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Relation</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>
                                  {val.guarantor_applicant_relation
                                    ? val.guarantor_applicant_relation
                                    : "-"}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={6} md={6} xs={16} sm={16}>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Last Name</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.last_name ? val.last_name : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Gender</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.gender ? val.gender : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Postal Code</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.pin ? val.pin : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Pan No.</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.pan_no ? val.pan_no : "-"}</p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={6} md={6} xs={16} sm={16}>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">
                                  Participant Type
                                </p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>
                                  {val.category_name ? val.category_name : "-"}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">
                                  Date of Birth
                                </p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.dob ? val.dob : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">State</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>{val.state ? val.state : "-"}</p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12} xs={24} sm={12}>
                                <p className="font-weight-bold">Address</p>
                              </Col>
                              <Col span={12} xs={24} sm={12}>
                                <p>
                                  {val.address_line1 ? val.address_line1 : "-"}
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col span={6} md={6} xs={16} sm={16} style={{padding:'0', background:'#f9f9f9', border:'1px solid #e5e5e5', borderRadius:'10px' }}>
                            <h6 className="font-weight-bold " style={{textAlign:'center', padding:'10px 0', background:"#f1f1f1", borderBottom:'1px solid #e5e5e5'  }}>Documents</h6>
                            {val?.kyc_result.map((value) => {
                              return (
                                <>
                                  <Row style={{padding:'0px 12px'}}>
                                    <Col span={12} xs={24} sm={12}>
                                      <p className="font-weight-bold">
                                        {value.document_name}
                                      </p>
                                    </Col>
                                    <Col span={12} xs={24} sm={12}>
                                      <p>
                                        {value.identity_no
                                          ? value.identity_no
                                          : "-"}
                                      </p>
                                    </Col>
                                  </Row>
                                </>
                              );
                            })}
                          </Col>
                        </Row>
                        <hr />{" "}
                      </>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HunterRequestForm;
