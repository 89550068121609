import React, { Component, useEffect } from "react";
import { Tabs, Card, Row, Col } from "antd";
import DetailDocform from "../detaildocform";
import MainApplicantFormKyc from "../kycreport/mainapplicantform";
import BureauReport from "../bureaureport";
import { useDispatch } from "react-redux";
import { onParticipantDetail } from "../../../redux/actions/ParticipantAction";
import { onMemberDirectorDetail } from "../../../redux/actions/MemberAndDirectorAction";
import { onChangeSelectedUser } from "../../../redux/actions/AuthAction";
const { TabPane } = Tabs;
const SecondTab = (props)=> {
  const {type,applicant_id,applicant_type,} = props;
  const dispatch = useDispatch();
  useEffect(()=>{
    if(type && applicant_id ){
      if(type==='original_applicant'&& applicant_type ){
        dispatch(onParticipantDetail({
          applicant_type: applicant_type,
          applicant_coapplicant_guarantor_id: applicant_id,
        },(applicant)=>{
          dispatch(onChangeSelectedUser(applicant,()=>{}))
        }))
      }else{
        dispatch(onMemberDirectorDetail(applicant_id,(member_director)=>{
          dispatch(onChangeSelectedUser(member_director,()=>{}))
        }))
      }
    }
  },[dispatch,type,applicant_id,applicant_type])
  return (
    <React.Fragment>
      <div className="InnerTabsWrap">
        <Card>
          <Tabs defaultActiveKey="1">
            <TabPane tab="Details & documents" key="1">
              <DetailDocform />
            </TabPane>
            <TabPane tab="KYC" key="2">
              <MainApplicantFormKyc />
            </TabPane>
            <TabPane tab="Bureau Reports" key="3">
              {/* <Guarantorform /> */}
              <BureauReport />
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </React.Fragment>
  );
}
export default SecondTab;
