import React, { Component } from "react";
import { Card, Row, Col } from 'antd';
class VoterId extends Component {
    render() {
      return (
        <>
            <div className='CardBlock'>
                <h3>Voter ID</h3>
                <Card>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Voter ID Number</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>AHMPC45o6C</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Name of Card Holder</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Tanvish Kalra</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Gender</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>Male</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Age</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>25</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <h4>Assembly Constituency Detail of the Card Holder</h4>
                        </Col>
                        <Col span={12} md={12} xs={24} sm={24}>
                            <p>xxxxxx</p>
                        </Col>
                    </Row>
                  
                </Card>
            </div>
        </>
      )
    }
}
export default VoterId;