import {
  CREDIT_PENDING_CASES_SUCCESS,
  CREDIT_PENDING_CASES_FAILED,
  CHANGE_FIRST_TAB_KEY,
  CHANGE_SECOND_TAB_KEY,
  CHANGE_THIRD_TAB_KEY,
} from "../constants/ActionTypes";

const initState = {
  firstTabKey: "1",
  secondTabKey: "1",
  thirdTabKey: "1",
};
export default function course(state = initState, action) {
  switch (action.type) {
    case CHANGE_FIRST_TAB_KEY:
      
      return {
        ...state,
        firstTabKey: action.tab,
      };
    case CHANGE_SECOND_TAB_KEY:
      
      return {
        ...state,
        secondTabKey: action.tab,
      };
    case CHANGE_THIRD_TAB_KEY:
      
      return {
        ...state,
        thirdTabKey: action.tab,
      };
    default:
      return state;
  }
}
