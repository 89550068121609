import React, { useEffect, useState } from "react";
import TopBarNav from "../../topbar";
import Navigation from "../../navigation";
import { Button, Col, Form, Input, Row, Select } from "antd";
import { MESSAGES, REQUIRED_MESSAGES } from "../../../constants/messages";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { onCaseMasters } from "../../../redux/actions/MasterData";
import { REGEX } from "../../../constants/regex";
import {
  checkLoanAmountAllowed,
  checkLoanDurationAllowed,
  checkQuantityAllowed,
  firstCharacterOfEachWordUpperCase,
} from "../../../helpers/utils";
import { FINANCING_TYPES } from "../../../constants/constants";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import {
  onAddNewCase,
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../../redux/actions/CaseAction";
import { onChangeLogNewCaseActiveParticipantTab } from "../../../redux/actions/TabAction";
const layout = {
  labelCol: { span: 12, sm: 24 },
  wrapperCol: { span: 12, sm: 24 },
};
const { Option } = Select;
const CaseForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [subCategories, setSubCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [productModals, setProductModals] = useState([]);
  const [saveAndNext, setSaveAndNext] = useState(false);
  const caseMasters = useSelector((state) => {
    if (
      state &&
      state.auth &&
      state.auth.masterData &&
      state.auth.masterData.caseMasters
    ) {
      return state.auth.masterData.caseMasters;
    }
    return {};
  });

  useEffect(() => {
    window.scrollTo(0, 10);
    dispatch(onCaseMasters((caseMasters) => {}));
  }, [dispatch]);
  const changeSubCategories = (applicationCategoryId) => {
    form.setFieldsValue({
      application_sub_category: undefined,
    });
    if (
      caseMasters.subcategories &&
      Array.isArray(caseMasters.subcategories) &&
      caseMasters.subcategories.length > 0
    ) {
      const filteredSubCategories = caseMasters.subcategories.filter(
        (subCategory) => {
          return (
            subCategory.fk_category_id.toString() === applicationCategoryId
          );
        }
      );
      setSubCategories(filteredSubCategories);
    }
  };
  const handleCustomerName = (e) => {
    const input = e.target.value;
    if (!REGEX.NAME_VALIDATION.test(input)) {
      form.setFieldsValue({
        // 'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1))).replace( /[^a-zA-Z0-9 ]/gm, '')
        customer_name: firstCharacterOfEachWordUpperCase(
          input.substring(0, input.length - 1)
        )
          .replace(/\s\s+/g, " ")
          .replace(/^\s+/g, ""),
      });
    } else {
      form.setFieldsValue({
        customer_name: firstCharacterOfEachWordUpperCase(input)
          .replace(/\s\s+/g, " ")
          .replace(/^\s+/g, ""),
      });
    }
  };
  const checkIsBusinessAreaSelected = () => {
    if (!form.getFieldValue("business_area")) {
      toast.error("Please select business area");
      // form.setFields([
      //   {
      //     name:'business_area',
      //     errors:['Please select business area']
      //   }
      // ]);
    } else return true;
  };
  const onProductModalClick = () => {
    if (checkIsBusinessAreaSelected() && !form.getFieldValue("product_name")) {
      toast.error("Please select product segment");
      // form.setFields([
      //   {
      //     name:'product_name',
      //     errors:['Please select product name']
      //   }
      // ])
    }
  };
  const onProductNameClick = () => {
    checkIsBusinessAreaSelected();
  };
  const changeProducts = (businessAreaId) => {
    form.setFieldsValue({
      product_name: undefined,
      product_model: undefined,
    });
    if (
      caseMasters.products &&
      Array.isArray(caseMasters.products) &&
      caseMasters.products.length > 0
    ) {
      const filteredProducts = caseMasters.products.filter((product) => {
        return product.fk_business_area_id.toString() === businessAreaId;
      });
      setProducts(filteredProducts);
      setProductModals([]);
    }
  };
  const changeProductModals = (productId) => {
    form.setFieldsValue({
      product_model: undefined,
    });
    if (
      caseMasters.product_models &&
      Array.isArray(caseMasters.product_models) &&
      caseMasters.product_models.length > 0
    ) {
      const filteredProductModels = caseMasters.product_models.filter(
        (product) => {
          return product.fK_product_id.toString() === productId;
        }
      );
      setProductModals(filteredProductModels);
    }
  };
  const removeLoanMaxError = () => {
    const loan_amount_errors = form.getFieldError("loan_amount");
    if (loan_amount_errors.length > 0) {
      if (loan_amount_errors[0] !== REQUIRED_MESSAGES.LOAN_AMOUNT_REQUIRED) {
        form.setFields([
          {
            name: "loan_amount",
            errors: [],
          },
        ]);
      }
    }
  };
  const removeQuantityMaxError = () => {
    const quantitiy_errors = form.getFieldError("quantities");
    if (quantitiy_errors.length > 0) {
      if (quantitiy_errors[0] !== REQUIRED_MESSAGES.QUANTITY_REQUIRED) {
        form.setFields([
          {
            name: "quantities",
            errors: [],
          },
        ]);
      }
    }
  };
  const handleCancel = () => {
    history.goBack();
  };
  const onSuccess = (data) => {
    if (!saveAndNext) {
      return history.push("/OnGoingCases");
    } else {
      dispatch(
        onCaseDetail({ caseId: data.id }, (caseDetail) => {
          dispatch(onChangeLogNewCaseActiveParticipantTab("1"));
          document
            .getElementById("addnextdetails")
            .scrollIntoView({ behavior: "smooth" });
        })
      );
    }
  };
  const onFinish = (values) => {
    let data = {
      customer_name: values.customer_name,
      fk_category_id: values.application_category,
      fk_subcategory_id: values.application_sub_category
        ? values.application_sub_category
        : "",
      fk_business_area_id: values.business_area,
      fk_product_id: values.product_name,
      fk_product_model_id: values.product_model,
      finance_type: values.finance_type,
      loan_duration_in_months: values.loan_duration_in_months,
      finance_amount: values.loan_amount
        .replaceAll(",", "")
        .replace("&#8377", "")
        .replace(/[^\x00-\x7F]/g, ""),
      qty_required: values.quantities.replaceAll(",", ""),
    };
    
    // if (isNewlyCreatedCaseSaved) {
    //   data = {
    //     ...data,
    //     id: newlyCreatedCase.id,
    //   };
    // }
    dispatch(onAddNewCase(data, onSuccess, saveAndNext));
  };
  const removeLoanRequiredMaxError = () => {
    const quantitiy_errors = form.getFieldError("loan_duration_in_months");
    
    if (quantitiy_errors.length > 0) {
      if (quantitiy_errors[0] !== REQUIRED_MESSAGES.LOAN_DURATION_REQUIRED) {
        form.setFields([
          {
            name: "loan_duration_in_months",
            errors: [],
          },
        ]);
      }
    }
  };
  return (
    <React.Fragment>
      <div className="LogNewCasesWrap">
        <TopBarNav />
        <Navigation />
        <div className="Selector">
          <Form {...layout} form={form} onFinish={onFinish}>
            <div className="HeaderHighlight">
              <h4>Add New Case</h4>
              <div className="ButtonWrap">
                {/*<Button>
                <Link to='/dashboard'>Cancel</Link></Button>*/}
                {/*<Button type="primary" htmlType="submit" onClick={()=>{
                  setSaveAndNext(false)
                }}>
                  Save
              </Button>*/}
                <Button
                  type="primary"
                  htmlType="submit"
                  onClick={() => {
                    setSaveAndNext(true);
                  }}
                >
                  Save and Next
                </Button>
              </div>
            </div>
            <Row gutter={32}>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Customer's Name"
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.CUSTOMER_NAME_REQUIRED,
                    },
                  ]}
                  onChange={handleCustomerName}
                >
                  <Input placeholder="Enter Customer Name" />
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Application Category"
                  name="application_category"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.APPLICATION_CATEGORY_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder={"Please Select Application Category"}
                    onChange={changeSubCategories}
                  >
                    {caseMasters &&
                      caseMasters.categories &&
                      Array.isArray(caseMasters.categories) &&
                      caseMasters.categories.map((category) => (
                        <Option
                          value={category.id.toString()}
                          key={category.id.toString()}
                        >
                          {category.category_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Sub Category"
                  name="application_sub_category"
                  rules={[
                    {
                      required: subCategories.length !== 0,
                      message:
                        REQUIRED_MESSAGES.APPLICATION_SUB_CATEGORY_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder={"Please Select Sub Category"}
                    disabled={subCategories.length === 0}
                  >
                    {subCategories.map((subCategory) => (
                      <Option
                        value={subCategory.id.toString()}
                        key={subCategory.id.toString()}
                      >
                        {subCategory.subcategory_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Loan/Lease Amount Required"
                  name="loan_amount"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.LOAN_AMOUNT_REQUIRED,
                    },
                  ]}
                  // extra="Note: Maximum loan amount is 10B"
                >
                  <NumberFormat
                    placeholder="Loan Amount"
                    class="ant-input"
                    name="Loan Amount"
                    prefix="&#8377;"
                    isAllowed={(values) => {
                      if (values.value.startsWith("0")) {
                        return false;
                      }
                      if (checkLoanAmountAllowed(values)) {
                        return true;
                      }
                      form.setFields([
                        {
                          name: "loan_amount",
                          errors: [MESSAGES.MAX_AMOUNT_1000],
                        },
                      ]);
                      // toast.error(MESSAGES.MAX_AMOUNT_1000)
                    }}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    thousandSeparator={true}
                    decimalScale={0}
                    className="ant-input"
                    onTouchEnd={removeLoanMaxError}
                    onMouseLeave={removeLoanMaxError}
                  />
                  {/*<Input disabled={isNewlyCreatedCaseSaved && !isEditMode} />*/}
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Business Area"
                  name="business_area"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.BUSINESS_AREA_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder="Please Select Business Area"
                    onChange={changeProducts}
                  >
                    {caseMasters &&
                      caseMasters.business_areas &&
                      Array.isArray(caseMasters.business_areas) &&
                      caseMasters.business_areas.map((business_area) => (
                        <Option
                          value={business_area.id.toString()}
                          key={business_area.id.toString()}
                        >
                          {business_area.business_area}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Product Segment"
                  name="product_name"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.PRODUCT_NAME_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder="Please Select Product Segment"
                    onClick={onProductNameClick}
                    onChange={changeProductModals}
                    optionFilterProp="children"
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }
                     filterSort={(optionA, optionB) =>
                       optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                     }
                     showSearch
                  >
                    {products.map((product) => (
                      <Option
                        value={product.id.toString()}
                        key={product.id.toString()}
                      >
                        {product.product_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Product Description"
                  name="product_model"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.PRODUCT_MODAL_NUMBER_REQUIRED,
                    },
                  ]}
                >
                  <Select
                    placeholder="Please Select Product Description"
                    onClick={onProductModalClick}
                    optionFilterProp="children"
                     filterOption={(input, option) =>
                       option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                     }
                     filterSort={(optionA, optionB) =>
                       optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                     }
                     showSearch
                  >
                    {productModals.map((productModal) => (
                      <Option
                        value={productModal.id.toString()}
                        key={productModal.id.toString()}
                      >
                        {productModal.model_no}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Quantity Required"
                  name="quantities"
                  // extra={"Note: Maximum quantity is 10,000"}
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.QUANTITY_REQUIRED,
                    },
                    // {pattern: new RegExp("^([1-9][0-9]{0,2}|10000)$"),message:'only numbers allowed'}
                  ]}
                >
                  <NumberFormat
                    placeholder="Quantity Required"
                    name="quantities"
                    class="ant-input"
                    isAllowed={(values) => {
                      if (values.value.startsWith("0")) {
                        return false;
                      }
                      if (checkQuantityAllowed(values)) {
                        return true;
                      }
                      form.setFields([
                        {
                          name: "quantities",
                          errors: ["Maximum quantity can be 10,000"],
                        },
                      ]);
                      // toast.error('Quantity can not be more than 10,000')
                    }}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    thousandSeparator={true}
                    decimalScale={0}
                    className="ant-input"
                    onMouseLeave={removeQuantityMaxError}
                    onTouchEnd={removeQuantityMaxError}
                  />
                  {/*<Input disabled={isNewlyCreatedCaseSaved && !isEditMode} />*/}
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Type of Financing"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.FINANCE_TYPE,
                    },
                  ]}
                  name="finance_type"
                >
                  <Select placeholder="Please Select Finance Type">
                    {FINANCING_TYPES.map((financingType) => (
                      <Option value={financingType} key={financingType}>
                        {financingType}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Loan Tenure (in months)"
                  name="loan_duration_in_months"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.LOAN_DURATION_REQUIRED,
                    },
                  ]}
                >
                  <NumberFormat
                    placeholder="Loan Tenure"
                    name="loan_duration_in_months"
                    class="ant-input"
                    isAllowed={(values) => {
                      if (values.value.startsWith("0")) {
                        return false;
                      }
                      if (checkLoanDurationAllowed(values)) {
                        return true;
                      }
                      form.setFields([
                        {
                          name: "loan_duration_in_months",
                          errors: ["Maximum Tenure can be 84 months"],
                        },
                      ]);
                      // toast.error('Quantity can not be more than 10,000')
                    }}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    thousandSeparator={true}
                    decimalScale={0}
                    className="ant-input"
                    onMouseLeave={removeLoanRequiredMaxError}
                    onTouchEnd={removeLoanRequiredMaxError}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};
export default CaseForm;
