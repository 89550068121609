import { Modal } from "antd";

const CancelApplicantPopup = ({ isVisible, title, onCancel,onOk }) => {
  return (
    <Modal
    visible={isVisible}
    onCancel={onCancel}
    className="KycCheck"
    onOk={onOk}
    destroyOnClose={true}
  >
    <p className="dangerconfirmationtext">
      {title}
    </p>
  </Modal>
  );
};
export default CancelApplicantPopup;
