import {
  GET_FI_AGENT,
  ADD_FIELDINVESTIGATION,
  ADD_FIELDINVESTIGATIONOTP,
  ADD_FIELDINVESTIGATIONOTPVERIFY,
  GET_FIELDINVESTIGATIONALL,
  GET_FIELDINVESTIGATIONCASE,
  ADD_FIELDINVESTIGATIONRESPONSE,
  CHECK_LINK_EXPIRE,
  GET_CASE_DETAIL_FOR_FI
} from "../constants/ActionTypes";

export const getFIAgent = (onSuccess) => ({
  type: GET_FI_AGENT,
  onSuccess,
});

export const onAddFieldInvestigation = (data, onFISuccess) => ({
  type: ADD_FIELDINVESTIGATION,
  payload: data,
  onFISuccess,
});

export const onAddFieldInvestigationOTP = (data, onFISuccess) => ({
  type: ADD_FIELDINVESTIGATIONOTP,
  payload: data,
  onFISuccess,
});

export const onAddFieldInvestigationOTPVerify = (data, onFIOTPSuccess) => ({
  type: ADD_FIELDINVESTIGATIONOTPVERIFY,
  payload: data,
  onFIOTPSuccess,
});


export const getFieldInvestigationAll = (agency_id, onSuccess) => ({
  type: GET_FIELDINVESTIGATIONALL,
  payload: agency_id,
  onSuccess,
});
export const getFieldInvestigationCase = (data, onGetSuccess) => ({
  type: GET_FIELDINVESTIGATIONCASE,
  payload: data,
  onGetSuccess,
});
export const submitFieldInvestigationResponse = (data, onSuccess) => ({
  type: ADD_FIELDINVESTIGATIONRESPONSE,
  payload: data,
  onSuccess,
});

export const onCheckLinkExpire = (linkId, onSuccess) => {
  return {
    type: CHECK_LINK_EXPIRE,
    payload: linkId,
    onSuccess
  };
};
export const getCaseDetailForFI = (fiId, onSuccess) => ({
  type: GET_CASE_DETAIL_FOR_FI,
  payload: fiId,
  onSuccess,
});