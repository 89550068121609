import React from "react";
import { Radio } from "antd";
import { connect, useDispatch } from "react-redux";
import { onChangeSelectedUser } from "../../../redux/actions/AuthAction";
import EditIcon from "../../../assets/images/edit.png";
const GuarantorList = (props) => {
  const { selectedUser, guarantors, handleEdit } = props;
  return (
    <>
      <div className="SmallTableWrap SmallTableWrap-v2">
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Guarantor Type</th>
              {/*<th>Name</th>*/}
              {/*<th>Relation with Applicant</th>*/}
              <th>Status</th>
              {/*<th>Stage</th>*/}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {guarantors.map((guarantor, index) => (
              <tr key={guarantor.id}>
                <td>{guarantor.id}</td>
                <td>Individual</td>
                {/*<td>{guarantor.first_name} {guarantor.last_name}</td>*/}
                {/*<td>{guarantor.coapplicant_applicant_relation}</td>*/}
                <td>{guarantor.status}</td>
                {/*<td>{guarantor.stage}</td>*/}
                <td
                  onClick={() => {
                    handleEdit(guarantor);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <img src={EditIcon} alt="Edit" />
                </td>
              </tr>
            ))}{" "}
          </tbody>
        </table>
      </div>
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(GuarantorList);
