import React, { Component } from "react";
import TopBarNav from '../components/topbar';
import Navigation from '../components/navigation';
import ViewfleetAnalysis from '../components/viewfleetAnalysis'

function FleetAnalysis() {
  return (
    <div>
      <div className='NewCasesWrap'>
             <TopBarNav/>
              <Navigation/>
              <ViewfleetAnalysis />
          </div>
    </div>
  )
}

export default FleetAnalysis
