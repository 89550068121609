import { Button, Card, Col, DatePicker, Form, Row, Select, Space } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  DASHBOARD_ANALYTICS,
  VIEW_DATA_AS_ON,
} from "../../constants/constants";
import { MESSAGES } from "../../constants/messages";
import { disabledFutureDates } from "../../helpers/utils";
const { Option } = Select;

const ViewDataAsOn = (props) => {
  const { onFilter = {} } = props;
  
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(false);
  const dashboardData = useSelector((state) => state.auth.dashboardData);

  const onFinish = (values) => {
    if (values.from_date && values.to_date) {
      if (!moment(values.from_date).isSame(values.to_date)) {
        if (values.from_date.isAfter(values.to_date)) {
          toast.error(MESSAGES.FROM_DATE_MUST_BE_LESS_THAN_TO_DATE);
          return;
        }
      }
    }

    let filterData = `from_date=${
      values.from_date ? moment(values.from_date).format("YYYY-MM-DD") : ""
    }&to_date=${
      values.to_date ? moment(values.to_date).format("YYYY-MM-DD") : ""
    }&custom_date_range=${
      values.custom_date_range
        ? values.custom_date_range !== VIEW_DATA_AS_ON[0]
          ? values.custom_date_range
          : ""
        : ""
    }`;

    onFilter(filterData);
  };
  const disableDates = (value) => {
    form.setFieldsValue({ from_date: "", to_date: "" });
    if (value !== VIEW_DATA_AS_ON[0]) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  };
  const onReset = () => {
    setDisabled(false);
    let formvalues = form.getFieldValue();
    if (
      formvalues.from_date ||
      formvalues.to_date ||
      formvalues.custom_date_range
    ) {
      props.reset();
    }
    form.resetFields();
  };
  const GetAnalyticCard = ({ icon, title, value }) => {
    return (
      <Col span={6} md={6} sm={12} xs={24}>
        <div className="CardBox">
          <div className="textWrap">
            <div className="ImageWrap">
              <img src={icon} alt={title} />
            </div>
            <div className="number">
              {dashboardData[value] ? dashboardData[value] : 0}
            </div>
            <p>{title}</p>
          </div>
        </div>
      </Col>
    );
  };
  const GetDashboardAnalytics = () => {
    return (
      <div className="BoxWrap">
        <Row>
          {DASHBOARD_ANALYTICS.map((analytic) => {
            return (
              <GetAnalyticCard
                title={analytic.title}
                icon={analytic.icon}
                value={analytic.value}
              />
            );
          })}
        </Row>
      </div>
    );
  };
  return (
    <React.Fragment>
      <div className="ViewDataCardWrap">
        <Card>
          <div className="HeaderSelector">
            <Form
              form={form}
              name="global_state"
              layout="inline"
              onFinish={onFinish}
            >
              <Form.Item name="custom_date_range" label="View Data As on">
                <Select
                  defaultValue="Custom Date Range"
                  onChange={disableDates}
                >
                  {VIEW_DATA_AS_ON.map((data) => (
                    <Option value={data}>{data}</Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="from_date" label="From" className="ml-20">
                <DatePicker
                  disabledDate={disabledFutureDates}
                  inputReadOnly
                  format="ll"
                  disabled={disabled}
                />
              </Form.Item>
              <Form.Item name="to_date" label="To">
                <DatePicker
                  disabledDate={disabledFutureDates}
                  inputReadOnly
                  format="ll"
                  disabled={disabled}
                />
              </Form.Item>

              <Space className="ml-20">
                <Button type="primary" htmlType="submit" className="btn-filled">
                  Apply Filters
                </Button>
                <Button onClick={onReset} className="btn-blank">
                  Clear All
                </Button>
              </Space>
            </Form>
          </div>
          {GetDashboardAnalytics()}
        </Card>
      </div>
    </React.Fragment>
  );
};
export default ViewDataAsOn;
