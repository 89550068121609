import { INITIAL_STATE } from "../../constants/constants";
import { 
  DOWNLOAD_FLEET_REPORT,
  DOWNLOAD_FLEET_TEMPLATE,
  FLEET_ANALYSIS,
  FLEET_ANALYSIS_LIST,
  FLEET_ANALYSIS_LIST_BY_CASE,
  FLEET_ANALYSIS_RE_RUN,
 
} from "../constants/ActionTypes";
const DEFAULT_STATE = INITIAL_STATE;
export default function fleet(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case FLEET_ANALYSIS:
      return {
        ...state,
        fleetData: action.fleetData,
      };
    case FLEET_ANALYSIS_LIST:
      return {
        ...state,
        fleetData: action.fleetData,
      };
    case FLEET_ANALYSIS_LIST_BY_CASE:
      return {
        ...state,
        fleetData: action.fleetData,
      };
      case FLEET_ANALYSIS_RE_RUN:
        return {
          ...state,
          fleetData: action.fleetData,
        };

        case DOWNLOAD_FLEET_REPORT:
          return {
            ...state,
            fleetData: action.fleetData,
          };
          case DOWNLOAD_FLEET_TEMPLATE:
            return {
              ...state,
              fleetData: action.fleetData,
            };
    
    default:
      return state;
  }
}
