import {
    GET_VAAHAN_DETAILS,GET_VAHAN_REQUEST_DETAILS,STORE_VAAHAN_DETAILS,RUN_VAHAN_HYPOTHECATION,GET_VAAHAN_DETAILS_BY_ID
  } from "../constants/ActionTypes";
  
  
  
  export const onLoadVaahanDetails = (onSuccess) => ({
    type: GET_VAAHAN_DETAILS,
    payload: [],
    onSuccess,
  });

  export const onUploadStoreVaahanDetails = (data, onSuccess) => ({
    type: STORE_VAAHAN_DETAILS,
    payload: data,
    onSuccess,
    
  });
  export const getVahanRequestDetails=(data,onSuccess)=>(
    {
      type:GET_VAHAN_REQUEST_DETAILS,
      payload:data,
      onSuccess
    });
    export const runHypothecation=(data,onSuccess)=>(
      {
        type:RUN_VAHAN_HYPOTHECATION,
        payload:data,
        onSuccess
      });

      export const getVahanRequestDetailsByid=(data,onSuccess)=>(
        {
          type:GET_VAAHAN_DETAILS_BY_ID,
          payload:data,
          onSuccess
        });

  
  
  
 
 
  
  
  
  
  