import React, { useCallback, useEffect, useState } from "react";
import TopBarNav from "../components/topbar";
import Navigation from "../components/navigation";
import { Col, Tabs, Row, Form, Select, Button, Modal, Input } from "antd";
import { connect, useDispatch } from "react-redux";
import {
  onAddNewCase,
  onCaseDetail,
  onCaseMasters,
  onChangeLogNewCaseActiveParticipantTab,
} from "../redux/actions/AuthAction";
import { MESSAGES, REQUIRED_MESSAGES } from "../constants/messages";
import { REGEX } from "../constants/regex";
import AddParticipant from "../components/lognewcase/participant";
import { toast } from "react-toastify";
import NumberFormat from "react-number-format";
import AddParticipants from "../components/participants/AddParticipants";
import { firstCharacterOfEachWordUpperCase } from "../helpers/utils";
const { TabPane } = Tabs;
const layout = {
  labelCol: { span: 12, sm: 24 },
  wrapperCol: { span: 12, sm: 24 },
};
const { Option } = Select;
const AddNewCase = (props) => {
  return (
    <React.Fragment>
      <div className="LogNewCasesWrap">
        <TopBarNav />
        <Navigation />
        <div className="Selector">
          <Form {...layout} name="category">
            <div className="HeaderHighlight">
              <h4>Add New Case</h4>
              <div className="ButtonWrap">
                <Button
                  onClick={() => {
                    props.history.goBack();
                  }}
                >
                  Cancel
                </Button>
                <Button>Save</Button>
              </div>
            </div>

            <Row gutter={32}>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Customer's Name"
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.CUSTOMER_NAME_REQUIRED,
                    },
                  ]}
                >
                  <Input placeholder="Enter Customer Name" />
                </Form.Item>
              </Col>

              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item
                  label="Application Category"
                  name="application_category"
                  rules={[
                    {
                      required: true,
                      message: REQUIRED_MESSAGES.APPLICATION_CATEGORY_REQUIRED,
                    },
                  ]}
                >
                  <Select placeholder={"Please Select Application Category"}>
                    {/*{caseMasters.categories.map((category, index) => (
                  <Option value={category.id.toString()} key={category.id}>
                    {category.category_name}
                  </Option>
                ))}*/}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={6} md={6} sm={12} xs={24}>
                <Form.Item label="SubCategory" name="application_sub_category">
                  <Select placeholder={"Please Select Sub Category"}>
                    {/*{subCategories.map((subCategory) => (
                  <Option value={subCategory.id.toString()} key={subCategory.id}>
                    {subCategory.subcategory_name}
                  </Option>
                ))}*/}
                  </Select>
                </Form.Item>
              </Col>

              <React.Fragment>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Loan/Lease Amount Required"
                    name="loan_amount"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.LOAN_AMOUNT_REQUIRED,
                      },
                    ]}
                  >
                    <NumberFormat
                      placeholder="Loan Amount"
                      class="ant-input"
                      name="Loan Amount"
                      prefix="&#8377;"
                      isAllowed={(values) => {
                        const { formattedValue, floatValue, value } = values;
                        if (value.startsWith("0")) {
                          return false;
                        }
                        return (
                          formattedValue === "" || floatValue <= 10000000000
                        );
                      }}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator={true}
                      decimalScale={0}
                      className="ant-input"
                      // disabled={isNewlyCreatedCaseSaved && !isEditMode}
                    />
                    {/*<Input disabled={isNewlyCreatedCaseSaved && !isEditMode} />*/}
                  </Form.Item>
                </Col>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Business Area"
                    name="business_area"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.BUSINESS_AREA_REQUIRED,
                      },
                    ]}
                  >
                    <Select placeholder="Please Select Business Area"></Select>
                  </Form.Item>
                  {/*{GetBusinessAreas()}*/}
                </Col>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item label="Product Name" name="product_name">
                    <Select placeholder="Please Select Product Segment"></Select>
                  </Form.Item>
                </Col>

                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Product Model No."
                    name="product_model"
                    rules={[
                      {
                        required: true,
                        message:
                          REQUIRED_MESSAGES.PRODUCT_MODAL_NUMBER_REQUIRED,
                      },
                    ]}
                  >
                    <Select placeholder="Please Select Modal No"></Select>
                  </Form.Item>
                </Col>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Quantity Required"
                    name="quantities"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.QUANTITY_REQUIRED,
                      },
                      // {pattern: new RegExp("^([1-9][0-9]{0,2}|10000)$"),message:'only numbers allowed'}
                    ]}
                  >
                    <NumberFormat
                      placeholder="Quantity Required"
                      name="quantities"
                      class="ant-input"
                      isAllowed={(values) => {
                        const { formattedValue, floatValue, value } = values;
                        if (value.startsWith("0")) {
                          return false;
                        }
                        return formattedValue === "" || floatValue <= 10000;
                      }}
                      allowNegative={false}
                      allowLeadingZeros={false}
                      thousandSeparator={true}
                      decimalScale={0}
                      className="ant-input"
                    />
                    {/*<Input disabled={isNewlyCreatedCaseSaved && !isEditMode} />*/}
                  </Form.Item>
                </Col>
                <Col span={6} md={6} sm={12} xs={24}>
                  <Form.Item
                    label="Type of financing"
                    rules={[
                      {
                        required: true,
                        message: REQUIRED_MESSAGES.FINANCE_TYPE,
                      },
                    ]}
                    name="finance_type"
                  >
                    <Select placeholder="Please Select Finance Type">
                      <Option value="loan">loan</Option>
                      <Option value="lease">lease</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </React.Fragment>
            </Row>
          </Form>
        </div>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    isLoggedIn: auth.isLoggedIn,
    userData: auth.userData,
    caseMasters:
      auth.masterData && auth.masterData.caseMasters
        ? auth.masterData.caseMasters
        : [],
    mainApplicant: auth.mainApplicant,
    coApplicant: auth.coApplicant,
    gurantor: auth.gurantor,
    isNewlyCreatedCaseSaved: auth.isNewlyCreatedCaseSaved,
    newlyCreatedCase: auth.newlyCreatedCase,
    pageKey: auth.pageKey,
    logNewCaseActiveKey: auth.logNewCaseActiveKey,
    currentCase: auth.currentCase,
  };
};
export default connect(mapStateToProps, null)(AddNewCase);
