import React from 'react';
import {FadeLoader} from 'react-spinners'

function PageLoading(props) {
    if(props.loading) {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: 999999
            }}><FadeLoader color={'#13656c'} loading={true} /></div>
        );
    }
}

export default React.memo(PageLoading);