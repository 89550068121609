import { Tabs } from "antd";
import React from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { onChangeLogNewCaseActiveParticipantTab } from "../../redux/actions/TabAction";
// import { onChangeLogNewCaseActiveParticipantTab } from "../../redux/actions/AuthAction";
import AddNewParticipant from "./AddNewParticipant";

const AddParticipants = (props)=> {
    const dispatch = useDispatch()
    const {logNewCaseActiveKey,currentCase = {}} = props;
    
    const { TabPane } = Tabs;
    const changeActiveKey = (key) => {
      
        if (key === "1") {
          
          dispatch(onChangeLogNewCaseActiveParticipantTab(key));
        } else if (key === "2") {
          
          if (
            currentCase.main_applicant &&
            currentCase.main_applicant.ocr_documents &&
            Array.isArray(currentCase.main_applicant.ocr_documents) &&
            currentCase.main_applicant.ocr_documents.length > 0
          ) {
            
            dispatch(onChangeLogNewCaseActiveParticipantTab(key));
          } else {
            
            toast.error("Please Add Main Applicant");
          }
        } else if (key === "3") {
          
          if (
            currentCase.main_applicant &&
            currentCase.main_applicant.ocr_documents &&
            Array.isArray(currentCase.main_applicant.ocr_documents) &&
            currentCase.main_applicant.ocr_documents.length > 0
          ) {
            
            if (
              currentCase.co_applicants &&
              Array.isArray(currentCase.co_applicants) &&
              currentCase.co_applicants.length > 0
            ) {
              
              dispatch(onChangeLogNewCaseActiveParticipantTab(key));
            } else {
              
              toast.error("Please Add Atleast One Co-Applicant");
            }
          } else {
            
            toast.error("Please Add Main Applicant");
          }
        }
      };
    return (
        <Tabs activeKey={logNewCaseActiveKey} onChange={changeActiveKey}>
            <TabPane tab="Main Applicant" key="1">
              <AddNewParticipant
                type="Main Applicant"
                isNewCase={true}
              />
            </TabPane>
            <TabPane tab="Co Applicant" key="2">
              <AddNewParticipant
                type="Co Applicant"
              />
            </TabPane>
            <TabPane tab="Guarantor" key="3">
              <AddNewParticipant
                type="Guarantor"
              />
            </TabPane>
          </Tabs>
      );
}
const mapStateToProps = (store) => {
    const { auth } = store;
    return {
      isLoggedIn: auth.isLoggedIn,
      userData: auth.userData,
      caseMasters:
        auth.masterData && auth.masterData.caseMasters
          ? auth.masterData.caseMasters
          : [],
      isCaseSaved: auth.isNewlyCreatedCaseSaved,
      savedCase: auth.currentCase,
      mainApplicant: auth.mainApplicant,
      coApplicant: auth.coApplicant,
      gurantor: auth.gurantor,
      caseDetail:auth.caseDetail,
      currentCase:auth.currentCase,
      logNewCaseActiveKey:auth.logNewCaseActiveKey
    };
  };
  export default connect(mapStateToProps, null)(AddParticipants);
