import React, { Component } from "react";
import CheckIcon from '../../../../../../assets/images/completedcheck.png';
import invalidIcon from '../../../../../../assets/images/invalid.png'
class InfoMatching extends Component {
    render() {
      return (
        <>
        <div className='infoWrap'>
            <h4>Information Matching</h4>
            <div className='TableWrap'>
            <table>
                <thead>
                    <tr>
                        <th>Information On Entired In Case</th>
                        <th>PAN</th>
                        <th>Aadhar</th>
                        <th>DL</th>
                        <th>Voter Id</th>
                        <th>Passport</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Name</td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td><img src={invalidIcon} alt='invalid'/></td>
                    </tr>
                    <tr>
                        <td>DOB</td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                    </tr>
                    <tr>
                        <td>Address</td>
                        <td></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td></td>
                        <td><img src={invalidIcon} alt='invalid'/></td>
                    </tr>
                    <tr>
                        <td>Mobile</td>
                        <td></td>
                        <td><img src={CheckIcon} alt='valid'/></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
            </div>
           
        </div>
            
        </>
      )
    }
}
export default InfoMatching;