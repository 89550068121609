import { Button } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import EditIcon from "../../../assets/images/edit.png";
import { RELATIONSHIPS } from "../../../constants/constants";
import {
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../../redux/actions/CaseAction";
import { onDeleteCoapplicant } from "../../../redux/actions/CoApplicantAction";
import { DeleteCoapplicantModal } from "../../common/modals/DeleteCoapplicantModal";
const CoApplicantListing = (props) => {
  const {
    coApplicants = [],
    editCoApplicant,
    caseDetail,
    afterDeleteCoapplicant,
  } = props;
  const [
    deleteCoapplicantModalVisible,
    setDeleteCoapplicantModalVisible,
  ] = useState(false);
  const [coapplicantDeletionId, setCoapplicantDeletionId] = useState(false);
  const dispatch = useDispatch();
  const deleteCoapplicantConfirmation = () => {
    setDeleteCoapplicantModalVisible(false);
    deleteCoapplicant(coapplicantDeletionId);
  };
  const deleteCoapplicantCancel = () => {
    setDeleteCoapplicantModalVisible(false);
  };
  const deleteCoapplicant = (coappplicantId) => {
    dispatch(
      onDeleteCoapplicant(coappplicantId, () => {
        afterDeleteCoapplicant(coappplicantId);
        dispatch(
          onCaseDetail({ caseId: caseDetail.id }, (response) => {
            dispatch(onUpdateNewCreatedCase(response, () => {}));
          })
        );
      })
    );
  };
  return (
    <React.Fragment>
      <div className="SmallTableWrap SmallTableWrap-v2">
        <h3>Added Co-applicants</h3>
        <table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Co Applicant Type</th>
              {/*<th>Name</th>*/}
              <th>Relation with Applicant</th>
              <th>Stage</th>
              {/*<th>Stage</th>*/}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {coApplicants.map((co_applicant, index) => (
              <tr key={co_applicant.id}>
                <td>{co_applicant.id}</td>
                <td>{co_applicant.category_name}</td>
                {/*<td>{co_applicant.first_name} {co_applicant.last_name}</td>*/}
                <td>
                  {co_applicant.coapplicant_applicant_relation}
                  {co_applicant.coapplicant_applicant_relation &&
                    co_applicant.coapplicant_applicant_relation === "Others" &&
                    `(${co_applicant.other_relation})`}
                </td>
                <td>{co_applicant.stage}</td>
                {/*<td>{co_applicant.stage}</td>*/}
                <td style={{ cursor: "pointer" }}>
                  <div className="btnWrap">
                    <Button
                      onClick={() => {
                        editCoApplicant(co_applicant);
                      }}
                    >
                      Edit
                    </Button>{" "}
                    <Button
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setCoapplicantDeletionId(co_applicant.id);
                        setDeleteCoapplicantModalVisible(true);
                      }}
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}{" "}
          </tbody>
        </table>
        <DeleteCoapplicantModal
          deleteVisible={deleteCoapplicantModalVisible}
          deleteCancel={deleteCoapplicantCancel}
          deleteConfirm={deleteCoapplicantConfirmation}
          title={"Co-Applicant"}
        />
      </div>
    </React.Fragment>
  );
};
export default CoApplicantListing;
