import { Button, Col, Form, Input, Row, Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uplaodIcon from "../../../assets/images/uploadfile.png";
import { HEADINGS, OCR_DOCUMENT_LABEL } from "../../../constants/constants";
import { MESSAGES, REQUIRED_MESSAGES } from "../../../constants/messages";
import { REGEX } from "../../../constants/regex";
import { firstCharacterOfEachWordUpperCase } from "../../../helpers/utils";
import { onMemberDirectorDetail } from "../../../redux/actions/MemberAndDirectorAction";
import {
  beforeUploadNonRequiredOcrDocument,
  beforeUploadOcrAndRequiredNonOcrDocument,
  onRemoveOcrAndRequiredNonOcrDocument,
} from "../helpers/beforeUpload";
import {
  handlePreviewNonOcrDoc,
  handlePreviewOcrDoc,
} from "../helpers/handlePreview";
import { saveMemberOrDirector } from "../helpers/SaveApplicant";
import { updatedApplicantDocuments } from "../helpers/UpdateDefaultDocuments";
import {
  addNonRequiredNonOcrDoc,
  checkAtleastOnePOADocumentUploaded,
  checkValidation,
  deleteMemberDirectorNonOcrDocument,
  getNonOcrFields,
  getOcrFields,
} from "../helpers/utils";
import PreviewImageOrPDF from "../modals/PreviewImageOrPDF";
import OCRResultModal from "../ocr-result-modal";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const AddIndividualForm = (props) => {
  const {
    memberOrDirector,
    closeDirectorMemberModal,
    updateEditCoApplicant,
    data,
    onAddNewMemberDirector,
    handleCancel,
  } = props;

  const [ocrDocuments, setOcrDocuments] = useState([]);
  const [requiredNonOcrDocuments, setRequiredNonOcrDocuments] = useState([]);
  const [nonRequiredNonOcrDocuments, setNonRequiredNonOcrDocuments] = useState(
    []
  );
  const [isOcrModalVisible, setOcrModalVisible] = useState(false);
  const [ocrResponse, setOcrResponse] = useState(false);
  const [editableMemberDirector, setEditableMemberDirector] = useState(null);
  const newlyCreatedCase = useSelector((state) => {
    if (state && state.auth && state.auth.newlyCreatedCase) {
      return state.auth.newlyCreatedCase;
    }else if (state && state.auth && state.auth.caseDetail) {
      return state.auth.newlyCreatedCase;
    }
    return {};
  });
  const [poaDocumentError, setPOADocumentError] = useState(false);
  const [panCardError, setPanCardError] = useState(false);
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const getUpdatedApplicantDocuments = useCallback((applicant) => {
    const {
      uploadedOcrDocuments,
      uploadedRequiredNonOcrDocuments,
      uploadedNonOcrDocuments,
    } = updatedApplicantDocuments(applicant);
    setOcrDocuments((ocrDocuments) => {
      return { ...ocrDocuments, ...uploadedOcrDocuments };
    });
    setRequiredNonOcrDocuments((requiredDocs) => {
      return { ...requiredDocs, ...uploadedRequiredNonOcrDocuments };
    });
    setNonRequiredNonOcrDocuments(uploadedNonOcrDocuments);
  }, []);
  useEffect(() => {
    form.setFields([{ name: "pan_card", errors: [panCardError] }]);
  }, [form, panCardError]);
  useEffect(() => {
    const setMemberDirectorNameField = () => {
      form.setFieldsValue({
        customer_name: memberOrDirector.first_name
          ? `${memberOrDirector.first_name?memberOrDirector.first_name:''} ${memberOrDirector.last_name?memberOrDirector.last_name:''}`
          : memberOrDirector.customer_name?memberOrDirector.customer_name:'',
          aadhaar_no:memberOrDirector.aadhaar_no
      });
    };
    if (memberOrDirector) {
      dispatch(
        onMemberDirectorDetail(memberOrDirector.id, (member_director) => {
          setEditableMemberDirector(memberOrDirector);
          getUpdatedApplicantDocuments(memberOrDirector);
        })
      );

      setMemberDirectorNameField();
    }
  }, [memberOrDirector, form, getUpdatedApplicantDocuments, dispatch]);

  const OcrDocument = () => {
    const GetOcrDocumentField = ({ label, name, message }) => {
      if (label === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
        return (
          <Col
            span={12}
            md={12}
            xs={24}
            sm={24}
            className="edit-participant-details"
          >
            <Row gutter={0}>
              <Col span={12} md={8} xs={8} sm={8}>
                <label class="custom-label" title="Document Name / Upload">
                  {/*GST Number / Upload <span className="requiredAsterik"></span>*/}
                  Aadhar Card Number / Upload <span></span>
                </label>
              </Col>
              <Col span={12} md={16} xs={16} sm={16}>
              <div className="applicant-upload-block">
                  <Row gutter={2}>
                    <Col span={12} md={12} xs={12} sm={12}>
                      <Form.Item
                        name={"aadhaar_no"}
                        label=""
                        // rules={[
                        //   // {
                        //   //   required: true,
                        //   //   message: REQUIRED_MESSAGES.GST_NUMBER_REQUIRED,
                        //   // },
                        //   // {
                        //   //   len: 12,
                        //   //   // pattern:REGEX.AADHAR_CARD_NUMBER_REGEX,
                        //   //   message: MESSAGES.AADHAR_NUMBER_INVALID,
                        //   //   validateTrigger: true,
                        //   // },
                          
                        // ]}
                      >
                        <Input placeholder={"Aadhar Card Number"} maxLength={12} onChange={(e) => {
                          form.setFields([
                            {
                              name: "aadhaar_no",
                              errors: [],
                            },
                          ]);
                          if(!e.target.value){
                            form.setFields([
                              {
                                name: "aadhar_card",
                                errors: [],
                              },
                            ]);
                          }
                        }}/>
                      </Form.Item>
                    </Col>
                    <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                    // label={label}
                    name={name}
                    required={message ? true : false}
                    extra={
                      label === OCR_DOCUMENT_LABEL.AADHAR_CARD
                        ? MESSAGES.AADHARD_CARD_NOTE
                        : label === OCR_DOCUMENT_LABEL.VOTER_ID
                        ? MESSAGES.VOTER_ID_NOTE
                        : ""
                    }
                    // rules={[
                    //   {
                    //     required: message ? true : false,
                    //     message: message,
                    //   },
                    // ]}
                  >
                    <Upload
                      maxCount={1}
                      beforeUpload={(file, fileList, e) => {
                        if (name !== "pan_card") {
                          setPOADocumentError(false);
                        }
                        const res = beforeUploadOcrAndRequiredNonOcrDocument(
                          file,
                          fileList,
                          name,
                          ocrDocuments
                        );
                        if (res) {
                          setOcrDocuments(res);
                          return false;
                        } else {
                          return Upload.LIST_IGNORE;
                        }
                      }}
                      fileList={ocrDocuments[name]}
                      onPreview={(file, fileList) => {
                        handlePreviewOcrDoc(file, dispatch);
                      }}
                      // onRemove={() => {
                      //   if (ocrDocuments[name][0] instanceof File) {
                      //     onRemoveOcrDoc(name);
                      //   }
                      // }}
                      showUploadList={{
                        removeIcon:
                          ocrDocuments[name] &&
                          ocrDocuments[name][0] &&
                          !(ocrDocuments[name][0] instanceof File),
                      }}
                    >
                      <Button>
                        Upload {label} <img src={uplaodIcon} alt="uplaodIcon" />
                      </Button>
                    </Upload>
                  </Form.Item>
                    </Col>
                  </Row>
                </div>
                
              </Col>
            </Row>
          </Col>
        );
      }
      return (
        <Col span={12} md={12} xs={24} sm={24}>
          <Form.Item
            label={label}
            name={name}
            required={message ? true : false}
            extra={
              label === OCR_DOCUMENT_LABEL.AADHAR_CARD
                ? MESSAGES.AADHARD_CARD_NOTE
                : label === OCR_DOCUMENT_LABEL.VOTER_ID
                ? MESSAGES.VOTER_ID_NOTE
                : ""
            }
            // rules={[
            //   {
            //     required: message ? true : false,
            //     message: message,
            //   },
            // ]}
          >
            <Upload
              maxCount={1}
              beforeUpload={(file, fileList, e) => {
                if (name !== "pan_card") {
                  setPOADocumentError(false);
                }
                const res = beforeUploadOcrAndRequiredNonOcrDocument(
                  file,
                  fileList,
                  name,
                  ocrDocuments
                );
                if (res) {
                  setOcrDocuments(res);
                  return false;
                } else {
                  return Upload.LIST_IGNORE;
                }
              }}
              fileList={ocrDocuments[name]}
              onPreview={(file, fileList) => {
                handlePreviewOcrDoc(file, dispatch);
              }}
            >
              <Button>
                Upload <img src={uplaodIcon} alt="uplaodIcon" />
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      );
    };
    return (
      <React.Fragment>
        {getOcrFields("1", "").map((ocr_field) => {
          return (
            <GetOcrDocumentField
              label={ocr_field.label}
              name={ocr_field.name}
              message={ocr_field.message}
            />
          );
        })}
      </React.Fragment>
    );
  };
  const RequiredNonOcrDocument = () => {
    const GetNonOcrDocumentField = ({ label, name, message }) => {
      return (
        <Col span={12} md={12} xs={24} sm={24}>
          <Form.Item
            label={label}
            name={name}
            // required={true}
            // rules={[
            //   {
            //     required: true,
            //     message: message,
            //   },
            // ]}
          >
            <Upload
              maxCount={1}
              beforeUpload={(file, fileList) => {
                const res = beforeUploadOcrAndRequiredNonOcrDocument(
                  file,
                  fileList,
                  label,
                  requiredNonOcrDocuments
                );
                if (res) {
                  setRequiredNonOcrDocuments(res);
                  return false;
                } else {
                  return Upload.LIST_IGNORE;
                }
              }}
              fileList={requiredNonOcrDocuments[label]}
              onRemove={() => {
                let filteredDocs = onRemoveOcrAndRequiredNonOcrDocument(
                  name,
                  requiredNonOcrDocuments
                );
                setRequiredNonOcrDocuments(filteredDocs);
              }}
              onPreview={(file, fileList) => {
                handlePreviewOcrDoc(file, dispatch);
              }}
            >
              <Button>
                Upload <img src={uplaodIcon} alt="uplaodIcon" />
              </Button>
            </Upload>
          </Form.Item>
        </Col>
      );
    };
    return (
      <React.Fragment>
        {getNonOcrFields("1", "").map((non_ocr_field) => {
          return (
            <GetNonOcrDocumentField
              label={non_ocr_field.label}
              name={non_ocr_field.name}
              message={non_ocr_field.message}
            />
          );
        })}
      </React.Fragment>
    );
  };
  const AddNonRequiredNonOcrDoc = () => {
    const handleDocumentName = (e) => {
      const input = e.target.value;
      if (!REGEX.NAME_VALIDATION.test(input)) {
        form.setFieldsValue({
          // 'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1))).replace( /[^a-zA-Z0-9 ]/gm, '')
          DocName: firstCharacterOfEachWordUpperCase(
            input.substring(0, input.length - 1)
          )
            .replace(/\s\s+/g, " ")
            .replace(/^\s+/g, ""),
        });
      } else {
        form.setFieldsValue({
          DocName: firstCharacterOfEachWordUpperCase(input)
            .replace(/\s\s+/g, " ")
            .replace(/^\s+/g, ""),
        });
      }
    };
    const setErrors = (name, errors) => {
      form.setFields([
        {
          name,
          errors,
        },
      ]);
    };
    const addButtonHandler = () => {
      setErrors("DocName", []);
      const documentName = form.getFieldValue("DocName");
      const uploadedDocument = form.getFieldValue("non_ocr_doc_pic");
      const result = addNonRequiredNonOcrDoc(
        documentName?firstCharacterOfEachWordUpperCase(documentName.trim()):documentName,
        uploadedDocument,
        nonRequiredNonOcrDocuments
      );
      if (result) {
        setErrors(result.name, result.errors);
        return;
      }
      setNonRequiredNonOcrDocuments(
        nonRequiredNonOcrDocuments.concat({
          name: documentName?firstCharacterOfEachWordUpperCase(documentName.trim()):documentName,
          doc: uploadedDocument,
        })
      );
      form.resetFields(["DocName", "non_ocr_doc_pic"]);
    };
    const removeNonOcrDocument = async (document, index) => {
      const filteredDocs = (docs) => {
        setNonRequiredNonOcrDocuments(docs);
        if (document.id) {
        }
      };

      const docs = await deleteMemberDirectorNonOcrDocument(
        document,
        nonRequiredNonOcrDocuments,
        index,
        dispatch,
        filteredDocs,
        newlyCreatedCase.id
      );
      // setNonRequiredNonOcrDocuments(docs);
    };
    return (
      <Row gutter={32}>
        <Col span={12} md={12} xs={24} sm={24}>
          <Row gutter={0}>
            <Col span={12} md={8} xs={8} sm={8}>
              <label class="custom-label" title="Document Name / Upload">
                Document Name / Upload
              </label>
            </Col>
            <Col span={12} md={16} xs={16} sm={16}>
              <div className="applicant-upload-block">
                <Row gutter={2}>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item name="DocName" label="">
                      <Input
                        onFocus={() => {
                          setErrors("DocName", []);
                        }}
                        // onChange={handleDocumentName}
                        name="DocName"
                        placeholder='Document Name'
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      label=""
                      name="non_ocr_doc_pic"
                      onFocus={() => {
                        setErrors("non_ocr_doc_pic", []);
                      }}
                    >
                      <Upload
                        maxCount={1}
                        beforeUpload={(file, fileList) => {
                          if (
                            beforeUploadNonRequiredOcrDocument(file, fileList)
                          ) {
                            return Upload.LIST_IGNORE;
                          } else {
                            return false;
                          }
                        }}
                      >
                        <Button>
                          Upload <img src={uplaodIcon} alt="uplaodIcon" />
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <div className="AddField text-right mt-2 mb-2">
            <Button htmlType="button" onClick={addButtonHandler}>
              Add
            </Button>
          </div>
        </Col>
        <Col span={12} md={12} xs={24} sm={24}>
          {nonRequiredNonOcrDocuments.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Document Name </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {nonRequiredNonOcrDocuments &&
                  nonRequiredNonOcrDocuments.map((document, index) => {
                    return (
                      <tr key={document.name}>
                        <td>{document.name}</td>
                        <td>
                          <Button
                            onClick={() => {
                              handlePreviewNonOcrDoc(document, dispatch);
                            }}
                          >
                            View
                          </Button>
                          <Button
                            onClick={() => {
                              removeNonOcrDocument(document, index);
                              // const result = deleteNonOcrDocument(
                              //   document,
                              //   nonRequiredNonOcrDocuments,
                              //   index,
                              //   newlyCreatedCase.id,
                              //   dispatch
                              // );
                              // if (
                              //   result &&
                              //   result.status &&
                              //   result.status === "1"
                              // ) {
                              //   setNonRequiredNonOcrDocuments(result.docs);
                              // }
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    );
  };
  const displayErrorMessage = () => {
    let aadhar_no = form.getFieldValue("aadhaar_no");
    let errors = checkValidation(
      "1",
      "",
      ocrDocuments,
      requiredNonOcrDocuments,
      "",
      aadhar_no
    );
    let isOnePOADocumentUploaded = checkAtleastOnePOADocumentUploaded(
      ocrDocuments
    );

    if (!isOnePOADocumentUploaded) {
      setPOADocumentError(
        REQUIRED_MESSAGES.PLEASE_UPLOAD_ATLEAST_ONE_POA_DOCUMENT
      );
    } else {
      setPOADocumentError(false);
    }
    errors.forEach((error) => {
      if (error.name === "pan_card") {
        setPanCardError(error.message);
      }
      form.setFields([{ name: error.name, errors: [error.message] }]);
    });
    return errors;
  };
  const onFinish = (values) => {
    let err = displayErrorMessage();
    if (err.length > 0 || poaDocumentError) {
      return;
    }

    const params = {
      fk_case_id: newlyCreatedCase.id,
      applicant_type: data.applicant_type,
      member_type: data.member_type,
      applicant_coapplicant_guarantor_id: data.applicant_id,
      member_director_id: editableMemberDirector
        ? editableMemberDirector.id
        : "",
      member_director_name: values.customer_name,
      ocr_documents: ocrDocuments,
      non_ocr_documents: nonRequiredNonOcrDocuments,
      non_ocr_required_documents: requiredNonOcrDocuments,
      // aadhaar_no:values.aadhaar_no,
    };
    if(!(editableMemberDirector && editableMemberDirector.aadhaar_no && editableMemberDirector.aadhaar_no === values.aadhaar_no)){
      params.aadhaar_unmasked = values.aadhaar_no
    }
    const getOcrResponse = (ocrResponse) => {
      setOcrModalVisible(true);
      setOcrResponse(ocrResponse);
    };
    saveMemberOrDirector(
      params,
      dispatch,
      closeDirectorMemberModal,
      updateEditCoApplicant,
      onAddNewMemberDirector,
      handleCancel,
      true,
      getOcrResponse
    );
  };
  const handleCustomerName = (e) => {
    const input = e.target.value;
    if (!REGEX.NAME_VALIDATION.test(input)) {
      form.setFieldsValue({
        // 'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1))).replace( /[^a-zA-Z0-9 ]/gm, '')
        customer_name: firstCharacterOfEachWordUpperCase(
          input.substring(0, input.length - 1)
        )
          .replace(/\s\s+/g, " ")
          .replace(/^\s+/g, ""),
      });
    } else {
      form.setFieldsValue({
        customer_name: firstCharacterOfEachWordUpperCase(input)
          .replace(/\s\s+/g, " ")
          .replace(/^\s+/g, ""),
      });
    }
  };
  return (
    <React.Fragment>
      <Form {...layout} name="mainapplicant" form={form} onFinish={onFinish}>
        <Row gutter={32}>
          <Col span={24} md={24} sm={24} xs={24}>
            {editableMemberDirector && (
              <h4>
                {editableMemberDirector.member_type} ID:{" "}
                {editableMemberDirector.id}
                <div>Status: {editableMemberDirector.stage}</div>
              </h4>
            )}
          </Col>
          <Col span={12} md={12} sm={12} xs={24}>
            <Form.Item
              label={`${data.member_type} Name`}
              name="customer_name"
              rules={[
                {
                  required: true,
                  message: `${data.member_type} Name is required`,
                },
                {
                  max: 50,
                  message: "Maximum 50 characters can be entered",
                },
              ]}
            >
              <Input
                placeholder={`Enter ${data.member_type} Name`}
                onChange={handleCustomerName}
                maxLength={51}
              />
            </Form.Item>
          </Col>
          <Col span={24} md={24} xs={24} sm={24}>
            <h4>
              {HEADINGS.OCR_DOCUMENT_HEADING}
              <span className="SubHeading">
                {HEADINGS.OCR_DOCUMENT_SUB_HEADING}
              </span>
            </h4>
          </Col>
          <OcrDocument />
          {/*<AddOcrDocument />*/}
        </Row>
        <div className={poaDocumentError ? "smallErrorMessage" : ""}>
          Note: {REQUIRED_MESSAGES.PLEASE_UPLOAD_ATLEAST_ONE_POA_DOCUMENT}
        </div>
        <Row gutter={32}>
          <Col span={24}>
          <h4>
          {HEADINGS.NON_OCR_DOCUMENT_HEADING}
          <span className="SubHeading">
            {HEADINGS.NON_OCR_DOCUMENT_SUB_HEADING}
          </span>
        </h4>
          </Col>
          <RequiredNonOcrDocument />
        </Row>
        <AddNonRequiredNonOcrDoc />
        {/*<AddNonOcrDocument />*/}
        <div className="MainBtnWrap">
          <Button className="plane" onClick={closeDirectorMemberModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={displayErrorMessage}
          >
            {editableMemberDirector ? "Update" : "Save"}
          </Button>
          {/*<Button htmlType="submit" onClick={displayErrorMessage}>
            Save And Run OCR
  </Button>*/}
        </div>
      </Form>
      {/*<OCRResultModal 
        isVisible={isOcrModalVisible}
        ocrResponse={ocrResponse}
        handleOk = {()=>{
          setOcrModalVisible(false);
          setOcrResponse({});
          if(closeDirectorMemberModal){
            closeDirectorMemberModal()
          }
        }}
      />*/}
      <PreviewImageOrPDF />
    </React.Fragment>
  );
};
export default AddIndividualForm;
