import React, { useEffect, useState } from "react";
import { Form, Select, DatePicker, Button, Row, Col, Space } from "antd";
import {
  disabledFutureDates,
  loggedInUserData,
  numberWithCommas,
} from "../../helpers/utils";
import moment from "moment";
import { toast } from "react-toastify";
import { MESSAGES } from "../../constants/messages";
const { RangePicker } = DatePicker;

let masterData = [];
const Filters = (props) => {
  const { Option } = Select;
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [clouserDateFrom, setClouserDateFrom] = useState("");
  const [clouserDateTo, setClouserDateTo] = useState("");
  const [initialDateFrom, setInitialDateFrom] = useState("");
  const [initialDateTo, setInitialDateTo] = useState("");

  const dateFormat = "YYYY-MM-DD";
  if (props.authMaster && props.authMaster.caseMasters) {
    masterData = props.authMaster.caseMasters;
  }
  useEffect(() => {
    window.scrollTo(0, 10);
  }, []);

  const onFinish = (values) => {
    if (values.from_date && values.to_date) {
      if (!moment(values.from_date).isSame(values.to_date)) {
        if (values.from_date.isAfter(values.to_date)) {
          toast.error(MESSAGES.FROM_DATE_MUST_BE_LESS_THAN_TO_DATE);
          return;
        }
      }
    }
    let filterData = `customer_type=${
      values.application_category ? values.application_category : ""
    }&from_date=${
      values.from_date ? moment(values.from_date).format("YYYY-MM-DD") : ""
    }&to_date=${
      values.to_date ? moment(values.to_date).format("YYYY-MM-DD") : ""
    }&business_area=${
      values.business_area ? values.business_area : ""
    }&pending_since=${
      values.pending_since ? values.pending_since : ""
    }&allocated_to=${values.allocated_to ? values.allocated_to : ""}`;

    if (props.case_type === "pending") {
      props.getFiltered(filterData);
    } else {
      let closefilterData = `from_date=${
        initialDateFrom ? initialDateFrom : ""
      }&to_date=${initialDateTo ? initialDateTo : ""}&closure_from=${
        clouserDateFrom ? clouserDateFrom : ""
      }&closure_to=${clouserDateTo ? clouserDateTo : ""}&business_area=${
        values.business_area ? values.business_area : ""
      }&tat=${values.tat ? values.tat : ""}`;
      props.getFiltered(closefilterData);
    }
  };

  const onReset = () => {
    let formvalues = form.getFieldValue();
    
    if (
      formvalues.application_category ||
      formvalues.from_date ||
      formvalues.to_date ||
      formvalues.business_area ||
      formvalues.allocated_to ||
      formvalues.pending_since
    ) {
      props.reset();
    }
    if (
      initialDateFrom ||
      initialDateTo ||
      clouserDateFrom ||
      clouserDateTo ||
      formvalues.tat ||
      formvalues.business_area
    ) {
      props.reset();
    }

    setInitialDateFrom("");
    setInitialDateTo("");
    setClouserDateFrom("");
    setClouserDateTo("");
    form.resetFields();
  };
  const getDates = (dates, dateStrings) => {
    setInitialDateFrom(dateStrings[0]);
    setInitialDateTo(dateStrings[1]);
  };

  const getClosuerDates = (dates, dateStrings) => {
    setClouserDateFrom(dateStrings[0]);
    setClouserDateTo(dateStrings[1]);
  };

  return (
    <React.Fragment>
      <div className="FilterList">
        <div className="HeadWrap">
          <h3>{props.title}</h3>
        </div>
      </div>
      <div className="FilterForm PendingReview formexpandedfilter">
        <Form
          name="global_state"
          layout="inline"
          form={form}
          onFinish={onFinish}
        >
          <Row>
            <Col>
              <Form.Item name="business_area" label="Business Area">
                <Select defaultValue="">
                  <Option value="">All</Option>
                  {masterData.business_areas &&
                    masterData.business_areas.map((business_area) => (
                      <Option value={business_area.id} key={business_area.id}>
                        {business_area.business_area}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            {props.case_type === "pending" ? (
              <React.Fragment>
                <Col>
                  <Form.Item
                    name="application_category"
                    label="Application Category"
                  >
                    <Select defaultValue="">
                      <Option value="">All</Option>
                      {masterData.categories &&
                        masterData.categories.map((category, index) => (
                          <Option value={category.id} key={category.id}>
                            {category.category_name}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="pending_since" label="Pending Since Days">
                    <Select defaultValue="All">
                      <Option value="">All</Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5+">5+</Option>
                      <Option value="10+">10+</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </React.Fragment>
            ) : (
              ""
            )}

            {props.case_type === "pending" &&
            props.userData &&
            props.userData.user_type === "Credit" ? (
              <Col>
                <Form.Item name="allocated_to" label="Allocated To">
                  <Select defaultValue="All">
                    <Option value="">All</Option>
                    {props.creditUser &&
                      props.creditUser.map((user) => (
                        <Option value={user.id} key={user.id}>
                          {user.first_name} {user.last_name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            ) : (
              ""
            )}
            {props.case_type === "close" ? (
              <React.Fragment>
                <Col>
                  <Form.Item name="initial_range" label="Created Date">
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        onChange={getDates}
                        renderExtraFooter={() => "extra footer"}
                      />
                    </Space>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="closure_range" label="Closed Date">
                    <Space direction="vertical" size={12}>
                      <RangePicker
                        onChange={getClosuerDates}
                        renderExtraFooter={() => "extra footer"}
                      />
                    </Space>
                  </Form.Item>
                </Col>

                <Col>
                  <Form.Item name="tat" label="TAT">
                    <Select defaultValue="All">
                      <Option value="">All</Option>
                      <Option value="1">1</Option>
                      <Option value="2">2</Option>
                      <Option value="3">3</Option>
                      <Option value="4">4</Option>
                      <Option value="5">5+</Option>
                      <Option value="10">10+</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Col>
                  <Form.Item name="from_date" label="From">
                    <DatePicker
                      disabledDate={disabledFutureDates}
                      inputReadOnly
                      format="ll"
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="to_date" label="To">
                    <DatePicker
                      disabledDate={disabledFutureDates}
                      inputReadOnly
                      format="ll"
                    />
                  </Form.Item>
                </Col>
              </React.Fragment>
            )}

            <Col className={props.case_type === "close" ? "" : "filterbutton"}>
              <div className="BtnGroup">
                <Button type="primary" htmlType="submit">
                  Apply Filters
                </Button>
                <Button onClick={onReset}>Clear All</Button>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </React.Fragment>
  );
};

export default Filters;
