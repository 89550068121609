import {
  GET_BUREAU_REPORT_LISTING,
  GET_BUREAU_REPORT_MASTER,
  GENERATE_BUREAU_REPORT,
  SET_BUREAU_REPORT_LISTING,
  SET_SELECTED_BUREAU_REPORT,
  VIEW_BUREAU_REPORT,
  GET_BUREAU_REPORT_HTML,
  GET_BUREAU_REPORT_PARAMETERS,
  GET_BUREAU_REPORT_LIST,
  GET_BUREAU_REPORT_SUMMARY,
  GET_CIBIL_REPORT_MASTER,
  GENERATE_CIBIL_REPORT,
  GET_BUREAU_REPORT_EXCEL,
  GENERATE_BUREAU_REPORT_CHECK,
  BUREAU_DONE_CASES_LIST,
  } from "../constants/ActionTypes";
  
  export const onFetchBureauReportListing = (data) => {
    return {
      type: GET_BUREAU_REPORT_LISTING,
      data:data,
      
    };
  };

  export const onFetchBureauReportMaster = (data) => {
    return {
      type: GET_BUREAU_REPORT_MASTER,
      data:data
    };
  };

  export const onFetchCibilReportMaster = () => {
    return {
      type: GET_CIBIL_REPORT_MASTER,
      // data:data
    };
  };

  export const onGenerateBureauReport = (data,onApiSuccess,onApiError) => { 
    return {
      type: GENERATE_BUREAU_REPORT,
      data:data,
      onApiSuccess,
      onApiError
    };
  };
  export const onGenerateCibilReport = (data,onApiSuccess,onApiError) => { 
    return {
      type: GENERATE_CIBIL_REPORT,
      data:data,
      onApiSuccess,
      onApiError
    };
  };
  
  

  export const onChangeSelectedBureauReportView = (data,onSuccess) => {
    return {
      type: SET_SELECTED_BUREAU_REPORT,
      data:data,
    };
  };
  export const onViewBureauReport = (bureauReportId,onSuccess)=>({
    type: VIEW_BUREAU_REPORT,
    bureauReportId,
    onSuccess,
  });

  export const onSetBureauReportListing = (data) => {
    return {
      type: SET_BUREAU_REPORT_LISTING,
      data:data,
    };
  };
  
  export const onFetchBureauReportHtml = (data,onSuccess) => {
    return {
      type: GET_BUREAU_REPORT_HTML,
      data:data,
      onSuccess
    };
  };

  export const onBureauReportParameters = (data,onSuccess) => {
    return {
      type: GET_BUREAU_REPORT_PARAMETERS,
      data:data,
      onSuccess
    };
  };
  export const onGetBureauReportListing = (data,onSuccess,onError)=>{
    return {
      type:GET_BUREAU_REPORT_LIST,
      data,
      onSuccess
    }
  }
  export const onGetBureauReportSummary = (data,onSuccess)=>{
    return {
      type:GET_BUREAU_REPORT_SUMMARY,
      data,
      onSuccess
    }
  }
  export const onGetBureauReportExcel = (data,onSuccess)=>{
    return {
      type:GET_BUREAU_REPORT_EXCEL,
      data,
      onSuccess
    }
  }
  export const onGetBureauReportCheckParam = (data,onApiCheckSuccess)=>{
    return {
      type:GENERATE_BUREAU_REPORT_CHECK,
      data,
      onApiCheckSuccess
    }
  }

  //Action to get bureau report generated cases 
  export const getBureauReportGeneratedCasesList = () => {
    return {
      type: BUREAU_DONE_CASES_LIST,
    };
  };

  