import React, { useEffect, useState } from "react";
import { Row, Col, Button, Modal, Form } from "antd";
import UploadDocument from "../modals/uploadDoument";
import RunKycCheck from "../modals/runkyccheck";
import BureauReportForm from "../modals/bureaureport";
import AskJustification from "../modals/askjustification";
import CreditReview from "../modals/creditreview";
import ReAllocate from "../modals/runkyccheck";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import { onChangeUploadDocumentActiveParticipantTab } from "../../../redux/actions/TabAction";
import { onResetParticipant } from "../../../redux/actions/ParticipantAction";
import { isSalesUser, loggedInUserData } from "../../../helpers/utils";
import ReAllocationModal from "../../modals/reAllocationModal";
import { onGetCities, onGetStates } from "../../../redux/actions/MasterData";
import {
  onCaseDetail,
  onGetUserByStateCity,
  onCaseReallocation,
  onCaseRejectApprove,
} from "../../../redux/actions/CaseAction";
import { InformationModal } from "../../modals/InformationModal";
import RejectCaseModal from "../../modals/RejectCaseModal";

// import { onChangeUploadDocumentActiveParticipantTab, onResetParticipant } from "../../../redux/actions/AuthAction";
const CaseDetailHeader = (props) => {
  const { caseDetail } = props;
  const newlyCreatedCase = useSelector((state) => state.auth.newlyCreatedCase);
  const userData = loggedInUserData();
  const [uploaddocument, setUploaddocument] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [runkyc, setRunkyc] = useState(false);
  const [BureauReport, setBureauReport] = useState(false);
  const [visible, setVisible] = useState(false);
  const [CreditReview, setCreditReview] = useState(false);
  const [reallocate, setReallocate] = useState(false);
  const [Justification, setJustification] = useState(false);
  const [listofusers, setListofusers] = useState([]);
  const [modalTitle, setModalTitle] = useState(false);
  const [bureauReportNotDoneModal, setBureauReportNotDoneModal] =
  useState(false);
  const [rejectCaseOpen, setRejectCaseOpen] = useState(false);

  const state = useSelector((state) => state.auth.states);
  const cities = useSelector((state) => state.auth.cities);
  // const userPermissions = useSelector(state => state.auth.userPermissions)

  const history = useHistory();
  const [form] = Form.useForm();

  // state = {
  //   uploaddocument: false,
  //   runkyc: false,
  //   BureauReport: false,
  //   visible: false,
  //   CreditReview: false,
  //   reallocate: false,
  // };
  useEffect(() => {
    dispatch(onGetStates());
    dispatch(onGetUserByStateCity(onSuccessUsers));
  }, []);
  const setModal2Visible = (uploaddocument) => {
    dispatch(onChangeUploadDocumentActiveParticipantTab("1"));
    setUploaddocument(uploaddocument);
    if (newlyCreatedCase.status !== "Case Logged") {
      history.push(`/viewOnGoingcase/${caseDetail.id}`);
    }
  };
  const KycCheck = (runkyc) => {
    setRunkyc(runkyc);
  };
  // const ReAllocate = (reallocate) => {
  //   setReallocate(reallocate)
  // }
  const bureauReport = (BureauReport) => {
    setBureauReport(BureauReport);
  };
  const askJustification = (Justification) => {
    setJustification(Justification);
  };
  const creditreview = (CreditReview) => {
    setCreditReview(CreditReview);
  };
  const handleCancel = () => {
    setJustification(false);
    setCreditReview(false);
    setReallocate(false);
    dispatch(onChangeUploadDocumentActiveParticipantTab("1"));
  };
  const dispatch = useDispatch();
  const closeUploadDocumentsModalHandler = () => {
    setRunkyc(false);
    setModal2Visible(false);
    dispatch(onChangeUploadDocumentActiveParticipantTab("1"));
  };

  const handleOk = (values) => {
    values.case_id = caseDetail.id;

    dispatch(onCaseReallocation(values, onSuccessApi));
  };

  const onSuccessApi = (response) => {
    form.resetFields();
    setReallocate(false);
    history.push("/OnGoingCases");
  };
  const getCity = (data) => {
    dispatch(onGetCities(data, onSuccessCity));
  };
  const onSuccessCity = (data) => { };
  const onSuccessUsers = (data) => {
    console.log(data, 'data')
    setListofusers(data);
  };
  const getUserbystatecity = () => {
    dispatch(onGetUserByStateCity(onSuccessUsers));
  };
  const handleCaseReject = () => {
    setModalTitle("");
    setBureauReportNotDoneModal(false);
    setRejectCaseOpen(true);
  };
  const handleRejectForm = (values) => {
    const onSuccess = (response) => {
      console.log(props,'>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
      setRejectCaseOpen(false);
      history.push("/closedcases");
    };
    values.case_id = caseDetail.id;

    values.is_approved = 0;
    dispatch(onCaseRejectApprove(values, onSuccess));
  };
  
  const handleCancelReject = () => {
    setRejectCaseOpen(false);
  };
  return (
    <React.Fragment>
      <div className="CaseDetailHeader">
        <div className="InnerWrap">
          <Row>
            <Col span={4} xs={24} sm={24} md={12}>
              <p className="CaseId">
                Case ID - {caseDetail.id}{" "}
                <span>
                  <span>{caseDetail.status}</span>
                  <span>{caseDetail.created_on}</span>
                </span>
              </p>
            </Col>
            <Col span={4} xs={24} sm={24} md={12}>
              <div className="BtnWrap">
                {userData && userData.permissions && userData.permissions.Case && userData.permissions.Case.edit && isSalesUser(userData) && (
                  <Button
                    onClick={() => {
                      dispatch(onResetParticipant(true));
                      setRunkyc(false);
                      setModal2Visible(true);
                    }}
                  >
                    Upload Documents
                  </Button>
                )}
                {/* <Button>Re-Allocate</Button> */}
                {userData && userData.permissions && userData.permissions.Case && userData.permissions.Case.edit && <Button onClick={() => setReallocate(true)}>Re-Allocate</Button>}
                <Button onClick={handleCaseReject}>Reject</Button>
                <Link to='/OnGoingCases'><Button>Back</Button></Link>
                {/* <Button  onClick={() => this.bureauReport(true)}>Run Bureao Reports</Button> */}
                {/* <Button onClick={() => this.askJustification(true)}>Ask for Justification</Button> */}
                {/* <Button onClick={() => this.creditreview(true)}>Request Credit for Review</Button> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* Upload Documents Modal */}
      {userData && userData.permissions && userData.permissions.Case && userData.permissions.Case.edit && <Modal
        title="Step 1 - Upload Documents"
        centered
        visible={uploaddocument}
        onOk={() => setModal2Visible(false)}
        onCancel={() => closeUploadDocumentsModalHandler()}
        footer={null}
        width={1000}
        className="ModalFormWrap AddCoapplicant"
        style={{ top: 50 }}
        destroyOnClose={true}
      >
        <UploadDocument
          changeUploaded={() => {
            setIsUploaded(true);
          }}
          closeUploadDocumentsModalHandler={closeUploadDocumentsModalHandler}
        />
      </Modal>}
      {/* Upload Documents Modal */}
      {/* ReAllocate */}
      <ReAllocationModal
        isModalVisible={reallocate}
        handleOk={handleOk}
        handleCancel={handleCancel}
        states={state}
        cities={cities}
        getCity={getCity}
        getUserbystatecity={getUserbystatecity}
        listofusers={listofusers}
      />

      {/* ReAllocate */}
      {/* Run KYC */}
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={runkyc}
        onOk={() => KycCheck(false)}
        onCancel={() => KycCheck(false)}
        footer={null}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunKycCheck />
      </Modal>
      {/* Run KYC */}

      {/* Run Bureao Reports */}
      <Modal
        title="Bureau Report Generation"
        centered
        visible={BureauReport}
        onOk={() => bureauReport(false)}
        onCancel={() => bureauReport(false)}
        footer={null}
        width={1000}
        className="BeureauReport"
        style={{ top: 50 }}
      >
        <BureauReportForm />
      </Modal>
      {/* Run Bureao Reports */}

      {/* Ask For Justification */}
      <Modal
        title="Justification Requirements "
        centered
        visible={Justification}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button className="filled" onClick={handleCancel}>
            Submit
          </Button>,
        ]}
        width={1000}
        className="JustReqWrap"
        style={{ top: 50 }}
      >
        <AskJustification />
      </Modal>
      {/* Ask For Justification */}
      {/* Credit Review */}
      <Modal
        title="Credit Review Request"
        centered
        visible={CreditReview}
        onCancel={handleCancel}
        footer={[
          <Button onClick={handleCancel}>Cancel</Button>,
          <Button className="filled" onClick={handleCancel}>
            <Link to="/OnGoingCases"> Submit</Link>
          </Button>,
        ]}
        width={1000}
        className="JustReqWrap"
        style={{ top: 50 }}
      >
        <CreditReview />
      </Modal>
      <RejectCaseModal
      isModalVisible={rejectCaseOpen}
      handleOk={handleRejectForm}
      handleCancel={handleCancelReject}
    />
      {/* Ask For Justification */}
    </React.Fragment>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
  };
};
export default connect(mapStateToProps, null)(CaseDetailHeader);
