import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Input,
  Form,
  Upload,
  Button,
  Radio,
  Select,
  Modal,
} from "antd";
import uplaodIcon from "../../assets/images/uploadfile.png";
// import PreviewImageOrPDF from "../../common/modals/PreviewImageOrPDF";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileTypeFromName,
} from "../../helpers/utils";
import { MESSAGES, REQUIRED_MESSAGES } from "../../constants/messages";
const { TextArea } = Input;
const { Option } = Select;

const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};

const AskForJustificationModal = (props) => {
  const { caseDetail, selectedApplicant } = props;
  const [justifications, setJustifications] = useState([]);
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewPdf, setPreviewPdf] = useState(false);
  const [attachement, setAttachment] = useState(false);
  const [value, setValue] = useState("");

  const [applicant, setApplicant] = useState(false);
  const [coapplicant, setCoapplicantt] = useState(false);
  const [guarantor, setGuarantors] = useState(false);

  const [form] = Form.useForm();

  const beforeUpload = (file, list) => {
    if (checkFileTypeImageAndPdf(file)) {
      if (checkFileSizeOneMB(file,10)) {
        setAttachment(list[0]);
        return false;
      }
    }
    return Upload.LIST_IGNORE;
  };
  const handleAddCommentForCustomer = (values) => {
    const formData = new FormData();
    if (attachement) {
      formData.append("attachment", attachement);
    }
    formData.append("applicant_type", selectedApplicant.applicant_type);
    formData.append("fk_case_id", caseDetail.id);
    formData.append("justification_type", 1);
    formData.append("applicant_coapplicant_guarantor_id", selectedApplicant.id);
    formData.append("comment", values.comment);
    props.handleOk(formData);
  };
  const onChange = (e) => {
    setValue(e.target.value);
    props.form.setFieldsValue({
      applicant_coapplicant_guarantor_id: undefined,
    });
    if (e.target.value == "Guarantor") {
      setGuarantors(true);
      setApplicant(false);
      setCoapplicantt(false);
    } else if (e.target.value == "Co Applicant") {
      setGuarantors(false);
      setApplicant(false);
      setCoapplicantt(true);
    } else {
      setGuarantors(false);
      setApplicant(true);
      setCoapplicantt(false);
    }
  };
  const handlePreviewOcrDoc = (file) => {
    if (!file.url && !file.preview) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = function (e) {
        if (file.type === "application/pdf") {
          setPreviewPdf(reader.result);
        } else {
          setPreviewImage(reader.result);
        }
      };
    } else {
      if (file.fileType === "pdf") {
        setPreviewPdf(file.url);
      } else {
        setPreviewImage(file.url || file.preview);
      }
    }
    setPreviewVisible(true);
  };
  return (
    <>
      <Modal
        title="Justification Requirements"
        visible={props.isModalVisible}
        onOk={props.handleOk}
        onCancel={props.handleCancel}
        className="JustReqWrap"
        footer={[
          <Button key="back" onClick={props.handleCancel}>
            Cancel
          </Button>,
          <Button form="myForm" key="submit" htmlType="submit" type="primary">
            Add Comment
          </Button>,
        ]}
        style={{ top: 90 }}
        width={600}
        destroyOnClose={true}
      >
        <Form
          id="myForm"
          name="communication"
          onFinish={handleAddCommentForCustomer}
          form={props.form}
        >
            <h4>
              Enter a reason why justification is required{" "}
            </h4>

            <Form.Item
              name="comment"
              rules={[
                {
                  required: true,
                  message: REQUIRED_MESSAGES.PLEASE_ADD_COMMENT,
                },
                {
                  max: 250,
                  message: MESSAGES.COMMENT_MAX_LENGTH,
                },
                {
                  min: 20,
                  message: MESSAGES.COMMENT_MIN_LENGTH,
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Enter your comment here"
                size={250}
              />
            </Form.Item>

            <Form.Item label="Upload Relevant Document" name="attachment" >
            <Upload
                    accept=".png,.jpeg,.jpg,.doc,.docx,.pdf"
                    maxCount={1}
                    beforeUpload={(file, fileList, e) => {
                  
                      const result = beforeUpload(file, fileList);
                      if (result) {
                        return Upload.LIST_IGNORE;
                      }
                      return false;
                    }}
                    onPreview={handlePreviewOcrDoc}
                  >
                <Button>
                  {" "}
                  Upload <img src={uplaodIcon} alt="" />
                </Button>
              </Upload>
            </Form.Item>
            {/* <div className="ButtonAddon">
                      <Button htmlType="submit">Add Comment</Button>
                    </div> */}
        </Form>
      </Modal>
    </>
  );
};

export default AskForJustificationModal;
