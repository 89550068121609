import {
    GET_REQUEST_COMMENTS_FAILED,
    GET_REQUEST_COMMENTS_SUCCESS,
    GET_JUSTIFICATION_COMMENTS_SUCCESS,
    GET_JUSTIFICATION_COMMENTS_FAILED,
    GET_USER_OCR_RESULT_SUCCESS,
    GET_USER_OCR_RESULT_FAILED
} from "../constants/ActionTypes";

const initState = {
    caseComments: [],
    justificationComments:[],
    ocrresultData:[]
}
export default function course(state = initState, action) {
    switch (action.type) {
        case GET_REQUEST_COMMENTS_SUCCESS:
            return {...state, caseComments: action.pendingCases};
        case GET_REQUEST_COMMENTS_FAILED:
            return {...state, caseComments: []};
        case GET_JUSTIFICATION_COMMENTS_SUCCESS:
            return {...state, justificationComments: action.pendingCases};
        case GET_JUSTIFICATION_COMMENTS_FAILED:
            return {...state, justificationComments: []};
        case GET_USER_OCR_RESULT_SUCCESS:
            return {...state, ocrresultData: action.userOcrResult};
        case GET_USER_OCR_RESULT_FAILED:
            return {...state, ocrresultData: []};
        
        default:
            return state
    }
}