import DollarIcon from "../assets/images/dollaricon.png";
import KycIcon from "../assets/images/kyc.png";
import CutomerIcon from "../assets/images/customer.png";
import NotesIcon from "../assets/images/sticky-notes.png";
import PaymentIcon from "../assets/images/payment.png";
import HoursIcon from "../assets/images/24-hours.png";
import ThumsIcon from "../assets/images/thums.png";
const initMasterData = {
  caseMasters: {
    business_areas: [],
    categories: [],
    product_models: [],
    products: [],
    subcategories: [],
  },
};
export const RELATIONSHIPS = [
  "Father",
  "Mother",
  "Spouse",
  "Brother",
  "Sister",
  "Other",
];
export const VIEW_DATA_AS_ON = [
  "Custom Date Range",
  "YTD",
  "MTD",
  "Last Week",
  "Last Month",
];
export const DASHBOARD_ANALYTICS = [
  {
    icon: DollarIcon,
    title: "New cases allocated in last 24 hrs",
    value: "new_cases_allocated_24_hours",
  },
  {
    icon: PaymentIcon,
    title: "New cases allocated but no action taken in last 24 hrs",
    value: "case_logged_no_action_24_hours",
  },
  {
    icon: PaymentIcon,
    title: "Total Cases Logged in which no action taken",
    value: "case_logged_no_action",
  },
  {
    icon: NotesIcon,
    title: "Case logged but KYC Check Pending",
    value: "cases_logged_kyc_pending",
  },
  {
    icon: KycIcon,
    title: "Case where KYC done but bureau reports pending",
    value: "bureau_report_pending",
  },
  {
    icon: CutomerIcon,
    title: "Cases where justification from customer pending",
    value: "customer_justification_pending",
  },
  {
    icon: NotesIcon,
    title: "Cases Pending with Credit for review",
    value: "pending_credit_for_review",
  },
  {
    icon: ThumsIcon,
    title: "Cases completed but not posted to LOS",
    value: "cases_completed_but_not_posted_los",
  },
];
export const STAGES = {
  OCR_PENDING: "OCR Pending",
  KYC_PENDING: "KYC Pending",
  BUREAU_REPORT_PENDING: "Bureau Report Pending",
  CREDIT_REVIEW_PENDING: "Credit Review Pending",
};
export const HEADINGS = {
  OCR_DOCUMENT_HEADING: "OCR Compatible Document",
  NON_OCR_DOCUMENT_HEADING: "NON OCR Compatible Document",
  OCR_DOCUMENT_SUB_HEADING: "(OCR Documents must be maximum of 5MB size)",
  NON_OCR_DOCUMENT_SUB_HEADING:
    "(NON OCR Documents must be maximum of 10MB size)",
};
export const STATUS = {
  DROPPED: "Dropped",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  CREDIT_BUREAU_APPROVED: "Credit Bureau Approved",
  CREDIT_REVIEW_PENDING: "Credit Review Pending",
};
export const APPLICANT_TYPE = {
  MAIN_APPLICANT: "Main Applicant",
  CO_APPLICANT: "Co Applicant",
  GUARANTOR: "Guarantor",
};
export const CATEGORY = {
  INDIVIDUAL: "1",
  CORPORATE: "2",
  HUF: "3",
  PROPRIETOR: "4",
};
export const CATEGORY_NAME = {
  INDIVIDUAL: "Individual",
  CORPORATE: "Corporate",
  HUF: "HUF",
  PROPRIETOR: "Proprietor",
};
export const MEMBER_TYPE = {
  DIRECTOR: "Director",
  MEMBER: "Member",
  PROPRIETOR: "Proprietor",
};
export const USER_TYPE = {
  SALES: "Sales",
  CREDIT: "Credit",
};
export const GROUP_NAME = {
  VOLVO: "Volvo",
  EICHER: "Eicher",
};
export const FINANCING_TYPES = ["Loan", "Lease"];
export const OCR_DOCUMENT_LABEL = {
  PAN_CARD: "PAN Card",
  AADHAR_CARD: "Aadhar Card",
  // DRIVING_LICENSE:'Driving License',
  DRIVING_LICENSE: "Driving License",
  PASSPORT: "Passport",
  VOTER_ID: "Voter ID",
  GST_REGISTRATION: "GST Registration",
};
export const NON_OCR_DOCUMENT_LABEL = {
  GST_REGISTRATION: "GST Registration",
  CUSTOMER_CONSENT: "Customer Consent",
};
// export const INDIVIDUAL_OCR_DOCS = ['PAN Card','Aadhar Card','Voter ID',];
// export const INDIVIDUAL_NON_OCR_DOCS = ['Customer Consent'];
// export const CORPORATE_OCR_DOCS = ['PAN Card','GST REGISTRATION']
// export const STAGES = ['KYC Pending','OCR Pending','Bureau Report Pendin']
export const INITIAL_STATE = {
  userData: {},
  masterData: initMasterData,
  planData: [],
  stateData: [],
  userProfile: {},
  isLoggedIn: false,
  userCases: [],
  role: {},
  userPermissions:{},
  currentTab: "1",
  newlyCreatedCase: null,
  isNewlyCreatedCaseSaved: false,
  // mainApplicant:{
  //   ocrDocuments:{},
  //   nonOcrDocuments:{},
  // },
  coApplicant: {
    category: "",
    subCategory: "",
    relationshipWithApplicant: "",
    ocrDocuments: {},
    nonOcrDocuments: {},
    otherRelation: "",
  },
  gurantor: {
    category: "",
    subCategory: "",
    ocrDocuments: {},
    nonOcrDocuments: {},
  },
  firstTab: "1",
  secondTab: "1",
  caseDetail: {
    business_area: "",
    case_code: null,
    category_name: "",
    fk_business_area_id: "",
    fk_category_id: "",
    fk_dealer_id: null,
    fk_product_id: "",
    fk_product_model_id: "",
    fk_subcategory_id: "",
    id: "",
    model_no: "",
    product_name: "",
    status: "",
    subcategory_name: "",
  },
  mainApplicant: null,
  coApplicantList: [],
  guarantor: [],
  selectedUser: {},
  selectedMemberDirector: {},
  states: [],
  cities: [],
  logNewCaseActiveKey: "1",
  uploadDocumentActiveKey: "1",
  currentCase: null,
  dashboardData: {},
  toResetParticipant: false,
  resetIndividualModal: false,
  middleTab: "1",
  toResetNewCase: false,
  currentKycResult: [],
  currentOcrResult: [],
};
