import { INITIAL_STATE } from "../../constants/constants";
import {
    GET_VAAHAN_DETAILS,GET_VAHAN_REQUEST_DETAILS,STORE_VAAHAN_DETAILS,RUN_VAHAN_HYPOTHECATION,VAAHAN_DETAILS

} from "../constants/ActionTypes";

const DEFAULT_STATE = INITIAL_STATE;
export default function vaahan(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case GET_VAAHAN_DETAILS:
      return {
        ...state,
        vaahanData: action.vaahanData,
      };
      case STORE_VAAHAN_DETAILS:
      return {
        ...state,
        vaahanData: action.vaahanData,
      };
      case GET_VAHAN_REQUEST_DETAILS:
      return {
        ...state,
        vaahanData: action.vaahanData,
      };
      case RUN_VAHAN_HYPOTHECATION:
      return {
        ...state,
        vaahanData: action.vaahanData,
      };
      case VAAHAN_DETAILS:
      return {
        ...state,
        vaahanDataByID: action.vaahanDataByID,
      }
      
    default:
      return state;
  }
}
