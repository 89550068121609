import {
  LOG_NEW_CASE_ACTIVE_KEY,
  SET_FIRST_TAB,
  SET_SECOND_TAB,
  UPLOAD_DOCUMENT_ACTIVE_KEY,
  CHANGE_MIDDLE_TAB,
  CHANGE_FIRST_TAB_KEY,
  CHANGE_SECOND_TAB_KEY,
  CHANGE_THIRD_TAB_KEY,
} from "../constants/ActionTypes";

/**
 * @method onFirstTabChange
 * @description first tab change in case detail page
 * @param {*  }
 * @returns
 */
export const onFirstTabChange = (tab) => {
  return {
    type: SET_FIRST_TAB,
    tab,
  };
};
/**
 * @method onSecondTabChange
 * @description second tab change in case detail page
 * @param {*  }
 * @returns
 */
export const onSecondTabChange = (tab) => {
  return {
    type: SET_SECOND_TAB,
    tab,
  };
};
/**
 * @method onChangeLogNewCaseActiveParticipantTab
 * @description  tab change while adding participant from add new case
 * @param {*  }
 * @returns
 */
export const onChangeLogNewCaseActiveParticipantTab = (key) => ({
  type: LOG_NEW_CASE_ACTIVE_KEY,
  key,
});
/**
 * @method onChangeLogNewCaseActiveParticipantTab
 * @description  tab change while adding participant from upload document modal
 * @param {*  }
 * @returns
 */
export const onChangeUploadDocumentActiveParticipantTab = (key) => ({
  type: UPLOAD_DOCUMENT_ACTIVE_KEY,
  key,
});
export const onChangeMiddleTab = (tab)=>({
  type:CHANGE_MIDDLE_TAB,
  tab
})

export const onChangeFirstTabKey = (tab)=>({
  type:CHANGE_FIRST_TAB_KEY,
  tab,
})
export const onChangeSecondTabKey = (tab)=>({
  type:CHANGE_SECOND_TAB_KEY,
  tab,
})
export const onChangeThirdTabKey = (tab)=>({
  type:CHANGE_THIRD_TAB_KEY,
  tab,
})