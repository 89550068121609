
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory, Redirect } from "react-router-dom";
import { Col, Tabs, Row,  Form, Select, Table, Space, Input } from "antd";
import { onCheckLinkExpire } from '../../../../redux/actions/FieldInvestigation'
import siteLogo from "../..//..//..//assets/images/logo-new.png";
import ViewIcon from "../..//..//..//assets/images/view.png";
import { getFieldInvestigationAll } from '../../../../redux/actions/FieldInvestigation'
import moment from "moment";

export default function FieldInvestigationAllCasesView() {
  const dispatch = useDispatch();
  
  const [data, setData] = useState([]);
  const [searchedInput, setSearchedInput] = useState("");
  const [message, setMessage] = useState("");
  const [linkExpire, setLinkExpire] = useState(true);
  const [allCases, setAllCases] = useState([]);
  //const fiLinkExpire = useSelector((state) => state.fieldInvestigation.fiLinkExpire);

 
  const params = useParams();
  const filterCaseListing = (e) => {
    const searchedInput = e.target.value ? e.target.value.toLowerCase() : "";
    const filteredCases = data.filter((userCase) => {
      let casesById = userCase.case_id.toString().includes(searchedInput);
      let casesParticipantById = userCase.participant_id.toString().includes(searchedInput);
      let casesByCustomerName = userCase.username
        ? userCase.username.toLowerCase().includes(searchedInput)
        : "";
      let casesByParticipantType = userCase.participant_type
        ? userCase.participant_type.toLowerCase().includes(searchedInput)
        : "";
      return (
        casesById ||
        casesParticipantById ||
        casesByCustomerName||
        casesByParticipantType
      );
    });
    setAllCases(filteredCases);
  };
  const columns = [
    {
      title: "Case Id",
      dataIndex: "case_id",
      key: "case_id",
      sorter: (a, b) => a.case_id - b.case_id,
    },
    {
      title: "Name / Organization name",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "Participant Id",
      dataIndex: "participant_id",
      key: "participant_id",
    },
    {
      title: "Participant Type",
      dataIndex: "participant_type",
      key: "participant_type",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, row, record) => {
        return (
          <div>
            <Space size="middle">
              {row.status != 3 && <a href={row.fi_link} >
                <p
                 // title="Click to view case details"
                > Pending</p>
              </a>}
              {row.status === 3 && <p to={{
                // pathname: `/field-investigation-view/${params.agencyId}/${row.case_id}
                // /${row.participant_id}/${row.fi_attempt_id}/${row.fi_id}/${params.linkId}`,
              }}>
                <p
                  src={ViewIcon}
                  alt={row.caseId}
                 // title="Click to view case details"
                > Completed</p>
              </p>}
            </Space>
          </div>
        );
      },
    },
    {
      title: "Mobile No",
      dataIndex: "mobile_no",
      key: "agent",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, row, record) => {
        return <div>{moment(row.created_at).format("ll")}</div>;
      },
      //sorter: (a, b) => a.UpdatedDate - b.UpdatedDate,
    },
    // {
    //   title: "Actions",
    //   dataIndex: "action",
    //   render: (text, row, record) => {
    //     return (
    //       <div>
    //         <Space size="middle">
    //           <Link to={`/field-investigation-view/${row.agency_id}/${row.caseid}/${row.participantid}/${row.fi_attempt_id}`}>
    //             <img
    //               src={ViewIcon}
    //               alt={row.caseId}
    //               title="Click to view case details"

    //             />
    //           </Link>

    //         </Space>
    //       </div>
    //     );
    //   },
    // },
  ];

  const checkLink = (linkId) => {
    dispatch(onCheckLinkExpire(linkId, onLinkSuccess));
  };
  const onLinkSuccess = (responseData) => {
    setMessage(responseData.data.data.status);
    setLinkExpire(responseData.data.data.status);
  };
  const getAllCases = (agency_id) => {
    dispatch(getFieldInvestigationAll(agency_id, onSuccess));
  };
  const onSuccess = (responseData) => {
    setData(responseData.data.data.data);
    setAllCases(responseData.data.data.data);
  }
  useEffect(() => {
    checkLink(params.linkId);
    getAllCases(params.agencyId)
  }, [dispatch]);
  return (
    <>
      <div className="topBarWrap">
        <Row>
          <Col span={12} sm={12} xs={24} className="logowrap">
            <div className="Branding">
              <div className="text">
                <div>
                  <img src={siteLogo} alt="logo" />
                </div>
                <div>
                  <h4>Volvo Financial Services</h4>
                  <p>INDIA</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {linkExpire ?
        <div className="CaseListData CaseListDataMobile">
          <Row>
            <Col span={12}>
              <Form>
                <Input.Group compact>
                  <Input.Search
                    allowClear
                    placeholder="Search On Case ID, Customer Name, Participant Type"
                    value={searchedInput}
                    onChange={(e) => {
                      setSearchedInput(e.target.value);
                      filterCaseListing(e);
                    }}
                  />
                </Input.Group>
              </Form>
            </Col>
          </Row>
          <Table
            columns={columns}
            dataSource={allCases}
            pagination={true}
          />
        </div>
        :
        <Redirect to={{
          pathname: `/link/expired`,
          state: { message: message },
        }}>
        </Redirect>
      }
    </>
  )
}
