import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MiddleTab from "../middletab";
import SecondTab from "../secondtab/secondtab";
import MainApplicantSummary from "./MainApplicantSummary";
const Mainapplicantform = () => {
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  const [memberOrDirectors,setMemberOrDirectors] = useState([]);
  const [isMemberOrDirector,setIsMemberOrDirector] = useState(false);
  useEffect(()=>{
    const checkApplicantType = ()=>{
      if(caseDetail &&
        ((caseDetail.main_applicant &&
          caseDetail.main_applicant.fk_category_id.toString() === "2") ||
          caseDetail.main_applicant.fk_category_id.toString() === "3")){
            setIsMemberOrDirector(true)
            setMemberOrDirectors(caseDetail.main_applicant.member_director)
      }
    }
    checkApplicantType()
  },[caseDetail])
  
  return (
    <>
    {isMemberOrDirector ? <MiddleTab 
      memberOrDirectors={memberOrDirectors}
      applicant_id={caseDetail.main_applicant.id}
      />:<SecondTab />}
    </>
  );
};
export default Mainapplicantform;
