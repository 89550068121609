import React, { Component } from "react";
import { Col, Tabs, Row, Card } from "antd";

import Mainapplicant from "./generaldetail/mainapplicant";
import KycReport from "./kycreport";
import BureauReport from "./bureaureport";
import ComminucationForm from "./communication"
const { TabPane } = Tabs;
class CasesTabs extends Component {
  render() {
    return (
      <>
        <div className="GeneralTabsWrap">
          <div className='popbox'>
              {/* <Card className='popcard'>
                <h3>System Recommendation</h3>
                <h4>Basis The Co -Applicant2 Details The Loan Can Be Sanctioned To The User. The Case Looks Like Doable At This Stage.  </h4>
              </Card> */}
          </div>
          <Tabs defaultActiveKey="1">
            <TabPane tab="General Detail" key="1">
              <Mainapplicant />
            </TabPane>
            <TabPane tab="KYC Report" key="2">
              <KycReport />
            </TabPane>
            <TabPane tab="Bureau Reports" key="3">
              <BureauReport />
            </TabPane>
            <TabPane tab="Communication" key="4">
              <ComminucationForm/>
            </TabPane>
          </Tabs>
        </div>
      </>
    );
  }
}
export default CasesTabs;
