import { Button, Col, Form, Row } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import HunterRequestForm from "./HunterRequestForm";

import { Link, useParams, useHistory } from "react-router-dom"; 
import { onRunHunter } from "../../../redux/actions/HunterAction";
import { toast } from "react-toastify";

export const RunRequestBox = ({ deleteVisible, deleteCancel, closeModal,caseId}) => {
  const dispatch = useDispatch();
  const runHunter = (caseId) => {
    dispatch(onRunHunter(caseId, onSuccess));
  };

  const onSuccess = (responseData) => { 
    deleteCancel();
    toast.success(responseData.data.message); 
  }; 

  return (
    <Modal
      title= {`Case ID - ${caseId}`}
      centered
      visible={deleteVisible}
      onCancel={deleteCancel}
      className="ModalFormWrap AddCoapplicant"
      // onOk={deleteConfirm}
      destroyOnClose={true}
      width={1500}
      footer={[
        <Button key="Cancel" onClick={deleteCancel} style={{ marginLeft: 10 }}>
          Cancel
        </Button>,
        <Button
        
          type="primary"
          htmlType="submit"
          style={{
            backgroundColor: "#202A44",
            color: "#fff",
          }}
          onClick={() => runHunter(caseId)}
        >
          Submit
        </Button>,
      ]}
    >
     <HunterRequestForm/>
    </Modal>
  );
};
