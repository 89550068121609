import React, { Component, useEffect, useState } from "react";
import { Row, Col, Card, Button, Table, Modal, Form, Input } from "antd";
import PlusIcon from "../../assets/images/plus.png";
import RemoveIcon from "../../assets/images/remove.png";
import { Link, useParams } from "react-router-dom";
import CreditReview from "../casedetail/modals/creditreview";
import AskJustification from "../casedetail/modals/askjustification";
import { useDispatch, useSelector } from "react-redux";
import { CustomerDetailHeader } from "./CustomerDetailHeader";
import { onParticipantByCaseId } from "../../redux/actions/ParticipantAction";
import { APPLICANT_TYPE } from "../../constants/constants";
import BureauReportListing from "./BureauReportListing";
import MainApplicantBureauReportListing from "./MainApplicantBureauReportList";
import { CoApplicantListing } from "./CoApplicantListing";
import { GuarantorListing } from "./GuarantorListing";
import {
  onCaseDetail,
  onCaseRejectApprove,
  onViewRecommendation,
} from "../../redux/actions/CaseAction";
import { MESSAGES } from "../../constants/messages";
import RunParametersModal from "../modals/runParametersModal";
import { onBureauReportParameters } from "../../redux/actions/BureauReportAction";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { Alert } from "antd";
import ConfirmationModal from "../modals/ConfirmationModal";

const { TextArea } = Input;

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {},
  getCheckboxProps: (record) => ({
    disabled: record.name === "Disabled User",
    // Column configuration not to be checked
    name: record.name,
  }),
};

const BureauGeneratedReport = (props) => {
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  const [mainApplicant, setMainApplicant] = useState(null);
  const [coapplicants, setCoapplicants] = useState({});
  const [guarantors, setGuarantors] = useState({});
  const [userParameters, setUserParameters] = useState([]);
  const [visible, setVisible] = useState(false);
  const params = useParams();
  const selectedApplicant = caseDetail && caseDetail.main_applicant;
  const [mainApplicantId, setMainApplicantId] = useState("");
  const [coapplicantId, setCoapplicantId] = useState([]);
  const [guarantorId, setGuarantorId] = useState([]);
  const [mainApplicantBRId, setMainApplicantBRId] = useState("");
  const [coapplicantBRId, setCoapplicantBRId] = useState(null);
  const [guarantorBRId, setGuarantorBRId] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showErrorG, setShowErrorG] = useState(false);
  const [showSystemError, setShowSystemError] = useState(false);
  const [systemMessage, setSystemMessage] = useState("");
  const [msgStatus, setMsgStatus] = useState(false);
  const [confirmationModalVisible, setConfirmationModal] = useState(false);

  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  //
  const CreditBureaucolumns = [
    {
      title: "Bureau Name",
      dataIndex: "bureau_name",
    },
    {
      title: "Score",
      dataIndex: "score",
    },
    {
      title: "Report Generated on ",
      dataIndex: "generated_on",
    },
    {
      title: "Run Parameters ",
      dataIndex: "parameter",
      render: (text, record) => (
        <a
          onClick={() => {
            openparamModal(record.id);
          }}
        >
          Click here
        </a>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <React.Fragment>
          <Link target="_blank" to={`/download-report/${record.id}`}>
            View Report
          </Link>
        </React.Fragment>
      ),
    },
  ];

  const openparamModal = (id) => {
    setUserParameters([]);
    dispatch(onBureauReportParameters(id, onSuccess));
    setVisible(true);
  };
  const onSuccess = (response) => {
    setUserParameters(response);
  };
  useEffect(() => {
    if (params.id) {
      const onSuccess = (caseDetail) => {
        if (caseDetail && caseDetail.main_applicant) {
          dispatch(
            onParticipantByCaseId(caseDetail.id, (participants) => {
              setMainApplicant(() => {
                return participants.main_applicant;
              });
              setMainApplicantId(caseDetail.main_applicant.id);
              setCoapplicants(participants.co_applicants);
              setGuarantors(participants.guarantors);
            })
          );
        }
      };
      const data = {
        caseId: params.id,
      };
      dispatch(onCaseDetail(data, onSuccess));
    }
  }, [params.id, dispatch]);

  const onSetApplicantBRId = (id) => {
    setMainApplicantBRId(id);
  };
  const onSetCoApplicantId = (id) => {
    setCoapplicantId(id);
  };
  const onSetCoApplicantBRId = (id) => {
    setCoapplicantBRId(id);
  };
  const onSetGuarantorId = (id) => {
    setGuarantorId(id);
  };
  const onSetGuarantorBRId = (id) => {
    setGuarantorBRId(id);
  };

  const handleSubmit = (values) => {
    setShowError(false);
    setShowErrorG(false);
    values.is_approved = 1;
    values.case_id = caseDetail.id;
    if (
      mainApplicantId &&
      mainApplicantBRId &&
      ((coapplicantBRId && coapplicantBRId.length>0) || (guarantorBRId && guarantorBRId.length>0 ))
    ) {
      values.main_applicant_id = mainApplicantId;
      values.main_applicant_bureau_report_id = mainApplicantBRId;
      values.co_applicant_data = coapplicantBRId;
      values.gurantor_data = guarantorBRId;
      console.log(values,'val')

      dispatch(onCaseRejectApprove(values, onSuccessApprove));
    } else {
      setShowError(true);
      setShowErrorG(true);
      toast.error("Please select Bureau Report for Main applicant and Co-Applicant or Guarantor");
    }
  };
  const checkValidations = () => {
    setShowSystemError(false);
    let co_ids=[];
    coapplicantBRId && Array.isArray(coapplicantBRId) && coapplicantBRId.map((id) => {
      console.log(id,'pp')
      co_ids.push(id.co_applicant_bureau_report_id)
      
    })
    guarantorBRId && Array.isArray(guarantorBRId) && guarantorBRId.map((id) => {
      console.log(id,'pp')
      co_ids.push(id.gurantor_bureau_report_id)
      
    })
    console.log(co_ids,'hhhgf',co_ids.toString())
    if (mainApplicantId &&
      mainApplicantBRId && co_ids!="") {
     
      let data = {
        case_id: caseDetail.id,
        bureau_report_ids: mainApplicantBRId + "," + co_ids.toString(),
      };

      dispatch(onViewRecommendation(data, onSuccessCheck));
    } else {
      setShowError(true);
      setShowErrorG(true);
      toast.error(
        "Please select Bureau Report for Main applicant and Co-Applicant or Guarantor"
      );
    }
  };

  const onSuccessCheck = (data) => {
    setSystemMessage(data.message);
    setMsgStatus(data.status);
    setShowSystemError(true);
  };
  const onSuccessApprove = () => {
    history.push("/closedcases");
  };

  return (
    <React.Fragment>
      <div className="GeneratedReportWrap">
        <Form
          id="myForm"
          name="communication"
          onFinish={handleSubmit}
          form={form}
        >
          <div className="BeureauHeader">
            <div className="InnerWrap">
              <Row>
                <Col span={4} xs={24} sm={24} md={12}>
                  <p className="CaseId">
                    Case ID - {caseDetail.id}{" "}
                    <span>
                      <span>{caseDetail.status}</span>
                    </span>
                  </p>
                </Col>
                <Col span={4} xs={24} sm={24} md={12}>
                  <div className="BtnWrap">
                    <Button>
                      <Link to={`/viewOnGoingcase/${caseDetail.id}`}>Back</Link>
                    </Button>
                    {/*<Button onClick={() => this.creditreview(true)}>Request Credit for Review</Button>
                
                  <Button onClick={() => this.askJustification(true)}>Ask for justification</Button>*/}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {/*<div className="popbox">
            <Card className="popcard">
              <h3>System Recommendation</h3>
              <h4>
                Basis The Co -Applicant2 Details The Loan Can Be Sanctioned To
                The User. The Case Looks Like Doable At This Stage.{" "}
              </h4>
            </Card>
                </div>*/}
          {mainApplicant && (
            <CustomerDetailHeader mainApplicant={mainApplicant} />
          )}
          <div className="TableWrap">
            <h4>
              Select bureau reports for participants to approve case
            </h4>
            <h4>Main Applicant</h4>
            {caseDetail && caseDetail.main_applicant && (
              <MainApplicantBureauReportListing
                caseDetail={caseDetail}
                creditBureauColumns={CreditBureaucolumns}
                onSetApplicantBRId={onSetApplicantBRId}
              />
            )}
            {mainApplicantBRId ? (
              ""
            ) : showError ? (
              <p className="error">
                Please select Bureau Report for Main Applicant
              </p>
            ) : (
              ""
            )}
            <h4>Co Applicant Details</h4>
            {coapplicants && (
              <CoApplicantListing
                co_applicants={coapplicants}
                onSetCoApplicantId={onSetCoApplicantId}
                onSetCoApplicantBRId={onSetCoApplicantBRId}
              />
            )}
            {/* {coapplicantId && coapplicantBRId ? (
              ""
            ) : showError ? (
              <p className="error">
                Please select Co Applicant and Bureau Report!
              </p>
            ) : (
              ""
            )} */}

            <h4>Guarantor Details</h4>
            {guarantors && (
              <GuarantorListing
                setGuarantorId={onSetGuarantorId}
                onSetGuarantorBRId={onSetGuarantorBRId}
                guarantors={guarantors}
              />
            )}

            {(coapplicantId && coapplicantBRId) ||
            (guarantorId && guarantorBRId) ? (
              ""
            ) : showErrorG ? (
              <p className="error">
                Please select Co Applicant/Guarantor and respective Bureau
                Report
              </p>
            ) : (
              ""
            )}
            <br />
            <div className="mb-1">
              <Button
                className="filled btn-dark"
                type="primary"
                onClick={checkValidations}
              >
                View System Recommendation
              </Button>
            </div>

            {showSystemError ? (
              <Alert
                message={msgStatus ? "Success" : "Warning"}
                description={systemMessage}
                type={msgStatus ? "success" : "warning"}
                showIcon
                closable
              />
            ) : (
              ""
            )}
            <br />
            <div className="mb-1">
              <label>
                Enter Explanation For Credit Bureau Approval
                <span className="errorastrick">*</span>
              </label>
            </div>

            <Form.Item
              // className="fontStyle"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Please specify the reason",
                },
                {
                  max: 250,
                  message: MESSAGES.COMMENT_MAX_LENGTH,
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder="Enter your reason here"
                size={250}
              />
            </Form.Item>
          </div>
          <div className="FootWrap">
            <Button
              onClick={() => {
                setConfirmationModal(true);
              }}
              className="btn-light-with-lable-dark"
            >
              {/*<Link to={`/viewOnGoingcase/${caseDetail.id}`}>Cancel</Link>*/}
              Cancel
            </Button>
            <Button className="filled" type="primary" htmlType="submit">
              Approve
            </Button>
          </div>
        </Form>
        {/** Show parameters modal */}
        <RunParametersModal
          visible={visible}
          userParameters={userParameters}
          selectedApplicant={selectedApplicant}
          onCancel={() => setVisible(false)}
        />
        <ConfirmationModal
          isModalVisible={confirmationModalVisible}
          handleCancel={() => {
            setConfirmationModal(false);
          }}
          handleOk={() => {
            history.push(`/viewOnGoingcase/${caseDetail.id}`);
          }}
          title={"Are you sure you want to leave the page?"}
        />
        {/** Show parameters modal */}
      </div>
    </React.Fragment>
  );
};

export default BureauGeneratedReport;
