import { CREDIT_USER } from "../constants/ActionTypes";
// alert('0')
/**
 * @method onFetchCreditUserList
 * @description get the listing of credit users
 * @param {* } onSuccess
 * @returns
 */
export const onFetchCreditUserList = (data,onSuccess)=>({
    type: CREDIT_USER,
    data,
    onSuccess,
  })