import Modal from "antd/lib/modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { closePreviewModal } from "../helpers/handlePreview";
const PreviewImageOrPDF = ({ onCancel }) => {
  const dispatch = useDispatch();
  const previewVisible = useSelector((state) => {
    return state.modalReducer.previewVisible;
  });
  const previewImage = useSelector((state) => {
    return state.modalReducer.previewImage;
  });
  const previewLoader = useSelector((state) => {
    return state.modalReducer.previewLoader;
  });
  const previewPdf = useSelector((state) => {
    return state.modalReducer.previewPdf;
  });
  return (
    <Modal
      visible={previewVisible}
      footer={null}
      className="KycCheck"
      onCancel={() => {
        closePreviewModal(dispatch);
      }}
      style={{ top: 80, }}
      
    >
    {previewLoader ? 'Loading':previewImage ? (
      <img
        alt="example"
        style={{ width: "100%" }}
        src={previewImage}
        className="mt-2"
      />
    ):   <iframe
    alt=""
    title="a"
    src={previewPdf}
    style={{ width: 480, height: 480 }}
    frameborder="0"
    className="mt-2"
    
  />}
      {/*{previewImage && (
        <img
          alt="example"
          style={{ width: "100%" }}
          src={previewImage}
          className="mt-2"
        />
      )}
      {previewPdf && (
        <iframe
          alt=""
          title="a"
          src={previewPdf}
          style={{ width: 480, height: 480 }}
          frameborder="0"
          className="mt-2"
          
        />
      )}*/}
    </Modal>
  );
};
export default PreviewImageOrPDF;
