import React, { useEffect, useState } from "react";
import { Col, Row, Card, Input, Form, Button } from "antd";
import {
  checkFileSizeOneMB,
  checkFileTypeImageAndPdf,
  fileTypeFromName,
  isCaseNotClosed,
} from "../../../helpers/utils";
import { MESSAGES, REQUIRED_MESSAGES } from "../../../constants/messages";
import moment from "moment";

const { TextArea } = Input;
const layout = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
function getDate(date) {
  const getdate = moment(date);
  return getdate.format("MMM-DD-YYYY");
}
const SuggestionReviewForm = (props) => {
  const { caseDetail } = props;

  const handleSubmit = (values) => {
    // alert('9')
    const formData = new FormData();
    formData.append("fk_case_id", caseDetail.id);
    formData.append("comment", values.comment);
    props.saveSuggestion(formData);
  };
  return (
    <Col span={12} md={12} sm={24} xs={24}>
      <Row gutter={0}>
        <Col span={24} md={24} sm={24} xs={24}>
          <h4>Suggestion/Review Comments/Recommendations from Credit </h4>
          <Card>
            {props.userType == "Credit" &&
            isCaseNotClosed(caseDetail.status) ? (
              <Form
                form={props.form}
                name="communication"
                onFinish={handleSubmit}
              >
                <Form.Item
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Please add comment.",
                    },
                    {
                      max: 250,
                      message: MESSAGES.COMMENT_MAX_LENGTH,
                    },
                  ]}
                >
                  <TextArea
                    rows={4}
                    placeholder="Enter your comment here"
                    size={250}
                  />
                </Form.Item>
                <div className="ButtonAddon">
                  <Button htmlType="submit"> Add Comment</Button>
                </div>
              </Form>
            ) : (
              ""
            )}
            <div
              className={`CommentCard ${
                props.requestComments &&
                props.requestComments.length &&
                props.requestComments.length > 4 &&
                "CommentCardScroll"
              }`}
            >
              {props.requestComments && props.requestComments.length > 0 ? (
                props.requestComments.map((comment) => (
                  <>
                    <p
                      className="commentlist"
                      style={{ wordWrap: "break-word" }}
                    >
                      {comment.comment}{" "}
                      <p className="postedBlock">
                        <i>
                          {" "}
                          Posted on{" "}
                          {moment(comment.created_at).format(
                            "ddd MMM D YYYY, h:mm A"
                          )}{" "}
                          by {comment.created_by_name}{" "}
                        </i>
                        <br />
                        <b>
                          {" "}
                          {comment.user_type === "Sales"
                            ? "From Sales"
                            : "From Credit"}{" "}
                        </b>
                      </p>
                    </p>
                    <hr />
                  </>
                ))
              ) : (
                <p style={{ "text-align": "center" }} className="mb-0">
                  <b>{MESSAGES.NO_COMMUNICATION_YET}</b>
                </p>
              )}
            </div>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default SuggestionReviewForm;
