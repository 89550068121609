import React, { useEffect } from "react";
import { Col, Row, Button, Table } from "antd";
import { useParams } from "react-router-dom";
import { onHunterDetilsByCase } from "../../redux/actions/HunterAction";
import { useDispatch } from "react-redux";
import { useState } from "react";

export default function HunterViewByCase() {
  const [caseData, setCaseData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [tableData, setTableData] = useState([]);
  const dispatch = useDispatch();
  const parms = useParams();
  const id = parms.id;

  // console.log(params);

  //Matched response table columns
  const rulesResponseColumns = [
    {
      title: "Rule Id",
      dataIndex: "rule_id",
      key:"rule_id"
    },
    {
      title: "Rule Description",
      dataIndex: "rule_description",
      key:"rule_id"
    },
  ];

  //No match Response table columns

  //No match error Response table columns
  const errorsResponseColumns = [
    {
      title: "Error Type",
      dataIndex: "error_type",
      key:"error_code"
    },
    {
      title: "Error Code",
      dataIndex: "error_code",
      key:"error_code"
      
    },
    {
      title: "Error Message",
      dataIndex: "error_message",
      key:"error_code"

    },
  ];
  const onloadScreen = (response) => {
    const resData = response?.data?.data;
    setCaseData(resData);
    if (resData?.errors?.length > 0) {
      setTableColumn(errorsResponseColumns);
      setTableData(resData?.errors);
    } else if (resData?.rules?.length > 0) {
      setTableColumn(rulesResponseColumns);
      setTableData(resData?.rules);
    }
  };
  const loadCaseDetails = () => {
    dispatch(onHunterDetilsByCase(id, onloadScreen));
  };
  useEffect(() => {
    loadCaseDetails();
  }, []);

  return (
    <>
      <div className="GeneralTabsWrap hunterTabs">
        <div className="CaseDetailHeader">
          <div className="InnerWrap">
            <Row>
              <Col span={4} xs={24} sm={24} md={16}>
                <p className="CaseId mx-4">
                  <span>
                    <span>Case ID -{caseData?.case_id}</span>
                    <span>Run By -{caseData?.created_by}</span>
                    <span
                      style={{
                        color: `${
                          caseData?.hunter_status === "No Match"
                            ? "#34a853"
                            : "#ea4335"
                        }`,
                      }}
                    >
                      {caseData?.hunter_status}
                    </span>
                  </span>
                </p>
              </Col>
              {/* <Col span={4} xs={24} sm={24} md={24}>
                <span>
                  <Button>Download</Button>
                </span>
              </Col> */}
            </Row>
          </div>
        </div>
      </div>
      {(caseData?.errors?.length > 0 || caseData?.rules?.length > 0) && (
        <div className="CaseListData" style={{ fontSize: "50px" }}>
          <Table
            className="HunterTableWrap"
            columns={tableColumn}
            dataSource={tableData}
            pagination={true}
          />
        </div>
      )}
    </>
  );
}
