import { Button, Col, Form, Input, Modal, Row, Select, Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import uplaodIcon from "../../../assets/images/uploadfile.png";
import {
  APPLICANT_TYPE,
  CATEGORY,
  HEADINGS,
  MEMBER_TYPE,
  NON_OCR_DOCUMENT_LABEL,
  OCR_DOCUMENT_LABEL,
} from "../../../constants/constants";
import {
  CONSTANTS,
  MESSAGES,
  REQUIRED_MESSAGES,
} from "../../../constants/messages";
import { REGEX } from "../../../constants/regex";
import {
  firstCharacterOfEachWordUpperCase,
  isOcrDone,
} from "../../../helpers/utils";
import { onParticipantDetail } from "../../../redux/actions/ParticipantAction";
import {
  beforeUploadNonRequiredOcrDocument,
  beforeUploadOcrAndRequiredNonOcrDocument,
  beforeUploadRequiredNonOcrDocument,
} from "../helpers/beforeUpload";
import {
  closePreviewModal,
  handlePreviewNonOcrDoc,
  handlePreviewOcrDoc,
} from "../helpers/handlePreview";
import { saveApplicant } from "../helpers/SaveApplicant";
import { updatedApplicantDocuments } from "../helpers/UpdateDefaultDocuments";
import {
  addNonRequiredNonOcrDoc,
  checkAtleastOnePOADocumentUploaded,
  checkValidation,
  deleteMemberDirectorNonOcrDocument,
  getNonOcrFields,
  getOcrFields,
} from "../helpers/utils";
import AddIndividualForm from "../individual/AddIndividualForm";
import MemberDirectorListing from "../member-directors/MemberDirectorListing";
import CancelApplicantPopup from "../modals/CancelApplicantPopup";
import PreviewImageOrPDF from "../modals/PreviewImageOrPDF";

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;
const AddCoApplicant = (props) => {
  const {
    editCoApplicant,
    makeEditable,
    add_new_coapplicant_inside_modal,
    handleCancel,
  } = props;

  const newlyCreatedCase = useSelector((state) =>{
    if(state && state.auth &&  state.auth.newlyCreatedCase){
      return state.auth.newlyCreatedCase
    }else if(state && state.auth && state.auth.caseDetail){
      return state.auth.caseDetail
    }
    return {};
  });
  const [relationship, setRelationship] = useState("");
  const [form] = Form.useForm();
  const [subCategories, setSubCategories] = useState([]);
  const [ocrDocuments, setOcrDocuments] = useState([]);
  const [requiredNonOcrDocuments, setRequiredNonOcrDocuments] = useState([]);
  const [nonRequiredNonOcrDocuments, setNonRequiredNonOcrDocuments] = useState(
    []
  );
  const [directorMemberError, setDirectorMemberError] = useState(false);
  const [applicantCancelPopup, setCancelPopup] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
  const [isMemberOrDirectorModalOpen, setMemberOrDirectorModalOpen] = useState(
    false
  );
  const [runOcr, setRunOcr] = useState(false);
  const [memberDirectorList, setMemberDirectorList] = useState([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [memberOrDirector, setMemberOrDirector] = useState(null);
  const [isProprietorModalOpen, setProprietorModalOpen] = useState(false);
  const [proprietor, setProprietor] = useState(null);
  const [poaDocumentError, setPOADocumentError] = useState(false);
  const [panCardError, setPanCardError] = useState(false);

  useEffect(() => {
    closePreviewModal(dispatch);
  }, [dispatch]);
  useEffect(() => {
    form.setFields([{ name: "pan_card", errors: [panCardError] }]);
  }, [form, panCardError]);
  const updateMemberDirectorList = (co_applicant) => {
    if (
      co_applicant.member_director &&
      Array.isArray(co_applicant.member_director)
    ) {
      setMemberDirectorList(co_applicant.member_director);
    }
  };
  const onAddEditProprietor = (proprietor) => {
    const onSuccess = (participantDetail) => {
      updateMemberDirectorList(participantDetail);
      setProprietor(proprietor);
      if (!props.add_new_coapplicant_inside_modal) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      } else {
        makeEditable();
      }
    };
    let data = {
      applicant_type: APPLICANT_TYPE.CO_APPLICANT,
      applicant_coapplicant_guarantor_id: editCoApplicant.id,
    };

    dispatch(onParticipantDetail(data, onSuccess));
  };
  const getUpdatedApplicantDocuments = useCallback((applicant) => {
    const {
      uploadedOcrDocuments,
      uploadedRequiredNonOcrDocuments,
      uploadedNonOcrDocuments,
    } = updatedApplicantDocuments(applicant);
    setOcrDocuments((ocrDocuments) => {
      return { ...ocrDocuments, ...uploadedOcrDocuments };
    });
    setRequiredNonOcrDocuments((requiredDocs) => {
      return { ...requiredDocs, ...uploadedRequiredNonOcrDocuments };
    });
    setNonRequiredNonOcrDocuments(uploadedNonOcrDocuments);
  }, []);
  useEffect(() => {
    setDirectorMemberError("");
    // if (newlyCreatedCase && editCoApplicant && newlyCreatedCase.co_applicants) {
    //   getUpdatedApplicantDocuments(editCoApplicant);
    // }
    if (newlyCreatedCase && editCoApplicant && newlyCreatedCase.co_applicants) {
      let data = {
        applicant_type: "Co Applicant",
        applicant_coapplicant_guarantor_id: editCoApplicant.id,
      };
      dispatch(
        onParticipantDetail(data, (updated_co_applicant) => {
          updateMemberDirectorList(updated_co_applicant);
          getUpdatedApplicantDocuments(updated_co_applicant);
          form.setFieldsValue({
            gst_registration_no: updated_co_applicant.gst_registration_no,
            aadhaar_no: updated_co_applicant.aadhaar_no,
            
          });
        })
      );
    }
    if (!editCoApplicant) {
      setMemberDirectorList([]);
    }
  }, [
    newlyCreatedCase,
    editCoApplicant,
    getUpdatedApplicantDocuments,
    dispatch,
    form,
  ]);
  const caseMasters = useSelector((state) => {
    if (
      state &&
      state.auth &&
      state.auth.masterData &&
      state.auth.masterData.caseMasters
    ) {
      return state.auth.masterData.caseMasters;
    }
    return {};
  });
  const changeSubCategories = useCallback(
    (applicationCategoryId) => {
      setPOADocumentError(false);
      setSelectedCategory(applicationCategoryId.toString());
      setSelectedSubCategory(undefined);
      form.setFieldsValue({
        application_sub_category: undefined,
      });
      if (
        caseMasters.subcategories &&
        Array.isArray(caseMasters.subcategories) &&
        caseMasters.subcategories.length > 0
      ) {
        const filteredSubCategories = caseMasters.subcategories.filter(
          (subCategory) => {
            return (
              subCategory.fk_category_id.toString() === applicationCategoryId
            );
          }
        );
        setSubCategories(filteredSubCategories);
      }
    },
    [caseMasters, form]
  );

  const updateEditCoApplicantDetails = useCallback(() => {
    const applicantCategory = editCoApplicant.fk_category_id.toString();
    changeSubCategories(applicantCategory);
    const applicantSubCategory =
      editCoApplicant.fk_subcategory_id &&
      editCoApplicant.fk_subcategory_id !== "undefined"
        ? editCoApplicant.fk_subcategory_id.toString()
        : undefined;

    setSelectedCategory(applicantCategory);
    setSelectedSubCategory(applicantSubCategory);
    setRelationship(editCoApplicant.coapplicant_applicant_relation);
    form.setFieldsValue({
      applicant_category: applicantCategory,
      application_sub_category: applicantSubCategory
        ? applicantSubCategory.toString()
        : applicantSubCategory,
      relationship: editCoApplicant.coapplicant_applicant_relation,
      other_relation: editCoApplicant.other_relation,
    });
    // setOcrDocuments([]);
    // let uploadedOcrDocuments = updateDefaultOcrDocs(
    //   editCoApplicant.ocr_documents
    // );
    // setOcrDocuments((ocrDocuments) => {
    //   return { ...ocrDocuments, ...uploadedOcrDocuments };
    // });
    // let uploadedNonOcrDocuments = updatedDefaultNonOcrDocs(
    //   editCoApplicant.non_ocr_documents
    // );
    // setNonRequiredNonOcrDocuments(uploadedNonOcrDocuments);
    // let uploadedRequiredNonOcrDocuments = updateDefaultRequiredNonOcrDocs(
    //   editCoApplicant.non_ocr_required_documents
    // );
    // setRequiredNonOcrDocuments((requiredDocs) => {
    //   return { ...requiredDocs, ...uploadedRequiredNonOcrDocuments };
    // });
  }, [editCoApplicant, changeSubCategories, form]);
  useEffect(() => {
    if (editCoApplicant) {
      updateEditCoApplicantDetails();
    }
  }, [editCoApplicant, updateEditCoApplicantDetails]);
  const onRemoveOcrDoc = (fileName) => {
    setOcrDocuments({
      ...ocrDocuments,
      [fileName]: [],
    });
  };
  const onRemoveNonOcrDoc = (fileName) => {
    setRequiredNonOcrDocuments({
      ...requiredNonOcrDocuments,
      [fileName]: [],
    });
  };
  const GetOcrDocumentField = ({ label, name, message }) => {
    if (label === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
      return (
        <Col
          span={12}
          md={12}
          xs={24}
          sm={24}
          className="edit-participant-details"
        >
          <Row gutter={0}>
            <Col span={12} md={8} xs={8} sm={8}>
              <label class="custom-label" title="Document Name / Upload">
                {/*GST Number / Upload <span className="requiredAsterik"></span>*/}
                Aadhar Card Number / Upload <span></span>
              </label>
            </Col>
            <Col span={12} md={16} xs={16} sm={16}>
            <div className="applicant-upload-block">
                <Row gutter={2}>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      name={"aadhaar_no"}
                      label=""
                      rules={[
                        {
                          len:12,
                          message:MESSAGES.AADHAR_NUMBER_INVALID,
                          validateTrigger: true,

                        },
                        {
                          pattern:REGEX.NUMBER_ONLY_REGEX,
                          message:'Only numbers are allowed'
                        }
                      ]}
                      // rules={[
                      //   // {
                      //   //   required: true,
                      //   //   message: REQUIRED_MESSAGES.GST_NUMBER_REQUIRED,
                      //   // },
                      //   // {
                      //   //   len: 12,
                      //   //   // pattern:REGEX.AADHAR_CARD_NUMBER_REGEX,
                      //   //   message: MESSAGES.AADHAR_NUMBER_INVALID,
                      //   //   validateTrigger: true,
                      //   // },
                        
                      // ]}
                    >
                      <Input placeholder={"Aadhar Card Number"} maxLength={12} onChange={(e) => {
                        form.setFields([
                          {
                            name: "aadhaar_no",
                            errors: [],
                          },
                        ]);
                        if(!e.target.value){
                          form.setFields([
                            {
                              name: "aadhar_card",
                              errors: [],
                            },
                          ]);
                        }
                      }}/>
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={12} sm={12}>
                  <Form.Item
                  // label={label}
                  name={name}
                  required={message ? true : false}
                  extra={
                    label === OCR_DOCUMENT_LABEL.AADHAR_CARD
                      ? MESSAGES.AADHARD_CARD_NOTE
                      : label === OCR_DOCUMENT_LABEL.VOTER_ID
                      ? MESSAGES.VOTER_ID_NOTE
                      : ""
                  }
                  // rules={[
                  //   {
                  //     required: message ? true : false,
                  //     message: message,
                  //   },
                  // ]}
                >
                  <Upload
                    maxCount={1}
                    beforeUpload={(file, fileList, e) => {
                      if (selectedCategory === CATEGORY.INDIVIDUAL) {
                        if (name !== "pan_card") {
                          setPOADocumentError(false);
                        }
                      }
                      const res = beforeUploadOcrAndRequiredNonOcrDocument(
                        file,
                        fileList,
                        name,
                        ocrDocuments
                      );
                      if (res) {
                        setOcrDocuments(res);
                        return false;
                      } else {
                        return Upload.LIST_IGNORE;
                      }
                    }}
                    fileList={ocrDocuments[name]}
                    onPreview={(file, fileList) => {
                      handlePreviewOcrDoc(file, dispatch);
                    }}
                    onRemove={() => {
                      if (ocrDocuments[name][0] instanceof File) {
                        onRemoveOcrDoc(name);
                      }
                    }}
                    showUploadList={{
                      removeIcon:
                        ocrDocuments[name] &&
                        ocrDocuments[name][0] &&
                        !(ocrDocuments[name][0] instanceof File),
                    }}
                  >
                    <Button>
                      Upload {label} <img src={uplaodIcon} alt="uplaodIcon" />
                    </Button>
                  </Upload>
                </Form.Item>
                  </Col>
                </Row>
              </div>
              
            </Col>
          </Row>
        </Col>
      );
    }
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item
          label={label}
          name={name}
          // required={message ? true : false}
          required={message ? true : false}
          extra={
            label === OCR_DOCUMENT_LABEL.AADHAR_CARD
              ? MESSAGES.AADHARD_CARD_NOTE
              : label === OCR_DOCUMENT_LABEL.VOTER_ID
              ? MESSAGES.VOTER_ID_NOTE
              : ""
          }

          // rules={[
          //   {
          //     required: message ? true : false,
          //     message: message,
          //   },
          // ]}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file, fileList, e) => {
              if (selectedCategory === CATEGORY.INDIVIDUAL) {
                if (name !== "pan_card") {
                  setPOADocumentError(false);
                }
              }
              const res = beforeUploadOcrAndRequiredNonOcrDocument(
                file,
                fileList,
                name,
                ocrDocuments
              );
              if (res) {
                setOcrDocuments(res);
                return false;
              } else {
                return Upload.LIST_IGNORE;
              }
            }}
            fileList={ocrDocuments[name]}
            onPreview={(file, fileList) => {
              handlePreviewOcrDoc(file, dispatch);
            }}
            onRemove={() => {
              if (ocrDocuments[name][0] instanceof File) {
                onRemoveOcrDoc(name);
              }
            }}
            showUploadList={{
              removeIcon:
                ocrDocuments[name] &&
                ocrDocuments[name][0] &&
                !(ocrDocuments[name][0] instanceof File),
            }}
          >
            <Button className="btn-label-overclip">
              Upload {label} <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const GetNonOcrDocumentField = ({ label, name, message }) => {
    if (label === NON_OCR_DOCUMENT_LABEL.GST_REGISTRATION) {
      return (
        <Col
          span={12}
          md={12}
          xs={24}
          sm={24}
          className="edit-participant-details"
        >
          <Row gutter={0}>
            <Col span={12} md={8} xs={8} sm={8}>
              <label class="custom-label" title="Document Name / Upload">
                GST Number / Upload 
              </label>
            </Col>
            <Col span={12} md={16} xs={16} sm={16}>
              <div className="applicant-upload-block">
                <Row gutter={2}>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      name={"gst_registration_no"}
                      label=""
                      rules={[
                        // {
                        //   required: true,
                        //   message: REQUIRED_MESSAGES.GST_NUMBER_REQUIRED,
                        // },
                        {
                          pattern: REGEX.GST_REGISTRATION_NO,
                          message: MESSAGES.GST_NUMBER_INVALID,
                          validateTrigger: true,
                        },
                      ]}
                    >
                      <Input placeholder={"GST Registration Number"} />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      // label={label}
                      name={name}
                      required={
                        label === NON_OCR_DOCUMENT_LABEL.GST_REGISTRATION ||
                        label === NON_OCR_DOCUMENT_LABEL.CUSTOMER_CONSENT
                      }
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: message,
                      //   },
                      // ]}
                    >
                      <Upload
                        maxCount={1}
                        beforeUpload={(file, fileList) => {
                          const res = beforeUploadRequiredNonOcrDocument(
                            file,
                            fileList,
                            label,
                            requiredNonOcrDocuments
                          );
                          if (res) {
                            setRequiredNonOcrDocuments(res);
                            return false;
                          } else {
                            return Upload.LIST_IGNORE;
                          }
                        }}
                        fileList={requiredNonOcrDocuments[label]}
                        // onRemove={() => {
                        //
                        //   let filteredDocs = onRemoveOcrAndRequiredNonOcrDocument(
                        //     name,
                        //     requiredNonOcrDocuments
                        //   );
                        //   setRequiredNonOcrDocuments(filteredDocs);
                        // }}
                        onRemove={() => {
                          if (
                            requiredNonOcrDocuments[label][0] instanceof File
                          ) {
                            onRemoveNonOcrDoc(label);
                          }
                        }}
                        showUploadList={{
                          removeIcon:
                            requiredNonOcrDocuments[label] &&
                            requiredNonOcrDocuments[label][0] &&
                            !(
                              requiredNonOcrDocuments[label][0] instanceof File
                            ),
                        }}
                        onPreview={(file, fileList) => {
                          handlePreviewOcrDoc(file, dispatch);
                        }}
                      >
                        <Button className="btn-label-overclip">
                          Upload {label}{" "}
                          <img src={uplaodIcon} alt="uplaodIcon" />
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      );
    }
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item
          label={label}
          name={name}
          required={label === NON_OCR_DOCUMENT_LABEL.GST_REGISTRATION}
          // rules={[
          //   {
          //     required: true,
          //     message: message,
          //   },
          // ]}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file, fileList) => {
              const res = beforeUploadRequiredNonOcrDocument(
                file,
                fileList,
                label,
                requiredNonOcrDocuments
              );
              if (res) {
                setRequiredNonOcrDocuments(res);
                return false;
              } else {
                return Upload.LIST_IGNORE;
              }
            }}
            fileList={requiredNonOcrDocuments[label]}
            onRemove={() => {
              if (requiredNonOcrDocuments[label][0] instanceof File) {
                onRemoveNonOcrDoc(label);
              }
            }}
            showUploadList={{
              removeIcon:
                requiredNonOcrDocuments[label] &&
                requiredNonOcrDocuments[label][0] &&
                !(requiredNonOcrDocuments[label][0] instanceof File),
            }}
            // onRemove={() => {
            //   let filteredDocs = onRemoveOcrAndRequiredNonOcrDocument(
            //     name,
            //     requiredNonOcrDocuments
            //   );
            //   setRequiredNonOcrDocuments(filteredDocs);
            // }}
            onPreview={(file, fileList) => {
              handlePreviewOcrDoc(file, dispatch);
            }}
          >
            <Button>
              Upload {label} <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const onFinish = (values) => {
    let err = displayErrorMessage();
    if (err.length > 0 || poaDocumentError) {
      return;
    }
    let data = {
      fk_case_id: newlyCreatedCase.id,
      applicant_type: "Co Applicant",
      user_id: editCoApplicant ? editCoApplicant.id : "",
      fk_category_id: selectedCategory,
      fk_subcategory_id: selectedSubCategory ? selectedSubCategory : "",
      ocr_documents: ocrDocuments,
      non_ocr_documents: nonRequiredNonOcrDocuments,
      non_ocr_required_documents: requiredNonOcrDocuments,
      coapplicant_applicant_relation: values.relationship,
      other_relation: values.other_relation ? values.other_relation : "",
      gst_registration_no: values.gst_registration_no,
      // aadhaar_no:values.aadhaar_no,
      aadhaar_unmasked:values.aadhaar_no,

    };
    if(!(editCoApplicant && editCoApplicant.aadhaar_no && editCoApplicant.aadhaar_no && editCoApplicant.aadhaar_no === values.aadhaar_no) ){
      data.aadhaar_unmasked = values.aadhaar_no
    }
    saveApplicant(data, dispatch, makeEditable, runOcr, handleCancel);
  };
  const displayErrorMessage = () => {
    let aadhar_no = form.getFieldValue("aadhaar_no");
    let errors = checkValidation(
      selectedCategory,
      selectedSubCategory,
      ocrDocuments,
      requiredNonOcrDocuments,
      APPLICANT_TYPE.CO_APPLICANT,
      aadhar_no
    );
    errors.forEach((error) => {
      if (error.name === "pan_card") {
        setPanCardError(error.message);
      }
      form.setFields([{ name: error.name, errors: [error.message] }]);
    });

    if (selectedCategory === CATEGORY.INDIVIDUAL) {
      let isOnePOADocumentUploaded = checkAtleastOnePOADocumentUploaded(
        ocrDocuments
      );

      if (!isOnePOADocumentUploaded) {
        setPOADocumentError(
          REQUIRED_MESSAGES.PLEASE_UPLOAD_ATLEAST_ONE_POA_DOCUMENT
        );
      } else {
        setPOADocumentError(false);
      }
    }

    return errors;
  };
  const OcrDocument = () => {
    return (
      <React.Fragment>
        {getOcrFields(selectedCategory, selectedSubCategory).map(
          (ocr_field) => {
            return (
              <GetOcrDocumentField
                label={ocr_field.label}
                name={ocr_field.name}
                message={ocr_field.message}
              />
            );
          }
        )}
      </React.Fragment>
    );
  };
  const RequiredNonOcrDocument = () => {
    return (
      <React.Fragment>
        {getNonOcrFields(selectedCategory, selectedSubCategory).map(
          (non_ocr_field, index) => {
            return (
              <GetNonOcrDocumentField
                label={non_ocr_field.label}
                name={non_ocr_field.name}
                message={non_ocr_field.message}
                key={index.toString()}
              />
            );
          }
        )}
      </React.Fragment>
    );
  };
  const AddNonRequiredNonOcrDoc = () => {
    const handleDocumentName = (e) => {
      const input = e.target.value;
      if (!REGEX.NAME_VALIDATION.test(input)) {
        form.setFieldsValue({
          // 'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1))).replace( /[^a-zA-Z0-9 ]/gm, '')
          DocName: firstCharacterOfEachWordUpperCase(
            input.substring(0, input.length - 1)
          )
            .replace(/\s\s+/g, " ")
            .replace(/^\s+/g, ""),
        });
      } else {
        form.setFieldsValue({
          DocName: firstCharacterOfEachWordUpperCase(input)
            .replace(/\s\s+/g, " ")
            .replace(/^\s+/g, ""),
        });
      }
    };
    const setErrors = (name, errors) => {
      form.setFields([
        {
          name,
          errors,
        },
      ]);
    };
    const addButtonHandler = () => {
      setErrors("DocName", []);
      const documentName = form.getFieldValue("DocName");
      const uploadedDocument = form.getFieldValue("non_ocr_doc_pic");
      const result = addNonRequiredNonOcrDoc(
        documentName?firstCharacterOfEachWordUpperCase(documentName.trim()):documentName,
        uploadedDocument,
        nonRequiredNonOcrDocuments
      );
      if (result) {
        setErrors(result.name, result.errors);
        return;
      }
      setNonRequiredNonOcrDocuments(
        nonRequiredNonOcrDocuments.concat({
          name: documentName?firstCharacterOfEachWordUpperCase(documentName.trim()):documentName,
          doc: uploadedDocument,
        })
      );
      form.resetFields(["DocName", "non_ocr_doc_pic"]);
    };
    return (
      <Row gutter={32}>
        <Col span={12} md={12} xs={24} sm={24}>
          <Row gutter={0}>
            <Col span={12} md={8} xs={8} sm={8}>
              <label class="custom-label" title="Document Name / Upload">
                Document Name / Upload
              </label>
            </Col>
            <Col span={12} md={16} xs={16} sm={16}>
              <div className="applicant-upload-block">
                <Row gutter={2}>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item name="DocName" label="">
                      <Input
                        onFocus={() => {
                          setErrors("DocName", []);
                        }}
                        // onChange={handleDocumentName}
                        name="DocName"
                        placeholder='Document Name'
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      label=""
                      name="non_ocr_doc_pic"
                      onFocus={() => {
                        setErrors("non_ocr_doc_pic", []);
                      }}
                    >
                      <Upload
                        maxCount={1}
                        beforeUpload={(file, fileList) => {
                          if (
                            beforeUploadNonRequiredOcrDocument(file, fileList)
                          ) {
                            return Upload.LIST_IGNORE;
                          } else {
                            return false;
                          }
                        }}
                      >
                        <Button className="btn-label-overclip">
                          Upload <img src={uplaodIcon} alt="uplaodIcon" />
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={12} md={24} xs={24} sm={24}>
                    <div className="AddField text-right mt-2 mb-2">
                      <Button htmlType="button" onClick={addButtonHandler}>
                        Add
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12} md={12} xs={24} sm={24}>
          {nonRequiredNonOcrDocuments.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Document Name </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {nonRequiredNonOcrDocuments &&
                  nonRequiredNonOcrDocuments.map((document, index) => {
                    return (
                      <tr key={document.name}>
                        <td>{document.name}</td>
                        <td>
                          <Button
                            onClick={() => {
                              handlePreviewNonOcrDoc(document, dispatch);
                            }}
                          >
                            View
                          </Button>
                          <Button
                            onClick={() => {
                              const filteredDocs = (docs) => {
                                setNonRequiredNonOcrDocuments(docs);
                              };
                              deleteMemberDirectorNonOcrDocument(
                                document,
                                nonRequiredNonOcrDocuments,
                                index,
                                dispatch,
                                filteredDocs,
                                newlyCreatedCase.id
                              );
                              // const result = deleteNonOcrDocument(
                              //   document,
                              //   nonRequiredNonOcrDocuments,
                              //   index,
                              //   newlyCreatedCase.id,
                              //   dispatch
                              // );
                              // if (
                              //   result &&
                              //   result.status &&
                              //   result.status === "1"
                              // ) {
                              //   setNonRequiredNonOcrDocuments(result.docs);
                              // }
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    );
  };
  const GetDirectorMemberButton = () => {
    setMemberOrDirector(false);

    const openMemberDirectorModal = () => {
      if (memberDirectorList.length < 5) {
        setMemberOrDirectorModalOpen(true);
        setDirectorMemberError("");
      } else {
        setMemberOrDirectorModalOpen(false);
        setDirectorMemberError(
          selectedCategory === "2"
            ? MESSAGES.MAX_DIRECTOR_ERROR
            : MESSAGES.MAX_MEMBER_ERROR
        );
      }
      // if(editCoApplicant &&
      //   editCoApplicant.member_director &&
      //   Array.isArray(editCoApplicant.member_director)){
      //
      //
      //     if(editCoApplicant.member_director.length<5){
      //       setMemberOrDirectorModalOpen(true);
      //       setDirectorMemberError("");
      //     }else{
      //       setMemberOrDirectorModalOpen(false);
      //       setDirectorMemberError(
      //         selectedCategory === "2"
      //           ? MESSAGES.MAX_DIRECTOR_ERROR
      //           : MESSAGES.MAX_MEMBER_ERROR
      //       );
      //     }
      // }else{
      //   setMemberOrDirectorModalOpen(true);
      //       setDirectorMemberError("");
      // }
    };
    return (
      <Button className="AddField " onClick={openMemberDirectorModal}>
        Add {selectedCategory === "2" ? "Director" : "Member"} Details
      </Button>
    );
  };
  const closeProprietorModal = () => {
    setProprietorModalOpen(false);
  };
  // const closeDirectorMemberModal = (co_applicant)=>{
  //   dispatch(
  //     onParticipantDetail({
  //       applicant_type: "Co Applicant",
  //       applicant_coapplicant_guarantor_id: co_applicant.id,
  //     },(co_applicant)=>{
  //       makeEditable(co_applicant)
  //     })
  //   );
  // }
  const cancelApplicantCreation = () => {
    setPOADocumentError(false);
    if (props.add_new_coapplicant_inside_modal) {
      handleCancel();
    } else {
      setCancelPopup(true);
    }
  };
  const onCancelApplicantCreation = () => {
    setCancelPopup(false);
    if (add_new_coapplicant_inside_modal) {
      handleCancel();
    } else {
      history.push("/");
    }
  };
  const GetProprietorButton = () => {
    const openProprietorModal = () => {
      setProprietorModalOpen(true);
    };
    return (
      <Button className="AddField " onClick={openProprietorModal}>
        Add Proprietor Details
      </Button>
    );
  };
  const GetHeading = () => {
    return (
      <React.Fragment>
        {newlyCreatedCase && newlyCreatedCase.co_applicants.length > 0 && (
          <div className="HeaderHighlight" id="coapplicant_heading">
            <Row className="w-100">
              <Col span={12} md={12} xs={24} sm={24}>
                <h4>
                  {editCoApplicant
                    ? `Co-applicant Detail ${editCoApplicant.id}`
                    : !props.add_new_coapplicant_inside_modal &&
                      "Add New Co-Applicant"}
                </h4>
              </Col>
              <Col span={12} md={12} xs={24} sm={24}>
                {editCoApplicant &&
                  (selectedCategory === "2" || selectedCategory === "3") && (
                    <div className="ButtonWrap text-right">
                      <GetDirectorMemberButton />
                    </div>
                  )}
                {editCoApplicant &&
                  selectedCategory &&
                  selectedCategory.toString() === CATEGORY.PROPRIETOR &&
                  memberDirectorList.length === 0 && (
                    <div className="ButtonWrap text-right">
                      {" "}
                      <GetProprietorButton />
                    </div>
                  )}
              </Col>
            </Row>
          </div>
        )}
      </React.Fragment>
    );
  };
  const closeDirectorMemberModal = () => {
    setMemberOrDirectorModalOpen(false);
  };
  const handleCustomerName = (e) => {
    const input = e.target.value;
    if (!REGEX.NAME_VALIDATION.test(input)) {
      form.setFieldsValue({
        // 'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1))).replace( /[^a-zA-Z0-9 ]/gm, '')
        other_relation: firstCharacterOfEachWordUpperCase(
          input.substring(0, input.length - 1)
        )
          .replace(/\s\s+/g, " ")
          .replace(/^\s+/g, ""),
      });
    } else {
      form.setFieldsValue({
        other_relation: firstCharacterOfEachWordUpperCase(input)
          .replace(/\s\s+/g, " ")
          .replace(/^\s+/g, ""),
      });
    }
  };
  const onAddNewMemberDirector = (member_director) => {
    // const onSuccess = (updatedMemberDirectorList)=>{
    //
    //   setMemberDirectorList(updatedMemberDirectorList);
    //   setMemberOrDirector(member_director);
    // }
    const onSuccess = (participantDetail) => {
      updateMemberDirectorList(participantDetail);
      setMemberOrDirector(member_director);
      if (!props.add_new_coapplicant_inside_modal) {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      } else {
        makeEditable();
      }
    };
    let data = {
      applicant_type: APPLICANT_TYPE.CO_APPLICANT,
      applicant_coapplicant_guarantor_id: editCoApplicant.id,
    };

    dispatch(onParticipantDetail(data, onSuccess));
    // dispatch(onGetMemberDirectorList(data,onSuccess))
  };
  return (
    <>
      <GetHeading />
      {directorMemberError && (
        <p className="errorMessage">{directorMemberError}</p>
      )}
      <Col span={24} md={24} xs={24} sm={24}>
      <h4 className='rightHeading'>
        Co-Applicant Stage:{" "}
        {(editCoApplicant &&
          editCoApplicant.stage)
            ? editCoApplicant.stage
            : "Documents Required"}
        
      </h4>
    </Col>
      <Form {...layout} name="mainapplicant" form={form} onFinish={onFinish}>
        <Row gutter={32}>
          {/*<Col span={24} md={24} xs={24} sm={24}>
            <h4>
              Co Applicant Status :{" "}
              {editCoApplicant ? editCoApplicant.stage : "Documents Required"}
            </h4>
      </Col>*/}
          <Col span={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="Co-Applicant Type"
              name="applicant_category"
              rules={[
                {
                  required: true,
                  message: REQUIRED_MESSAGES.COAPPLICANT_CATEGORY_REQUIRED,
                },
              ]}
              form={form}
              className="TopTabBox"
            >
              <Select
                placeholder="Please Select Co-Applicant Type"
                onChange={(val) => {
                  changeSubCategories(val);
                  setOcrDocuments([]);
                  setRequiredNonOcrDocuments([]);
                  // setNonRequiredNonOcrDocuments([]);
                }}
                disabled={editCoApplicant}
              >
                {caseMasters &&
                  caseMasters.categories &&
                  Array.isArray(caseMasters.categories) &&
                  caseMasters.categories.map((category) => {
                    if (category.category_name !== "HUF") {
                      return (
                        <Option
                          value={category.id.toString()}
                          key={category.id.toString()}
                        >
                          {category.category_name}
                        </Option>
                      );
                    }
                  })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="Sub Category"
              name="application_sub_category"
              rules={[
                {
                  required: subCategories.length > 0,
                  message: REQUIRED_MESSAGES.APPLICATION_SUB_CATEGORY_REQUIRED,
                },
              ]}
              className="TopTabBox"
            >
              <Select
                placeholder={"Please select sub category"}
                disabled={subCategories.length === 0 || editCoApplicant}
                onChange={(val) => {
                  setRequiredNonOcrDocuments([]);
                  setSelectedSubCategory(val);
                }}
              >
                {subCategories.map((subCategory) => (
                  <Option
                    value={subCategory.id.toString()}
                    key={subCategory.id.toString()}
                  >
                    {subCategory.subcategory_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12} md={12} sm={12} xs={24}>
            <Form.Item
              label="Relationship"
              name="relationship"
              rules={[
                {
                  required: true,
                  message: REQUIRED_MESSAGES.RELATIONSHIP_REQUIRED,
                },
              ]}
              className="TopTabBox relapp"
            >
              <Select
                placeholder={"Relationship with applicant"}
                onSelect={(val) => {
                  setRelationship(val);
                }}
              >
                {caseMasters.relationships.map((relation) => (
                  <Option
                    value={relation.name.toString()}
                    key={relation.id.toString()}
                  >
                    {relation.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          {relationship === "Others" && (
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item
                label="Other"
                name="other_relation"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.OTHER_RELATION_REQUIRED,
                  },
                  {
                    max: 50,
                    message: "Maximum 50 characters can be entered",
                  },
                ]}
              >
                <Input
                  placeholder="relationship"
                  onChange={handleCustomerName}
                  maxLength={51}
                />
              </Form.Item>
            </Col>
          )}

          {/*<AddOcrDocument  form={form}/>*/}
         
          <Col span={24} md={24} xs={24} sm={24}>
            <h4>
              {HEADINGS.OCR_DOCUMENT_HEADING}
              <span className="SubHeading">
                {HEADINGS.OCR_DOCUMENT_SUB_HEADING}
              </span>
            </h4>
          </Col>
          
          <OcrDocument />
        </Row>
        {selectedCategory == CATEGORY.INDIVIDUAL && (
          <div className={poaDocumentError ? "smallErrorMessage" : ""}>
            Note: {REQUIRED_MESSAGES.PLEASE_UPLOAD_ATLEAST_ONE_POA_DOCUMENT}
          </div>
        )}
        <Row gutter={32}>
          <Col span={24}>
            <h4>
              {HEADINGS.NON_OCR_DOCUMENT_HEADING}
              <span className="SubHeading">
                {HEADINGS.NON_OCR_DOCUMENT_SUB_HEADING}
              </span>
            </h4>
          </Col>
          <RequiredNonOcrDocument />
        </Row>
        <AddNonRequiredNonOcrDoc />
        {editCoApplicant &&
          (selectedCategory === "2" ||
            selectedCategory === "3" ||
            selectedCategory === "4") && (
            <React.Fragment>
              {/*<div className="ButtonWrap text-right">
                      <GetDirectorMemberButton />
          </div>*/}
              <MemberDirectorListing
                makeEditable={makeEditable}
                applicant={editCoApplicant}
                applicant_type={APPLICANT_TYPE.CO_APPLICANT}
                members={memberDirectorList}
                onAddNewMemberDirector={onAddNewMemberDirector}
                title={
                  selectedCategory === "2"
                    ? MEMBER_TYPE.DIRECTOR
                    : selectedCategory === "3"
                    ? MEMBER_TYPE.MEMBER
                    : MEMBER_TYPE.PROPRIETOR
                }
              />
            </React.Fragment>
          )}
          <div  className='AboveButtonNote'> <span>
        Note : Run OCR to fetch details from OCR documents{" "}
        {selectedCategory &&
          selectedCategory != "1" &&
          "and GST number"}{" "}
        {!selectedCategory && "and GST number"}
      </span></div>
        <div className="MainBtnWrap">
          <Button className="plane" onClick={cancelApplicantCreation}>
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() => {
              let errors = displayErrorMessage();
              form.setFieldsValue({
                DocName: "",
                non_ocr_doc_pic: undefined,
              });
              setRunOcr(false);
            }}
          >
            Save
          </Button>
          <Button
            htmlType="submit"
            onClick={() => {
              let errors = displayErrorMessage();
              form.setFieldsValue({
                DocName: "",
                non_ocr_doc_pic: undefined,
              });
              if (errors.length === 0) {
                setRunOcr(true);
              }
            }}
          >
            {editCoApplicant &&
            editCoApplicant.stage &&
            isOcrDone(editCoApplicant.stage)
              ? "Save And Re-Run OCR"
              : "Save And Run OCR"}
          </Button>
        </div>
      </Form>
      <PreviewImageOrPDF />

      {editCoApplicant && (
        <Modal
          title={selectedCategory === "2" ? "Director" : "Member"}
          centered
          visible={isMemberOrDirectorModalOpen}
          destroyOnClose={true}
          // onOk={() => setModal2Visible(false)}
          onCancel={closeDirectorMemberModal}
          footer={null}
          width={1000}
          className="ModalFormWrap AddCoapplicant DirectModalStyle"
          style={{ top: 50 }}
        >
          <AddIndividualForm
            data={{
              applicant_type: APPLICANT_TYPE.CO_APPLICANT,
              member_type:
                selectedCategory === CATEGORY.CORPORATE
                  ? MEMBER_TYPE.DIRECTOR
                  : MEMBER_TYPE.MEMBER,
              applicant_id: editCoApplicant.id,
            }}
            closeDirectorMemberModal={closeDirectorMemberModal}
            memberOrDirector={memberOrDirector}
            onAddNewMemberDirector={onAddNewMemberDirector}
            // data={{
            //   applicant_type: "Co Applicant",
            //   member_type: selectedCategory === "2" ? "Director" : "Member",
            //   applicant_id: editCoApplicant.id,
            // }}
            // closeDirectorMemberModal={() => setMemberOrDirectorModalOpen(false)}
            // updateEditCoApplicant={makeEditable}
          />
        </Modal>
      )}
      <CancelApplicantPopup
        isVisible={applicantCancelPopup}
        title={CONSTANTS.CANCEL_APPLICANT}
        onOk={onCancelApplicantCreation}
        onCancel={() => {
          setCancelPopup(false);
        }}
      />
      {editCoApplicant && (
        <Modal
          title={"Proprietor Details"}
          centered
          visible={isProprietorModalOpen}
          destroyOnClose={true}
          // onOk={() => setModal2Visible(false)}
          onCancel={() => setProprietorModalOpen(false)}
          footer={null}
          width={1000}
          className="ModalFormWrap AddCoapplicant"
          style={{ top: 50 }}
        >
          <AddIndividualForm
            data={{
              applicant_type: APPLICANT_TYPE.CO_APPLICANT,
              member_type: MEMBER_TYPE.PROPRIETOR,
              applicant_id: editCoApplicant.id,
            }}
            closeDirectorMemberModal={closeProprietorModal}
            isProprietor={true}
            memberOrDirector={proprietor}
            onAddNewMemberDirector={onAddEditProprietor}
          />
        </Modal>
      )}

      {/*<Modal
        title="Document Uploaded Successfully"
        centered
        visible={ocrResponse}
        onOk={() => closeRunOcrModal(dispatch)}
        onCancel={() => closeRunOcrModal(dispatch)}
        footer={null}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunOcr />
      </Modal>*/}
    </>
  );
};
export default AddCoApplicant;
