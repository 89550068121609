import { INITIAL_STATE } from "../../constants/constants";
import {
  LOAD_FI_AGENT,
  LOAD_FIELDINVESTIGATION,
  LOAD_FIELDINVESTIGATIONOTP,
  LOAD_FIELDINVESTIGATIONOTPVERIFY,
  LOAD_FIELDINVESTIGATIONALL,
  LOAD_FIELDINVESTIGATIONCASE,
  LOAD_FIELDINVESTIGATIONRESPONSE,
  CHECK_LINK_EXPIRE,
  LOAD_CASE_DETAIL_FOR_FI
} from "../constants/ActionTypes";

const DEFAULT_STATE = INITIAL_STATE;
export default function fieldInvestigation(state = DEFAULT_STATE, action) {
  switch (action.type) {
    case LOAD_FI_AGENT:
      return {
        ...state,
        fiAgentData: action.fiAgentData,
      };
    case LOAD_FIELDINVESTIGATION:
      return {
        ...state,
        fiAddData: action.fiAddData,
      };
    case LOAD_FIELDINVESTIGATIONOTP:
      return {
        ...state,
        fiOTP: action.fiOTP,
      };
      case LOAD_FIELDINVESTIGATIONOTPVERIFY:
      return {
        ...state,
        fiOTP: action.fiOTPVerify,
      };
      
      case LOAD_FIELDINVESTIGATIONALL:
      return {
        ...state,
        fiAll: action.fiGetAll,
      };
      case LOAD_FIELDINVESTIGATIONCASE:
      return {
        ...state,
        fiCase: action.fiCase,
      };
      case LOAD_FIELDINVESTIGATIONRESPONSE:
      return {
        ...state,
        fiResponse: action.fiResponse,
      };
      case CHECK_LINK_EXPIRE:
      return {
        ...state,
        fiLinkExpire: action.fiLinkExpire,
      };
      case LOAD_CASE_DETAIL_FOR_FI:
      return {
        ...state,
        fiCaseDetails: action.fiCaseDetails,
      };
    default:
      return state;
  }
}
