import React, { useState } from "react";
import { Card, Row, Col, Button, Input } from "antd";
import { connect, useDispatch } from "react-redux";
import {Form} from "antd";
import { REQUIRED_MESSAGES } from "../../../constants/messages";
// import { onAddNewCase, onCaseDetail } from "../../../redux/actions/AuthAction";
import NumberFormat from "react-number-format";
import { useForm } from "antd/lib/form/Form";
import { onAddNewCase, onCaseDetail } from "../../../redux/actions/CaseAction";
import { isSalesUser, isVolvoUser, loggedInUserData } from "../../../helpers/utils";
import { useHistory } from "react-router-dom";
const LoanDetail = (props) => {
  const { caseDetail = {} } = props;
  const [isEditable, setIsEditable] = useState(false);
  const userData = loggedInUserData();
  const history = useHistory();
  const [form] = useForm()
  const dispatch = useDispatch();
  const updateCaseDetails = (values) => {
    
    
    if((values.finance_amount.toString() === caseDetail.finance_amount.toString()) && (values.qty_required.toString() === caseDetail.qty_required.toString())){
      setIsEditable(false);
    }else{
      const data = {
        id:caseDetail.id,
        fk_category_id:caseDetail.fk_category_id,
        fk_subcategory_id:caseDetail.fk_subcategory_id,
        fk_business_area_id:caseDetail.fk_business_area_id,
        fk_product_id:caseDetail.fk_product_id,
        fk_product_model_id:caseDetail.fk_product_model_id,
        finance_type:caseDetail.finance_type,
        finance_amount:values.finance_amount.toString().replaceAll(",", "").replace("&#8377","").replace(/[^\x00-\x7F]/g,""),
        qty_required:values.qty_required.toString().replaceAll(",", ""),
        customer_name:caseDetail.customer_name,
      }
      dispatch(onAddNewCase(data,(response)=>{
        const data = {
          caseId:caseDetail.id,
        };
        dispatch(onCaseDetail(data,()=>{
          setIsEditable(false);
        }))
      }))
    }
  };
  return (
    <div className="CardDetailWrap">
      <Form onFinish={updateCaseDetails} form={form}>
        <div className="EditCard">
          <h3>Product and Loan Detail </h3>
          <div>
            {!isEditable ? (
              userData && isSalesUser(userData) && <Button
                onClick={() => {
                  if(isVolvoUser(userData)){
                    history.push(`/edit-case/${caseDetail.id}`)
                  }else{
                    setIsEditable(true);
                  }
                }}
              >
                Edit
              </Button>
            ) : (
              <React.Fragment>
                <Button
                  onClick={() => {
                    form.setFieldsValue({
                      'finance_amount':caseDetail.finance_amount,
                      'qty_required':caseDetail.qty_required
                    })
                    setIsEditable(false);
                  }}
                >
                  Cancel
                </Button>
                <Button htmlType="submit">Save</Button>
              </React.Fragment>
            )}
          </div>
        </div>
        <Card>
          <Row>
            <Col span={12} xs={24} sm={12}>
              <h4>Business Area</h4>
            </Col>
            <Col span={12} xs={24} sm={12}>
              <p>{caseDetail.business_area}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} xs={24} sm={12}>
              <h4>Product Type</h4>
            </Col>
            <Col span={12} xs={24} sm={12}>
              <p>Heavy Duty Trucks</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} xs={24} sm={12}>
              <h4>Product Name</h4>
            </Col>
            <Col span={12} xs={24} sm={12}>
              <p>{caseDetail.product_name}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} xs={24} sm={12}>
              <h4>Product Model No.</h4>
            </Col>
            <Col span={12} xs={24} sm={12}>
              <p>{caseDetail.model_no}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} xs={24} sm={12}>
              <h4>Type of financing</h4>
            </Col>
            <Col span={12} xs={24} sm={12}>
              <p>{caseDetail.finance_type}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} xs={24} sm={12}>
              <h4>Loan Amount Required</h4>
            </Col>
            <Col span={12} xs={24} sm={12}>
              {isEditable ? (
                <Form.Item
                // label="Loan/Lease Amount Required"
                name="finance_amount"
                initialValue={caseDetail.finance_amount}
                extra="Note: Maximum loan amount is 10,000,000"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.LOAN_AMOUNT_REQUIRED,
                  },
                ]}
              >
                <NumberFormat
                  placeholder="Loan Amount"
                  name="finance_amount"
                  class='ant-input'
                  prefix="&#8377;"
                  isAllowed={(values) => {
                    
                    const { formattedValue, floatValue, value } = values;
                    if (value.startsWith("0")) {
                      return false;
                    }
                    return formattedValue === "" || floatValue <= 10000000000;
                  }}
                  allowNegative={false}
                  allowedDecimalSeparators={false}
                  defaultValue={caseDetail.finance_amount.toString()}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  decimalScale={0}
                  onValueChange={(value) => {
                    
                  }}
                  className="ant-input"
                />
                {/*<Input disabled={isNewlyCreatedCaseSaved && !isEditMode} />*/}
              </Form.Item>
           
              ) : (
                <p>&#8377;{caseDetail.finance_amount}</p>
              )}
              {/*<input placeholder="15,00,000" className="EditableField" />*/}
            </Col>
          </Row>
          <Row>
            <Col span={12} xs={24} sm={12}>
              <h4>Quantity Required</h4>
            </Col>
            <Col span={12} xs={24} sm={12}>
              {isEditable ? 
                <Form.Item
                // label="Quantity Required"
                name="qty_required"
                initialValue={caseDetail.qty_required.toString()}
                extra={"Note: Maximum quantity is 10,000"}
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.QUANTITY_REQUIRED,
                  },
                  // {pattern: new RegExp("^([1-9][0-9]{0,2}|10000)$"),message:'only numbers allowed'}
                ]}
              >
              <NumberFormat
                  placeholder="Quantity Required"
                  class='ant-input'
                  name="qty_required"
                  isAllowed={(values) => {
                    
                    const { formattedValue, floatValue, value } = values;
                    if (value.startsWith("0")) {
                      return false;
                    }
                    return formattedValue === "" || floatValue <= 10000;
                  }}
                  allowNegative={false}
                  decimalScale={0}
                  allowLeadingZeros={false}
                  thousandSeparator={true}
                  onValueChange={(value) => {
                    
                  }}
                  defaultValue={caseDetail.qty_required.toString()}
                  className="ant-input"
                />
                {/*<Input disabled={isNewlyCreatedCaseSaved && !isEditMode} />*/}
              </Form.Item>
           
              //   (
              //   <Form.Item className="EditableField" name="qty_required"
              //   rules={[
              //     {
              //       required: true,
              //       message: REQUIRED_MESSAGES.QUANTITY_REQUIRED,
              //     },
              //   ]}
              //   >
              //     <Input placeholder={caseDetail.qty_required} />
              //   </Form.Item>
              // ) 
              : (
                <p>{caseDetail.qty_required}</p>
              )}
              {/*<input placeholder="10" className="EditableField" />*/}
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
  };
};
export default connect(mapStateToProps, null)(LoanDetail);
