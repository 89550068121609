export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';
export const SET_PERMISSION_DATA = 'SET_PERMISSION_DATA';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT = 'LOGOUT';
export const FORGOT = 'FORGOT';
export const RESET_PASSWORD = 'RESET_PASSWORD';

export const CASE_MASTERS = 'CASE_MASTERS';
export const SET_CASE_MASTERS = 'SET_CASE_MASTERS';
export const SET_CASES = 'SET_CASES';

export const CASES = 'CASES';
export const CASE_DETAIL = 'CASE_DETAIL';
export const SET_CASE_DETAIL = 'SET_CASE_DETAIL';
export const ADD_NEW_CASE = 'ADD_NEW_CASE';

export const UPLOAD_OCR_DOCUMETS = 'UPLOAD_OCR_DOCUMETS';
export const UPLOAD_NON_OCR_DOCUMETS = 'UPLOAD_NON_OCR_DOCUMETS';

export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const NEW_CASE_CREATED = 'NEW_CASE_CREATED';
export const REMOVE_NEW_CASE = 'REMOVE_NEW_CASE';
export const UPDATE_OCR_DOCS = 'UPDATE_OCR_DOCS';
export const UPDATE_NON_OCR_DOCS = 'UPDATE_NON_OCR_DOCS';
export const SET_FIRST_TAB = 'SET_FIRST_TAB';
export const SET_SECOND_TAB = 'SET_SECOND_TAB';
export const CHANGE_SELECTED_USER = 'CHANGE_SELECTED_USER';

export const GET_USER_OCR_RESULT = 'GET_USER_OCR_RESULT';
export const GET_USER_OCR_RESULT_SUCCESS = 'GET_USER_OCR_RESULT_SUCCESS';
export const GET_USER_OCR_RESULT_FAILED = 'GET_USER_OCR_RESULT_FAILED';

export const CREDIT_USER = 'CREDIT_USER';
export const CREDIT_USER_SUCCESS = 'CREDIT_USER_SUCCESS';
export const CREDIT_USER_FAILED = 'CREDIT_USER_FAILED';

export const STATES = 'STATES';
export const CITIES = 'CITIES';
export const SSO_LOGIN = 'SSO_LOGIN';
export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
export const SET_STATES = 'SET_STATES';
export const SET_CITIES = 'SET_CITIES';
export const CIBIL_CITIES = 'CIBIL_CITIES';
export const SET_CIBIL_CITIES = 'SET_CIBIL_CITIES';

export const UPDATE_MAIN_APPLICANT = 'UPDATE_MAIN_APPLICANT';
export const UPDATE_CO_APPLICANT = 'UPDATE_CO_APPLICANT';
export const UPDATE_GUARANTOR = 'UPDATE_GUARANTOR';

export const RUN_OCR = 'RUN_OCR';
export const ON_GOING_CASES = 'ON_GOING_CASES';
export const RESET_NEW_CASE = 'RESET_NEW_CASE';
export const SET_PAGE_KEY = 'SET_PAGE_KEY';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const DELETE_NON_OCR_DOCUMENT = 'DELETE_NON_OCR_DOCUMENT';
export const UPDATE_NEW_CASE = 'UPDATE_NEW_CASE';
export const UPDATE_NEW_CASE_2 = 'UPDATE_NEW_CASE_2';
export const GET_PARTICIPANT_DETAIL = 'GET_PARTICIPANT_DETAIL';
export const RUN_KYC = 'RUN_KYC';
export const LOG_NEW_CASE_ACTIVE_KEY = 'LOG_NEW_CASE_ACTIVE_KEY';
export const UPLOAD_DOCUMENT_ACTIVE_KEY = 'UPLOAD_DOCUMENT_ACTIVE_KEY';
export const CURRENT_CASE = 'CURRENT_CASE';
export const SET_CURRENT_CASE = 'SET_CURRENT_CASE';
export const DASHBOARD = 'DASHBOARD';
export const SET_DASHBOARD_DATA = 'SET_DASHBOARD_DATA';
export const RESET_PARTICIPANT = 'RESET_PARTICIPANT';
export const DELETE_COAPPLICANT = 'DELETE_COAPPLICANT';
export const DELETE_GUARANTOR = 'DELETE_GUARANTOR';
export const RESET_INDIVIDUAL_MODAL = 'RESET_INDIVIDUAL_MODAL';

export const CREDIT_PENDING_CASES = 'CREDIT_PENDING_CASES';
export const CREDIT_PENDING_CASES_SUCCESS = 'CREDIT_PENDING_CASES_SUCCESS';
export const CREDIT_PENDING_CASES_FAILED = 'CREDIT_PENDING_CASES_FAILED';

export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const SET_SELECTED_SUB_CATEGORY = 'SET_SELECTED_SUB_CATEGORY';
export const SET_OCR_DOCS = 'SET_OCR_DOCS';
export const SET_NON_OCR_DOCS = 'SET_NON_OCR_DOCS';

export const ADD_REQUEST_REVIEW = 'ADD_REQUEST_REVIEW';
export const GET_REQUEST_COMMENTS = 'GET_REQUEST_COMMENTS';
export const GET_REQUEST_COMMENTS_FAILED = 'GET_REQUEST_COMMENTS_FAILED';
export const GET_REQUEST_COMMENTS_SUCCESS = 'GET_REQUEST_COMMENTS_SUCCESS';

export const ADD_JUSTIFICATION_COMMENTS = 'ADD_JUSTIFICATION_COMMENTS';
export const GET_JUSTIFICATION_COMMENTS = 'GET_JUSTIFICATION_COMMENTS';
export const GET_JUSTIFICATION_COMMENTS_FAILED = 'GET_JUSTIFICATION_COMMENTS_FAILED';
export const GET_JUSTIFICATION_COMMENTS_SUCCESS = 'GET_JUSTIFICATION_COMMENTS_SUCCESS';

export const ADD_COMMENT_BY_CREDIT = 'ADD_COMMENT_BY_CREDIT';


export const ADD_MEMBER_DIRECTOR = 'ADD_MEMBER_DIRECTOR';
export const SET_PREVIEW_MODAL_VISIBILITY='SET_PREVIEW_MODAL_VISIBILITY';
export const SET_PREVIEW_PDF = 'SET_PREVIEW_PDF';
export const SET_PREVIEW_IMAGE = 'SET_PREVIEW_IMAGE';
export const SET_PREVIEW_LOADER = 'SET_PREVIEW_LOADER';
export const DELETE_MEMBER_DIRECTOR = 'DELETE_MEMBER_DIRECTOR';
export const DIRECTOR_MEMBER_MODAL_VISIBILITY = 'DIRECTOR_MEMBER_MODAL_VISIBILITY';
export const EDIT_DIRECTOR_MEMBER_DATA = 'EDIT_DIRECTOR_MEMBER_DATA';
export const SET_RUN_OCR_RESPONSE = 'SET_RUN_OCR_RESPONSE';
export const CHANGE_MIDDLE_TAB = 'CHANGE_MIDDLE_TAB';
export const GET_MEMBER_DIRECTOR_DETAIL = 'GET_MEMBER_DIRECTOR_DETAIL';
export const GET_MEMBER_DIRECTOR_LIST = 'GET_MEMBER_DIRECTOR_LIST';
export const CHANGE_FIRST_TAB_KEY = 'CHANGE_FIRST_TAB_KEY';
export const CHANGE_SECOND_TAB_KEY = 'CHANGE_SECOND_TAB_KEY';
export const CHANGE_THIRD_TAB_KEY = 'CHANGE_THIRD_TAB_KEY';
export const CHANGE_SELECTED_MEMBER_DIRECTOR = 'CHANGE_SELECTED_MEMBER_DIRECTOR';
export const SALES_ON_GOING_CASES = 'SALES_ON_GOING_CASES';
export const RESET_CASE_DETAIL = 'RESET_CASE_DETAIL';

export const GET_BUREAU_REPORT_EXCEL = 'GET_BUREAU_REPORT_EXCEL';

export const GET_BUREAU_REPORT_LISTING = 'GET_BUREAU_REPORT_LISTING';
export const GET_BUREAU_REPORT_LISTING_SUCCESS = 'GET_BUREAU_REPORT_LISTING_SUCCESS';
export const GET_BUREAU_REPORT_LISTING_FAILED = 'GET_BUREAU_REPORT_LISTING_FAILED';

export const GET_BUREAU_REPORT_LISTING_11 = 'GET_BUREAU_REPORT_LISTING_11';
export const GET_BUREAU_REPORT_HTML = 'GET_BUREAU_REPORT_HTML';
export const GET_BUREAU_REPORT_LIST = 'GET_BUREAU_REPORT_LIST';

export const GET_BUREAU_REPORT_MASTER = 'GET_BUREAU_REPORT_MASTER';
export const GET_BUREAU_REPORT_MASTER_FAILED = 'GET_BUREAU_REPORT_MASTER_FAILED';
export const GET_BUREAU_REPORT_MASTER_SUCCESS = 'GET_BUREAU_REPORT_MASTER_SUCCESS';

export const GET_CIBIL_REPORT_MASTER = 'GET_CIBIL_REPORT_MASTER';
export const GET_CIBIL_REPORT_MASTER_FAILED = 'GET_CIBIL_REPORT_MASTER_FAILED';
export const GET_CIBIL_REPORT_MASTER_SUCCESS = 'GET_CIBIL_REPORT_MASTER_SUCCESS';

export const GENERATE_BUREAU_REPORT = 'GENERATE_BUREAU_REPORT';
export const GENERATE_CIBIL_REPORT = 'GENERATE_CIBIL_REPORT';
export const GENERATE_BUREAU_REPORT_CHECK = 'GENERATE_BUREAU_REPORT_CHECK';

export const SET_SELECTED_BUREAU_REPORT = 'SET_SELECTED_BUREAU_REPORT';
export const VIEW_BUREAU_REPORT = 'VIEW_BUREAU_REPORT';
export const SET_BUREAU_REPORT_LISTING = 'SET_BUREAU_REPORT_LISTING';
export const TO_RESET_NEW_CASE = 'TO_RESET_NEW_CASE';
export const GET_USER_OCR_RESULT2 = 'GET_USER_OCR_RESULT2';
export const SET_LOADER = 'SET_LOADER';

export const APPROVE_REJECT_PARTICIPANT = 'APPROVE_REJECT_PARTICIPANT';
export const GET_PARTICIPANT_BY_CASE_ID = 'GET_PARTICIPANT_BY_CASE_ID';

export const GET_USER_BY_STATE_CITY = 'GET_USER_BY_STATE_CITY';
export const CASE_REALLOCATION = 'CASE_REALLOCATION';

export const GET_BUREAU_REPORT_PARAMETERS = 'GET_BUREAU_REPORT_PARAMETERS';
export const GET_BUREAU_REPORT_SUMMARY = 'GET_BUREAU_REPORT_SUMMARY';

export const RUN_NEW_OCR = 'RUN_NEW_OCR';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';

export const READ_NOTIFICATION = 'READ_NOTIFICATION';
export const CASE_REJECT_APPROVE = 'CASE_REJECT_APPROVE';
export const CLOSED_CASES = 'CLOSED_CASES';
export const TO_BE_MOVED_TO_LOS = 'TO_BE_MOVED_TO_LOS';
export const CLOSED_CASES_SUCCESS = 'CLOSED_CASES_SUCCESS';
export const CLOSED_CASES_FAILED = 'CLOSED_CASES_FAILED';

export const SYSTEM_RECOMMENDATION = 'SYSTEM_RECOMMENDATION';
export const SET_CURRENT_OCR_RESULT = 'SET_CURRENT_OCR_RESULT';
export const SET_CURRENT_KYC_RESULT = 'SET_CURRENT_KYC_RESULT';
export const DELETE_OCR_DOCUMENT = 'DELETE_OCR_DOCUMENT';
export const GET_CASE_DETAIL_EXCEL = 'GET_CASE_DETAIL_EXCEL';

//constant for fleet analysis(Ashish Choudhary)
export const FLEET_ANALYSIS = 'FLEET_ANALYSIS';
export const FLEET_SUCCESS = 'FLEET_SUCCESS';
export const FLEET_ANALYSIS_LIST = 'FLEET_ANALYSIS_LIST';
export const FLEET_ANALYSIS_LIST_BY_CASE = 'FLEET_ANALYSIS_LIST_BY_CASE';
export const FLEET_ANALYSIS_RE_RUN = 'FLEET_ANALYSIS_RE_RUN';
export const DOWNLOAD_FLEET_REPORT = 'DOWNLOAD_FLEET_REPORT';
export const DOWNLOAD_FLEET_TEMPLATE = 'DOWNLOAD_FLEET_TEMPLATE';
export const DOWNLOAD_FLEET_SUMMARY_REPORT = 'DOWNLOAD_FLEET_SUMMARY_REPORT';


//Constant for hunter cases run (yadu patidar)
export const DOWNLOAD_HUNTER_REPORT = 'DOWNLOAD_HUNTER_REPORT';
export const DOWNLOAD_HUNTER_SUMMARY_REPORT = 'DOWNLOAD_HUNTER_SUMMARY_REPORT';
export const HUNTER_SUCCESS='HUNTER_SUCCESS';
export const KYC_DONE_CASES_LIST="KYC_DONE_CASES_LIST";
export const SET_KYC_CASES="SET_KYC_CASES";
export const RUN_HUNTER="RUN_HUNTER";
export const HUNTER_SUMMARY_LIST="HUNTER_SUMMARY_LIST";
export const SET_HUNTER_SUMMARY="SET_HUNTER_SUMMARY";
export const GET_HUNTER_DETAIL_BY_CASE="GET_HUNTER_DETAIL_BY_CASE"






//Constant for bureau report-generated-cases cases (yadu patidar)
export const DOWNLOAD_BUREAU_REPORT = 'DOWNLOAD_BUREAU_REPORT';
export const DOWNLOAD_BUREAU_SUMMARY_REPORT = 'DOWNLOAD_BUREAU_SUMMARY_REPORT';
export const BUREAU_DONE_CASES_LIST="BUREAU_DONE_CASES_LIST";
export const  SET_BUREAU_CASES="SET_BUREAU_CASES";

//Constant for bureau field-investigation-cases (Shashikant patidar)
export const GET_FI_AGENT = "GET_FI_AGENT";
export const LOAD_FI_AGENT = "LOAD_FI_AGENT";
export const ADD_FIELDINVESTIGATION = 'ADD_FIELDINVESTIGATION';
export const LOAD_FIELDINVESTIGATION = 'LOAD_FIELDINVESTIGATION';
export const ADD_FIELDINVESTIGATIONOTP = 'ADD_FIELDINVESTIGATIONOTP';
export const LOAD_FIELDINVESTIGATIONOTP = 'LOAD_FIELDINVESTIGATIONOTP';
export const ADD_FIELDINVESTIGATIONOTPVERIFY = 'ADD_FIELDINVESTIGATIONOTPVERIFY';
export const LOAD_FIELDINVESTIGATIONOTPVERIFY = 'LOAD_FIELDINVESTIGATIONOTPVERIFY';
export const GET_FIELDINVESTIGATIONALL = 'GET_FIELDINVESTIGATIONALL';
export const LOAD_FIELDINVESTIGATIONALL = 'LOAD_FIELDINVESTIGATIONALL';
export const GET_FIELDINVESTIGATIONCASE = 'GET_FIELDINVESTIGATIONCASE';
export const LOAD_FIELDINVESTIGATIONCASE = 'LOAD_FIELDINVESTIGATIONCASE';
export const ADD_FIELDINVESTIGATIONRESPONSE = 'ADD_FIELDINVESTIGATIONRESPONSE';
export const LOAD_FIELDINVESTIGATIONRESPONSE = 'LOAD_FIELDINVESTIGATIONRESPONSE';
export const CHECK_LINK_EXPIRE = 'CHECK_LINK_EXPIRE';
export const SET_CHECK_LINK_EXPIRE = 'SET_CHECK_LINK_EXPIRE';
export const GET_CASE_DETAIL_FOR_FI = 'GET_CASE_DETAIL_FOR_FI';
export const LOAD_CASE_DETAIL_FOR_FI = 'LOAD_CASE_DETAIL_FOR_FI';

//Constants for vaahan
export const GET_VAAHAN_DETAILS = 'GET_VAAHAN_DETAILS';
export const VAAHAN_SUCCESS='VAAHAN_SUCCESS';
export const STORE_VAAHAN_DETAILS='STORE_VAAHAN_DETAILS';
export const GET_VAHAN_REQUEST_DETAILS='GET_VAHAN_REQUEST_DETAILS';
export const RUN_VAHAN_HYPOTHECATION='RUN_VAHAN_HYPOTHECATION';
export const GET_VAAHAN_DETAILS_BY_ID = 'GET_VAAHAN_DETAILS_BY_ID';
export const VAAHAN_DETAILS='VAAHAN_DETAILS';













