import { Alert, Button, Modal } from "antd";

export const InformationModal = (props) => {
  const { isVisible, informationContent, title, handleOk } = props;
  return (
    <Modal
      visible={isVisible}
      className="KycCheck "
      footer={[<Button onClick={handleOk}>Ok</Button>]}
      style={{marginTop:'90px'}}
      onOk={handleOk}
      onCancel={handleOk}
    >
      {/*<Alert message={title} type="info"  description={informationContent} />*/}
      <p className="confirmationtext">{informationContent}</p>
    </Modal>
  );
};
