import createSagaMiddleware from 'redux-saga';
import {applyMiddleware, createStore , compose} from 'redux';
import {logger} from 'redux-logger';
import reducer from './reducers';
import rootSaga from './sagas';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

const persistConfig = {
    key: 'root',
    storage: storage,
    // whitelist: ['login', 'account', 'layout'],
    stateReconciler: autoMergeLevel2, // see "Merge Process" section for details.    
};

const persistedReducer = persistReducer(persistConfig, reducer);
const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const store = createStore(
//     reducer,
//     applyMiddleware(sagaMiddleware, logger),
// );
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
  
  
);
const persistor = persistStore(store);

export { store, persistor, sagaMiddleware };
sagaMiddleware.run(rootSaga);

export default store