import React, { useState } from "react";
import { Table, Form, Input } from "antd";

const CaseListing = (props) => {
  const [approve, setApprove] = useState(false);
  const [reject, setReject] = useState(false);
  return (
    <>
      <Form>
        <Input.Group compact>
          <Input.Search
            allowClear
            placeholder={props.case_type==='close'? "Search on Case ID, Business Area, Case Status"
            : "Search on Case ID, Customer Name, Business Area, Product Description, Product Segment, Finance Type"}
            
            value={props.searchedInput}
            onChange={(e) => {
              props.filterCaseListing(e);
            }}
          />
        </Input.Group>
      </Form>
      <Table
        columns={props.columns}
        dataSource={props.data}
        className="OnGoingCasesTable"
      />
    </>
  );
};
export default CaseListing;
