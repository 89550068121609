import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
// import Logo from "../assets/images/logo.png";
import LogoNew from "../assets/images/logo-new.png";
import { useDispatch } from "react-redux";
import { onLogin, onSetUserPermissions } from "../redux/actions/AuthAction";
import { capitalizeFirstLetter, isLoggedIn } from "../helpers/utils";
import { onCaseMasters } from "../redux/actions/MasterData";
const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSuccess = (responseData) => {
    if (responseData.data) {
      localStorage.setItem(
        "token",
        `${capitalizeFirstLetter(responseData.data.token_type)} ${
          responseData.data.access_token
        }`
      );
      localStorage.setItem(
        "userInfo",
        JSON.stringify(responseData.data.user_detail)
      );

      if (responseData.data.user_detail) {
        dispatch(
          onSetUserPermissions(
            responseData.data.user_detail.permissions,
            () => {
              window.location.reload();
            }
          )
        );
      }
      // history.push('/dashboard');
      // window.location.reload();
    }
  };
  const onFinish = (values) => {
    const data = {
      employee_id: values.loginid,
      password: values.password,
    };
    dispatch(onLogin(data, onSuccess));
  };
  const onFinishFailed = (errorInfo) => {};

  return (
    <React.Fragment>
      <div className="AuthWrap test AuthSingle">
        <div className="InnerWrap">
          <div className="HeadWrap">
            <h3>Login</h3>
          </div>
          <div className="Branding">
            <div className="text">
              <img src={LogoNew} alt="logo" />
              <h4>Volvo Financial Services</h4>
              <p>INDIA</p>
            </div>
          </div>
          {/* <Form
            layout="vertical"
            name="basic"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Login ID (Employee ID)"
              name="loginid"
              rules={[
                {
                  required: true,
                  message: "Please enter your Employee ID.",
                },
              ]}
            >
              <Input placeholder="Enter the Emplyoee ID" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your password.",
                },
              ]}
            >
              <Input.Password placeholder="Enter the Password" />
            </Form.Item>

            <Form.Item className="ForgetLink">
              <Link to="/forget-password">Forget Password?</Link>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                LOGIN
              </Button>
            </Form.Item>
            <Form.Item></Form.Item>
          </Form> */}

            {/* QA SSO URL  */}

          <div className="sso-login-btn"> <a className="ant-btn ant-btn-primary" href="https://federate-qa.volvo.com/as/authorization.oauth2?response_type=token&client_id=vfs-india-credit-portal&redirect_uri=https://www.qa-creditone.vfsco.in/ssologin">
            Volvo Single Sign On Login
            </a> </div>

            {/* Production SSO URL  */}

          {/* <div className="sso-login-btn">
            <a
              className="ant-btn ant-btn-primary"
              href="https://federate.volvo.com/as/authorization.oauth2?response_type=token&client_id=vfs-india-credit-portal&redirect_uri=https://www.creditone.vfsco.in/ssologin"
            > 
              Volvo Single Sign On Login
            </a>
          </div> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default Login;
