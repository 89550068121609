import React, { Component, useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { useSelector } from "react-redux";
import { OCR_DOCUMENT_LABEL } from "../../../../../constants/constants";
const Passport = (props) => {
  const ocrResult = useSelector((state) => state.auth.currentOcrResult);
  const kycResult = useSelector((state) =>
    state.auth.currentKycResult ? state.auth.currentKycResult : []
  );
  const [passportOCRResult, setPassportOcrResult] = useState({});
  const [passportKycResult, setPassportKycResult] = useState({});
  useEffect(() => {
    let passportOCRResult = ocrResult.find((ocr_result) => {
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.PASSPORT;
    });
    if (passportOCRResult) {
      setPassportOcrResult(passportOCRResult);
    }
  }, [ocrResult]);
  useEffect(() => {
    let passportKyc = kycResult.find((ocr_result) => {
      return ocr_result.document_name === OCR_DOCUMENT_LABEL.PASSPORT;
    });
    if (passportKyc) {
      setPassportKycResult(passportKyc);
    }
  }, [kycResult]);
  return (
    <>
      <div className="CardBlock">
        <h3>Passport</h3>
        <Card>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Passport Number</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(passportOCRResult && passportOCRResult.passport_no) ? passportOCRResult.passport_no : "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(passportOCRResult && passportOCRResult.name) ? passportOCRResult.name : "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Father Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(passportOCRResult && passportOCRResult.father) ? passportOCRResult.father : "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Mother Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(passportOCRResult && passportOCRResult.mother) ? passportOCRResult.mother : "-"}</p>
            </Col>
          </Row>
          <Row>
          <Col span={12} md={12} xs={24} sm={24}>
            <h4>Address</h4>
          </Col>
          <Col span={12} md={12} xs={24} sm={24}>
            <p>{(passportOCRResult && passportOCRResult.address) ? passportOCRResult.address : "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} md={12} xs={24} sm={24}>
            <h4>State</h4>
          </Col>
          <Col span={12} md={12} xs={24} sm={24}>
            <p>{(passportOCRResult && passportOCRResult.state) ? passportOCRResult.state : "-"}</p>
          </Col>
        </Row>
        <Row>
          <Col span={12} md={12} xs={24} sm={24}>
            <h4>City</h4>
          </Col>
          <Col span={12} md={12} xs={24} sm={24}>
            <p>{(passportOCRResult && passportOCRResult.city) ? passportOCRResult.city : "-"}</p>
          </Col>
        </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(passportOCRResult && passportOCRResult.dob) ? passportOCRResult.dob : "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOI</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(passportOCRResult && passportOCRResult.doi) ? passportOCRResult.doi: "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOE</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(passportOCRResult && passportOCRResult.doe) ? passportOCRResult.doe : "-"}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Gender</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{(passportOCRResult && passportOCRResult.gender) ?passportOCRResult.gender : "-"}</p>
            </Col>
          </Row>
          {/*<Row>
          <Col span={12} md={12} xs={24} sm={24}>
            <h4>PIN as mentioned in Aadhar</h4>
          </Col>
          <Col span={12} md={12} xs={24} sm={24}>
            <p>{userOcrResult?userOcrResult.pin:''}</p>
          </Col>
          </Row>*/}
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>Name Matching With Given Name</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{passportKycResult.name_matching_result}</p>
            </Col>
          </Row>
          <Row>
            <Col span={12} md={12} xs={24} sm={24}>
              <h4>DOB Matching</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <p>{passportKycResult.dob_matching_result}</p>
            </Col>
          </Row>
        </Card>
      </div>
    </>
  );
};
export default Passport;
