import React, { Component } from "react";
import { Card, Row, Col, Button } from "antd";

class LoanDetail extends Component {
  constructor(props) {
    super(props);
    this.state = { isShown: false };
  }

  onToggleDisplay() {
    this.setState({
      isShown: !this.state.isShown
    });
  }
  render() {
    const { isShown } = this.state;
    return (
      
      <>
        <div className="CardDetailWrap">
          <div className='EditCard'>
            <h3>Product and Loan Detail </h3>
            <div>
            {isShown ? '' :<Button  onClick={() => this.onToggleDisplay()}>Edit</Button>}
              {isShown ?<><Button   onClick={() => this.onToggleDisplay()}>Cancel</Button>
              <Button   onClick={() => this.onToggleDisplay()}>Save</Button></> : ' '}
            </div>
            
          </div>
          

          <Card>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Business Area</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Eicher Trucks</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Product Type</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Heavy Duty Trucks</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Product Name</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Tipper</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Product Model No.</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Pro 6019T</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Type of financing</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
                <p>Loan</p>
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Loan Amount Required</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
              {isShown ? '' : <p>15,00,000</p>}
                {isShown ?<input placeholder='15,00,000'  className='EditableField'/>:''}
              
              </Col>
            </Row>
            <Row>
              <Col span={12} xs={24} sm={12}>
                <h4>Quantity Required</h4>
              </Col>
              <Col span={12} xs={24} sm={12}>
              {isShown ? '' : <p>10</p>}
                {isShown ?<input placeholder='10'  className='EditableField'/>:''}
              
              </Col>
            </Row>
          </Card>
        </div>
      </>
    );
  }
}
export default LoanDetail;
