import React, { Component } from "react";
import MainApplicantFormKyc from './mainapplicantform'
import CoApplicantFormKyc from './coapplicant'
import GuarantorFormKyc from './guarantor'
import { Tabs } from 'antd';
const { TabPane } = Tabs;
class KycReport extends Component {
    render() {
      return (
        <>
        <div className='KycWrap'>
          <div className='InnerTabsWrap'>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Main Applicant" key="1">
                <MainApplicantFormKyc/>
              </TabPane>
              <TabPane tab="Co Applicant" key="2">
                <CoApplicantFormKyc/>
              </TabPane>
              <TabPane tab="Guarantor" key="3">
                <GuarantorFormKyc/>
              </TabPane>
            </Tabs>
          </div>
        </div>
        
        </>
      )
    }
}
export default KycReport;