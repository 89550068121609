import axios from "axios";
import { SSO_LOGIN_PRODUCTION, SSO_LOGIN_QA } from "../../utils/constants";
import { API, BASE_URL } from "../constants/Config";

// import HttpsProxyAgent from 'https-proxy-agent'
// const httpsAgent = new HttpsProxyAgent({host: "proxyhost", port: "proxyport", auth: "username:password"})

export const AxiosInstace = axios.create({
    baseURL:SSO_LOGIN_QA,
    // baseURL:SSO_LOGIN_PRODUCTION
});
AxiosInstace.interceptors.request.use(
  async config => {
      const token = localStorage.getItem('token') ? localStorage.getItem('token') : '';
      config.headers = {
          'Authorization': `${token}`,
          
      }
      return config;
  },
  error => {
    
      Promise.reject(error)
  });
export function userLogin(data) {
  return AxiosInstace.post(API.login, data);
}
export function logoutUserAPI(data) {
  return axios.get('https://federate.volvo.com/idp/startSLO.ping?TargetResource=https://www.creditone.vfsco.in/');
}
export function forgotPasswordAPI(data) {
  return AxiosInstace.post(API.forgetPassword, data);
}
export function resetPasswordAPI(data) {
  return AxiosInstace.post("/reset-password", data);
}
export function getCaseMastersAPI() {
  return AxiosInstace.get("/case-masters");
}
export function getNewCasesAPI(filterQuery='') {
 
  return AxiosInstace.get(`/new-cases?${filterQuery}`);
}
export function getOnGoingCasesAPI(filterQuery='') {
  return AxiosInstace.get(`/ongoing-cases?${filterQuery}`);
}
export function getCaseDetailsAPI(data) {
  return AxiosInstace.get(`/case/detail/${data.caseId}`);
}
export function addNewCaseAPI(data){
  return AxiosInstace.post(`/case/store`,data)
}
export function uploadOCRDocumensAPI(data){
  return AxiosInstace.post(`/ocr-document/upload`,data)
}
export function uploadNonOCRDocumensAPI(data){
  return AxiosInstace.post(`/non-ocr-document/upload`,data)
}
export function getCitiesAPI(data){
  return AxiosInstace.get(`${API.cities}/${data}`)
}
export function getStatesAPI(data){
  return AxiosInstace.get(`${API.states}`)
}
export function updateMainApplicantAPI(data){
  return AxiosInstace.post(`${API.updateMainApplicant}`,data)
}
export function updateCoApplicantAPI(data){
  return AxiosInstace.post(`${API.updateCoApplicant}`,data)
}
export function updateGuarantorAPI(data){
  return AxiosInstace.post(`${API.updateGuarantor}`,data)
}
export function addParticipantAPI(data){
  return AxiosInstace.post(`${API.addParticipant}`,data)
}
export function runOcrAPI(data){
  return AxiosInstace.post(`${API.runOcr}`,data)
}
export function deleteNonOcrDocumentAPI(data){
  return AxiosInstace.delete(`${API.deleteNonOcrDocument}/${data.id}`)
}
export function deleteOcrDocumentAPI(data){
  return AxiosInstace.delete(`${API.deleteOcrDocument}/${data.id}`)
}
export function getParticipantDetailAPI(data){
  return AxiosInstace.post(`${API.getParticipantDetail}`,data)
}
export function runKycCheckAPI(data){
  return AxiosInstace.post(`${API.runKycCheck}`,data)
}
export function getDashboardDataAPI(filterQuery=''){
  return AxiosInstace.get(`${API.dashboardAPI}?${filterQuery}`)
}
export function deleteCoapplicantAPI(coapplicantId){
  return AxiosInstace.delete(`${API.deleteCoApplicant}/${coapplicantId}`)
}
export function participantApproveRejectAPI(data){
  return AxiosInstace.post(`${API.participantApproveReject}`,data)
}
export function participantByCaseIdAPI(caseId){
  return AxiosInstace.get(`${API.participantByCaseId}/${caseId}`)
}

export function deleteGuarantorAPI(guarantorId){
  return AxiosInstace.delete(`${API.deleteGuarantor}/${guarantorId}`)
}
export function getPendingcaseAPI(filterQuery='') {
  return AxiosInstace.get(`${API.creditReviewPendingCases}?${filterQuery}`);
}

export function addRequestCreditReviewAPI(data){
  return AxiosInstace.post(`${API.creditRequest}`,data)
}

export function getRequestCommentAPI(data){
  return AxiosInstace.get(`${API.getCreditRequest}/${data}`)
}
export function addCommentByCreditAPI(data){
  return AxiosInstace.post(`${API.commentByCredit}`,data)
}

export function addJustificationCommentAPI(data){
  return AxiosInstace.post(`${API.customerJustification}`,data)
}
export function getJustificationCommentAPI(data){
  return AxiosInstace.get(`${API.getCustomerJustification}/${data}`)
}
export function addMemberOrDirectorAPI(data) {
  return AxiosInstace.post(`${API.addMemberOrDirector}`,data);
}
export function deleteMemberOrDirectorAPI(id) {
  return AxiosInstace.delete(`${API.deleteMemberOrDirector}/${id}`);
}
export function getMemberDirectorDetailAPI(id) {
  return AxiosInstace.get(`${API.getMemberDirector}/${id}`);
}
export function getMemberDirectorListAPI(data) {
  return AxiosInstace.post(`${API.getMemberDirectorList}`,data);
}

export function getSalesUserAPI() {
  return AxiosInstace.get(API.salesUsers);
}
export function getSalesOnGoingCasesAPI(filterQuery='') {
  return AxiosInstace.get(`${API.salesOnGoingCases}?${filterQuery}`);
}
export function getBureauReportListingAPI(data) {
  return AxiosInstace.post(`${API.getBureauReportListing}`,data);
}

export function getBureauReportMasterAPI(ind) {
  return AxiosInstace.get(`${API.getBureauReportMasters}?ind=${ind}`);
}
export function getCibilReportMasterAPI() {
  return AxiosInstace.get(`${API.getCibilReportMasters}`);
}
export function generateBureauReportAPI(data) {
  return AxiosInstace.post(`${API.generateBureauReport}`,data);
}
export function generateBureauReportCheckParamAPI(data) {
  return AxiosInstace.post(`${API.checkParams}`,data);
}

export function getBureauReportDetailAPI(bureauReportId) {
  return AxiosInstace.get(`${API.bureauReportDetail}/${bureauReportId}`);
}

export function getuserOcrResultAPI(data){
  return AxiosInstace.post(`${API.userBureauAddress}`,data)
}
export function getUserOcrResultAPI(data) {
  return AxiosInstace.post(`${API.getUserOcrResult}`,data);
}

export function getClosedCasesAPI(filterQuery='') {
  return AxiosInstace.get(`${API.closedCases}?${filterQuery}`);
}

export function getBureauReportHtmlAPI(id) {
  return AxiosInstace.get(`${API.getBureauReportHtml}/${id}`);
}
export function getCaseReallocationAPI(data) {
  return AxiosInstace.post(`${API.caseReallocation}`,data);
}

export function getSalesCreditUsersForLocationAPI(stateId,cityId) {
  return AxiosInstace.get(`${API.salesCreditUsersForLocation}`);
}
export function getBureauReportParametersAPI(id) {
  return AxiosInstace.get(`${API.getRequestBureauParameters}/${id}`);
}

export function getNotificationsAPI() {
  return AxiosInstace.get(API.getNotifications);
}

export function readNotificationAPI(id) {
  return AxiosInstace.get(`${API.readNotification}/${id}`);
}
export function getCaseApproveRejectAPI(data) {
  return AxiosInstace.post(`${API.caseApproveReject}`,data);
}

export function getBureauReportSummaryAPI(id) {
  return AxiosInstace.get(`${API.bureauSummaryReport}/${id}`);
}

export function getsystemRecommendationAPI(data) {
  return AxiosInstace.post(`${API.systemRecommendation}`,data);
}
export function generateCibilBureauReportAPI(data) {
  return AxiosInstace.post(`${API.generateCibilBureauReport}`,data);
}
export function getCibilCitiesAPI(data){
  return AxiosInstace.get(`${API.cibilCities}/${data}`)
}
export function getBureauReportExcelAPI(data){
  return AxiosInstace.get(`${API.getBureauReportExcel}/${data}`)
}
export function ssoLoginAPI(data){
  return AxiosInstace.post(`${API.ssoLogin}`,data)
}
export function getCaseDetailExcelAPI(data){
  return AxiosInstace.get(`${API.getCaseDetailExcel}/${data}`)
}
//API for fleet analysis
export function runFleetApi(data){
  return AxiosInstace.post(`${API.runFleetAnalysis}`,data)
}

export function loadFleetApi(data){
  return AxiosInstace.get(`${API.loadFleetApi}`,data)
}

export function loadFleetByCaseApi(id){ 
  return AxiosInstace.get(`${API.loadFleetByyCaseApi}/${id}`)
}

export function loadReRunFleetAnalysisApi(data){ 
  return AxiosInstace.post(`${API.reRunFleetAnalysisApi}`,data)
}

export function loadGetCasesApi(){ 
  return AxiosInstace.get(`${API.getCasesApi}`)
}


export function loadDownloadFleetReportCasesApi(id){ 
  return AxiosInstace.get(`${API.downloadFleetReportApi}/${id}`)
}

export function loadDownloadFleetTemplateCasesApi(){ 
  return AxiosInstace.get(`${API.downloadFleetTemplateReportApi}`)
}

export function loadDownloadFleetSummaryReportCasesApi(id){ 
  return AxiosInstace.get(`${API.downloadFleetSummaryReportApi}/${id}`)
}

//Api For Hunter Page

export function loadKycDoneCasesApi(){
  return AxiosInstace.get(`${API.loadKycDoneCases}`)
}
export function getHunterSummaryListAPI(){
  return AxiosInstace.get(`${API.hunterSummaryList}`)
}
export function loadRunHunterApi(id){ 
  return AxiosInstace.get(`${API.loadRunHunter}/${id}`)
}
export function fetchHunterCaseDetailsApi(id){ 
  return AxiosInstace.get(`${API.hunterCaseDetail}/${id}`)
}

export function downloadHunterSummaryApi(){ 
  return AxiosInstace.get(`${API.downloadHunterSummary}`)
}

//Api for Field Investigation By Yadu

export function getBureauReportGeneratedCasesApi(){
  return AxiosInstace.get(`${API.getBureauReportGeneratedCases}`)
}

//Api for Field Investigation By Shashikant

export function getAgent(){
  return AxiosInstace.get(`${API.getAgent}`)
}

export function addFieldInvestigation(data){
  return AxiosInstace.post(`${API.addFieldInvestigation}`, data)
}

export function addFieldInvestigationOTP(data){
  return AxiosInstace.post(`${API.addFieldInvestigationOTP}`, data)
}
export function addFieldInvestigationOTPVerify(data){
  return AxiosInstace.post(`${API.addFieldInvestigationOTPVerify}`, data)
}
export function getFieldInvestigationAll(agency_id = ''){
  return AxiosInstace.get(`${API.getFieldInvestigationAll}/${agency_id}`)
}
export function getFieldInvestigationCase(data) {
  if(data.attempt_id === "")
  {
    return AxiosInstace.get(`${API.getFieldInvestigationCase}/${data.id}`)
  }
  else
  {
    return AxiosInstace.get(`${API.getFieldInvestigationCase}/${data.id}/${data.attempt_id}`)
  }
}
export function submitFieldInvestigationResponse(data) {
  return AxiosInstace.post(`${API.submitFieldInvestigationResponse}`, data)
}
export function linkExpire(linkId){ 
  return AxiosInstace.get(`${API.linkExpireCheck}/${linkId}`)
}

export function getCaseForFI(fiId){
  return AxiosInstace.get(`${API.getCaseForFI}/${fiId}`)
}

//vaahan APIS
export function getVahanDetailsAPI(){
  return AxiosInstace.get(`${API.getVahanaDetails}`)
}
export function uploadStoreVaahanDetailsAPI(data){
  return AxiosInstace.post(`${API.uploadVaahanStoreDetails}`,data)
}
export function getVahanRequestDetailsAPI(data){
  return AxiosInstace.post(`${API.getVahanRequestDetail}`,data)
}
export function runVahanHypothecationAPI(data){
  return AxiosInstace.post(`${API.runVahanHypothecation}`,data)
}
export function getVahanDetailsByIdAPI(data){
  return AxiosInstace.get(`${API.getVahanaDetailsByID}/${data}`)
}