import {
  SET_PREVIEW_IMAGE,
    SET_PREVIEW_IMAGE_OR_PDF,
    SET_PREVIEW_LOADER,
    SET_PREVIEW_MODAL_VISIBILITY,
    SET_PREVIEW_PDF
  } from "../constants/ActionTypes";
  const INITIAL_STATE = {
    previewImage: false,
    previewVisible:false,
    previewPdf:false,
    previewLoader:false,
  };
  export default function auth(state = INITIAL_STATE, action) {
    switch (action.type) {
      case SET_PREVIEW_IMAGE:
        return {
          ...state,
          previewImage:action.data
        };
      case SET_PREVIEW_PDF:
        return{
          ...state,
          previewPdf:action.data
        }
        case SET_PREVIEW_LOADER:
          return{
            ...state,
            previewLoader:action.data
          }
      case SET_PREVIEW_MODAL_VISIBILITY:
        return {
          ...state,
          previewVisible:action.data
        };
      default:
        return state;
    }
  }
  