import React, { Component } from "react";
import { Card, Form, Row, Col, Select, Upload, Button } from "antd";
import uplaodIcon from "../../../../../assets/images/uploadfile.png";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;
class AddnewCoapplicant extends Component {
  render() {
    return (
      <>
        <div className="TextWrap">
          <h4>
            Please provide the reason why you would like to add a new
            co-applicant
          </h4>
          <Card>
            <p>
              Address on Aadhar and passport is not matching. please provide at
              least 1 supporting document where name is same as PAN and address
              is same as Aadhar.{" "}
            </p>
          </Card>
        </div>
        <Form {...layout} name="co-applicant">
          <Row gutter={32}>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item name="CoApplicantType" label="Co Applicant Type">
                <Select defaultValue="Individual">
                  <Option value="Individual1">Individual1</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item
                name="Relationship"
                label="Relationship with Applicant"
              >
                <Select defaultValue="Brother">
                  <Option value="Father">Father</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={24} md={24} xs={24} sm={24}>
              <h4>OCR Compatible Documents</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="PAN Card" name="pan">
                <Upload maxCount={1}>
                  <Button>
                    Upload <img src={uplaodIcon} alt="uplaodIcon" />
                  </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="Driving License" name="DL">
                <Upload maxCount={1}>
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="Aadhar Card" name="Aadhar">
                <Upload maxCount={1}>
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="Passport" name="Passport">
                <Upload maxCount={1}>
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="Voter ID" name="VoterID">
                <Upload maxCount={1}>
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={24}>
              <h4>OCR Compatible Documents</h4>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <Form.Item label="Customer Consent" name="CustomerConsent">
                <Upload maxCount={1}>
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
              <Form.Item label="Aadhar Card" name="AadharCard">
                <Upload maxCount={1}>
                  <Button>Upload </Button>
                </Upload>
              </Form.Item>
              <div className="AddField">
                <Button>Add</Button>
              </div>
            </Col>
            <Col span={12} md={12} xs={24} sm={24}>
              <table>
                <thead>
                  <tr>
                    <th>Document Name </th>
                    <th>View</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Doc 1</td>
                    <td>
                      <Button>View</Button>
                    </td>
                  </tr>
                  <tr>
                    <td>Doc 2</td>
                    <td>
                      <Button>View</Button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </Form>
      </>
    );
  }
}
export default AddnewCoapplicant;
