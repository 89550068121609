import React, { Component, useEffect, useState } from "react";
import { Row, Col, Button, Radio, Select, Modal, Tabs, Card } from "antd";
import { Link } from "react-router-dom";
import DetailDocform from "../detaildocform";
import LoanDetail from "../loandetail";
import { connect, useDispatch } from "react-redux";
import DealerInfo from "../dealerinfocard";

import MainApplicantFormKyc from "../kycreport/mainapplicantform";
import ReAllocate from "../../casedetail/modals/reallocate";
import SecondTab from "../secondtab/secondtab";
import Participant from "../../lognewcase/participant";
import { onCaseDetail } from "../../../redux/actions/CaseAction";
import { onResetParticipant } from "../../../redux/actions/ParticipantAction";
import { onChangeSelectedUser } from "../../../redux/actions/AuthAction";
import { onDeleteGuarantor } from "../../../redux/actions/GuarantorAction";
// import {
//   onCaseDetail,
//   onChangeSelectedUser,
//   onDeleteGuarantor,
//   onResetParticipant,
// } from "../../../redux/actions/AuthAction";
const { TabPane } = Tabs;

const Guarantorform = (props) => {
  const { caseDetail, selectedUser } = props;
  const [reallocate, setReallocate] = useState(false);
  const [addGuarantor, setAddGuarantor] = useState(false);
  const [guarantors, setGuarantors] = useState([]);
  const [deleteGuarantorModalVisible, setDeleteGuarantorModalVisible] =
    useState(false);
  const [guarantorDeletionId, setGuarantorDeletionId] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      caseDetail &&
      caseDetail.guarantors &&
      Array.isArray(caseDetail.guarantors)
    ) {
      setGuarantors(caseDetail.guarantors);
      if (
        Object.keys(selectedUser).length === 0 &&
        selectedUser.constructor === Object
      ) {
        if (caseDetail.guarantors.length > 0) {
          dispatch(onChangeSelectedUser(caseDetail.guarantors[0]));
        }
      }
    }
  }, [caseDetail, selectedUser, dispatch]);
  const openGuarantorModal = () => {
    dispatch(onResetParticipant(true));
    setAddGuarantor(true);
  };
  const handleCancel = () => {
    setAddGuarantor(false);
  };
  const closeModal = (newlyCreatedGuarantor) => {
    if (newlyCreatedGuarantor) {
      dispatch(
        onCaseDetail({ caseId: caseDetail.id }, (caseDetails) => {
          if (
            caseDetails &&
            caseDetails.guarantors &&
            Array.isArray(caseDetails.guarantors)
          ) {
            const newGuarantorDetails = caseDetails.guarantors.find(
              (guarantor) => {
                return (
                  guarantor.id.toString() ===
                  newlyCreatedGuarantor.id.toString()
                );
              }
            );
            dispatch(onChangeSelectedUser(newGuarantorDetails));
          }
        })
      );
    }
    setAddGuarantor(false);
  };
  const ReAllocate = (reallocate) => {
    setReallocate(reallocate);
  };

  const deleteGuarantorConfirmation = () => {
    setDeleteGuarantorModalVisible(false);
    deleteGuarantor(guarantorDeletionId);
  };
  const deleteGuarantorCancel = () => {
    setDeleteGuarantorModalVisible(false);
  };
  const deleteGuarantor = (guarantorId) => {
    dispatch(
      onDeleteGuarantor(guarantorId, () => {
        dispatch(onCaseDetail({ caseId: caseDetail.id }, () => {}));
      })
    );
  };
  return (
    <>
      <div className="HeaderPanelAction">
        <h3></h3>
        <div className="ButtonWrap">
          <Button onClick={openGuarantorModal}>Add New Guarantor</Button>
        </div>
      </div>
      <div className="TableWrap">
        <table>
          <thead>
            <tr>
              <th>Guarantor Type</th>
              <th>Name</th>
              <th>Status</th>
              <th>Stage</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {guarantors &&
            Array.isArray(guarantors) &&
            guarantors.length > 0 ? (
              guarantors.map((guarantor, index) => (
                <tr key={guarantor.id}>
                  <td>
                    <Radio.Group
                      name="radiogroup"
                      value={selectedUser.id}
                      onChange={(val) => {
                        dispatch(onChangeSelectedUser(guarantor));
                      }}
                    >
                      <Radio value={guarantor.id}>
                        {guarantor.category_name}
                      </Radio>
                    </Radio.Group>
                  </td>
                  <td>
                    {guarantor.first_name} {guarantor.last_name}
                  </td>
                  <td>{guarantor.status}</td>
                  <td>{guarantor.stage}</td>
                  <td>
                    {/*<Button>edit</Button>*/}
                    <Button
                      onClick={() => {
                        setGuarantorDeletionId(guarantor.id);
                        setDeleteGuarantorModalVisible(true);
                      }}
                    >
                      drop
                    </Button>
                    {/*<Button>RUN KYC</Button>*/}
                    <Button>approve</Button>
                    <Button>Ask for justification</Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <p className="NoCardBox">No Guarantors</p>
                </td>
              </tr>
            )}{" "}
          </tbody>
        </table>
      </div>

      {/* ReAllocate */}
      <Modal
        title="Document Uploaded Successfully"
        centered
        visible={reallocate}
        onOk={() => ReAllocate(false)}
        onCancel={() => ReAllocate(false)}
        footer={
          <Button key="back" className="Btndefault">
            <Link to="/OnGoingCases">View Details </Link>
          </Button>
        }
        width={600}
        className="KycCheck KYCform"
        style={{ top: 50 }}
      >
        <ReAllocate />
      </Modal>
      {caseDetail &&
        caseDetail.guarantors &&
        caseDetail.guarantors.length > 0 && <SecondTab />}
      <Modal
        title="Add New Guarantor"
        centered
        visible={addGuarantor}
        onCancel={handleCancel}
        footer={null}
        width={1000}
        className="AddCoapplicant"
        style={{ top: 50 }}
      >
        <Participant
          type="Guarantor"
          caseId={caseDetail.id}
          modalType="add_new_guarantor"
          closeModal={closeModal}
          closeUploadDocumentsModalHandler={closeModal}
        />
        {/*<AddnewCoapplicant />*/}
      </Modal>
      <Modal
        visible={deleteGuarantorModalVisible}
        onCancel={deleteGuarantorCancel}
        className="KycCheck"
        onOk={deleteGuarantorConfirmation}
      >
        <p className="dangerconfirmationtext">
          Are you sure you want to delete this Guarantor?
        </p>
      </Modal>
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(Guarantorform);
