import { Card, Col, Form, Row } from "antd";
import React, { useEffect, useState } from "react";
const layout = {
  labelCol: { span: 11, sm: 24, md: 11 },
  wrapperCol: { span: 13, sm: 24, md: 13 },
};
export default function EicherUserDetail(props) {
  const { caseDetail } = props;
  const [leadInfo, setLeadInfo] = useState({});
  // console.log("leadInfo:>>>>>>>>>>>>>>>>> ", leadInfo);
  useEffect(() => {
    if (caseDetail && caseDetail.leadInfo) {
      setLeadInfo(caseDetail.leadInfo);
    }
  }, [caseDetail]);
  return (
    <Form
      {...layout}
      name="basic"
      // initialValues={{ remember: true }}
      // onFinish={onFinish}
      // onFinishFailed={onFinishFailed}
    >
      <Card>
        <Row gutter={32}>
          <Col span={8} md={8} xs={24} sm={24}>
            <Form.Item label="Enquiry ID" name="fullname">
              {leadInfo.enquiry_id ? leadInfo.enquiry_id : "-"}
            </Form.Item>
            <Form.Item label="Erp ID" name="MobileNumber">
              {leadInfo.erp_id ? leadInfo.erp_id : "-"}
            </Form.Item>
            <Form.Item label="Contact Number" name="MobileNumber">
              {leadInfo.contact_number ? leadInfo.contact_number : "-"}
            </Form.Item>
            <Form.Item label="Address Line 1" name="MobileNumber">
              {leadInfo.address1 ? leadInfo.address1 : "-"}
            </Form.Item>

            <Form.Item label="PAN No." name="pannumber">
              {leadInfo.pan_number ? leadInfo.pan_number : "-"}
            </Form.Item>
            <Form.Item label="Date of Birth" name="dob">
              -
            </Form.Item>
            <Form.Item label="City" name="city">
              -
            </Form.Item>
            <Form.Item label="Material Group" name="mg">
              {leadInfo.material_group ? leadInfo.material_group : "-"}
            </Form.Item>
            <Form.Item label="Net Value" name="md">
              {caseDetail && caseDetail.finance_amount && <span>&#8377;</span>}
              {caseDetail && caseDetail.finance_amount
                ? `${caseDetail.finance_amount}`
                : "-"}
            </Form.Item>
          </Col>
          <Col span={8} md={8} xs={24} sm={24}>
            <Form.Item label="Customer's Full Name" name="addressline1">
              {leadInfo.full_name ? leadInfo.full_name : "-"}
            </Form.Item>
            <Form.Item label="Email" name="email">
              {leadInfo.email ? leadInfo.email : "-"}
            </Form.Item>
            <Form.Item label="Postal Code" name="addressline3">
              {leadInfo.pin_code ? leadInfo.pin_code : "-"}
            </Form.Item>
            <Form.Item label="Landmark" name="Landmark">
              -
            </Form.Item>

            <Form.Item label="State" name="state">
              -
            </Form.Item>
            <Form.Item label="Fert" name="addressline3">
              {leadInfo.fert ? leadInfo.fert : "-"}
            </Form.Item>
            <Form.Item label="GST No." name="addressline3">
              {leadInfo.gst_no ? leadInfo.gst_no : "-"}
            </Form.Item>
            <Form.Item label="Material Description" name="md">
              {leadInfo.material_description
                ? leadInfo.material_description
                : "-"}
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </Form>
  );
}
