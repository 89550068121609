import { Button, Col, Form, Input, Modal, Row, Select, Upload } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import uplaodIcon from "../../../assets/images/uploadfile.png";
import {
  APPLICANT_TYPE,
  CATEGORY,
  HEADINGS,
  MEMBER_TYPE,
  NON_OCR_DOCUMENT_LABEL,
  OCR_DOCUMENT_LABEL,
} from "../../../constants/constants";
import {
  CONSTANTS,
  MESSAGES,
  REQUIRED_MESSAGES,
} from "../../../constants/messages";
import { REGEX } from "../../../constants/regex";
import {
  firstCharacterOfEachWordUpperCase,
  isOcrDone,
} from "../../../helpers/utils";
import {
  onCaseDetail,
  onUpdateNewCreatedCase,
} from "../../../redux/actions/CaseAction";
import { onUserOcrResult } from "../../../redux/actions/OcrAction";
import { onParticipantDetail } from "../../../redux/actions/ParticipantAction";
import { InformationModal } from "../../modals/InformationModal";
import {
  beforeUploadNonRequiredOcrDocument,
  beforeUploadOcrAndRequiredNonOcrDocument,
  beforeUploadRequiredNonOcrDocument,
} from "../helpers/beforeUpload";
import {
  closePreviewModal,
  handlePreviewNonOcrDoc,
  handlePreviewOcrDoc,
} from "../helpers/handlePreview";
import { saveApplicant } from "../helpers/SaveApplicant";
import { updatedApplicantDocuments } from "../helpers/UpdateDefaultDocuments";
import {
  addNonRequiredNonOcrDoc,
  checkAtleastOnePOADocumentUploaded,
  checkValidation,
  deleteMemberDirectorNonOcrDocument,
  getNonOcrFields,
  getOcrFields,
} from "../helpers/utils";
// import Modal from "antd/lib/modal/Modal";
import AddIndividualForm from "../individual/AddIndividualForm";
import MemberDirectorListing from "../member-directors/MemberDirectorListing";
import CancelApplicantPopup from "../modals/CancelApplicantPopup";
import PreviewImageOrPDF from "../modals/PreviewImageOrPDF";
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const { Option } = Select;
const AddMainApplicant = (props) => {
  const { editMainApplicant, makeEditable } = props;
  const [form] = Form.useForm();
  const [selectedCategory, setSelectedCategory] = useState(undefined);
  const [selectedSubCategory, setSelectedSubCategory] = useState(undefined);
  const [subCategories, setSubCategories] = useState([]);
  const [ocrDocuments, setOcrDocuments] = useState([]);
  const [requiredNonOcrDocuments, setRequiredNonOcrDocuments] = useState([]);
  const [nonRequiredNonOcrDocuments, setNonRequiredNonOcrDocuments] = useState(
    []
  );
  const [applicantCancelPopup, setCancelPopup] = useState(false);
  const [isMemberOrDirectorModalOpen, setMemberOrDirectorModalOpen] =
    useState(false);
  const [isProprietorModalOpen, setProprietorModalOpen] = useState(false);
  const [directorMemberError, setDirectorMemberError] = useState(false);
  const [memberDirectorList, setMemberDirectorList] = useState([]);
  const [ocrResponse, setOcrResponse] = useState(null);
  const [poaDocumentError, setPOADocumentError] = useState(false);
  const [toAddMemberDirector, setToAddMemberDirector] = useState(false);
  // const [nonOcrMaxLengthError,setNonOcrMaxLengthError] = useState('');
  const history = useHistory();
  const [isOcrVisible, setOcrVisible] = useState(false);
  const caseMasters = useSelector((state) => {
    if (
      state &&
      state.auth &&
      state.auth.masterData &&
      state.auth.masterData.caseMasters
    ) {
      return state.auth.masterData.caseMasters;
    }
    return {};
  });
  const caseDetail = useSelector((state) => state.auth.caseDetail);
  const newlyCreatedCase = useSelector((state) => {
    if (state && state.auth && state.auth.newlyCreatedCase) {
      return state.auth.newlyCreatedCase;
    } else if (state && state.auth && state.auth.caseDetail) {
      return state.auth.newlyCreatedCase;
    }
    return {};
  });
  const [runOcr, setRunOcr] = useState(false);
  const [memberOrDirector, setMemberOrDirector] = useState(null);
  const [proprietor, setProprietor] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      onCaseDetail({ caseId: caseDetail.id }, (updatedCaseDetail) => {
        dispatch(onUpdateNewCreatedCase(updatedCaseDetail, () => {}));
      })
    );
    closePreviewModal(dispatch);
  }, [dispatch]);
  const getUpdatedApplicantDocuments = useCallback((applicant) => {
    const {
      uploadedOcrDocuments,
      uploadedRequiredNonOcrDocuments,
      uploadedNonOcrDocuments,
    } = updatedApplicantDocuments(applicant);
    setOcrDocuments((ocrDocuments) => {
      return { ...ocrDocuments, ...uploadedOcrDocuments };
    });
    setRequiredNonOcrDocuments((requiredDocs) => {
      return { ...requiredDocs, ...uploadedRequiredNonOcrDocuments };
    });
    setNonRequiredNonOcrDocuments(uploadedNonOcrDocuments);
  }, []);
  const updateMemberDirectorList = (main_applicant) => {
    if (
      main_applicant.member_director &&
      Array.isArray(main_applicant.member_director)
    ) {
      setMemberDirectorList(main_applicant.member_director);
    }
  };
  useEffect(() => {
    if (newlyCreatedCase && newlyCreatedCase.main_applicant) {
      let main_applicant = newlyCreatedCase.main_applicant;

      //
      getUpdatedApplicantDocuments(main_applicant);
      updateMemberDirectorList(main_applicant);
      form.setFieldsValue({
        gst_registration_no: main_applicant.gst_registration_no,
        aadhaar_no: main_applicant.aadhaar_no,
      });
    }
  }, [newlyCreatedCase, getUpdatedApplicantDocuments, form]);

  const changeSubCategories = useCallback(
    (applicationCategoryId) => {
      setPOADocumentError(false);
      setSelectedCategory(applicationCategoryId);
      form.setFieldsValue({
        application_sub_category: undefined,
      });
      if (
        caseMasters.subcategories &&
        Array.isArray(caseMasters.subcategories) &&
        caseMasters.subcategories.length > 0
      ) {
        const filteredSubCategories = caseMasters.subcategories.filter(
          (subCategory) => {
            return (
              subCategory.fk_category_id.toString() === applicationCategoryId
            );
          }
        );

        setSubCategories(filteredSubCategories);
      }
    },
    [caseMasters, form]
  );
  const setInitialValues = useCallback(() => {
    if (newlyCreatedCase) {
      const applicantCategory = newlyCreatedCase.fk_category_id
        ? newlyCreatedCase.fk_category_id.toString()
        : undefined;
      const applicantSubCategory = newlyCreatedCase.fk_subcategory_id
        ? newlyCreatedCase.fk_subcategory_id.toString()
        : undefined;
      form.setFieldsValue({
        applicant_category: applicantCategory,
        application_sub_category: applicantSubCategory,
      });

      setSelectedCategory(applicantCategory);
      setSelectedSubCategory(applicantSubCategory);
    }
  }, [newlyCreatedCase, form]);
  useEffect(() => {
    if (newlyCreatedCase.fk_category_id) {
      changeSubCategories(newlyCreatedCase.fk_category_id.toString());
    }
    setInitialValues();
  }, [setInitialValues, changeSubCategories, newlyCreatedCase.fk_category_id]);
  const onRemoveOcrDoc = (fileName) => {
    setOcrDocuments({
      ...ocrDocuments,
      [fileName]: [],
    });
  };
  const onRemoveNonOcrDoc = (fileName) => {
    setRequiredNonOcrDocuments({
      ...requiredNonOcrDocuments,
      [fileName]: [],
    });
  };
  const GetOcrDocumentField = ({ label, name, message }) => {
    if (label === OCR_DOCUMENT_LABEL.AADHAR_CARD) {
      return (
        <Col
          span={12}
          md={12}
          xs={24}
          sm={24}
          className="edit-participant-details"
        >
          <Row gutter={0}>
            <Col span={12} md={8} xs={8} sm={8}>
              <label class="custom-label" title="Document Name / Upload">
                {/*GST Number / Upload <span className="requiredAsterik"></span>*/}
                Aadhar Card Number / Upload <span></span>
              </label>
            </Col>
            <Col span={12} md={16} xs={16} sm={16}>
              <div className="applicant-upload-block">
                <Row gutter={2}>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      name={"aadhaar_no"}
                      label=""
                      rules={[
                        {
                          len:12,
                          message:MESSAGES.AADHAR_NUMBER_INVALID,
                          validateTrigger: true,

                        },
                        {
                          pattern:REGEX.NUMBER_ONLY_REGEX,
                          message:'Only numbers are allowed'
                        }
                      ]}
                      // rules={[
                      //   // {
                      //   //   required: true,
                      //   //   message: REQUIRED_MESSAGES.GST_NUMBER_REQUIRED,
                      //   // },
                      //   // {
                      //   //   len: 12,
                      //   //   // pattern:REGEX.AADHAR_CARD_NUMBER_REGEX,
                      //   //   message: MESSAGES.AADHAR_NUMBER_INVALID,
                      //   //   validateTrigger: true,
                      //   // },

                      // ]}
                    >
                      <Input
                        placeholder={"Aadhar Card Number"}
                        maxLength={12}
                        onChange={(e) => {
                          form.setFields([
                            {
                              name: "aadhaar_no",
                              errors: [],
                            },
                          ]);
                          if(!e.target.value){
                            form.setFields([
                              {
                                name: "aadhar_card",
                                errors: [],
                              },
                            ]);
                          }
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      // label={label}
                      name={name}
                      required={message ? true : false}
                      extra={
                        label === OCR_DOCUMENT_LABEL.AADHAR_CARD
                          ? MESSAGES.AADHARD_CARD_NOTE
                          : label === OCR_DOCUMENT_LABEL.VOTER_ID
                          ? MESSAGES.VOTER_ID_NOTE
                          : ""
                      }
                      // rules={[
                      //   {
                      //     required: message ? true : false,
                      //     message: message,
                      //   },
                      // ]}
                    >
                      <Upload
                        maxCount={1}
                        beforeUpload={(file, fileList, e) => {
                          if (selectedCategory === CATEGORY.INDIVIDUAL) {
                            if (name !== "pan_card") {
                              setPOADocumentError(false);
                            }
                          }
                          const res = beforeUploadOcrAndRequiredNonOcrDocument(
                            file,
                            fileList,
                            name,
                            ocrDocuments
                          );
                          if (res) {
                            setOcrDocuments(res);
                            return false;
                          } else {
                            return Upload.LIST_IGNORE;
                          }
                        }}
                        fileList={ocrDocuments[name]}
                        onPreview={(file, fileList) => {
                          handlePreviewOcrDoc(file, dispatch);
                        }}
                        onRemove={() => {
                          if (ocrDocuments[name][0] instanceof File) {
                            onRemoveOcrDoc(name);
                          }
                        }}
                        showUploadList={{
                          removeIcon:
                            ocrDocuments[name] &&
                            ocrDocuments[name][0] &&
                            !(ocrDocuments[name][0] instanceof File),
                        }}
                      >
                        <Button>
                          Upload {label}{" "}
                          <img src={uplaodIcon} alt="uplaodIcon" />
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      );
    }
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        <Form.Item
          label={label}
          name={name}
          required={message ? true : false}
          extra={
            label === OCR_DOCUMENT_LABEL.AADHAR_CARD
              ? MESSAGES.AADHARD_CARD_NOTE
              : label === OCR_DOCUMENT_LABEL.VOTER_ID
              ? MESSAGES.VOTER_ID_NOTE
              : ""
          }
          // rules={[
          //   {
          //     required: message ? true : false,
          //     message: message,
          //   },
          // ]}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file, fileList, e) => {
              if (selectedCategory === CATEGORY.INDIVIDUAL) {
                if (name !== "pan_card") {
                  setPOADocumentError(false);
                }
              }
              const res = beforeUploadOcrAndRequiredNonOcrDocument(
                file,
                fileList,
                name,
                ocrDocuments
              );
              if (res) {
                setOcrDocuments(res);
                return false;
              } else {
                return Upload.LIST_IGNORE;
              }
            }}
            fileList={ocrDocuments[name]}
            onPreview={(file, fileList) => {
              handlePreviewOcrDoc(file, dispatch);
            }}
            onRemove={() => {
              if (ocrDocuments[name][0] instanceof File) {
                onRemoveOcrDoc(name);
              }
            }}
            showUploadList={{
              removeIcon:
                ocrDocuments[name] &&
                ocrDocuments[name][0] &&
                !(ocrDocuments[name][0] instanceof File),
            }}
          >
            <Button>
              Upload {label} <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const GetNonOcrDocumentField = ({ label, name, message }) => {
    if (label === NON_OCR_DOCUMENT_LABEL.GST_REGISTRATION) {
      return (
        <Col
          span={12}
          md={12}
          xs={24}
          sm={24}
          className="edit-participant-details"
        >
          <Row gutter={0}>
            <Col span={12} md={8} xs={8} sm={8}>
              <label class="custom-label" title="Document Name / Upload">
                {/*GST Number / Upload <span className="requiredAsterik"></span>*/}
                GST Number / Upload <span></span>
              </label>
            </Col>
            <Col span={12} md={16} xs={16} sm={16}>
              <div className="applicant-upload-block">
                <Row gutter={2}>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      name={"gst_registration_no"}
                      label=""
                      rules={[
                        // {
                        //   required: true,
                        //   message: REQUIRED_MESSAGES.GST_NUMBER_REQUIRED,
                        // },
                        {
                          pattern: REGEX.GST_REGISTRATION_NO,
                          message: MESSAGES.GST_NUMBER_INVALID,
                          validateTrigger: true,
                        },
                      ]}
                    >
                      <Input placeholder={"GST Registration Number"} />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      // label={label}
                      name={name}
                      required={
                        label === NON_OCR_DOCUMENT_LABEL.GST_REGISTRATION ||
                        label === NON_OCR_DOCUMENT_LABEL.CUSTOMER_CONSENT
                      }
                      // rules={[
                      //   {
                      //     required: true,
                      //     message: message,
                      //   },
                      // ]}
                    >
                      <Upload
                        maxCount={1}
                        beforeUpload={(file, fileList) => {
                          const res = beforeUploadRequiredNonOcrDocument(
                            file,
                            fileList,
                            label,
                            requiredNonOcrDocuments
                          );
                          if (res) {
                            setRequiredNonOcrDocuments(res);
                            return false;
                          } else {
                            return Upload.LIST_IGNORE;
                          }
                        }}
                        fileList={requiredNonOcrDocuments[label]}
                        // onRemove={() => {
                        //
                        //   let filteredDocs = onRemoveOcrAndRequiredNonOcrDocument(
                        //     name,
                        //     requiredNonOcrDocuments
                        //   );
                        //   setRequiredNonOcrDocuments(filteredDocs);
                        // }}
                        onRemove={() => {
                          if (
                            requiredNonOcrDocuments[label][0] instanceof File
                          ) {
                            onRemoveNonOcrDoc(label);
                          }
                        }}
                        showUploadList={{
                          removeIcon:
                            requiredNonOcrDocuments[label] &&
                            requiredNonOcrDocuments[label][0] &&
                            !(
                              requiredNonOcrDocuments[label][0] instanceof File
                            ),
                        }}
                        onPreview={(file, fileList) => {
                          handlePreviewOcrDoc(file, dispatch);
                        }}
                      >
                        <Button className="btn-label-overclip">
                          Upload {label}
                          <img src={uplaodIcon} alt="uplaodIcon" />
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      );
    }
    return (
      <Col span={12} md={12} xs={24} sm={24}>
        {label === NON_OCR_DOCUMENT_LABEL.GST_REGISTRATION && (
          <Form.Item>
            <Input />
          </Form.Item>
        )}
        <Form.Item
          label={label}
          name={name}
          required={
            label === NON_OCR_DOCUMENT_LABEL.GST_REGISTRATION ||
            label === NON_OCR_DOCUMENT_LABEL.CUSTOMER_CONSENT
          }
          // rules={[
          //   {
          //     required: true,
          //     message: message,
          //   },
          // ]}
        >
          <Upload
            maxCount={1}
            beforeUpload={(file, fileList) => {
              const res = beforeUploadRequiredNonOcrDocument(
                file,
                fileList,
                label,
                requiredNonOcrDocuments
              );
              if (res) {
                setRequiredNonOcrDocuments(res);
                return false;
              } else {
                return Upload.LIST_IGNORE;
              }
            }}
            fileList={requiredNonOcrDocuments[label]}
            // onRemove={() => {
            //
            //   let filteredDocs = onRemoveOcrAndRequiredNonOcrDocument(
            //     name,
            //     requiredNonOcrDocuments
            //   );
            //   setRequiredNonOcrDocuments(filteredDocs);
            // }}
            onRemove={() => {
              if (requiredNonOcrDocuments[label][0] instanceof File) {
                onRemoveNonOcrDoc(label);
              }
            }}
            showUploadList={{
              removeIcon:
                requiredNonOcrDocuments[label] &&
                requiredNonOcrDocuments[label][0] &&
                !(requiredNonOcrDocuments[label][0] instanceof File),
            }}
            onPreview={(file, fileList) => {
              handlePreviewOcrDoc(file, dispatch);
            }}
          >
            <Button>
              Upload {label} <img src={uplaodIcon} alt="uplaodIcon" />
            </Button>
          </Upload>
        </Form.Item>
      </Col>
    );
  };
  const checkDealerOrMember = () => {
    if (
      newlyCreatedCase &&
      newlyCreatedCase.main_applicant &&
      newlyCreatedCase.main_applicant.member_director
    ) {
      return newlyCreatedCase.main_applicant.member_director.length >= 2;
    }
  };
  const onFinish = (values) => {
    let err = displayErrorMessage();
    console.log('err:==============@@@@@@@ ', err);

    if (err.length > 0 || poaDocumentError) {
      return;
    }
    // if (selectedCategory === "2" || selectedCategory === "3") {
    //   if (!checkDealerOrMember()) {
    //     setDirectorMemberError(
    //       `Please add minimum 2 ${
    //         selectedCategory === "2" ? "directors" : "members"
    //       }`
    //     );
    //     return;
    //   }
    // }
    let data = {
      fk_case_id: newlyCreatedCase.id,
      applicant_type: "Main Applicant",
      user_id: newlyCreatedCase.main_applicant.id,
      fk_category_id: selectedCategory,
      fk_subcategory_id: selectedSubCategory,
      ocr_documents: ocrDocuments,
      non_ocr_documents: nonRequiredNonOcrDocuments,
      non_ocr_required_documents: requiredNonOcrDocuments,
      gst_registration_no: values.gst_registration_no,
      // aadhaar_no:values.aadhaar_no,
      // aadhaar_unmasked:values.aadhaar_no,
    };
    if (
      !(
        newlyCreatedCase &&
        newlyCreatedCase.main_applicant &&
        newlyCreatedCase.main_applicant.aadhaar_no &&
        newlyCreatedCase.main_applicant.aadhaar_no === values.aadhaar_no
      )
    ) {
      data.aadhaar_unmasked = values.aadhaar_no;
    }
    saveApplicant(data, dispatch, makeEditable, runOcr, props.handleCancel);
  };
  const displayErrorMessage = () => {
    let aadhar_no = form.getFieldValue("aadhaar_no");
    let errors = checkValidation(
      selectedCategory,
      selectedSubCategory,
      ocrDocuments,
      requiredNonOcrDocuments,
      APPLICANT_TYPE.MAIN_APPLICANT,
      aadhar_no
    );

    if (selectedCategory === CATEGORY.INDIVIDUAL) {
      let isOnePOADocumentUploaded =
        checkAtleastOnePOADocumentUploaded(ocrDocuments);

      if (!isOnePOADocumentUploaded) {
        setPOADocumentError(
          REQUIRED_MESSAGES.PLEASE_UPLOAD_ATLEAST_ONE_POA_DOCUMENT
        );
      } else {
        setPOADocumentError(false);
      }
    }

    errors.forEach((error) => {
      form.setFields([
        { name: error.name, errors: error.message ? [[error.message]] : [] },
      ]);
    });
    return errors;
  };
  const OcrDocument = () => {
    return (
      <React.Fragment>
        {getOcrFields(
          selectedCategory,
          selectedSubCategory,
          APPLICANT_TYPE.MAIN_APPLICANT
        ).map((ocr_field) => {
          return (
            <GetOcrDocumentField
              label={ocr_field.label}
              name={ocr_field.name}
              message={ocr_field.message}
            />
          );
        })}
      </React.Fragment>
    );
  };
  const RequiredNonOcrDocument = () => {
    return (
      <React.Fragment>
        {getNonOcrFields(selectedCategory, selectedSubCategory).map(
          (non_ocr_field) => {
            return (
              <GetNonOcrDocumentField
                label={non_ocr_field.label}
                name={non_ocr_field.name}
                message={non_ocr_field.message}
              />
            );
          }
        )}
      </React.Fragment>
    );
  };
  const handleDocumentName = (e) => {
    const input = e.target.value;
    if (!REGEX.NAME_VALIDATION.test(input)) {
      form.setFieldsValue({
        // 'customer_name':(firstCharacterOfEachWordUpperCase(input.substring(0, input.length - 1))).replace( /[^a-zA-Z0-9 ]/gm, '')
        DocName: firstCharacterOfEachWordUpperCase(
          input.substring(0, input.length - 1)
        )
          .replace(/\s\s+/g, " ")
          .replace(/^\s+/g, ""),
      });
    } else {
      form.setFieldsValue({
        DocName: firstCharacterOfEachWordUpperCase(input)
          .replace(/\s\s+/g, " ")
          .replace(/^\s+/g, ""),
      });
    }
  };
  const AddNonRequiredNonOcrDoc = () => {
    const setErrors = (name, errors) => {
      form.setFields([
        {
          name,
          errors,
        },
      ]);
    };
    const addButtonHandler = () => {
      setErrors("DocName", []);
      // setNonOcrMaxLengthError('');
      let documentName = form.getFieldValue("DocName");
      const uploadedDocument = form.getFieldValue("non_ocr_doc_pic");
      const result = addNonRequiredNonOcrDoc(
        documentName
          ? firstCharacterOfEachWordUpperCase(documentName.trim())
          : documentName,
        uploadedDocument,
        nonRequiredNonOcrDocuments
      );
      // if (!documentName) {
      //   documentName = "Other";
      // }
      if (result) {
        setErrors(result.name, result.errors);
        return;
      }
      setNonRequiredNonOcrDocuments(
        nonRequiredNonOcrDocuments.concat({
          name: documentName
            ? firstCharacterOfEachWordUpperCase(documentName.trim())
            : documentName,
          doc: uploadedDocument,
        })
      );
      form.resetFields(["DocName", "non_ocr_doc_pic"]);
    };
    return (
      <Row gutter={32}>
        <Col
          span={12}
          md={12}
          xs={24}
          sm={24}
          className="edit-participant-details"
        >
          <Row gutter={0}>
            <Col span={12} md={8} xs={8} sm={8}>
              <label class="custom-label" title="Document Name / Upload">
                Document Name / Upload
              </label>
            </Col>
            <Col span={12} md={16} xs={16} sm={16}>
              <div className="applicant-upload-block">
                <Row gutter={2}>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item name="DocName" label="">
                      <Input
                        onFocus={() => {
                          setErrors("DocName", []);
                        }}
                        // onChange={handleDocumentName}
                        name="DocName"
                        maxLength={100}
                        placeholder="Document Name"
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} md={12} xs={12} sm={12}>
                    <Form.Item
                      label=""
                      name="non_ocr_doc_pic"
                      onFocus={() => {
                        setErrors("non_ocr_doc_pic", []);
                      }}
                    >
                      <Upload
                        maxCount={1}
                        beforeUpload={(file, fileList) => {
                          if (
                            beforeUploadNonRequiredOcrDocument(file, fileList)
                          ) {
                            return Upload.LIST_IGNORE;
                          } else {
                            return false;
                          }
                        }}
                      >
                        <Button>
                          Upload <img src={uplaodIcon} alt="uplaodIcon" />
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                  <Col span={12} md={24} xs={24} sm={24}>
                    <div className="AddField text-right mb-2">
                      <Button htmlType="button" onClick={addButtonHandler}>
                        Add
                      </Button>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={12} md={12} xs={24} sm={24}>
          {nonRequiredNonOcrDocuments.length > 0 && (
            <table>
              <thead>
                <tr>
                  <th>Document Name </th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody>
                {nonRequiredNonOcrDocuments &&
                  nonRequiredNonOcrDocuments.map((document, index) => {
                    return (
                      <tr key={document.name}>
                        <td>{document.name}</td>
                        <td>
                          <Button
                            onClick={() => {
                              handlePreviewNonOcrDoc(document, dispatch);
                            }}
                          >
                            View
                          </Button>
                          <Button
                            onClick={() => {
                              const filteredDocs = (docs) => {
                                setNonRequiredNonOcrDocuments(docs);
                              };
                              deleteMemberDirectorNonOcrDocument(
                                document,
                                nonRequiredNonOcrDocuments,
                                index,
                                dispatch,
                                filteredDocs,
                                newlyCreatedCase.id
                              );
                              // const result = deleteNonOcrDocument(
                              //   document,
                              //   nonRequiredNonOcrDocuments,
                              //   index,
                              //   newlyCreatedCase.id,
                              //   dispatch
                              // );
                              // if (
                              //   result &&
                              //   result.status &&
                              //   result.status === "1"
                              // ) {
                              //   setNonRequiredNonOcrDocuments(result.docs);
                              // }
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          )}
        </Col>
      </Row>
    );
  };
  const GetDirectorMemberButton = () => {
    setMemberOrDirector(false);
    const openMemberDirectorModal = () => {
      if (newlyCreatedCase && newlyCreatedCase.main_applicant) {
        if (
          newlyCreatedCase.main_applicant &&
          newlyCreatedCase.main_applicant.ocr_documents &&
          Array.isArray(newlyCreatedCase.main_applicant.ocr_documents)
        ) {
          if (newlyCreatedCase.main_applicant.ocr_documents.length > 0) {
            if (
              newlyCreatedCase.main_applicant.member_director &&
              newlyCreatedCase.main_applicant.member_director.length < 5
            ) {
              setMemberOrDirectorModalOpen(true);
              setDirectorMemberError("");
            } else {
              setMemberOrDirectorModalOpen(false);
              setDirectorMemberError(
                selectedCategory === "2"
                  ? MESSAGES.MAX_DIRECTOR_ERROR
                  : MESSAGES.MAX_MEMBER_ERROR
              );
            }
          } else {
            setToAddMemberDirector(
              `Please Save ${newlyCreatedCase.category_name} Documents Firstly`
            );
          }
        }
      }
    };

    return (
      <div className="HeaderPanelAction">
        <h3></h3>

        <div>
          <Button className="AddField " onClick={openMemberDirectorModal}>
            Add {selectedCategory === "2" ? "Director" : "Member"} Details
          </Button>
        </div>
      </div>
    );
  };
  const GetProprietorButton = () => {
    const openProprietorModal = () => {
      if (newlyCreatedCase && newlyCreatedCase.main_applicant) {
        if (
          newlyCreatedCase.main_applicant &&
          newlyCreatedCase.main_applicant.ocr_documents &&
          Array.isArray(newlyCreatedCase.main_applicant.ocr_documents)
        ) {
          if (newlyCreatedCase.main_applicant.ocr_documents.length > 0) {
            setProprietorModalOpen(true);
          } else {
            setToAddMemberDirector(
              `Please Save Main Applicant Documents Firstly`
            );
          }
        }
      }
    };
    return (
      <div className="HeaderPanelAction">
        <h3></h3>
        <div>
          <Button className="AddField " onClick={openProprietorModal}>
            Add Proprietor Details
          </Button>
        </div>
      </div>
    );
  };
  const cancelApplicantCreation = () => {
    setPOADocumentError(false);
    if (props.closeModal) {
      props.handleCancel();
    } else {
      setCancelPopup(true);
    }
  };
  const closeDirectorMemberModal = () => {
    setMemberOrDirectorModalOpen(false);
  };
  const closeProprietorModal = () => {
    setProprietorModalOpen(false);
  };
  const onAddEditProprietor = (proprietor) => {
    const onSuccess = (participantDetail) => {
      updateMemberDirectorList(participantDetail);
      setProprietor(proprietor);
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    };
    let data = {
      applicant_type: APPLICANT_TYPE.MAIN_APPLICANT,
      applicant_coapplicant_guarantor_id: newlyCreatedCase.main_applicant.id,
    };

    dispatch(onParticipantDetail(data, onSuccess));
  };
  const onAddNewMemberDirector = (member_director) => {
    const onSuccess = (participantDetail) => {
      updateMemberDirectorList(participantDetail);
      setMemberOrDirector(member_director);
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    };
    let data = {
      applicant_type: APPLICANT_TYPE.MAIN_APPLICANT,
      applicant_coapplicant_guarantor_id: newlyCreatedCase.main_applicant.id,
    };

    dispatch(onParticipantDetail(data, onSuccess));
    //
    // setMemberOrDirector(member_director)
    // updateMemberDirectorList(newlyCreatedCase.main_applicant)
  };
  const fetchOcrResult = () => {
    let data = {
      applicant_type: APPLICANT_TYPE.MAIN_APPLICANT,
      applicant_coapplicant_guarantor_id: newlyCreatedCase.main_applicant.id,
    };
    dispatch(
      onUserOcrResult(data, (ocrData) => {
        setOcrResponse(ocrData);
        setOcrVisible(true);
      })
    );
  };
  const handleCloseOcrModal = () => {
    setOcrResponse(null);
    setOcrVisible(false);
  };
  return (
    <React.Fragment>
      {(selectedCategory === "2" || selectedCategory === "3") && (
        // newlyCreatedCase &&
        // newlyCreatedCase.main_applicant &&
        // newlyCreatedCase.main_applicant.ocr_documents
        // &&Array.isArray(newlyCreatedCase.main_applicant.ocr_documents)
        // && newlyCreatedCase.main_applicant.ocr_documents.length >0 &&
        <React.Fragment>
          <GetDirectorMemberButton />
          {directorMemberError && (
            <p className="errorMessage">{directorMemberError}</p>
          )}
          {/*<MemberDirectorListing
            members={newlyCreatedCase.main_applicant.member_director}
            title={selectedCategory === "2" ? "Director" : "Member"}
          />*/}
        </React.Fragment>
      )}
      {selectedCategory &&
        selectedCategory.toString() === CATEGORY.PROPRIETOR &&
        memberDirectorList.length === 0 && <GetProprietorButton />}
      <Form {...layout} name="mainapplicant" form={form} onFinish={onFinish}>
        <Row gutter={32}>
          {props.upload_document && (
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item
                label="Main Applicant Type"
                name="applicant_category"
                rules={[
                  {
                    required: true,
                    message: REQUIRED_MESSAGES.APPLICATION_CATEGORY_REQUIRED,
                  },
                ]}
                className="TopTabBox"
              >
                <Select
                  placeholder="Please Select Main Applicant Type"
                  onChange={changeSubCategories}
                  disabled={newlyCreatedCase.fk_category_id}
                >
                  {caseMasters &&
                    caseMasters.categories &&
                    Array.isArray(caseMasters.categories) &&
                    caseMasters.categories.map((category) => (
                      <Option
                        value={category.id.toString()}
                        key={category.id.toString()}
                      >
                        {category.category_name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          )}

          {props.upload_document && (
            <Col span={12} md={12} sm={12} xs={24}>
              <Form.Item
                label="Sub Category"
                name="application_sub_category"
                rules={[
                  {
                    required: subCategories.length > 0,
                    message:
                      REQUIRED_MESSAGES.APPLICATION_SUB_CATEGORY_REQUIRED,
                  },
                ]}
                className="TopTabBox"
              >
                <Select
                  placeholder={"Please select sub category"}
                  // disabled={subCategories.length === 0}
                  onChange={(val) => {
                    setRequiredNonOcrDocuments([]);
                    setSelectedSubCategory(val);
                  }}
                  disabled={
                    newlyCreatedCase.fk_category_id ||
                    subCategories.length === 0
                  }
                >
                  {subCategories.map((subCategory) => (
                    <Option
                      value={subCategory.id.toString()}
                      key={subCategory.id.toString()}
                    >
                      {subCategory.subcategory_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          )}
          <Col span={24} md={24} xs={24} sm={24}>
            <h4 className="rightHeading">
              Main Applicant Stage:{" "}
              {newlyCreatedCase &&
                newlyCreatedCase.main_applicant &&
                (newlyCreatedCase.main_applicant.stage
                  ? newlyCreatedCase.main_applicant.stage
                  : "Documents Required")}
              {/*<span>
                Note : Run OCR to fetch details from OCR documents{" "}
                {selectedCategory &&
                  selectedCategory != "1" &&
                  "and GST number"}{" "}
                {!selectedCategory && "and GST number"}
                </span>*/}
            </h4>
          </Col>
          <Col span={24} md={24} xs={24} sm={24}>
            <h4>
              {HEADINGS.OCR_DOCUMENT_HEADING}
              <span className="SubHeading">
                {HEADINGS.OCR_DOCUMENT_SUB_HEADING}
              </span>
            </h4>
          </Col>
          <OcrDocument />

          {/*<AddOcrDocument />*/}
        </Row>
        {selectedCategory == CATEGORY.INDIVIDUAL && (
          <div
            className={poaDocumentError ? "smallErrorMessage" : "noteMessage"}
          >
            Note: {REQUIRED_MESSAGES.PLEASE_UPLOAD_ATLEAST_ONE_POA_DOCUMENT}
          </div>
        )}
        <Row gutter={32}>
          <Col span={24}>
            <h4>
              {HEADINGS.NON_OCR_DOCUMENT_HEADING}
              <span className="SubHeading">
                {HEADINGS.NON_OCR_DOCUMENT_SUB_HEADING}
              </span>
            </h4>
          </Col>
          <RequiredNonOcrDocument />
        </Row>
        <AddNonRequiredNonOcrDoc />
        {(selectedCategory === "2" ||
          selectedCategory === "3" ||
          selectedCategory === "4") && (
          <React.Fragment>
            {/*<GetDirectorMemberButton />*/}

            <MemberDirectorListing
              members={memberDirectorList}
              applicant={newlyCreatedCase.main_applicant}
              applicant_type={APPLICANT_TYPE.MAIN_APPLICANT}
              title={
                selectedCategory === CATEGORY.CORPORATE
                  ? MEMBER_TYPE.DIRECTOR
                  : selectedCategory === CATEGORY.HUF
                  ? MEMBER_TYPE.MEMBER
                  : MEMBER_TYPE.PROPRIETOR
              }
              onUpdateDirectorMember={onAddNewMemberDirector}
              onAddNewMemberDirector={onAddNewMemberDirector}
            />
          </React.Fragment>
        )}

        {/*<AddNonOcrDocument />*/}
        <div className="AboveButtonNote">
          {" "}
          <span>
            Note : Run OCR to fetch details from OCR documents{" "}
            {selectedCategory && selectedCategory != "1" && "and GST number"}{" "}
            {!selectedCategory && "and GST number"}
          </span>
        </div>
        <div className="MainBtnWrap">
          <Button className="plane" onClick={cancelApplicantCreation}>
            Cancel
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            // onClick={displayErrorMessage}
            onClick={() => {
              let errors = displayErrorMessage();
              form.setFieldsValue({
                DocName: "",
                non_ocr_doc_pic: undefined,
              });
              setRunOcr(false);
            }}
          >
            Save
          </Button>
          <Button
            htmlType="submit"
            onClick={() => {
              let errors = displayErrorMessage();
              form.setFieldsValue({
                DocName: "",
                non_ocr_doc_pic: undefined,
              });
              if (errors.length === 0) {
                setRunOcr(true);
              }
            }}
          >
            {newlyCreatedCase &&
            newlyCreatedCase.main_applicant &&
            newlyCreatedCase.main_applicant.stage &&
            isOcrDone(newlyCreatedCase.main_applicant.stage)
              ? "Save And Re-Run OCR"
              : "Save and Run OCR"}
          </Button>
          {/*<Button onClick={fetchOcrResult}>View OCR Result</Button>*/}
        </div>
      </Form>
      <PreviewImageOrPDF />{" "}
      {newlyCreatedCase && newlyCreatedCase.main_applicant && (
        <React.Fragment>
          <Modal
            title={
              selectedCategory === CATEGORY.CORPORATE
                ? MEMBER_TYPE.DIRECTOR
                : MEMBER_TYPE.MEMBER
            }
            centered
            visible={isMemberOrDirectorModalOpen}
            destroyOnClose={true}
            // onOk={() => setModal2Visible(false)}
            onCancel={() => setMemberOrDirectorModalOpen(false)}
            footer={null}
            width={1000}
            className="ModalFormWrap AddCoapplicant DirectModalStyle"
            style={{ top: 50 }}
          >
            <AddIndividualForm
              data={{
                applicant_type: APPLICANT_TYPE.MAIN_APPLICANT,
                member_type:
                  selectedCategory === CATEGORY.CORPORATE
                    ? MEMBER_TYPE.DIRECTOR
                    : MEMBER_TYPE.MEMBER,
                applicant_id: newlyCreatedCase.main_applicant.id,
              }}
              closeDirectorMemberModal={closeDirectorMemberModal}
              memberOrDirector={memberOrDirector}
              onAddNewMemberDirector={onAddNewMemberDirector}
            />
          </Modal>
          <Modal
            title={"Proprietor Details"}
            centered
            visible={isProprietorModalOpen}
            destroyOnClose={true}
            // onOk={() => setModal2Visible(false)}
            onCancel={() => setProprietorModalOpen(false)}
            footer={null}
            width={1000}
            className="ModalFormWrap AddCoapplicant"
            style={{ top: 50 }}
          >
            <AddIndividualForm
              data={{
                applicant_type: APPLICANT_TYPE.MAIN_APPLICANT,
                member_type: MEMBER_TYPE.PROPRIETOR,
                applicant_id: newlyCreatedCase.main_applicant.id,
              }}
              closeDirectorMemberModal={closeProprietorModal}
              isProprietor={true}
              memberOrDirector={proprietor}
              onAddNewMemberDirector={onAddEditProprietor}
            />
          </Modal>
        </React.Fragment>
      )}
      {/*<Modal
        title="Document Uploaded Successfully"
        centered
        visible={ocrResponse}
        onOk={() => closeRunOcrModal(dispatch)}
        onCancel={() => closeRunOcrModal(dispatch)}
        footer={null}
        width={600}
        className="KycCheck"
        style={{ top: 50 }}
      >
        <RunOcr  />
      </Modal>*/}
      <CancelApplicantPopup
        isVisible={applicantCancelPopup}
        title={CONSTANTS.CANCEL_APPLICANT}
        onOk={() => {
          setCancelPopup(false);
          if (props.upload_document) {
            props.onCloseModal();
          } else {
            history.push("/");
          }
        }}
        onCancel={() => {
          setCancelPopup(false);
        }}
      />
      <InformationModal
        isVisible={toAddMemberDirector}
        informationContent={toAddMemberDirector}
        // title={toAddMemberDirector}
        handleOk={() => {
          setToAddMemberDirector(false);
        }}
      />
      {/*<Modal
        title={toAddMemberDirector}
        centered
        visible={toAddMemberDirector}
        destroyOnClose={true}
        // onOk={() => setModal2Visible(false)}
        onCancel={() => {
          setToAddMemberDirector(false);
        }}
        footer={[
          <Button
            onClick={() => {
              setToAddMemberDirector(false);
            }}
          >
            OK
          </Button>,
        ]}
        width={1000}
        className="ModalFormWrap AddCoapplicant"
        style={{ top: 50 }}
      >
      </Modal>*/}
      {/*<OCRDetailModal
        isVisible={isOcrVisible}
        ocrResponse={ocrResponse}
        header={APPLICANT_TYPE.MAIN_APPLICANT}
        handleCloseModal={handleCloseOcrModal}
      />*/}
    </React.Fragment>
  );
};

export default AddMainApplicant;
