import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  onChangeSelectedBureauReportView,
} from "../../../redux/actions/BureauReportAction";
import ReportRunSummary from "./report-run-summary";
import ReportTab from "./reports-tab";
const BureauReport = () => {
  return (
    <React.Fragment>
      <div className="BureauReportWrap">
        <div className="InnerTabsWrap">
          <ReportRunSummary
          />
          <ReportTab
              id="report_detail_tab"
            />
        </div>
      </div>
    </React.Fragment>
  );
};
export default BureauReport;
