import { 
  DOWNLOAD_FLEET_REPORT,
  DOWNLOAD_FLEET_SUMMARY_REPORT,
  DOWNLOAD_FLEET_TEMPLATE,
  FLEET_ANALYSIS,
  FLEET_ANALYSIS_LIST,
  FLEET_ANALYSIS_LIST_BY_CASE,
  FLEET_ANALYSIS_RE_RUN,
} from "../constants/ActionTypes";

/**
 * @method onLogin
 * @description login user
 * @param {* case_id,rc_excel } onSuccess
 * @returns
 */
export const onFleetAnalysisRun = (data, onSuccess) => ({
  type: FLEET_ANALYSIS,
  payload: data,
  onSuccess,
});
export const onFleetAnalysisLoad = (onSuccess) => ({
  type: FLEET_ANALYSIS_LIST,
  payload: [],
  onSuccess,
});

export const onLoadFleetAnalysisByCase = (data, onSuccess) => ({
  type: FLEET_ANALYSIS_LIST_BY_CASE,
  payload: data,
  onSuccess,
});


export const onReRunFleetAnalysis = (data,onSuccess) => ({
  type: FLEET_ANALYSIS_RE_RUN,
  payload: data,
  onSuccess,
});

export const onDownloadFleetReport = (case_id,onSuccess) => ({
  type: DOWNLOAD_FLEET_REPORT,
  payload: case_id,
  onSuccess,
});

export const onDownloadFleetSummaryReport = (case_id,onSuccess) => ({
  type: DOWNLOAD_FLEET_SUMMARY_REPORT,
  payload: case_id,
  onSuccess,
});

export const onDownloadTemplate = (onSuccess) => ({
  type: DOWNLOAD_FLEET_TEMPLATE,
  payload: [],
  onSuccess,
});







 
 