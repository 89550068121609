import React, { Component, useEffect, useState } from "react";
import { Button, Radio, Select, Modal, Tabs, Table } from "antd";
import { Link } from "react-router-dom";

import AddnewCoapplicant from "../../casedetail/modals/addcoapplicant";
import SecondTab from "../secondtab/secondtab";
import { connect, useDispatch } from "react-redux";
import Participant from "../../lognewcase/participant";
import { onCaseDetail } from "../../../redux/actions/CaseAction";
import { onChangeSelectedUser } from "../../../redux/actions/AuthAction";
import { onDeleteCoapplicant } from "../../../redux/actions/CoApplicantAction";
import { onResetParticipant } from "../../../redux/actions/ParticipantAction";
import { isSalesUser, loggedInUserData } from "../../../helpers/utils";
// import {
//   onCaseDetail,
//   onChangeSelectedUser,
//   onDeleteCoapplicant,
//   onResetParticipant,
// } from "../../../redux/actions/AuthAction";
const Coapplicantform = (props) => {
  const { caseDetail, selectedUser } = props;
  const [addcoapplicant, setAddCoapplicant] = useState(false);
  const [co_applicants, setCoapplicants] = useState([]);
  const [deleteCoapplicantModalVisible,setDeleteCoapplicantModalVisible] = useState(false);
  const [coapplicantDeletionId,setCoapplicantDeletionId] = useState(false);
  const dispatch = useDispatch();
  const userData = loggedInUserData();
  useEffect(() => {
    
    if (
      caseDetail &&
      caseDetail.co_applicants &&
      Array.isArray(caseDetail.co_applicants)
    ) {
      setCoapplicants(caseDetail.co_applicants);
      if(selectedUser && Object.keys(selectedUser).length ===0 && selectedUser.constructor === Object){
        if(caseDetail.co_applicants.length>0){
          
          dispatch(onChangeSelectedUser(caseDetail.co_applicants[0]))
        }
      }
    }
  }, [caseDetail,selectedUser,dispatch]);
  const handleCancel = () => {
    setAddCoapplicant(false);
  };
  const closeModal = (newlyCreatedCoApplicant) => {
    if(newlyCreatedCoApplicant){
      dispatch(
        onCaseDetail({ caseId: caseDetail.id }, (caseDetails) => {
          if (
            caseDetails &&
            caseDetails.co_applicants &&
            Array.isArray(caseDetails.co_applicants)
          ) {
            const newCoApplicantAllDetails = caseDetails.co_applicants.find(
              (co_applicant) => {
                return (
                  co_applicant.id.toString() ===
                  newlyCreatedCoApplicant.id.toString()
                );
              }
            );
            dispatch(onChangeSelectedUser(newCoApplicantAllDetails));
          }
        })
      );
    }
    setAddCoapplicant(false);
  };
  const deleteCoapplicantConfirmation = ()=>{
    setDeleteCoapplicantModalVisible(false);
    deleteCoapplicant(coapplicantDeletionId)
  }
  const deleteCoapplicantCancel = ()=>{
    setDeleteCoapplicantModalVisible(false);
  }
  const deleteCoapplicant = (coappplicantId) => {
    dispatch(
      onDeleteCoapplicant(coappplicantId, () => {
        dispatch(onCaseDetail({ caseId: caseDetail.id }, () => {}));
      })
    );
  };
  const openCoapplicantModal = () => {
    dispatch(onResetParticipant(true));
    setAddCoapplicant(true);
  };
  return (
    <>
      <div className="HeaderPanelAction">
        <h3></h3>
        <div className="ButtonWrap">
          <Button onClick={openCoapplicantModal}>Add New Co-applicant</Button>
        </div>
      </div>
      <div className="TableWrap">
        <table>
          <thead>
            <tr>
              <th>Co Applicant Type</th>
              <th>Name</th>
              <th>Relation with Applicant</th>
              <th>Status</th>
              <th>Stage</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {co_applicants &&
            Array.isArray(co_applicants) &&
            co_applicants.length > 0 ? (
              co_applicants.map((co_applicant, index) => (
                <tr key={co_applicant.id}>
                  <td>
                    <Radio.Group
                      name="radiogroup"
                      value={selectedUser.id}
                      onChange={(val) => {
                        
                        dispatch(onChangeSelectedUser(co_applicant));
                      }}
                    >
                      <Radio value={co_applicant.id}>
                        {co_applicant.category_name}
                      </Radio>
                    </Radio.Group>
                  </td>
                  <td>
                    {co_applicant.first_name} {co_applicant.last_name}
                  </td>
                  <td>
                    {co_applicant.coapplicant_applicant_relation}
                    {co_applicant.coapplicant_applicant_relation === "Other" &&
                      `(${co_applicant.other_relation})`}
                  </td>
                  <td>{co_applicant.status}</td>
                  <td>{co_applicant.stage}</td>
                  <td>
                    {/*<Button>edit</Button>*/}
                    {userData && isSalesUser(userData) && <Button
                      onClick={()=>{
                        setCoapplicantDeletionId(co_applicant.id);
                        setDeleteCoapplicantModalVisible(true);
                      }}
                    >
                      drop
                    </Button>}
                    <Button>RUN KYC</Button>
                    <Button>approve</Button>
                    <Button>Ask for justification</Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6">
                  <p className="NoCardBox">No Co-Applicants</p>
                </td>
              </tr>
            )}{" "}
          </tbody>
        </table>
      </div>
      {caseDetail &&
        caseDetail.co_applicants &&
        caseDetail.co_applicants.length > 0 && <SecondTab />}
      {/* Add New Co-Applicant */}
      <Modal
        title="Add New Co-Applicant"
        centered
        visible={addcoapplicant}
        onCancel={handleCancel}
        footer={null}
        // footer={[
        //   <Button onClick={this.handleCancel}>Cancel</Button>,
        //   <Button className="filled" onClick={this.handleCancel}>
        //     Save
        //   </Button>,
        //   <Button className="filled" onClick={this.handleCancel}>
        //     Save and Clear
        //   </Button>,
        // ]}
        width={1000}
        className="AddCoapplicant"
        style={{ top: 50 }}
      >
        <Participant
          type="Co Applicant"
          caseId={caseDetail.id}
          modalType="add_new_coapplicant"
          closeModal={closeModal}
          closeUploadDocumentsModalHandler={closeModal}
        />
        {/*<AddnewCoapplicant />*/}
      </Modal>
      <Modal
        visible={deleteCoapplicantModalVisible}
        onCancel={deleteCoapplicantCancel}
        className="KycCheck"
        onOk={deleteCoapplicantConfirmation}
      >
        <p className="dangerconfirmationtext">
          Are you sure you want to delete this Co-applicant?
        </p>
      </Modal>
    </>
  );
};
const mapStateToProps = (store) => {
  const { auth } = store;
  return {
    caseDetail: auth.caseDetail,
    firstTab: auth.firstTab,
    selectedUser: auth.selectedUser,
  };
};
export default connect(mapStateToProps, null)(Coapplicantform);
